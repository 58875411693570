import React from 'react';
import { Container, Divider, List, Label, Loader, Dimmer, Popup, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

export const YugtunLoader = (props) => (
  <Dimmer active={props.criteria}>
    <Loader size='massive'>
      Iñupiaq is loading...
    </Loader>
  </Dimmer>
);

// let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

export const processStyledText = (sentence) => {
      // sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


export const processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }

export const NewUsageBaseDisplay = (k) => {
    let returnNewUsageBaseDisplayLabel = {
      'verb phrase':[
        'present',
        'past',
        'question',
        'polite command',
        'command',
      ],
      'noun':[
        'stem',
        'possessive',
        'locative noun case',
        'with pronoun',
      ],
    }

    let word = k.word
    let state = k.state
    let props = k.props
    // console.log(word)


    return  <List.Item style={{paddingBottom:'8px',paddingTop:'15px'}}>
              <List.Content style={{fontSize:'16px',paddingBottom:'10px'}}><span style={{paddingRight:'20px',fontWeight:'500'}}>{word.url.replaceAll(',',', ')}</span><span style={{paddingRight:'15px',fontStyle:'italic'}}>{processStyledText(word.thisDefinition)}</span><span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}><TagColors tags={word.t} width={window.innerWidth} /></span></List.Content>
              {word.newUsageDefinitions.map((u,uindex)=>
              <List.Content style={{paddingLeft:'20px'}}>
                <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex],usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList,state.Yposition, state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'3px', color:'#8f8f8f'}} size='small' name='chevron right' />
                  </List.Content>
                </Link>
                <List.Content style={{display:'flex',fontSize:'16px',paddingBottom:'8px',fontFamily:customFontFam,lineHeight:'25px'}}>
                  <span style={{flex:2,'paddingRight':'3px',fontWeight:'500'}}>
                  <Link style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex],usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList, state.Yposition, state.activeTabIndex] }}}>
                  {processStyledTextLink(word.usageWords[uindex])}
                  </Link>
                  {word.audioMatrix[uindex] ?
                    <Button onClick={()=>this.repeatAudio(word.audioMatrix[uindex])} style={{paddingLeft:'7px',fontSize:'14px',color:'#4e7498'}} icon='volume up' />
                    :
                    null
                  }
                  </span>
                  <span style={{flex:5,fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{processStyledText(word.newUsageDefinitions[uindex])}</span>
                  <span style={{flex:3,color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
                </List.Content>
              </List.Content>
              )}
            </List.Item>  
}

export const TagColors = (props) => {
  // console.log(props)
  // let isParticle = props.tags.includes('particle');
  // let isExclamation = props.tags.includes('exclamation');
  // let isConjunction = props.tags.includes('conjunction');
  // let isAdverb = props.tags.includes('adverb');
  // let isPredicate = props.tags.includes('predicate');
  // let isDemonstrative = props.tags.includes('demonstrative');
  // let isInterjection = props.tags.includes('interjection');
  // let isPostbase = props.tags.includes('postbase');
  // let isEnclitic = props.tags.includes('enclitic');

  let upperCase = props.tags
  let width = props.width

  // if (upperCase.length > 0) {
  //   if (upperCase[0] == '!') {
  //     upperCase = upperCase.slice(1)
  //   }
  // }
  // console.log(props)
  let english = ''

var tagDict = {


"adverb":["Adverb","Qanuq, Sukun, Summan Suraġaġmagaaġun ","Qanuq,..."," - How, When, Why Something is/was done."],
"tat":["prefix used when providing more emphasis or information referring back to a previously mentioned thing, or when the entity is closer and visible to the listener","(tat-)","(tat-)",""],
"res":["restricted and visible","(r, v)","(r, v)"," - occupying a restricted area or stationary"],
"ext":["extended and visible","(e, v)","(e, v)"," - occupying a large area or in motion"],
"not":["not visible","(not v)","(not v)",""],
"¤unconfirmed":["Unconfirmed","¤ Iluaġmagaaġutaiḷaq","¤ Iluaġm...",""],
"Anaqtuuvak":["Anaqtuuvak","Anaqtuuvak","Anaqtu..."," - Anaktuvuk Pass Dialect"],
"Nuvuk":["Nuvuk","Nuvuk","Nuvuk..."," - Point Barrow Dialect"],
"Tikiġaq":["Tikiġaq","Tikiġaq","Tikiġa..."," - Point Hope Dialect"],
"Ulġuniq":["Ulġuniq","Ulġuniq","Ulġuni..."," - Wainwright Dialect"],
"verb stem":["Verb Stem","Suraġaġniġum Maŋŋua","Suraġa..",""],
"VERB":["Verb Stem","Suraġaġniġum Maŋŋua","Suraġa..",""],
"(t) TRANSITIVE VERB":["(t) Transitive Verb","Nalunaiŋaruk Suraġaqtilu Suraġaqtaġlu","Naluna..."," - 'Subject And Object Are Marked'  - Ending with Subject and Direct Object"],
"(i) INTRANSITIVE VERB":["(i) Intransitive Verb","Nalunaiŋaruq Suraġaqti","Naluna..."," - 'Subject is Marked' - Ending with Subject Only"],
"verb phrase":["Verb Phrase","Suraġaġniq","Suraġa...",""],
"ablative":["Ablative","Sumiñŋaqtilaaġun","Sumiñŋ..."," - From what or where"],
"absolutive":["Absolutive","Inmiguallaruaq","Inmigu...",""],
"noun case":["Noun Case","Atiqausim Isua","Atiqau..."," - Endings for nouns"],
"conjunction":["Conjunction","Iḷagiigutaq","Iḷagi..."," - Indicator of being with others"],
"contemporative":["Contemporative","Atautchikuaġun Uqallaun","Atautc..."," - Happening at same time statement"],
"deep root":["Deep Root","Maŋŋuġruaq","Maŋŋuġ...",""],
"demonstrative adverb":["Demonstrative Adverb","Tikkuaġaun ","Tikkua...",""],
"demonstrative pronoun":["Demonstrative Pronoun","Tikkuaġaun Atiqausiqqayaq","Tikkua...",""],
"demonstrative stem":["Demonstrative Stem","Tikkuaġautim Maŋŋua","Tikkua...",""],
"discourse marker":["Discourse Marker","Uqaqtuni Ikayuun","Uqaqtu..."," - Helper when talking"],
"dual":["Dual","Malġuk","Malġuk...",""],
"exclamation":["Exclamation","Uqallagaun Tupaktuni, Qinnaliqsuni, Naakka Anniqsuni","Uqalla..."," - A word said loudly when surprised, angered, or when hurt"],
"expression":["Expression","Uqallagniq","Uqalla...",""],
"indicative":["Indicative","Uqallaun Naanŋaraaq","Uqalla...",""],
"interjection":["Interjection","Uqallagaun Saniġatqułługu Uqausiġiraat","Uqalla..."," - A word out of the blue, usually not on subject"],
"interjectional":["Interjectional","Uqallagaun Saniġatqułługu Uqausiġiraat","Uqalla..."," - A word out of the blue, usually not on subject"],
"interrogative":["Interrogative","Uqallaun Apiqqun","Uqalla...",""],
"irregular":["Irregular","Inmiguaqtuaq","Inmigu..."," - Independent word"],
"localis":["Localis","Sumiitilaaġun","Sumiit...",""],
"locative":["Locative","Sumitilaaġun","Sumiti...",""],
"negative":["Negative","Naaggaun","Naagga...",""],
"possessive":["Possessive","Pimaaqtuun","Pimaaq...",""],
"pronoun":["Pronoun","Atiqausiqqayaq","Atiqau..."," - Almost a noun"],
"PRONOUN":["Pronoun","Atiqausiqqayaq","Atiqau..."," - Almost a noun"],
"relative":["Relative","Inmigualaitchuaq","Inmigu...",""],
"similaris":["Similaris","Sutin Itilaaġun","Sutin ...",""],
"subordinative":["Subordinative","Uqallaun Ilaagualaiḷaq","Uqalla...",""],
"temporal noun":["Temporal Noun","Piviksram Igliġniŋagun Atiqausiq","Piviks..."," - Time to do something noun"],
"terminalis":["Terminalis","Sumuktilaaġun","Sumukt...",""],
"toward":["Toward","Tuŋaanun","Tuŋaan...",""],
"vialis":["Vialis","Sukuaqtilaaġun","Sukuaq...",""],
"vocative":["Vocative","Tuqłuun","Tuqłuu...",""],
"postbase":["Postbase","Akunniġun","Akunni...",""],
"noun":["Noun","Atiqausiq","Atiqau...",""],
"root":["Root","Maŋŋuq","Maŋŋuq"," - Neither a verb stem nor noun. Cannot acquire verb or noun endings without the addition of a postbase."],
"*root":["Root","*Maŋŋuq","*Maŋŋuq",""],
"*ROOT":["Root","*Maŋŋuq","*Maŋŋuq",""],
"*deep root":["Root","*Maŋŋuq","*Maŋŋuq",""],
"stem":["Stem","Maŋŋuq","Maŋŋuq",""],
"half transitive":["Half Transitive Form","Skunniġun Nalunaiŋaiqsiḷḷaruaq Suraġaqtamik","Akunniġ..."," - A transitive verb stem with a postbase that enables it to use intransitive endings."],
"°stem":["Stem","°Maŋŋuq","°Maŋŋuq"," - The degree symbol indicates that stems are not complete words as they are but embody concepts."],
"°STEM":["Stem","°Maŋŋuq","°Maŋŋuq"," - The degree symbol indicates that stems are not complete words as they are but embody concepts."],
"°demonstrative stem":["Demonstrative Stem","°Tikkuaġautim Maŋŋua","°Tikkua...",""],
"°interrogative stem":["Interrogative Stem","°Maŋŋuq","°Maŋŋuq",""],
"NOUN":["Noun","Atiqausiq","Atiqau...",""],
"NOUN*":["Noun","Atiqausiq","Atiqau...",""],
"dn":["postbase attaches to demonstrative to become a noun","dn","dn",""],
"dv":["postbase attaches to demonstrative to become a verb stem","dv","dv",""],
"nn":["postbase attaches to singular absolutive noun to become a noun","nn","nn",""],
"nv":["postbase attaches to singular absolutive noun to become a verb stem","nv","nv",""],
"rn":["postbase attaches to root to become a noun","rn","rn",""],
"rv":["postbase attaches to root to become a verb stem","rv","rv",""],
"vn":["postbase attaches to verb stem to become a noun","vn","vn",""],
"vv":["postbase attaches to verb stem to become a verb stem","vv","vv",""],
"wv":["postbase attaches to word to become a verb stem","wv","wv",""],
"limited":["indicates that the distribution of the postbase is limited","limited","limited",""],
"see examples1":["","see example stems","see example stems",[["aullaq-","to leave, go away, depart"],["tikit-","to arrive at"],["ai-","to go home"],["aqpat-","to run (of a human)"]]],
"see examples2":["","see example stems","see example stems",[["siñik-","to sleep"],["niġi-","to eat"],["aquppi-","to be sitting"],["iglaq-","to laugh"]]],

"modalis":["Modalis Case","Qanusiutilaaġun Qanusimik, Qanuq, Qanuqługu","Qanusi..."," - 1) Indirect object of intransitive verb. 2) Instrument or means to do an action. 3) A way to specify an object or an action."], 
"pro-mod":["Modalis Case","Qanusiutilaaġun Qanusimik, Qanuq, Qanuqługu","Qanusi..."," - 1) Indirect object of intransitive verb. 2) Instrument or means to do an action. 3) A way to specify an object or an action."], 
"adv-base":["Stative Case","Sumitilaaġun","Sumiti..."," - Directing the person to a location where something is."],
"adv-loc":["Locative Case","Sumitilaaġun","Sumiti..."," - Location in which an event occurs."],
"adv-via":["Vialis Case","Sukuaqtilaaġun","Sukuaq..."," - Through which an event occurs."],
"adv-abl":["Ablative Case","Sumiñŋaqtilaaġun","Sumiñŋ..."," - From whence an event occurs."],
"adv-2abl":["2nd Ablative Case","Sumiñŋaqtilaaġun","Sumiñŋ..."," - Origin of the movement or progression from the area indicated."],
"adv-ter":["Terminalis Case","Sumuktilaaġun","Sumukt..."," - Termination point of an activity."],
"adv-2ter":["2nd Terminalis Case","Suŋnanmuktilaaġun","Suŋnan..."," - Movement or orientation towards the area indicated."],

"pro-abs":["Absolutive Case","Inmiguallaruaq","Inmigu..."," - 1) Subject of intransitive verb. 2) Direct object of transitive verb."],
"pro-rel":["Relative Case","Inmigualaitchuaq","Inmigu..."," - 1) Subject of a transitive verb. 2) Possessor of another noun."],
"pro-loc":["Locative Case","Sumitilaaġun","Sumiti..."," - Place or time where or when an event occurs."],
"pro-ter":["Terminalis Case","Sumuktilaaġun","Sumukt..."," - 1) Place, time, or entity where an event or action stops, concludes. 2) Subject of embedded verb."],
"pro-abl":["Ablative Case","Sumiñŋaqtilaaġun","Sumiñŋ..."," - 1) Origin of an item or an event. 2) Source of comparison."],
"pro-via":["Vialis Case","Sukuaqtilaaġun","Sukuaq..."," - 1) Venue, time, entity, or circumstances through which an event occurs. 2) Source of a comparison of differences."],
"pro-sim":["Similaris Case","Sutin Itilaaġun","Sutin ..."," - Similarities between entities."],

'demAdvAblVerb':["Verb stem of demonstrative root with an ablative postbase","Suraġaġniġum Sumiñ Tikuaġutaata Maŋŋua","Suraġa...",""],
'demAdvTerVerb':["Verb stem of demonstrative root with a terminalis postbase","Suraġaġniġum Sumun Tikuaġutaata Maŋŋua","Suraġa...",""],
'demAdvViaVerb':["Verb stem of demonstrative root with a vialis postbase","Suraġaġniġum Sukun Tikuaġutaata Maŋŋua","Suraġa...",""],
'demAdvLocVerb':["Verb stem of demonstrative root with a locative postbase","Suraġaġniġum Sumi Tikuaġutaata Maŋŋua","Suraġa...",""],
'rsub':["indicates co-reference (subject of a separate independent verb is the same referent as the subject of this dependent verb)","R","R",""],
'robj':["indicates co-reference (subject of a separate independent verb is the same referent as the object of this dependent verb)","R","R",""],


}


  if (Object.keys(tagDict).includes(props.tags)) {

    if (width < 480 && width !== -1) {
      upperCase = tagDict[props.tags][2]

    } else {


      upperCase = tagDict[props.tags][1]
    }

    // console.log(tagDict[props.tags])
    if (props.tags == 'Anaqtuuvak' || props.tags == 'Nuvuk' || props.tags == 'Tikiġaq' || props.tags == 'Ulġuniq') {
      english = tagDict[props.tags][0]+tagDict[props.tags][3]
    } else if (props.tags.length == 2) {
      english = tagDict[props.tags][0]
    } else {
      english = tagDict[props.tags][1]+' - '+tagDict[props.tags][0]+tagDict[props.tags][3]

    }

  }
    if (props.tags != 'res' && props.tags != 'ext' && props.tags != 'not' && props.tags != 'tat') {
      upperCase = upperCase.toUpperCase()
    }
    
  // console.log(props.tags)

  if (props.tags == 'noun') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f6ede4',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'NOUN' || props.tags == 'NOUN*') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f1f1f1',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'verb stem' || props.tags == 'verb phrase') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#e2eeea',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == '(i) INTRANSITIVE VERB' || props.tags == '(t) TRANSITIVE VERB' || props.tags == 'VERB') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f1f1f1',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'postbase') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f7e7fa',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == '*root' || props.tags == '*deep root' || props.tags == '°stem' || props.tags == '°demonstrative stem' || props.tags == '°interrogative stem') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#ecd8d8',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'Anaqtuuvak' || props.tags == 'Nuvuk' || props.tags == 'Tikiġaq' || props.tags == 'Ulġuniq') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label basic size='mini' style={{color:'#5f5f5f',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags.length == 2) {
    return <Popup position='bottom right' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'Grammar Year 1') {
    return null
  } else if (english.length == 0) {
    return <Label size='mini' style={{backgroundColor:'#f3f3f3',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}</Label>
  } else if (props.tags == '¤unconfirmed') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'res' || props.tags == 'ext' || props.tags == 'not' || props.tags == 'tat') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',whiteSpace:'nowrap',fontFamily:customFontFam,fontSize:'13px',fontWeight:'500',padding:'3px 7px'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'see examples1') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{tagDict['see examples1'][3].map((k)=>{return <div style={{display:'flex',fontFamily:customFontFam}}><div style={{flex:2}}>{k[0]}</div><div style={{flex:5,color:'#737373'}}>{k[1]}</div></div>})}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'see examples2') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{tagDict['see examples2'][3].map((k)=>{return <div style={{display:'flex',fontFamily:customFontFam}}><div style={{flex:4}}>{k[0]}</div><div style={{flex:5,color:'#737373'}}>{k[1]}</div></div>})}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'robj') {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{fontSize:'8.2px',cursor:'pointer',backgroundColor:'#eedede',marginLeft:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags == 'rsub') {
    return <Popup position='bottom left' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{fontSize:'8.2px',cursor:'pointer',backgroundColor:'#eedede',marginLeft:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else if (props.tags.includes('adv-') || props.tags.includes('pro-')) {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  } else {
    return <Popup position='bottom center' on='click' content={<span style={{fontSize:'15px'}}>{english}</span>} trigger={<Label size='mini' style={{backgroundColor:'#f3f3f3',marginTop:'5px',whiteSpace:'nowrap'}}>{upperCase}<Icon style={{fontSize:'9px',margin:0,padding:0,marginLeft:'6px'}} name='chevron down' /></Label>} />
  }



}


// export const WordItem = (props) => {
//   console.log(props.word)
//   let word = props.word;
//   let isNoun = true;
//   // let isVerb = props.tags.includes('verb');
//   // let isParticle = props.tags.includes('particle');
//   // let isExclamation = props.tags.includes('exclamation');
//   // let isConjunction = props.tags.includes('conjunction');
//   // let isAdverb = props.tags.includes('adverb');
//   // let isPredicate = props.tags.includes('predicate');
//   // let isDemonstrative = props.tags.includes('demonstrative');
//   // let isInterjection = props.tags.includes('interjection');


//   // let isPostbase = props.tags.includes('postbase');
//   // let isEnclitic = props.tags.includes('enclitic');

//   // let isExpression = props.tags.includes('expression');
//   // let isCommon = props.tags.includes('grammar');
//   // let isHBC = props.tags.includes('Hooper Bay Chevak');


//   return (
//     <List.Item key={word.keyString}>
//     <Link to={{pathname: '/' + word.url, state: { word: word.keyString, }}}>
//       <List.Content>
//         <List.Header style={{display:'flex',fontSize:'16px',paddingBottom:'4px',fontFamily:"Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"}}>
//           {word.keySplit.map((w,index) => 
//               <span style={{'paddingRight':'3px'}}>
//               {/*{console.log(w)}*/}
//               {this.processStyledText(w[0])}
//               {w[1][0] !== '' ?
//                   <span style={{marginLeft:'3px',fontWeight:'100'}}>{"("+w[1].join(', ')+")"}</span>
//                 :
//                 (index == word.keySplit.length-1 ?
//                   ''
//                   :
//                   ', '
//                 )
//               }
//                 <span style={{ 'marginLeft': '15px'}}>  
//                   {isNoun ? <Label size='mini' style={{backgroundColor:'#7F90B0',color:'white'}}>NOUN</Label> : ''}
//                 </span>
//               </span>
//             )}
//         </List.Header>
//         <List.Description style={{fontSize:'16px',fontWeight:'400'}}>{word.definitionString}</List.Description>
//       </List.Content>
//     </Link>
//     </List.Item>
//   );
// }
