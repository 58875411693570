
import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, Dropdown, List, Label, Popup, Checkbox, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../App.js';
import axios from 'axios';
import palette from 'google-palette';
import shuffle from 'shuffle-array';
import * as Scroll from 'react-scroll';
import {chineseCharacters} from './chineseconstants.js'


var scroll    = Scroll.animateScroll;
let customFontFam = "MingLiu, PingFang"


class Chinese extends Component {
	constructor(props) {
		console.log(props)
		super(props);
		this.state = {
			// history: props.location.state === undefined ? [] : props.location.state.history,
			// from: props.location.state === undefined ? '' : props.location.state.from,
			playingAudio:false,
			pinyin:false,
			clickedIndex:[],
			activeIndex:-1,
		}	   
		scroll.scrollTo(0);

	}

    repeatAudio(audio, event, data) {
	    if (!this.state.playingAudio) {
	      let sound = new Audio(API_URL + "/chineseaudiolibrary/" +  audio);
	      this.setState({playingAudio: true});

	      sound.play()

	      sound.onended=()=>{
	        this.setState({playingAudio: false});
	      }
	    }
    }

	getCharacters(char, event, data) {
		let i = []
		// console.log(char)
		// char.split("").map((k)=>{
		// 	// console.log(pinyin(k, {toneToNumberOnly: true}))
		// 	i.push(pinyin(k)+' ')
		// })
		// return <span style={{color:'red'}}>{char}</span>

		// return i.join("")
		return char
	}

	addIndex(newIndex, event, data) {
		console.log(newIndex, this.state.clickedIndex.includes(newIndex))
		if (this.state.clickedIndex.includes(newIndex)) {
			this.setState({clickedIndex:this.state.clickedIndex.filter(item => item !== newIndex)})
		} else {
			this.setState({clickedIndex:this.state.clickedIndex.concat([newIndex])})
		}
	}

	  handleClick = (e, titleProps) => {
	    const { index } = titleProps
	    const { activeIndex } = this.state
	    const newIndex = activeIndex === index ? -1 : index

	    this.setState({ activeIndex: newIndex })
	    scroll.scrollTo(0);
	  }

	render() {
		console.log(this.state)
		// console.log(this.state.colorsList)

		return (
			<div style={{fontFamily:customFontFam,display:'flex',justifyContent:'center'}}>
			<Accordion styled>
			{[0,1,2,3,4,5,6,7,8,9].map((i,index)=>{
				return <div>
				<Accordion.Title
					active={this.state.activeIndex === index}
					index={index}
					onClick={this.handleClick}
					style={{fontFamily:customFontFam,fontSize:'20px',}}
				>
				<Icon name='dropdown' />
				{(index*100+1)+'-'+((index+1)*100)}
				</Accordion.Title>
				<Accordion.Content active={this.state.activeIndex == index}>
				{this.state.activeIndex == index ?
					(chineseCharacters.map((k,kind)=>
						(Math.floor(kind/400) == index ?
						<div style={{display:'flex',fontFamily:customFontFam,flexDirection:'column',alignItems:'left',marginLeft:(kind%4 == 0 ? 0 : 10)}}>
							<div style={{display:'flex',fontFamily:customFontFam,alignItems:'center'}}>
								<span style={{margin:'10px 0px',}} onClick={()=>this.repeatAudio(k[1])}>
									<span style={{lineHeight:'44px',fontFamily:customFontFam,color:'black',fontSize:'34px',cursor:'pointer',borderBottom:(kind%4 == 0 ?'1px solid #adadad':'')}}>
									{this.getCharacters(k[0])}
									</span>
								</span>
								{kind%4 != 0 ?
									<Button size='mini' style={{marginLeft:'5px',color:'#c6c6c699',border:'solid 1px #c6c6c699',backgroundColor:'white'}} icon={this.state.clickedIndex.includes(kind) ? 'minus' : 'add'} onClick={this.addIndex.bind(this,kind)} />
									:
									null
								}
							</div>
								{this.state.clickedIndex.includes(kind) ?
									<div>
										<div style={{fontSize:'25px',fontFamily:customFontFam,marginBottom:'12px',marginTop:'4px', lineHeight:'30px'}}>{k[2].toLowerCase()}</div>
										<div style={{fontSize:'24px',fontFamily:customFontFam,lineHeight:'27px',marginBottom:'7px',color:'#c9c9c9'}}>{k[3]}</div>
									</div>
									:
									null
								}
						</div>
						:
						null
						)
					))
					:
					null
				}
				</Accordion.Content>
				</div>
			})}
			</Accordion>
			</div>
		);
	}
}
export default Chinese;
