import React, { Component, createRef } from 'react';
import { Segment, Container, Input, List, Label, Divider, Tab, Grid, Header, Image, Button, Menu, Dropdown, Icon, Transition } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import fuzzysort from 'fuzzysort';
import axios from 'axios';
import { API_URL } from '../App.js';
import { YugtunLoader, WordItem, TagColors } from './HomeHelper.js';
import NewUsageBaseDisplay from './NewUsageBaseDisplay.js'
import { inupiaqSpeakerIDs } from './constants/constants.js';
// import { dialogueGenerator } from './constants/dialogueListGenerator.js';


import * as Scroll from 'react-scroll';
// import { Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;

const optionsFuzzy = {
  keys: ['keyStringNormal','fullDefinitionNormalized'],
  limit: 50, // don't return more results than you need!
  threshold: -100, // don't return bad results
  scoreFn: a => {
    // console.log(a)
    return Math.max(a[0] ? (a.obj.keySplit[0][2].includes("Grammar Year 1") ? a[0].score+50 : a[0].score) : -1000, 
                    a[1] ? (a.obj.keySplit[0][2].includes("Grammar Year 1") ? a[1].score+50 : a[1].score) : -1000)
  }
};

const optionsUsageFuzzy = {
  keys: ['usageWordNormal','usageDefinition'],
  limit: 50, // don't return more results than you need!
  threshold: -10000, // don't return bad results
  scoreFn: a => {
    // console.log(a)
    return Math.max(a[0] ? ("keytags" in a.obj ? a[0].score+35 : a[0].score) : -10000, 
                    a[1] ? ("keytags" in a.obj ? a[1].score+35 : a[1].score) : -10000)
  }
};

const optionsAudioFuzzy = {
  keys: ['inormal', 'e'],
  limit: 50, // don't return more results than you need!
  threshold: -10000, // don't return bad results
};

const tabOptions = [
  {
    key: 0,
    text: <div style={{fontSize:'16px'}}><span style={{fontWeight:'600'}}>Uqaluit Sivuniŋit</span><br /><span style={{fontWeight:'400'}}>Dictionary</span></div>,
    value: 0,
  },
  {
    key: 1,
    text: <div style={{fontSize:'16px'}}><span style={{fontWeight:'600'}}>Uqaluliuġun</span><br /><span style={{fontWeight:'400'}}>Word Builder</span></div>,
    value: 1,
  },
  {
    key: 2,
    text: <div style={{fontSize:'16px'}}><span style={{fontWeight:'600'}}>Uqallagniġit</span><br /><span style={{fontWeight:'400'}}>Audio Library</span></div>,
    value: 2,
  },
]

const dailyActivities = 
[
  ["niġiruq","nigiruq","s/he is eating"],
  ["niġigaa","nigigaa","s/he is eating it"],
  ["ikitkaa","ikitkaa","s/he turned it on"],
  ["qamitkaa","qamitkaa","s/he turned it off"],
  ["agmaġaa","agmagaa","s/he is opening it"],
  ["umikkaa","umikkaa","s/he is closing it"],
  ["unitkaa","unitkaa","s/he is leaving it behind"],
  ["kaluġaa","kalugaa","s/he is taking it along"],
  ["itiqtuq","itiqtuq","s/he woke up"],
  ["siquruq","siquruq","s/he fell asleep"],
  ["makittuq","makittuq","s/he is getting out of bed"],
  ["tuttaaqtuq","tuttaaqtuq","s/he is going to bed"],
  ["kiŋuġautchiruq","kiŋugautchiruq","s/he is late"],
  ["miquġaa","miqugaa","s/he is sewing it"],
  ["kiluaġaa","kiluagaa","s/he is ripping its seam"],
  ["qiḷaktuq","qiḷaktuq","s/he is knitting"],
  ["miquqtuq","miquqtuq","s/he is sewing"],
]

const movement = 
[
["aqpattuq","aqpattuq","s/he is running (of a human)"],
["paammaktuq","paammaktuq","s/he is crawling"],
["makitaruq","makitaruq","s/he is standing"],
["minŋiqtuq","minŋiqtuq","s/he is jumping"],
["tiŋiruq","tiŋiruq","s/he is taking flight"],
["mitchaaqtuq","mitchaaqtuq","s/he landed from flight (of bird or plane)"],
["aullaqtuq","aullaqtuq","s/he is leaving"],
["nutqaqtuq","nutqaqtuq","s/he stopped"],
]

const health = [
["quviasuktuq","quviasuktuq","s/he is happy"],
["quviitchuq","quviitchuq","s/he is unhappy"],
["ipiqtusuktuq","ipiqtusuktuq","s/he is depressed"],
["naŋittuq","naŋittuq","s/he is sick"],
["nuvaktuq","nuvaktuq","s/he has a cold"],
["auktuq","auktuq","s/he is bleeding"],
["quiqsuqtuq","quiqsuqtuq","s/he is coughing"],
["qaurimaiqsuq","qaurimaiqsuq","s/he lost consciousness"],
["nakuuruq","nakuuruq","s/he is good"],
["mamittuq","mamittuq","it healed (of a wound, sore, injured body part)"],
["uunnaitchuq","uunnaitchuq","s/he does not have a fever"],
["aqiattuqtuq","aqiattuqtuq","s/he became satisfied, full of food"],
]

const weather = [
["niglaqtuq","niglaqtuq","it is becoming cool (of weather or beverage)"],
["qiiyanaqtuq","qiiyanaqtuq","it is cold (of place, object, weather)"],
["uunaqtuq","uunaqtuq","it is hot"],
["sialuktuq","sialuktuq","it is raining"],
["kalluktuq","kalluktuq","it is thundering (of weather)"],
]
const hunting = [
["tuttuliaqtuq","tuttuliaqtuq","s/he is going caribou hunting"],
["tuttuttuq","tuttuttuq","s/he killed a caribou"],
["iqaluktuq","iqaluktuq","s/he caught a fish"],
["iqalliaqtuq","iqalliaqtuq","s/he is going fishing"],
["aġviqsiuqtuq","agviqsiuqtuq","s/he is hunting whales"],
["ataaqtuqtuq","ataaqtuqtuq","s/he is out whaling, camped on the ice"],
["aivvaktuq","aivvaktuq","s/he killed a walrus"],
]

// const popularBases = [
//   ["niġi-",0,0],
//   // ["niġi-",1,0],
//   ["kaak-",0,0],
//   // ["kaaliq-",0,0],
//   ["isiq-",0,0],
//   ["tautuk-",0,0],

//   ["ai-²",0,0], // go home
//   ["naat-¹",1,0], // to finish
//   ["tautuk-",0,0], // to see something
//   // ["tautuk-",1,0], // to see it
//   ["kaak-",0,0], // to be hungry
//   ["uqallak-¹",0,0], // to speak
//   ["ani-",0,0], // to go out

//   ["ikit-¹",2,0],
//   ["qamit-",2,0],
//   ["agmaq-,aŋmaq-",1,0],
//   ["umik(-)¹",4,0], 
//   ["unit-",0,0], 
//   ["kaluq-",0,0], 
//   ["itiq-",0,0], 
//   ["siqu-",0,0], 
//   ["makit-",1,0], 
//   ["tuttaaq-",0,0], 
//   ["nallaq-",0,0], 
//   ["makit-",0,0], 
//   ["quviasuk-",0,0], 
//   ["quviit-",0,0], 
//   ["ipiqtusuk-",0,0], 
//   ["miquq-",1,0], 
//   ["kiluaq-",1,0], 
//   ["qiḷak-²",0,0], 
//   ["miquq-",1,0], 

//   ["kiŋuġautchi-,kiŋuġauti-",0,0],

//   // ["nigiqpaq(-)",0,0], // nigiqpaq(-) east wind (LS: fst error)
//   // ["nigiqłuk(-)",0,0], // nigiqłuk(-) blizzard from east
//   // ["siḷaluk(-),sialuk(-)",0,1], // sialuk(-) rain, to rain
//   // ["anuqłiq-",0,0], // anuqłiq(-) wind, be windy (LS: fst error)
//   // ["kalluk(-)",0,0], // kalluk(-) thunder, to thunder


//   // ["auk(-)¹",0,0], // auk(-) blood, bleed
//   // ["nuvak(-)",0,0], // nuvak(-) cold, have a cold nuvaktuq|he has a cold
//   // ["iqaluk(-),qaluk(-)",0,0], // iqaluk(-) fish, catch a fish iqaluktuq|he caught a fish
//   // ["mikigaq",0,0], // mikigaq fermented whale meat and maktak

//   /////// ["niglaq-",0,0], // niglaq- become cool niglaqtuq|it is becoming cool (of weather or beverage)
//   ["uunaq-",0,0], // uunaq- be hot uunaqtuq|it is hot
//   ["qiiyanaq-",0,0], // qiiyanaq- be cold qiiyanaqtuq|it is cold (of place, object, weather)
//   /////// ["siḷaluk(-),sialuk(-)",1,1], // sialuk(-) rain, to rain (LS: missing (i/t)) sialuktuq|it is raining
//   // ["anuqłiq-",0,0], // anuqłiq(-) wind, be windy (LS: fst error)
//   ["kalluk(-)",1,0], // kalluk(-) thunder, to thunder
//   ["aiḷaq-",0,0], // aiḷaq- to be wet
//   // ["qiqit-",0,0], // qiqit- to freeze (LS: missing (i/t))
//   // ["auk-",0,0], // auk- to melt (LS: no object in (t) definition error)
//   ["auk(-)¹",1,0], // auk(-) blood, bleed
//   ["mamit-",0,0], // mamit- to heal mamittuq|it healed (of a wound, sore, injured body part)
//   ["naŋit-¹",0,0], // naŋit- be sick naŋittuq|he is sick
//   ["nakuu-",0,0], // nakuu- be good, well, satisfactory nakuuruq|he is good
//   ["nuvak(-)",2,0], // nuvak(-) cold, have a cold
//   // ["uunnautiqaq-",0,0], // uunnautiqaq- have a fever (LS: uunnaun has uunnautiqaqtuq example)
//   ["uunnait-,uunnautait-",0,1], // uunnautait- not have a fever uunnaitchuq|he is not having a fever
//   ["quiqsuq-",0,0], // quiqsuq- to cough quiqsuqtuq|he is coughing
//   // ["miġiaġaq-",0,0], // muġiaġaq- to vomit (LS: miġiaq(-),muġiaq(-),miġġiq to vomit, miġiaġaq- to vomit repeatedly fst error)
//   // ["qauri-",0,0], // qauri- gain consciousness (LS: fst error)
//   ["qaurimaiq-",0,0], // qaurimaiq- to lose consciousness qaurimaiqsuq|he lost consciousness

//   ["aqiattuq-",1,0], // aqiattuq- become satisfied, full of food “stomach is satisfied” aqiattuqtuq|he became satisfied, full of food

//   ["tuttuliaq-",0,0], // tuttuliaq- go caribou hunting tuttuliaqtuq|he is going caribou hunting
//   ["tuttut-",0,0], // tuttut- kill a caribou tuttuttuq|he killed a caribou
//   ["iqaluk(-),qaluk(-)",1,0], // iqaluk(-) fish, catch a fish
//   ["iqalliaq-",0,0], // iqalliaq- go fishing iqalliaqtuq|he is going out fishing
//   // ["natchiḷiaq-",0,0], // natchiḷiaq- go seal hunting (LS: combined postbase)
//   // ["ugruksiuq-",0,0], // ugruksiuq- be bearded seal hunting (LS: combined postbase)
//   // ["ugruksiuġiaq-",0,0], // ugruksiuġiaq- go bearded seal hunting (LS: combined postbase)
//   ["aġviqsiuq-,aġviġniaq-",0,0], // aġviqsiuq- be whaling aġviqsiuqtuq|he is hunting whales
//   ["ataaqtuq-",0,0], // ataaqtuq- to be at whaling camp on the ice ataaqtuqtuq|he is out whaling, camped on the ice
//   // ["aiviqsiuq-",0,0], // aiviqsiuq- be walrus hunting (LS: combined postbase)
//   ["aivvak-",0,0], // aivvak- kill a walrus aivvaktuq|he killed a walrus
//   // ["qaukkiqi-",0,0], // qaukkiqi- be duck hunting (LS: qaukkiaq-)
//   // ["niġliaq-",0,0], // niġliaq- go geese hunting (LS: combined postbase)

//   // ["mikigaqtullatu-",0,0], // mikigaqtullatu- to love to eat mikigaq LS: combined postbase

//   ["aqpat-",0,0], // aqpat-to run aqpattuq|he is running (of a human)
//   ["paammak-",0,0], // paammak- to crawl paammaktuq|he is crawling
//   ["makita-",0,0], // makita- be standing makitaruq|he is standing
//   // ["aksraliuk-",0,0], // aksraliuk- to roll (LS: aksrak-; aksralik-,aksralui-)
//   ["minŋiq-,mitŋiq-",0,0], // minŋiq- to jump minŋiqtuq|he is jumping
//   ["tiŋi-",0,0], // tiŋi- to fly away, take off tiŋiruq|it is taking flight
//   ["mitchaaq-",0,0], // mitchaaq- to land from flight mitchaaqtuq|it landed from flight (of bird or plane)
//   ["aullaq-",0,0], // aullaq- to leave aullaqtuq|he is leaving
//   ["nutqaq-",0,0], // nutqaq- to stop nutqaqtuq|he stopped
// ]

const popularBases = [
  ["agmaq-,aŋmaq-",1,0],
  ["aġviqsiuq-,aġviġniaq-",0,0], // aġviqsiuq- be whaling aġviqsiuqtuq|he is hunting whales
  ["ai-²",0,0], // go home
  ["aiḷaq-",0,0], // aiḷaq- to be wet
  ["aivvak-",0,0], // aivvak- kill a walrus aivvaktuq|he killed a walrus
  ["ani-",0,0], // to go out
  ["aqiattuq-",1,0], // aqiattuq- become satisfied, full of food “stomach is satisfied” aqiattuqtuq|he became satisfied, full of food
  ["aqpat-",0,0], // aqpat-to run aqpattuq|he is running (of a human)
  ["ataaqtuq-",0,0], // ataaqtuq- to be at whaling camp on the ice ataaqtuqtuq|he is out whaling, camped on the ice
  ["auk(-)¹",1,0], // auk(-) blood, bleed
  ["aullaq-",0,0], // aullaq- to leave aullaqtuq|he is leaving
  ["ikit-¹",2,0],
  ["ipiqtusuk-",0,0], 
  ["iqalliaq-",0,0], // iqalliaq- go fishing iqalliaqtuq|he is going out fishing
  ["iqaluk(-),qaluk(-)",1,0], // iqaluk(-) fish, catch a fish
  ["isiq-",0,0],
  ["itiq-",0,0], 
  ["kaak-",0,0],
  ["kaluq-",0,0], 
  ["kalluk(-)",1,0], // kalluk(-) thunder, to thunder
  ["kiluaq-",1,0], 
  ["kiŋuġautchi-,kiŋuġauti-",0,0],
  ["makit-",0,0], 
  ["makita-",0,0], // makita- be standing makitaruq|he is standing
  ["mamit-",0,0], // mamit- to heal mamittuq|it healed (of a wound, sore, injured body part)
  ["minŋiq-,mitŋiq-",0,0], // minŋiq- to jump minŋiqtuq|he is jumping
  ["miquq-",1,0], 
  ["mitchaaq-",0,0], // mitchaaq- to land from flight mitchaaqtuq|it landed from flight (of bird or plane)
  ["naat-¹",1,0], // to finish
  ["nallaq-",0,0], 
  ["nakuu-",0,0], // nakuu- be good, well, satisfactory nakuuruq|he is good
  ["naŋit-¹",0,0], // naŋit- be sick naŋittuq|he is sick
  ["niġi-",0,0],
  ["nuvak(-)",2,0], // nuvak(-) cold, have a cold
  ["nutqaq-",0,0], // nutqaq- to stop nutqaqtuq|he stopped
  ["paammak-",0,0], // paammak- to crawl paammaktuq|he is crawling
  ["qamit-",2,0],
  ["qaurimaiq-",0,0], // qaurimaiq- to lose consciousness qaurimaiqsuq|he lost consciousness
  ["qiiyanaq-",0,0], // qiiyanaq- be cold qiiyanaqtuq|it is cold (of place, object, weather)
  ["qiḷak-²",0,0], 
  ["quiqsuq-",0,0], // quiqsuq- to cough quiqsuqtuq|he is coughing
  ["quviasuk-",0,0], 
  ["quviit-",0,0], 
  ["siqu-",0,0], 
  ["tautuk-",0,0], // to see something
  ["tiŋi-",0,0], // tiŋi- to fly away, take off tiŋiruq|it is taking fligh
  ["tuttaaq-",0,0], 
  ["tuttuliaq-",0,0], // tuttuliaq- go caribou hunting tuttuliaqtuq|he is going caribou hunting
  ["tuttut-",0,0], // tuttut- kill a caribou tuttuttuq|he killed a caribou
  ["umik(-)¹",4,0], 
  ["unit-",0,0], 
  ["uqallak-¹",0,0], // to speak
  ["uunaq-",0,0], // uunaq- be hot uunaqtuq|it is hot
  ["uunnait-,uunnautait-",0,1], // uunnautait- not have a fever uunnaitchuq|he is not having a fever
]

// const popularNouns = [
//   ["ui",0,0], 
//   ["nuliaq(-)",0,0], 
//   ["tuvaaqan",1,0],
//   ["qitunġaq",0,0],
//   ["piayaaq",0,0],
//   ["aapiyaq",0,0],
//   ["aatauraq",0,0],
//   ["aqqaluk",0,0],
//   ["nayaaluk",0,0],
//   ["nuka,nukaq,nukatchiaq,nukaaluk",0,4],
//   // ["aññaq",0,0],
//   ["iḷḷuq,iḷḷualuk",0,0],
//   ["aaka",0,0],
//   ["aapa",0,0],
//   ["aakaaluk",0,0],
//   ["aapaaluk",0,0],
//   // ["iġñiq",0,0],
//   ["panik",0,0],
//   ["iñġutaq",0,0],
//   ["tutik,tutaaluk",0,0],
//   ["amau,amaułuk,amauluk",0,1],
//   // ["iḷummiq",0,0],
// ]

const popularNouns = [
  ["aaka",0,0],
  ["aakaaluk",0,0],
  ["aapa",0,0],
  ["aapaaluk",0,0],
  ["aapiyaq",0,0],
  ["aatauraq",0,0],
  ["aġnaq",0,0], // female
  ["amau,amaułuk,amauluk",0,1],
  ["aŋun",0,0], // male
  ["apqun",0,0], // road
  ["aqqaluk",0,0],

  ["iglu(-)",0,0], // house
  ["iġġi,iġġiq",0,0], // mountain
  ["iḷḷuq,iḷḷualuk",0,0],
  ["iñġutaq",0,0],
  ["iñuk(-)",0,0], // person
  ["Iñupiaq",0,0],
  ["iqaluk(-),qaluk(-)",0,0], // fish

  ["maktaaq",0,0],
  ["mikigaq",0,0],
  ["miqłiqtuq",0,0],

  ["natiq(-)¹",0,0], // floor
  ["nanuq",0,0],
  ["nayaaluk",0,0],
  ["nuliaq(-)",0,0], 
  ["nuka,nukaq,nukatchiaq,nukaaluk",0,4],
  ["nuna",0,0], // land

  ["panik",0,0],
  ["piayaaq",0,0],
  ["qamun",0,0],
  ["qimmiq",0,0], // dog
  ["qitunġaq",0,0],
  ["siku(-)¹",0,0], // ice 
  ["suu",0,0],

  ["Tanik",1,0], // white person
  ["tauqsiġñiaġvik,tauqsiġiaġvik",0,0], // store
  ["Tikiġaq",0,0], // point hope
  ["tiŋŋun",0,0],
  ["tupiq(-)",0,0], // tent   
  ["tutik,tutaaluk",0,0],
  ["tuttu",0,0],
  ["tuvaaqan",1,0],



  ["ui",0,0], 
  ["umiaq(-)",0,0],
  ["Utqiaġvik,Utqiaġviit",0,0], // barrow
  ["uunaalik",0,0],

]

const popularExclamations = [
// ["a","listen; oops; oh"],
["aa¹","I agree"],
["aa²","(expresses exasperation, criticism, or dismay over what another person does or says; said in breathy voice)"],
["aaglukiaq","yes, that is right; I agree"],
["aakhaa","that’s not right!; you’re lying!"],
["aallalii","shame on you; not like that"],
["aannaa","oh, my; oh"],
["aannagu","wait; wait a minute"],
["aaññaŋaa,. aaññuŋuu","oh, how cute; adorable"],
["aaŋ","yes"],
["aaŋhaa","it’s not right"],
["aaqan,. aaqagu,. aaqagun","wait; wait a minute; later on; in a while"],
["aaqaqa","(surprise or dismay (Jenness 1928: 7)"],
["aaqhaaŋ.uumaa","my goodness; oh dear"],
["aaqhii","(Nu) oh my"],
["aaqqaa","phew, what a bad smell; how foul smelling; it stinks!"],
["aarigaa","good; oh, how nice; wonderful; that’s good; awesome"],
["aasi","don’t; stop i"],
["aasii suruq,. aasii suva","and so what; what difference does it make to you; what’s it to you"],
["aatahai","(Nu) such cute little things"],
["aatakaa,. aatukuu","oh, how little"],
["aatchikaaŋ","what a narrow escape; look out"],
["aattai","oh, how cute; oh, how pretty"],
["aġvaaq.aŋŋuuq","(announcement crying out that a whale has been caught)"],
["aġviḷia","(announcement cried out as a crew or family member of a successful whaling captain gets on a blanket toss to jump)"],
["ai","seeks approval or endorsement"],
["aiḷḷukiaq","yes, that is right; I agree"],
["aiy","is that so"],
["akkakaa","enough, what a lot"],
["akłạ","a too bad (dismay, of disappointment)"],
["alappaa,. alappuu","oh, it’s cold"],
["aliuġnaġaa","how amazing; wow"],
["aḷakkaa","alas; oh, no; something didn’t go right; that’s too bad"],
["aḷḷai","(expresses displeasure at unacceptable behavior or unsuitable conditions)"],
["amai","yes; (Ti) I don’t know"],
["amii","don’t you agree; isn’t that so"],
["amiami,. amiamikii","well....based on whatever...; well, just the same"],
["amikii","well, anyway"],
["amma","(Nu) really"],
["ammi","don’t be stupid!"],
["ammiumaa","oh, don’t be stupid; don’t you dare; that’s nonsense"],
["anaa","(Nu) oh my"],
["anaŋŋaa","(Ti) how cute"],
["aniqsa","fortunately; luckily; good thing"],
["annaa","(Ti) (excl. of grief, sadness)"],
["annii","(excl. of pain, sorrow, or disappointment)"],
["aŋ","here, take it"],
["aŋŋuu,. aŋŋuuŋ","quit it; leave it alone; don’t do that"],
["apai,. apay","(Ti) enough; that’s plenty; too much"],
["aqh","wow"],
["ara","(surprise, as when seeing or smelling smoke)"],
["araa","no, I don’t want to; oh, no; too much"],
["araavai","too much"],
["araumaa","(excl. of pain, sorrow, or disappointment)"],
["araq,. araqhaa","how big; wow; cool"],
["arii","ouch; it hurts"],
["ariumaa","oh, you make me tired"],
["arraa","I’ve had it"],
["asugupta","fortunately, luckily, it’s a good thing"],
["asulu","you’re so right; should have done so"],
["asuluasu","so, that’s the reason, no wonder"],
["asulukiaq","that’s right"],
["ataa","(Nu) (Ti) silence; be quiet; listen (as to a strange noise)"],
["ataguna","here, give it to me; let me see it; look at this"],
["atagupta","much to one’s annoyance, this is the usual pattern of behavior"],
["atain","move"],
["¤atainna","(Ti) it’s right over there"],
["atakhii","oh, look at that"],
["ataŋ","listen"],
["atchaan","almost; that was close"],
["atchu","I don’t know"],
["atchuamiiq","I joke!"],
["atchuhaa","don’t know, beats me"],
["atchukii","don’t know, not certain at all"],
["atta","listen; be quiet; be silent"],
["auk²","(Ti) I’m tired"],
["ayyai","that was close; almost"],
["chach,. chatchii","very good"],
["hauk(-)","oh, I am so tired; (Ti) to be depressed and tired"],
// ["i","oops; oh oh"],
["iaqi","don’t"],
["ii¹","yes"],
["ii²","aah"],
["ii³","(excl. of fear or alarm, said in a throaty breathy voice)"],
["ii⁴","(moan or groan expressing sadness or sickness)"],
["iikiaq","yes, I think so"],
["iiq","it’s going to get you and it’s scary"],
["iiqinii,. iirigii","I’m scared, I don’t want to; it was scary"],
["iitittii","(cry of surprise when burned or hurt, or when one narrowly escapes an accident)"],
["iivaluk","yes, I guess so"],
["ikkahii","look, it’s over there"],
["ikkaikka,. ikkahii","there, over there"],
["ikkii","icky; oh, how dirty"],
["iksrumaa","hey, you over there"],
["ilaa²","(used to express that something or someone is similar to or acts like another being or thing)"],
["iḷaiññaq unnii","regardless of the outcome"],
["iḷaŋa.mininnagu","give it all you’ve got"],
["imani","at last, finally, about time"],
["immaami","maybe that is the situation"],
["immahii","oh, the previously mentioned one"],
["imma iḷaanni","maybe so"],
["immakiaq","I think...; I wonder..."],
["immaliqaa","oh, I almost forgot ..."],
["immakii","maybe"],
["imma qanuq","maybe so"],
["innatun,. iminnaatun,. imannaatun","this much"],
["ka","(Nu) go ahead"],
["kaasi","that’s enough; don’t; stop it"],
["kaukau","“food” (trade jargon from a Polynesian language)"],
["kaviuk, kaviuk,.kaviuk","said or called out while making little circles with the index finger"],
["ki","come on; on with it; do it; go ahead"],
["kiami","okay, fine (said when one is convinced)"],
["kiamiki","okay then; okay okay (said when one is convinced)"],
["kiata (Nu),. kiita (Ti),. kiataŋ","let’s give it a try; let’s go; okay then; (Ti) go ahead; try it; (Nu) go ahead; go on"],
["kihaa","come on; let’s go; enough of this nonsense"],
["kii","see what happens when you do that; I told you so"],
["kiika, kiikaa,. kiikavsaaq,. kiikalu","I want more; more"],
["kiiki","keep it up; you asked for it, you do not deserve any pity or sympathy"],
["kiikii, ki, ki","encouraging someone to keep performing"],
["kiisaimma,. kiisaimmaa","finally, at last"],
["kiita","let’s go"],
["kinnaq","you fool"],
["kiq","give it all you’ve got; try your best"],
["ku ku(u)","(excl. to start or scare dogs)"],
["maannaasii","aw shucks; it’s happening again"],
["maannakiaq","now, that’s how it should be or be done; perfect; you did it just right"],
["maannapayaaq","now, that’s better; much better"],
["maanna tavra","you think you’re doing right but it’s wrong; that’s not a very smart thing you’re doing"],
["maannapayaaq.tavra","oh, darn; oh, bother; oh, brother"],
["mam","this time I’ll succeed"],
["naagga,. naakka","no"],
["nalaiñ","not very much; do it right; be careful; take it easy; don’t mess up"],
["nanii","(excl. used when something is done well; especially, when done with sufficient force to overpower the opponent, or when one has a good rebuttal)"],
["naumi","no"],
["naumi qanuq","I guess not"],
["naumiunnii","no, after all"],
["naumipayuk.una","good work (said to someone considered unlikely to accomplish the task)"],
["naumiunahaa","good for you"],
["naumiunnii","no, after all"],
["ñiaq","don’t; don’t do that"],
["paasaqtuŋa","(Ti) “I cannot move my cards anymore” (in snerts card game)"],
["puiyaqpaŋŋuuq","(Ti) “they are spouting”"],
["¤qaġligiruaġivsi","“I woke up before all of you”"],
["qanuġliqaa","by any means; in whatever manner or style"],
["qanukkiaq","I wonder how (way or manner, in what condition)"],
["qanuqpiviñ.imña","my goodness, why are you carrying on so"],
["qanuq siḷa","“how is the weather”"],
["qanuq suli","how unfortunate"],
["qaŋaluqaa","oh, just like the way it used to be long ago"],
["qaŋami taima","oh, it was a long time ago"],
["qhai! qhai!","(shouted when arrivals come into view)"],
["qilamik","quickly; right away"],
["quyanaq","thank you; good riddance, you had it coming (when said sarcastically and with a different inflection)"],
["saa,. sa,. cha","huh; what; what did you say"],
["samma³","let me see it"],
["sammaami","let me see it then (for verification)"],
["sammakii","it is highly probable considering the circumstances"],
["¤sii²","it’s cold"],
["siḷagisuktani.tavra.siḷagiliutigaa","you got what you deserve"],
["sivunniqsa","call for question (during a meeting)"],
["sua,. sua una","lo and behold; then totally or partially unexpected; unexpectedly"],
["suahii,. suaqhii","(Nu) what’s that"],
["suasiuna","lo, then again, it appears unexpectedly"],
["su iluqaan","everything"],
["suksraq","what good is it; never mind; it is of no consequence"],
["suksraq una","this one is good for nothing"],
["sunauvva","and all the while; lo and behold"],
["suniayuġattuq","it’s just as well; it doesn’t matter"],
["suuramik unnii","definitely; without any exception"],
["suvaluk","especially so; in particular"],
["taammaliami","just let her/him be over there (not visible), can’t do anything about it, whatever happens happens"],
["taamnaasuuna","you are impossible; he is impossible; oh, you"],
["taikkaikka","there; over there"],
["taikuu","thank you"],
["taima","that’s true; that’s it; that’s enough; stop"],
["taimalugguuqasu","it is inevitable; to be predestined; to be a foregone conclusion"],
["taimmalikii","so what, nothing can be done, so let it be"],
["tainna","(Nu) enough"],
["tainnaŋiḷaaq","not like that"],
["talak","come (said to dogs)"],
["talva","(Ti) that’s all; it’s over"],
["tarva,. tavra","yes, I know; that’s right, true; that’s all; that’s enough, stop it"],
["tasamma","stop it; that’s all"],
["tausaqsiŋaasuuna,. tausaqsiŋahaa","(a swear word used to get attention before one scolds; said when one is offended or something goes wrong)"],
["tavrakiaq","I think, guess that is it"],
["tavrakii","it happened, couldn’t be avoided and can’t be helped!"],
["tavraliqaa","oh yes, that is what happened"],
["tavrapayaaq","that’s better; that’s more like it"],
["tavrapiallak","precisely; exactly"],
["tavratualuk,. tavratuaq","that’s all; that was the last one"],
["¤ukayaŋałaqpuġluqauna","(excl. of pleasure and excitement, used when one sees a friend or relative unexpectedly)"],
["una","no way"],
["unaasuuna","oh, you are so exasperating, so infuriating"],
["uniuŋasirusi","point of order (parliamentary procedure)"],
["usii","you remember; you know"],
["usiuvva","oh, I thought so..."],
["uumaa","hey, you; hey, don’t"],
["uuñuŋuu","oh, how little; so small"],
["uutukuu,. (Nu) uutukuhuu","how little; so small"],
["uvva","here, take it"],
["uvvahii,. uvvii","here is the situation; here are the facts; look, here it is"],
["uvvaliqaa","oh yes, here it is (remembering); now I understand the situation"],
["yaa","(excl. of displeasure, disbelief, surprise)"],
["yai¹,. yaiy,. yach","wow; very nice; just right"],
["yai²","too bad; alas"],
["yaqhii","my goodness; oh, no; too bad"],
]
  
const popularCelebrations = [
["aġġi-","(i) to hold an Iñupiaq dance"],
["aŋayu-","(i) to dance Iñupiaq style"],
["Kivgiq(-)","Messenger Feast, (i) to have a Messenger Feast"],
["maġlak-","(i) to present a gift to someone before one dances so that (she/he) may get up and dance as well, (t) to present a gift to her/him before one dances so that (she/he) may get up and dance as well"],
["maġlaktuuti-","(i) to give presents to each other (of participants or attendees at an Iñupiaq dance)"],
["Nalukataq(-)","whaling festival held usually in June, after spring whaling season ends (a communal feast held outdoors in which blanket tossing is the main entertainment with an Eskimo dance providing the grand finale), (i) to celebrate Nalukataq (of many people)"],
["qiḷaurraq-","(i) to drum, play the drum"],
["qiḷaun","knitting needle"],
["sayuq-","(i) to do a dance with fixed Iñupiaq dance motions"],
["uamit-,.uamipiaq-","(i) to dance Iñupiaq style (of a man), (i) to dance Iñupiaq style (of woman or man)"],
["uyuk-","(i) to dance Iñupiaq style (of woman)"],

]
// let gen_out = dialogueGenerator();


const popularSu = [
["suna","what"],
// ["suk","what (two)"],
// ["sut","what (3+)"],
["sumi","where, in what location"],
["sumun","to where"],
["sumiñ","from where"],
["suqpani","where, in which area"],
["suŋnamun","to which direction"],

["summan","why"],
["sukun","via what"],
// ["sutun","like what"],
]

let demonstrativeExampleSentences = [
["⎡°ag⎤","pro","abs","agna iñuk","that person over across there (i.e. walking along the river)","edna-01.mp3"],
["⎡°akim⎤","pro","abs","akimña nuna","the land over across there (not visible)","edna-02.mp3"],
["⎡°av⎤","pro","abs","amna umiaq","the boat over there towards the west","edna-03.mp3"],
["⎡°am⎤","pro","abs","amna qamŋuiruaq","the one snoring over there (i.e. in the next room)","edna-04.mp3"],
["⎡°Ik⎤","pro","abs","igña iñuk","that person over there (visible, restricted, at eye level) ","edna-05.mp3"],
["⎡°Im⎤","pro","abs","imña iñuk","that person previously mentioned","edna-06.mp3"],
["⎡°kan⎤","pro","abs","kanna qattaq","the water barrel down there by the door","edna-07.mp3"],
["⎡°kIg⎤","pro","abs","kigña qattaq","the water barrel visible just outside of the house","edna-08.mp3"],
["⎡°kIv⎤","pro","abs","kimña iñuk","the person inside the house (or up the coast towards the east, standing still)","edna-09.mp3"],
["⎡°mar⎤","pro","abs","manna suppun","this rifle (lengthy)","edna-10.mp3"],
["⎡°pag⎤","pro","abs","pagna tiŋŋun","that airplane up there","edna-11.mp3"],
["⎡°pakim⎤","pro","abs","pakimna qayaq","the qayaq on the roof (i.e. so the dogs don't get to it)","edna-12.mp3"],
["⎡°pam⎤","pro","abs","pamna taġġaqtuun","that mirror upstairs","edna-13.mp3"],
["⎡°pIk⎤","pro","abs","pigña naniq","that light up there","edna-14.mp3"],
["⎡°qag⎤","pro","abs","qagna akłunaaq","that rope out there","edna-15.mp3"],
["⎡°qakim⎤","pro","abs","qakimña qamun","that car outside","edna-16.mp3"],
["⎡°qam⎤","pro","abs","qamna niġġivik","that table inside","edna-17.mp3"],
["⎡°qav⎤","pro","abs","qamna iñuk","that person up the coast towards the east","edna-18.mp3"],
["⎡°sakim⎤","pro","abs","sakimña kiguunniq","that box in the cold porch","edna-19.mp3"],
["⎡°sam¹⎤","pro","abs","suna samna?","what is that under here?","edna-20.mp3"],
["⎡°sam²⎤","pro","abs","samna umiaq","that boat that is out on the ocean (not v)","edna-21.mp3"],
["⎡°uv⎤","pro","abs","una qallun","this cup","edna-22.mp3"],
["⎡°un⎤","pro","abs","unna iñuk","that person down there walking along the beach","edna-23.mp3"],
["⎡°ag⎤","pro","abs","iḷisimaviuŋ agna iñuk?","do you know that person over across there (i.e. walking along the river)?","edna-24.mp3"],
["⎡°akim⎤","pro","abs","akimña umiaŋat atuġniaġikput","we will use their boat that is across over there","edna-25.mp3"],
["⎡°av⎤","pro","abs","amna suppun salummaqqaaġlugu tutquġuŋ","clean that rifle (lengthy) then put it away","edna-26.mp3"],
["⎡°am⎤","pro","abs","kiña amna atuqpa?","who is that singing (i.e. in the next room, not visible)?","edna-27.mp3"],
["⎡°Ik⎤","pro","abs","igña qallun kaviqsaaq atuġuŋ","use that (visible, restricted, at eyelevel) red cup ","edna-28.mp3"],
["⎡°Im⎤","pro","abs","imña unnugman kasuqtaġa iñuk kimik atiqaqpa?","who is the name of that person I met yesterday?","edna-29.mp3"],
["⎡°kan⎤","pro","abs","kanna qattaq sikuliqsuanikkupku qirriuġumautin","after you fill that (i.e. by the door) water barrel you should chop some wood","edna-30.mp3"],
["⎡°kIg⎤","pro","abs","kiña kigña iñuk aapam uqaqatigivauŋ?","who is that (i.e outside, visible) person that father is conversing with?","edna-31.mp3"],
["⎡°kIv⎤","pro","abs","kimña aġnaq aakam uqaqatigiraa savaqatiginiaġiga","I will be working with that (inside, visible) woman that mother is conversing with","edna-32.mp3"],
["⎡°mar⎤","pro","abs","manna savik ipiksaġuŋ","sharpen this (lengthy) knife","edna-33.mp3"],
["⎡°pag⎤","pro","abs","pagna tiŋŋun nipaturuq","that airplane up there is loud","edna-34.mp3"],
["⎡°pakim⎤","pro","abs","pakimña qayaq atuġniaġaa","he will use that (top of the house roof) qayaq","edna-35.mp3"],
["⎡°pam⎤","pro","abs","pamna quqsuqtaaq aquppiutaq qaiñiaġiga ilignun","I will give you that (upstairs) yellow chair ","edna-36.mp3"],
["⎡°pIk⎤","pro","abs","pigña naniq simmiġuŋ","change that light bulb up there","edna-37.mp3"],
["⎡°qag⎤","pro","abs","qagna umiaq amiḷitqigniaġikput upinġaaġu","we will replace the cover of that (outside, lengthy) boat next spring","edna-38.mp3"],
["⎡°qakim⎤","pro","abs","qakimña qamun aullaqpa?","did the car outside start?","edna-39.mp3"],
["⎡°qam⎤","pro","abs","qamna siuġruk tunmiġautat ataaniittuaq atuġniaġikput","we will use that (inside, not visible) campstove which is under the stairs","edna-40.mp3"],
["⎡°qav⎤","pro","abs","qamna qamun sukapaluktuq ","that (up the coast towards the east) vehicle is very fast","edna-41.mp3"],
["⎡°sakim⎤","pro","abs","sakimña kiguunniq imaiḷaq isiġuŋ","bring that (in the cold porch) empty box inside","edna-42.mp3"],
["⎡°sam¹⎤","pro","abs","suna samna?","what is that (under the table)?","edna-43.mp3"],
["⎡°sam²⎤","pro","abs","qanusiq samna umiaqpak nipauqłuktaqpa?","what kind of ship is that (on the ocean, not visible) blowing its horn?","edna-44.mp3"],
["⎡°uv⎤","pro","abs","una qallutiga","this is my cup","edna-45.mp3"],
["⎡°un⎤","pro","abs","kia unna supputaa?","whose rifle that (on the floor)?","edna-46.mp3"],
["⎡°ag⎤","pro","via","aktumuuna ayaŋniaqtuŋa","I will go via that (over there across, in motion, visible) one","edna-47.mp3"],
["⎡°akim⎤","pro","via","akiptumuuna umiakun naattaptigun, Utqiaġviŋñiaqtugut...","via that (not visible, across) boat that we've been working on, we will go to Utqiaġvik...","edna-48.mp3"],
["⎡°av⎤","pro","via","aptumuuna avilaitqatipkun aiñiaqtuŋa ","I will go home with that (over there, visible, in motion) my friend","edna-49.mp3"],
["⎡°am⎤","pro","via","aptumuuna avilaitqatipkun aiñiaqtuŋa ","I will go home with that (over there, not visible) my friend","edna-50.mp3"],
["⎡°Ik⎤","pro","via","iktumuuna qamutikun aullaġumautin aasiiḷi iktumuunatchikun aullaġniaqtuŋa  ","you should leave with that (over there) car and I will leave with that (over there) other car","edna-51.mp3"],
["⎡°Im⎤","pro","via","iptumuuna uqausiġiraptigun tuyuġiuŋ","send it via that entity (i.e., person) that we talked about","edna-52.mp3"],
["⎡°kan⎤","pro","via","kattumuuna tiŋŋutikun Utqiaġvigñiaqtugut","we will go to Utqiaġvik via that (down river) airplane","edna-53.mp3"],
["⎡°kIg⎤","pro","via","kiktumuuna aputikuaġutikun aiñiaġiga ","I will fetch her with that (outside, visible) snowmobile","edna-54.mp3"],
["⎡°kIv⎤","pro","via","kiptumuuna tiŋŋutikun Canada-mugniaqtuŋa","I will go to Canada on that (up the coast towards east, visible) airplane","edna-55.mp3"],
["⎡°mar⎤","pro","via","mattumuuna train-kun aullaġumaaqtuŋa ","I am eager to leave on this (visible, closeby, lengthy) train","edna-56.mp3"],
["⎡°pag⎤","pro","via","paktumuuna apqutikun sukanaqtuq","it is possible to travel fast on that (away from shore, visible, lengthy) road","edna-57.mp3"],
["⎡°pakim⎤","pro","via","pakiptumuuna helicopter-kun naŋirvigmuutiniaġikput ","we will take her to the hospital with that (on rooftop, not visible) helicopter","edna-58.mp3"],
["⎡°pam⎤","pro","via","paptumuuna qaqasaurakun tuyuġniaġiga","I will write to her via that (upstairs, not visible) computer","edna-59.mp3"],
["⎡°pIk⎤","pro","via","piktumuuna qiŋakun isiqsimaruaq","it evidently came in through that (up there, visible, restricted) house vent","edna-60.mp3"],
["⎡°qag⎤","pro","via","qaktumuuna train-kun utiġumiñaqtutin","you can return via that (outside, visible, in motion) train","edna-61.mp3"],
["⎡°qakim⎤","pro","via","qakiptumuuna Kumaum tuyuġmiaŋagun qaimaruaq","it is evident that he came with that (in next door neighbor's house, not visible) house guest of Kumak","edna-62.mp3"],
["⎡°qam⎤","pro","via","qaptumuuna tiŋŋutikun utiġniaqtugut","we will return with that (in there, not visible) airplane","edna-63.mp3"],
["⎡°qav⎤","pro","via","qaptumuuna umiaqpakun tikiññiaġniraŋa ","he said she will arrive via that (up the coast, moving, visible) ship","edna-64.mp3"],
["⎡°sakim⎤","pro","via","sapkiptumuuna tuyuġiuŋ","send it with that (in the cold porch, not visible; speaker in the house) one","edna-65.mp3"],
["⎡°sam¹⎤","pro","via","saptumuuna immiivikun tuyuġniaġiga ","I will send her a message via that (under here, not visible) tape recorder","edna-66.mp3"],
["⎡°sam²⎤","pro","via","saptumuuna umiaqpakun nipauqłuktaqtuakun aullaġniaqtuŋa","I will leave on that (on ocean, not visible) ship which is blowing its horn","edna-67.mp3"],
["⎡°uv⎤","pro","via","uumuuna putukun anniiñ","go out via this (here, visible, restricted) hole","edna-68.mp3"],
["⎡°un⎤","pro","via","uttumuuna umiaqpakun aullaġniaqtuŋa ","I will leave on that (on ocean, visible, in motion) ship","edna-69.mp3"],
["⎡°ag⎤","pro","mod","aktumiŋa umiamik atuġñiaqtugut","we will use that (there across, visible) boat","edna-70.mp3"],
["⎡°akim⎤","pro","mod","akiptumiŋa uqausiġiraanik atuġupta suniaqpa?","will it be fine if we use that (there across, not visible) what he is talking about?","edna-71.mp3"],
["⎡°av⎤","pro","mod","aptumiŋa akłuunamik atuġniaqtuq","he will use that (over there, lengthy, visible) rope ","edna-72.mp3"],
["⎡°am⎤","pro","mod","aptumiŋa atuunmik atuġupta suniaqpa?","will it be fine if we sing that (in the other room being sung) song?","edna-73.mp3"],
["⎡°Ik⎤","pro","mod","iktumiŋa miquunmik aitchuġniaġiga","I will give her that (over there at eye level, visible) sewing machine","edna-74.mp3"],
["⎡°Im⎤","pro","mod","iptumiŋa atikłiaqqaaŋanik atipkaġlakput","let us put on her that (in the past item) first parka she made","edna-75.mp3"],
["⎡°kan⎤","pro","mod","kattumiŋa qattamik tigumiaġutin...","you carrying that water pail down there...","edna-76.mp3"],
["⎡°kIg⎤","pro","mod","kiktumiŋa qamunmik atuġniaqtuŋa ","I will use that (outside, visible, restricted) car ","edna-77.mp3"],
["⎡°kIv⎤","pro","mod","kiptumiŋa qamunmik utqutiniaġikpiñ","I will take you back with that (up the coast eastward, visible, restricted) car","edna-78.mp3"],
["⎡°mar⎤","pro","mod","mattumiŋa suppunmik atuqtirruŋ","let him use this (visible, close to speaker, lengthy) rifle","edna-79.mp3"],
["⎡°pag⎤","pro","mod","paktumiŋa akłunaamik qimiqsruġniaġaat ","they will bound it with that (up hanging, visible, lengthy) rope ","edna-80.mp3"],
["⎡°pakim⎤","pro","mod","pakiptumiŋa qayyamik atuġumaaqtuŋa ","I am planning to use that (on the roof, not visible) kayak","edna-81.mp3"],
["⎡°pam⎤","pro","mod","paptumiŋa taġġaqtuunmik aitchuqtaani ","she gave me that (upstairs, not visible) mirror","edna-82.mp3"],
["⎡°pIk⎤","pro","mod","piktumiŋa qiñiġaamik aitchuŋŋa ","gift me with that (up there, visible, restricted) picture","edna-83.mp3"],
["⎡°qag⎤","pro","mod","qaktumiŋa napaqsramik ikaaġutchiġuŋ ","put a bridge over it with that (outside, visible, lengthy) pole","edna-84.mp3"],
["⎡°qakim⎤","pro","mod","qakiptumiŋa suvvigmiittuamik atuġiñ ","use that (inside outside dwelling, not visible, speaker and listener are in neighboring dwelling) one","edna-85.mp3"],
["⎡°qam⎤","pro","mod","qaptumiŋa atuġniaqtuŋa ","I will use that (inside, not visible)","edna-86.mp3"],
["⎡°qav⎤","pro","mod","qaptumiŋa atuġniaqtuŋa ","I will use that (up the coast towards east, not visible)","edna-87.mp3"],
["⎡°sakim⎤","pro","mod","sakiptumiŋa qattamik atuqtinniaġitka","I will let them use that (in the cold entrance way, not visible) water barrel","edna-88.mp3"],
["⎡°sam¹⎤","pro","mod","saptumiŋa tuttaat ataaniittuamik atuġiñ","use the one that is under the bed","edna-89.mp3"],
["⎡°sam²⎤","pro","mod","saptumiŋa naattamnik aitchuqsaġiñ","you should give her the one that (downstairs, not visible) I finished","edna-90.mp3"],
["⎡°uv⎤","pro","mod","uumiŋa aitchuġniaġiga","I will give her this (within reach of speaker, visible, restricted) one","edna-91.mp3"],
["⎡°un⎤","pro","mod","uttumiŋa umiamik Ulġuniġniaqtugut","we will go to Ulġuniq using that umiaq (down there, by the door, downriver)","edna-92.mp3"],
["⎡°ag⎤","pro","sim","aktumatun umiatun piqaġuktuŋa ","I would like to have a boat like that (across there, e.v.) one","edna-93.mp3"],
["⎡°akim⎤","pro","sim","akiptumatun avaalaruatun piññak ","don't do like that (across there, not visible) one who is hollering ","edna-94.mp3"],
["⎡°av⎤","pro","sim","aptumatun aġnatun atigiqaġuktuŋa","I would like to have a parka like that (there, in motion) woman","edna-95.mp3"],
["⎡°am⎤","pro","sim","aptumatun qamŋuiruatun nipaturutin ","you are as loud as that (i.e. in the other room, not visible)","edna-96.mp3"],
["⎡°Ik⎤","pro","sim","iktumatun iglaŋatqugikpiñ","I would like you to smile like that (there at eye level, visible)","edna-97.mp3"],
["⎡°Im⎤","pro","sim","iptumatun unnugman kasuqtaptitun qiññaqaqtuq","he looks like that (in aforementioned time) one we met yesterday","edna-98.mp3"],
["⎡°kan⎤","pro","sim","kattumatun iñuktun","like that person down there","edna-99.mp3"],
["⎡°kIg⎤","pro","sim","kiktumatun qattatun qauniqaqsimaruaq","it had the same color as that (out there, visible) water barrel","edna-100.mp3"],
["⎡°kIv⎤","pro","sim","kiptumatun iglutun igluqaġuktuaq","she wanted a house just like that (up the coast towards east, visible)","edna-101.mp3"],
["⎡°mar⎤","pro","sim","mattumatun qaġruuraqtuutitun ittuamik paqisimaruaq","it seems he found a shotgun just like this (close to speaker, lengthy, visible) one","edna-102.mp3"],
["⎡°pag⎤","pro","sim","paktumatun qamutitun qauniqaqtuq","it has the same color as that (up inland, visible, in motion) car","edna-103.mp3"],
["⎡°pakim⎤","pro","sim","pakiptumatun qayatun piliuġumautin","you should make a qayaq like that qayaq up there (i.e. on the roof)","edna-104.mp3"],
["⎡°pam⎤","pro","sim","paptumatun nipauqłuktaunmik iḷisaġnak","don't study how to use a trumpet like that (upstairs, not visible) one","edna-105.mp3"],
["⎡°pIk⎤","pro","sim","piktumatun ittuamik siqiñŋuraqaqtugut","we have a clock like that (up there, visible, restricted) one","edna-106.mp3"],
["⎡°qag⎤","pro","sim","qaktumatun napaqsratun taktigiruq","it is as long as that (out there, visible) pole","edna-107.mp3"],
["⎡°qakim⎤","pro","sim","qakiptumatun suvviuratun aktigiruamik piḷiuqsaġiñ","you should build a storage shed like that (outside, not visible) one for him ","edna-108.mp3"],
["⎡°qam⎤","pro","sim","qaptumatun niġġivivsisun aktigiruamik piqaqtuŋa","I have one that is as big as that (inside, not visible) table of yours","edna-109.mp3"],
["⎡°qav⎤","pro","sim","qaptumatun aputikuaġutitun sukaruq aputikuaġutiga","my snowmachine is as fast as that (up the coast towards east, in motion, visible) snowmachine","edna-110.mp3"],
["⎡°sakim⎤","pro","sim","sakiptumatun sakivsiviptun piḷiuquruaq","she wants a skin scraping pole like that (in the cold porch, not visible) one I have in the cold porch","edna-111.mp3"],
["⎡°sam¹⎤","pro","sim","saptumatun qimmivsisun paqitchuktuŋa ","I would like to find a dog like that (i.e. under the table, not visible) one you have ","edna-112.mp3"],
["⎡°sam²⎤","pro","sim","saptumatun miquutiptun piqaġuktuaq","she wants a sewing machine like that (downstairs, not visible) one I have downstairs","edna-113.mp3"],
["⎡°uv⎤","pro","sim","uumatun ittuamik paqinniaŋitchutin","you will not be able to find one like this (closer to speaker, visible, restricted) one","edna-114.mp3"],
["⎡°un⎤","pro","sim","uttumatun umiatun aktigiŋitchuq ","it is not the same size as that (in the ocean, in motion) boat ","edna-115.mp3"],
["⎡°ag⎤","pro","rel","aktuma iñuum iḷisimaniraatin ","that (across a barrier, in motion, visible) person said he knows you","edna-116.mp3"],
["⎡°akim⎤","pro","rel","akiptuma nunam umialiŋa ","that (across there, not visible) land's king ","edna-117.mp3"],
["⎡°av⎤","pro","rel","aptuma qamutim aksraligaŋi qasuŋarut","the tires of that (over there, in motion, visible) car are loose","edna-118.mp3"],
["⎡°am⎤","pro","rel","aptuma iñuum tautugukkaatin","that (in the other room, not visible) person wants to see you","edna-119.mp3"],
["⎡°Ik⎤","pro","rel","iktuma paniŋa","that (over there at eye level, visible, not in motion) one's daughter","edna-120.mp3"],
["⎡°Im⎤","pro","rel","iptuma paniŋa","that (previously mentioned) one's daughter","edna-121.mp3"],
["⎡°kan⎤","pro","rel","kattuma iñuum paniŋa","that (down there by the ocean, visible, not in motion) person daughter","edna-122.mp3"],
["⎡°kIg⎤","pro","rel","kiktuma qamutim aksraligaksrautiŋi mikivaisimaruat","it seems the intended tires for that (outside, visible, not in motion) car are too small ","edna-123.mp3"],
["⎡°kIv⎤","pro","rel","kiptuma tupqum iñua iñukpalliŋaitchuq suli","the owner of that (up the coast towards east, visible, restricted) tent has made an appearance yet","edna-124.mp3"],
["⎡°mar⎤","pro","rel","mattuma aglaiyautaa nuŋuŋaruq ","the eraser of this (here, lengthy, visible) one is depleted","edna-125.mp3"],
["⎡°pag⎤","pro","rel","paktuma akłauram tupaktipalukkaaŋa ","that (up there, in motion, visible) spider sure startled me","edna-126.mp3"],
["⎡°pakim⎤","pro","rel","pakiptuma iñuum mayuġautaŋi ulġumaruat ","it seems that (on the roof, not visible) person's ladder toppled over ","edna-127.mp3"],
["⎡°pam⎤","pro","rel","paptuma atiŋa puiguġiga ","I forget that (upstairs, not visible) entity's name","edna-128.mp3"],
["⎡°pIk⎤","pro","rel","piktuma qullim qaummaġniŋa","that (up there, restricted, visible) light's brightness","edna-129.mp3"],
["⎡°qag⎤","pro","rel","qaktuma aġnam atikłua qiññaġipaluktuq","that (outside, visible, in motion) woman's parka cover is beautiful","edna-130.mp3"],
["⎡°qakim⎤","pro","rel","qakiptuma aputikuaġutim aksraligaŋa kiktuġaqsimaruaq","it seems the track of that (out there in shed, not visible) snowmachine snapped","edna-131.mp3"],
["⎡°qam⎤","pro","rel","qaptuma igniġvium naniŋa qamimaruaq","it seems the light of that (inside, not visible) stove went out","edna-132.mp3"],
["⎡°qav⎤","pro","rel","qaptuma iñuum piqataa kiña?","who is the companion of that (up the coast towards east, in motion, visible) person?","edna-133.mp3"],
["⎡°sakim⎤","pro","rel","sakiptuma qattam matuaŋa tammaŋaruq","the lid of that (in the cold entry, not visible) water barrel is lost","edna-134.mp3"],
["⎡°sam¹⎤","pro","rel","saptuma qimmim atiŋa Pakak","the name of that (under the table, not visible) dog is Pakak","edna-135.mp3"],
["⎡°sam²⎤","pro","rel","saptuma miquutigma mitqutiŋi paqillaiġitka","I cannot find the needles for that (downstairs, not visible) my sewing machine","edna-136.mp3"],
["⎡°uv⎤","pro","rel","uuma ikayuġniaġaatin","this (here, visible, not in motion) will help you","edna-137.mp3"],
["⎡°un⎤","pro","rel","uttuma aapaŋa tikiññiaqtuq uvlupak","the father of that (by the ocean, visible, in motion) one will arrive today","edna-138.mp3"],
["⎡°ag⎤","adv","adv","agga aŋuniaqatiksran ikaaġiaqtuq","that (entity across there, visible, in motion) one going to cross over will be your hunting partner","edna-139.mp3"],
["⎡°am⎤","adv","adv","amma atigin","your parka is in the other room","edna-140.mp3"],
["⎡°av⎤","adv","adv","avva supputin","over there is your gun","edna-141.mp3"],
["⎡°av⎤","adv","adv","avva iñuit","the people are down the coast, towards the west (from Barrow)","edna-142.mp3"],
["⎡°akim⎤","adv","adv","aŋmani aġġiñiaqtugut","we will hold the Iñupiaq dance across ","edna-143.mp3"],
["⎡°Ik⎤","adv","adv","ikka qallutin","there's your cup","edna-144.mp3"],
["⎡°Im⎤","adv","adv","imma iḷisimagaluaqtaġa","I used to remember it","edna-145.mp3"],
["⎡°kan⎤","adv","adv","kanna kamikiñ","down there by the door are your boots","edna-146.mp3"],
["⎡°kIg⎤","adv","adv","kigga qamutiġa tikitchuq","my car has arrived out there ","edna-147.mp3"],
["⎡°kIv⎤","adv","adv","kivva iñuit","the people are up the coast, stationary, towards the north (from Barrow)","edna-148.mp3"],
["⎡°mar⎤","adv","adv","marra tilaġġisit","here's the broom","edna-149.mp3"],
["⎡°pag⎤","adv","adv","pagga kammaka","up there are my boots (drying out over the stove, i.e.)","edna-150.mp3"],
["⎡°pam⎤","adv","adv","pamma makpiġaat","the book is upstairs","edna-151.mp3"],
["⎡°pakim⎤","adv","adv","paŋma Anaqtuġvigmi ","there (inland, not visible, furthest extent) in Anaqtuġvik ","edna-152.mp3"],
["⎡°pIk⎤","adv","adv","pikka siqiñġuraq","up there is the clock","edna-153.mp3"],
["⎡°qag⎤","adv","adv","qagga ikaaqatiksratin tikitchut","there (outside, visible, in motion) arriving are your companions in making the crossing","edna-154.mp3"],
["⎡°qam⎤","adv","adv","qamma iglumi qimmit anuŋit","in there (in the house) are the harnesses of the dogs","edna-155.mp3"],
["⎡°qav⎤","adv","adv","qavva iñuit","the people are up the coast in motion","edna-156.mp3"],
["⎡°qakim⎤","adv","adv","qaŋma niġirut Kumatkugni ","they are eating there (inside next door, not visible) with Kumak and her spouse","edna-157.mp3"],
["⎡°sam²⎤","adv","adv","samma miquqtuq","she is sewing downstairs ","edna-158.mp3"],
["⎡°sam¹⎤","adv","adv","samma uqaqtuq","she is talking in here (not visible, inside the phone)","edna-159.mp3"],
["⎡°sakim⎤","adv","adv","saŋma qanitchani","out there in the cold entry","edna-160.mp3"],
["⎡°un⎤","adv","adv","unna","down there (coast, in motion, extended)","edna-161.mp3"],
["⎡°un⎤","adv","adv","unna umiaqpak","there (on the ocean, in motion, visible) the ship","edna-162.mp3"],
["⎡°uv⎤","adv","adv","uvva uvaniituq","here (restricted, visible), here it is","edna-163.mp3"],
["⎡°taag⎤","adv","adv","taagga savaqatitka ","across there (visible, in motion) are my coworkers","edna-164.mp3"],
["⎡°taam⎤","adv","adv","taamma suli uqaqtut","they are still talking there (not visible) ","edna-165.mp3"],
["⎡°taav⎤","adv","adv","taavva avilaitqatikpiñ iḷaŋi","there (towards the west, extended, visible) are your friend's relations","edna-166.mp3"],
["⎡°taakim⎤","adv","adv","taaŋma suli sivunniuqtut","they are still deliberating there (across, not visible)","edna-167.mp3"],
["⎡°taik⎤","adv","adv","taikka qallutaa","there (visible, at eye level, restricted) is his cup","edna-168.mp3"],
["⎡°taim⎤","adv","adv","taimma tautugnatqiŋitchuq ","then he was not seen ever again","edna-169.mp3"],
["⎡°takan⎤","adv","adv","takanna puggutaq atuġuŋ","use that cooking pot there (which is by the door, visible, restricted)","edna-170.mp3"],
["⎡°tatkIg⎤","adv","adv","tatkigga kiguunniksran","out there (restricted, visible) is the box for you","edna-171.mp3"],
["⎡°tatkIv⎤","adv","adv","tatkivva iglukput","up there (towards the northeast) is our house","edna-172.mp3"],
["⎡°tamar⎤","adv","adv","tamarra kamivut","spread around here are our boots","edna-173.mp3"],
["⎡°tatpag⎤","adv","adv","tatpagga tupivut","there (extended, visible, upriver) are our tents","edna-174.mp3"],
["⎡°tatpam⎤","adv","adv","tatpamma aakakput","our mother is up there (in a house further away inland, not visible)","edna-175.mp3"],
["⎡°tatpakim⎤","adv","adv","tatpaŋma atqallaiqsuaq","she is there (on the roof, not visible) unable to come down ","edna-176.mp3"],
["⎡°tatpIk⎤","adv","adv","tatpikka tupiqput","there (visible, restricted, upriver) is our tent","edna-177.mp3"],
["⎡°tatqa(g)⎤","adv","adv","tatqagga umiaġa","out there (extended, visible) is my boat","edna-178.mp3"],
["⎡°tatqam⎤","adv","adv","tatqamma aakan","your mother is inside","edna-179.mp3"],
["⎡°tatqav⎤","adv","adv","tatqavva umiat","there (in motion, visible, up the coast towards the north) are the boats","edna-180.mp3"],
["⎡°tatqakim⎤","adv","adv","tatqaŋma kasimarut","they are holding a meeting there (inside, not visible, in neighboring house)","edna-181.mp3"],
["⎡°tasam²⎤","adv","adv","tasamma tusaġnaġaluaqtut qiñiġnaitchut taktukpaił̣ł̣uni ","they are down there (in the ocean, not visible), we can hear them but can't see them because of the thick fog","edna-182.mp3"],
["⎡°tasam¹⎤","adv","adv","tasamma niqiksrasi","in here (not visible, in container) is your grub","edna-183.mp3"],
["⎡°tasam¹⎤","adv","adv","tasamma miquaksratin","there (downstairs, not visible) are the ones you should sew","edna-184.mp3"],
["⎡°tasakim⎤","adv","adv","tasaŋma iqaluit","there (inside the cold entry, not visible) are the fish","edna-185.mp3"],
["⎡°taun⎤","adv","adv","taunna napaqsraq kalillugu aggisuutisaġiñ","you should take that there (down on the beach, lengthy, visible) long pole home ","edna-186.mp3"],
["⎡°tavr⎤,⎡°taam⎤","adv","adv","tavra taamna piuŋ","there that (closer to listener, visible, restricted) take it","edna-187.mp3"],
["⎡°ag⎤","adv","via","auna igluliñiaqtuguk ","we will build a house over there (across, entended, visible)","edna-188.mp3"],
["⎡°am⎤","adv","via","amuuna pitugniaġiga taġġiraun","I will install the television in there (another room, not visible)","edna-189.mp3"],
["⎡°av⎤","adv","via","avuuna apqusiuġniaġniruat ","they said they will build a road via there (towards the west, extended, visible)","edna-190.mp3"],
["⎡°akim⎤","adv","via","aŋmuuna mitchaaġvium saaŋanun tulagniaġniruaq ","he said that he will come ashore there (across, extended, not visible) in front of the airport","edna-191.mp3"],
["⎡°Ik⎤","adv","via","ikuuna igalauraliġniaġiga iggavik","I will put a window in the kitchen, there (speaker in the kitchen, there at eye level, visible, restricted)","edna-192.mp3"],
["⎡°kan⎤","adv","via","kanuuna tupiġniaqtuŋa ","I will set a tent there (downriver, restricted, visible)","edna-193.mp3"],
["⎡°kIg⎤","adv","via","kiuna suvviuraliuġniaqtuguk ","we will build a shed there (outside, restricted, visible)","edna-194.mp3"],
["⎡°kIv⎤","adv","via","kivuuna saaŋagun suġalliqpium tulagviḷiuġniaqtut ","they will build a dock there (towards the east on beach, restricted, visible) in front of the warehouse","edna-195.mp3"],
["⎡°mar⎤","adv","via","mauna apqusiuġniaqpalliqsut","they will probably build a road through here (close to speaker, extended, visible)","edna-196.mp3"],
["⎡°pag⎤","adv","via","pauna naŋirvium tunuagun nappaiñiaqtut","they will construct something there (inland, extended, visible) behind the hospital","edna-197.mp3"],
["⎡°pam⎤","adv","via","pamuuna qiḷaguakun tautuktaġa","I saw him through there (via window upstairs, not visible)","edna-198.mp3"],
["⎡°pakim⎤","adv","via","paŋmuuna Fairbanks-kun apqusiuġniaqtut ","they will construct a road through Fairbanks (spoken by someone in Utqiaġvik)","edna-199.mp3"],
["⎡°pIk⎤","adv","via","pikuuna tulagniaqtugut ","we will come to shore there (upriver, restricted, visible)","edna-200.mp3"],
["⎡°qag⎤","adv","via","qauna alġurat iḷiñiaġait","they will place the wires there (outside, extended, visible)","edna-201.mp3"],
["⎡°qam⎤","adv","via","qamuuna Piġnikun nappaiñiaġniruat napaqsranik","they said they would set up poles there (up the coast towards east, not visble) in Piġniq","edna-202.mp3"],
["⎡°qav⎤","adv","via","qavuuna Piġniġum saaŋagun apqutitchialiuġuktut ","they would like a build a new road there (up the coast towards northeast, visible, extended) in front of Piġniq ","edna-203.mp3"],
["⎡°qakim⎤","adv","via","qaŋmuuna alġuraliqsiqqaaġlutin alġuraliġniaġaatigut ","they will put wiring in there (in a neighboring house, not visible) first, then they will provide us with wiring","edna-204.mp3"],
["⎡°sam²⎤","adv","via","samuuna taġġirautiqaġniaŋitchugut ","we will not have a television downstairs (below, not visible) ","edna-205.mp3"],
["⎡°sam¹⎤","adv","via","samuuna tutquqsiviqaqtuguk ","we have storage space there (under here, not visible)","edna-206.mp3"],
["⎡°sakim⎤","adv","via","saŋmuuna qakirviḷiuġniaġniruaq ","he said he would build shelves there (out there, in the cold entryway, not visible)","edna-207.mp3"],
["⎡°un⎤","adv","via","unuuna sapukutchiuġniaqtut ","they will construct a barrier there (by the ocean, visible, extended) ","edna-208.mp3"],
["⎡°uv⎤","adv","via","uvuuna qiŋaliġuŋ ","construct a vent here (visible, restricted)","edna-209.mp3"],
["⎡°ag⎤","adv","loc","aani Europe-mi piuraaġniaqtut ","they will play there (across a body of water, not visible) in Europe","edna-210.mp3"],
["⎡°am⎤","adv","loc","amani siñigniaqtuguk ","we will sleep in there (in the other room, not visible)","edna-211.mp3"],
["⎡°av⎤","adv","loc","avani piuraaġniaqtugut","we will play over there (down the coast towards west, expansive, visible)","edna-212.mp3"],
["⎡°akim⎤","adv","loc","aŋmani Russia-mi kasimaniaġniraŋi ","she said that they will hold a meeting there (across furthest away, not visible) in Russia ","edna-213.mp3"],
["⎡°Ik⎤","adv","loc","ikani utaqqiñiaqtuŋa","I will wait over there (close by, visible, restricted)","edna-214.mp3"],
["⎡°kan⎤","adv","loc","kanani umiam saniġaani utaqqiñiaqtuŋa ","I will wait down there (on beach, restricted, visible) by the boat","edna-215.mp3"],
["⎡°kIg⎤","adv","loc","kiani utaqqiḷuk","let's wait out there (outside, visible, restricted)","edna-216.mp3"],
["⎡°kIv⎤","adv","loc","kivani nalukataġniaqtut iglupta saaŋani ","they will hold the whaling feast there (up the coast towards east, visible, restricted) in front of our house","edna-217.mp3"],
["⎡°mar⎤","adv","loc","maani utaqqitquraatiguk aakamnuk","our mother wants us to wait here (expansive, visible)","edna-218.mp3"],
["⎡°pag⎤","adv","loc","paani igluqaqtuguk","we have a house there (upriver, extended, visible) ","edna-219.mp3"],
["⎡°pam⎤","adv","loc","pamani siñikkumiñaqtusi ","you all can sleep up there (upstairs, not visible)","edna-220.mp3"],
["⎡°pakim⎤","adv","loc","paŋmani paŋma ","there (on roof, not visible) there (up there) it is","edna-221.mp3"],
["⎡°pIk⎤","adv","loc","pikani aŋaiyyuvigmi atuġaaġniaqtugut","we will hold a singspiration there (further inland, visible, restricted) in the church","edna-222.mp3"],
["⎡°qag⎤","adv","loc","qaani ittuq (qaaniittuq)","it is there (outside close to dwelling, visible)","edna-223.mp3"],
["⎡°qam⎤","adv","loc","qamani aġnat kasimarut ","the women are holding a meeting there (inside dwellling, not visible)","edna-224.mp3"],
["⎡°qav⎤","adv","loc","qavani Iñuuvigmi iñuuruk ","they live there (eastwards, not visible) in Iñuuvik","edna-225.mp3"],
["⎡°qakim⎤","adv","loc","qaŋmami samma kamipiakiñ","your fur boots are there (out, in shed, not visble) in the shed","edna-226.mp3"],
["⎡°sam²⎤","adv","loc","samani samma piuraaqtut","they are playing there (downstairs, not visible) ","edna-227.mp3"],
["⎡°sam¹⎤","adv","loc","samani samma niġġivium ataani iriqsimaruq","he is hiding there (immediately under, not visible) under the table","edna-228.mp3"],
["⎡°sakim⎤","adv","loc","saŋmani qanitchani paqinniaġikkiñ","you will find them there (out, not visible) in the cold entry","edna-229.mp3"],
["⎡°un⎤","adv","loc","unaniillaturuŋa aġviqsiuġapta ","I like to remain there (on the ice, visible) when we are whaling","edna-230.mp3"],
["⎡°uv⎤","adv","loc","uvani aquppiuraaġniaqtuguk ","we will sit here (restricted, visible) leisurely","edna-231.mp3"],
["⎡°ag⎤","adv","abl","aaŋŋa qanniġñiaqtuŋa","I will order from over across there (i.e., from across an expanse of water or land)","edna-232.mp3"],
["⎡°am⎤","adv","abl","amaŋŋa imaiyautiksraisuġiñ ","fetch a towel from there (next room, not visible)","edna-233.mp3"],
["⎡°av⎤","adv","abl","avaŋŋa aputiksraisuġin","fetch snow from over there (towards west, visible, expansive)","edna-234.mp3"],
["⎡°akim⎤","adv","abl","aŋmaŋŋa annuraaksraisuġiñ","get some clothes from over there (across the river)","edna-235.mp3"],
["⎡°Ik⎤","adv","abl","ikaŋŋa piksraġniaqtuŋa ","I will get some from there (restricted, visible)","edna-236.mp3"],
["⎡°kan⎤","adv","abl","kanaŋŋa piqaluyagmiñ sikutaġiñ ","fetch some ice from there (on the beach) from the freshwater iceberg","edna-237.mp3"],
["⎡°kIg⎤","adv","abl","kiaŋŋa qattamiñ ukuak immikkik","fill these two up from the water barrel there (outside, visible, restricted)","edna-238.mp3"],
["⎡°kIv⎤","adv","abl","kivaŋŋa siġḷuamiñ qaugaksraisuġumausik uvlupak ","you two should fetch some ducks from the ice cellar there (up the coast towards the east, visible, restricted)","edna-239.mp3"],
["⎡°mar⎤","adv","abl","maaŋŋa iḷaksraġniaġiga ","I will get a piece for it from here (extended, visible, close to speaker)","edna-240.mp3"],
["⎡°pag⎤","adv","abl","paaŋŋa mitchaaġvium akianiñ aniutaqtuagut ","we fetched packed snow from the other side of the runway there (inland, visible, extended)","edna-241.mp3"],
["⎡°pam⎤","adv","abl","pamaŋŋa qiḷaguamiñ aitqatiksaisuġiñ","fetch yourself a pair of mittens from there (upstairs, not visible)","edna-242.mp3"],
["⎡°pakim⎤","adv","abl","paŋmaŋŋa isakki mayuġauttat ","fetch the ladder from there (on roof, not visible)","edna-243.mp3"],
["⎡°pIk⎤","adv","abl","pikaŋŋa napaaqtum qitqaniñ qisiqsiutiksraġiñ","get a branch from the middle section of the tree there (up, visible, restricted)","edna-244.mp3"],
["⎡°qag⎤","adv","abl","qaaŋŋa maġġaksraisuġiñ ","fetch some gravel from there (outside, extended, visible)","edna-245.mp3"],
["⎡°qam⎤","adv","abl","qamaŋŋa nauliga aigguuŋ ","fetch my harpoon from there (outside inside dwelling, not visible)","edna-246.mp3"],
["⎡°qav⎤","adv","abl","qavaŋŋa Piġniġmiñ aniuksraisuqtuaŋa ","I fetched packed snow from Piġniq (up the coast towards east, visible)","edna-247.mp3"],
["⎡°qakim⎤","adv","abl","qaŋmaŋŋa Kumagmiñ avuatchiaġiñ ","go borrow some sugar from Kumak (in neighbor's house, out, not visible)","edna-248.mp3"],
["⎡°sam²⎤","adv","abl","samaŋŋa inauramniñ uliksraġiñ ","go fetch a blanket from my bedroom there (downstairs, not visible) ","edna-249.mp3"],
["⎡°sam¹⎤","adv","abl","samaŋŋa kiguunniġmiñ saviksraġniaġikpiñ ","I will get a knife for you from the box down here (i.e. under the table in a box)","edna-250.mp3"],
["⎡°sakim⎤","adv","abl","saŋmaŋŋa quaksraġiñ ","fetch some frozen meat from there (out, in cold entry, not visible)","edna-251.mp3"],
["⎡°un⎤","adv","abl","unaŋŋa ivaqłiġiaġiñ piqaluyagnik ","go look for fresh water ice chunks there (along the shore, visible)","edna-252.mp3"],
["⎡°uv⎤","adv","abl","uvaŋŋa nivviamiñ imiqtaġniaqtuŋa ","I will get some water from the water well here (restricted, visible)","edna-253.mp3"],
["⎡°ag⎤","adv","ter","auŋaġniaqtuguk qitiġusianikkumnuk ","we will go there (across, extended, visible) after we have lunch","edna-254.mp3"],
["⎡°akim⎤","adv","ter","aŋmuŋa ikaaġniaqtuŋa tautugiaġlugu avilaitqatiga ","I will go across (there, not visible, possibly beyond another barrier) to visit my friend","edna-255.mp3"],
["⎡°av⎤","adv","ter","avuŋa pisuaqtuaġluk ","let's go for a walk there (towards the west, visible, extended)","edna-256.mp3"],
["⎡°am⎤","adv","ter","amuŋa tamatkua iḷikki ","put those there (in the next room, not visible)","edna-257.mp3"],
["⎡°Ik⎤","adv","ter","ikuŋa iḷḷiuŋ taamna ","put that one over there (visible, restricted at eye level)","edna-258.mp3"],
["⎡°kan⎤","adv","ter","kanuŋa upkuam saniġaanun taapkuak iḷikkik","put those two down there by the door (down there, restricted, visible)","edna-259.mp3"],
["⎡°kIg⎤","adv","ter","kiuŋa tutquġuŋ","store it out there (restricted, visible)","edna-260.mp3"],
["⎡°kIv⎤","adv","ter","kivuŋa Igluqpaurat saaŋanun tulaktut","they came ashore there (up the coast towards east, restricted, visible) in front of Browerville","edna-261.mp3"],
["⎡°mar⎤","adv","ter","mauŋa tamatkua nappakki","set those upright around here (extended, visible)","edna-262.mp3"],
["⎡°pag⎤","adv","ter","pauŋa ikiġġat qaaŋiġḷugit tuppiġñiaqtugut ","we will put the tent up there (extended, visible) past the meat caches","edna-263.mp3"],
["⎡°pakim⎤","adv","ter","paŋmuŋa iḷisigiŋ ","store them up there (on rooftop, not visible)","edna-264.mp3"],
["⎡°pam⎤","adv","ter","pamuŋa qiḷaguamun iriġitchik","you two hide upstairs (extended, not visible)","edna-265.mp3"],
["⎡°pIk⎤","adv","ter","pikuŋa niviŋŋaġuŋ taamna qiñiġaaq ","hang that picture up there (restricted, visible)","edna-266.mp3"],
["⎡°qag⎤","adv","ter","qauŋa iñiñiaġitka kamikłuusi qitiġusianikkuma ","I will hang your pants there (outside, visible) after I have lunch","edna-267.mp3"],
["⎡°qakim⎤","adv","ter","qaŋmuŋa suvviuramun tutquġniaġitka ","I will store them out there in the shed (not visible)","edna-268.mp3"],
["⎡°qav⎤","adv","ter","qavuŋa Iḷisaġvik College-mun nullautchiġiaġniaqtuguk ","we will go have dinner there (up the coast, not visible) at the Iḷisaġvik College","edna-269.mp3"],
["⎡°qam⎤","adv","ter","qamuŋa tupimnun isikki ","take them in there (inside, not visible)","edna-270.mp3"],
["⎡°sam²⎤","adv","ter","samuŋautikki ","take them there (down, not visible)","edna-271.mp3"],
["⎡°sam¹⎤","adv","ter","samuŋa niġġivium ataanun irikki","hide them there (under here, not visible) under the table","edna-272.mp3"],
["⎡°sakim⎤","adv","ter","saŋmuŋa anirruŋ ","take it out there (to cold entry, not visible)","edna-273.mp3"],
["⎡°un⎤","adv","ter","unuŋa uniññagu qayaq ","don't leave the kayak there (down on the beach, visible)","edna-274.mp3"],
["⎡°uv⎤","adv","ter","uvuŋa iḷḷiuŋ ","put it right here (restricted, visible)","edna-275.mp3"],
["⎡°ag⎤","adv","2abl","aaŋŋamiñ tikisaaqtuat ","they arrived enmasse from that (area across an expanse of water or lands) area","edna-276.mp3"],
["⎡°am⎤","adv","2abl","amaŋŋamiñ naiñaqtuq","the smell is detectable coming from there (next room, not visible)","edna-277.mp3"],
["⎡°av⎤","adv","2abl","avaŋŋamiñ imma avaalaruq","the hollering is coming from there (over there, towards west, visible, extended)","edna-278.mp3"],
["⎡°akim⎤","adv","2abl","aŋmaŋŋamiñguuq tatpakkua tiŋŋutit tiŋiruat","it is reported that those (up there, visible, in motion) airplanes took off from that (area across furthest, not visible)","edna-279.mp3"],
["⎡°Ik⎤","adv","2abl","ikaŋŋamiñ qaiñiaqtut","they will be coming from that direction","edna-280.mp3"],
["⎡°kan⎤","adv","2abl","kanaŋŋamiñ imma naiñaqtuq","the smell is coming from there (down there, by the door, restricted)","edna-281.mp3"],
["⎡°kIg⎤","adv","2abl","kiaŋŋamiñ imma nipattuq","the noise is coming from out there (visible, restricted)","edna-282.mp3"],
["⎡°kIv⎤","adv","2abl","kivaŋŋamiñ napaqsramiñ isagutiruat ","they began from there (up coast towards, restricted, visible) from that pole.","edna-283.mp3"],
["⎡°pag⎤","adv","2abl","paaŋŋamiñ imma tusaġnaqtuq","it is audible from there (inland, extended, visible)","edna-284.mp3"],
["⎡°pakim⎤","adv","2abl","paŋmaŋŋamiñ imma ququularuq","the shouting is coming from there (on roof, not visible)","edna-285.mp3"],
["⎡°pag⎤","adv","2abl","paaŋŋamiñ tikisaaqtuat","they arrived from that direction (inland, extended, visible)","edna-286.mp3"],
["⎡°pIk⎤","adv","2abl","pikaŋŋamiñ imma iglumiñ tusaġnaqtuq qaaqtuaq","the explosion is audible specifically from there (inland, or upriver, restricted, visible) from the house","edna-287.mp3"],
["⎡°qag⎤","adv","2abl","qaaŋŋamiñ imma atuqtuat tusaġnaqtut ","the singing of the carollers can be heard coming from out there (outside, not visible) ","edna-288.mp3"],
["⎡°qakim⎤","adv","2abl","qaŋmaŋŋamiñ imma mapqaqtuaq tusaġnaqtuq","the explosion is audible specifically coming from there (in shed, not visible)","edna-289.mp3"],
["⎡°qav⎤","adv","2abl","qavaŋŋamiñ uniaġaqtuat qiñiġnaqsirut ","the dog teams are now visible there (extended, visible, up coast east)","edna-290.mp3"],
["⎡°qam⎤","adv","2abl","qamaŋŋamiñ saqłautiruat tusaġnapaluktut ","the shouting at each other is very audible coming from there (inside, not visible)","edna-291.mp3"],
["⎡°sam²⎤","adv","2abl","samaŋŋamñ imma igniqugaurat tusaġnaqtut ","the engines are audible from there (on ocean, not visible)","edna-292.mp3"],
["⎡°sam¹⎤","adv","2abl","samaŋŋamiñ imma nipattuq","the sound is coming from under here (not visible)","edna-293.mp3"],
["⎡°sakim⎤","adv","2abl","saŋmaŋŋamiñ samma uqaqtuak tusaġnaqtuk ","the talking is audible from there (cold entryway, not visible)","edna-294.mp3"],
["⎡°un⎤","adv","2abl","uŋaŋŋamiñ nipauqłuktaun tusaġnaqtuq ","the horn from there (on the ocean, visible, extended) is audible ","edna-295.mp3"],
["⎡°ag⎤","adv","2ter","auŋanmun pisuaqtuq sukaisuuraamik ","she is walking slowly over there (across a barrier/lagoon, visible) ","edna-296.mp3"],
["⎡°am⎤","adv","2ter","amuŋanmun saquruaq","she headed to there (next room, not visible)","edna-297.mp3"],
["⎡°av⎤","adv","2ter","avuŋanmun igliqtut","they are traveling over towards that direction (i.e. towards the west, visible, extended)","edna-298.mp3"],
["⎡°akim⎤","adv","2ter","aŋmuŋanmun Isreal-mun umiaqpait aŋuyait isagutirut","the warships are heading over there (across a barrier, furthest, not visible) towards Isreal","edna-299.mp3"],
["⎡°Ik⎤","adv","2ter","ikuŋanmun iḷḷiuŋ ","place it facing towards there (over there, visible, restricted)","edna-300.mp3"],
["⎡°kan⎤","adv","2ter","kanuŋanmun saatirruuŋ ","make it face there (towards door, visible, restricted)","edna-301.mp3"],
["⎡°kIg⎤","adv","2ter","kiuŋanmun qiviaġiñ ","look out there (restricted, visible, outside)","edna-302.mp3"],
["⎡°kIv⎤","adv","2ter","kivuŋanmun aqpanniaqtut tikiḷḷugu kuugauraq","they will run to up there (up the coast, towards east, visible) reaching the creek","edna-303.mp3"],
["⎡°mar⎤","adv","2ter","mauŋanmun saanŋakpan nakuułhaaqtuq","it will be better if it's facing towards here (extended, visible)","edna-304.mp3"],
["⎡°pag⎤","adv","2ter","pauŋanmun pisuaqtuq tuŋaaġivlugu avilaitqatini","he is walking to up there (inland, extended, visible) to where his friend is","edna-305.mp3"],
["⎡°pam⎤","adv","2ter","pamuŋanmun tikkuaqtuaq","he pointed towards up there (upstairs, not visible)","edna-306.mp3"],
["⎡°pakim⎤","adv","2ter","paŋmuŋanmun qiḷagmun mitchaaġiaqtuq helicopter","the helicopter is going up there (furthest level in structure, not visible) to land on the roof","edna-307.mp3"],
["⎡°pIk⎤","adv","2ter","pikuŋanmun aqpattin aasii uyaġaum tunuanun iriġutin ","run towards up there (visible, restricted, upriver) and hide behind the rock","edna-308.mp3"],
["⎡°qag⎤","adv","2ter","qauŋanmun miḷuqsauttuuŋ","throw it there (outside, visible, extended)","edna-309.mp3"],
["⎡°qakim⎤","adv","2ter","qaŋmuŋanmun isagutiruq","he is walking towards there (outside, towards shed, visible)","edna-310.mp3"],
["⎡°qav⎤","adv","2ter","qavuŋanmun pisuaqtuaġniaqtuguk ","we two will walk towards there (up the coast towards east, visible, extended)","edna-311.mp3"],
["⎡°qam⎤","adv","2ter","qamuŋanmun puyuuq isiqtinniaġaat","they will let the smoke drift into there (inside, not visible)","edna-312.mp3"],
["⎡°sam²⎤","adv","2ter","samuŋanmun isagutirut ","they are proceeding directly way out there (as far as they can into the ocean)","edna-313.mp3"],
["⎡°sam¹⎤","adv","2ter","samuŋanmun kataktirruŋ taaptumuuna putukun","let it drop down under here through that hole (under here, not visible)","edna-314.mp3"],
["⎡°sakim⎤","adv","2ter","saŋmuŋanmun titiġuŋ natiq maliġiyumagaa","mark the floor towards there (into the cold entryway) so he can follow it ","edna-315.mp3"],
["⎡°un⎤","adv","2ter","unuŋanmun pisuarraqsirut ","they are proceeding towards there (towards ocean, visible, extended)","edna-316.mp3"],
["⎡°uv⎤","adv","2ter","uvuŋanmun uvamnun saqutirruŋ ","turn it here, to me (restricted, visible)","edna-317.mp3"],
]

// let dictionary = [];
// var dictionary_dict = {};
let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"
let customFontFam2 = {fontFamily:"Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"}
let letterbutton = {paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}

// let usagedictionary = [];
// var usagedictionary_dict = {};

// let audiolibrary = [];
// var audiolibrary_dict = {};
// var dictionary = [{"1":{"descriptor":["verb"],"properties":["no_usage"]},"english":["to hurry excitedly","to run"],"yupik":"taave-"},{"1":{"descriptor":["noun"],"properties":[]},"english":"decoration at the crown of the hood of a young woman's traditional Yup'ik parka that consists of strands of red, black, and, white beads or strips of calfskin","yupik":"kakauyaq"},{"1":{"descriptor":["noun"],"properties":[]},"english":"fog","yupik":"umta"},{"1":{"descriptor":["verb"],"properties":["no_usage"]},"english":"to go bankrupt","yupik":"nangqerte-"},{"1":{"descriptor":["noun"],"properties":[]},"english":"tangle remover; comb with widely spaced teeth","yupik":"tegurrliurun"},]

// var dictionary = {
//   "taave-": {
//   	"definition": "testing 3", 
//     "children": 
//     {
// 	    "taavkaa-": {
// 	      "definition": "to associated with in some way", 
// 	    },
// 	    "taavkii-": {
// 	      "definition": "to associate ", 
// 	    },
//     },
//   }, 
//   "natke-": {
//   	"definition": "testing 3", 
//     "children": 
//     {
// 	    "natkaa-": {
// 	      "definition": "to walk", 
// 	    },
//     },
//   }, 

// };



class Home extends Component {
	constructor(props) {
    // console.log(props)
		super(props);
		this.state = {
			search: props.location.state === undefined ? '' : props.location.state.history[0],
			wordsList: props.location.state === undefined ? [] : props.location.state.history[1],
      prevSearch: [],
      prevWordsList: [],
      randomSentenceIndex: 0,
      randomSentences: demonstrativeExampleSentences.sort((a, b) => 0.5 - Math.random()),
			// dictionary: props.location.state === undefined ? [] : props.location.state.dictionary,
      // dictionary_dict: props.location.state === undefined ? [] : props.location.state.dictionary_dict,
      // usagedictionary: props.location.state === undefined ? [] : props.location.state.usagedictionary,
      // usagedictionary_dict: props.location.state === undefined ? [] : props.location.state.usagedictionary_dict,
			// audiolibrary: props.location.state === undefined ? [] : props.location.state.audiolibrary,

      activeTabIndex: props.location.state === undefined ? 0 : props.location.state.history[3],
      // history: props.location.state === undefined ? [] : props.location.state.history,
      homeMode: props.location.state === undefined ? 0 : props.location.state.history[3],
      activeAudioIndex: {},
      tabValue: props.location.state === undefined ? 0 : props.location.state.history[3],
      playingAudio: false,
      // theposition: 0,
		}
    // console.log('new')
		this.handleRef = createRef()
		// this.handleRef2 = createRef()
    if (props.location.state !== undefined) {
      // console.log('hi',props.location.state.history[2])
      scroll.scrollTo(props.location.state.history[2]);
    }
	}

	// componentDidMount() {
	// 	// dictionary.forEach(entry => {dictionary_dict[entry.yupik] = entry.english})
 //    // console.log('hi',dictionary.length)
	// 	// for (const [key, value] of Object.entries(dictionary)) {
	// 	//   dictionary_dict[key] = value.definition;
	// 	// }
    
 //    window.addEventListener('scroll', this.listenToScroll)


 //    if (dictionary.length === 0) {
 //      axios
 //        .get(API_URL + "/inupiaqwordslist/all")
 //        .then(response => {
 //          // let end = now();
 //          // ReactGA.timing({
 //          //   category: 'Loading',
 //          //   variable: 'dictionary',
 //          //   value: (end-start).toFixed(3),
 //          //   label: 'Dictionary loading'
 //          // });
 //          dictionary = response.data;
 //          // fuse.setCollection(dictionary);
 //          // fuse1.setCollection(dictionary);
 //          console.log('Fetched dictionary');

 //          // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
 //          // dictionary_prepared = fuzzysort.prepare(dictionary)

 //          this.setState({ dictionary: dictionary });
 //        });
 //    }
 //    else {
 //      // fuse.setCollection(dictionary);
 //      this.setState({ dictionary: dictionary });
 //    }

 //    if (usagedictionary.length === 0) {
 //      axios
 //        .get(API_URL + "/inupiaqsearchableusagelist/all")
 //        .then(response => {
 //          // let end = now();
 //          // ReactGA.timing({
 //          //   category: 'Loading',
 //          //   variable: 'dictionary',
 //          //   value: (end-start).toFixed(3),
 //          //   label: 'Dictionary loading'
 //          // });
 //          usagedictionary = response.data;
 //          // fuse.setCollection(dictionary);
 //          // fuse1.setCollection(dictionary);
 //          console.log('Fetched usage dictionary');
 //          // console.log(usagedictionary);

 //          usagedictionary.forEach(entry => usagedictionary_dict[entry.url] = entry) // create dictionary_dict dictionary
 //          // dictionary_prepared = fuzzysort.prepare(dictionary)
 //          // console.log(usagedictionary_dict)
 //          this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });
 //        });
 //    }
 //    else {
 //      // fuse.setCollection(dictionary);
 //      this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });
 //    }

 //    if (audiolibrary.length === 0) {
 //      axios
 //        .get(API_URL + "/inupiaqaudiolibrary/all")
 //        .then(response => {
 //          // let end = now();
 //          // ReactGA.timing({
 //          //   category: 'Loading',
 //          //   variable: 'dictionary',
 //          //   value: (end-start).toFixed(3),
 //          //   label: 'Dictionary loading'
 //          // });
 //          audiolibrary = response.data;
 //          // fuse.setCollection(dictionary);
 //          // fuse1.setCollection(dictionary);
 //          console.log('Fetched AudioLibrary');

 //          // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
 //          // dictionary_prepared = fuzzysort.prepare(dictionary)

 //          this.setState({ audiolibrary: audiolibrary });
 //        });
 //    }
 //    else {
 //      // fuse.setCollection(dictionary);
 //      this.setState({ audiolibrary: audiolibrary });
 //    }

	// }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    // const height =
      // document.documentElement.scrollHeight -
      // document.documentElement.clientHeight

    // const scrolled = winScroll / height

    // console.log(winScroll, height)

    this.setState({
      Yposition: winScroll,
    })
  }

  setHistory = (num) => {
    this.setState({
      prevSearch:num[0],
      prevWordsList:num[1],
    })
  }

  resetAll = (e,data) => {
  	this.setState({
  		search:"",
      // newSearchList:[],
      searchWord:"",
  	})
  }



		returnTitle = (word) => {
			let newword = word.split(' | ');
			let fulllist = [];
			for (let i = 0; i < newword.length; i++) {
				if (newword[i].includes('(‐)')) {
					fulllist.push(newword[i].split('(‐)')[0])
					fulllist.push(newword[i].split('(‐)')[0]+'‐')
				} else {
					fulllist.push(newword[i])
				}
			}
			return fulllist
		}

		returnDefinition = (word) => {
			let newword = word.split(' | ')
			return newword
		}
  	onChangeSearch = (bool,homeMode,event,data) => {
      // console.log(bool,homeMode, event,data)
      let word

      const optionsFuzzy = {
        keys: ['keyStringNormal','fullDefinitionNormalized'],
        limit: 50, // don't return more results than you need!
        threshold: -100, // don't return bad results
        scoreFn: a => {
          // console.log(a)
          let a0score = -1000
          let a1score = -1000
          if (a[0]) {
            a0score = a[0].score
            if (a.obj.keySplit[0][2].includes("Grammar Year 1")) {
              a0score = a0score+30
            }
            if (a.obj.keyStringNormal.includes(this.state.search)) {
              a0score = a0score+100
            }
          }
          if (a[1]) {
            a1score = a[1].score
            if (a.obj.keySplit[0][2].includes("Grammar Year 1")) {
              a1score = a1score+30
            }
            if (a.obj.fullDefinitionNormalized.includes(this.state.search)) {
              a1score = a1score+100
            }
          }
          return Math.max(a0score, a1score)
          // return Math.max(a[0] ? (a.obj.keySplit[0][2].includes("Grammar Year 1") || a.obj.keyStringNormal.includes(this.state.search) ? (a.obj.keyStringNormal.includes(this.state.search) ? a[0].score+100 : a[0].score+10) : a[0].score) : -1000, 
                          // a[1] ? (a.obj.keySplit[0][2].includes("Grammar Year 1") || a.obj.fullDefinitionNormalized.includes(this.state.search) ? (a.obj.fullDefinitionNormalized.includes(this.state.search) ? a[1].score+100 : a[1].score+10) : a[1].score) : -1000)
        }
      };


      if (bool) {
        word = data
      } else {
        word = data.value
        homeMode = this.state.homeMode
      }

	    let new_search, wordsList
      word = word.replaceAll("’","'").replaceAll("ḷ","ḷ").replaceAll("ł̣","ł̣").replaceAll("G","ġ").replaceAll("g.","ġ").replaceAll("l.","ḷ").replaceAll("L","ł").replaceAll("ł.","ł̣");
      new_search = word.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',','')

      // console.log(word, new_search)
	    if (homeMode === 1) {
	    	wordsList = fuzzysort.go(new_search, this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj));
	    } else if (homeMode === 0) {
        console.log(fuzzysort.go(new_search, this.props.dictionary, optionsFuzzy))
	    	wordsList = fuzzysort.go(new_search, this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj));
	    } else if (homeMode === 2) {
	    	wordsList = fuzzysort.go(new_search, this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj));
	    }


	    this.setState({ wordsList: wordsList, search: word,  activeAudioIndex: {} });
  	}

  	processStyledText = (sentence) => {
      // sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("«","").replaceAll("»","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
  	}


    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }

  	processUsageDefinition = (definition, tag, ind) => {
  		// console.log(definition)
			let matches = definition.match(/<.*?>/g)

			if (matches !== null) {
				matches.map((m) => definition = definition.replaceAll(m,"<i>"+m.slice(1,-1)+'</i>'))				
			}
			let matches1 = definition.match(/⎡.*?⎤/g)
			if (matches1 !== null) {
				matches1.map((m) => definition = definition.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))				
			}

      let matches2 = definition.match(/_.*?_/g)
      if (matches2 !== null) {
        matches2.map((m) => definition = definition.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }

      let matches3 = definition.match(/{.*?}/g)
      if (matches3 !== null) {
        matches3.map((m) => definition = definition.replaceAll(m,m.slice(1,-1)))            
      }

			if (tag !== 'noun') {
				definition = '<i>s/he/it is </i>'+definition
			}

			definition = definition.replaceAll("⟨","").replaceAll("⟩","")
			// } else {
			// 	definition = '<i>the one </i>'+definition
			// }
			// console.log(sentence)
			// var dom = document.createElement('span');
			// dom.innerHTML = sentence;
  		return <span dangerouslySetInnerHTML={{__html: definition}} />
  	}		


  	processAudioDefinition = (sentence) => {			
      // console.log(sentence)
			let matches = sentence.match(/⎡.*?⎤/g)
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))				
			}
			sentence = sentence.replaceAll('>','')
			// console.log(sentence)
			// var dom = document.createElement('span');
			// dom.innerHTML = sentence;
  		return <span dangerouslySetInnerHTML={{__html: sentence}} />
  	}


  repeatAudio(audio, event, data) {
    // console.log(audio)
    // var a = new Audio(API_URL+'static/WoW-Link.mp3');
    // a.play();
    // let a;
    // axios
    //   .get(API_URL + "/audiolibrary/" + audio.mp3)
    //   .then(response => {
    //     // let end = now();
    //     // ReactGA.timing({
    //     //   category: 'Loading',
    //     //   variable: 'dictionary',
    //     //   value: (end-start).toFixed(3),
    //     //   label: 'Dictionary loading'
    //     // });
    //     var a = new Audio(response.data);
    //     console.log(a)
    //     a.play();
    //     // fuse.setCollection(dictionary);
    //     // fuse1.setCollection(dictionary);
    //     // console.log('Fetched dictionary');

    //     // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
    //     // // dictionary_prepared = fuzzysort.prepare(dictionary)

    //     // this.setState({ dictionary: dictionary });
    //   });
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replaceAll('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }

  // soundDone = () => {
  //   console.log('done!')
  // }

  setActiveAudioIndex = (word, index) => {
    // console.log(word, index)
    let activeAudioIndex = this.state.activeAudioIndex
    if (index in activeAudioIndex) {
      delete activeAudioIndex[index]
      this.setState({activeAudioIndex:activeAudioIndex})
    } else {
      // activeAudioIndex.push(index)
      // console.log(word)


      axios
        .get(API_URL + "/inupiaqaudioentry/" + word.key)
        .then(response => {
          // let end = now();
          // ReactGA.timing({
          //   category: 'Loading',
          //   variable: 'dictionary',
          //   value: (end-start).toFixed(3),
          //   label: 'Dictionary loading'
          // });
          // audiolibrary = response.data;
          // fuse.setCollection(dictionary);
          // fuse1.setCollection(dictionary);
          // console.log(response.data);
          activeAudioIndex[index] = response.data
          this.setState({activeAudioIndex:activeAudioIndex})

          // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
          // dictionary_prepared = fuzzysort.prepare(dictionary)

          // this.setState({ audiolibrary: audiolibrary });
        });


    }
    // console.log(activeAudioIndex)
    // this.setState({activeAudioIndex:activeAudioIndex})
  }


  handleTabChange = (e,data) => {
    // console.log(data.activeIndex, this.state.activeIndex)
    if (data.activeIndex == this.state.activeTabIndex) {
      this.resetAll()
    }

    if (data.activeIndex === 0 && this.state.activeTabIndex !== 0) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:0})
      this.onChangeSearch(true, 0, null,this.state.search)
    } else if (data.activeIndex === 1 && this.state.activeTabIndex !== 1) {
      // if (!this.state.yugtunAnalyzer) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:1});                     
      this.onChangeSearch(true, 1, null,this.state.search)
      // this.inputClicked()
      // }
    } else if (data.activeIndex === 2 && this.state.activeTabIndex !== 2) {
      // if (!this.state.yugtunAnalyzer) {
      this.setState({wordsList: [], homeMode: data.activeIndex, activeTabIndex:2});                     
      this.onChangeSearch(true, 2, null,this.state.search)
      // this.inputClicked()
      // }
    }
  }



  handleTabChange2 = (e, {value}) => {

    if (value == this.state.tabValue) {
      this.resetAll()
    }

    if (value === 0 && this.state.tabValue !== 0) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:0})
      this.onChangeSearch(true, 0, null,this.state.search)
    } else if (value === 1 && this.state.tabValue !== 1) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:1});                     
      this.onChangeSearch(true, 1, null,this.state.search)
    } else if (value === 2 && this.state.tabValue !== 2) {
      this.setState({wordsList: [], homeMode: value, activeTabIndex:2});                     
      this.onChangeSearch(true, 2, null,this.state.search)
    }
  	this.setState({tabValue: value})
  }

  randomizeSentence = (direction) => {
    if (direction === '+') {
      if (this.state.randomSentences.length-1 === this.state.randomSentenceIndex) {
        this.setState({randomSentenceIndex: 0})        
      } else {
        this.setState({randomSentenceIndex: this.state.randomSentenceIndex+1})        
      }
    } else {
      this.setState({randomSentenceIndex: this.state.randomSentenceIndex-1})
    }
  }

  newUsageBaseDisplay = (word) => {
    // console.log(word)

    let returnNewUsageBaseDisplayLabel = {
      'verb phrase':[
        'present',
        'past',
        'question',
        'polite command',
        'command',
      ],
      'noun':[
        'stem',
        'possessive',
        'locative noun case',
        'with pronoun',
      ],
    }
    return  <List.Item>
              <List.Content style={{fontSize:'16px',paddingTop:'8px',paddingBottom:'10px'}}><span style={{paddingRight:'20px',fontWeight:'500'}}>{word.url.replaceAll(',',', ')}</span><span style={{paddingRight:'15px',fontStyle:'italic'}}>{this.processStyledText(word.thisDefinition)}</span><span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}><TagColors tags={word.t} width={window.innerWidth} /></span></List.Content>
              {word.newUsageDefinitions.map((u,uindex)=>
              <List.Content style={{paddingLeft:'20px'}}>
                <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex], usagedictionary_dict: this.props.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'3px', color:'#8f8f8f'}} size='small' name='chevron right' />
                  </List.Content>
                </Link>
                <List.Content style={{display:'flex',fontSize:'16px',paddingBottom:'8px',fontFamily:customFontFam,lineHeight:'25px'}}>
                  <span style={{flex:2,'paddingRight':'3px',fontWeight:'500'}}>
                  <Link style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[uindex], activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                  {this.processStyledTextLink(word.usageWords[uindex])}
                  </Link>
                  </span>
                  <span style={{flex:5,fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{word.newUsageDefinitions[uindex]}</span>
                  <span style={{flex:3,color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
                </List.Content>
              </List.Content>
              )}
            </List.Item>

    // return <List.Item>
    // <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[0], activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
    //   <List.Content floated='right'>
    //     <Icon circular style={{marginTop:'8px', color:'#8f8f8f'}} size='large' name='chevron right' />
    //   </List.Content>
    // </Link>
    //   <List.Content>
    //     <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
    //       <div> 
    //       <span style={{'paddingRight':'3px',fontWeight:'500'}}>
    //       <Link style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:word.backendCalls[0], activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
    //       {this.processStyledText(word.usageWord)}
    //       </Link>
    //       </span>
    //       <span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}>  
    //         <TagColors tags={word.t} width={window.innerWidth} />
    //       </span>
    //       </div>
    //     </List.Header>
    //     <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processUsageDefinition(word.usageDefinition,word.t,i)}</List.Description>
    //   </List.Content>
    // </List.Item>
  }

	render() {
		let isCommon = false
    let displayList = this.state.search.length >= 2 && this.state.wordsList.length > 0;
    let wordsList = this.state.wordsList;
    let emptyList = this.state.search.length >= 2 && this.state.wordsList.length === 0;
    let ranDem = this.state.randomSentences[this.state.randomSentenceIndex]
    // console.log(this.state)

  const panes = [
    {
      menuItem: (
      	<Menu.Item style={customFontFam2}>
      	<div><span >Uqaluit Sivuniŋit</span> <br /> <div style={{paddingTop:'5px',fontWeight:'400',fontSize:'16px'}}>Dictionary</div></div>
      	</Menu.Item>
      	),
    },
    {
      menuItem: (
      	<Menu.Item style={customFontFam2}>
      	<div><span >Uqaluliuġun</span> <br /> <div style={{paddingTop:'5px',fontWeight:'400',fontSize:'16px'}}>Word Builder</div></div>
      	</Menu.Item>
      	),
    },
    {
      menuItem: (
      	<Menu.Item style={customFontFam2}>
      	<div><span >Uqallagniġit</span> <br /> <div style={{paddingTop:'5px',fontWeight:'400',fontSize:'16px'}}>Audio Library</div></div>
      	</Menu.Item>
      	),
    },
    ];

		return (
			<div style={{fontFamily:customFontFam}}>
      <Grid textAlign='center' style={{ height: window.innerHeight/1.5 }} verticalAlign={this.state.searchBarStuckTop ? 'top' : 'top'}>
      <Grid.Row style={{paddingTop:25}}>
      <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>

        	<Grid>
        	{window.innerWidth < 480 ?
      		<Grid.Row columns={2}>
        	<Grid.Column width={6}>
          <Image onClick={this.resetAll} style={{'fontSize': '2.3em',cursor:'pointer'}} src="/frontinupiaqlogo.png"/>          
        	</Grid.Column>
	        	<Grid.Column style={{display:'flex',alignItems:'center'}} width={10}>
	        	<div style={{lineHeight:'25px'}}>
	          <div onClick={this.resetAll} style={{ color: 'black', fontSize:'15pt', fontWeight:'600', cursor:'pointer'}}>Iñupiatun Uqaluit Taniktun Sivuniŋit</div>
	          <div onClick={this.resetAll} style={{ color: 'black',fontSize:'15pt',cursor:'pointer', fontWeight:'300'}}>North Slope Iñupiaq to English Dictionary</div>
	          </div>
	        	</Grid.Column>
        	</Grid.Row>
        		:
      		<Grid.Row columns={2}>
        	<Grid.Column width={4}>
          <Image onClick={this.resetAll} style={{'fontSize': '2.3em',cursor:'pointer'}} src={"/frontinupiaqlogo.png"}/>          
        	</Grid.Column>
	        	<Grid.Column style={{display:'flex',alignItems:'center'}} width={12}>
	        	<div style={{lineHeight:'30px'}}>
	          <div onClick={this.resetAll} style={{ color: 'black', fontSize:'17pt', fontWeight:'600', cursor:'pointer'}}>Iñupiatun Uqaluit Taniktun Sivuniŋit</div>
	          <div onClick={this.resetAll} style={{ color: 'black',fontSize:'17pt',cursor:'pointer', fontWeight:'300'}}>North Slope Iñupiaq to English Dictionary</div>
	          </div>
	        	</Grid.Column>
        	</Grid.Row>
        	}
        	</Grid>


        <Container ref={this.search_container} className='search_container'>
					<Grid stackable>
					{window.innerWidth < 480 ?
						<Dropdown className='frontpage' value={this.state.tabValue} onChange={this.handleTabChange2} options={tabOptions} />
						:
          	<Tab style={{paddingTop:10}} activeIndex={this.state.activeTabIndex} menu={{ secondary: true, pointing: true, size:'huge' }} panes={panes} onTabChange={this.handleTabChange} />
          }


			{this.state.homeMode === 0 ? 
					<Grid.Row>
              <Grid.Column style={{ flex: 1, paddingTop:0 }}>
					<div className='header-text'>
		              	<Input
		                ref={this.handleRef}
		                placeholder='Ivaġuŋ uqaluk... Search a word...'
		                // action={{ icon: (this.state.search > 0 ? 'x': null), transparent:true,size:'huge', onClick: () => {this.setState({search:''})}}}
		                icon={this.state.search.length > 0 ? <Icon name='x' color='grey' onClick={()=>{this.setState({search:''})}} link /> : null}
		                // iconPosition='right'
		                size='huge'
		                onChange={this.onChangeSearch.bind(this,false,0)}
                    spellcheck="false"
		                value={this.state.search}
                    id='searchinput'
		                // onKeyPress={this.onKeyPress}
		                fluid>

		                </Input> 
					</div>

				  <div style={{textAlign:'center',marginTop:'3px'}}>
				    <Button.Group basic>
              <Button onClick={()=>{this.onChangeSearch(true,0,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ġ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ġ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,0,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ḷ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ḷ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,0,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ł'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ł</Button>
              <Button onClick={()=>{this.onChangeSearch(true,0,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ł̣'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ł̣</Button>
              <Button onClick={()=>{this.onChangeSearch(true,0,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ñ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ñ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,0,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ŋ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ŋ</Button>
				    </Button.Group>
				  </div>
					</Grid.Column>
				</Grid.Row>
				:
				null
			}


        {this.state.homeMode === 1 ? 
          <Grid.Row>
              <Grid.Column style={{ flex: 1, paddingTop:0 }}>
          <div className='header-text'>
                    <Input
                    ref={this.handleRef}
                    placeholder='Ivaġuŋ uqaluk... Search a word...'
                    // action={{ icon: (this.state.wordBuilderMode ? 'search' : null), transparent:true,size:'huge', onClick: () => 
                    // {this.state.wordBuilderMode ? this.inputClicked() : null}
                    // }}
                    icon={this.state.search.length > 0 ? <Icon name='x' color='grey' onClick={()=>{this.setState({search:''})}} link /> : null}
                    // icon={<Icon name='x' onClick={console.log('hi')} link />}
                    // iconPosition='right'
                    size='huge'
                    onChange={this.onChangeSearch.bind(this,false,1)}
                    value={this.state.search}
                    spellcheck="false"
                    id='searchinput'
                    // onKeyPress={this.onKeyPress}
                    fluid>

                    </Input> 
          </div>

          <div style={{textAlign:'center',marginTop:'3px'}}>
            <Button.Group basic>
              <Button onClick={()=>{this.onChangeSearch(true,1,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ġ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ġ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,1,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ḷ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ḷ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,1,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ł'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ł</Button>
              <Button onClick={()=>{this.onChangeSearch(true,1,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ł̣'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ł̣</Button>
              <Button onClick={()=>{this.onChangeSearch(true,1,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ñ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ñ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,1,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ŋ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ŋ</Button>
            </Button.Group>
          </div>

          </Grid.Column>
        </Grid.Row>
        :
        null
      }

			{this.state.homeMode === 2 ? 
            <Grid.Row >
              <Grid.Column style={{ flex: 1, paddingTop:0 }}>
					<div className='header-text'>
              <Input
                ref={this.handleRef}
                placeholder='Search a word...'
                // action={{ icon: (this.state.yugtunAnalyzer ? 'search' : null), transparent:true,size:'huge', onClick: () => 
                // {this.state.yugtunAnalyzer ? this.inputClicked() : null}
                // }}
                icon={this.state.search.length > 0 ? <Icon name='x' color='grey' onClick={()=>{this.setState({search:'',activeAudioIndex:{}})}} link /> : null}
                // icon={<Icon name='x' onClick={console.log('hi')} link />}
                // iconPosition='right'
                size='huge'
                spellcheck="false"
                onChange={this.onChangeSearch.bind(this,false,2)}
                value={this.state.search}
                id='searchinput'
                // onKeyPress={this.onKeyPress}
                fluid  />    
					</div>

				  <div style={{textAlign:'center',marginTop:'3px'}}>
				    <Button.Group basic>
              <Button onClick={()=>{this.onChangeSearch(true,2,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ġ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ġ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,2,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ḷ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ḷ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,2,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ł'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ł</Button>
              <Button onClick={()=>{this.onChangeSearch(true,2,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ł̣'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ł̣</Button>
              <Button onClick={()=>{this.onChangeSearch(true,2,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ñ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ñ</Button>
              <Button onClick={()=>{this.onChangeSearch(true,2,null,this.state.search.slice(0,document.getElementById('searchinput').selectionStart)+'ŋ'+this.state.search.slice(document.getElementById('searchinput').selectionStart)); this.handleRef.current.focus()}} style={letterbutton}>ŋ</Button>
				    </Button.Group>
				  </div>

              </Grid.Column>
            </Grid.Row>
				:
				null
			}


				</Grid>


          <Transition visible={displayList} animation='fade' duration={300}>

            <List divided>
              {displayList && this.state.homeMode === 0 ?
              	wordsList.map((word) => 
						    <List.Item key={word.url}>
						    <Link to={{pathname: '/' + word.url, state: { word: word.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
                </Link>
						      <List.Content>
						        <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
						          {word.keySplit.map((w,index) =>
						          		<div style={{display:'flex'}}> 
                          {/*{console.log(word)}*/}
                          <Link style={{color:'#306190'}} to={{pathname: '/' + word.url, state: { word: word.url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
						              <span style={{'paddingRight':'3px',fontWeight:'500',borderBottom:'1px solid #858585',paddingBottom:'1px',}}>
                          {this.processStyledTextLink(w[0])}
                          </span>
                          </Link>

						              {w[2] !== [""] ?
						                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  
                              {w[1][0] !== '' ?
                                <span style={{marginRight:'5px',cursor:'pointer'}}>
                                  <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
                                </span>
                                :
                                ''
                              }

															{w[2].map((tag) => 
																<span style={{marginRight:'5px',cursor:'pointer'}}>
																	<TagColors tags={tag} width={window.innerWidth} />
																</span>
																)}
						                </span>
						                :
						                ''
						              }
						              </div>
						            )}
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(word.fullDefinition)}</List.Description>
						      </List.Content>
						    </List.Item>
						    )
						    :
						    null
              	}

              	{displayList && this.state.homeMode === 1 ?
              	wordsList.map((word,i) => 
                <NewUsageBaseDisplay sentenceBuilder={false} currentProps={this.props} currentState={this.state} word={word} width={window.innerWidth} />
              	)
              	:
              	null
              }

              {displayList && this.state.homeMode === 2 ?
                wordsList.map((word, index) => 
                <List.Item style={{paddingTop:8,paddingBottom:8}}>
                	{0 in word ?
                  	<Button style={{float:'left', border:'solid 1px #dededf',backgroundColor:'white', color:(inupiaqSpeakerIDs[word[0][1]]) ,cursor:'pointer'}} circular icon='volume up' onClick={()=>this.repeatAudio(word[0][0])} />
                  	:
                  	null
                  }
                	{1 in word ?
                  	<Button style={{float:'left',border:'solid 1px #dededf',backgroundColor:'white',color:(inupiaqSpeakerIDs[word[1][1]]) ,cursor:'pointer'}} circular icon='volume up' onClick={()=>this.repeatAudio(word[1][0])} />
                  	:
                  	null
                  }
                	{2 in word ?
                  	<Button style={{float:'left', border:'solid 1px #dededf',backgroundColor:'white', color:(inupiaqSpeakerIDs[word[2][1]]) ,cursor:'pointer'}} circular icon='volume up' onClick={()=>this.repeatAudio(word[2][0])} />
                  	:
                  	null
                  }
                  <Button style={{float:'right', color:'#8F8F8F',cursor:'pointer'}} basic icon='info' onClick={()=>this.setActiveAudioIndex(word,index)} />
                  <List.Content>
                    <List.Header style={{width:'100%',display:'flex',fontFamily:customFontFam,lineHeight:'20px',fontSize:'16px',fontWeight:'500'}}>
                      {this.processAudioDefinition(word.i)}
                    </List.Header>
                    <List.Description style={{paddingTop:'2px',fontFamily:customFontFam,lineHeight:'20px',fontSize:'16px',fontWeight:'400'}}>{this.processAudioDefinition(word.e)}</List.Description>
                    {index in this.state.activeAudioIndex ?
                      <List.Description>
                        <div style={{paddingTop:'14px'}}>
                          {this.state.activeAudioIndex[index]['content']['clips'].map((entry,j)=>
                            <div>
                            {/*{console.log(inupiaqSpeakerIDs[entry['speaker']]])}*/}
                            {/*{console.log(entry['speaker'])}*/}
                            {/*{console.log(inupiaqSpeakerIDs[entry['speaker']])}*/}
                          	<div style={{fontSize:'16px', lineHeight:'21px',marginBottom:'10px',paddingLeft:'6px',borderLeft:'solid 2px '+ inupiaqSpeakerIDs[entry['speaker']]}}>
                          	<div>{'Speaker: '+entry['speaker']}</div>
                            <div>{'Dialect: '+entry['dialect']}</div>
                            <div>{'Village: '+entry['village']}</div>
                          	<div>{'Source: '+entry['source']}</div>
                          	</div>
                            </div>
                          	)}                      
                        </div>
                      </List.Description>
                      :
                      null
                    }
                    
                  </List.Content>
                </List.Item>
                )
              	:
              	null
              }
          </List>
          </Transition>

          <List>


         {!displayList ?
          (this.state.search.length === 0 ?
					<div style={{display:'flex'}}>
					<div style={{fontSize:'1.2rem',color:'#666',lineHeight:1.6,maxWidth:600}}>

          {this.state.homeMode === 0 ?
          <div>
          <div style={{paddingTop:'15px'}}> Here's a few example searches: </div>
          <List bulleted>
          <List.Item>
          <span>{'Iñupiaq nouns: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); scroll.scrollTo(0); this.setState({search:'nanuq',wordsList: fuzzysort.go('nanuq', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>nanuq</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'Nalukataq',wordsList: fuzzysort.go('Nalukataq', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>Nalukataq</span>         
          </List.Item>
          <List.Item>
          <span>{'English nouns: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'weather',wordsList: fuzzysort.go('weather', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>weather</span>         
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'morning',wordsList: fuzzysort.go('morning', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>morning</span>         
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'coffee',wordsList: fuzzysort.go('coffee', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>coffee</span>         
          </List.Item>
          <List.Item>
          <span>{'Iñupiaq verb stems: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'niġi',wordsList: fuzzysort.go('nigi', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>niġi-</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'atigi',wordsList: fuzzysort.go('atigi', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>atigi(-)</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'qitik',wordsList: fuzzysort.go('qitik', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>qitik-</span>
          </List.Item>
          <List.Item>
          <span>{'English "to be" phrases: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'to be happy',wordsList: fuzzysort.go('tobehappy', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>to be happy</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'hungry',wordsList: fuzzysort.go('hungry', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>hungry</span>       
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'walk',wordsList: fuzzysort.go('walk', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>walking</span>       
         </List.Item>
          <List.Item>
          <span>{'And more: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'°uv',wordsList: fuzzysort.go('°uv', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>°uv</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'good',wordsList: fuzzysort.go('good', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>good</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'aarigaa',wordsList: fuzzysort.go('aarigaa', this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>aarigaa</span>
          </List.Item>       
          </List> 
          <div style={{paddingTop:'15px'}}> Explore the appendices: </div>
          <List bulleted>
          <List.Item>
          <span>{'Names of Mammals: '}</span>
          <Link to={{pathname: '/appendix/23', state: {}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'Niġrutit Atiŋit'}</span>
          </Link>
          </List.Item>
          <List.Item>
          <span>{'Bowhead Whale Shares: '}</span>
          <Link to={{pathname: '/appendix/19', state: {}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'Aġviġmiñ Niŋit'}</span>
          </Link>
          </List.Item>
          <List.Item>
          <span>{'Kinship Terms: '}</span>
          <Link to={{pathname: '/appendix/5', state: {}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'Tuqłuġautit'}</span>
          </Link>
          </List.Item>
          <List.Item>
          <span>{'And more: '}</span>
          <Link to={{pathname: '/appendix', state: {}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'See all appendices'}</span>
          </Link>
          </List.Item>
          </List>

          <div style={{paddingTop:'15px'}}> Generate ending tables: </div>
          <List bulleted>
          <List.Item>
          <Link to={{pathname: '/endingtables', state: {empty:true}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'See Maŋŋuum Naggutai'}</span>
          </Link>
          </List.Item>
          </List>

          <div style={{paddingTop:'15px'}}> Learn about demonstratives: </div>
          <List bulleted>
          <List.Item>
          <span>{'Adverbs: '}</span>
          <Link to={{pathname: '/demonstratives/⎡°uv⎤', state: {demTableType:['adv','base']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'uvva'}</span>
          </Link>
          <span>{', '}</span>
          <Link to={{pathname: '/demonstratives/⎡°tavr⎤,⎡°taam⎤', state: {demTableType:['adv','base']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'tavra'}</span>
          </Link>
          <span>{', '}</span>
          <Link to={{pathname: '/demonstratives/⎡°pIk⎤', state: {demTableType:['adv','loc']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'pikani'}</span>
          </Link>
          <span>{', '}</span>
          <Link to={{pathname: '/demonstratives/⎡°Ik⎤', state: {demTableType:['adv','abl']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'ikaŋŋa'}</span>
          </Link>

          </List.Item>
          <List.Item>
          <span>{'Pronouns: '}</span>
          <Link to={{pathname: '/demonstratives/⎡°uv⎤', state: {demTableType:['pro','abs']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'una'}</span>
          </Link>
          <span>{', '}</span>
          <Link to={{pathname: '/demonstratives/⎡°uv⎤', state: {demTableType:['pro','rel']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'uuma'}</span>
          </Link>
          <span>{', '}</span>
          <Link to={{pathname: '/demonstratives/⎡°tavr⎤,⎡°taam⎤', state: {demTableType:['pro','abs']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'taamna'}</span>
          </Link>
          <span>{', '}</span>
          <Link to={{pathname: '/demonstratives/⎡°Ik⎤', state: {demTableType:['pro','mod']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'iktumiŋa'}</span>
          </Link>
          </List.Item>
          <List.Item>
          <span>{'Choose a random example to see more demonstrative usages: '}</span>
          </List.Item>
          <div style={{margin:'10px 0px'}}>
          <div style={{display:'flex',height:(window.innerWidth < 480 ? 150:120)}}>
            <div style={{flex:4,display:'flex',alignItems:'center',justifyContent:(window.innerWidth < 480 ? 'center':'center')}}>
              <Link to={{pathname: '/demonstratives/'+ranDem[0], state: {demTableType:[ranDem[1],ranDem[2]]}}}>
                <Button basic style={{borderRadius:'4rem'}} circular>
                  <div style={{width:(window.innerWidth < 480 ? '':'350px'),textAlign:'center',fontSize:(window.innerWidth < 480 ? '18px':'20px'),fontWeight:'400',lineHeight:'22px',padding:'0px 5px',marginBottom:'5px',fontFamily:customFontFam}}>
                    <span style={{color:'#585858'}}>{ranDem[3]}</span>
                  </div>
                  <div style={{width:(window.innerWidth < 480 ? '':'350px'),textAlign:'center',fontSize:(window.innerWidth < 480 ? '15px':'16.8px'),fontWeight:'400',marginBottom:'2px',lineHeight:'19px',fontFamily:customFontFam}}>
                    <span style={{color:'#666666'}}>{ranDem[4]}</span>
                  </div>
                </Button>          
              </Link>
            </div>
            {window.innerWidth < 480 ? 
              null
              :
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',flex:1}}>
                <Icon onClick={()=>this.repeatAudio(ranDem[5])} circular style={{boxShadow:'0 0 0 0.05em rgba(0,0,0,.15) inset', color:'#B1B1B1',cursor:'pointer',fontSize:'22px',marginRight:'6px',marginLeft:'4px',}} name='volume up' />
                <Icon onClick={()=>this.randomizeSentence('+')} circular style={{boxShadow:'0 0 0 0.05em rgba(0,0,0,.15) inset', color:'#B1B1B1',cursor:'pointer',fontSize:'22px'}} name='shuffle' />
              </div>
            }

          </div>
            {window.innerWidth < 480 ? 
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',flex:1}}>
                <Icon onClick={()=>this.repeatAudio(ranDem[5])} circular style={{boxShadow:'0 0 0 0.05em rgba(0,0,0,.15) inset', color:'#B1B1B1',cursor:'pointer',fontSize:'22px',marginRight:'6px',marginLeft:'4px',}} name='volume up' />
                <Icon onClick={()=>this.randomizeSentence('+')} circular style={{boxShadow:'0 0 0 0.05em rgba(0,0,0,.15) inset', color:'#B1B1B1',cursor:'pointer',fontSize:'22px'}} name='shuffle' />
              </div>
              :
              null
            }
          </div>
          <List.Item>
          <Link to={{pathname: '/demonstratives', state: {demTableType:['roots','']}}}>
              <span style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>{'See full demonstrative table'}</span>
          </Link>
          </List.Item>
          </List> 

          <div style={{paddingTop:'15px'}}> Commonly used stems: </div>
          <List bulleted>
          {Object.keys(this.props.usagedictionary_dict).length > 0 ?
            <List.Item>
              <div>Suraġaġniġit Maŋŋuŋit - Verb Stems</div>
              <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                  {popularBases.map((p)=>{
                    let entry = this.props.usagedictionary_dict[p[0]][p[1]]
                    return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:p[0],wordsList: fuzzysort.go(p[0].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',',''), this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:100}}>{entry.url.split(',')[0]}</div><div style={{flex:5}}>{this.processStyledText(entry.fullDefinition)}</div></div>
                  })}
                </div>
              </Segment>
            </List.Item>
            :
            null
          }
          {Object.keys(this.props.usagedictionary_dict).length > 0 ?
            <List.Item style={{paddingTop:10}}>
              <div>Atiqausit - Nouns</div>
              <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                  {popularNouns.map((p)=>{
                    let entry = this.props.usagedictionary_dict[p[0]][p[1]]
                    return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:p[0],wordsList: fuzzysort.go(p[0].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',',''), this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:100}}>{entry.url.split(',')[0]}</div><div style={{flex:5}}>{this.processStyledText(entry.thisDefinition)}</div></div>
                  })}
                </div>
              </Segment>
            </List.Item>
            :
            null
          }
          {Object.keys(this.props.usagedictionary_dict).length > 0 ?
            <List.Item style={{paddingTop:10}}>
              <div>Uqallagautit Tupaktuni, Qinnaliqsuni, naakka Anniqsuni - Exclamations of Surprise, Fear, or Injury</div>
              <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                  {popularExclamations.map((p,pind)=>{
                    return <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:p[0].replaceAll('.',''),wordsList: fuzzysort.go(p[0].replaceAll('.','').replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',',''), this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:100}}>{p[0].split('.').map((k,kind)=><div>{k}</div>)}</div><div style={{flex:5}}>{p[1]}</div></div>
                  })}
                </div>
              </Segment>
            </List.Item>
            :
            null
          }
          {Object.keys(this.props.usagedictionary_dict).length > 0 ?
            <List.Item style={{paddingTop:10}}>
              <div>Quviasuutikun Uqaluit - Celebration Terms</div>
              <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                  {popularCelebrations.map((p,pind)=>{
                    return <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:p[0].replaceAll('.',''),wordsList: fuzzysort.go(p[0].replaceAll('.','').replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',',''), this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:100}}>{p[0].split('.').map((k,kind)=><div>{k}</div>)}</div><div style={{flex:5}}>{p[1]}</div></div>
                  })}
                </div>
              </Segment>
            </List.Item>
            :
            null
          }
          {Object.keys(this.props.usagedictionary_dict).length > 0 ?
            <List.Item style={{paddingTop:10}}>
              <div>Su- Apiqqutit - Wh- Questions</div>
              <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                  {popularSu.map((p,pind)=>{
                    return <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:p[0].replaceAll('.',''),wordsList: fuzzysort.go(p[0].replaceAll('.','').replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',',''), this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:100}}>{p[0].split('.').map((k,kind)=><div>{k}</div>)}</div><div style={{flex:5}}>{p[1]}</div></div>
                  })}
                </div>
              </Segment>
            </List.Item>
            :
            null
          }
          </List>
					<div style={{textDecoration:'underline',marginBottom:10,marginTop:15,fontWeight:'500'}}>  <span> Uqaluit Sivuniŋit </span> </div>
					<div style={{marginBottom:10,fontWeight:'500'}}> Aglakkupku Taniktun uqaluk naakka Iñupiatun uqaluum maŋŋua samma arrigiit uqaluit sivuniŋiḷḷu puiñiaqtut inmiktun.</div>
					<div style={{textDecoration:'underline',marginBottom:10,marginTop:15}}>  <span> Dictionary </span> </div>
					<div style={{marginBottom:30}}> Type any English word or Iñupiaq base and the matching dictionary entries will show automatically.</div>
          </div>
          :
          null}
          {this.state.homeMode === 1 ?
					<div>

          <div style={{paddingTop:'15px'}}> Here's a few example searches: </div>
          <List bulleted>
          <List.Item>
          <span>{'Iñupiaq nouns: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'qamun',wordsList: fuzzysort.go('qamun', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>qamun</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'aaka',wordsList: fuzzysort.go('aaka', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>aaka</span>
          </List.Item>
          <List.Item>
          <span>{'English nouns: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'child',wordsList: fuzzysort.go('child', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>child</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'store',wordsList: fuzzysort.go('store', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>store</span>
          </List.Item>
          <List.Item>
          <span>{'English verb statements: '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'he is hungry',wordsList: fuzzysort.go('hungry', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>he is hungry</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'she is putting on a parka',wordsList: fuzzysort.go('puttingonaparka', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>she is putting on a parka</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'it is good',wordsList: fuzzysort.go('good', this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>it is good</span>
         </List.Item>
          <List.Item>
          <div>{'Common Iñupiaq verb phrases: '}</div>
          {false ?
            <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
              <div>
              {[...dailyActivities,...movement,...health,...weather,...hunting].map((k)=>{
                return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:k[0],wordsList: fuzzysort.go(k[1], this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:100}}>{k[0]}</div><div style={{flex:5}}>{k[2]}</div></div>
              })}
              </div>
            </Segment>
            :
            <div>
              <div style={{fontStyle:'italic',marginTop:10,marginLeft:10}}>{'Daily Activities'}</div>
              <Segment style={{maxHeight:220,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                {dailyActivities.map((k)=>{
                  return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:k[0],wordsList: fuzzysort.go(k[1], this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:115}}>{k[0]}</div><div style={{flex:5}}>{k[2]}</div></div>
                })}
                </div>
              </Segment>

              <div style={{fontStyle:'italic',marginTop:10,marginLeft:10}}>{'Movement'}</div>
              <Segment style={{maxHeight:350,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                {movement.map((k)=>{
                  return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:k[0],wordsList: fuzzysort.go(k[1], this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:115}}>{k[0]}</div><div style={{flex:5}}>{k[2]}</div></div>
                })}
                </div>
              </Segment>

              <div style={{fontStyle:'italic',marginTop:10,marginLeft:10}}>{'Health'}</div>
              <Segment style={{maxHeight:450,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                {health.map((k)=>{
                  return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:k[0],wordsList: fuzzysort.go(k[1], this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:115}}>{k[0]}</div><div style={{flex:5}}>{k[2]}</div></div>
                })}
                </div>
              </Segment>

              <div style={{fontStyle:'italic',marginTop:10,marginLeft:10}}>{'Weather'}</div>
              <Segment style={{maxHeight:350,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                {weather.map((k)=>{
                  return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:k[0],wordsList: fuzzysort.go(k[1], this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:115}}>{k[0]}</div><div style={{flex:5}}>{k[2]}</div></div>
                })}
                </div>
              </Segment>

              <div style={{fontStyle:'italic',marginTop:10,marginLeft:10}}>{'Hunting'}</div>
              <Segment style={{maxHeight:350,overflow: 'auto',fontSize:'16px',paddingTop:'9px',paddingBottom:'9px',marginTop:3}}>
                <div>
                {hunting.map((k)=>{
                  return <div style={{display:'flex',flexDirection:'row',margin:3}}><div onClick={()=>{scroll.scrollTo(0); this.setState({search:k[0],wordsList: fuzzysort.go(k[1], this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer',minWidth:115}}>{k[0]}</div><div style={{flex:5}}>{k[2]}</div></div>
                })}
                </div>
              </Segment>
            </div>
        }

          </List.Item>
      
          </List>

					<div style={{textDecoration:'underline',marginBottom:10,marginTop:15,fontWeight:'500'}}>  <span> Uqaluliuġun </span> </div>
					<div style={{marginBottom:10,fontWeight:'500'}}> Aglakkupku Taniktun uqaluk naakka Iñupiatun uqaluum maŋŋua samma arrigiit uqallautit sivuniŋiḷḷu puiñiaqtut inmiktun. </div>
					<div style={{textDecoration:'underline',marginBottom:10,marginTop:15}}>  <span> Word Builder </span> </div>
					<div style={{marginBottom:30}}> Type any English word or Iñupiaq base and the matching base case sentence will show automatically.</div>
					</div>
					:
					null
        	}
          {this.state.homeMode === 2 ?
					<div>

          <div style={{paddingTop:'15px'}}> Here's a few example searches: </div>
          <List bulleted>
          <List.Item>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'niġiruq',wordsList: fuzzysort.go('nigiruq',this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>niġiruq</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'uvlaaq',wordsList: fuzzysort.go('uvlaaq',this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>uvlaaq</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'learn',wordsList: fuzzysort.go('learn',this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>learn</span>
          <span>{', '}</span>
          <span onClick={()=>{scroll.scrollTo(0); this.setState({search:'walk',wordsList: fuzzysort.go('walk',this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj))})}} style={{textDecoration:'underline',color:'#4A80B5',cursor:'pointer'}}>walk</span>
          </List.Item>   
          </List>
					<div style={{textDecoration:'underline',marginBottom:10,marginTop:15,fontWeight:'500'}}>  <span> Uqallagniġit </span> </div>
					<div style={{marginBottom:10,fontWeight:'500'}}>  Taniktun naakka Iñupiatun uqaluk aglakkupku uqallagniŋa puiñiaqtuq. </div>
					<div style={{textDecoration:'underline',marginBottom:10,marginTop:15}}>  <span> Audio Library </span> </div>
					<div style={{marginBottom:30}}> Type any English word or Iñupiaq word and matching audio will display.</div>
					</div>
					:
					null
        	}
				</div>
				</div>
				:
				null
				)
          :
          null
}
      {displayList ? 
      	<div>
        <Divider />
        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:60,paddingBottom:16}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>
          {window.innerWidth < 480 ?
        <Grid>
  	        <Grid.Row>
  	        <Grid.Column style={{textAlign:'center'}}>
  	        	<span style={{fontWeight:'bold'}}>Dictionary Compiled by Edna Ahgeak MacLean. </span>
  	        	<span>Artwork by Ronald Senungetuk. Website production provided by <a href="https://yuarcuun.github.io" target="_blank" >Yuarcuun Technologies</a> in partnership with the Arctic Slope Community Foundation (<a href="https://www.arcticslopecommunity.org/" target="_blank">ASCF</a>). This website was made possible by Grant Number 90XN0110-01-00 from Administration for Native Americans.</span>
  	        </Grid.Column>
            </Grid.Row>
            <Grid.Row>
  	        <Grid.Column style={{textAlign:'center'}}>
  	        	Based on the 2014 publication by University of Alaska Press with the collaboration of the Alaska Native Language Center, University of Alaska Fairbanks, and the North Slope Borough Iñupiaq, History, Language, and Culture Commission (IHLC). FST revised after adapting from previous work by Aric Bills.
  	        </Grid.Column>
  	        </Grid.Row>
          <Grid.Row>
          <Grid.Column>
            <div style={{display:'flex',justifyContent:'center',marginBottom:'15px'}}> <a style={{textDecoration:'underline',color:'#000000de',textAlign:'center'}} href="https://youtu.be/_mjMpvGyiv0?t=24" target="_blank" ><span style={{fontWeight:'600'}}>Atchagat</span> <span>Iñupiaq Alphabet Video</span></a> </div>
            <div style={{display:'flex',justifyContent:'center'}}> <a style={{textDecoration:'underline',color:'#000000de',textAlign:'center'}} href="/Inupiaq-General-Introduction.pdf" target="_blank" ><span style={{fontWeight:'600'}}>Mannisaat Makpiġaat</span> <span>General Introduction</span></a> </div>
            <div style={{display:'flex',justifyContent:'center'}}> <a style={{textDecoration:'underline',color:'#000000de',textAlign:'center'}} href="/Inupiaq-Preface.pdf" target="_blank" ><span style={{fontWeight:'600'}}>Makpiġarriuqtit Quyanaaġutaat</span> <span>Acknowledgments</span></a> </div>
          </Grid.Column>
          </Grid.Row>
        </Grid>
            :
        <Grid>
            <Grid.Row columns={2}>
            <Grid.Column>
              <span style={{fontWeight:'bold'}}>Dictionary Compiled by Edna Ahgeak MacLean. </span>
              <span>Artwork by Ronald Senungetuk. Website production provided by <a href="https://yuarcuun.github.io" target="_blank" >Yuarcuun Technologies</a> in partnership with the Arctic Slope Community Foundation (<a href="https://www.arcticslopecommunity.org/" target="_blank">ASCF</a>). This website was made possible by Grant Number 90XN0110-01-00 from Administration for Native Americans.</span>
            </Grid.Column>
            <Grid.Column>
              Based on the 2014 publication by University of Alaska Press with the collaboration of the Alaska Native Language Center, University of Alaska Fairbanks, and the North Slope Borough Iñupiaq, History, Language, and Culture Commission (IHLC). FST revised after adapting from previous work by Aric Bills.
            </Grid.Column>
            </Grid.Row>
          <Grid.Row>
          <Grid.Column>
            <div style={{display:'flex',justifyContent:'center',marginBottom:'15px'}}> <a style={{textDecoration:'underline',color:'#000000de'}} href="https://youtu.be/_mjMpvGyiv0?t=24" target="_blank" ><span style={{fontWeight:'600'}}>Atchagat</span> <span>Iñupiaq Alphabet Video</span></a> </div>
            <div style={{display:'flex',justifyContent:'center'}}> <a style={{textDecoration:'underline',color:'#000000de'}} href="/Inupiaq-General-Introduction.pdf" target="_blank" ><span style={{fontWeight:'600'}}>Mannisaat Makpiġaat</span> <span>General Introduction</span></a> </div>
            <div style={{display:'flex',justifyContent:'center'}}> <a style={{textDecoration:'underline',color:'#000000de'}} href="/Inupiaq-Preface.pdf" target="_blank" ><span style={{fontWeight:'600'}}>Makpiġarriuqtit Quyanaaġutaat</span> <span>Acknowledgments</span></a> </div>
          </Grid.Column>
          </Grid.Row>
        </Grid>
          }

        </div>
        :
        null
      }
              {emptyList ? <p><i>No matches...</i></p> : ''}
            </List>






				</Container>
        </Grid.Column>
        </Grid.Row>
      </Grid>


			</div>
		);
	}
}
export default Home;