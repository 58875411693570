import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup, Segment, Table, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import { TagColors } from './HomeHelper.js';
import NewUsageBaseDisplay from './NewUsageBaseDisplay.js'
import {nounoptions1, nounoptions2, options1, options2, options3, colorsList, customFontFam} from './constants/constants.js'
import SimpleWordBuilder from './SimpleWordBuilder.js';
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")



var scroll    = Scroll.animateScroll;

// let demProTable = 
// {"⎡°Ik⎤":{
// 'vis':'res',
// 'english':'over there',
// 'pro':{
//     "abs":{'links':[],'examples':[{'inu':'Igña aŋun','eng':'That one man.',audio:''},],'sg':{'text':'igña','alts':[],'audio':'INU_02341.mp3'},'du':{'text':'ikkuak','alts':[],'audio':'INU_02342.mp3'},'pl':{'text':'ikkua','alts':[],'audio':'INU_02343.mp3'}},
//     "rel":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktuma','alts':[],'audio':''},'du':{'text':'ikkuak','alts':[],'audio':'INU_02342.mp3'},'pl':{'text':'ikkua','alts':[],'audio':'INU_02343.mp3'}},
//     "loc":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktumani','alts':[],'audio':''},'du':{'text':'ikkugnagni','alts':[],'audio':''},'pl':{'text':'ikkunani','alts':[],'audio':''}},
//     "ter":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktumuŋa','alts':[],'audio':''},'du':{'text':'ikkugnuŋa','alts':[],'audio':''},'pl':{'text':'ikkunuŋa','alts':[],'audio':''}},
//     "abl":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktumaŋŋa','alts':[],'audio':''},'du':{'text':'ikkugnaŋŋa','alts':[],'audio':''},'pl':{'text':'ikkunaŋŋa','alts':[],'audio':''}},
//     "via":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktumuuna','alts':[],'audio':''},'du':{'text':'ikkugnuuna','alts':[],'audio':''},'pl':{'text':'ikkunuuna','alts':[],'audio':''}},
//     "sim":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktumatun','alts':[],'audio':''},'du':{'text':'ikkugnaktun','alts':[],'audio':''},'pl':{'text':'ikkunatitun','alts':[],'audio':''}},
//     "mod":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],'sg':{'text':'iktumiŋa','alts':[],'audio':''},'du':{'text':'ikkugniŋa','alts':[],'audio':''},'pl':{'text':'ikkuniŋa','alts':[],'audio':''}},
// },
// 'adv':{
//     "base":{'links':[],'examples':[{'inu':'Ikka umiaq.','eng':'Over there is the boat.',audio:'INU_03063.mp3'},],vforms:{},'word':{'text':'ikka','alts':[],'audio':'INU_03073.mp3'},},
//     "sim":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],vforms:{},'word':{'text':'-','alts':[],'audio':''},},
//     "loc":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],vforms:{'past':{'3Sg':'ikuunnaaqtuaq','1Sg':'ikuunnaaqtuaŋa'},'present':{'3Sg':'ikuunnaaqtuq','1Sg':'ikuunnaaqtuŋa'},'future':{'3Sg':'ikuunnaaġniaqtuq','1Sg':'ikuunnaaġniaqtuŋa'}},'word':{'text':'ikani','alts':[],'audio':''},},
//     "via":{'links':["ikuuna","ikuunnaaq-"],'examples':[{'inu':'Ikuuna aniruaq.','eng':'He went out through over there (i.e., doorway).',audio:''},],vforms:{'past':{'3Sg':'ikuunnaaqtuaq','1Sg':'ikuunnaaqtuaŋa'},'present':{'3Sg':'ikuunnaaqtuq','1Sg':'ikuunnaaqtuŋa'},'future':{'3Sg':'ikuunnaaġniaqtuq','1Sg':'ikuunnaaġniaqtuŋa'}},'word':{'text':'ikuuna','alts':[],'audio':''},},
//     "abl":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],vforms:{'past':{'3Sg':'ikuunnaaqtuaq','1Sg':'ikuunnaaqtuaŋa'},'present':{'3Sg':'ikuunnaaqtuq','1Sg':'ikuunnaaqtuŋa'},'future':{'3Sg':'ikuunnaaġniaqtuq','1Sg':'ikuunnaaġniaqtuŋa'}},'word':{'text':'ikaŋŋa','alts':[],'audio':''},},
//     "ter":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],vforms:{'past':{'3Sg':'ikuunnaaqtuaq','1Sg':'ikuunnaaqtuaŋa'},'present':{'3Sg':'ikuunnaaqtuq','1Sg':'ikuunnaaqtuŋa'},'future':{'3Sg':'ikuunnaaġniaqtuq','1Sg':'ikuunnaaġniaqtuŋa'}},'word':{'text':'ikuŋa','alts':[],'audio':''},},
//     "2ter":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],vforms:{'past':{'3Sg':'ikuunnaaqtuaq','1Sg':'ikuunnaaqtuaŋa'},'present':{'3Sg':'ikuunnaaqtuq','1Sg':'ikuunnaaqtuŋa'},'future':{'3Sg':'ikuunnaaġniaqtuq','1Sg':'ikuunnaaġniaqtuŋa'}},'word':{'text':'ikuŋanmun','alts':[],'audio':''},},
//     "2abl":{'links':[],'examples':[{'inu':'','eng':'',audio:''},],vforms:{'past':{'3Sg':'ikuunnaaqtuaq','1Sg':'ikuunnaaqtuaŋa'},'present':{'3Sg':'ikuunnaaqtuq','1Sg':'ikuunnaaqtuŋa'},'future':{'3Sg':'ikuunnaaġniaqtuq','1Sg':'ikuunnaaġniaqtuŋa'}},'word':{'text':'ikaŋŋamiñ','alts':[],'audio':''},},
// }}}



let pronounCase = [
"abs",
"rel",
"mod",
"loc",
"ter",
"abl",
"via",
"sim",
]

let adverbCase = [
"base",
"loc",
"ter",
"2ter",
"abl",
"2abl",
"via",
// "sim",
]

let adverbEnglish = {
"base":'',
"loc":'at',
// "sim":'like',
"abl":'from',
"ter":'to',
"2ter":'toward',
"2abl":'from that direction',
"via":'through',
}

let pronounEnglish = {
"abs":"that one",
"rel":"that one",
"mod":"(a, some)",
"loc":"at that one",
"ter":"toward that one",
"abl":"from that one",
"via":"through that one",
"sim":"like that one",
}

let pronounEnglishThis = {
"abs":"this one",
"rel":"this one",
"mod":"(a, some)",
"loc":"at this one",
"ter":"toward this one",
"abl":"from this one",
"via":"through this one",
"sim":"like this one",
}

let vSubjectToSubject = {
"3Sg-1":"3Sg",
"3Sg-2":"3Sg",
"3Sg-3":"3Sg",
"3Du":"3Du",
"3Pl":"3Pl",
"1Sg":"1Sg",
"1Du":"1Du",
"1Pl":"1Pl",
"2Sg":"2Sg",
"2Du":"2Du",
"2Pl":"2Pl",
}

let vSubjectToQuestionSubject = {
"3Sg-1":"3Sg",
"3Sg-2":"3Sg",
"3Sg-3":"3Sg",
"3Du":"3Du",
"3Pl":"3Pl",
"1Sg":"2Sg",
"1Du":"2Du",
"1Pl":"2Pl",
"2Sg":"1Sg",
"2Du":"1Du",
"2Pl":"1Pl",
}

let vSubjectToQuestionAnswerSubject = {
"3Sg-1":"3Sg-1",
"3Sg-2":"3Sg-2",
"3Sg-3":"3Sg-3",
"3Du":"3Du",
"3Pl":"3Pl",
"1Sg":"2Sg",
"1Du":"2Du",
"1Pl":"2Pl",
"2Sg":"1Sg",
"2Du":"1Du",
"2Pl":"1Pl",
}

let vSubjectToEnglish = {
"3Sg-1":"he",
"3Sg-2":"she",
"3Sg-3":"it",
"3Du":"the two of them",
"3Pl":"they all (3+)",
"1Sg":"I",
"1Du":"the two of us",
"1Pl":"we all (3+)",
"2Sg":"you",
"2Du":"the two of you",
"2Pl":"you all (3+)",
}

let vFormQuestionEnglish = {
'present':{
    "loc":['Where','is','?'], // add the subject at second index
    "abl":['Where','is',' coming from?'],
    "ter":['Where','is',' going to?'],
    "2ter":['Which direction','is',' going towards?'],
    "2abl":['Which direction','is',' coming from?'],
    "via":['Which way','is',' going through?'],    
},
'past':{
    "loc":['Where','was','?'], // add the subject at second index
    "abl":['Where did','',' come from?'],
    "ter":['Where did','',' go to?'],
    "2ter":['Which direction did','',' go towards?'],
    "2abl":['Which direction did','',' come from?'],
    "via":['Which way did','',' go through?'],    
},
'future':{
    "loc":['Where will','',' be?'], // add the subject at second index
    "abl":['Where will','',' be coming from?'],
    "ter":['Where will','',' go to?'],
    "2ter":['Which direction will','',' go towards?'],
    "2abl":['Which direction will','',' come from?'],
    "via":['Which way will','',' go through?'],        
}
}

let vFormAnswerEnglish = {
'present':{
    "loc":['is','','.'], // add the subject start, dem after second index
    "abl":['is','coming from','.'],
    "ter":['is','going','.'],
    "2ter":['is','going',' (towards that direction).'],
    "2abl":['is','coming from',' (from that direction).'],
    "via":['is','going through','.'],    
},
'past':{
    "loc":['was','','.'], // add the subject start, dem after second index
    "abl":['','came from','.'],
    "ter":['','went','.'],
    "2ter":['','went',' (towards that direction).'],
    "2abl":['','came from',' (from that direction).'],
    "via":['','went through','.'],  
},
'future':{
    "loc":['will','be','.'], // add the subject start, dem after second index
    "abl":['will','come from','.'],
    "ter":['will','go','.'],
    "2ter":['will','go',' (towards that direction).'],
    "2abl":['will','come from',' (from that direction).'],
    "via":['will','go through','.'],     
}
}



// let vFormQuestions = {"loc":{
// "3Sg":"sumiitpa",
// "3Du":"sumiitpak",
// "3Pl":"sumiitpat",
// "1Sg":"sumiitpik",
// "1Du":"sumiitpiñuk",
// "1Pl":"sumiitpisa",
// "2Sg":"sumiitpiñ",
// "2Du":"sumiitpisik",
// "2Pl":"sumiitpisi",
// },"via":{
// "3Sg":"sukuaqpa",
// "3Du":"sukuaqpak",
// "3Pl":"sukuaqpat",
// "1Sg":"sukuaqpik",
// "1Du":"sukuaqpiñuk",
// "1Pl":"sukuaqpisa",
// "2Sg":"sukuaqpiñ",
// "2Du":"sukuaqpisik",
// "2Pl":"sukuaqpisi",
// },"abl":{
// "3Sg":"sumiñŋaqpa",
// "3Du":"sumiñŋaqpak",
// "3Pl":"sumiñŋaqpat",
// "1Sg":"sumiñŋaqpik",
// "1Du":"sumiñŋaqpiñuk",
// "1Pl":"sumiñŋaqpisa",
// "2Sg":"sumiñŋaqpiñ",
// "2Du":"sumiñŋaqpisik",
// "2Pl":"sumiñŋaqpisi",
// },"2abl":{
// "3Sg":"sugnamiñŋaqpa",
// "3Du":"sugnamiñŋaqpak",
// "3Pl":"sugnamiñŋaqpat",
// "1Sg":"sugnamiñŋaqpik",
// "1Du":"sugnamiñŋaqpiñuk",
// "1Pl":"sugnamiñŋaqpisa",
// "2Sg":"sugnamiñŋaqpiñ",
// "2Du":"sugnamiñŋaqpisik",
// "2Pl":"sugnamiñŋaqpisi",
// },"ter":{
// "3Sg":"sumukpa",
// "3Du":"sumukpak",
// "3Pl":"sumukpat",
// "1Sg":"sumukpik",
// "1Du":"sumukpiñuk",
// "1Pl":"sumukpisa",
// "2Sg":"sumukpiñ",
// "2Du":"sumukpisik",
// "2Pl":"sumukpisi",
// },"2ter":{
// "3Sg":"sugnamukpa",
// "3Du":"sugnamukpak",
// "3Pl":"sugnamukpat",
// "1Sg":"sugnamukpik",
// "1Du":"sugnamukpiñuk",
// "1Pl":"sugnamukpisa",
// "2Sg":"sugnamukpiñ",
// "2Du":"sugnamukpisik",
// "2Pl":"sugnamukpisi",
// }}

// let pronounCaseTitles = {
// "abs":{'text':[["igña","mvv.b"]],'answer':[["that one over there (r, v)","mvv.b"]],'audio':''},
// "rel":{'text':[["iktuma","mvv.b"]],'answer':[["that one over there (r, v)","mvv.b"]],'audio':''},
// "loc":{'text':[["iktumani","mvv.b"]],'answer':[["at that one over there (r, v)","mvv.b"]],'audio':''},
// "ter":{'text':[["iktumuŋa","mvv.b"]],'answer':[["toward that one over there (r, v)","mvv.b"]],'audio':''},
// "abl":{'text':[["iktumaŋŋa","mvv.b"]],'answer':[["from that one over there (r, v)","mvv.b"]],'audio':''},
// "via":{'text':[["iktumuuna","mvv.b"]],'answer':[["through that one over there (r, v)","mvv.b"]],'audio':''},
// "sim":{'text':[["iktumatun","mvv.b"]],'answer':[["like that one over there (r, v)","mvv.b"]],'audio':''},
// "mod":{'text':[["iktumiŋa","mvv.b"]],'answer':[["that one over there (r, v)","mvv.b"]],'audio':''},
// }

// let adverbCaseTitles = {
// 'base':{'text':[["ikka","mvv.b"]],'answer':[["over there (r, v)","mvv.b"]],'audio':''},
// "loc":{'text':[["ikani","mvv.b"]],'answer':[["located over there (r, v)","mvv.b"]],'audio':''},
// "via":{'text':[["ikuuna","mvv.b"]],'answer':[["via, through over there (r, v)","mvv.b"]],'audio':''},
// "abl":{'text':[["ikaŋŋa","mvv.b"]],'answer':[["from over there (r, v)","mvv.b"]],'audio':''},
// "ter":{'text':[["ikuŋa","mvv.b"]],'answer':[["to over there (r, v)","mvv.b"]],'audio':''},
// "2ter":{'text':[["ikuŋanmun","mvv.b"]],'answer':[["toward over there (r, v)","mvv.b"]],'audio':''},
// "2abl":{'text':[["ikaŋŋamiñ","mvv.b"]],'answer':[["from that direction over there (r, v)","mvv.b"]],'audio':''},
// }

// let adverbQuestionCaseTitles = {
// "base":{},
// "loc":{},
// "sim":{},
// "abl":{},
// "ter":{},
// "2ter":{},
// "2abl":{},
// "via":{},
// }

// let adverbQuestionCaseSentences = {
// "examples":{'text':[["ikuuna aullaqtuaq","he left through over there"],["ikuunnaaġlunuk aiḷuk","why don't we (two) go home through over there?"]],
// "abs":{'text':[["ikuuna","through over there"],["ikuunnaaqtuq","he is going through over there"], ["ikuunnaaqtuŋa","I am going through over there"], ["ikuunnaaqtutin","you are going through over there"]],
// "rel":{'text':[["ikuunnaaqtuaq","he went through over there"], ["ikuunnaaqtuaŋa","I went through over there"], ["ikuunnaaqtuatin","you went through over there"]],
// "loc":{'text':[["ikuunnaaġniaqtuq","he will go through over there"], ["ikuunnaaġniaqtuŋa","I will go through over there"], ["ikuunnaaġniaqtutin","you will go through over there",]],
// }
// let adverbQuestionCaseSentences = {
// 1:{
// "examples":[{'text':[["Ikka umiaq.","mvv.b"]],'answer':[["Over there is the boat.","mvv.b"]],'audio':'INU_03063.mp3'},{'text':[["Ikka umiaqpak.","mvv.b"]],'answer':[["Over there is the big boat.","mvv.b"]],'audio':'INU_03064.mp3'}],	
// "links":["ikka"],
// },
// "loc":{
// "examples":[{'text':[["ikuuna aullaqtuaq","mvv.b"]],'answer':[["he left through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġlunuk aiḷuk","mvv.b"]],'answer':[["why don't we (two) go home through over there?","mvv.b"]],'audio':''}],	
// "links":[],
// },
// "sim":{
// "examples":[{'text':[["ikuuna aullaqtuaq","mvv.b"]],'answer':[["he left through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġlunuk aiḷuk","mvv.b"]],'answer':[["why don't we (two) go home through over there?","mvv.b"]],'audio':''}],	
// "links":[],
// },
// "abl":{
// "examples":[{'text':[["ikuuna aullaqtuaq","mvv.b"]],'answer':[["he left through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġlunuk aiḷuk","mvv.b"]],'answer':[["why don't we (two) go home through over there?","mvv.b"]],'audio':''}],	
// "links":[],
// },
// "ter":{
// "examples":[{'text':[["ikuuna aullaqtuaq","mvv.b"]],'answer':[["he left through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġlunuk aiḷuk","mvv.b"]],'answer':[["why don't we (two) go home through over there?","mvv.b"]],'audio':''}],	
// "links":[],
// },
// "2ter":{
// "examples":[{'text':[["ikuuna aullaqtuaq","mvv.b"]],'answer':[["he left through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġlunuk aiḷuk","mvv.b"]],'answer':[["why don't we (two) go home through over there?","mvv.b"]],'audio':''}],	
// "links":[],
// },
// "2abl":{
// "examples":[{'text':[["ikuuna aullaqtuaq","mvv.b"]],'answer':[["he left through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġlunuk aiḷuk","mvv.b"]],'answer':[["why don't we (two) go home through over there?","mvv.b"]],'audio':''}],	
// "links":[],
// },
// "via":{
// "examples":[{'text':[["Ikuuna aniruaq.","mvv.b"]],'answer':[["He went out through over there (i.e., doorway).","mvv.b"]],'audio':''},],
// "present":[{'text':[["ikuuna","mvv.b"]],'answer':[["through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaq","mvv.b"],["tu","mvv.m"],["q","mvv.s"]],'answer':[["he ","mvv.s"],["is ","mvv.m"],[" going through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaq","mvv.b"],["tu","mvv.m"],["ŋa","1stPerson"]],'answer':[["I ","1stPerson"],["am ","mvv.m"],[" going through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaq","mvv.b"],["tu","mvv.m"],["tin","2ndPerson"]],'answer':[["you ","2ndPerson"],["are ","mvv.m"],[" going through over there","mvv.b"]],'audio':''}],
// "past":[{'text':[["ikuuna","mvv.b"]],'answer':[["through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaq","mvv.b"],["tua","mvv.m"],["q","mvv.s"]],'answer':[["he ","mvv.s"],[" went through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaq","mvv.b"],["tua","mvv.m"],["ŋa","1stPerson"]],'answer':[["I ","1stPerson"],["went through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaq","mvv.b"],["tua","mvv.m"],["tin","2ndPerson"]],'answer':[["you ","2ndPerson"],["went through over there","mvv.b"]],'audio':''}],
// "future":[{'text':[["ikuuna","mvv.b"]],'answer':[["through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġ","mvv.b"],["niaq","pb.1"],["tu","mvv.m"],["q","mvv.s"]],'answer':[["he ","mvv.s"],["will ","pb.1"],["go through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġ","mvv.b"],["niaq","pb.1"],["tu","mvv.m"],["ŋa","1stPerson"]],'answer':[["I ","1stPerson"],["will ","pb.1"],["go through over there","mvv.b"]],'audio':''},{'text':[["ikuunnaaġ","mvv.b"],["niaq","pb.1"],["tu","mvv.m"],["tin","2ndPerson"]],'answer':[["you ","2ndPerson"],["will ","pb.1"],["go through over there","mvv.b"]],'audio':''}],
// "links":["ikuuna","ikuunnaaq-"],
// },
// }




let demStems = [
"⎡°uv⎤",
"⎡°tavr⎤,⎡°taam⎤",
"⎡°Ik⎤",
"⎡°taik⎤",
"⎡°pIk⎤",
"⎡°kan⎤",
"⎡°mar⎤",
"⎡°av⎤",
"⎡°pag⎤",
"⎡°un⎤",
"⎡°Im⎤",
"⎡°sam¹⎤",
"⎡°am⎤",
"⎡°qakim⎤",
"⎡°qam⎤",
"⎡°pakim⎤",

"⎡°ag⎤",
"⎡°akim⎤",
"⎡°kIg⎤",
"⎡°kIv⎤",
"⎡°pam⎤",
"⎡°qag⎤",
"⎡°qav⎤",
"⎡°sakim⎤",
"⎡°sam²⎤",
"⎡°taag⎤",
"⎡°taakim⎤",
"⎡°taam⎤",
"⎡°taav⎤",
"⎡°taim⎤",
"⎡°takan⎤",
"⎡°tatkIg⎤",
"⎡°tatkIv⎤",
"⎡°tamar⎤",
"⎡°tatpag⎤",
"⎡°tatpakim⎤",
"⎡°tatpam⎤",
"⎡°tatpIk⎤",
"⎡°tatqa(g)⎤",
"⎡°tatqakim⎤",
"⎡°tatqam⎤",
"⎡°tatqav⎤",
"⎡°tasakim⎤",
"⎡°tasam¹⎤",
"⎡°tasam²⎤",
"⎡°taun⎤",
]


// let demAdvs = {
// "agga;0;0":["agga","⎡°ag⎤"],
// "amma;0;0":["amma","⎡°am⎤"],
// "avva;0;0":["avva","⎡°av⎤"],
// "aŋma;0;0":["aŋma","⎡°akim⎤"],
// "ikka;0;0":["ekka","⎡°Ik⎤"],
// "imma;0;0":["emma","⎡°Im⎤"],
// "kanna²;0;0":["kanna","⎡°kan⎤"],
// "kigga;0;0":["kigga","⎡°kIg⎤"],
// "kivva;0;0":["kevva","⎡°kIv⎤"],
// "marra;0;0":["marra","⎡°mar⎤"],
// "pagga;0;0":["pagga","⎡°pag⎤"],
// "pamma;0;0":["pamma","⎡°pam⎤"],
// "paŋma;0;0":["paŋma","⎡°pakim⎤"],
// "pikka;0;0":["pekka","⎡°pIk⎤"],
// "qagga;0;0":["qagga","⎡°qag⎤"],
// "qamma;0;0":["qamma","⎡°qam⎤"],
// "qavva;0;0":["qavva","⎡°qav⎤"],
// "qaŋma;0;0":["qaŋma","⎡°qakim⎤"],
// "samma¹;0;0":["samma","⎡°sam¹⎤"],
// "samma²;0;0":["samma","⎡°sam²⎤"],
// "saŋma,sagma;0;0":["saŋma","⎡°sakim⎤"],
// "saŋma,sagma;0;1":["sagma","⎡°sakim⎤"],
// "unna²;0;0":["unna","⎡°un⎤"],
// "uvva;0;0":["uvva","⎡°uv⎤"],

// "taagga;0;0":["tat-agga","⎡°taag⎤"],
// "taamma;0;0":["tat-amma","⎡°taam⎤"],
// "taavva;0;0":["tat-avva","⎡°taav⎤"],
// "taaŋma;0;0":["tat-aŋma","⎡°taakim⎤"],
// "taikka;0;0":["tat-ekka","⎡°taik⎤"],
// "taimma;0;0":["tat-emma","⎡°taim⎤"],
// "takanna²;0;0":["tat-kanna","⎡°takan⎤"],
// "tatkigga;0;0":["tat-kegga","⎡°tatkIg⎤"],
// "tatkivva;0;0":["tat-kevva","⎡°tatkIv⎤"],
// "tamarra;0;0":["tat-marra","⎡°tamar⎤"],
// "tatpagga;0;0":["tat-pagga","⎡°tatpag⎤"],
// "tatpamma;0;0":["tat-pamma","⎡°tatpam⎤"],
// "tatpaŋma;0;0":["tat-paŋma","⎡°tatpakim⎤"],
// "tatpikka;0;0":["tat-pekka","⎡°tatpIk⎤"],
// "tatqagga;0;0":["tat-qagga","⎡°tatqa(g)⎤"],
// "tatqamma;0;0":["tat-qamma","⎡°tatqam⎤"],
// "tatqavva;0;0":["tat-qavva","⎡°tatqav⎤"],
// "tatqaŋma;0;0":["tat-qaŋma","⎡°tatqakim⎤"],
// "tasamma¹;0;0":["tat-samma","⎡°tasam¹⎤"],
// "tasamma²;0;0":["tat-samma","⎡°tasam²⎤"],
// "tasaŋma;0;0":["tat-saŋma","⎡°tasakim⎤"], // "tat-sagma"
// "taunna¹;0;0":["tat-unna","⎡°taun⎤"],
// "tavra,tarva;0;0":["tavra","⎡°tavr⎤,⎡°taam⎤"],
// "tavra,tarva;0;1":["tarva","⎡°tavr⎤,⎡°taam⎤"],
// "taiñña;0;0":["taiñña","⎡°taiñ⎤"],
// }

let demLookup = {
"agna;0;0":["OverThereAcross","ext","pro",0,"⎡°ag⎤"],
"akimna;0;0":["OverThereAcross","not","pro",0,"⎡°akim⎤"],
"amna¹;0;0":["OverThere","not","pro",0,"⎡°am⎤"],
"amna²;0;0":["OverThere","ext","pro",0,"⎡°av⎤"],
"igña;0;0":["OverThere","res","pro",0,"⎡°Ik⎤"],
"imña;0;0":["PreviouslyMentioned","not","pro",0,"⎡°Im⎤"],
"kanna¹;0;0":["DownThere","res","pro",0,"⎡°kan⎤"],
"kigña;0;0":["OutThere","res","pro",0,"⎡°kIg⎤"],
"kimña;0;0":["InThere","res","pro",0,"⎡°kIv⎤"],
"manna;0;0":["Here","ext","pro",0,"⎡°mar⎤"],
"pagna;0;0":["UpThere","ext","pro",0,"⎡°pag⎤"],
"pakimna;0;0":["UpTopOfTheRoof","not","pro",0,"⎡°pakim⎤"],
"pamna;0;0":["UpThere","not","pro",0,"⎡°pam⎤"],
"pigña;0;0":["UpThere","res","pro",0,"⎡°pIk⎤"],
"qagna,qaŋna;0;0":["OutThere","ext","pro",0,"⎡°qag⎤"],
"qakimna;0;0":["OutThere","not","pro",0,"⎡°qakim⎤"],
"qamna¹;0;0":["InThere","not","pro",0,"⎡°qam⎤"],
"qamna²;0;0":["InThere","ext","pro",0,"⎡°qav⎤"],
"sakimna;0;0":["InTheColdPorchOceanward","not","pro",0,"⎡°sakim⎤"],
"samna¹;0;0":["Here","not","pro",0,"⎡°sam¹⎤"],
"samna²;0;0":["DownThere","not","pro",0,"⎡°sam²⎤"],
"una;0;0":["Here","res","pro",0,"⎡°uv⎤"],
"unna¹;0;0":["DownThere","ext","pro",0,"⎡°un⎤"],

"taagagna;0;0":["OverThereAcross-tat","ext","pro",1,"⎡°taag⎤"],
"taakimna;0;0":["OverThereAcross-tat","not","pro",1,"⎡°taakim⎤"],
"taamamna;0;0":["OverThere-tat","not","pro",1,"⎡°taam⎤"],
"taavamna;0;0":["OverThere-tat","ext","pro",1,"⎡°taav⎤"],
"taigña;0;0":["OverThere-tat","res","pro",1,"⎡°taik⎤"],
"taimña;0;0":["PreviouslyMentioned-tat","not","pro",1,"⎡°taim⎤"],
"takanna¹;0;0":["DownThere-tat","res","pro",1,"⎡°takan⎤"],
"tatkigña;0;0":["OutThere-tat","res","pro",1,"⎡°tatkIg⎤"],
"tatkimña;0;0":["InThere-tat","res","pro",1,"⎡°tatkIv⎤"],
"tamanna;0;0":["Here-tat","ext","pro",1,"⎡°tamar⎤"],
"tatpagna;0;0":["UpThere-tat","ext","pro",1,"⎡°tatpag⎤"],
"tatpakimna;0;0":["UpTopOfTheRoof-tat","not","pro",1,"⎡°tatpakim⎤"],
"tatpamna;0;0":["UpThere-tat","not","pro",1,"⎡°tatpam⎤"],
"tatpigña;0;0":["UpThere-tat","res","pro",1,"⎡°tatpIk⎤"],
"tatqagna;0;0":["OutThere-tat","ext","pro",1,"⎡°tatqa(g)⎤"],
"tatqakimna;0;0":["OutThere-tat","not","pro",1,"⎡°tatqakim⎤"],
"tatqamna¹;0;0":["InThere-tat","not","pro",1,"⎡°tatqam⎤"],
"tatqamna²;0;0":["InThere-tat","ext","pro",1,"⎡°tatqav⎤"],
"tasakimna;0;0":["InTheColdPorchOceanward-tat","not","pro",1,"⎡°tasakim⎤"],
"tasamna¹;0;0":["Here-tat","not","pro",1,"⎡°tasam¹⎤"],
"tasamna²;0;0":["DownThere-tat","not","pro",1,"⎡°tasam²⎤"],
"taamna;0;0":["Here-tat","res","pro",1,"⎡°tavr⎤,⎡°taam⎤"],
"taunna²;0;0":["DownThere-tat","ext","pro",1,"⎡°taun⎤"],



"agga;0;0":["OverThereAcross","ext","adv",0,"⎡°ag⎤"],
"amma;0;0":["OverThere","not","adv",0,"⎡°am⎤"],
"avva;0;0":["OverThere","ext","adv",0,"⎡°av⎤"],
"aŋma;0;0":["OverThereAcross","not","adv",0,"⎡°akim⎤"],
"ikka;0;0":["OverThere","res","adv",0,"⎡°Ik⎤"],
"imma;0;0":["PreviouslyMentioned","not","adv",0,"⎡°Im⎤"],
"kanna²;0;0":["DownThere","res","adv",0,"⎡°kan⎤"],
"kigga;0;0":["OutThere","res","adv",0,"⎡°kIg⎤"],
"kivva;0;0":["InThere","res","adv",0,"⎡°kIv⎤"],
"marra;0;0":["Here","ext","adv",0,"⎡°mar⎤"],
"pagga;0;0":["UpThere","ext","adv",0,"⎡°pag⎤"],
"pamma;0;0":["UpThere","not","adv",0,"⎡°pam⎤"],
"paŋma;0;0":["UpTopOfTheRoof","not","adv",0,"⎡°pakim⎤"],
"pikka;0;0":["UpThere","res","adv",0,"⎡°pIk⎤"],
"qagga;0;0":["OutThere","ext","adv",0,"⎡°qag⎤"],
"qamma;0;0":["InThere","not","adv",0,"⎡°qam⎤"],
"qavva;0;0":["InThere","ext","adv",0,"⎡°qav⎤"],
"qaŋma;0;0":["OutThere","not","adv",0,"⎡°qakim⎤"],
"samma¹;0;0":["Here","not","adv",0,"⎡°sam¹⎤"],
"samma²;0;0":["DownThere","not","adv",0,"⎡°sam²⎤"],
"saŋma,sagma;0;0":["Here","res","adv",0,"⎡°sakim⎤"],
"saŋma,sagma;0;1":["InTheColdPorchOceanward","not","adv",0,"⎡°sakim⎤"],
"unna²;0;0":["DownThere","ext","adv",0,"⎡°un⎤"],
"uvva;0;0":["Here","res","adv",0,"⎡°uv⎤"],

"taagga;0;0":["OverThereAcross-tat","ext","adv",1,"⎡°taag⎤"],
"taamma;0;0":["OverThere-tat","not","adv",1,"⎡°taam⎤"],
"taavva;0;0":["OverThere-tat","ext","adv",1,"⎡°taav⎤"],
"taaŋma;0;0":["OverThereAcross-tat","not","adv",1,"⎡°taakim⎤"],
"taikka;0;0":["OverThere-tat","res","adv",1,"⎡°taik⎤"],
"taimma;0;0":["PreviouslyMentioned-tat","not","adv",1,"⎡°taim⎤"],
"takanna²;0;0":["DownThere-tat","res","adv",1,"⎡°takan⎤"],
"tatkigga;0;0":["OutThere-tat","res","adv",1,"⎡°tatkIg⎤"],
"tatkivva;0;0":["InThere-tat","res","adv",1,"⎡°tatkIv⎤"],
"tamarra;0;0":["Here-tat","ext","adv",1,"⎡°tamar⎤"],
"tatpagga;0;0":["UpThere-tat","ext","adv",1,"⎡°tatpag⎤"],
"tatpamma;0;0":["UpThere-tat","not","adv",1,"⎡°tatpam⎤"],
"tatpaŋma;0;0":["UpTopOfTheRoof-tat","not","adv",1,"⎡°tatpakim⎤"],
"tatpikka;0;0":["UpThere-tat","res","adv",1,"⎡°tatpIk⎤"],
"tatqagga;0;0":["OutThere-tat","ext","adv",1,"⎡°tatqa(g)⎤"],
"tatqamma;0;0":["InThere-tat","not","adv",1,"⎡°tatqam⎤"],
"tatqavva;0;0":["InThere-tat","ext","adv",1,"⎡°tatqav⎤"],
"tatqaŋma;0;0":["OutThere-tat","not","adv",1,"⎡°tatqakim⎤"],
"tasamma¹;0;0":["Here-tat","not","adv",1,"⎡°tasam¹⎤"],
"tasamma²;0;0":["DownThere-tat","not","adv",1,"⎡°tasam²⎤"],
"tasaŋma;0;0":["InTheColdPorchOceanward-tat","not","adv",1,"⎡°tasakim⎤"], // "tat-sagma"
"taunna¹;0;0":["DownThere-tat","ext","adv",1,"⎡°taun⎤"],
"tavra,tarva;0;0":["Here-tat","res","adv",1,"⎡°tavr⎤,⎡°taam⎤"],
"tavra,tarva;0;1":["Here-tat","res","adv",1,"⎡°tavr⎤,⎡°taam⎤"],
// "taiñña;0;0":["Here","not","adv",1,"⎡°taiñ⎤"],
}

let demChart = {
"Here": {
    "res": "⎡°uv⎤",
    "ext": "⎡°mar⎤",
    "not": "⎡°sam¹⎤",
},
"OverThere": {
    "res": "⎡°Ik⎤",
    "ext": "⎡°av⎤",
    "not": "⎡°am⎤",
},
"OverThereAcross": {
    // "res": "",
    "ext": "⎡°ag⎤",
    "not": "⎡°akim⎤",
},
"UpThere": {
    "res": "⎡°pIk⎤",
    "ext": "⎡°pag⎤",
    "not": "⎡°pam⎤",
},
"UpTopOfTheRoof": {
    // "res": "",
    // "ext": "",
    "not": "⎡°pakim⎤",
},
"DownThere": {
    "res": "⎡°kan⎤",
    "ext": "⎡°un⎤",
    "not": "⎡°sam²⎤",
},
"InTheColdPorchOceanward": {
    // "res": "",
    // "ext": "",
    "not": "⎡°sakim⎤",
},
"OutThere": {
    "res": "⎡°kIg⎤",
    "ext": "⎡°qag⎤",
    "not": "⎡°qakim⎤",
},
"InThere": {
    "res": "⎡°kIv⎤",
    "ext": "⎡°qav⎤",
    "not": "⎡°qam⎤",
},
"PreviouslyMentioned": {
    // "res": "",
    // "ext": "",
    "not": "⎡°Im⎤",
},
"Here-tat": {
    "res": "⎡°tavr⎤,⎡°taam⎤",
    "ext": "⎡°tamar⎤",
    "not": "⎡°tasam¹⎤",
},
"OverThere-tat": {
    "res": "⎡°taik⎤",
    "ext": "⎡°taav⎤",
    "not": "⎡°taam⎤",
},
"OverThereAcross-tat": {
    // "res": "",
    "ext": "⎡°taag⎤",
    "not": "⎡°taakim⎤",
},
"UpThere-tat": {
    "res": "⎡°tatpIk⎤",
    "ext": "⎡°tatpag⎤",
    "not": "⎡°tatpam⎤",
},
"UpTopOfTheRoof-tat": {
    // "res": "",
    // "ext": "",
    "not": "⎡°tatpakim⎤",
},
"DownThere-tat": {
    "res": "⎡°takan⎤",
    "ext": "⎡°taun⎤",
    "not": "⎡°tasam²⎤",
},
"InTheColdPorchOceanward-tat": {
    // "res": "",
    // "ext": "",
    "not": "⎡°tasakim⎤",
},
"OutThere-tat": {
    "res": "⎡°tatkIg⎤",
    "ext": "⎡°tatqa(g)⎤",
    "not": "⎡°tatqakim⎤",
},
"InThere-tat": {
    "res": "⎡°tatkIv⎤",
    "ext": "⎡°tatqav⎤",
    "not": "⎡°tatqam⎤",
},
"PreviouslyMentioned-tat": {
    // "res": "",
    // "ext": "",
    "not": "⎡°taim⎤",
}
// "Here": {
//     "res": {0:"⎡°uv⎤", 'sg':"⎡°tavr⎤,⎡°taam⎤"},
//     "ext": {0:"⎡°mar⎤", 'sg':"⎡°tamar⎤"},
//     "not": {0:"⎡°sam¹⎤", 'sg':"⎡°tasam¹⎤"},
// },
// "OverThere": {
//     "res": {0:"⎡°Ik⎤", 'sg':"⎡°taik⎤"},
//     "ext": {0:"⎡°av⎤", 'sg':"⎡°taav⎤"},
//     "not": {0:"⎡°am⎤", 'sg':"⎡°taam⎤"},
// },
// "OverThereAcross": {
//     // "res": "",
//     "ext": {0:"⎡°ag⎤", 'sg':"⎡°taag⎤"},
//     "not": {0:"⎡°akim⎤", 'sg':"⎡°taakim⎤"},
// },
// "UpThere": {
//     "res": {0:"⎡°pIk⎤", 'sg':"⎡°tatpIk⎤"},
//     "ext": {0:"⎡°pag⎤", 'sg':"⎡°tatpag⎤"},
//     "not": {0:"⎡°pam⎤", 'sg':"⎡°tatpam⎤"},
// },
// "UpTopOfTheRoof": {
//     // "res": "",
//     // "ext": "",
//     "not": {0:"⎡°pakim⎤", 'sg':"⎡°tatpakim⎤"},
// },
// "DownThere": {
//     "res": {0:"⎡°kan⎤", 'sg':"⎡°takan⎤"},
//     "ext": {0:"⎡°un⎤", 'sg':"⎡°taun⎤"},
//     "not": {0:"⎡°sam²⎤", 'sg':"⎡°tasam²⎤"},
// },
// "InTheColdPorchOceanward": {
//     // "res": "",
//     // "ext": "",
//     "not": {0:"⎡°sakim⎤", 'sg':"⎡°tasakim⎤"},
// },
// "OutThere": {
//     "res": {0:"⎡°kIg⎤", 'sg':"⎡°tatkIg⎤"},
//     "ext": {0:"⎡°qag⎤", 'sg':"⎡°tatqa(g)⎤"},
//     "not": {0:"⎡°qakim⎤", 'sg':"⎡°tatqakim⎤"},
// },
// "InThere": {
//     "res": {0:"⎡°kIv⎤", 'sg':"⎡°tatkIv⎤"},
//     "ext": {0:"⎡°qav⎤", 'sg':"⎡°tatqav⎤"},
//     "not": {0:"⎡°qam⎤", 'sg':"⎡°tatqam⎤"},
// },
// "PreviouslyMentioned": {
//     // "res": "",
//     // "ext": "",
//     "not": {0:"⎡°Im⎤", 'sg':"⎡°taim⎤"},
// },
}


let demRoot = {
"⎡°ag⎤": {
    "pro":["agna;0;0","agna"],
    "adv":["agga;0;0","agga"],
},
"⎡°akim⎤": {
    "pro":["akimna;0;0","akimña"],
    "adv":["aŋma;0;0","aŋma"],
},
"⎡°am⎤": {
    "pro":["amna¹;0;0","amna¹"],
    "adv":["amma;0;0","amma"],
},
"⎡°av⎤": {
    "pro":["amna²;0;0","amna²"],
    "adv":["avva;0;0","avva"],
},
"⎡°Ik⎤": {
    "pro":["igña;0;0","igña"],
    "adv":["ikka;0;0","ikka"],
},
"⎡°Im⎤": {
    "pro":["imña;0;0","imña"],
    "adv":["imma;0;0","imma"],
},
"⎡°kan⎤": {
    "pro":["kanna¹;0;0","kanna"],
    "adv":["kanna²;0;0","kanna"],
},
"⎡°kIg⎤": {
    "pro":["kigña;0;0","kigña"],
    "adv":["kigga;0;0","kigga"],
},
"⎡°kIv⎤": {
    "pro":["kimña;0;0","kimña"],
    "adv":["kivva;0;0","kivva"],
},
"⎡°mar⎤": {
    "pro":["manna;0;0","manna"],
    "adv":["marra;0;0","marra"],
},
"⎡°pag⎤": {
    "pro":["pagna;0;0","pagna"],
    "adv":["pagga;0;0","pagga"],
},
"⎡°pakim⎤": {
    "pro":["pakimna;0;0","pakimña"],
    "adv":["paŋma;0;0","paŋma"],
},
"⎡°pam⎤": {
    "pro":["pamna;0;0","pamna"],
    "adv":["pamma;0;0","pamma"],
},
"⎡°pIk⎤": {
    "pro":["pigña;0;0","pigña"],
    "adv":["pikka;0;0","pikka"],
},
"⎡°qag⎤": {
    "pro":["qagna,qaŋna;0;0","qagna"],
    "adv":["qagga;0;0","qagga"],
},
"⎡°qakim⎤": {
    "pro":["qakimna;0;0","qakimña"],
    "adv":["qaŋma;0;0","qaŋma"],
},
"⎡°qam⎤": {
    "pro":["qamna¹;0;0","qamna¹"],
    "adv":["qamma;0;0","qamma"],
},
"⎡°qav⎤": {
    "pro":["qamna²;0;0","qamna²"],
    "adv":["qavva;0;0","qavva"],
},
"⎡°sakim⎤": {
    "pro":["sakimna;0;0","sakimña"],
    // "adv":["saŋma,sagma;0;1","sagma"],
    "adv":["saŋma,sagma;0;0","saŋma"],
},
"⎡°sam¹⎤": {
    "pro":["samna¹;0;0","samna¹"],
    "adv":["samma¹;0;0","samma¹"],
},
"⎡°sam²⎤": {
    "pro":["samna²;0;0","samna²"],
    "adv":["samma²;0;0","samma²"],
},

"⎡°uv⎤": {
    "pro":["una;0;0","una"],
    "adv":["uvva;0;0","uvva"],
},
"⎡°un⎤": {
    "pro":["unna¹;0;0","unna"],
    "adv":["unna²;0;0","unna"],
},
"⎡°taag⎤": {
    "pro":["taagagna;0;0","taagagna"],
    "adv":["taagga;0;0","taagga"],
},
"⎡°taakim⎤": {
    "pro":["taakimna;0;0","taakimña"],
    "adv":["taaŋma;0;0","taaŋma"],
},
"⎡°taam⎤": {
    "pro":["taamamna;0;0","taamamna"],
    "adv":["taamma;0;0","taamma"],
},
"⎡°taav⎤": {
    "pro":["taavamna;0;0","taavamna"],
    "adv":["taavva;0;0","taavva"],
},
"⎡°taik⎤": {
    "pro":["taigña;0;0","taigña"],
    "adv":["taikka;0;0","taikka"],
},
"⎡°taim⎤": {
    "pro":["taimña;0;0","taimña"],
    "adv":["taimma;0;0","taimma"],
},
"⎡°takan⎤": {
    "pro":["takanna¹;0;0","tatkanna"],
    "adv":["takanna²;0;0","tatkanna"],
},
"⎡°tatkIg⎤": {
    "pro":["tatkigña;0;0","tatkigña"],
    "adv":["tatkigga;0;0","tatkigga"],
},
"⎡°tatkIv⎤": {
    "pro":["tatkimña;0;0","tatkimña"],
    "adv":["tatkivva;0;0","tatkivva"],
},
"⎡°tamar⎤": {
    "pro":["tamanna;0;0","tamanna"],
    "adv":["tamarra;0;0","tamarra"],
},
"⎡°tatpag⎤": {
    "pro":["tatpagna;0;0","tatpagna"],
    "adv":["tatpagga;0;0","tatpagga"],
},
"⎡°tatpakim⎤": {
    "pro":["tatpakimna;0;0","tatpakimña"],
    "adv":["tatpaŋma;0;0","tatpaŋma"],
},
"⎡°tatpam⎤": {
    "pro":["tatpamna;0;0","tatpamna"],
    "adv":["tatpamma;0;0","tatpamma"],
},
"⎡°tatpIk⎤": {
    "pro":["tatpigña;0;0","tatpigña"],
    "adv":["tatpikka;0;0","tatpikka"],
},
"⎡°tatqa(g)⎤": {
    "pro":["tatqagna;0;0","tatqagna"],
    "adv":["tatqagga;0;0","tatqagga"],
},
"⎡°tatqakim⎤": {
    "pro":["tatqakimna;0;0","tatqakimña"],
    "adv":["tatqaŋma;0;0","tatqaŋma"],
},
"⎡°tatqam⎤": {
    "pro":["tatqamna¹;0;0","tatqamna¹"],
    "adv":["tatqamma;0;0","tatqamma"],
},
"⎡°tatqav⎤": {
    "pro":["tatqamna²;0;0","tatqamna²"],
    "adv":["tatqavva;0;0","tatqavva"],
},
"⎡°tasakim⎤": {
    "pro":["tasakimna;0;0","tasakimña"],
    "adv":["tasaŋma;0;0","tasaŋma"],
},
"⎡°tasam¹⎤": {
    "pro":["tasamna¹;0;0","tasamna¹"],
    "adv":["tasamma¹;0;0","tasamma¹"],
},
"⎡°tasam²⎤": {
    "pro":["tasamna²;0;0","tasamna²"],
    "adv":["tasamma²;0;0","tasamma²"],
},
"⎡°tavr⎤,⎡°taam⎤": {
    "pro":["taamna;0;0","taamna"],
    // "adv":["tavra,tarva;0;1","tarva"],
    "adv":["tavra,tarva;0;0","tavra"],
},
"⎡°taun⎤": {
    "pro":["taunna²;0;0","taunna"],
    "adv":["taunna¹;0;0","taunna"],
}
// "⎡°taiñ⎤"  "adv":["taiñña;0;0","taiñña"],
}



// let demProsTitles = {
// "agna;0;0":"that one over there across (extended and visible)",
// "akimna;0;0":"that one over there across (not visible)",
// "amna¹;0;0":"that one over there (not visible)",
// "amna²;0;0":"that one over there (extended and visible)",
// "igña;0;0":"the one over there (restricted and visible)",
// "imña;0;0":"that of the past, the one mentioned or seen previously",
// "kanna¹;0;0":"that one down there, on or near the ocean, downriver, or by the door (restricted and visible)",
// "kigña;0;0":"that one out there (restricted and visible)",
// "kimña;0;0":"that one in there, or up the coast (restricted and visible)",
// "manna;0;0":"this one here (extended and visible)",
// "pagna;0;0":"that one up there on the wall, the ceiling, or in the sky above eye level, that one up there away from the ocean, that one up there, upriver (extended and visible)",
// "pakimna;0;0":"that one up there on roof (not visible)",
// "pamna;0;0":"that one up there (e.g., inland, upriver, upstairs) (not visible)",
// "pigña;0;0":"that one up there on the wall, on the ceiling, or in the sky above eye level, that one up there away from the ocean, that one up there, upriver (restricted and visible)",
// "qagna,qaŋna;0;0":"that one out there, the outside environment (extended and visible)",
// "qakimna;0;0":"that one out there in the shed or in the neighbor’s house (not visible to speaker or listener who are inside another house)",
// "qamna¹;0;0":"that one in there (not visible)",
// "qamna²;0;0":"that one in there, or up the coast (extended and visible), that one in Canada or Greenland (not visible at the moment but has been observed or seen previously)",
// "sakimna;0;0":"that one out there in the arctic entry (not visible)",
// "samna¹;0;0":"the one down under here (not visible)",
// "samna²;0;0":"that one down there (e.g., far out on the ocean, downriver, or downstairs) (not visible)",
// "taamna;0;0":"that one there (restricted and visible; entity in reference is closer to the listener), topic of ongoing conversation, recently aforementioned one",
// "una;0;0":"this one here (restricted and visible)",
// "unna¹;0;0":"that one down there (e.g., on or near the ocean, downriver, or by the door) (extended and visible)",
// }

let demDirectionText={
"Here":"here",
"Here-tat":"there",
"OverThere":"over there",
"OverThere-tat":"over there",
"OverThereAcross":"over across there",
"OverThereAcross-tat":"over across there",
"UpThere":"up there",
"UpThere-tat":"up there",
"UpTopOfTheRoof":"up on top of the roof",
"UpTopOfTheRoof-tat":"up on top of the roof",
"DownThere":"down there",
"DownThere-tat":"down there",
"InTheColdPorchOceanward":"in the cold entry; oceanward",
"InTheColdPorchOceanward-tat":"in the cold entry; oceanward",
"OutThere":"out there",
"OutThere-tat":"out there",
"InThere":"in there",
"InThere-tat":"in there",
"PreviouslyMentioned":"previously mentioned",
"PreviouslyMentioned-tat":"previously mentioned",
}


let demDirection={
"Here":"here",
"Here-tat":"there (tat-)",
"OverThere":"over there",
"OverThere-tat":"over there (tat-)",
"OverThereAcross":"over across there",
"OverThereAcross-tat":"over across there (tat-)",
"UpThere":"up there",
"UpThere-tat":"up there (tat-)",
"UpTopOfTheRoof":"up on top of the roof",
"UpTopOfTheRoof-tat":"up on top of the roof (tat-)",
"DownThere":"down there",
"DownThere-tat":"down there (tat-)",
"InTheColdPorchOceanward":"in the cold entry; oceanward",
"InTheColdPorchOceanward-tat":"in the cold entry; oceanward (tat-)",
"OutThere":"out there",
"OutThere-tat":"out there (tat-)",
"InThere":"in there",
"InThere-tat":"in there (tat-)",
"PreviouslyMentioned":"previously mentioned",
"PreviouslyMentioned-tat":"previously mentioned (tat-)",
}

let demDirectionSorted=[
"Here",
"Here-tat",
"OverThere",
"OverThere-tat",
"OverThereAcross",
"OverThereAcross-tat",
"UpThere",
"UpThere-tat",
"UpTopOfTheRoof",
"UpTopOfTheRoof-tat",
"DownThere",
"DownThere-tat",
"InTheColdPorchOceanward",
"InTheColdPorchOceanward-tat",
"OutThere",
"OutThere-tat",
"InThere",
"InThere-tat",
"PreviouslyMentioned",
"PreviouslyMentioned-tat",
]

let demVisibility={
"res":"restricted and visible (r, v)",
"ext":"extended and visible (e, v)",
"not":"not visible (not v)",
}
let demVisibilityAcronym={
"res":"(r, v)",
"ext":"(e, v)",
"not":"(not v)",
}
let demVisibilitySorted=[
"res",
"ext",
"not",
]

let demonstrativeQuestions = {
"loc":['Sumi?','At where?'],
"ter":['Sumun?','Where to?'],
"abl":['Sumiñ?','From where?'],
"2ter":['Sugnamun?','Toward which direction?'],
"2abl":['Sugnamiñ?','From which direction?'],
"via":['Sukun?','Which way?'],
// "sim":[],
}
// let demAdvsTitles={
// "agga;0;0":"over there across (e.g., a river, a street, or a body of water) (extended and visible)",
// "amma;0;0":"over there (not visible)",
// "avva;0;0":"over there (extended and visible)",
// "aŋma;0;0":"over there across (not visible)",
// "ikka;0;0":"over there (restricted and visible, at eye level)",
// "imma;0;0":"it is known as a previously established fact",
// "kanna²;0;0":"down there, on or near the ocean, downriver, or by the door (restricted and visible)",
// "kigga;0;0":"out there (restricted and visible)",
// "kivva;0;0":"in there, or up the coast (restricted and visible)",
// "marra;0;0":"here, here it is (extended and visible)",
// "pagga;0;0":"up there on the wall, the ceiling, or in the sky above eye level, up there away from the ocean, up there, upriver (extended and visible)",
// "pamma;0;0":"up there (e.g., inland, upriver, upstairs) (not visible)",
// "paŋma;0;0":"up there on roof (not visible)",
// "pikka;0;0":"up there on the wall, on the ceiling, or in the sky above eye level, up there away from the ocean, up there, upriver (restricted and visible)",
// "qagga;0;0":"out there (extended and visible)",
// "qamma;0;0":"in there (not visible)",
// "qavva;0;0":"up the coast (extended and visible), in Canada or Greenland (not visible at the moment but has been observed or seen previously)",
// "qaŋma;0;0":"out there in the shed or the neighbor’s house (not visible to speaker or listener who are inside another house)",
// "saŋma,sagma;0;0":"out there in the arctic entry (not visible)",
// "saŋma,sagma;0;1":"out there in the arctic entry (not visible)",
// "samma¹;0;0":"under here (not visible)",
// "samma²;0;0":"down there (e.g., far out on the ocean, downriver, or downstairs) (not visible), let me see it",
// "taiñña;0;0":"over there, across a flat area",
// "tavra,tarva;0;1":"there (restricted and visible)",
// "tavra,tarva;0;0":"there (restricted and visible)",
// "unna²;0;0":"down there (e.g., on or near the ocean, downriver, or by the door) (extended and visible)",
// "uvva;0;0":"here (restricted and visible)",
// }


let demRootExtras={
"⎡°ag⎤":"e.g., a river, a street, or a body of water",
"⎡°am⎤":"",
"⎡°av⎤":"",
"⎡°akim⎤":"e.g., across an expanse of water or land",
"⎡°Ik⎤":"e.g., at eye level",
"⎡°Im⎤":"",
"⎡°kan⎤":"e.g., on or near the ocean, downriver, or by the door",
"⎡°kIg⎤":"",
"⎡°kIv⎤":"e.g., up the coast",
"⎡°mar⎤":"also means 'here it is'",
"⎡°pag⎤":"e.g., on the wall, the ceiling, or in the sky above eye level, up there away from the ocean, up there, upriver",
"⎡°pam⎤":"e.g., inland, upriver, upstairs",
"⎡°pakim⎤":"",
"⎡°pIk⎤":"e.g., on the wall, on the ceiling, or in the sky above eye level, up there away from the ocean, up there, upriver",
"⎡°qag⎤":"",
"⎡°qam⎤":"",
"⎡°qav⎤":"e.g., up the coast, in Canada or Greenland (not visible at the moment but has been observed or seen previously)",
"⎡°qakim⎤":"e.g., in the shed or the neighbor’s house (not visible to speaker or listener who are inside another house)",
"⎡°sakim⎤":"e.g., out there in the arctic entry",
"⎡°sam¹⎤":"e.g., under here",
"⎡°sam²⎤":"e.g., far out on the ocean, downriver, or downstairs, also means 'let me see it'",
"⎡°uv⎤":"",
"⎡°un⎤":"e.g., on or near the ocean, downriver, or by the door",

"⎡°taag⎤":"e.g., a river, a street, or a body of water",
"⎡°taam⎤":"",
"⎡°taav⎤":"",
"⎡°taakim⎤":"e.g., across an expanse of water or land",
"⎡°taik⎤":"e.g., at eye level",
"⎡°taim⎤":"",
"⎡°takan⎤":"e.g., on or near the ocean, downriver, or by the door",
"⎡°tatkIg⎤":"",
"⎡°tatkIv⎤":"e.g., up the coast",
"⎡°tamar⎤":"also means 'here it is'",
"⎡°tatpag⎤":"e.g., on the wall, the ceiling, or in the sky above eye level, up there away from the ocean, up there, upriver",
"⎡°tatpam⎤":"e.g., inland, upriver, upstairs",
"⎡°tatpakim⎤":"",
"⎡°tatpIk⎤":"e.g., on the wall, on the ceiling, or in the sky above eye level, up there away from the ocean, up there, upriver",
"⎡°tatqa(g)⎤":"",
"⎡°tatqam⎤":"",
"⎡°tatqav⎤":"e.g., up the coast, in Canada or Greenland (not visible at the moment but has been observed or seen previously)",
"⎡°tatqakim⎤":"e.g., in the shed or the neighbor’s house (not visible to speaker or listener who are inside another house)",
"⎡°tasakim⎤":"e.g., out there in the arctic entry",
"⎡°tasam¹⎤":"e.g., under here",
"⎡°tasam²⎤":"e.g., far out on the ocean, downriver, or downstairs, also means 'let me see it'",
"⎡°tavr⎤,⎡°taam⎤":"also means 'that's correct or it's right there'",
"⎡°taun⎤":"e.g., on or near the ocean, downriver, or by the door",

}


let subjectOptions = [
  {
    key: 'he',
    text: 'he',
    value: '3Sg-1',
  },
  {
    key: 'she',
    text: 'she',
    value: '3Sg-2',
  },
  {
    key: 'it',
    text: 'it',
    value: '3Sg-3',
  },
  {
    key: 'the two of them',
    text: 'the two of them',
    value: '3Du',
  },
  {
    key: 'they all (3+)',
    text: 'they all (3+)',
    value: '3Pl',
  },
  {
    key: 'I',
    text: 'I',
    value: '1Sg',
  },
  {
    key: 'the two of us',
    text: 'the two of us',
    value: '1Du',
  },
  {
    key: 'we all (3+)',
    text: 'we all (3+)',
    value: '1Pl',
  },
  {
    key: 'you',
    text: 'you',
    value: '2Sg',
  },
  {
    key: 'the two of you',
    text: 'the two of you',
    value: '2Du',
  },
  {
    key: 'you all (3+)',
    text: 'you all (3+)',
    value: '2Pl',
  },
]




// const tableTitleOptions = [
//   {
//     key: 'Demonstrative roots',
//     text: 'Demonstrative roots',
//     value: 'roots',
//   },
//   {
//     key: 'Demonstrative adverbs',
//     text: 'Demonstrative adverbs',
//     value: 'adverbs',
//   },
//   {
//     key: 'Demonstrative abs. pronouns',
//     text: 'Demonstrative abs.if (true) {} pronouns',
//     value: 'pronouns',
//   },
// ]


class DemonstrativeTables extends Component {
	constructor(props) {
		super(props);
		// console.log(props)
		this.state = {
			activeIndex: -1,
			activeIndex2: -1,
			// activeIndex3: -1,
			demTableType: props.location.state === undefined ? ['roots',''] : props.location.state.demTableType,
            demonstrativerootdata: {},
			currentStem:"igña",
			currentStem2:'ikka',
			currentStem3:"⎡°Ik⎤",
			currentRoot:decodeURI(props.match.params.word),
			currentLocation:'',
			currentVisibility:'',
			playingAudio:false,
            currentVerbTense:'present',
            currentVerbSubject:'3Sg',
            currentVerbVSubject:'3Sg-1',
            currentAccountTense:'firsthand',
            seeMoreExamples:false,
            seeMoreExamples2:false,
			// future:true,
			// firstperson:true,
            // demProTable:{},
			// show: false,
			// word: decodeURI(props.match.params.word),
			// history: props.location.state === undefined ? [] : props.location.state.history,
			// from: props.location.state === undefined ? '' : props.location.state.from,
			// entryData: {},
			// keySplit: [],
			// definition: [],
			// key: '',
			// verbalIllustrations: [],
			// etymology: [],
			// parent: '',
			// children: [],
			// childrenEntries: {},
			// relatedWords: {},
			// relatedWordsEntries: {},
			// parentEntries: {},
			// postbaseEntries: [],
			// halfTransitive: [],
			// halfTransitiveEtymology: [],
			// postbaseExamples: [],
			// audioMatrix: [],
			// exampleAudioMatrix: [],
			// extraInfo: [],
   //    entryModified: [],
   //    activeEditIndex:-1,
   //    baseOptions:[],
   //    activeKeyInEditIndex:0,
   //    nounMood:'Absolutive',
   //    verbMood:'Indicative',
   //    fstCall:'',
   //    irregular:[],
   //    extraInfo:[],
   //    objectPossessed:false,
   //    loaded:false,
   //    entryUsageData: props.usagedictionary_dict[decodeURI(props.match.params.word)] === undefined ? {} : this.props.usagedictionary_dict[decodeURI(props.match.params.word)],
		}
	    // this.getWord = this.getWord.bind(this);
	    // this.getWord(decodeURI(props.match.params.word));
	    scroll.scrollTo(0);
	}



	componentDidMount(){

        // console.log(this.state.demTableType)
        let proIndexes = {
            'abs':0,
            'rel':1,
            'mod':2,
            'loc':3,
            'ter':4,
            'abl':5,
            'via':6,
            'sim':7,            
        }

        let advIndexes = {
            'base':0,
            'loc':1,
            'ter':2,
            '2ter':3,
            'abl':4,
            '2abl':5,
            'via':6,
        }
        if (this.state.demTableType[0] == 'pro') {
            setTimeout(function() {scroll.scrollTo(1000);}.bind(this), 200)
            if (this.state.demTableType[1].length > 0) {
                this.setState({activeIndex2:proIndexes[this.state.demTableType[1]]})
            } 
        } else if (this.state.demTableType[0] == 'adv') {
            if (this.state.demTableType[1].length > 0) {
                this.setState({activeIndex:advIndexes[this.state.demTableType[1]]})
            }             
        }


        if (demStems.includes(this.state.currentRoot)) {
            this.getDemData(this.state.currentRoot)
            this.setState({currentLocation:demLookup[demRoot[this.state.currentRoot]['adv'][0]][0], currentVisibility:demLookup[demRoot[this.state.currentRoot]['adv'][0]][1]})         
            // this.getDemData(this.state.currentRoot)
        } else {
            this.props.history.push('/demonstratives')
        }
		// if (this.state.currentRoot.length > 0) {
		// 	this.setState({currentLocation:demLookup[demRoot[this.state.currentRoot]['adv'][0]][0], currentVisibility:demLookup[demRoot[this.state.currentRoot]['adv'][0]][1]})			
		// }
		// this.getWord(decodeURI(this.props.match.params.word));
	}

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.currentRoot !== this.state.currentRoot) {
    //   // this.setState({loaded:true})
    //   this.getDemData(this.state.currentRoot)
    // }
    if (prevState.currentRoot !== this.state.currentRoot && this.state.currentRoot.length !== 0) {
      this.setState({activeIndex: -1, activeIndex2: -1,seeMoreExamples:false, seeMoreExamples2:false, currentLocation:demLookup[demRoot[this.state.currentRoot]['adv'][0]][0], currentVisibility:demLookup[demRoot[this.state.currentRoot]['adv'][0]][1]})         
      window.history.replaceState(null, '', '/demonstratives/'+this.state.currentRoot);
      this.getDemData(this.state.currentRoot)
      // this.getDemData(this.state.currentRoot)
    }

    if (prevState.activeIndex !== this.state.activeIndex) {
        this.setState({currentVerbTense:'present',currentVerbSubject:'3Sg',currentVerbVSubject:'3Sg-1',seeMoreExamples:false})
    }

    if (prevState.activeIndex2 !== this.state.activeIndex2) {
        this.setState({seeMoreExamples2:false})
    }
    // else if (prevProps.match.params.word !== this.props.match.params.word) {
    // 	this.getWord(decodeURI(this.props.match.params.word));
    // }
  }

  getDemData(root) {
    // scroll.scrollTo(0);
    // axios
    //   .get(API_URL + "/inupiaqusage/" + encodeURIComponent(word))
    //   .then(response => {
    //     this.setState({
    //       entryUsageData: response.data,
    //     });
    //   })
    // let entryUsageData = {}
    // if (this.props.usagedictionary_dict[decodeURI(this.props.match.params.word)] !== undefined) {
    //         entryUsageData = this.props.usagedictionary_dict[decodeURI(this.props.match.params.word)]
    // }
            

    axios
      .get(API_URL + "/demonstrativerootdata/" + root)
      .then(response => {
        // console.log(response.data);
        this.setState({
          demonstrativerootdata:response.data,
        },()=>{
            this.setState({loaded:true})
        })
      });
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({ 
			from: nextProps.location.pathname,
			// fullDefinition: nextProps.fullDefinition,
		})
	}

  capitalize = (word, event, data) => {
    // console.log(word)
    // console.log(this.state)
    if (word !== undefined) {
        if (word.length > 0) {
            return word[0].toUpperCase() + word.slice(1)
        } else {
            return word
        }        
    }
  }

  handleClick = (e, titleProps) => {
    // console.log(e,titleProps)
    // this.setState({ loaderOn: true });
    const { index, currentIndex } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    // let mood = moods[index];
    // if (newIndex !== -1) {   
      // this.getEndings(this.state.parses[currentIndex], mood);
    // }
    if (index > activeIndex && activeIndex !== -1) {
        // window.scrollBy(0,-parseInt(style.getPropertyValue("height"))-321)
        scroll.scrollTo(600+index*50)
        // setTimeout(function() {window.scrollBy({top:-parseInt(style.getPropertyValue("height"))-321,behavior:'smooth'})}.bind(this), 1000)
    }
    this.setState({ activeIndex: newIndex, activeIndexHeight:0 });
  };

  handleClick2 = (e, titleProps) => {
    // console.log(e,titleProps)
    // this.setState({ loaderOn: true });
    const { index, currentIndex } = titleProps;
    const { activeIndex2 } = this.state;
    const newIndex = activeIndex2 === index ? -1 : index;
    // let mood = moods[index];
    // if (newIndex !== -1) {   
      // this.getEndings(this.state.parses[currentIndex], mood);
    // }

    var style = window.getComputedStyle(document.getElementById("advaccordion"), null);
    // console.log(parseInt(style.getPropertyValue("height"))-321)
    // console.log(document.documentElement.scrollTop)

    if (index > activeIndex2 && activeIndex2 !== -1) {
        // window.scrollBy(0,-parseInt(style.getPropertyValue("height"))-321)
        scroll.scrollTo(1500+parseInt(style.getPropertyValue("height"))-321+index*55)
        // setTimeout(function() {window.scrollBy({top:-parseInt(style.getPropertyValue("height"))-321,behavior:'smooth'})}.bind(this), 1000)
    }

    this.setState({ activeIndex2: newIndex });
  };

  // handleClick3 = (e, titleProps) => {
  //   // console.log(e,titleProps)
  //   // this.setState({ loaderOn: true });
  //   const { index, currentIndex } = titleProps;
  //   const { activeIndex3 } = this.state;
  //   const newIndex = activeIndex3 === index ? -1 : index;
  //   // let mood = moods[index];
  //   // if (newIndex !== -1) {   
  //     // this.getEndings(this.state.parses[currentIndex], mood);
  //   // }
  //   this.setState({ activeIndex3: newIndex });
  // };

  getIs = (word, sub, event, data) => {
    // let sub = this.state.currentVerbSubject
    // console.log(word, sub)
    if (word == 'is') {
        if (sub == '1Sg') {
            return 'am'
        } else if (sub == '3Sg') {
            return 'is'
        } else {
            return 'are'
        }
    } else if (word == 'was') {
        if (sub == '1Sg' || sub == '3Sg') {
            return 'was'
        } else {
            return 'were'
        }
    } else {
        return word
    }
  }


  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replaceAll('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }
  	processStyledText = (sentence) => {
      // sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("«","").replaceAll("»","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
  	}

    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }
    
  handleTitleChange = (e, { value }) => {
  	this.setState({ demTableType:value })
  }

	render() {
		// console.log(this.state)
		let postbaseTableOn = false
    let postbaseExampleOn = false
    let examplesShown = 5

    const { activeIndex, activeIndex2 } = this.state;

    // const { activeIndex2 } = this.state;
		// console.log(this.props.location)
		// console.log(this.props)
	
		return (
			<div style={{fontFamily:customFontFam}}>
            {this.state.currentRoot in this.props.demonstrativedata ?
			<Transition visible={this.state.loaded} animation='fade' duration={300}>

      <Grid textAlign='center'>
      <Grid.Row  style={{height:40,paddingBottom:0}}>
      <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>

			<div style={{display:'flex'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					
	      </div>

	      {this.state.currentRoot.length > 0 ?
	      	<Link to={{pathname: "/demonstratives", state: { history:this.state.history }}} >
			      <div style={{cursor:'pointer',flex:1,display:'flex',justifyContent:'flex-end',paddingTop:5}}>
							<span style={{fontSize:'18px',fontWeight:'400',textDecoration:'underline',color:'#4a80b5'}}>
							{window.innerWidth < 480 ? 'See Dem. Table' : 'See Demonstrative Table'}
							</span>
						</div>
	      	</Link>
					:
					null
				}
			</div>


					<div style={{border:'1px solid #E3E3E3',marginTop:'10px'}}>

					<div style={{marginBottom:25}} className='hierarchymain'>
					<span className='span1'>Tikkuaġautit</span>
					<span className='span2'>Demonstratives</span>
					</div>
    
                    {Object.keys(this.props.demonstrativedata[this.state.currentRoot]).length !== 0 && Object.keys(this.props.dictionarydict).length !== 0 ?

					<Transition visible={this.state.loaded} animation='fade' duration={400}>
					<div>
						<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>
						<Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'5px',borderRadius:'5px'}} text={<span style={{color:'black', fontWeight:'600'}}>{demRoot[this.state.currentRoot]['adv'][1]}</span>}>
		          <Dropdown.Menu style={{maxHeight:130}}>
		          	{demStems.map((q)=><Dropdown.Item onClick={()=>{this.setState({currentRoot:q})}} style={{fontWeight:(this.state.currentRoot == q ? '600':'400')}}>{demRoot[q]['adv'][1]}</Dropdown.Item>)}
		          </Dropdown.Menu>
		        </Dropdown>
		        </div>
		        
						<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>

						<Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}} text={<span style={{color:'black'}}>{demDirection[demLookup[demRoot[this.state.currentRoot]['adv'][0]][0]]}</span>}>
		          <Dropdown.Menu style={{maxHeight:130}}>
		          	{demDirectionSorted.map((q)=><Dropdown.Item onClick={()=>{
		          		if (this.state.currentVisibility in demChart[q]) {this.setState({currentRoot:demChart[q][this.state.currentVisibility]})} else {this.setState({currentRoot:demChart[q]['not']})}
		          	}} style={{fontWeight:(demLookup[demRoot[this.state.currentRoot]['adv'][0]][0] == q ? '600':'400')}}>{demDirection[q]}</Dropdown.Item>)}
		          </Dropdown.Menu>
		        </Dropdown>

						<Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}} text={<span style={{color:'black'}}>{demVisibility[demLookup[demRoot[this.state.currentRoot]['adv'][0]][1]]}</span>}>
		          <Dropdown.Menu style={{maxHeight:130}}>
		          	{demVisibilitySorted.map((q)=>
		          		{if (this.state.currentLocation.length > 0) {
 										if (q in demChart[this.state.currentLocation]) {
		          			return <Dropdown.Item onClick={()=>{this.setState({currentRoot:demChart[this.state.currentLocation][q]})}} style={{fontWeight:(demLookup[demRoot[this.state.currentRoot]['adv'][0]][1] == q ? '600':'400')}}>{demVisibility[q]}</Dropdown.Item>		          			
		          			}
		          		}
		          		}
		          		)}
		          </Dropdown.Menu>
		        </Dropdown>

						</div>

                        <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',paddingBottom:'10px',paddingTop:'10px',fontSize:'16px',color:'#000000CC'}}>
                            {this.state.currentLocation.includes('-tat') ?
                                <span style={{marginRight:'4px',cursor:'pointer'}}>
                                  <TagColors tags={'tat'} width={window.innerWidth} />
                                </span>
                                :
                                null
                            }
                            <span style={{cursor:'pointer'}}>
                              <TagColors tags={this.state.currentVisibility} width={window.innerWidth} />
                            </span>
                        </div>

						{demRootExtras[this.state.currentRoot].length > 0 ?
							<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',paddingBottom:'10px',paddingTop:'5px',fontSize:'16px',color:'#000000CC'}}>
							<div style={{paddingRight:20,paddingLeft:20,textAlign:'center'}}>{demRootExtras[this.state.currentRoot]}</div>
							</div>
							:
							null
						}


						<div style={{marginTop:'17px',marginBottom:'18px'}}>
							<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',paddingBottom:'10px',fontSize:'16px',fontStyle:'italic',color:'#777777'}}>Adverb Forms</div>

						<div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>
                        <Segment style={{margin:5,padding:0,border:'none',overflow: 'auto',fontSize:16}}>
			          <Table unstackable basic compact collapsing>
			            <Table.Header>
			              <Table.Row>
			                <Table.HeaderCell style={{textDecoration:'underline',color:"#000000CC",fontWeight:500}}>case</Table.HeaderCell>
			                <Table.HeaderCell />
			              </Table.Row>
			            </Table.Header>
			            <Table.Body>
			            {adverbCase.map((l,lindex) =>
			            	(Object.keys(this.props.demonstrativedata[this.state.currentRoot]['adv'][l]).length > 0 ?
			              <Table.Row>
			                <Table.HeaderCell style={{color:"#000000CC",fontWeight:400}}><span>{lindex !== 0 ? l+'.' : 'sta.'}</span></Table.HeaderCell>
			                <Table.Cell style={{paddingLeft:10,fontSize:16,fontWeight:500}}>
                                {'text' in this.props.demonstrativedata[this.state.currentRoot]['adv'][l]['word'] ?
                                <span>{this.props.demonstrativedata[this.state.currentRoot]['adv'][l]['word']['text']}</span>
                                :
                                null
                                }
                                
			                	{'audio' in this.props.demonstrativedata[this.state.currentRoot]['adv'][l]['word'] ?
								<Icon onClick={()=>this.repeatAudio(this.props.demonstrativedata[this.state.currentRoot]['adv'][l]['word']['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
								:
								null
			                	}
			                </Table.Cell>
			              </Table.Row>
			              :
			              null
			              )
			            )}
			            </Table.Body>
			          </Table>
                      </Segment>
						</div>
            				<div style={{display:'flex',justifyContent:'center'}}>
            	                <Accordion id='advaccordion' style={{ margin:20,fontSize: 16,}} styled>
            	                  {adverbCase.map((p, pindex) => {
                                    let pdisplay = p
                                    if (pdisplay == 'base') {pdisplay = 'sta'}
            	                  	if (Object.keys(this.props.demonstrativedata[this.state.currentRoot]['adv'][p]).length) {
                                        return <div>
                                      <Accordion.Title
                                        active={activeIndex === pindex}
                                        index={pindex}
                                        onClick={this.handleClick}
                                      >
                                      <div style={{display:'flex',alignItems:'baseline',flexDirection:'row'}}>
                                          <Icon name="dropdown" />
                                          <div style={{fontFamily:customFontFam, marginLeft:10,fontWeight:500}}>{this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['text']}</div>
                                          <div style={{fontFamily:customFontFam, marginLeft:25,fontWeight:400}}><span>{adverbEnglish[p]}</span>{' '}<span>{demDirectionText[this.props.demonstrativedata[this.state.currentRoot]['english']]}</span>{' '}<span>{demVisibilityAcronym[this.props.demonstrativedata[this.state.currentRoot]['vis']]}</span></div>
                                      </div>
                                      </Accordion.Title>

                                      {Object.keys(this.state.demonstrativerootdata).length > 0 ?
                                      <Accordion.Content
                                        active={activeIndex === pindex}
                                      >

                                        <div style={{marginLeft:15,paddingTop:15,paddingBottom:15,lineHeight:'20px',fontWeight:'400'}}><span style={{fontStyle:'italic',color:'#777777',marginRight:'7px',fontWeight:'400'}}>{'Case:'}</span><span style={{fontWeight:'400'}}>{pdisplay+'.'}</span><span style={{marginLeft:'12px',cursor:'pointer'}}><TagColors tags={'adv-'+p} width={window.innerWidth} /></span></div>


                                    {'alts' in this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word'] ?
                                        <div style={{marginLeft:15,paddingTop:15,paddingBottom:15,lineHeight:'20px'}}><span style={{fontStyle:'italic',fontStyle:'italic',color:'#777777',marginRight:'12px'}}>{this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['alts'].length > 1 ? 'Alternate Forms:':'Alternate Form:'}</span>{this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['alts'].map((k)=><span>{k}</span>)}</div>
                                        :
                                        null
                                    }

                                      {"examples" in this.state.demonstrativerootdata['adv'][p] || "vForms" in this.state.demonstrativerootdata['adv'][p] ?
                                          <div style={{marginTop:15,marginLeft:15,marginBottom:12,lineHeight:'20px',fontStyle:'italic',color:'#777777'}}>Examples:</div>
                                          :
                                          null
                                        }


                                      {"examples" in this.state.demonstrativerootdata['adv'][p]?
                                        (this.state.demonstrativerootdata['adv'][p]["examples"].length > examplesShown ?
                                              <div style={{margin:0,padding:0}}>
                                              {this.state.demonstrativerootdata['adv'][p]["examples"].slice(0,examplesShown).map((k)=>
                                                    <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>                           
                                                      <div style={{fontFamily:customFontFam,fontWeight:500,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['inu']))}</span><span>{k['inu'][0] !== '“' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span>
                                                                {'audio' in k > 0 ?
                                                                                <Icon onClick={()=>this.repeatAudio(k['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                                                                                :
                                                                                null
                                                                }                                 
                                                      </div>
                                                      <div style={{fontFamily:customFontFam,fontWeight:400,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['eng']).trim())}</span><span>{k['eng'][0] !== '[' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span></div>
                                                    </div>                                              
                                                )}

                                                {!this.state.seeMoreExamples ? 
                                                    <Button
                                                      basic
                                                      style={{fontFamily: "sans-serif", fontSize: 16, marginLeft:15, marginBottom:6}}
                                                      onClick={() => {
                                                        this.setState({
                                                          seeMoreExamples:
                                                            true
                                                        });
                                                      }}
                                                    >
                                                    <div>
                                                      {"See More Examples"}
                                                      <Icon
                                                        style={{ paddingLeft: 10 }}
                                                        name="chevron down"
                                                      />
                                                    </div>
                                                    </Button>
                                                    :
                                                      (this.state.demonstrativerootdata['adv'][p]["examples"].slice(examplesShown).map((k)=>
                                                            <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>                           
                                                              <div style={{fontFamily:customFontFam,fontWeight:500,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['inu']))}</span><span>{k['inu'][0] !== '“' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span>
                                                                        {'audio' in k > 0 ?
                                                                                        <Icon onClick={()=>this.repeatAudio(k['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                                                                                        :
                                                                                        null
                                                                        }                                 
                                                              </div>
                                                              <div style={{fontFamily:customFontFam,fontWeight:400,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['eng']).trim())}</span><span>{k['eng'][0] !== '[' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span></div>
                                                            </div>                                              
                                                        ))
                                                }
                                              </div>
                                            :
                                              <div style={{margin:0,padding:0}}>
                                              {this.state.demonstrativerootdata['adv'][p]["examples"].map((k)=>
                                                    <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>                           
                                                      <div style={{fontFamily:customFontFam,fontWeight:500,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['inu']))}</span><span>{k['inu'][0] !== '“' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span>
                                                                {'audio' in k > 0 ?
                                                                                <Icon onClick={()=>this.repeatAudio(k['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                                                                                :
                                                                                null
                                                                }                                 
                                                      </div>
                                                      <div style={{fontFamily:customFontFam,fontWeight:400,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['eng']).trim())}</span><span>{k['eng'][0] !== '[' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span></div>
                                                    </div>                                              
                                                )}
                                              </div>
                                          )
                                          :
                                          null
                                        }

                                {"vForms" in this.state.demonstrativerootdata['adv'][p] || p == 'loc' ?
                                <div>
                                    <div style={{display:'flex',flexDirection:'row'}}>
                                    <div style={{marginLeft:15,marginTop:3,color:'#000000DE'}}>{'a.'}</div>
                                    <div>
                                    <div style={{display:'flex',flexDirection:'column',marginLeft:15,marginBottom:5,lineHeight:'23px'}}>
                                      <div style={{fontFamily:customFontFam, fontWeight:500,padding:'1px 0px'}}>
                                        <span style={{color:"000000DE"}}>{demonstrativeQuestions[p][0]}</span>
                                      </div>
                                      <div style={{fontFamily:customFontFam,  fontWeight:400,padding:'2px 0px'}}>
                                        <span style={{color:"000000DE"}}>{demonstrativeQuestions[p][1]}</span>
                                      </div>
                                    </div>      
                                    <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>
                                      <div style={{fontFamily:customFontFam, fontWeight:500,padding:'1px 0px'}}>
                                        <span style={{color:"000000DE"}}>{this.capitalize(this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['text'])+'.'}</span>
                                      </div>
                                      <div style={{fontFamily:customFontFam, fontWeight:400,padding:'2px 0px'}}>
                                       <span style={{color:"000000DE"}}><span>{this.capitalize(adverbEnglish[p])}</span>{' '}<span>{demDirectionText[this.props.demonstrativedata[this.state.currentRoot]['english']]+'.'}</span></span>
                                        <span style={{marginLeft:'5px',cursor:'pointer'}}>
                                          <TagColors tags={this.state.currentVisibility} width={window.innerWidth} />
                                        </span>
                                      </div>
                                    </div>      
                                    </div>
                                    </div>

                                    <div style={{display:'flex',flexDirection:'row'}}>
                                    <div style={{marginLeft:15,marginTop:3,color:'#000000DE'}}>{'b.'}</div>
                                            <div>
                                                <div style={{marginLeft:15,marginBottom:5,lineHeight:'23px'}}>
                                                  <div style={{fontFamily:customFontFam, fontWeight:500,padding:'2px 0px'}}>
                                                    <span style={{color:"000000DE"}}>{this.state.currentVerbTense == 'future' ? this.capitalize(this.props.demonstrativedata['qWords'][p]['future'][this.state.currentAccountTense][vSubjectToQuestionSubject[this.state.currentVerbVSubject]])+'?' : this.capitalize(this.props.demonstrativedata['qWords'][p]['prespast'][this.state.currentAccountTense][vSubjectToQuestionSubject[this.state.currentVerbVSubject]])+'?'}</span>
                                                  </div>
                                                  <div style={{fontFamily:customFontFam,  fontWeight:400,padding:'2px 0px'}}>
                                                    <span style={{color:"000000DE"}}>{vFormQuestionEnglish[this.state.currentVerbTense][p][0]+' '+this.getIs(vFormQuestionEnglish[this.state.currentVerbTense][p][1],vSubjectToQuestionSubject[this.state.currentVerbVSubject])+' '+vSubjectToEnglish[vSubjectToQuestionAnswerSubject[this.state.currentVerbVSubject]]+vFormQuestionEnglish[this.state.currentVerbTense][p][2]}</span>
                                                  </div>
                                                </div>
                                                <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>
                                                  <div style={{fontFamily:customFontFam, fontWeight:500,padding:'2px 0px'}}>
                                                    {p == 'loc' ?
                                                        <span style={{color:"000000DE"}}>{this.capitalize(this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['text'])+' '+this.props.demonstrativedata['existWords']['ind'][this.state.currentVerbTense][this.state.currentAccountTense][this.state.currentVerbSubject]+'.'}</span>
                                                        :
                                                        <span style={{color:"000000DE"}}>{this.capitalize(this.state.demonstrativerootdata['adv'][p]['vForms'][this.state.currentVerbTense][this.state.currentAccountTense][this.state.currentVerbSubject])+'.'}</span>
                                                    }
                                                  </div>
                                                    <div style={{fontFamily:customFontFam,lineHeight:'23px',}}>
                                                        <Dropdown inline scrolling style={{fontFamily:customFontFam,border:'solid 1px #22242626',padding:'2px 5px',fontSize:'16px',borderRadius:'5px',color:'#000000DE'}} text={this.capitalize(vSubjectToEnglish[this.state.currentVerbVSubject])}>
                                                        <Dropdown.Menu style={{maxHeight:130}}>
                                                          {subjectOptions.map((l,lindex)=>{                                    
                                                            return <Dropdown.Item onClick={()=>this.setState({currentVerbVSubject:l.value, currentVerbSubject: vSubjectToSubject[l.value]})} active={this.state.currentVerbVSubject == l.value}><span style={{color:'#000000DE'}}>{this.capitalize(l.text)}</span></Dropdown.Item>                                 
                                                          })}
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                        <span style={{marginLeft:'5px',color:"000000DE"}}>{this.getIs(vFormAnswerEnglish[this.state.currentVerbTense][p][0],this.state.currentVerbSubject)+(vFormAnswerEnglish[this.state.currentVerbTense][p][0].length > 0 ? ' ':'')+vFormAnswerEnglish[this.state.currentVerbTense][p][1]+' '+demDirectionText[this.props.demonstrativedata[this.state.currentRoot]['english']]+vFormAnswerEnglish[this.state.currentVerbTense][p][2]}</span>
                                                        <span style={{marginLeft:'5px',cursor:'pointer'}}>
                                                          <TagColors tags={this.state.currentVisibility} width={window.innerWidth} />
                                                        </span>
                                                    </div>                  
                                                </div>
                                                <div>
                                                  <Button.Group style={{marginLeft:'15px',marginBottom:'15px'}} size='tiny'>
                                                    <Button onClick={()=>this.setState({currentVerbTense:'past'})} style={{background:(this.state.currentVerbTense == 'past' ? '#e7e7e7':'white'),border:'1px solid #dededf'}}>Past</Button>
                                                    <Button onClick={()=>this.setState({currentVerbTense:'present'})} style={{background:(this.state.currentVerbTense == 'present' ? '#e7e7e7':'white'),borderTop:'1px solid #dededf',borderBottom:'1px solid #dededf'}}>Present</Button>
                                                    <Button onClick={()=>this.setState({currentVerbTense:'future'})} style={{background:(this.state.currentVerbTense == 'future' ? '#e7e7e7':'white'),border:'1px solid #dededf'}}>Future</Button>
                                                  </Button.Group>   
                                                </div>
                                                <div>
                                                  <Button.Group vertical={window.innerWidth < 480 ? true : false} style={{marginLeft:'15px',marginBottom:'15px'}} size='tiny'>
                                                    <Button onClick={()=>this.setState({currentAccountTense:'firsthand'})} style={{background:(this.state.currentAccountTense == 'firsthand' ? '#e7e7e7':'white'),border:'1px solid #dededf',borderRight:(window.innerWidth < 480?'1px solid #dededf':''),borderBottom:(window.innerWidth < 480?'':'1px solid #dededf')}}>Firsthand Information</Button>
                                                    <Button onClick={()=>this.setState({currentAccountTense:'secondhand'})} style={{background:(this.state.currentAccountTense == 'secondhand' ? '#e7e7e7':'white'),border:'1px solid #dededf'}}>Secondhand Information</Button>
                                                  </Button.Group>   
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    null
                                }



                                  {"links" in this.state.demonstrativerootdata['adv'][p] ?
                                  <div>
                                      <div style={{marginTop:25,marginLeft:15,lineHeight:'20px',fontStyle:'italic',color:'#777777'}}>Entry Links:</div>
                                        <List style={{marginTop:12,paddingTop:0}}>
                                        {this.state.demonstrativerootdata['adv'][p]["links"].map((q)=>
                                                    <List.Item>
                                                    <Link to={{pathname: '/' + this.props.dictionarydict[q].url, state: { word: this.props.dictionarydict[q].url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                                                      <List.Content floated='right'>
                                                        <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                                                      </List.Content>
                                                    </Link>
                                                                  <List.Content style={{paddingTop:0,paddingBottom:0}}>
                                                                    <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
                                                                      {this.props.dictionarydict[q].keySplit.map((w,index) =>
                                                                            <div style={{display:'flex'}}> 
                                                              <Link style={{color:'#306190'}} to={{pathname: '/' + this.props.dictionarydict[q].url, state: { word: this.props.dictionarydict[q].url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                                                                          <span style={{'paddingRight':'3px',fontWeight:'500',borderBottom:'1px solid #858585',paddingBottom:'1px',}}>
                                                              {this.processStyledTextLink(w[0])}
                                                              </span>
                                                              </Link>

                                                                          {w[2] !== [""] ?
                                                                            <span style={{'marginLeft':'15px',marginRight:'6px'}}>  
                                                                  {w[1][0] !== '' ?
                                                                    <span style={{marginRight:'5px',cursor:'pointer'}}>
                                                                      <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
                                                                    </span>
                                                                    :
                                                                    ''
                                                                  }

                                                                                                {w[2].map((tag) => 
                                                                                                    <span style={{marginRight:'5px',cursor:'pointer'}}>
                                                                                                        <TagColors tags={tag} width={window.innerWidth} />
                                                                                                    </span>
                                                                                                    )}
                                                                            </span>
                                                                            :
                                                                            ''
                                                                          }
                                                                          </div>
                                                                        )}
                                                                    </List.Header>
                                                                    <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(this.props.dictionarydict[q].fullDefinition)}</List.Description>
                                                                  </List.Content>
                                                                </List.Item>
                                        )}
                                                            </List>
                                                            </div>
                                                            :
                                                            null
                                                          }
                                      </Accordion.Content>
                                      :
                                      null
                                  }
                                    </div>
                                    }})}
            	                </Accordion>
            	           	</div>
						</div>


						<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>
						<Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'5px',borderRadius:'5px'}} text={<span style={{color:'black', fontWeight:'600'}}>{demRoot[this.state.currentRoot]['pro'][1]}</span>}>
		          <Dropdown.Menu style={{maxHeight:130}}>
		          	{demStems.map((q)=><Dropdown.Item onClick={()=>{this.setState({currentRoot:q})}} style={{fontWeight:(this.state.currentRoot == q ? '600':'400')}}>{demRoot[q]['pro'][1]}</Dropdown.Item>)}
		          </Dropdown.Menu>
		        </Dropdown>
		        </div>
		        
						<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>
						<span style={{margin:'4px 4px',lineHeight:'32px',textAlign:'center'}}>{'that one'}</span>

						<Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}} text={<span style={{color:'black'}}>{demDirection[demLookup[demRoot[this.state.currentRoot]['pro'][0]][0]]}</span>}>
		          <Dropdown.Menu style={{maxHeight:130}}>
		          	{demDirectionSorted.map((q)=><Dropdown.Item onClick={()=>{
		          		if (this.state.currentVisibility in demChart[q]) {this.setState({currentRoot:demChart[q][this.state.currentVisibility]})} else {this.setState({currentRoot:demChart[q]['not']})}
		          	}} style={{fontWeight:(demLookup[demRoot[this.state.currentRoot]['adv'][0]][0] == q ? '600':'400')}}>{demDirection[q]}</Dropdown.Item>)}
		          </Dropdown.Menu>
		        </Dropdown>

						<Dropdown inline scrolling style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}} text={<span style={{color:'black'}}>{demVisibility[demLookup[demRoot[this.state.currentRoot]['pro'][0]][1]]}</span>}>
		          <Dropdown.Menu style={{maxHeight:130}}>
		          	{demVisibilitySorted.map((q)=>
		          		{if (this.state.currentLocation.length > 0) {
 										if (q in demChart[this.state.currentLocation]) {
		          			return <Dropdown.Item onClick={()=>{this.setState({currentRoot:demChart[this.state.currentLocation][q]})}} style={{fontWeight:(demLookup[demRoot[this.state.currentRoot]['pro'][0]][1] == q ? '600':'400')}}>{demVisibility[q]}</Dropdown.Item>		          			
		          			}
		          		}
		          		}
		          		)}
		          </Dropdown.Menu>
		        </Dropdown>

						</div>
                        <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',paddingBottom:'10px',paddingTop:'10px',fontSize:'16px',color:'#000000CC'}}>
                            {this.state.currentLocation.includes('-tat') ?
                                <span style={{marginRight:'4px',cursor:'pointer'}}>
                                  <TagColors tags={'tat'} width={window.innerWidth} />
                                </span>
                                :
                                null
                            }
                            <span style={{cursor:'pointer'}}>
                              <TagColors tags={this.state.currentVisibility} width={window.innerWidth} />
                            </span>
                        </div>


						<div style={{marginTop:'17px',marginBottom:'18px'}}>
							<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',paddingBottom:'10px',fontSize:'16px',fontStyle:'italic',color:'#777777'}}>Pronoun Forms</div>
						<div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>
                        <Segment style={{margin:5,padding:0,border:'none',overflow: 'auto',fontSize:16}}>
			          <Table unstackable basic compact collapsing>
			            <Table.Header>
			              <Table.Row>
			                <Table.HeaderCell style={{minWidth:65}}></Table.HeaderCell>
			                <Table.HeaderCell style={{textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}>number</Table.HeaderCell>
			                <Table.HeaderCell style={{minWidth:80}}></Table.HeaderCell>
			                <Table.HeaderCell></Table.HeaderCell>
			              </Table.Row>
			              <Table.Row>
			                <Table.HeaderCell style={{textDecoration:'underline',color:"#000000CC",fontWeight:500}}>case</Table.HeaderCell>
			                <Table.HeaderCell style={{"color":"#000000CC",fontWeight:400}}>{"singular (1)"}</Table.HeaderCell>
			                <Table.HeaderCell style={{"color":"#000000CC",fontWeight:400}}>{"dual (2)"}</Table.HeaderCell>
			                <Table.HeaderCell style={{"color":"#000000CC",fontWeight:400}}>{"plural (3+)"}</Table.HeaderCell>
			              </Table.Row>
			            </Table.Header>
			            <Table.Body>
			            {pronounCase.map((l,lindex) =>
			              <Table.Row>
			                <Table.HeaderCell style={{color:"#000000CC",fontWeight:400}}>{l+'.'}</Table.HeaderCell>
			                {['sg','du','pl'].map((i,index) =>
			                <Table.Cell style={{paddingLeft:10,fontSize:16,fontWeight:500}}>
                                <span>{this.props.demonstrativedata[this.state.currentRoot]['pro'][l][[i]]['text']}</span>
			                	{'audio' in this.props.demonstrativedata[this.state.currentRoot]['pro'][l][[i]] ?
												<Icon onClick={()=>this.repeatAudio(this.props.demonstrativedata[this.state.currentRoot]['pro'][l][[i]]['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
												:
												null
			                	}
			                </Table.Cell>
			                )}
			              </Table.Row>
			            )}
			            </Table.Body>
			          </Table>
                      </Segment>
					</div>

				
                <div style={{display:'flex',justifyContent:'center'}}>
                                <Accordion style={{ margin:20,fontSize: 16,}} styled>
                                  {pronounCase.map((e, eindex) => 
                                    (Object.keys(this.props.demonstrativedata[this.state.currentRoot]['pro'][e]).length > 0 ?
                                    <div>
                                      <Accordion.Title
                                        active={activeIndex2 === eindex}
                                        index={eindex}
                                        onClick={this.handleClick2}
                                      >
                                      <div style={{display:'flex',alignItems:'baseline',flexDirection:'row'}}>
                                          <Icon name="dropdown" />
                                          <div style={{fontFamily:customFontFam, marginLeft:10,fontWeight:500}}>{this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['sg']['text']}</div>
                                          <div style={{fontFamily:customFontFam, marginLeft:25,fontWeight:400}}><span>{this.state.currentRoot == '⎡°uv⎤' ? pronounEnglishThis[e] : pronounEnglish[e]}</span>{' '}<span>{demDirectionText[this.props.demonstrativedata[this.state.currentRoot]['english']]}</span>{' '}<span>{demVisibilityAcronym[this.props.demonstrativedata[this.state.currentRoot]['vis']]}</span></div>
                                      </div>
                                      </Accordion.Title>

                                      {Object.keys(this.state.demonstrativerootdata).length > 0 ?

                                      <Accordion.Content
                                        active={activeIndex2 === eindex}
                                      >


                                        <div style={{marginLeft:15,paddingTop:15,paddingBottom:15,lineHeight:'20px',fontWeight:'400'}}><span style={{fontStyle:'italic',color:'#777777',marginRight:'7px',fontWeight:'400'}}>{'Case:'}</span><span style={{fontWeight:'400'}}>{e+'.'}</span><span style={{marginLeft:'12px',cursor:'pointer'}}><TagColors tags={'pro-'+e} width={window.innerWidth} /></span></div>


                                        <div style={{marginLeft:15,paddingTop:15,lineHeight:'20px',fontWeight:'400'}}><span style={{fontStyle:'italic',color:'#777777',marginRight:'7px',fontWeight:'400'}}>{'Singular (1):'}</span><span style={{fontWeight:'500'}}>{this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['sg']['text']}</span>{'alts' in this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['sg'] ? this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['sg']['alts'].map((k)=><span>{', '+k}</span>):null}</div>
                                        <div style={{marginLeft:15,paddingTop:15,paddingBottom:15,lineHeight:'20px',fontWeight:'400'}}><span style={{fontStyle:'italic',color:'#777777',marginRight:'7px',fontWeight:'400'}}>{'Dual (2):'}</span><span style={{fontWeight:'500'}}>{this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['du']['text']}</span>{'alts' in this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['du'] ? this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['du']['alts'].map((k)=><span>{', '+k}</span>):null}</div>
                                        <div style={{marginLeft:15,paddingBottom:15,lineHeight:'20px',fontWeight:'400'}}><span style={{fontStyle:'italic',color:'#777777',marginRight:'7px',fontWeight:'400'}}>{'Plural (3+):'}</span><span style={{fontWeight:'500'}}>{this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['pl']['text']}</span>{'alts' in this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['pl'] ? this.props.demonstrativedata[this.state.currentRoot]['pro'][e]['pl']['alts'].map((k)=><span>{', '+k}</span>):null}</div>
                                      
                                      {"examples" in this.state.demonstrativerootdata['pro'][e] || "vForms" in this.state.demonstrativerootdata['pro'][e] ?
                                          <div style={{marginTop:15,marginLeft:15,marginBottom:12,lineHeight:'20px',fontStyle:'italic',color:'#777777'}}>Examples:</div>
                                          :
                                          null
                                        }

                                      {"examples" in this.state.demonstrativerootdata['pro'][e]?

                                        (this.state.demonstrativerootdata['pro'][e]["examples"].length > examplesShown ?
                                              <div style={{margin:0,padding:0}}>
                                              {this.state.demonstrativerootdata['pro'][e]["examples"].slice(0,examplesShown).map((k)=>
                                                    <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>                           
                                                      <div style={{fontFamily:customFontFam,fontWeight:500,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['inu']))}</span><span>{k['inu'][0] !== '“' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span>
                                                                {'audio' in k ?
                                                                                <Icon onClick={()=>this.repeatAudio(k['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                                                                                :
                                                                                null
                                                                }                                 
                                                      </div>
                                                      <div style={{fontFamily:customFontFam,fontWeight:400,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['eng']).trim())}</span><span>{k['eng'][0] !== '[' && ((k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span></div>
                                                    </div>                                                 
                                                )}

                                                {!this.state.seeMoreExamples2 ? 
                                                    <Button
                                                      basic
                                                      style={{fontFamily: "sans-serif", fontSize: 16, marginLeft:15, marginBottom:6}}
                                                      onClick={() => {
                                                        this.setState({
                                                          seeMoreExamples2:
                                                            true
                                                        });
                                                      }}
                                                    >
                                                    <div>
                                                      {"See More Examples"}
                                                      <Icon
                                                        style={{ paddingLeft: 10 }}
                                                        name="chevron down"
                                                      />
                                                    </div>
                                                    </Button>
                                                    :
                                                      (this.state.demonstrativerootdata['pro'][e]["examples"].slice(examplesShown).map((k)=>
                                                    <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>                           
                                                      <div style={{fontFamily:customFontFam,fontWeight:500,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['inu']))}</span><span>{k['inu'][0] !== '“' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span>
                                                                {'audio' in k ?
                                                                                <Icon onClick={()=>this.repeatAudio(k['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                                                                                :
                                                                                null
                                                                }                                 
                                                      </div>
                                                      <div style={{fontFamily:customFontFam,fontWeight:400,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['eng']).trim())}</span><span>{k['eng'][0] !== '[' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span></div>
                                                    </div>                                              
                                                        ))
                                                }
                                              </div>
                                            :
                                              <div style={{margin:0,padding:0}}>
                                              {this.state.demonstrativerootdata['pro'][e]["examples"].map((k)=>
                                                    <div style={{marginLeft:15,marginBottom:23,lineHeight:'23px'}}>                           
                                                      <div style={{fontFamily:customFontFam,fontWeight:500,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['inu']))}</span><span>{k['inu'][0] !== '“' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span>
                                                                {'audio' in k ?
                                                                                <Icon onClick={()=>this.repeatAudio(k['audio'])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                                                                                :
                                                                                null
                                                                }                                 
                                                      </div>
                                                      <div style={{fontFamily:customFontFam,fontWeight:400,padding:'2px 0px'}}><span>{this.processStyledText(this.capitalize(k['eng']).trim())}</span><span>{k['eng'][0] !== '[' && (k['eng'][k['eng'].length-1] !== '.' && k['eng'][k['eng'].length-1] !== '?') ? '.':null}</span></div>
                                                    </div>                                            
                                                )}
                                              </div>
                                          )
                                          :
                                          null
                                        }


                                  {"links" in this.state.demonstrativerootdata['pro'][e] ?
                                  <div>
                                      <div style={{marginTop:25,marginLeft:15,lineHeight:'20px',fontStyle:'italic',color:'#777777'}}>Entry Links:</div>
                                        <List style={{marginTop:12,paddingTop:0}}>
                                        {this.state.demonstrativerootdata['pro'][e]["links"].map((q)=>
                                                    <List.Item>
                                                    <Link to={{pathname: '/' + this.props.dictionarydict[q].url, state: { word: this.props.dictionarydict[q].url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                                                      <List.Content floated='right'>
                                                        <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                                                      </List.Content>
                                                    </Link>
                                                                  <List.Content style={{paddingTop:0,paddingBottom:0}}>
                                                                    <List.Header style={{fontSize:'16px',paddingBottom:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
                                                                      {this.props.dictionarydict[q].keySplit.map((w,index) =>
                                                                            <div style={{display:'flex'}}> 
                                                              <Link style={{color:'#306190'}} to={{pathname: '/' + this.props.dictionarydict[q].url, state: { word: this.props.dictionarydict[q].url, from: this.props.location.pathname, history:[this.state.search,this.state.wordsList, this.state.Yposition, this.state.activeTabIndex] }}}>
                                                                          <span style={{'paddingRight':'3px',fontWeight:'500',borderBottom:'1px solid #858585',paddingBottom:'1px',}}>
                                                              {this.processStyledTextLink(w[0])}
                                                              </span>
                                                              </Link>

                                                                          {w[2] !== [""] ?
                                                                            <span style={{'marginLeft':'15px',marginRight:'6px'}}>  
                                                                  {w[1][0] !== '' ?
                                                                    <span style={{marginRight:'5px',cursor:'pointer'}}>
                                                                      <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
                                                                    </span>
                                                                    :
                                                                    ''
                                                                  }

                                                                                                {w[2].map((tag) => 
                                                                                                    <span style={{marginRight:'5px',cursor:'pointer'}}>
                                                                                                        <TagColors tags={tag} width={window.innerWidth} />
                                                                                                    </span>
                                                                                                    )}
                                                                            </span>
                                                                            :
                                                                            ''
                                                                          }
                                                                          </div>
                                                                        )}
                                                                    </List.Header>
                                                                    <List.Description style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(this.props.dictionarydict[q].fullDefinition)}</List.Description>
                                                                  </List.Content>
                                                                </List.Item>
                                        )}
                                                            </List>
                                                            </div>
                                                            :
                                                            null
                                                          }
                                      </Accordion.Content>
                                      :
                                      null
                                  }
                                    </div>
                                    :
                                    null
                                    )
                                  )}
                                </Accordion>
                            </div>

						</div>
					</div>
					</Transition>
                :
                null
            }



					</div>


			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'16px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>

        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:90,paddingBottom:15,paddingTop:25}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>

				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
                :
                null
            }
			</div>
		);
	}
}
export default DemonstrativeTables;