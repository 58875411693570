export const postbasesEndingsUnderlying = {
"niaq":["+niaq-"],
"niaq>ŋit":["+niaŋIt-"],
"palliq":["+p/vallIq-"],
"ŋit":["±ŋIt-"],
"saaġe":["+saaġi-"],
"suu":["+[s]uu-"],
"suk":["+[s]uk-"],
"rraqsi":["-rraqsI-"],
"llatu":["-llatu-"],
"sulait":["+[s]ulaIt-"],
"lait":["-laIt-"],
"lla":["-lla-"],
"lluataq":["-lluataq(-)"],
"qpak":["+qpak(-)"],
"pałłuk":["-pałłuk(-)"],
"ŋuluk":["±ŋuluk(-)"],
"u":[":u-"],
"liuq":["-liuq"],
"tuq":["+tuq-"],
"qaq":["-qaq-"],
"kit":["-kIt-"],
"it":[":It-"],
"+N+Loc+Sg>miet":["÷miit-"],
"+N+Abl+Sg>miñŋaq":["÷miñŋaq-"],
"+N+Trm+Sg>muk":["÷muk-"],
//
//
"+N+Abs+Sg":[[""],[]],
"+N+Abs+Du":[["DUAL"],[]],
// "+N+Abs+Pl":[":It","-t","?GEM-t"],
"+N+Abs+Pl":[["PLURAL"],[]],
"+N+Abs+Sg+1Sg":[["+[g]a"],[]],
"+N+Abs+Sg+1Du":[["+kpuk"],[]],
"+N+Abs+Sg+1Pl":[["+kput"],[]],
// "+N+Abs+Sg+2Sg":["-n",":In"],
"+N+Abs+Sg+2Sg":[["-n","-iñ"],[]],
"+N+Abs+Sg+2Du":[["+ksIk"],[]],
"+N+Abs+Sg+2Pl":[["+ksI"],[]],
// "+N+Abs+Sg+3Sg":[":a","-ŋa"],
// "+N+Abs+Sg+3Du":[":ak","-ŋak"],
// "+N+Abs+Sg+3Pl":[":at","-ŋat"],
"+N+Abs+Sg+3Sg":[["-ŋa"],[":a"]],
"+N+Abs+Sg+3Du":[["-ŋak"],[":ak"]],
"+N+Abs+Sg+3Pl":[["-ŋat"],[":at"]],
"+N+Abs+Sg+3RSg":[["-nI"],["'=I"]],
"+N+Abs+Sg+3RDu":[["+ktik"],[]],
// "+N+Abs+Sg+3RPl":["-riŋ","+ktiŋ"],
"+N+Abs+Sg+3RPl":[["+ktiŋ"],["-riŋ"]],
"+N+Abs+Du+1Sg":[["-(k)ka"],[]],
"+N+Abs+Du+1Du":[["-vuk"],[]],
"+N+Abs+Du+1Pl":[["-vut"],[]],
// "+N+Abs+Du+2Sg":["?GEM-kIñ","?GEM-kkIñ"],
"+N+Abs+Du+2Sg":[["-(k)kIñ"],["'-(k)kIñ"]],
"+N+Abs+Du+2Du":[["-sIk"],[]],
"+N+Abs+Du+2Pl":[["-sI"],[]],
// "+N+Abs+Du+3Sg":["-ŋik","GEM-k"],
// "+N+Abs+Du+3Du":["-ŋIk","GEM-k"],
// "+N+Abs+Du+3Pl":[":It","GEM-k","-ŋIk","-ŋIt"],
"+N+Abs+Du+3Sg":[["-ŋik"],["'-k"]],
"+N+Abs+Du+3Du":[["-ŋIk"],["'-k"]],
"+N+Abs+Du+3Pl":[["-ŋIt"],[":It"]],
"+N+Abs+Du+3RSg":[["DUAL+nI"],[]],
"+N+Abs+Du+3RDu":[["-tik"],[]],
"+N+Abs+Du+3RPl":[["-tiŋ"],[]],
"+N+Abs+Pl+1Sg":[["-tka"],[]],
"+N+Abs+Pl+1Du":[["-vuk"],[]],
"+N+Abs+Pl+1Pl":[["-vut"],[]],
"+N+Abs+Pl+2Sg":[["-tin"],[]],
"+N+Abs+Pl+2Du":[["-sIk"],[]],
"+N+Abs+Pl+2Pl":[["-sI"],[]],
// "+N+Abs+Pl+3Sg":[":I",":It","-ŋI","-ŋIt"],
// "+N+Abs+Pl+3Du":[":It","-ŋIk","-ŋIt"],
// "+N+Abs+Pl+3Pl":[":It","-ŋIt"],
"+N+Abs+Pl+3Sg":[["-ŋI(t)",],[":I(t)"]],
"+N+Abs+Pl+3Du":[["-ŋIk"],[":It"]],
"+N+Abs+Pl+3Pl":[["-ŋIt"],[":It"]],
"+N+Abs+Pl+3RSg":[["-nI"],["'=I"]],
"+N+Abs+Pl+3RDu":[["-tik"],[]],
"+N+Abs+Pl+3RPl":[["-tiŋ"],[]],
// "+N+Rel+Sg":[":um","-m","?GEM-m"],
"+N+Rel+Sg":[["-m","'-m",":um"],[]],
"+N+Rel+Du":[["DUAL"],[]],
// "+N+Rel+Pl":[":It","-t","?GEM-t"],
"+N+Rel+Pl":[["PLURAL"],[]],
"+N+Rel+Sg+1Sg":[["+gma"],["+ŋma"]],
"+N+Rel+Sg+1Du":[["-mnuk"],[]],
"+N+Rel+Sg+1Pl":[["-pta"],[]],
// "+N+Rel+Sg+2Sg":["+kpIt","+kpIñ","+vIt","+vIñ"],
"+N+Rel+Sg+2Sg":[["+kpIñ","+kpIt"],["+vIñ","+vIt"]],
// "+N+Rel+Sg+2Du":["-ptik","-vsik"],
"+N+Rel+Sg+2Du":[["-vsik"],["-ptik"]],
"+N+Rel+Sg+2Pl":[["-vsI"],[]],
// "+N+Rel+Sg+3Sg":[":an",":ata","-ŋan","-ŋata"],
// "+N+Rel+Sg+3Du":[":akta","-ŋakta"],
// "+N+Rel+Sg+3Pl":[":atta","-ŋatta"],
"+N+Rel+Sg+3Sg":[["-ŋata"],["-ŋan",":an",":ata"]],
"+N+Rel+Sg+3Du":[["-ŋakta"],[":akta"]],
"+N+Rel+Sg+3Pl":[["-ŋatta"],[":atta"]],
"+N+Rel+Sg+3RSg":[["÷mI"],[]],
"+N+Rel+Sg+3RDu":[["÷mik"],[]],
// "+N+Rel+Sg+3RPl":["÷mik","÷miŋ"],
"+N+Rel+Sg+3RPl":[["÷miŋ"],["÷mik"]],
// "+N+Rel+Du+1Sg":["+ma"],
"+N+Rel+Du+1Sg":[["DUAL+ma"],[]],
"+N+Rel+Du+1Du":[["-mnuk"],[]],
"+N+Rel+Du+1Pl":[["-pta"],[]],
"+N+Rel+Du+2Sg":[["DUAL+kpIñ","DUAL+kpIt"],[]],
// "+N+Rel+Du+2Du":["-ptik","-vsik"],
"+N+Rel+Du+2Du":[["-vsik"],["-ptik"]],
"+N+Rel+Du+2Pl":[["-vsI"],[]],
"+N+Rel+Du+3Sg":[["-ŋiksa"],[]],
"+N+Rel+Du+3Du":[["-ŋIksa"],[]],
// "+N+Rel+Du+3Pl":["-ŋiksa","-ŋisa"],
"+N+Rel+Du+3Pl":[["-ŋisa"],["-ŋiksa", "(Nu) -kisa"]],
"+N+Rel+Du+3RSg":[["DUAL+mI"],[]],
"+N+Rel+Du+3RDu":[["÷mik"],[]],
// "+N+Rel+Du+3RPl":["÷mik","÷miŋ"],
"+N+Rel+Du+3RPl":[["÷miŋ"],["÷mik"]],
"+N+Rel+Pl+1Sg":[["+gma"],["+ŋma"]],
"+N+Rel+Pl+1Du":[["-mnuk"],[]],
"+N+Rel+Pl+1Pl":[["-pta"],[]],
"+N+Rel+Pl+2Sg":[["+kpIñ","+kpIt"],["+vIñ","+vIt"]],
// "+N+Rel+Pl+2Du":["-ptik","-vsik"],
"+N+Rel+Pl+2Du":[["-vsik"],["-ptik"]],
"+N+Rel+Pl+2Pl":[["-vsI"],[]],
// "+N+Rel+Pl+3Sg":["-ŋisa"],
"+N+Rel+Pl+3Sg":[["-ŋI(t)",":It"],[]],
// "+N+Rel+Pl+3Du":["-ŋiksa","-ŋisa"],
"+N+Rel+Pl+3Du":[["-ŋIksa"],["-ŋisa"]],
"+N+Rel+Pl+3Pl":[["-ŋisa"],[]],
// "+N+Rel+Pl+3RSg":["÷mI"],
"+N+Rel+Pl+3RSg":[["÷nI"],[]],
"+N+Rel+Pl+3RDu":[["÷mik"],[]],
// "+N+Rel+Pl+3RPl":["÷mik","÷miŋ"],
"+N+Rel+Pl+3RPl":[["÷miŋ"],["÷mik"]],
"+N+Mod+Sg":[["÷mik"],[]],
// "+N+Mod+Sg":["?GEM÷mik","?Nend÷mik"],
"+N+Mod+Du":[["DUAL+nik"],[]],
"+N+Mod+Pl":[["÷nik"],[]],
// "+N+Mod+Pl":["÷nik","?GEM÷nik"],
"+N+Mod+Sg+1Sg":[["-mnik"],[]],
"+N+Mod+Sg+1Du":[["-ptignik"],[]],
"+N+Mod+Sg+1Pl":[["-ptinnik"],[]],
"+N+Mod+Sg+2Sg":[["+ŋnik"],[]],
"+N+Mod+Sg+2Du":[["-vsigñik"],[]],
"+N+Mod+Sg+2Pl":[["-vsiññik"],[]],
// "+N+Mod+Sg+3Sg":[":anik","-ŋanik"],
// "+N+Mod+Sg+3Du":[":aŋnik","-ŋaŋnik"],
// "+N+Mod+Sg+3Pl":[":annik","-ŋannik"],
"+N+Mod+Sg+3Sg":[["-ŋanik"],[":anik"]],
"+N+Mod+Sg+3Du":[["-ŋaŋnik"],[":aŋnik"]],
"+N+Mod+Sg+3Pl":[["-ŋannik"],[":annik"]],
"+N+Mod+Sg+3RSg":[["÷miñik"],[]],
"+N+Mod+Sg+3RDu":[["÷mignik"],[]],
"+N+Mod+Sg+3RPl":[["÷miŋnik"],[]],
"+N+Mod+Du+1Sg":[["-mnik"],[]],
"+N+Mod+Du+1Du":[["-ptignik"],[]],
"+N+Mod+Du+1Pl":[["-ptinnik"],[]],
"+N+Mod+Du+2Sg":[["+ŋnik"],[]],
"+N+Mod+Du+2Du":[["-vsigñik"],[]],
"+N+Mod+Du+2Pl":[["-vsiññik"],[]],
"+N+Mod+Du+3Sg":[["-ŋiŋñik"],[":iŋñik"]],
"+N+Mod+Du+3Du":[["-ŋiŋñik"],[":iŋñik"]],
"+N+Mod+Du+3Pl":[["-ŋiññik"],[":iññik"]],
// "+N+Mod+Du+3RSg":["-ŋmIñik"],
"+N+Mod+Du+3RSg":[["DUAL+miñik"],[]],
"+N+Mod+Du+3RDu":[["÷mignik"],[]],
"+N+Mod+Du+3RPl":[["÷miŋnik"],[]],
"+N+Mod+Pl+1Sg":[["-mnik"],[]],
"+N+Mod+Pl+1Du":[["-ptignik"],[]],
"+N+Mod+Pl+1Pl":[["-ptinnik"],[]],
"+N+Mod+Pl+2Sg":[["+ŋnik"],[]],
"+N+Mod+Pl+2Du":[["-vsigñik"],[]],
"+N+Mod+Pl+2Pl":[["-vsiññik"],[]],
// "+N+Mod+Pl+3Sg":["-ŋIñik","-ŋIññik"],
// "+N+Mod+Pl+3Du":["-ŋIñik","-ŋIññik"],
// "+N+Mod+Pl+3Pl":["-ŋIñik","-ŋIññik"],
"+N+Mod+Pl+3Sg":[["-ŋiññik"],[":iññik"]],
"+N+Mod+Pl+3Du":[["-ŋiŋñik"],[":iŋñik"]],
"+N+Mod+Pl+3Pl":[["-ŋiññik"],[":iññik"]],
// "+N+Mod+Pl+3RSg":["-mIñik"],
"+N+Mod+Pl+3RSg":[["÷miñik"],[]],
"+N+Mod+Pl+3RDu":[["÷mignik"],[]],
"+N+Mod+Pl+3RPl":[["÷miŋnik"],[]],
"+N+Loc+Sg":[["÷mI"],[]],
"+N+Loc+Du":[["DUAL+nI"],[]],
"+N+Loc+Pl":[["÷nI"],[]],
"+N+Loc+Sg+1Sg":[["-mnI"],[]],
"+N+Loc+Sg+1Du":[["-ptignI"],[]],
"+N+Loc+Sg+1Pl":[["-ptinnI"],[]],
"+N+Loc+Sg+2Sg":[["+ŋnI"],[]],
"+N+Loc+Sg+2Du":[["-vsigñI"],[]],
"+N+Loc+Sg+2Pl":[["-vsiññI"],[]],
// "+N+Loc+Sg+3Sg":[":anI","-ŋanI"],
// "+N+Loc+Sg+3Du":[":aŋnI","-ŋaŋnI"],
// "+N+Loc+Sg+3Pl":[":annI","-ŋannI"],
"+N+Loc+Sg+3Sg":[["-ŋanI"],[":anI"]],
"+N+Loc+Sg+3Du":[["-ŋaŋnI"],[":aŋnI"]],
"+N+Loc+Sg+3Pl":[["-ŋannI"],[":annI"]],
"+N+Loc+Sg+3RSg":[["÷miñI"],[]],
"+N+Loc+Sg+3RDu":[["÷mignI"],[]],
"+N+Loc+Sg+3RPl":[["÷miŋnI"],[]],
"+N+Loc+Du+1Sg":[["-mnI"],[]],
"+N+Loc+Du+1Du":[["-ptignI"],[]],
"+N+Loc+Du+1Pl":[["-ptinnI"],[]],
"+N+Loc+Du+2Sg":[["+ŋni"],[]],
"+N+Loc+Du+2Du":[["-vsigñI"],[]],
"+N+Loc+Du+2Pl":[["-vsiññI"],[]],
"+N+Loc+Du+3Sg":[["-ŋiŋñI"],[":iŋñI"]],
"+N+Loc+Du+3Du":[["-ŋiŋñI"],[":iŋñI"]],
"+N+Loc+Du+3Pl":[["-ŋiññI"],[":iññI"]],
// "+N+Loc+Du+3RSg":["-ŋmIñI"],
"+N+Loc+Du+3RSg":[["DUAL+miñI"],[]],
"+N+Loc+Du+3RDu":[["÷mignI"],[]],
"+N+Loc+Du+3RPl":[["÷miŋnI"],[]],
"+N+Loc+Pl+1Sg":[["-mnI"],[]],
"+N+Loc+Pl+1Du":[["-ptignI"],[]],
"+N+Loc+Pl+1Pl":[["-ptinnI"],[]],
"+N+Loc+Pl+2Sg":[["+ŋnI"],[]],
"+N+Loc+Pl+2Du":[["-vsigñI"],[]],
"+N+Loc+Pl+2Pl":[["-vsiññI"],[]],
// "+N+Loc+Pl+3Sg":["-ŋIñI","-ŋIññI"],
// "+N+Loc+Pl+3Du":["-ŋIñI","-ŋIññI"],
// "+N+Loc+Pl+3Pl":["-ŋIñI","-ŋIññI"],
"+N+Loc+Pl+3Sg":[["-ŋiññI"],[":iññI"]],
"+N+Loc+Pl+3Du":[["-ŋiŋñI"],[":iŋñI"]],
"+N+Loc+Pl+3Pl":[["-ŋiññI"],[":iññI"]],
"+N+Loc+Pl+3RSg":[["÷miñI"],[]],
"+N+Loc+Pl+3RDu":[["÷mignI"],[]],
"+N+Loc+Pl+3RPl":[["÷miŋnI"],[]],
"+N+Abl+Sg":[["÷miñ"],[]],
"+N+Abl+Du":[["DUAL+niñ"],[]],
"+N+Abl+Pl":[["÷niñ"],[]],
"+N+Abl+Sg+1Sg":[["-mniñ"],[]],
"+N+Abl+Sg+1Du":[["-ptigniñ"],[]],
"+N+Abl+Sg+1Pl":[["-ptinniñ"],[]],
"+N+Abl+Sg+2Sg":[["+ŋniñ"],[]],
"+N+Abl+Sg+2Du":[["-vsigñiñ"],[]],
"+N+Abl+Sg+2Pl":[["-vsiññiñ"],[]],
// "+N+Abl+Sg+3Sg":[":aniñ","-ŋaniñ"],
// "+N+Abl+Sg+3Du":[":aŋniñ","-ŋaŋniñ"],
// "+N+Abl+Sg+3Pl":[":anniñ","-ŋanniñ"],
"+N+Abl+Sg+3Sg":[["-ŋaniñ"],[":aniñ"]],
"+N+Abl+Sg+3Du":[["-ŋaŋniñ"],[":aŋniñ"]],
"+N+Abl+Sg+3Pl":[["-ŋanniñ"],[":anniñ"]],
"+N+Abl+Sg+3RSg":[["÷miñiñ"],[]],
"+N+Abl+Sg+3RDu":[["÷migniñ"],[]],
"+N+Abl+Sg+3RPl":[["÷miŋniñ"],[]],
"+N+Abl+Du+1Sg":[["-mniñ"],[]],
"+N+Abl+Du+1Du":[["-ptigniñ"],[]],
"+N+Abl+Du+1Pl":[["-ptinniñ"],[]],
"+N+Abl+Du+2Sg":[["+ŋniñ"],[]],
"+N+Abl+Du+2Du":[["-vsigñiñ"],[]],
"+N+Abl+Du+2Pl":[["-vsiññiñ"],[]],
"+N+Abl+Du+3Sg":[["-ŋiŋñiñ"],[":iŋñiñ"]],
"+N+Abl+Du+3Du":[["-ŋiŋñiñ"],[":iŋñiñ"]],
"+N+Abl+Du+3Pl":[["-ŋiññiñ"],[":iññiñ"]],
// "+N+Abl+Du+3RSg":["-ŋmiñiñ"],
"+N+Abl+Du+3RSg":[["DUAL+miñiñ"],[]],
"+N+Abl+Du+3RDu":[["÷migniñ"],[]],
"+N+Abl+Du+3RPl":[["÷miŋniñ"],[]],
"+N+Abl+Pl+1Sg":[["-mniñ"],[]],
"+N+Abl+Pl+1Du":[["-ptigniñ"],[]],
"+N+Abl+Pl+1Pl":[["-ptinniñ"],[]],
"+N+Abl+Pl+2Sg":[["+ŋniñ"],[]],
"+N+Abl+Pl+2Du":[["-vsigñiñ"],[]],
"+N+Abl+Pl+2Pl":[["-vsiññiñ"],[]],
// "+N+Abl+Pl+3Sg":["-ŋiñiñ","-ŋiññiñ"],
// "+N+Abl+Pl+3Du":["-ŋiñiñ","-ŋiññiñ"],
// "+N+Abl+Pl+3Pl":["-ŋiñiñ","-ŋiññiñ"],
"+N+Abl+Pl+3Sg":[["-ŋiññiñ"],[":iññiñ"]],
"+N+Abl+Pl+3Du":[["-ŋiŋñiñ"],[":iŋñiñ"]],
"+N+Abl+Pl+3Pl":[["-ŋiññiñ"],[":iññiñ"]],
"+N+Abl+Pl+3RSg":[["÷miñiñ"],[]],
"+N+Abl+Pl+3RDu":[["÷migniñ"],[]],
"+N+Abl+Pl+3RPl":[["÷miŋniñ"],[]],
"+N+Trm+Sg":[["÷mun"],[]],
"+N+Trm+Du":[["DUAL+nun"],[]],
"+N+Trm+Pl":[["÷nun"],[]],
"+N+Trm+Sg+1Sg":[["-mnun"],[]],
"+N+Trm+Sg+1Du":[["-ptignun"],[]],
"+N+Trm+Sg+1Pl":[["-ptinnun"],[]],
"+N+Trm+Sg+2Sg":[["+ŋnun"],[]],
"+N+Trm+Sg+2Du":[["-vsigñun"],[]],
"+N+Trm+Sg+2Pl":[["-vsiññun"],[]],
// "+N+Trm+Sg+3Sg":[":anun","-ŋanun"],
// "+N+Trm+Sg+3Du":[":aŋnun","-ŋaŋnun"],
// "+N+Trm+Sg+3Pl":[":annun","ŋannun"],
"+N+Trm+Sg+3Sg":[["-ŋanun"],[":anun"]],
"+N+Trm+Sg+3Du":[["-ŋaŋnun"],[":aŋnun"]],
"+N+Trm+Sg+3Pl":[["-ŋannun"],[":annun"]],
"+N+Trm+Sg+3RSg":[["÷miñun"],[]],
"+N+Trm+Sg+3RDu":[["÷mignun"],[]],
"+N+Trm+Sg+3RPl":[["÷miŋnun"],[]],
"+N+Trm+Du+1Sg":[["-mnun"],[]],
"+N+Trm+Du+1Du":[["-ptignun"],[]],
"+N+Trm+Du+1Pl":[["-ptinnun"],[]],
"+N+Trm+Du+2Sg":[["+ŋnun"],[]],
"+N+Trm+Du+2Du":[["-vsigñun"],[]],
"+N+Trm+Du+2Pl":[["-vsiññun"],[]],
"+N+Trm+Du+3Sg":[["-ŋiŋñun"],[":iŋñun"]],
"+N+Trm+Du+3Du":[["-ŋiŋñun"],[":iŋñun"]],
"+N+Trm+Du+3Pl":[["-ŋiŋñun"],[":iŋñun"]],
// "+N+Trm+Du+3RSg":["-ŋmiñun"],
"+N+Trm+Du+3RSg":[["DUAL+miñun"],[]],
"+N+Trm+Du+3RDu":[["÷mignun"],[]],
"+N+Trm+Du+3RPl":[["÷miŋnun"],[]],
"+N+Trm+Pl+1Sg":[["-mnun"],[]],
"+N+Trm+Pl+1Du":[["-ptignun"],[]],
"+N+Trm+Pl+1Pl":[["-ptinnun"],[]],
"+N+Trm+Pl+2Sg":[["+ŋnun"],[]],
"+N+Trm+Pl+2Du":[["-vsigñun"],[]],
"+N+Trm+Pl+2Pl":[["-vsiññun"],[]],
// "+N+Trm+Pl+3Sg":["-ŋiñun","-ŋiññun"],
// "+N+Trm+Pl+3Du":["-ŋiñun","-ŋiññun"],
// "+N+Trm+Pl+3Pl":["-ŋiñun","-ŋiññun"],
"+N+Trm+Pl+3Sg":[["-ŋiññun"],[":iññun"]],
"+N+Trm+Pl+3Du":[["-ŋiŋñun"],[":iŋñun"]],
"+N+Trm+Pl+3Pl":[["-ŋiññun"],[":iññun"]],
"+N+Trm+Pl+3RSg":[["÷miñun"],[]],
"+N+Trm+Pl+3RDu":[["÷mignun"],[]],
"+N+Trm+Pl+3RPl":[["÷miŋnun"],[]],
// "+N+Via+Sg":["-kun","?GEM-kun"],
"+N+Via+Sg":[["-kun"],[]],
"+N+Via+Du":[["DUAL+kun"],[]],
// "+N+Via+Pl":["÷tigun","?GEM÷tigun"],
"+N+Via+Pl":[["÷tigun"],[]],
"+N+Via+Sg+1Sg":[["-pkun"],[]],
"+N+Via+Sg+1Du":[["-ptigun"],[]],
"+N+Via+Sg+1Pl":[["-ptigun"],[]],
"+N+Via+Sg+2Sg":[["+kkun"],[]],
"+N+Via+Sg+2Du":[["-vsigun"],[]],
"+N+Via+Sg+2Pl":[["-vsigun"],[]],
// "+N+Via+Sg+3Sg":[":agun","-ŋagun"],
// "+N+Via+Sg+3Du":[":aktigun","-ŋaktigun"],
// "+N+Via+Sg+3Pl":[":atigun","-ŋatigun"],
"+N+Via+Sg+3Sg":[["-ŋagun"],[":agun"]],
"+N+Via+Sg+3Du":[["-ŋakkun"],[":akkun"]],
"+N+Via+Sg+3Pl":[["-ŋatigun"],[":atigun"]],
"+N+Via+Sg+3RSg":[["÷migun"],[]],
// "+N+Via+Sg+3RDu":["÷miktigun"],
"+N+Via+Sg+3RDu":[["÷mikkun"],[]],
// "+N+Via+Sg+3RPl":["÷miktigun"],
"+N+Via+Sg+3RPl":[["÷mikkun"],[]],
"+N+Via+Du+1Sg":[["-pkun"],[]],
"+N+Via+Du+1Du":[["-ptigun"],[]],
"+N+Via+Du+1Pl":[["-ptigun"],[]],
"+N+Via+Du+2Sg":[["-kkun"],[]],
"+N+Via+Du+2Du":[["-vsigun"],[]],
"+N+Via+Du+2Pl":[["-vsigun"],[]],
"+N+Via+Du+3Sg":[["-ŋikkun"],[]],
"+N+Via+Du+3Du":[["-ŋiksigun"],[]],
// "+N+Via+Du+3Pl":["-ŋiksigun"],
"+N+Via+Du+3Pl":[["-ŋisigun"],[]],
// "+N+Via+Du+3RSg":["-ŋmigun"],
"+N+Via+Du+3RSg":[["DUAL+migun"],[]],
"+N+Via+Du+3RDu":[["÷miktigun"],[]],
"+N+Via+Du+3RPl":[["÷miktigun"],[]],
"+N+Via+Pl+1Sg":[["-pkun"],[]],
"+N+Via+Pl+1Du":[["-ptignun"],[]],
"+N+Via+Pl+1Pl":[["-ptigun"],[]],
"+N+Via+Pl+2Sg":[["+kkun"],[]],
"+N+Via+Pl+2Du":[["-vsigun"],[]],
"+N+Via+Pl+2Pl":[["-vsigun"],[]],
// "+N+Via+Pl+3Sg":["-igun","-ŋigun"],
// "+N+Via+Pl+3Du":["-isigun","-ŋisigun"],
// "+N+Via+Pl+3Pl":["-isigun","-ŋisigun"],
"+N+Via+Pl+3Sg":[["-ŋigun"],[]],
"+N+Via+Pl+3Du":[["-ŋiksigun"],[]],
"+N+Via+Pl+3Pl":[["-ŋisigun"],[]],
"+N+Via+Pl+3RSg":[["÷migun"],[]],
"+N+Via+Pl+3RDu":[["÷miktigun"],[]],
"+N+Via+Pl+3RPl":[["÷miktigun"],[]],
// "+N+Sim+Sg":["÷tun","?GEM÷tun"],
"+N+Sim+Sg":[["÷tun"],[]],
"+N+Sim+Du":[["DUAL+tun"],[]],
"+N+Sim+Pl":[["÷titun"],[]],
"+N+Sim+Sg+1Sg":[["-ptun"],[]],
// "+N+Sim+Sg+1Du":["-ptitun"],
"+N+Sim+Sg+1Du":[["-ptiktun"],[]],
"+N+Sim+Sg+1Pl":[["-ptitun"],[]],
"+N+Sim+Sg+2Sg":[["+ktun"],[]],
// "+N+Sim+Sg+2Du":["-vsitun"],
"+N+Sim+Sg+2Du":[["-vsiktun"],[]],
"+N+Sim+Sg+2Pl":[["-vsitun"],[]],
// "+N+Sim+Sg+3Sg":[":atun","-ŋatun"],
// "+N+Sim+Sg+3Du":[":aktun","-ŋaktun"],
// "+N+Sim+Sg+3Pl":[":atitun","-ŋatitun"],
"+N+Sim+Sg+3Sg":[["-ŋatun"],[":atun"]],
"+N+Sim+Sg+3Du":[["-ŋaktun"],[":aktun"]],
"+N+Sim+Sg+3Pl":[["-ŋatitun"],[":atitun"]],
"+N+Sim+Sg+3RSg":[["÷misun"],[]],
"+N+Sim+Sg+3RDu":[["÷miktun"],[]],
"+N+Sim+Sg+3RPl":[["÷miktun"],[]],
"+N+Sim+Du+1Sg":[["-ptun"],[]],
// "+N+Sim+Du+1Du":["-ptitun"],
"+N+Sim+Du+1Du":[["-ptiktun"],[]],
"+N+Sim+Du+1Pl":[["-ptitun"],[]],
"+N+Sim+Du+2Sg":[["-ktun"],[]],
// "+N+Sim+Du+2Du":["-vsitun"],
"+N+Sim+Du+2Du":[["-vsiktun"],[]],
"+N+Sim+Du+2Pl":[["-vsitun"],[]],
"+N+Sim+Du+3Sg":[["-ŋiktun"],[]],
"+N+Sim+Du+3Du":[["-ŋiksitun"],[]],
// "+N+Sim+Du+3Pl":["-ŋiksitun"],
"+N+Sim+Du+3Pl":[["-ŋisitun"],[]],
// "+N+Sim+Du+3RSg":["÷misun"],
// "+N+Sim+Du+3RDu":["÷miktun"],
// "+N+Sim+Du+3RPl":["÷miktun"],
"+N+Sim+Du+3RSg":[["DUAL+misun"],[]],
"+N+Sim+Du+3RDu":[["÷miktitun"],[]],
"+N+Sim+Du+3RPl":[["÷miktitun"],[]],
"+N+Sim+Pl+1Sg":[["-ptun"],[]],
// "+N+Sim+Pl+1Du":["-ptitun"],
"+N+Sim+Pl+1Du":[["-ptiktun"],[]],
"+N+Sim+Pl+1Pl":[["-ptitun"],[]],
"+N+Sim+Pl+2Sg":[["+ktun"],[]],
// "+N+Sim+Pl+2Du":["-vsitun"],
"+N+Sim+Pl+2Du":[["-vsiktun"],[]],
"+N+Sim+Pl+2Pl":[["-vsitun"],[]],
// "+N+Sim+Pl+3Sg":["-isun","-ŋisun"],
// "+N+Sim+Pl+3Du":["-isitun","-ŋisitun"],
// "+N+Sim+Pl+3Pl":["-isitun","-ŋisitun"],
"+N+Sim+Pl+3Sg":[["-ŋisun"],[]],
"+N+Sim+Pl+3Du":[["-ŋisitun"],[]],
"+N+Sim+Pl+3Pl":[["-ŋisitun"],[]],
"+N+Sim+Pl+3RSg":[["÷misun"],[]],
// "+N+Sim+Pl+3RDu":["÷miktun"],
// "+N+Sim+Pl+3RPl":["÷miktun"],
"+N+Sim+Pl+3RDu":[["÷miktitun"],[]],
"+N+Sim+Pl+3RPl":[["÷miktitun"],[]],
"+V+Ind+Prs+1Sg":[["+t/ruŋa"],[]],
"+V+Ind+Prs+1Du":[["+t/ruguk"],[]],
"+V+Ind+Prs+1Pl":[["+t/rugut"],[]],
"+V+Ind+Prs+2Sg":[["+t/rutin"],[]],
// "+V+Ind+Prs+2Du":["+t/rusik","+t/rutik"],
"+V+Ind+Prs+2Du":[["+t/rusik"],[]],
"+V+Ind+Prs+2Pl":[["+t/rusI"],[]],
"+V+Ind+Prs+3Sg":[["+t/ruq"],[]],
"+V+Ind+Prs+3Du":[["+t/ruk"],[]],
"+V+Ind+Prs+3Pl":[["+t/rut"],[]],
// "+V+Ind+Prs+1Sg+2SgO":["+k/gIkpIñ"],
"+V+Ind+Prs+1Sg+2SgO":[["+k/gIkpiñ"],[]],
"+V+Ind+Prs+1Sg+2DuO":[["+k/gIvsik"],[]],
"+V+Ind+Prs+1Sg+2PlO":[["+k/gIvsI"],[]],
"+V+Ind+Prs+1Sg+3SgO":[["+k/gIga"],[]],
// "+V+Ind+Prs+1Sg+3DuO":["+k/gIka","+k/gIkka"],
"+V+Ind+Prs+1Sg+3DuO":[["+k/gI(k)ka"],[]],
"+V+Ind+Prs+1Sg+3PlO":[["+k/gItka"],[]],
// "+V+Ind+Prs+1Du+2SgO":["+k/gIvsigIñ"],
"+V+Ind+Prs+1Du+2SgO":[["+k/gIvsigiñ"],[]],
"+V+Ind+Prs+1Du+2DuO":[["+k/gIvsik"],[]],
"+V+Ind+Prs+1Du+2PlO":[["+k/gIvsI"],[]],
"+V+Ind+Prs+1Du+3SgO":[["+k/gIkpuk"],[]],
"+V+Ind+Prs+1Du+3DuO":[["+k/gIvuk"],[]],
"+V+Ind+Prs+1Du+3PlO":[["+k/gIvuk"],[]],
// "+V+Ind+Prs+1Pl+2SgO":["+k/gIvsigIñ"],
"+V+Ind+Prs+1Pl+2SgO":[["+k/gIvsigiñ"],[]],
"+V+Ind+Prs+1Pl+2DuO":[["+k/gIvsik"],[]],
"+V+Ind+Prs+1Pl+2PlO":[["+k/gIvsI"],[]],
"+V+Ind+Prs+1Pl+3SgO":[["+k/gIkput"],[]],
"+V+Ind+Prs+1Pl+3DuO":[["+k/gIvut"],[]],
"+V+Ind+Prs+1Pl+3PlO":[["+k/gIvut"],[]],
"+V+Ind+Prs+2Sg+1SgO":[["+k/gIŋma"],[]],
"+V+Ind+Prs+2Sg+1DuO":[["+k/gIvsiguk"],[]],
"+V+Ind+Prs+2Sg+1PlO":[["+k/gIvsigut"],[]],
"+V+Ind+Prs+2Sg+3SgO":[["+k/gIn"],[]],
// "+V+Ind+Prs+2Sg+3DuO":["+k/gIkIñ","+k/gIkkIñ"],
"+V+Ind+Prs+2Sg+3DuO":[["+k/gIkkiñ"],[]],
// "+V+Ind+Prs+2Sg+3PlO":["+k/gItin"],
"+V+Ind+Prs+2Sg+3PlO":[["+k/gitin"],[]],
"+V+Ind+Prs+2Du+1SgO":[["+k/gIvsiŋŋa"],[]],
"+V+Ind+Prs+2Du+1DuO":[["+k/gIvsiguk"],[]],
"+V+Ind+Prs+2Du+1PlO":[["+k/gIvsigut"],[]],
"+V+Ind+Prs+2Du+3SgO":[["+k/gIksik"],[]],
"+V+Ind+Prs+2Du+3DuO":[["+k/gIsik"],[]],
"+V+Ind+Prs+2Du+3PlO":[["+k/gIsik"],[]],
"+V+Ind+Prs+2Pl+1SgO":[["+k/gIvsIñŋa"],[]],
"+V+Ind+Prs+2Pl+1DuO":[["+k/gIvsiguk"],[]],
"+V+Ind+Prs+2Pl+1PlO":[["+k/gIvsigut"],[]],
"+V+Ind+Prs+2Pl+3SgO":[["+k/gIksI"],[]],
"+V+Ind+Prs+2Pl+3DuO":[["+k/gIsI"],[]],
"+V+Ind+Prs+2Pl+3PlO":[["+k/gIsI"],[]],
"+V+Ind+Prs+3Sg+1SgO":[["+k/gaaŋa"],[]],
"+V+Ind+Prs+3Sg+1DuO":[["+k/gaatiguk"],[]],
"+V+Ind+Prs+3Sg+1PlO":[["+k/gaatigut"],[]],
"+V+Ind+Prs+3Sg+2SgO":[["+k/gaatin"],[]],
"+V+Ind+Prs+3Sg+2DuO":[["+k/gaasik"],[]],
"+V+Ind+Prs+3Sg+2PlO":[["+k/gaasI"],[]],
"+V+Ind+Prs+3Sg+3SgO":[["+k/gaa"],[]],
// "+V+Ind+Prs+3Sg+3DuO":["+k/gaIk","+k/gIk"],
"+V+Ind+Prs+3Sg+3DuO":[["+k/gaIk"],[]],
"+V+Ind+Prs+3Sg+3PlO":[["+k/gaI"],[]],
"+V+Ind+Prs+3Du+1SgO":[["+k/gaaŋŋa"],[]],
"+V+Ind+Prs+3Du+1DuO":[["+k/gaatiguk"],[]],
"+V+Ind+Prs+3Du+1PlO":[["+k/gaatigut"],[]],
"+V+Ind+Prs+3Du+2SgO":[["+k/gaatin"],[]],
"+V+Ind+Prs+3Du+2DuO":[["+k/gaasik"],[]],
"+V+Ind+Prs+3Du+2PlO":[["+k/gaasI"],[]],
"+V+Ind+Prs+3Du+3SgO":[["+k/gaak"],[]],
// "+V+Ind+Prs+3Du+3DuO":["+k/gaIk","+k/gIk"],
// "+V+Ind+Prs+3Du+3PlO":["+k/gaIk","+k/gaIt","+k/gIk"],
"+V+Ind+Prs+3Du+3DuO":[["+k/gaIk"],[]],
"+V+Ind+Prs+3Du+3PlO":[["+k/gaIk","+k/gaIt"],[]],
"+V+Ind+Prs+3Pl+1SgO":[["+k/gaanŋa"],[]],
"+V+Ind+Prs+3Pl+1DuO":[["+k/gaatiguk"],[]],
"+V+Ind+Prs+3Pl+1PlO":[["+k/gaatigut"],[]],
"+V+Ind+Prs+3Pl+2SgO":[["+k/gaatin"],[]],
"+V+Ind+Prs+3Pl+2DuO":[["+k/gaasik"],[]],
"+V+Ind+Prs+3Pl+2PlO":[["+k/gaasI"],[]],
"+V+Ind+Prs+3Pl+3SgO":[["+k/gaat"],[]],
// "+V+Ind+Prs+3Pl+3DuO":["+k/gaIk","+k/gaIt","+k/gIk"],
"+V+Ind+Prs+3Pl+3DuO":[["+k/gaIk","+k/gaIt"],[]],
"+V+Ind+Prs+3Pl+3PlO":[["+k/gaIt"],[]],
"+V+Ind+Pst+1Sg":[["+t/ruaŋa","+t/ruamI"],[]],
"+V+Ind+Pst+1Du":[["+t/ruaguk","+t/ruagnI"],[]],
"+V+Ind+Pst+1Pl":[["+t/ruagut","+t/ruanI"],[]],
"+V+Ind+Pst+2Sg":[["+t/ruatin"],[]],
"+V+Ind+Pst+2Du":[["+t/ruasik","+t/ruatik"],[]],
"+V+Ind+Pst+2Pl":[["+t/ruasI"],[]],
"+V+Ind+Pst+3Sg":[["+t/ruaq"],[]],
"+V+Ind+Pst+3Du":[["+t/ruak"],[]],
"+V+Ind+Pst+3Pl":[["+t/ruat"],[]],
"+V+Ind+Pst+1Sg+2SgO":[["+t/raqpIñ"],[]],
"+V+Ind+Pst+1Sg+2DuO":[["+t/ravsik"],[]],
"+V+Ind+Pst+1Sg+2PlO":[["+t/ravsI"],[]],
"+V+Ind+Pst+1Sg+3SgO":[["+t/raġa"],[]],
"+V+Ind+Pst+1Sg+3DuO":[["+t/raka","+t/rakka"],[]],
"+V+Ind+Pst+1Sg+3PlO":[["+t/ratka"],[]],
"+V+Ind+Pst+1Du+2SgO":[["+t/ravsigIñ"],[]],
"+V+Ind+Pst+1Du+2DuO":[["+t/ravsik"],[]],
"+V+Ind+Pst+1Du+2PlO":[["+t/ravsI"],[]],
"+V+Ind+Pst+1Du+3SgO":[["+t/raqpuk"],[]],
"+V+Ind+Pst+1Du+3DuO":[["+t/ravuk"],[]],
"+V+Ind+Pst+1Du+3PlO":[["+t/ravuk"],[]],
"+V+Ind+Pst+1Pl+2SgO":[["+t/ravsigIñ"],[]],
"+V+Ind+Pst+1Pl+2DuO":[["+t/ravsik"],[]],
"+V+Ind+Pst+1Pl+2PlO":[["+t/ravsI"],[]],
"+V+Ind+Pst+1Pl+3SgO":[["+t/raqput"],[]],
"+V+Ind+Pst+1Pl+3DuO":[["+t/ravut"],[]],
"+V+Ind+Pst+1Pl+3PlO":[["+t/ravut"],[]],
"+V+Ind+Pst+2Sg+1SgO":[["+t/raqma"],[]],
"+V+Ind+Pst+2Sg+1DuO":[["+t/ravsiguk"],[]],
"+V+Ind+Pst+2Sg+1PlO":[["+t/ravsigut"],[]],
"+V+Ind+Pst+2Sg+3SgO":[["+t/ran"],[]],
"+V+Ind+Pst+2Sg+3DuO":[["+t/rakIñ","+t/rakkIñ"],[]],
"+V+Ind+Pst+2Sg+3PlO":[["+t/ratin"],[]],
"+V+Ind+Pst+2Du+1SgO":[["+t/ravsiŋŋa"],[]],
"+V+Ind+Pst+2Du+1DuO":[["+t/ravsiguk"],[]],
"+V+Ind+Pst+2Du+1PlO":[["+t/ravsigut"],[]],
"+V+Ind+Pst+2Du+3SgO":[["+t/raqsik"],[]],
"+V+Ind+Pst+2Du+3DuO":[["+t/rasik"],[]],
"+V+Ind+Pst+2Du+3PlO":[["+t/rasik"],[]],
"+V+Ind+Pst+2Pl+1SgO":[["+t/ravsIñŋa"],[]],
"+V+Ind+Pst+2Pl+1DuO":[["+t/ravsiguk"],[]],
"+V+Ind+Pst+2Pl+1PlO":[["+t/ravsigut"],[]],
"+V+Ind+Pst+2Pl+3SgO":[["+t/raqsI"],[]],
"+V+Ind+Pst+2Pl+3DuO":[["+t/rasI"],[]],
"+V+Ind+Pst+2Pl+3PlO":[["+t/rasI"],[]],
"+V+Ind+Pst+3Sg+1SgO":[["+t/raanI","+t/raŋanI"],[]],
"+V+Ind+Pst+3Sg+1DuO":[["+t/raatiguk","+t/raŋatiguk"],[]],
"+V+Ind+Pst+3Sg+1PlO":[["+t/raatigut","+t/raŋatigut"],[]],
"+V+Ind+Pst+3Sg+2SgO":[["+t/raatin","+t/raŋatin"],[]],
"+V+Ind+Pst+3Sg+2DuO":[["+t/raasik","+t/raŋasik"],[]],
"+V+Ind+Pst+3Sg+2PlO":[["+t/raasI","+t/raŋasI"],[]],
"+V+Ind+Pst+3Sg+3SgO":[["+t/raa","+t/raŋa"],[]],
// "+V+Ind+Pst+3Sg+3DuO":["+t/raIk","+t/rak","+t/raŋIk","+t/raŋk"],
"+V+Ind+Pst+3Sg+3DuO":[["+t/raIk","+t/rak","+t/raŋIk"],[]],
"+V+Ind+Pst+3Sg+3PlO":[["+t/raI","+t/raIt","+t/raŋI","+t/raŋIt"],[]],
"+V+Ind+Pst+3Du+1SgO":[["+t/raaŋnI","+t/raŋaŋnI"],[]],
"+V+Ind+Pst+3Du+1DuO":[["+t/raatiguk","+t/raŋatiguk"],[]],
"+V+Ind+Pst+3Du+1PlO":[["+t/raatigut","+t/raŋatigut"],[]],
"+V+Ind+Pst+3Du+2SgO":[["+t/raatin","+t/raŋatin"],[]],
"+V+Ind+Pst+3Du+2DuO":[["+t/raasik","+t/raŋasik"],[]],
"+V+Ind+Pst+3Du+2PlO":[["+t/raasI","+t/raŋasI"],[]],
"+V+Ind+Pst+3Du+3SgO":[["+t/raak","+t/raŋak"],[]],
"+V+Ind+Pst+3Du+3DuO":[["+t/raIk","+t/raŋIk"],[]],
"+V+Ind+Pst+3Du+3PlO":[["+t/raIk","+t/raIt","+t/raŋIk","+t/raŋIt"],[]],
"+V+Ind+Pst+3Pl+1SgO":[["+t/raannI","+t/raŋannI"],[]],
"+V+Ind+Pst+3Pl+1DuO":[["+t/raatiguk","+t/raŋatiguk"],[]],
"+V+Ind+Pst+3Pl+1PlO":[["+t/raatigut","+t/raŋatigut"],[]],
"+V+Ind+Pst+3Pl+2SgO":[["+t/raatin","+t/raŋatin"],[]],
"+V+Ind+Pst+3Pl+2DuO":[["+t/raasik","+t/raŋasik"],[]],
"+V+Ind+Pst+3Pl+2PlO":[["+t/raasI","+t/raŋasI"],[]],
"+V+Ind+Pst+3Pl+3SgO":[["+t/raat","+t/raŋat"],[]],
"+V+Ind+Pst+3Pl+3DuO":[["+t/raIk","+t/raIt","+t/raŋIk","+t/raŋIt"],[]],
"+V+Ind+Pst+3Pl+3PlO":[["+t/raIt","+t/raŋIt"],[]],
"+V+Int+1Sg":[["+p/vIk"],[]],
"+V+Int+1Du":[["+p/vIñuk"],[]],
"+V+Int+1Pl":[["+p/vIsa"],[]],
"+V+Int+2Sg":[["+p/vIn","+p/vIt"],[]],
"+V+Int+2Du":[["+p/vIsik"],[]],
"+V+Int+2Pl":[["+p/vIsI"],[]],
"+V+Int+3Sg":[["+p/va"],[]],
"+V+Int+3Du":[["+p/vak"],[]],
"+V+Int+3Pl":[["+p/vat"],[]],
"+V+Int+1Sg+2SgO":[["+p/vIgIñ"],[]],
"+V+Int+1Sg+2DuO":[["+p/vIsik"],[]],
"+V+Int+1Sg+2PlO":[["+p/vIsI"],[]],
"+V+Int+1Sg+3SgO":[["+p/vIgu"],[]],
"+V+Int+1Sg+3DuO":[["+p/vIgik"],[]],
"+V+Int+1Sg+3PlO":[["+p/vIgI","+p/vIgIt"],[]],
"+V+Int+1Du+2SgO":[["+p/vIsigIñ"],[]],
"+V+Int+1Du+2DuO":[["+p/vIsik"],[]],
"+V+Int+1Du+2PlO":[["+p/vIsI"],[]],
"+V+Int+1Du+3SgO":[["+p/vIsigu"],[]],
"+V+Int+1Du+3DuO":[["+p/vIsigik"],[]],
"+V+Int+1Du+3PlO":[["+p/vIsigik","+p/vIsigiŋ"],[]],
"+V+Int+1Pl+2SgO":[["+p/vIsigIñ"],[]],
"+V+Int+1Pl+2DuO":[["+p/vIsik"],[]],
"+V+Int+1Pl+2PlO":[["+p/vIsI"],[]],
"+V+Int+1Pl+3SgO":[["+p/vIsigu"],[]],
"+V+Int+1Pl+3DuO":[["+p/vIsigik","+p/vIsigiŋ"],[]],
"+V+Int+1Pl+3PlO":[["+p/vIsigik","+p/vIsigiŋ"],[]],
"+V+Int+2Sg+1SgO":[["+p/vIŋa"],[]],
"+V+Int+2Sg+1DuO":[["+p/vIsiguk"],[]],
"+V+Int+2Sg+1PlO":[["+p/vIsigut"],[]],
"+V+Int+2Sg+3SgO":[["+p/vIuŋ"],[]],
"+V+Int+2Sg+3DuO":[["+p/vIgik"],[]],
"+V+Int+2Sg+3PlO":[["+p/vIgI","+p/vIgIt"],[]],
"+V+Int+2Du+1SgO":[["+p/vIsiŋŋa"],[]],
"+V+Int+2Du+1DuO":[["+p/vIsiguk"],[]],
"+V+Int+2Du+1PlO":[["+p/vIsigut"],[]],
"+V+Int+2Du+3SgO":[["+p/vItku"],[]],
"+V+Int+2Du+3DuO":[["+p/vIsigik","+p/vItkik"],[]],
"+V+Int+2Du+3PlO":[["+p/vIsigik","+p/vIsigiŋ","+p/vItkik"],[]],
"+V+Int+2Pl+1SgO":[["+p/vIsIñŋa"],[]],
"+V+Int+2Pl+1DuO":[["+p/vIsiguk"],[]],
"+V+Int+2Pl+1PlO":[["+p/vIsigut"],[]],
"+V+Int+2Pl+3SgO":[["+p/vIsIuŋ"],[]],
"+V+Int+2Pl+3DuO":[["+p/vIsigik","+p/vIsigiŋ","+p/vItkik"],[]],
"+V+Int+2Pl+3PlO":[["+p/vIsigik","+p/vIsigiŋ"],[]],
"+V+Int+3Sg+1SgO":[["+p/vaŋa"],[]],
"+V+Int+3Sg+1DuO":[["+p/vatiguk"],[]],
"+V+Int+3Sg+1PlO":[["+p/vatigut"],[]],
"+V+Int+3Sg+2SgO":[["+p/vatin"],[]],
"+V+Int+3Sg+2DuO":[["+p/vasik"],[]],
"+V+Int+3Sg+2PlO":[["+p/vasI"],[]],
"+V+Int+3Sg+3SgO":[["+p/vauŋ"],[]],
"+V+Int+3Sg+3DuO":[["+p/vagik"],[]],
// "+V+Int+3Sg+3PlO":["+p/vagI","+p/vagIk"],
"+V+Int+3Sg+3PlO":[["+p/vagI","+p/vagIt"],[]],
"+V+Int+3Du+1SgO":[["+p/vaŋŋa"],[]],
"+V+Int+3Du+1DuO":[["+p/vatiguk"],[]],
"+V+Int+3Du+1PlO":[["+p/vatigut"],[]],
"+V+Int+3Du+2SgO":[["+p/vatin"],[]],
"+V+Int+3Du+2DuO":[["+p/vasik"],[]],
"+V+Int+3Du+2PlO":[["+p/vasI"],[]],
"+V+Int+3Du+3SgO":[["+p/vatku"],[]],
"+V+Int+3Du+3DuO":[["+p/vatigik","+p/vatkik"],[]],
"+V+Int+3Du+3PlO":[["+p/vatigik","+p/vatkik"],[]],
// "+V+Int+3Pl+1SgO":["+p/vanŋa","+p/vasiŋa"],
"+V+Int+3Pl+1SgO":[["+p/vanŋa"],[]],
"+V+Int+3Pl+1DuO":[["+p/vatiguk"],[]],
"+V+Int+3Pl+1PlO":[["+p/vatigut"],[]],
"+V+Int+3Pl+2SgO":[["+p/vatin"],[]],
"+V+Int+3Pl+2DuO":[["+p/vasik"],[]],
"+V+Int+3Pl+2PlO":[["+p/vasI"],[]],
"+V+Int+3Pl+3SgO":[["+p/varruŋ"],[]],
"+V+Int+3Pl+3DuO":[["+p/vatigik","+p/vatigiŋ","+p/vatkik"],[]],
"+V+Int+3Pl+3PlO":[["+p/vatigik","+p/vatigiŋ"],[]],
}