import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup, Segment, Table, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import {colorsList, customFontFam} from './constants/constants.js'
import {appendixInformation} from './constants/appendixConstants.js'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")
var scroll    = Scroll.animateScroll;


class AppendixMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
      history: props.location.state === undefined ? [] : props.location.state.history,
      from: props.location.state === undefined ? '' : props.location.state.from,
      Yposition:0,
		}
      // if (decodeURI(props.match.params.word) != 'appendix') {
      //   this.props.history.push('/appendix')
      // }
	    scroll.scrollTo(0);
	}

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    // const height =
      // document.documentElement.scrollHeight -
      // document.documentElement.clientHeight

    // const scrolled = winScroll / height

    // console.log(winScroll, height)

    this.setState({
      Yposition: winScroll,
    })
  }


  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({ 
			from: nextProps.location.pathname,
			// fullDefinition: nextProps.fullDefinition,
		})
	}
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.currentType !== this.state.currentType && this.state.currentType === 'adv') {
  //       this.setState({currentCase:'base'})
  //   }
  //   if (prevState.currentType !== this.state.currentType && this.state.currentType === 'pro') {
  //       this.setState({currentCase:'abs',currentNumber:'sg'})
  //   }

  // }


  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replaceAll('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }
 
  // handleTitleChange = (e, { value }) => {
  // 	this.setState({ demTableType:value })
  // }

  // handleTableTypeOptions = (e, { value }) => {
  //   this.setState({ currentType:value })
  // }

  // handleTableAdvCaseOptions = (e, { value }) => {
  //   this.setState({ currentCase:value })
  // }

  // handleTableProCaseOptions = (e, { value }) => {
  //   this.setState({ currentCase:value })
  // }

  // handleTableProNumberOptions = (e, { value }) => {
  //   this.setState({ currentNumber:value })
  // }



  // getCorrectForm = (word, e, value) => {
  //   // console.log(word)
  //   // console.log(this.state.demTableType)
  //   // console.log(word)
  //   // console.log(this.props.demonstrativedata[word])
  //   //this.props.demonstrativedata[this.state.currentRoot]['pro'][e]["examples"]
  //   //this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['text']
  //   //this.props.demonstrativedata[this.state.currentRoot]['pro'][l][[i]]['text']
  //   if (word.length !== 0) {
  //     if (this.state.currentType == 'roots') {
  //       return word.replaceAll('⎤','-').replaceAll('⎡°','').replaceAll(',',', ').toLowerCase()
  //     } else if (this.state.currentType == 'adv' && this.state.currentCase.length !== 0 && this.state.currentCase !== 'sim' && this.state.currentCase in this.props.demonstrativedata[word]['adv']) {
  //       return this.props.demonstrativedata[word]['adv'][this.state.currentCase]['word']['text']
  //     } else if (this.state.currentType == 'pro' && this.state.currentCase.length !== 0 && this.state.currentNumber.length !== 0 && this.state.currentCase in this.props.demonstrativedata[word]['pro']) {
  //       return this.props.demonstrativedata[word]['pro'][this.state.currentCase][[this.state.currentNumber]]['text']
  //     } else {
  //       return word      
  //     }      
  //   }

  // }

    processStyledText = (sentence) => {
      // sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("«","").replaceAll("»","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


  render() {
    // console.log(this.state)
    // let tableCase = {}
    // let tableCaseTat = {}

    // if (this.state.demTableType == 'adverbs') {
    // 	tableCase = tableCaseAdverbs
    // 	tableCaseTat = tableCaseTatAdverbs
    // 	tableTitle = 'Demonstrative adverbs'
    // } else if (this.state.demTableType == 'pronouns') {
    // 	tableCase = tableCasePronouns
    // 	tableCaseTat = tableCaseTatPronouns
    // 	tableTitle = 'Demonstrative abs. pronouns'
    // } else {
    // 	tableCase = tableCaseRoots
    // 	tableCaseTat = tableCaseTatRoots
    // 	tableTitle = 'Demonstrative roots'
    // }

    // const { activeIndex2 } = this.state;
		// console.log(this.props.location)
		// console.log(this.props)
    // let clickText = 'a root'
    // if (this.state.currentType == 'adv') {
    //   clickText = 'an adverb'
    // } else if (this.state.currentType == 'pro') {
    //   clickText = 'a pronoun'
    // }
    let showKeys = false
	
		return (
			<div style={{fontFamily:customFontFam}}>
			<Transition visible={this.state.loaded} animation='fade' duration={300}>
          <Grid textAlign='center'>
          <Grid.Row  style={{height:40,paddingBottom:0}}>
          <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>

			<div style={{display:'flex'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					
	      </div>


			</div>


					<div style={{border:'1px solid #E3E3E3',marginTop:'10px'}}>

					<div style={{marginBottom:15}} className='hierarchymain'>
					<span className='span1'>Iiguutit Savaamun</span>
					<span className='span2'>Appendices</span>
					</div>

          {false ?
            <div style={{display:'flex',justifyContent:'center'}}>
            <Image style={{'maxWidth':'300px',cursor:'pointer'}} src="/appendixpicture.png"/>          
            </div>
            :
            null
          }


          <div style={{fontSize:'16px'}}>
          <List>
          {Object.keys(this.props.dictionarydict).length > 0 ?
            (Object.keys(appendixInformation).map((k,kind)=>
                <List.Item style={{padding:0,textAlign:'center'}}>
                <div>
                  {window.innerWidth > 480 ?
                    <div style={{fontSize:'17px',lineHeight:'24px',}}>
                      <div style={{padding:10, marginTop:(kind === 0 ? 0 : 15),fontStyle:'italic'}}>
                        <span style={{color:'#989898'}}>{'Appendix '+k+': '}</span>
                      </div>
                      <Link to={{pathname: "/appendix/"+k, state: { appendix:k, history:this.state.history }}}>
                        <span style={{cursor:'pointer',color:'#306190',paddingRight:'10px',paddingLeft:'10px'}}>
                          <span style={{fontWeight:'bold',paddingBottom:'1px',borderBottom:'1px solid #858585',}}>{appendixInformation[k]['inupTitle']}</span>
                          <span>
                          </span>
                          <span style={{fontStyle:'italic',paddingBottom:'1px',borderBottom:'1px solid #858585',}}>{' - '+appendixInformation[k]['engTitle']}</span>
                        </span>
                      </Link>
                    </div>
                    :
                    <div style={{padding:15,}}>
                      <Link to={{pathname: "/appendix/"+k, state: { appendix:k, history:this.state.history }}} style={{cursor:'pointer',color:'#306190',fontSize:'17px',lineHeight:'24px',paddingBottom:'1px',borderBottom:'1px solid #858585',}}>
                        <span>{'Appendix '+k+': '}</span>
                        <span style={{fontWeight:'bold'}}>{appendixInformation[k]['inupTitle']}</span>
                        <span>{' - '}
                        </span>
                        <span style={{fontStyle:'italic'}}>{appendixInformation[k]['engTitle']}</span>
                      </Link>
                    </div>
                  }
                </div>

                {appendixInformation[k]['previewImage'] && true ?
                <Link to={{pathname: "/appendix/"+k, state: { appendix:k, history:this.state.history }}}>
                  <LazyLoadImage effect='blur' height={'90px'} style={{cursor:'pointer',paddingTop:'10px'}} src={'/AppendixPreview_'+k+'.png'} />
                </Link>

                  :
                  null
                }


                {false ?
                  <Segment style={{fontFamily:customFontFam, maxHeight:220,overflow: 'auto',fontSize:'16px',margin:'25px 50px',paddingTop:'9px',paddingBottom:'9px',marginBottom:'0px',textAlign:'left'}}>
                    <div>
                      {appendixInformation[k]['keys'].map((p,pind)=>{
                        if (p in this.props.dictionarydict) {
                          return <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}><div style={{color:'#4A80B5',cursor:'pointer',minWidth:(window.innerWidth < 480 ? 100:120)}}><Link to={{pathname: '/' + this.props.dictionarydict[p]["url"], state: { word: this.props.dictionarydict[p]["url"], from: this.props.location.pathname, history:['',[], this.state.Yposition, 0] }}}>{p.split(',').map((k,kind)=><div><span style={{color:'#306190',lineHeight:'19px',paddingBottom:'1px',borderBottom:'1px solid #adadad',}}>{k}</span></div>)}</Link></div><div style={{flex:5, color:'#666666',lineHeight:'17px',}}>{this.processStyledText(this.props.dictionarydict[p]["fullDefinition"])}</div></div>                   
                        } else {
                          return <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}><div style={{color:'#4A80B5',cursor:'pointer',minWidth:(window.innerWidth < 480 ? 100:120)}}>{p}</div><div style={{flex:5}}>{'unlinked'}</div></div>                      
                        }
                      })}
                    </div>
                  </Segment>
                  :
                  null
                }
              </List.Item>
              ))
            :
            null
          }
          </List>
					
            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',margin:'15px',paddingTop:'10px'}}>
            <div style={{textAlign:'center',fontSize:'16px',color:'#000000DE',padding:'10px',fontWeight:'400',lineHeight:'23px'}}>
            <span style={{fontWeight:'bold'}}>{'Dictionary appendices compiled by Edna Ahgeak MacLean.'}</span><span>{' Appendix illustrations by Ronald Engstrom. Fish illustrations below by Dr. Ronald Senungetuk.'}</span>
            </div>
            <div style={{textAlign:'center',fontSize:'16px',color:'#000000DE',padding:'10px',fontWeight:'400',lineHeight:'23px'}}>
            Based on the 2014 publication by University of Alaska Press with the collaboration of the Alaska Native Language Center, University of Alaska Fairbanks, and the North Slope Borough Iñupiaq, History, Language, and Culture Commission (IHLC).
            </div>
            </div>

					</div>
          </div>

			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'16px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>

        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:90,paddingBottom:15,paddingTop:25}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>

				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
			</div>
		);
	}
}
export default AppendixMenu;