import logo from './logo.svg';
import React, {Component} from 'react';
import { BrowserRouter, Switch, Route, NavLink } from 'react-router-dom';
import { Loader, Dimmer, Progress } from 'semantic-ui-react';

import Home from './components/Home.js';
import Entry from './components/Entry.js';
import AppendixMenu from './components/AppendixMenu.js';
import Appendix from './components/Appendix.js';
import WordBuilder from './components/WordBuilder.js';
import SentenceBuilder from './components/SentenceBuilder.js';
import DemonstrativeTables from './components/DemonstrativeTables.js';
import DemonstrativeNavigator from './components/DemonstrativeNavigator.js';
import EndingTables from './components/EndingTables.js';
import Report from './components/Report.js';
import Chinese from './components/constants/chinese.js';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import axios from 'axios';

// export const API_URL = "https://yugtun-api.herokuapp.com";
// export const API_URL = "http://localhost:5000";
export const API_URL = "https://inupiaqonline.com/api";

let dictionary = [];
let dictionarydict = {};

let usagedictionary = [];
var usagedictionary_dict = {};

let postbaseusage = {};
let postbaseusagelist = [];

let audiolibrary = [];
let demonstrativedata = {};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dictionary:[],
      dictionarydict: {},
      postbaseusage:{},
      postbaseusagelist:[],
      audiolibrary: [],
      usagedictionary: [],
      usagedictionary_dict: {},
      demonstrativedata: {},
      innerWidth:window.innerWidth,
      innerHeight:window.innerHeight,
      progressData1:0,
      progressData2:0,
      progressData3:0,
      progressData4:0,
      fullProgress:0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.progressData1 !== prevState.progressData1 || this.state.progressData2 !== prevState.progressData2 || this.state.progressData3 !== prevState.progressData3) {
      this.setState({fullProgress:((this.state.progressData1+this.state.progressData2+this.state.progressData3)/12616540)*100})
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    if (Object.keys(dictionarydict).length == 0 || dictionary.length === 0 || Object.keys(usagedictionary_dict).length == 0 || usagedictionary.length === 0 || Object.keys(postbaseusage).length == 0 || postbaseusagelist.length === 0 || Object.keys(demonstrativedata).length == 0 || audiolibrary.length === 0) {
      axios.get(API_URL + "/allinupiaqdata/all", {
          onDownloadProgress: (progressEvent) => {
            // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            // console.log("onDownloadProgress1", totalLength);
            // if (totalLength !== null) {
              // this.progressData1 = 
            this.setState({progressData1:Math.round( progressEvent.loaded )})
              // console.log(this.progressData1)
            // }
          },
        }).then(response => {
          dictionary = response.data['1'];
          let dictionarydict = {}
          console.log('Fetched dictionary');
          Object.keys(dictionary).forEach(entry => {


            let irregularstripped = ''
            let irregulartext = ''

            if ('irr' in dictionary[entry]) {
              irregularstripped = dictionary[entry]['irr']
              let matches = irregularstripped.match(/⎡.*?⎤/g)
              if (matches !== null) {
                matches.map((m) => irregularstripped = irregularstripped.replaceAll(m,''))           
              }
              irregulartext = ' - ['+dictionary[entry]['irr'].replaceAll('sg.','singular').replaceAll('pl.','plural')+']'
            }
            let keySplitItems = dictionary[entry]['keySplit'].map(k => k[0]).join(" ")
            dictionary[entry]['fullDefinitionNormalized'] = dictionary[entry]['fullDefinition'].replaceAll(',',' ').replaceAll('⎡(i)⎤','').replaceAll('⎡(t)⎤','');
            dictionary[entry]['keyStringNormal'] = keySplitItems.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll(',',' ').replaceAll('(i)','').replaceAll('(t)','')+' '+irregularstripped;
            // dictionary[entry]['keyStringNormal'] = dictionary[entry]['url'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll(',',' ').replaceAll('(i)','').replaceAll('(t)','')+' '+irregularstripped;
            dictionary[entry]['fullDefinition'] = dictionary[entry]['fullDefinition'] + irregulartext
            dictionarydict[dictionary[entry]['url']]=dictionary[entry]
          })
          // console.log('Processed dictionary Normalized')
          // console.log(dictionary)
          this.setState({ dictionary: dictionary, dictionarydict: dictionarydict });


          postbaseusage = response.data['2'];
          Object.keys(postbaseusage).forEach(entry => {
            postbaseusagelist.push(postbaseusage[entry])
          })
          this.setState({ postbaseusagelist:postbaseusagelist, postbaseusage: postbaseusage });


          let usage = response.data['3']
          Object.keys(usage).forEach(entry => Object.keys(usage[entry]).forEach(e => {
            if (!(entry in usagedictionary_dict)) {
              usagedictionary_dict[entry] = {}
            }
            usagedictionary_dict[entry][e] = {}
            usagedictionary_dict[entry][e]['usageWordNormal'] = usage[entry][e]['a'][0].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('>','').replaceAll('&','').replaceAll('↞','').replaceAll('↠','');
            usagedictionary_dict[entry][e]['usageDefinition'] = usage[entry][e]['c'][0].replaceAll('it is ','').replaceAll('she is ','').replaceAll('he is ','').replaceAll('the one ','');
            usagedictionary_dict[entry][e]['usageWords'] = usage[entry][e]['a'];
            usagedictionary_dict[entry][e]['backendCalls'] = usage[entry][e]['b'];
            usagedictionary_dict[entry][e]['newUsageDefinitions'] = usage[entry][e]['c'];
            usagedictionary_dict[entry][e]['url'] = usage[entry][e]['d'];
            usagedictionary_dict[entry][e]['num'] = usage[entry][e]['e'];
            usagedictionary_dict[entry][e]['t'] = usage[entry][e]['f'];
            usagedictionary_dict[entry][e]['fullDefinition'] = usage[entry][e]['g'];
            usagedictionary_dict[entry][e]['thisDefinition'] = usage[entry][e]['h'];
            usagedictionary_dict[entry][e]['properties'] = usage[entry][e]['i'];
            usagedictionary_dict[entry][e]['tag'] = usage[entry][e]['j'];
            usagedictionary_dict[entry][e]['audioMatrix'] = usage[entry][e]['k'];
            if ('l' in usage[entry][e]) {
              usagedictionary_dict[entry][e]['halftransitiveKeys'] = usage[entry][e]['l'];              
            }
            if ('m' in usage[entry][e]) {
            usagedictionary_dict[entry][e]['keytags'] = usage[entry][e]['m'];
            }
            // if ('z' in usage[entry][e]) {
            usagedictionary_dict[entry][e]['differentKeySpellings'] = usage[entry][e]['z'];
            // } 
            usagedictionary.push(usagedictionary_dict[entry][e])
          }

          )) // create dictionary list dictionary
          // console.log(usagedictionary)
          // Object.entries(usagedictionary_dict).forEach(entry => Object.entries(entry[1]).forEach(e => usagedictionary.push(e[1]))) // create dictionary list dictionary

          this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });



          audiolibrary = response.data['4'];
          // console.log('Fetched AudioLibrary');
          audiolibrary.map((k,index)=> audiolibrary[index]['inormal'] = audiolibrary[index]['i'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('.','').replaceAll('?',''))
          // console.log('Processed AudioLibrary Normalized')
          this.setState({ audiolibrary: audiolibrary });


          demonstrativedata = response.data['5'];
          // console.log('Fetched demonstrativedata');
          // console.log(demonstrativedata)
          this.setState({ demonstrativedata: demonstrativedata });

        });          
    } else {
      this.setState({ dictionary: dictionary, dictionarydict: dictionarydict, postbaseusagelist:postbaseusagelist, postbaseusage: postbaseusage, usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict, audiolibrary: audiolibrary, demonstrativedata: demonstrativedata});
    }
    // if (dictionary.length === 0) {
    //   axios
    //     .get(API_URL + "/inupiaqwordslist/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress1", totalLength);
    //         // if (totalLength !== null) {
    //           // this.progressData1 = 
    //         this.setState({progressData1:Math.round( progressEvent.loaded )})
    //           // console.log(this.progressData1)
    //         // }
    //       },
    //     }).then(response => {
    //       dictionary = response.data;
    //       let dictionarydict = {}
    //       console.log('Fetched dictionary');
    //       Object.keys(dictionary).forEach(entry => {


    //         let irregularstripped = ''
    //         let irregulartext = ''

    //         if ('irr' in dictionary[entry]) {
    //           irregularstripped = dictionary[entry]['irr']
    //           let matches = irregularstripped.match(/⎡.*?⎤/g)
    //           if (matches !== null) {
    //             matches.map((m) => irregularstripped = irregularstripped.replaceAll(m,''))           
    //           }
    //           irregulartext = ' - ['+dictionary[entry]['irr'].replaceAll('sg.','singular').replaceAll('pl.','plural')+']'
    //         }
    //         let keySplitItems = dictionary[entry]['keySplit'].map(k => k[0]).join(" ")
    //         dictionary[entry]['fullDefinitionNormalized'] = dictionary[entry]['fullDefinition'].replaceAll(',',' ').replaceAll('⎡(i)⎤','').replaceAll('⎡(t)⎤','');
    //         dictionary[entry]['keyStringNormal'] = keySplitItems.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll(',',' ').replaceAll('(i)','').replaceAll('(t)','')+' '+irregularstripped;
    //         // dictionary[entry]['keyStringNormal'] = dictionary[entry]['url'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll(',',' ').replaceAll('(i)','').replaceAll('(t)','')+' '+irregularstripped;
    //         dictionary[entry]['fullDefinition'] = dictionary[entry]['fullDefinition'] + irregulartext
    //         dictionarydict[dictionary[entry]['url']]=dictionary[entry]
    //       })
    //       // console.log('Processed dictionary Normalized')
    //       console.log(dictionary)
    //       this.setState({ dictionary: dictionary, dictionarydict: dictionarydict });
    //     });
    // }
    // else {
    //   this.setState({ dictionary: dictionary, dictionarydict: dictionarydict });
    // }

    // if (Object.keys(postbaseusage).length === 0 || postbaseusagelist.length === 0) {
    //   axios
    //     .get(API_URL + "/inupiaqsearchableusagepostbasedict/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress1", totalLength);
    //         // if (totalLength !== null) {
    //           // this.progressData1 = 
    //         this.setState({progressData4:Math.round( progressEvent.loaded )})
    //           // console.log(this.progressData1)
    //         // }
    //       },
    //     }).then(response => {
    //       postbaseusage = response.data;
    //       Object.keys(postbaseusage).forEach(entry => {
    //         postbaseusagelist.push(postbaseusage[entry])
    //       })
    //       // console.log('Fetched postbases',postbaseusage);
    //       // console.log('Fetched postbase list',postbaseusagelist);
    //       // postbaseusage.map((k,index)=> audiolibrary[index]['inormal'] = audiolibrary[index]['i'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('.','').replaceAll('?',''))
    //       // FIGURE OUT HOW TO SEARCH WITH SYMBOLS FOR INUPIAQ ONLY

    //       this.setState({ postbaseusagelist:postbaseusagelist, postbaseusage: postbaseusage });
    //     });
    // }
    // else {
    //   this.setState({ postbaseusage: postbaseusage });
    // }


    // if (usagedictionary.length === 0) {
    //   axios
    //     .get(API_URL + "/inupiaqsearchableusagedict/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress2", totalLength);
    //         // if (totalLength !== null) {
    //           // this.progressData2 = Math.round( (progressEvent.loaded * 100) / totalLength );
    //         this.setState({progressData2:Math.round( progressEvent.loaded)})
    //           // console.log(this.progressData2)
    //         // }
    //       },
    //     }).then(response => {
    //       // usagedictionary_dict = response.data;
    //       // this.setState({ usagedictionary: response.data })
    //       console.log('Fetched usage dictionary');
    //       console.log(response.data)
    //       Object.keys(response.data).forEach(entry => Object.keys(response.data[entry]).forEach(e => {
    //         if (!(entry in usagedictionary_dict)) {
    //           usagedictionary_dict[entry] = {}
    //         }
    //         usagedictionary_dict[entry][e] = {}
    //         usagedictionary_dict[entry][e]['usageWordNormal'] = response.data[entry][e]['a'][0].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('>','').replaceAll('&','').replaceAll('↞','').replaceAll('↠','');
    //         usagedictionary_dict[entry][e]['usageDefinition'] = response.data[entry][e]['c'][0].replaceAll('it is ','').replaceAll('she is ','').replaceAll('he is ','').replaceAll('the one ','');
    //         usagedictionary_dict[entry][e]['usageWords'] = response.data[entry][e]['a'];
    //         usagedictionary_dict[entry][e]['backendCalls'] = response.data[entry][e]['b'];
    //         usagedictionary_dict[entry][e]['newUsageDefinitions'] = response.data[entry][e]['c'];
    //         usagedictionary_dict[entry][e]['url'] = response.data[entry][e]['d'];
    //         usagedictionary_dict[entry][e]['num'] = response.data[entry][e]['e'];
    //         usagedictionary_dict[entry][e]['t'] = response.data[entry][e]['f'];
    //         usagedictionary_dict[entry][e]['fullDefinition'] = response.data[entry][e]['g'];
    //         usagedictionary_dict[entry][e]['thisDefinition'] = response.data[entry][e]['h'];
    //         usagedictionary_dict[entry][e]['properties'] = response.data[entry][e]['i'];
    //         usagedictionary_dict[entry][e]['tag'] = response.data[entry][e]['j'];
    //         usagedictionary_dict[entry][e]['audioMatrix'] = response.data[entry][e]['k'];
    //         if ('l' in response.data[entry][e]) {
    //           usagedictionary_dict[entry][e]['halftransitiveKeys'] = response.data[entry][e]['l'];              
    //         }
    //         if ('m' in response.data[entry][e]) {
    //         usagedictionary_dict[entry][e]['keytags'] = response.data[entry][e]['m'];
    //         }
    //         // if ('z' in response.data[entry][e]) {
    //         usagedictionary_dict[entry][e]['differentKeySpellings'] = response.data[entry][e]['z'];
    //         // } 
    //         usagedictionary.push(usagedictionary_dict[entry][e])
    //       }

    //       )) // create dictionary list dictionary
    //       console.log(usagedictionary)
    //       // Object.entries(usagedictionary_dict).forEach(entry => Object.entries(entry[1]).forEach(e => usagedictionary.push(e[1]))) // create dictionary list dictionary

    //       this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });
    //     });
    // }
    // else {
    //   this.setState({ usagedictionary: usagedictionary, usagedictionary_dict: usagedictionary_dict });
    // }

    // if (audiolibrary.length === 0) {
    //   axios
    //     .get(API_URL + "/inupiaqaudiolibrary/all", {
    //       onDownloadProgress: (progressEvent) => {
    //         // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    //         // console.log("onDownloadProgress3", totalLength);
    //         // if (totalLength !== null) {
    //         this.setState({progressData3:Math.round( progressEvent.loaded )})
    //           // this.progressData3 = Math.round( (progressEvent.loaded * 100) / totalLength );
    //           // console.log(this.progressData3)
    //         // }
    //       },
    //     }).then(response => {
    //       audiolibrary = response.data;
    //       console.log('Fetched AudioLibrary');
    //       audiolibrary.map((k,index)=> audiolibrary[index]['inormal'] = audiolibrary[index]['i'].replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('-','').replaceAll('.','').replaceAll('?',''))
    //       console.log('Processed AudioLibrary Normalized')
    //       this.setState({ audiolibrary: audiolibrary });
    //     });
    // }
    // else {
    //   this.setState({ audiolibrary: audiolibrary });
    // }

    // if (Object.keys(demonstrativedata).length === 0) {
    //   axios
    //     .get(API_URL + "/demonstrativedata/all").then(response => {
    //       demonstrativedata = response.data;
    //       console.log('Fetched demonstrativedata');
    //       // console.log(demonstrativedata)
    //       this.setState({ demonstrativedata: demonstrativedata });
    //     });
    // }
    // else {
    //   this.setState({ demonstrativedata: demonstrativedata });
    // }

  }

  render() {
    // console.log(window.innerHeight)
    // console.log(this.state)
    //  <Route exact path='/appendix/:word' render={(props) => (props['match']['params']['word'] in appendixInformation ? <Appendix dictionarydict={this.state.dictionarydict} audiolibrary={this.state.audiolibrary} {...props} /> : <AppendixMenu dictionarydict={this.state.dictionarydict} audiolibrary={this.state.audiolibrary} {...props} />)}/>

    return(
    <BrowserRouter>
    <div className="app">
        {
            this.state.dictionary.length !== 0 && this.state.usagedictionary.length !== 0 && this.state.audiolibrary.length !== 0 && Object.keys(this.state.usagedictionary_dict).length !== 0  && Object.keys(this.state.dictionarydict).length !== 0 ?
            <Switch>
              <Route exact path='/' render={(props) => <Home dictionary={this.state.dictionary} audiolibrary={this.state.audiolibrary} usagedictionary={this.state.usagedictionary} usagedictionary_dict={this.state.usagedictionary_dict} {...props} />}/>
              <Route exact path='/demonstratives' render={(props) => <DemonstrativeNavigator demonstrativedata={this.state.demonstrativedata} dictionarydict={this.state.dictionarydict} {...props} />}/>
              <Route exact path='/endingtables' render={(props) => <EndingTables demonstrativedata={this.state.demonstrativedata}  postbaseusage={this.state.postbaseusage} postbaseusagelist={this.state.postbaseusagelist} dictionarydict={this.state.dictionarydict} audiolibrary={this.state.audiolibrary} usagedictionary={this.state.usagedictionary} usagedictionary_dict={this.state.usagedictionary_dict} {...props} />}/>
              <Route exact path='/report' component={Report}></Route>
              <Route exact path='/chinese' component={Chinese}></Route>
              <Route exact path='/appendix' render={(props) => <AppendixMenu dictionarydict={this.state.dictionarydict} audiolibrary={this.state.audiolibrary} {...props} />}/>
              <Route exact path='/appendix/:word' render={(props) => <Appendix dictionarydict={this.state.dictionarydict} audiolibrary={this.state.audiolibrary} {...props} />}/>
              <Route exact path='/wordbuilder/:word' render={(props) => <SentenceBuilder dictionary={this.state.dictionary} audiolibrary={this.state.audiolibrary} postbaseusage={this.state.postbaseusage} usagedictionary={this.state.usagedictionary} usagedictionary_dict={this.state.usagedictionary_dict} {...props} />}/>
              <Route exact path='/demonstratives/:word' render={(props) => <DemonstrativeTables demonstrativedata={this.state.demonstrativedata} dictionarydict={this.state.dictionarydict} {...props} />}/>
              <Route exact path='/:word' render={(props) => <Entry dictionary={this.state.dictionary} audiolibrary={this.state.audiolibrary} usagedictionary={this.state.usagedictionary} usagedictionary_dict={this.state.usagedictionary_dict} {...props} />}/>
            </Switch>     
            :
            <div>
            <Dimmer style={{height:(window.innerHeight > 870 ? window.innerHeight: 870)}} active>
              <Loader style={{marginTop:-((870-window.innerHeight)/2)}} size='massive'>
                Iñupiaq Online is loading...
{/*              <Progress style={{marginTop:20}} percent={this.state.fullProgress} size='tiny' />
*/}              </Loader>
            </Dimmer>
            <Switch>
              <Route exact path='/' render={(props) => <Home dictionary={this.state.dictionary} audiolibrary={this.state.audiolibrary} usagedictionary={this.state.usagedictionary} usagedictionary_dict={this.state.usagedictionary_dict} {...props} />}/>
            </Switch>
            </div>
        }

    </div>
    </BrowserRouter>
    );
  }
};

export default App;
