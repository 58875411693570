import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, Dropdown, List, Label, Popup, Checkbox, Transition} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL } from '../App.js';
import axios from 'axios';
import {retrieveSubjectObject, nounoptions1, nounoptions2, nounoptions3, nounoptionsmodalis, options1, options2, options3, verbPostbases, nounPostbases} from './constants/constants.js'
import {postbasesEndingsUnderlying} from './constants/underlying.js'
import palette from 'google-palette';
import shuffle from 'shuffle-array';
import { TagColors } from './HomeHelper.js';
import * as Scroll from 'react-scroll';

var scroll    = Scroll.animateScroll;

let peopleDict = {
	'1':"Sg",
	'2':"Du",
	'3':"Pl",
}
let notis = [1,2,7,10]
let future = [1,2]
let nextinfinitive = [1,2,6,8,9,11]
let matchCase = [3,4,5,12]

let pastwas = [8,9]
let pastdid = [4] // becomes inf
let pastVerbTense = [3,5,12]
let pastGerund = [7,10]
let doesis = [7,10]

let beingnotis = [1,2,7,10]
let befirst = [1,2,6,8,9,11]
let beingfirst = [7,10]

let beingwas = [3,4,5,8,9,12]

// let nounPostbaseDefaultNoNV = [13,14,15,16]
let verbPostbaseParticipialDefault = [3,4,5,6,7,8,9,10,11,12]
let verbPostbaseDefault = [1,2,3,4,5,6,7,8,9,10,11,12]
let nnpostbases = [13,14,15,16]
let nounPostbaseDefault = [13,14,15,16,17,18,20,21,22,23,24,25]
let nounToVerb = [17,18,19,20,21,22,23,24,25]
let nvNoIs = [20,21,22]

let serifFont = 'Times New Roman'

let getVerbInfo = {
	0:{'inup':'','eng':'',text:'past tense'},
	1:{'inup':'','eng':'',text:'question: yes or no?'},
	2:{'inup':'summan ','eng':'why ',text:'question: why?'},
	3:{'inup':'qanuq ','eng':'how ',text:'question: how?'},
	4:{'inup':'sumi ','eng':'at where ',text:'question: where at?'},
	5:{'inup':'sumiñ ','eng':'from where ',text:'question: from where?'},
	// 5:{'inup':'sumun ','eng':'to where ',text:'question: to where?'},
}


let nounQuestionWords = [
	{value:0,key:0,text:'at (localis)'},
	{value:1,key:1,text:'toward (terminalis)'},
	{value:2,key:2,text:'from (ablative)'},
	{value:3,key:3,text:'like (similaris)'},
	{value:4,key:4,text:'the (relative)'},
	{value:5,key:5,text:'a, some (modalis)'},
	{value:6,key:6,text:'through, by means of, via (vialis)'},
]

let getNounInfo = {
	0:{'inup':'','eng':'at ',text:'Localis',buttontext:'at (localis)'},
	1:{'inup':'','eng':'toward ',text:'Terminalis',buttontext:'toward (terminalis)'},
	2:{'inup':'','eng':'from ',text:'Ablative',buttontext:'from (ablative)'},
	3:{'inup':'','eng':'like ',text:'Similaris',buttontext:'like (similaris)'},
	4:{'inup':'','eng':'',text:'Relative',buttontext:'the (relative)'},
	5:{'inup':'','eng':'',text:'Modalis',buttontext:'a, some (modalis)'},
	6:{'inup':'','eng':'through, by means of, via ',text:'Vialis',buttontext:'through, by means of, via (vialis)'},
	// 2:{'inup':'summan ','eng':'why ',text:'question: why?'},
	// 3:{'inup':'qanuq ','eng':'how ',text:'question: how?'},
	// 4:{'inup':'sumi ','eng':'at where ',text:'question: where at?'},
	// 5:{'inup':'sumun ','eng':'to where ',text:'question: to where?'},
	// 6:{'inup':'sumiñ ','eng':'from where ',text:'question: from where?'},
}



let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"
let letterbutton = {paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}

let nounvalue1def = '1'
let nounvalue2def = '00(3)'
let value1def = 's31-3(1)'
let value2def = 'o31-3(2)'

class WordBuilder extends Component {
	constructor(props) {
		console.log(props)
		super(props);
		this.state = {
			// show: false,
			// word: decodeURI(props.match.params.word),
			// word: 'nakuu-',
			entryModified:[],
			entryModifiedAlt:[],
			directLink: props.location.state === undefined ? false : true,
			num: props.location.state === undefined ? -1 : props.location.state.num,
			usageDefinition: props.location.state === undefined ? '' : props.location.state.usageDefinition,
			baseUsageWord: props.location.state === undefined ? '' : props.location.state.word,
			baseCase: props.location.state === undefined ? '' : props.location.state.baseCase,
			fullDefinition: props.location.state === undefined ? '' : props.location.state.fullDefinition,
			history: props.location.state === undefined ? [] : props.location.state.history,
			from: props.location.state === undefined ? '' : props.location.state.from,
			activeKeyInEditIndex: props.location.state === undefined ? 0 : props.location.state.activeKeyInEditIndex,
			audioMatrix: props.location.state === undefined ? [''] : props.location.state.audioMatrix,
			properties: [],
			tag: '',
			baseTag: '',
			allBases: [],
			entryUrl: '',
			baseOptions: [],
	    dialect: [],
			nounMood: 'Absolutive',
			verbMood: 'Indicative',
			loaded: false,
			// baseOptions: [],
			postbaseInformationAvailable1: [],
			postbaseInformationAvailable2: [],
			postbasesAvailable: [],
			postbaseMaster: {},
			allPostbasesAvailable: [],
			colorsList: shuffle(palette('tol-rainbow', 30).map((c) => { return '#' + c; })),
			// url: 
			displayDispense:false,
			utiAdded:false,
			notEdited:true,

			subjectIs:'is ',
			subject:'it',
			people: 1,
			person: 3,
			nounvalue1: props.location.state === undefined ? nounvalue1def : props.location.state.nounvalue1,
			nounvalue2: props.location.state === undefined ? nounvalue2def : props.location.state.nounvalue2,
			value1: props.location.state === undefined ? value1def : props.location.state.value1,
			value2: props.location.state === undefined ? value2def : props.location.state.value2,
			sisters: [],

			currentPostbases: [],
			availablePostbaseInformationText: [],
			usageVerbTenses: [],

			transitiveLeftOfObject:'',
			transitiveRightOfObject:'',
			englishPreVerb:[],
			englishPreSubject: [],
			englishPreNoun:[],
			englishPreNounOptions:'',
			leftOfVerb: '',
			rightOfVerb: '',
			primaryNoun: '',
			primaryVerb: '',
			primaryVerbBase: '',
			bePreVerb: '',
			beforeSubject: '',
			questionMark: '',
			questionWord: '',
			questionWordEnglish: '',
			moodSpecific: '',
			endingNumber: -1,
			hasFuture: false,
			underlyingCallReturn: [],
			postbase1: -1,
			postbase2: -1,
			ending1: -1,

			objectPossessed: false,
			showAlt: false,
      options1: options1,
      options2: options2,
      options3: options3,
		}

	    this.getWord = this.getWord.bind(this);
	    this.addPostbase = this.addPostbase.bind(this);
	    if (props.location.state === undefined) { 
	    	this.getWord(decodeURI(props.match.params.word),-1);	    	
	    } else {
	    	this.getWord(decodeURI(props.match.params.word),props.location.state.num);	    	
	    }
	    this.setNoun = this.setNoun.bind(this);
	    this.setIntransitive = this.setIntransitive.bind(this);
	    
	}

  identifyObjectCase = (match) => {
  	// console.log('eventually set the object type here, her/him versus it')
  	let ind = Math.round(Math.random())
		let value1 = ''
		let value2 = ''
		let possessed = false
		if (ind % 2 == 0) {
		value1 = "s31-2(1)"  
		} else {
		value1 = "s31-1(1)"  
		}
		if (match == '<her/him/it>') {
  		value2 = "o31-3(2)"
		} else if (match == '<her/him>') {
  		if (ind % 2 == 0) {
  		value2 = "o31-1(2)"			
  		} else {
  		value2 = "o31-2(2)"
  		}
		} else if (match == '<her/his>') {
  		if (ind % 2 == 0) {
  		value2 = "o31-1(2)"			
  		} else {
  		value2 = "o31-2(2)"
  		}
		} else if (match == '<her>') {
  		value2 = "o31-2(2)"
		} else if (match == '<him>') {
  		value2 = "o31-1(2)"
		} else if (match.includes('<him =')) {
  		value2 = "o31-1(2)"
		} else if (match == '<a person/it>') {
  		value2 = "o31-3(2)"
		} else if (match == '<it>') {
  		value2 = "o31-3(2)"
		} else if (match == '<them>') {
  		value2 = "o33(2)"
		} else if (match == '<someone/something>') {
  		value2 = "o31-3(2)"
		} else if (match == '<her/his/its>') {
			possessed = true
  		value2 = "o31-3(2)"
		} else {
  		value2 = "o31-3(2)"			
		}
		this.setState({objectPossessed:possessed})

		if (!this.state.directLink) {
			this.setState({value1:value1,value2:value2})
		}
  }


	defaultFstCall = (tag,usageDefinition) => {
		// console.log(ind)
		// console.log(tag)
		this.setState({baseTag: tag})
		if (tag === '(i) INTRANSITIVE VERB') {
			// this.setState({person: 3,people: 1, value1: "31-1(1)",activeEditIndex:ind}); this.getFSTParse(fstCall,ind,0); 
			// console.log(this.state.usageDefinition)
			let verbTenseMatch = usageDefinition.match(/⟨.*?⟩/g)
			let splitAroundVerb = []
			if (verbTenseMatch.length == 1) {
				splitAroundVerb = usageDefinition.split(verbTenseMatch[0])
			}
			this.setState({
				leftOfVerb: splitAroundVerb[0],
				rightOfVerb: splitAroundVerb[1],
				primaryVerb: verbTenseMatch[0].replace("⟨","").replace("⟩",""),
				primaryVerbBase: verbTenseMatch[0].replace("⟨","").replace("⟩",""),
			},()=>{
				this.setIntransitive(true,undefined,undefined)
			})

		} else if (tag === 'NOUN') {
			this.setState({
				primaryNoun: usageDefinition,
			},()=>{
				this.setNoun(true,undefined,undefined)				
			})			
		} else if (tag === '(t) TRANSITIVE VERB') {
			let matches = usageDefinition.match(/<.*?>/g)
			let splitSentence = []
			if (matches.length == 1) {
				splitSentence = usageDefinition.split(matches[0])
			}

			this.identifyObjectCase(matches[0])				
			// let leftOfObject = splitSentence[0]
			let verbTenseMatch = splitSentence[0].match(/⟨.*?⟩/g)
			let splitLeftOfObject = []
			if (verbTenseMatch.length == 1) {
				splitLeftOfObject = splitSentence[0].split(verbTenseMatch[0])
			}
			this.setState({
				leftOfVerb: splitLeftOfObject[0],
				rightOfVerb: splitLeftOfObject[1],
				primaryVerb: verbTenseMatch[0].replace("⟨","").replace("⟩",""),
				primaryVerbBase: verbTenseMatch[0].replace("⟨","").replace("⟩",""),
				transitiveRightOfObject: splitSentence[1].trim(),
				// entryModified: matches[0],
			}, ()=>{
				this.setTransitive(true,undefined,undefined)
			});
			
		}

		if (tag === 'NOUN') {
			let postbaseInformationAvailable = []
			if (this.state.properties.includes('dispensable noun -- food')) { // include tuq eat use postbase
				if (this.state.entryUrl == 'aġviq') {
					nounPostbaseDefault =[13,14,16,17,18,19,20,21,22,23,24,25] // remove bad
				} else {
					nounPostbaseDefault =[13,14,15,16,17,18,19,20,21,22,23,24,25]					
				}
			} else {
				nounPostbaseDefault =[13,14,15,16,17,18,20,21,22,23,24,25]
			}

			nounPostbaseDefault.map((a)=>{
				postbaseInformationAvailable.push({value:a,key:a,text:nounPostbases[a].description})
			})
			this.setState({postbasesAvailable:nounPostbaseDefault})
			this.setState({allPostbasesAvailable:nounPostbaseDefault})
			this.setState({postbaseMaster:nounPostbases})
			this.setState({postbaseInformationAvailable1:postbaseInformationAvailable})
		} else {
			let postbaseInformationAvailable = []
			verbPostbaseDefault.map((a)=>{
				postbaseInformationAvailable.push({value:a,key:a,text:verbPostbases[a].description})
			})
			this.setState({postbasesAvailable:verbPostbaseDefault})
			this.setState({allPostbasesAvailable:verbPostbaseDefault})
			this.setState({postbaseMaster:verbPostbases})
			this.setState({postbaseInformationAvailable1:postbaseInformationAvailable})
		}

		let baseOptions = []

		// let allBases = this.state.allBases
		// const index = this.state.allBases.indexOf(this.state.baseCase);
		// let allBases = this.state.allBases.splice(index,1)

		// baseOptions.push({id:0,value:0,text:this.state.baseCase})

		this.state.allBases.map((m,ind)=>{
			baseOptions.push({id:ind,value:ind,text:m[0]})
		})
		this.setState({baseOptions:baseOptions})

	}


  getWord(word,num) {
  	// console.log(word, num)
  	this.setState({
			// nounvalue1: nounvalue1def,
			// nounvalue2: nounvalue2def,
			// value1: value1def,
			// value2: value2def,
			verbMood: 'Indicative',
			nounMood: 'Absolutive',
			currentPostbases: [],
			primaryVerb: "",
			primaryVerbBase: "",
			englishPreSubject: [],
			englishPreNoun: [],
			englishPreVerb: [],
			primaryNoun: '',
			englishPreNounOptions:'',
			postbase1: -1,
			postbase2: -1,
		})

//REWRITE SO THAT THE BASE CASE DOESNT CALL 0 IN CASE INDEX 0 IS EMPTY?
//SET THE SIMPLE WORD BUILDER TO SEND IN THE RIGHT NUM SO THAT IT IS CALLED HERE APPROPRIATELY

    axios
      .get(API_URL + "/inupiaqusage/" + encodeURIComponent(word))
      .then(response => {
        // console.log(response.data, num);
        let tag;
        if (num === -1) {
        	// console.log(response.data[Object.keys(response.data)[0]])
	        this.setState({
	          num: response.data[Object.keys(response.data)[0]].num,	
	          usageDefinition: response.data[Object.keys(response.data)[0]].usageDefinition,	
	          fullDefinition: response.data[Object.keys(response.data)[0]].fullDefinition,	
	          baseUsageWord: response.data[Object.keys(response.data)[0]].usageWord,	
	          underlyingForms: response.data[Object.keys(response.data)[0]].underlyingForms,
	          dialect: response.data[Object.keys(response.data)[0]].dialect,
	          baseCase: response.data[Object.keys(response.data)[0]].allBases[this.state.activeKeyInEditIndex],
	          properties: response.data[Object.keys(response.data)[0]].properties,
						
	          allBases: response.data[Object.keys(response.data)[0]].allBases,
	          entryUrl: response.data[Object.keys(response.data)[0]].url,	
	          tag: response.data[Object.keys(response.data)[0]].tag,	
	          sisters: response.data[Object.keys(response.data)[0]].sisters,
	          usageVerbTenses: response.data[Object.keys(response.data)[0]].usageVerbTenses,
	          audioMatrix: response.data[Object.keys(response.data)[0]].audioMatrix,

	      	},()=>{
	      		console.log(this.state.value1def)
			      if ((this.state.value1 == 's31-1(1)' || this.state.value1 == 's31-2(1)') && response.data[Object.keys(response.data)[0]].usageDefinition.includes("(of")) {
			  			this.setState({value1: "s31-3(1)"})	
			  		}
		        tag = response.data[Object.keys(response.data)[0]].tag
		        this.defaultFstCall(tag,response.data[Object.keys(response.data)[0]].usageDefinition)
		        this.setState({loaded:true})
	      	})

        } else {
        	// console.log(response.data[this.state.num])        
	        this.setState({
	        	// underlyingForms: response.data[this.state.num].underlyingForms,	

	          usageDefinition: response.data[num].usageDefinition,	
	          fullDefinition: response.data[num].fullDefinition,
	          baseUsageWord: response.data[num].usageWord,	
	          underlyingForms: response.data[num].underlyingForms,
	          dialect: response.data[num].dialect,
	          baseCase: response.data[num].allBases[this.state.activeKeyInEditIndex],
	          properties: response.data[num].properties,
	          // properties: ['transitional base'],

	          allBases: response.data[num].allBases,	
	          entryUrl: response.data[num].url,	
	          tag: response.data[num].tag,	
	          sisters: response.data[num].sisters,
	          usageVerbTenses: response.data[num].usageVerbTenses,
	          audioMatrix: response.data[num].audioMatrix,
	      	},()=>{
		      	// console.log(response.data[num].allBases)
	      		console.log(this.state.value1def)

			      if ((this.state.value1 == 's31-1(1)' || this.state.value1 == 's31-2(1)') && response.data[num].usageDefinition.includes("(of")) {
			  			this.setState({value1: "s31-3(1)"})	
			  		}
		        tag = response.data[num].tag
		        this.defaultFstCall(tag,this.state.usageDefinition)
		        this.setState({loaded:true})
	      	})
        }   

        this.setState({
          entryData: response.data,
        });
        // console.log(tag)
        
        // if (tag == 'NOUN') {
        // 	this.setNoun(this,true)
        // } else if (tag == '(i) INTRANSITIVE VERB') {
        // 	this.setIntransitive(this,true)
        // }
        
      });
  }


  setIntransitive(initializing, e, data) {
  	// console.log(e, data)
  	let value1;
  	let value2 = this.state.value2
  	let tag = '(i) INTRANSITIVE VERB'
  	let currentPostbases;
  	// let verbMood = 'Indicative'

  	if (initializing) {

  		
  		// let ind = Math.round(Math.random())
  		// if (ind % 2 == 0) {
  		// value1 = "s31-2(1)"  			
  		// } else {
  		// value1 = "s31-1(1)"   
  		// }
  		value1 = this.state.value1
  		currentPostbases = []
  		// person = 
  		// people = 
  	} else {
  		value1 = data.value
  		currentPostbases = this.state.currentPostbases
  		// person = data.value[0]
  		// people = data.value[1]
  	}

  	// let fstCall = ''
   //  if (verbMood === 'Indicative') {
   //  	fstCall = '>+V+Ind+Prs+' + value1[1] + peopleDict[value1[2]]
   //  }

  	// if (value1.slice(0,3) == "s31") {
  	// 	this.setState({notEdited:true})
  	// } else {
  	// 	this.setState({notEdited:false})  		
  	// }

  	this.isBaseCaseAudio(this.state.verbMood,null,value1,value2,null,null)

    this.setState({
      value1: value1,
      // activeEditIndex:ind,
      // person: person,
      // people: people,
      // fstCall: fstCall,
    });

    if (this.state.baseTag === 'NOUN') {
    	this.setEnglishNoun(currentPostbases,value1,'',this.state.nounMood)
    } else {
    	this.setEnglish(currentPostbases,value1,'',this.state.verbMood,this.state.moodSpecific)
    }
		this.setAvailablePostbaseInformation(value1, currentPostbases,this.state.verbMood,this.state.nounMood)
    this.getFSTParse(this.state.baseCase,currentPostbases,'(i) INTRANSITIVE VERB',this.state.verbMood,this.state.moodSpecific,value1,'',this.state.nounMood,[],[])
  }

  setTransitive(initializing, e, data) {
  	console.log(e, data, initializing)
  	let value1, value2, currentPostbases;
  	if (initializing) {
  		// if (ind % 2 == 0) {
  		value1 = this.state.value1 			
  		// } else {
  		// value1 = "s31-1(1)"  
  		// }
  		value2 = this.state.value2
  		// person = 
  		// people = 
  		currentPostbases = []
  	} else {
  		console.log(data.value)
  		if (data.value[0] == 's') {
	  		value1 = data.value
	  		value2 = this.state.value2
  		} else {
	  		value1 = this.state.value1
	  		value2 = data.value	
  		}
  		// person = data.value[0]
  		// people = data.value[1]
  		currentPostbases = this.state.currentPostbases
  	}


  	let flag1 = false
  	let flag2 = false
  	let newoptions1 = []
  	let newoptions2 = []
  	let newoptions3 = []

  	if (value1[1]=='1' || value1[1]=='2') {
	  	for (var i = 0; i < 11; i++) {
	  		if (value1[1]=='1') {
	  			if (![0,5,8].includes(i)) {
			  		newoptions2.push(options2[i])
			  		newoptions3.push(options3[i])
	  			}
	  		} else if (value1[1]=='2') {
	  			if (![1,6,9].includes(i)) {
			  		newoptions2.push(options2[i])
			  		newoptions3.push(options3[i])
	  			}
	  		}
	  	}
	  } else {
  		newoptions2 = options2
  		newoptions3 = options3
	  }
  	
  	if (value2[1]=='1' || value2[1]=='2') {
	  	for (var i = 0; i < 11; i++) {
	  		if (value2[1]=='1') {
	  			if (![0,5,8].includes(i)) {
	  				newoptions1.push(options1[i])
	  			}
	  		} else if (value2[1]=='2') {
	  			if (![1,6,9].includes(i)) {
	  				newoptions1.push(options1[i])
	  			}
	  		}
	  	}
  	} else {
  		newoptions1 = options1
  	}


  	// if (value1.slice(0,3) == "s31" && value2.slice(0,3) == "o31") {
  	// 	this.setState({notEdited:true})
  	// } else {
  	// 	this.setState({notEdited:false})  		
  	// }

  	this.isBaseCaseAudio(this.state.verbMood,null,value1,value2,null,null)

  	// let fstCall = ''
   //  if (this.state.verbMood === 'Indicative') {
   //  	fstCall = '>+V+Ind+Prs+' + value1[1] + peopleDict[value1[2]] + '+' + value2[1]+peopleDict[value2[2]] + 'O'
   //  }

    this.setState({
      value1: value1,
      value2: value2,
      options1: newoptions1,
      options2: newoptions2,
      options3: newoptions3,
      // activeEditIndex:ind,
      // person: person,
      // people: people,
      // fstCall: fstCall,
    });

		this.setAvailablePostbaseInformation(value1,currentPostbases,this.state.verbMood,this.state.nounMood)
    this.setEnglish(currentPostbases,value1,value2,this.state.verbMood,this.state.moodSpecific)
    this.getFSTParse(this.state.baseCase,currentPostbases,'(t) TRANSITIVE VERB',this.state.verbMood,this.state.moodSpecific,value1,value2,this.state.nounMood,[],[])
  }


	// getSubjectIs = (personN, peopleN) => {
	//   let subjectis = '';
	//   if (this.state.currentPostbases.length > 0) {
	// 	  if (!notis.includes(this.state.currentPostbases[0])) {
	// 		  if (peopleN === '1' && personN === '1') {
	// 		    subjectis = 'am '
	// 		  } else if (peopleN === '1' && personN === '3') {
	// 		    subjectis = 'is '
	// 		  } else {
	// 		    subjectis = 'are '
	// 		  }	  	
	// 	  }  	
	//   } else {
	// 	  if (peopleN === '1' && personN === '1') {
	// 	    subjectis = 'am '
	// 	  } else if (peopleN === '1' && personN === '3') {
	// 	    subjectis = 'is '
	// 	  } else {
	// 	    subjectis = 'are '
	// 	  }	 	  	
	//   }
 //    this.setState({
 //      person: parseInt(personN),
 //      people: parseInt(peopleN),
 //      subjectIs: subjectis,
 //      })	 
	// };

	getIs = (personN, peopleN,capitalize) => {
		// console.log()
		let subjectis = '';
		if (capitalize) {
			if (peopleN === '1' && personN === '1') {
		    subjectis = 'Am '
		  } else if (peopleN === '1' && personN === '3') {
		    subjectis = 'Is '
		  } else {
		    subjectis = 'Are '
		  }				
		} else {
			if (peopleN === '1' && personN === '1') {
		    subjectis = 'am '
		  } else if (peopleN === '1' && personN === '3') {
		    subjectis = 'is '
		  } else {
		    subjectis = 'are '
		  }	
		}
	  return subjectis
	}

	getDo = (personN, peopleN,capitalize) => {
		let subjectis = '';
		if (capitalize) {
		  if (peopleN === '1' && personN === '1') {
		    subjectis = 'Do '
		  } else if (peopleN === '1' && personN === '3') {
		    subjectis = 'Does '
		  } else {
		    subjectis = 'Do '
		  }
		} else {
		  if (peopleN === '1' && personN === '1') {
		    subjectis = 'do '
		  } else if (peopleN === '1' && personN === '3') {
		    subjectis = 'does '
		  } else {
		    subjectis = 'do '
		  }
		}
		return subjectis
	}

  setNoun(initializing, e, data) {
  	// console.log(e,data,initializing)
  	let nounvalue1, nounvalue2, owner;
  	if (initializing) {
  		nounvalue1=this.state.nounvalue1
  		nounvalue2=this.state.nounvalue2
  		// owner = '+'+this.state.nounvalue2[0]+peopleDict[this.state.nounvalue2[1]]
  		// person = 
  		// people = 
  	} else {
  		if (data.value.length == 1) {
	  		nounvalue1=data.value
	  		nounvalue2=this.state.nounvalue2
  		} else {
	  		nounvalue1=this.state.nounvalue1
	  		nounvalue2=data.value	
  		}

  		// console.log(owner)
  		// value1 = data.value
  		// person = data.value[0]
  		// people = data.value[1]
  	}

	// if (nounvalue2[0] !== '0') {
	// 	owner = '+'+nounvalue2[0]+peopleDict[nounvalue2[1]]
	// } else {
	// 	owner = ''
	// }


  	this.isBaseCaseAudio(null,this.state.nounMood,null,null,nounvalue1,nounvalue2)


  	// if (nounvalue1 == "1" && nounvalue2 == "00(3)") {
  	// 	this.setState({notEdited:true})
  	// } else {
  	// 	this.setState({notEdited:false})  		
  	// }
 //  	let fstCall = ''
 //    if (this.state.nounMood === 'Absolutive') {
 //    	fstCall = '>+N+Abs+' + peopleDict[nounvalue1] + owner
 //    }

    this.setState({
      nounvalue1: nounvalue1,
      nounvalue2: nounvalue2,
      // activeEditIndex:ind,
      // person: person,
      // people: people,
      // fstCall: fstCall,
    });

		this.setAvailablePostbaseInformation('',this.state.currentPostbases,this.state.verbMood,this.state.nounMood)
    this.setEnglishNoun(this.state.currentPostbases,nounvalue1,nounvalue2,this.state.nounMood)
    this.getFSTParse(this.state.baseCase,this.state.currentPostbases,'NOUN',this.state.verbMood,this.state.moodSpecific,[],[],this.state.nounMood,nounvalue1,nounvalue2)
  }




	// processUnderlyingForm() {
	// 	//add here
	// }

	switchToSister(info) {
		// console.log(info)
		// this.getWord(this.state.word,info['num'])
		scroll.scrollTo(0);
		this.state.currentPostbases.map((i)=>{
			console.log(i)
			this.removePostbase(i)
		})
		let activeIndex = 0
		let num = info['num']

		if (this.state.tag == this.state.entryData[num].tag) {
			activeIndex = this.state.activeKeyInEditIndex
		}

  	console.log(num,this.state.entryData[num]) 
  // 	console.log(word, num)
  	this.setState({
			// nounvalue1: nounvalue1def,
			// nounvalue2: nounvalue2def,
			// value1: value1def,
			// value2: value2def,
			verbMood: 'Indicative',
			nounMood: 'Absolutive',
			currentPostbases: [],
			primaryVerb: "",
			primaryVerbBase: "",
			englishPreSubject: [],
			englishPreNoun: [],
			englishPreVerb: [],
			primaryNoun: '',
			englishPreNounOptions:'',
			postbase1: -1,
			postbase2: -1,
      usageDefinition: this.state.entryData[num].usageDefinition,	
      baseUsageWord: this.state.entryData[num].usageWord,	
      underlyingForms: this.state.entryData[num].underlyingForms,
      dialect: this.state.entryData[num].dialect,
      baseCase: this.state.entryData[num].allBases[activeIndex],
      questionWord:'',
      allBases: this.state.entryData[num].allBases,	
      entryUrl: this.state.entryData[num].url,	
      tag: this.state.entryData[num].tag,	
      sisters: this.state.entryData[num].sisters,
      usageVerbTenses: this.state.entryData[num].usageVerbTenses,
      num: num,
  	},()=>{
  		this.defaultFstCall(this.state.entryData[num].tag,this.state.entryData[num].usageDefinition)
  	});

  	// console.log(this.state.entryData[num].allBases)
	        
	    
        // }   

        // this.setState({
        //   entryData: response.data,
        // });
        // console.log(tag)
        
        // if (tag == 'NOUN') {
        // 	this.setNoun(this,true)
        // } else if (tag == '(i) INTRANSITIVE VERB') {
        // 	this.setIntransitive(this,true)
        // }
        
      // });
  // }

	}

	setEnglishNoun(currentPostbases,value1,value2,nounMood) {
		let subjectis = ''
		let englishPreNoun = []
		let englishPreNounOptions = ''
		// console.log(currentPostbases, nounMood)

		let personN = value1[1]
		let peopleN = value1[2]
		if (currentPostbases.length > 0) {
			if (!nvNoIs.includes(currentPostbases[0])) {
				subjectis = this.getIs(personN, peopleN, false) 
			}

			if (peopleN === '1' && personN === '3') {
				currentPostbases.map((i,index)=>{
					englishPreNoun.push(this.state.postbaseMaster[i].englishModifier(""))
				})
			} else {
				currentPostbases.map((i,index)=>{
					englishPreNoun.push(this.state.postbaseMaster[i].englishModifierPlural(""))
				})				
			}

		}
		

		// currentPostbases.map((i,index)=>{
		// 	englishPreNoun.push(this.state.postbaseMaster[i].englishModifier(""))
		// })

		if (nounMood === 'Localis') {
			englishPreNounOptions = getNounInfo[0]['eng']
		} else if (nounMood === 'Terminalis') {
			englishPreNounOptions = getNounInfo[1]['eng']
		} else if (nounMood === 'Ablative') {
			englishPreNounOptions = getNounInfo[2]['eng']
		} else if (nounMood === 'Similaris') {
			englishPreNounOptions = getNounInfo[3]['eng']
		} else if (nounMood === 'Relative') {
			englishPreNounOptions = getNounInfo[4]['eng']
		} else if (nounMood === 'Modalis') {
			englishPreNounOptions = getNounInfo[5]['eng']
		} else if (nounMood === 'Vialis') {
			englishPreNounOptions = getNounInfo[6]['eng']
		}

		// console.log(englishPreVerb)
		// this.getSubjectIs(value1[1],value1[2])
		this.setState({
			englishPreNoun: englishPreNoun,
			englishPreNounOptions: englishPreNounOptions,
			subjectIs: subjectis,
		});	
		// this.getSubjectIs(value1[1],value1[2])
	}

	setEnglish(currentPostbases,value1,value2,verbMood,moodSpecific) {
		let englishPreVerb = []
		let englishPreSubject = []
		let primaryVerb = ''
		let subjectis = ''
		let beforeSubject = ''
		let bePreVerb = ''
		let personN = value1[1]
		let peopleN = value1[2]
		let questionWord = ''
		let questionWordEnglish = ''
		let capitalize = true

		// console.log(currentPostbases,value1,value2,verbMood,moodSpecific,this.state.primaryVerbBase,this.state.usageVerbTenses)
		let i = -1
		let j = -1
		if (currentPostbases.length == 1) {
			i = currentPostbases[0]
		} else if (currentPostbases.length == 2) {
			i = currentPostbases[0]
			j = currentPostbases[1]
		}


		if (currentPostbases.length == 0 && this.state.properties.includes('momentary base') && verbMood !== 'Interrogative') {
			verbMood = 'Participial'
		}
// let pastwas = [8,9]
// let pastdid = [4] // becomes inf
// let pastVerbTense = [3,5,12]
// let pastGerund = [7,10]

		if (verbMood == 'Participial') {
			if (this.state.primaryVerbBase.length === 0) { //be condition
				if (currentPostbases.length === 0) {
					subjectis = 'was '
				} else {
					if (pastwas.includes(i)) {
						subjectis = 'was '
					}
					englishPreVerb.push(this.state.postbaseMaster[i].englishModifierPast(""))
					if (befirst.includes(i)) {
						bePreVerb = 'be'
					} else if (beingfirst.includes(i)) {
						bePreVerb = 'being'
					}
				}
			} else {
				if (currentPostbases.length === 0) {
					primaryVerb=this.state.usageVerbTenses[1]
				} else {
					if (pastdid.includes(i)) {
						subjectis = 'did '
					} else if (pastwas.includes(i)) {
						subjectis = 'was '
					}
					englishPreVerb.push(this.state.postbaseMaster[i].englishModifierPast(""))
					if (pastVerbTense.includes(i)) {
						primaryVerb = this.state.usageVerbTenses[1]
					} else if (pastGerund.includes(i)) {
						primaryVerb = this.state.usageVerbTenses[3]
					} else {
						primaryVerb = this.state.usageVerbTenses[0]
					}
				}
			}
		} else if (verbMood == 'Interrogative') {
			if (moodSpecific.length !== 0) {
				// console.log(moodSpecific)
				questionWord = getVerbInfo[moodSpecific]['inup']
				questionWordEnglish = getVerbInfo[moodSpecific]['eng']
				capitalize = false
			}
			if (this.state.primaryVerbBase.length === 0) { //be condition
				primaryVerb = this.state.primaryVerbBase
				if (currentPostbases.length === 0) {
					if (currentPostbases.length == 0 && this.state.properties.includes('momentary base')) {
						beforeSubject = 'did'
						primaryVerb = this.state.usageVerbTenses[0]
					} else {
				  	beforeSubject = this.getIs(personN, peopleN, capitalize) 						
					}
				} else {
					if (notis.includes(i)) {
						if (doesis.includes(i)) {
							beforeSubject = this.getDo(personN,peopleN,capitalize)
						englishPreVerb.push(this.state.postbaseMaster[i].englishModifierInfinitive(""))
						} else {
							beforeSubject = ''
						englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
						}
					} else {
					  beforeSubject = this.getIs(personN, peopleN, capitalize)  	
						englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
					}
					if (befirst.includes(i)) {
						bePreVerb = 'be'
					} else if (beingfirst.includes(i)) {
						bePreVerb = 'being'
					}
				}
			} else {
				if (currentPostbases.length === 0) {
					if (currentPostbases.length == 0 && this.state.properties.includes('momentary base')) {
						beforeSubject = 'did '
						primaryVerb = this.state.usageVerbTenses[0]
					} else {
				  	beforeSubject = this.getIs(personN, peopleN, capitalize) 						
				  	primaryVerb = this.state.primaryVerbBase
					}
				} else {
					if (notis.includes(i)) {
						if (doesis.includes(i)) {
						  beforeSubject = this.getDo(personN,peopleN,capitalize)
							englishPreVerb.push(this.state.postbaseMaster[i].englishModifierInfinitive(""))
						} else {
							beforeSubject = ''
							englishPreSubject.push(this.state.postbaseMaster[i].englishModifierPast(""))
						}
					} else {
					  beforeSubject = this.getIs(personN, peopleN, capitalize) 
						englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
					}
					if (nextinfinitive.includes(i)) {
						primaryVerb = this.state.usageVerbTenses[0]
					} else {
						primaryVerb = this.state.usageVerbTenses[3]
					}
				}
			}
		} else{

			if (notis.includes(i) || future.includes(j)) {
				subjectis = ''
			} else {
				subjectis = this.getIs(personN, peopleN, false) 	
			}

			if (this.state.primaryVerbBase.length === 0) { //be condition

				if (currentPostbases.length === 0) {
					// this.setState({primaryVerb: this.state.primaryVerbBase});	

				} else if (currentPostbases.length === 1) { 
					
					englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
					if (befirst.includes(i)) {
						bePreVerb = 'be'
					} else if (beingfirst.includes(i)) {
						bePreVerb = 'being'
					}

				} else { // two postbases
					englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
					if (nextinfinitive.includes(i)) {
						englishPreVerb.push(this.state.postbaseMaster[j].englishModifierInfinitive(""))
					} else {
						englishPreVerb.push(this.state.postbaseMaster[j].englishModifierGerund(""))
					}

					if (matchCase.includes(j)) {
						if (befirst.includes(i)) {
							bePreVerb = 'be'
						} else if (beingfirst.includes(i)) {
							bePreVerb = 'being'
						}
					} else if (befirst.includes(j)) {
						bePreVerb = 'be'
					} else if (beingfirst.includes(j)) {
						bePreVerb = 'being'
					}
				}
			} else {
				if (currentPostbases.length === 0) {
					primaryVerb=this.state.primaryVerbBase
				} else if (currentPostbases.length === 1) { 
					// console.log('whatup')
					if (doesis.includes(i)) {
						if (peopleN === '1' && personN === '3') {
							englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))						
					  } else {
							englishPreVerb.push(this.state.postbaseMaster[i].englishModifierInfinitive(""))
					  }	
					} else {
						englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))						
					}
					if (nextinfinitive.includes(i)) { 
						primaryVerb=this.state.usageVerbTenses[0]
					} else {
						primaryVerb=this.state.usageVerbTenses[3]
					}
				} else { // two postbases
					englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
					// console.log(i,j)
					if (matchCase.includes(j)) {
						if (nextinfinitive.includes(i)) {
							englishPreVerb.push(this.state.postbaseMaster[j].englishModifierInfinitive(""))
							primaryVerb=this.state.usageVerbTenses[0]
						} else {
							englishPreVerb.push(this.state.postbaseMaster[j].englishModifierGerund(""))
							primaryVerb=this.state.usageVerbTenses[3]
						}
					} else {
						if (nextinfinitive.includes(i)) {
							englishPreVerb.push(this.state.postbaseMaster[j].englishModifierInfinitive(""))
						} else {
							englishPreVerb.push(this.state.postbaseMaster[j].englishModifierGerund(""))
						}						
						if (nextinfinitive.includes(j)) {
							primaryVerb=this.state.usageVerbTenses[0]
						} else {
							primaryVerb=this.state.usageVerbTenses[3]
						}
					}
				}
			}
		}

		// currentPostbases.map((i,index)=>{
		// 	if (index !== currentPostbases.length-1) {
		// 		// console.log(notis.includes(i), value1[1], value1[2])
		// 		if (notis.includes(i)) {
		// 			if (value1[1] === '3' && value1[2] === '1') {
		// 				englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))
		// 			} else {
		// 				englishPreVerb.push(this.state.postbaseMaster[i].englishModifierSecond(""))
		// 			}
		// 		} else {
		// 			englishPreVerb.push(this.state.postbaseMaster[i].englishModifierSecond(""))
		// 		}
		// 	} else {
		// 		englishPreVerb.push(this.state.postbaseMaster[i].englishModifierSecond(""))
		// 		if (infinitive.includes(i)) {
		// 			this.setState({primaryVerb: this.state.usageVerbTenses[0],});	
		// 		}
		// 	}
		// })
		// console.log(englishPreVerb)
		// this.getSubjectIs(value1[1],value1[2])

		if (verbMood == 'Interrogative') {
			this.setState({questionMark: '?'})
		} else {
			this.setState({questionMark: ''})
		}

		// console.log(englishPreVerb)
		this.setState({
			primaryVerb: primaryVerb,
			englishPreVerb: englishPreVerb,
			englishPreSubject: englishPreSubject,
			bePreVerb: bePreVerb,
      person: parseInt(personN),
      people: parseInt(peopleN),
      subjectIs: subjectis,
      beforeSubject: beforeSubject,
      questionWord: questionWord,
      questionWordEnglish: questionWordEnglish,
		});	
	}

	// addNounPostbase(e, {value}) {
	// 	// console.log(e, value)
	// 	let currentPostbases = this.state.currentPostbases
	// 	let postbasesAvailable = this.state.postbaseMaster[value].allowable_next_ids
	// 	// var remove = postbasesAvailable.indexOf(value)
	// 	// postbasesAvailable.splice(remove,1)
	// 	// console.log(postbasesAvailable)
	// 	let postbaseInformationAvailable = []
	// 	postbasesAvailable.map((a)=>{
	// 		postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifier("")})
	// 	})
	// 	currentPostbases.push(value)
	// 	this.setState({
	// 		currentPostbases: currentPostbases,
	// 		postbasesAvailable: postbasesAvailable,
	// 		postbaseInformationAvailable: postbaseInformationAvailable,
	// 	});

	// 	// let fstCall = ''
	// 	let owner = ''
	// 	let nounvalue1 = this.state.nounvalue1
	// 	let nounvalue2 = this.state.nounvalue2

	// 	if (nounvalue2[0] !== '0') {
	// 		owner = '+'+nounvalue2[0]+peopleDict[nounvalue2[1]]
	// 	} else {
	// 		owner = ''
	// 	}

	// 	// if (currentPostbases.includes(17)) {
	// 	// 	this.setState({tag: '(i) INTRANSITIVE VERB'})	
 //  //   	fstCall = '>+V+Ind+Prs+' + this.state.value1[1] + peopleDict[this.state.value1[2]]
	// 	// } else {
	// 	// 	this.setState({tag: 'NOUN'})	
	//  //    if (this.state.nounMood === 'Absolutive') {
	//  //    	fstCall = '>+N+Abs+' + peopleDict[nounvalue1] + owner
	//  //    }
	// 	// }

	// 	this.setEnglishNoun(currentPostbases,this.state.value1,this.state.value2)
	// 	this.getFSTParse(this.state.baseCase,currentPostbases,this.state.tag,this.state.verbMood,null,null,this.state.nounMood,nounvalue1,nounvalue2)
	// }

	addPostbase(e, {value}) {
		// console.log(e, value)

		let currentPostbases = this.state.currentPostbases
		// let postbasesAvailable = this.state.postbaseMaster[value].allowable_next_ids
		// var remove = postbasesAvailable.indexOf(value)
		// postbasesAvailable.splice(remove,1)
		// console.log(postbasesAvailable)
		if (this.state.baseTag == 'NOUN') {
			if (nnpostbases.includes(value)) {
				currentPostbases.push(value)
			} else {
				currentPostbases.unshift(value)
			}
		} else {
			currentPostbases.push(value)
		}

		// let postbaseInformationAvailable = []

		


		// let fstCall = this.state.fstCall
		// let owner = ''
		// let nounvalue1 = this.state.nounvalue1
		// let nounvalue2 = this.state.nounvalue2
		let tag = this.state.tag
		let verbMood = this.state.verbMood
		if (this.state.baseTag == 'NOUN') {

			// postbasesAvailable.map((a)=>{
			// 	postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifier("")})
			// })
			// if (nounvalue2[0] !== '0') {
			// 	owner = '+'+nounvalue2[0]+peopleDict[nounvalue2[1]]
			// } else {
			// 	owner = ''
			// }
			// console.log(currentPostbases,nounToVerb)
			// console.log(currentPostbases.filter(value => nounToVerb.includes(value)).)
			if (currentPostbases.filter(value => nounToVerb.includes(value)).length > 0) {
				tag = '(i) INTRANSITIVE VERB'
				verbMood = 'Indicative'
				// this.getFSTParse(this.state.baseCase,this.state.currentPostbases,'(i) INTRANSITIVE VERB','INDICATIVE',this.state.value1,'',this.state.nounMood,[],[])
			}
				// this.setState({tag: '(i) INTRANSITIVE VERB'})	
	    	// fstCall = '>+V+Ind+Prs+' + this.state.value1[1] + peopleDict[this.state.value1[2]]
			// } else {
				// this.setState({tag: 'NOUN'})	
		    // if (this.state.nounMood === 'Absolutive') {
		    	// fstCall = '>+N+Abs+' + peopleDict[nounvalue1] + owner
		    // }
			// }
			this.setEnglishNoun(currentPostbases,this.state.value1,this.state.value2,this.state.nounMood)

		} else {
			// postbasesAvailable.map((a)=>{
			// 	postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifier("")})
			// })
			this.setEnglish(currentPostbases,this.state.value1,this.state.value2,this.state.verbMood,this.state.moodSpecific)			
		}

		this.setAvailablePostbaseInformation(this.state.value1,currentPostbases,this.state.verbMood,this.state.nounMood)

		// console.log(tag,verbMood)

		this.getFSTParse(this.state.baseCase,currentPostbases,tag,verbMood,this.state.moodSpecific,this.state.value1,this.state.value2,this.state.nounMood,this.state.nounvalue1,this.state.nounvalue2)

		let postbase1 = -1
		let postbase2 = -1
		if (currentPostbases.length == 2) {
			postbase1 = currentPostbases[0]
			postbase2 = currentPostbases[1]
		} else if (currentPostbases.length == 1) {
			postbase1 = currentPostbases[0]
		}

		this.setState({
			postbase1:postbase1,
			postbase2:postbase2,
			currentPostbases: currentPostbases,
			tag: tag,
			verbMood: this.state.verbMood,

			// postbasesAvailable: postbasesAvailable,
			// postbaseInformationAvailable: postbaseInformationAvailable,
		});
	}


	removePostbase(value, e, data) {
		// console.log(value)
		let currentPostbases = this.state.currentPostbases
		// var remove = currentPostbases.indexOf(value)
		currentPostbases.splice(value,1)
		// console.log(postbasesAvailable)

		// let postbasesAvailable = []
		// if (currentPostbases.length == 1) {
		// 	postbasesAvailable = this.state.postbaseMaster[currentPostbases[0]].allowable_next_ids
		// } else {
		// 	if (this.state.verbMood == 'Participial') {
		// 		postbasesAvailable = verbPostbaseParticipialDefault
		// 	} else {
		// 		postbasesAvailable = this.state.allPostbasesAvailable
		// 	}
			
		// }
		// let postbaseInformationAvailable = []
		// postbasesAvailable.map((a)=>{
		// 	postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].description})
		// })

		this.setAvailablePostbaseInformation(this.state.value1,currentPostbases,this.state.verbMood,this.state.nounMood)


		let postbase1 = -1
		let postbase2 = -1
		if (currentPostbases.length == 2) {
			postbase1 = currentPostbases[0]
			postbase2 = currentPostbases[1]
		} else if (currentPostbases.length == 1) {
			postbase1 = currentPostbases[0]
		}

		this.setState({
			postbase1:postbase1,
			postbase2:postbase2,
			currentPostbases: currentPostbases,
			// postbasesAvailable: postbasesAvailable,
			// postbaseInformationAvailable: postbaseInformationAvailable,
		});


		let tag = this.state.tag
		let verbMood = this.state.verbMood

		// let fstCall = this.state.fstCall
		let owner = ''
		let nounvalue1 = this.state.nounvalue1
		let nounvalue2 = this.state.nounvalue2

		if (this.state.baseTag == 'NOUN') {

			// postbasesAvailable.map((a)=>{
			// 	postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifier("")})
			// })
			// if (nounvalue2[0] !== '0') {
			// 	owner = '+'+nounvalue2[0]+peopleDict[nounvalue2[1]]
			// } else {
			// 	owner = ''
			// }
			// if (currentPostbases.includes(17)) {
			// 	this.setState({tag: '(i) INTRANSITIVE VERB'})	
	  //   	fstCall = '>+V+Ind+Prs+' + this.state.value1[1] + peopleDict[this.state.value1[2]]
			// } else {
			// 	this.setState({tag: 'NOUN'})	
		 //    if (this.state.nounMood === 'Absolutive') {
		 //    	fstCall = '>+N+Abs+' + peopleDict[nounvalue1] + owner
		 //    }
			// }
			// console.log(currentPostbases)
			// console.log(currentPostbases.filter(value => nounToVerb.includes(value)))
			if (currentPostbases.filter(value => nounToVerb.includes(value)).length > 0) {
				tag = '(i) INTRANSITIVE VERB'
				verbMood = 'Indicative'
				// this.getFSTParse(this.state.baseCase,this.state.currentPostbases,'(i) INTRANSITIVE VERB','INDICATIVE',this.state.value1,'',this.state.nounMood,[],[])
			} else {
				tag = 'NOUN'
			}
			this.setState({tag:tag})
			console.log(tag)

			this.setEnglishNoun(currentPostbases,this.state.value1,this.state.value2,this.state.nounMood)

		} else {
			// postbasesAvailable.map((a)=>{
			// 	postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifierSecond("")})
			// })
			this.setEnglish(currentPostbases,this.state.value1,this.state.value2,this.state.verbMood,this.state.moodSpecific)			
		}

		

		this.getFSTParse(this.state.baseCase,currentPostbases,tag,this.state.verbMood,this.state.moodSpecific,this.state.value1,this.state.value2,this.state.nounMood,this.state.nounvalue1,this.state.nounvalue2)

	}


	setAvailablePostbaseInformation(value1, currentPostbases,verbMood,nounMood) {
		// console.log('yoyo')
		let availablePostbaseInformationText = []
		let postbaseInformationAvailable1 = []
		let postbaseInformationAvailable2 = []

		// if (this.state.currentPostbases.length == 0 && this.state.verbMood == 'Participial') {
		// 	postbasesAvailable = verbPostbaseParticipialDefault
		// }

		// console.log(currentPostbases)


		let postbasesAvailable = []
		if (currentPostbases.length == 0) {
			if (verbMood == 'Participial') {
				postbasesAvailable = verbPostbaseParticipialDefault
			} else if (this.state.baseTag == 'NOUN' && nounMood !== 'Absolutive') {
				postbasesAvailable = nnpostbases
			} else {
				postbasesAvailable = this.state.allPostbasesAvailable
			}
		} else {
			if (this.state.baseTag == 'NOUN' && nounMood !== 'Absolutive') {

			} else {
				postbasesAvailable = this.state.postbaseMaster[currentPostbases[0]].allowable_next_ids
			}
			
		}

		// console.log(mood)

		let hasFuture = false
		if (this.state.baseTag == 'NOUN') {
			if (this.state.currentPostbases.length == 0) {
				postbasesAvailable.map((a)=>{
					postbaseInformationAvailable1.push({value:a,key:a,text:this.state.postbaseMaster[a].description})
					availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifier(""))
				})
			} else if (this.state.currentPostbases.length == 1) {
				// console.log('hi',postbasesAvailable)
				postbaseInformationAvailable1 = this.state.postbaseInformationAvailable1
				postbasesAvailable.map((a)=>{
					postbaseInformationAvailable2.push({value:a,key:a,text:this.state.postbaseMaster[a].description})
					availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifier(""))
				})		
			}
		} else {
			if (verbMood == 'Participial') {
				postbasesAvailable.map((a)=>{
					postbaseInformationAvailable1.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifierPast("")})
					availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifierPast(""))
				})
			} else if (verbMood == 'Interrogative') {
				postbasesAvailable.map((a)=>{
					postbaseInformationAvailable1.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifierInfinitive("")})
					availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifierInfinitive(""))
				})
			} else {
				if (this.state.currentPostbases.length == 0) {
					postbasesAvailable.map((a)=>{
						if (doesis.includes(a) && (!(value1[2] === '1' && value1[1] === '3'))) {
							// if (value1[2] === '1' && value1[1] === '3') {
								// englishPreVerb.push(this.state.postbaseMaster[i].englishModifier(""))						
						  // } else {
							postbaseInformationAvailable1.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifierInfinitive("")})
							availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifierInfinitive(""))
								// englishPreVerb.push(this.state.postbaseMaster[i].englishModifierInfinitive(""))
						  // }	
						} else {
							postbaseInformationAvailable1.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifier("")})
							availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifier(""))
						}
					})
				} else if (this.state.currentPostbases.length == 1) {
					postbaseInformationAvailable1 = this.state.postbaseInformationAvailable1
					if (future.includes(this.state.currentPostbases[0])) {
						hasFuture = true
					}
					// console.log('wadup')
					if (nextinfinitive.includes(this.state.currentPostbases[0])) {
						postbasesAvailable.map((a)=>{
							postbaseInformationAvailable2.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifierInfinitive("")})
							availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifierInfinitive(""))
						})		
					}	else {
						postbasesAvailable.map((a)=>{
							postbaseInformationAvailable2.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifierGerund("")})
							availablePostbaseInformationText.push(this.state.postbaseMaster[a].englishModifierGerund(""))
						})		
					}		
				}
			}
		}


		// postbasesAvailable.map((a)=>{
		// 	postbaseInformationAvailable.push({value:a,key:a,text:this.state.postbaseMaster[a].englishModifier("")})
		// })		

		this.setState({
			// currentPostbases: currentPostbases,
			postbasesAvailable: postbasesAvailable,
			hasFuture: hasFuture,
			postbaseInformationAvailable1: postbaseInformationAvailable1,
			postbaseInformationAvailable2: postbaseInformationAvailable2,
			availablePostbaseInformationText: availablePostbaseInformationText,
		});

	}


	changeVerbEnding(reset,e, data) {
		// console.log(data.value)
		// let mood = 'Interrogative'
		// let moodSpecific = data.value
		this.setState({endingNumber:data.value});	
		if (reset) {
			this.changeEnding('Indicative','','')
		} else {
			if (data.value === 0) {
				this.changeEnding('Participial','','')
			} else {
				this.changeEnding('Interrogative',data.value,'')
			}		
		}
		
	}

	changeNounEnding(reset,e, data) {
		console.log(data.value)
		// let mood = 'Interrogative'
		// let moodSpecific = data.value
		this.setState({endingNumber:data.value});	
		if (reset) {
			this.changeEnding('','','Absolutive')
		} else {
			this.changeEnding('','',getNounInfo[data.value]['text'])
		}
		
	}

	isBaseCaseAudio(verbMood,nounMood,value1,value2,nounvalue1,nounvalue2) {


		// console.log(verbMood,nounMood,value1.slice(0,3),value2.slice(0,3),nounvalue1,nounvalue2,this.state.baseTag)

		// console.log(this.state.baseTag == 'NOUN')
		// console.log(value1.slice(0,3) == "s31" && value2.slice(0,3) == "o31" && verbMood == 'Indicative')
		if (this.state.baseTag == 'NOUN') {
	  	if (nounvalue1 == "1" && nounvalue2 == "00(3)" && nounMood == 'Absolutive') {
	  		this.setState({notEdited:true})
	  	} else {
	  		this.setState({notEdited:false})  		
	  	}
		} else {

	  	if (value1.slice(0,3) == "s31" && value2.slice(0,3) == "o31" && verbMood == 'Indicative') {
	  		this.setState({notEdited:true})
	  	} else {
	  		this.setState({notEdited:false})  		
	  	}

		}


	}

	changeEnding(verbMood,moodSpecific,nounMood,value1,value2) {

		console.log()
		let postbasesAvailable = []


		this.isBaseCaseAudio(verbMood,nounMood,this.state.value1,this.state.value2,this.state.nounvalue1,this.state.nounvalue2)

		this.setAvailablePostbaseInformation(this.state.value1,this.state.currentPostbases,verbMood,nounMood)

		if (this.state.tag === 'NOUN') {
			// if (mood === this.state.nounMood) {	
			// 	mood = 'Absolutive'
			// 	moodSpecific = ''
			// 	// nounMood = 
			// }
    	this.setEnglishNoun(this.state.currentPostbases,this.state.value1,this.state.value2,nounMood)
    	this.setState({nounMood:nounMood});	
    	this.setState({moodSpecific:moodSpecific});	

		} else {
			if (verbMood === this.state.verbMood && moodSpecific === this.state.moodSpecific) {
				// verbMood = 'Indicative'
				verbMood = 'Indicative'
				moodSpecific = ''
			} 

	    this.setEnglish(this.state.currentPostbases,this.state.value1,this.state.value2,verbMood,moodSpecific)
    	this.setState({verbMood:verbMood});	
    	this.setState({moodSpecific:moodSpecific});	
		}

    this.getFSTParse(this.state.baseCase,this.state.currentPostbases,this.state.tag,verbMood,moodSpecific,this.state.value1,this.state.value2,nounMood,this.state.nounvalue1,this.state.nounvalue2)


		
	}

  changeActiveUsageKey(entry, data) {
  	console.log(data, this.state.allBases[data.value])
  	let num = data.value
  	// console.log(data.options)
    this.setState({
      // value1: data.value,
      activeKeyInEditIndex:data.value,
      baseCase: this.state.allBases[data.value],
      // notEdited:false,
    });
    this.getFSTParse(this.state.allBases[data.value],this.state.currentPostbases,this.state.tag,this.state.verbMood,this.state.moodSpecific,this.state.value1,this.state.value2,this.state.nounMood,this.state.nounvalue1,this.state.nounvalue2)
  }

  getFSTParse(baseCase,currentPostbases,tag,verbMood,moodSpecific,value1,value2,nounMood,nounvalue1,nounvalue2) {
  	// console.log(baseCase,currentPostbases,tag,verbMood,value1,value2,nounMood,nounvalue1,nounvalue2)
  	// console.log(nounMood,tag,value1,value2)
  	let fstCall = ''
  	let owner = ''
  	if (tag == 'NOUN') {
			if (nounvalue2[0] !== '0') {
				owner = '+'+nounvalue2[0]+peopleDict[nounvalue2[1]]
			} else {
				owner = ''
			}
  		if (nounMood == 'Absolutive') {
			  fstCall = '>+N+Abs+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Localis') {
  			fstCall = '>+N+Loc+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Terminalis') {
  			fstCall = '>+N+Trm+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Ablative') {
  			fstCall = '>+N+Abl+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Similaris') {
  			fstCall = '>+N+Sim+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Relative') {
  			fstCall = '>+N+Rel+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Modalis') {
  			fstCall = '>+N+Mod+' + peopleDict[nounvalue1] + owner
  		} else if (nounMood == 'Vialis') {
  			fstCall = '>+N+Via+' + peopleDict[nounvalue1] + owner
  		}
  	} else {
	  	if (verbMood == 'Indicative') {
	  		if (tag == '(i) INTRANSITIVE VERB') {
	  			fstCall = '>+V+Ind+Prs+' + value1[1] + peopleDict[value1[2]]
	  		} else {
	  			fstCall = '>+V+Ind+Prs+' + value1[1] + peopleDict[value1[2]] + '+' + value2[1]+peopleDict[value2[2]] + 'O'
	  		}	
	  	} else if (verbMood == 'Participial') {
	  		if (tag == '(i) INTRANSITIVE VERB') {
	  			fstCall = '>+V+Ind+Pst+' + value1[1] + peopleDict[value1[2]]
	  		} else {
	  			fstCall = '>+V+Ind+Pst+' + value1[1] + peopleDict[value1[2]] + '+' + value2[1]+peopleDict[value2[2]] + 'O'
	  		}	
	  	} else if (verbMood == 'Interrogative') {
	  		if (tag == '(i) INTRANSITIVE VERB') {
	  			fstCall = '>+V+Int+' + value1[1] + peopleDict[value1[2]]
	  		} else {
	  			fstCall = '>+V+Int+' + value1[1] + peopleDict[value1[2]] + '+' + value2[1]+peopleDict[value2[2]] + 'O'
	  		}	
	  	}
  	}
  	
    fstCall = fstCall.replace('4','3R')

  	let underlyingCall = []

		let postfstCall = []
		let postfstCallString = ''
		if (currentPostbases.length > 0) {
			currentPostbases.map((i)=>{
				postfstCall.push(this.state.postbaseMaster[i].expression)
			})
			postfstCall = postfstCall.reverse()
			postfstCallString = '>'+postfstCall.join('>')
			
		}

    // if (this.state.properties.includes('dispensable noun') && nounvalue2[0] !== '0') {
    // 	postfstCallString = '>un' + postfstCallString
    // }

    if ((this.state.properties.includes('dispensable noun -- food') || this.state.properties.includes('dispensable noun -- kin')) && nounvalue2[0] !== '0' && tag == 'NOUN') {
    	postfstCallString =  postfstCallString + '>un'
    	this.setState({utiAdded:true})
    	if (this.state.properties.includes('dispensable noun -- food')) {
    		this.setState({ displayDispense: true })	
    	} else {
    		this.setState({ displayDispense: false })	    		
    	}
    } else {
    	this.setState({ 
    		utiAdded:false,
    		displayDispense: false 
    	})	    		
    }

		// fstCall = postfstCall+fstCall
		// console.log(underlyingCall,postfstCall)
		underlyingCall = underlyingCall.concat(postfstCall)
		underlyingCall.push(fstCall.slice(1))
		// console.log(underlyingCall)


		
  	let FSTsearch = baseCase[1] + postfstCallString + fstCall
  	// console.log(baseCase,postfstCallString,fstCall)
  	// console.log(FSTsearch)
  	// console.log(underlyingCall)
		// console.log(FSTsearch,activeEditIndex)
		let underlyingCallReturn = []
		underlyingCall.map((j)=>{
			underlyingCallReturn.push(postbasesEndingsUnderlying[j])
		})
    this.setState({
      underlyingCallReturn: underlyingCallReturn,
    });


		// console.log(FSTsearch)
    axios
      .get(API_URL + "/inupiaqsegment/" + encodeURIComponent(FSTsearch))
      .then(response => {
        // console.log(response.data);

        // var slicedColors = this.state.colorsList
        // slicedColors=slicedColors.slice(0,this.state.currentPostbases.length)
        let words1 = []
        let words2 = []
        response.data.words[0].map((k,ind)=>{
        	words1.push(k.replace("ia>ŋi","iaŋi"))
        })
        response.data.words[1].map((k,ind)=>{
        	words2.push(k.replace("ia>ŋi","iaŋi"))
        })
        this.setState({
        	// englishColorList: slicedColors,
        	// inupiaqColorList: ['#000000'].concat(slicedColors.slice(0).reverse()).concat(['#852828']),
          entryModified: words1,
          entryModifiedAlt: [words1,words2],
        });

        // if (response.data.chld !== []) {}
        // console.log(response.data.etymology)
        // if (response.data.etymology !== []) {
        // 	this.setState({etymology: response.data.etymology[0]})
        // }
      });
  }



	processStyledText = (sentence) => {			
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/{.*?}/g)
		if (matches !== null || matches1 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence.replace("<","").replace(">","")}</span>
		}
	}

	processStyledText2 = (sentence,tag) => {
		// console.log(sentence,tag)
		sentence = sentence.replace("⟨","").replace("⟩","")
		if (tag == 'NOUN') {
			sentence = 'the one '+sentence
		} else {
			sentence = 'he/she is '+sentence
		}
		
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/{.*?}/g)
		if (matches !== null || matches1 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))						
			}	
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence.replace("<","").replace(">","")}</span>
		}
	}

	processUnderlyingStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/\^.*?\^/g)
		if (matches !== null || matches1 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>['+m.slice(1,-1)+']</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}


	returnGeneralEnding = () => {
		let sub;
		if (this.state.tag !== 'NOUN') {
			sub = retrieveSubjectObject[this.state.value1]
			if (sub == 'he' || sub == 'she' || sub == 'it') {
				sub = 'she/he/it'
			}
			if (this.state.verbMood == 'Indicative') {
				if (this.state.tag == '(i) INTRANSITIVE VERB') {
					sub = sub + ' ' + this.state.subjectIs + ' (statement)'
				} else if (this.state.tag == '(t) TRANSITIVE VERB') {
					sub = sub + ' ' + this.state.subjectIs + ' (statement) → ' + retrieveSubjectObject[this.state.value2]
				}
			} else if (this.state.verbMood == 'Interrogative') {
				if (this.state.tag == '(i) INTRANSITIVE VERB') {
					sub = this.state.beforeSubject + ' ' + sub + ' (question?)'
				} else if (this.state.tag == '(t) TRANSITIVE VERB') {
					sub = this.state.beforeSubject + ' ' + sub + ' (question?) → ' + retrieveSubjectObject[this.state.value2]
				}
			} else if (this.state.verbMood == 'Participial') {
				if (this.state.tag == '(i) INTRANSITIVE VERB') {
					sub = sub + ' did (in the past)'
				} else if (this.state.tag == '(t) TRANSITIVE VERB') {
					sub = sub + ' did (in the past) to → ' + retrieveSubjectObject[this.state.value2]
				}
			}
		} else {
			sub = retrieveSubjectObject[this.state.nounvalue2]+' '+retrieveSubjectObject[this.state.nounvalue1]
		}

		return sub
	}

  repeatAudio(audio, event, data) {

    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replace('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }

	usageEntry = (ind,tag) => {
		console.log(ind,tag)
		let showAudio = this.state.audioMatrix[ind].length > 0 && this.state.notEdited && this.state.currentPostbases.length === 0 && this.state.activeKeyInEditIndex == 0
		if (tag === 'NOUN') {
			return (	
							<div>
							{!this.state.showAlt ?
								<div>
									<div style={{marginTop:'30px',marginBottom:'10px',fontSize:(window.innerWidth < 480 && this.state.currentPostbases.length > 0 ? '28px':'30px'),color:'#000000',fontWeight:'400'}}>
									{this.state.entryModified.map((modifiedword, m)=>
										<div style={{display:'flex',justifyContent:'center', lineHeight:'35px'}}>

										{showAudio ?
											<div style={{width:53}} />
											:
											null
										}	

											{this.state.allBases.length == 1 ?
												(modifiedword.split('>').map((q,index) =>
													<span style={{color:(index === 0 ? '#000000': (modifiedword.split(">").length-1 == index ? '#852828' : (this.state.utiAdded && modifiedword.split(">").length-2 == index ? '#852828' : this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]])))}}>{q}</span>
													))
												:
												<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{fontWeight:'400',color:(index === 0 ? '#000000': (modifiedword.split(">").length-1 == index ? '#852828' : (this.state.utiAdded && modifiedword.split(">").length-2 == index ? '#852828' : this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]])))}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
											}

										{showAudio ?
											<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',marginTop:'1px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
											:
											null
										}			

										</div>
										)}
									</div>



									<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',fontSize:'20px',marginBottom:'15px',fontWeight:'300'}}> 
									{this.state.questionWord.length > 0 ?
										<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>
											<Popup on='click' position='bottom' content={this.state.questionWordEnglish} trigger={<div style={{cursor:'pointer'}}>{this.state.questionWord}</div>} />
										</span>
										:
										null
									}

									<span style={{borderBottom:'2px solid black',margin:'10px',paddingBottom:'3px'}}>
										<Popup on='click' position='bottom' content={this.processStyledText(this.state.fullDefinition)} trigger={<div style={{cursor:'pointer',fontFamily:serifFont}}>{this.state.underlyingForms[this.state.activeKeyInEditIndex].length !== 0 ? this.processUnderlyingStyledText(this.state.underlyingForms[this.state.activeKeyInEditIndex].replace("⎤","").replace("⎡","")) : this.processUnderlyingStyledText(this.state.baseCase[0])}</div>} />
									</span>



								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										null
										:
											(this.state.currentPostbases.length > 0 ?
											<span style={{paddingBottom:'3px',fontFamily:serifFont,borderBottom:'2px solid '+(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]]),margin:'10px',color:(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]])}}>
											<Popup on='click' position='bottom' content={this.state.postbaseMaster[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]].clickDescription} trigger={<div style={{cursor:'pointer'}}>{x.join(', ')}</div>} />
											</span>										
											:
											null
											)
										)
								)}

								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										(this.state.utiAdded ?
											<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>										
											<Popup on='click' position='bottom' content={'supply of'} trigger={<div style={{cursor:'pointer'}}>{':uti-'}</div>} />
											</span>
											:
											null
										)
										:
										null
									)
								)}

								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										(x[0][0].length === 0 ?
											<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>										
											<Popup on='click' position='bottom' content={this.returnGeneralEnding()} trigger={<div style={{cursor:'pointer'}}>{'ø'}</div>} />
											</span>
											:
											<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>	
											<Popup on='click' position='bottom' content={this.returnGeneralEnding()} trigger={<div style={{cursor:'pointer'}}>{x[0].concat(x[1]).join(', ')}</div>} />
											</span>
										)
										:
										null
									)
								)}

									</div>
								</div>
								:
								<div>
								<div style={{marginTop:'30px',marginBottom:'10px',fontSize:(window.innerWidth < 480 && this.state.currentPostbases.length > 0 ? '28px':'30px'),color:'#000000',fontWeight:'400'}}>
								{this.state.entryModifiedAlt[0].map((modifiedword, m)=>
									<div style={{display:'flex',justifyContent:'center', lineHeight:'35px'}}>
										
										{showAudio ?
											<div style={{width:53}} />
											:
											null
										}	

										{this.state.allBases.length == 1 ?
											(modifiedword.split('>').map((q,index) =>
													<span style={{color:(index === 0 ? '#000000': (modifiedword.split(">").length-1 == index ? '#852828' : (this.state.utiAdded && modifiedword.split(">").length-2 == index ? '#852828' : this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]])))}}>{q}</span>
												))
											:
											<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{fontWeight:'400',color:(modifiedword.split(">").length-1 == index ?'#852828':'#000000')}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
										}

										{showAudio ?
											<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',marginTop:'1px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
											:
											null
										}			

									</div>
									)}
								{this.state.entryModifiedAlt[1].map((modifiedword, m)=>
									<div style={{display:'flex',justifyContent:'center', lineHeight:'35px'}}>
									<span style={{fontSize:'14px',color:'grey',paddingRight:'5px'}}>alt</span>
										{this.state.allBases.length == 1 ?
											(modifiedword.split('>').map((q,index) =>
													<span style={{color:(index === 0 ? '#000000': (modifiedword.split(">").length-1 == index ? '#852828' : (this.state.utiAdded && modifiedword.split(">").length-2 == index ? '#852828' : this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]])))}}>{q}</span>
												))
											:
											<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{fontWeight:'400',color:(modifiedword.split(">").length-1 == index ?'#852828':'#000000')}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
										}
									</div>
									)}
								</div>

								<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',fontSize:'20px',marginBottom:'15px',fontWeight:'300'}}> 
								{this.state.questionWord.length > 0 ?
									<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>
										<Popup on='click' position='bottom' content={this.state.questionWordEnglish} trigger={<div style={{cursor:'pointer'}}>{this.state.questionWord}</div>} />
									</span>
									:
									null
								}

								<span style={{borderBottom:'2px solid black',margin:'10px',paddingBottom:'3px'}}>

								<Popup on='click' position='bottom' content={this.processStyledText(this.state.fullDefinition)} trigger={<div style={{cursor:'pointer',fontFamily:serifFont}}>{this.state.underlyingForms[this.state.activeKeyInEditIndex].length !== 0 ? this.processUnderlyingStyledText(this.state.underlyingForms[this.state.activeKeyInEditIndex].replace("⎤","").replace("⎡","")) : this.state.baseCase[0]}</div>} />
									
								</span>



								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										null
										:
											(this.state.currentPostbases.length > 0  && x !== undefined ?
											<span style={{paddingBottom:'3px',fontFamily:serifFont,borderBottom:'2px solid '+(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]]),margin:'10px',color:(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]])}}>
											<Popup on='click' position='bottom' content={this.state.postbaseMaster[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]].clickDescription} trigger={<div style={{cursor:'pointer'}}>{x.join(', ')}</div>} />
											</span>										
											:
											null
											)
										)
								)}

								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										(this.state.utiAdded ?
											<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>										
											<Popup on='click' position='bottom' content={'supply of'} trigger={<div style={{cursor:'pointer'}}>{':uti-'}</div>} />
											</span>
											:
											null
										)
										:
										null
									)
								)}

								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										(x[0][0].length === 0 ?
											<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>										
											<Popup on='click' position='bottom' content={this.returnGeneralEnding()} trigger={<div style={{cursor:'pointer'}}>{'ø'}</div>} />
											</span>
											:
											<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>	
											<Popup on='click' position='bottom' content={this.returnGeneralEnding()} trigger={<div style={{cursor:'pointer'}}>{x[0].concat(x[1]).join(', ')}</div>} />
											</span>
										)
										:
										null
									)
								)}

								</div>

								</div>
							}


								<div style={{textAlign:'center',fontSize:'18px',color:'#0D0D0D',fontWeight:'300'}}>
								<span style={{color:'#852828'}}>{this.state.englishPreNounOptions}</span>
								{this.state.nounMood === 'Modalis' ?
								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setNoun.bind(this,false)} value={this.state.nounvalue2} options={nounoptionsmodalis} />
								:
								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setNoun.bind(this,false)} value={this.state.nounvalue2} options={nounoptions1} />
								}
								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setNoun.bind(this,false)} value={this.state.nounvalue1} options={this.state.displayDispense ? nounoptions3 : nounoptions2} />
								{this.state.englishPreNoun.map((w,wind)=>{
									return <span style={{color:this.state.colorsList[this.state.currentPostbases[wind]]}}>{w}</span>
								})}
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.usageDefinition)}</span>
								</div>
							</div>		
						)
		} else if (tag === '(i) INTRANSITIVE VERB') {
			return (	
							<div>
								<div style={{marginTop:'30px',marginBottom:'10px',fontSize:(window.innerWidth < 480 && this.state.currentPostbases.length > 0 ? '28px':'30px') ,color:'#000000',fontWeight:'400'}}>




								{this.state.entryModified.map((modifiedword, m)=>
									<div style={{display:'flex',justifyContent:'center', lineHeight:'35px'}}>



										{showAudio ?
											<div style={{width:50}} />
											:
											null
										}	



									<span style={{color:'#852828','paddingRight':'5px'}}>{this.state.questionWord}</span>
									{this.state.allBases.length == 1 ?
										(modifiedword.split('>').map((q,index) =>
											<span style={{color:(index === 0 ? '#000000': (modifiedword.split(">").length-1 == index ? '#852828' : this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]]))}}>{q}</span>
											))
										:
										<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{fontWeight:'400',color:(modifiedword.split(">").length-1 == index ?'#852828':this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]])}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
									}
									<span style={{color:'#852828'}}>{' '+this.state.questionMark}</span>									
										

										{showAudio ?
											<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',marginTop:'1px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
											:
											null
										}									


									</div>
									)}

	

								</div>

								<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',fontSize:'20px',marginBottom:'15px',fontWeight:'300'}}> 
								{this.state.questionWord.length > 0 ?
									<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>
										<Popup on='click' position='bottom' content={this.state.questionWordEnglish} trigger={<div style={{cursor:'pointer'}}>{this.state.questionWord}</div>} />
									</span>
									:
									null
								}

								<span style={{borderBottom:'2px solid black',margin:'10px',paddingBottom:'3px'}}>

								<Popup on='click' position='bottom' content={this.processStyledText(this.state.fullDefinition)} trigger={<div style={{cursor:'pointer',fontFamily:serifFont}}>{this.state.underlyingForms[this.state.activeKeyInEditIndex].length !== 0 ? this.processUnderlyingStyledText(this.state.underlyingForms[this.state.activeKeyInEditIndex].replace("⎤","").replace("⎡","") + (this.state.baseTag == 'NOUN' ? '' : '-')) : this.processUnderlyingStyledText(this.state.baseCase[0] + (this.state.baseTag == 'NOUN' ? '' : '-'))}</div>} />
									
								</span>


								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>
										<Popup on='click' position='bottom' content={this.returnGeneralEnding()} trigger={<div style={{cursor:'pointer'}}>{x[0].join(', ')}</div>} />
										</span>
										:
											(this.state.currentPostbases.length > 0 && x !== undefined ?
											<span style={{paddingBottom:'3px',fontFamily:serifFont,borderBottom:'2px solid '+(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]]),margin:'10px',color:(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]])}}>
											<Popup on='click' position='bottom' content={this.state.baseTag === "NOUN" ? this.state.postbaseMaster[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]].clickDescription : this.state.postbaseMaster[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]].description} trigger={<div style={{cursor:'pointer'}}>{x.join(', ')}</div>} />
											</span>										
											:
											null
											)
										)
								)}
								</div>

								<div style={{textAlign:'center',fontSize:'18px',color:'#0D0D0D',fontWeight:'300'}}>
								<span style={{color:'#852828'}}>{this.processStyledText(this.state.questionWordEnglish)}</span>
								{this.state.englishPreSubject.map((w,wind)=>{
									return <span style={{color:this.state.colorsList[this.state.currentPostbases[wind]]}}>{w}</span>
								})}
								<span style={{color:'#777777'}}>{this.state.beforeSubject}</span>
								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setIntransitive.bind(this,false)} value={this.state.value1} options={options1} />
								<span style={{color:'#777777'}}>{this.state.subjectIs}</span>
								{this.state.englishPreNoun.map((w,wind)=>{
									return <span style={{color:this.state.colorsList[this.state.currentPostbases[wind]]}}>{w}</span>
								})}
								{this.state.englishPreVerb.map((w,wind)=>{
									return <span style={{color:this.state.colorsList[this.state.currentPostbases[wind]]}}>{w}</span>
								})}
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.bePreVerb)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.leftOfVerb)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.primaryVerb)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.primaryNoun)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.rightOfVerb)}</span>
								<span style={{color:'#852828'}}>{' '+this.state.questionMark}</span>
								</div>
							</div>		
						)
		} else if (tag === '(t) TRANSITIVE VERB') {
			return (	
							<div>
								<div style={{marginTop:'30px',marginBottom:'10px',fontSize:(window.innerWidth < 480 && this.state.currentPostbases.length > 0 ? '28px':'30px'),color:'#000000',fontWeight:'400'}}>
								{this.state.entryModified.map((modifiedword, m)=>
									<div style={{display:'flex',justifyContent:'center', lineHeight:'35px'}}>

										{showAudio ?
											<div style={{width:50}} />
											:
											null
										}	

									<span style={{color:'#852828','paddingRight':'5px'}}>{this.state.questionWord}</span>
									{this.state.allBases.length == 1 ?
										(modifiedword.split('>').map((q,index) =>
											<span style={{color:(index === 0 ? '#000000': (modifiedword.split(">").length-1 == index ? '#852828' : this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]]))}}>{q}</span>
											))
										:
										<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{fontWeight:'400',color:(modifiedword.split(">").length-1 == index ?'#852828':this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-index]])}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
									}
									<span style={{color:'#852828'}}>{' '+this.state.questionMark}</span>

										{showAudio ?
											<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',marginTop:'1px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
											:
											null
										}			

									</div>
									)}
								</div>

								<div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',fontSize:'20px',marginBottom:'15px',fontWeight:'300'}}> 
								{this.state.questionWord.length > 0 ?
									<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>
										<Popup on='click' position='bottom' content={this.state.questionWordEnglish} trigger={<div style={{cursor:'pointer'}}>{this.state.questionWord}</div>} />
									</span>
									:
									null
								}
								<span style={{borderBottom:'2px solid black',margin:'10px',paddingBottom:'3px',fontFamily:serifFont}}>
								<Popup on='click' position='bottom' content={this.processStyledText(this.state.fullDefinition)} trigger={<div style={{cursor:'pointer',fontFamily:serifFont}}>{this.state.underlyingForms[this.state.activeKeyInEditIndex].length !== 0 ? this.processUnderlyingStyledText(this.state.underlyingForms[this.state.activeKeyInEditIndex].replace("⎤","").replace("⎡","") + (this.state.baseTag == 'NOUN' ? '' : '-')) : this.processUnderlyingStyledText(this.state.baseCase[0] + (this.state.baseTag == 'NOUN' ? '' : '-'))}</div>} />
								</span>
								{this.state.underlyingCallReturn.map((x,xind)=>
									(xind == this.state.underlyingCallReturn.length - 1 ?
										<span style={{paddingBottom:'3px',borderBottom:'2px solid #852828',margin:'10px',color:'#852828',fontFamily:serifFont}}>
										<Popup on='click' position='bottom' content={this.returnGeneralEnding()} trigger={<div style={{cursor:'pointer'}}>{x[0].join(', ')}</div>} />
										</span>
										:
										(this.state.currentPostbases.length > 0  && x !== undefined ?
											<span style={{paddingBottom:'3px',fontFamily:serifFont,borderBottom:'2px solid '+(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]]),margin:'10px',color:(this.state.colorsList[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]])}}>
											<Popup on='click' position='bottom' content={this.state.postbaseMaster[this.state.currentPostbases[this.state.currentPostbases.length-xind-1]].description} trigger={<div style={{cursor:'pointer'}}>{x.join(', ')}</div>} />
											</span>	
											:
											null
										)									
										)
								)}
								</div>
																<div style={{textAlign:'center',fontSize:'18px',color:'#0D0D0D',fontWeight:'300'}}>
								<span style={{color:'#852828'}}>{this.processStyledText(this.state.questionWordEnglish)}</span>
								{this.state.englishPreSubject.map((w,wind)=>{
									return <span style={{color:this.state.colorsList[this.state.currentPostbases[wind]]}}>{w}</span>
								})}
								<span style={{color:'#777777'}}>{this.state.beforeSubject}</span>
								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setTransitive.bind(this,false)} value={this.state.value1} options={this.state.options1} />
								<span style={{color:'#777777'}}>{this.state.subjectIs}</span>
								{this.state.englishPreVerb.map((w,wind)=>{
									return <span style={{color:this.state.colorsList[this.state.currentPostbases[wind]]}}>{w+" "}</span>
								})}
									<span style={{color:'#777777'}}>{this.processStyledText(this.state.bePreVerb)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.leftOfVerb)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.primaryVerb)}</span>
								<span style={{color:'#777777'}}>{this.processStyledText(this.state.rightOfVerb)}</span>
								<span style={{color:'#777777'}}>{this.state.transitiveLeftOfObject}</span>
								{this.state.objectPossessed ?
									<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setTransitive.bind(this,false)} value={this.state.value2} options={this.state.options3} />
									:
									<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setTransitive.bind(this,false)} value={this.state.value2} options={this.state.options2} />
								}
								<span style={{color:'#777777'}}>{this.state.transitiveRightOfObject}</span>
								<span style={{color:'#852828'}}>{' '+this.state.questionMark}</span>
								</div>
							</div>		
						)			
		}
	}


	backEndCall(keyChanged, eraseExisting, simpleCall) {
		// console.log('backend',this.state)
		console.log('backendcall',keyChanged, eraseExisting, simpleCall)

		// let mv = {}
		// let cv = {}
		// let sv = {}
		// let np = {}



		// if (eraseExisting) {
		// 	if (simpleCall && this.props.location.state !== undefined) {
		// 		if ('mv' in this.props.location.state) {
		// 			Object.keys(this.props.location.state['mv']).map((k)=>{
		// 				// console.log('entry',k)
		// 				mv[k]=this.props.location.state['mv'][k]
		// 			})
		// 		} else {
		// 			Object.keys(this.props.location.state['np']).map((k)=>{
		// 				// console.log('entry',k)
		// 				np[k]=this.props.location.state['np'][k]
		// 			})
		// 		}
		// 	}
		// } else {
		// 	if (this.state.mvvBase.length > 0) {mv['vBase']=this.state.mvvBase}
		// 	if (this.state.mvnsBases.length > 0) {mv['nsBases']=this.state.mvnsBases}
		// 	if (this.state.mvnoBases.length > 0) {mv['noBases']=this.state.mvnoBases}
		// 	if (this.state.mvno.length > 0) {mv['no']=this.state.mvno}
		// 	if (this.state.mvns.length > 0) {mv['ns']=this.state.mvns}

		// 	if (this.state.mvvMood.length > 0) {mv['vMood']=this.state.mvvMood}
		// 	if (this.state.mvvType.length > 0) {mv['vType']=this.state.mvvType}
		// 	if (this.state.mvvs.length > 0) {mv['vs']=this.state.mvvs}
		// 	if (this.state.mvvo.length > 0) {mv['vo']=this.state.mvvo}
		// 	if (this.state.mvnObliques.length > 0) {mv['nObliques']=this.state.mvnObliques}
		// 	// if (this.state.mvqWord.length > 0) {mv['qWord']=this.state.mvqWord}

		// 	if (this.state.cvvBase.length > 0) {cv['vBase']=this.state.cvvBase}
		// 	if (this.state.cvnsBases.length > 0) {cv['nsBases']=this.state.cvnsBases}
		// 	if (this.state.cvnoBases.length > 0) {cv['noBases']=this.state.cvnoBases}
		// 	if (this.state.cvno.length > 0) {cv['no']=this.state.cvno}
		// 	if (this.state.cvns.length > 0) {cv['ns']=this.state.cvns}
		// 	if (this.state.cvvMood.length > 0) {cv['vMood']=this.state.cvvMood}
		// 	if (this.state.cvvType.length > 0) {cv['vType']=this.state.cvvType}
		// 	if (this.state.cvvs.length > 0) {cv['vs']=this.state.cvvs}
		// 	if (this.state.cvvo.length > 0) {cv['vo']=this.state.cvvo}
		// 	if (this.state.cvnObliques.length > 0) {cv['nObliques']=this.state.cvnObliques}

		// 	if (this.state.svvBase.length > 0) {sv['vBase']=this.state.svvBase}
		// 	if (this.state.svnsBases.length > 0) {sv['nsBases']=this.state.svnsBases}
		// 	if (this.state.svnoBases.length > 0) {sv['noBases']=this.state.svnoBases}
		// 	if (this.state.svno.length > 0) {sv['no']=this.state.svno}
		// 	if (this.state.svns.length > 0) {sv['ns']=this.state.svns}
		// 	if (this.state.svvMood.length > 0) {sv['vMood']=this.state.svvMood}
		// 	if (this.state.svvType.length > 0) {sv['vType']=this.state.svvType}
		// 	if (this.state.svvs.length > 0) {sv['vs']=this.state.svvs}
		// 	if (this.state.svvo.length > 0) {sv['vo']=this.state.svvo}
		// 	if (this.state.svnObliques.length > 0) {sv['nObliques']=this.state.svnObliques}

		// 	if (this.state.npn.length > 0) {np['n']=this.state.npn}
		// 	if (this.state.npnBases.length > 0) {np['nBases']=this.state.npnBases}
		// 	if (this.state.npnCase.length > 0) {np['nCase']=this.state.npnCase}
		// 	if (this.state.npnType.length > 0) {np['nType']=this.state.npnType}

		// }


		// // console.log(keyChanged,mv)
  //   axios
  //     .post(API_URL + "/sentencebuilder", {
  //     	keyChanged:keyChanged,
  //     	mv:mv,
  //     	cv:cv,
  //     	sv:sv,
  //     	np:np,
  //     })
  //     .then(response => {
  //     	// console.log(response.data)
  //     	let vkey, nkey
  //     	let updateDict = {}
  //     	if ("english" in response.data) {
  //     		vEnglish.map((k)=>{
  //     			if (k in response.data['english']) {
  //     				updateDict[[k]] = response.data['english'][k]
  //     			} else {
  //     				updateDict[[k]] = []
  //     			}
  //     		})
  //     		// Object.keys(response.data['english']).map((k)=>{
  //     		// 		// console.log(k)
  //     		// 		// console.log(response.data['english'][k])
  //     		// 		updateDict[[k]] = response.data['english'][k]

		// 	     //    // this.setState({
		// 	     //    // 	[k]: response.data['english'][k],
		// 	     //    // })

		// 	     //  })
  //     	}

  //     	if ("mv" in response.data) {
  //     		updateDict['mv'] = response.data['mv']
  //     		vOptions.map((k)=>{
  //     			vkey = 'mv'+k
  //     			if (k in response.data['mv']) {
		// 	        // this.setState({
		// 	        	// [vkey]: response.data['mv'][k],
		// 	        // })

  //     				updateDict[[vkey]] = response.data['mv'][k]
  //     				if (k == 'vType') {
  //     					if (this.requirePostbaseMood(response.data['mv']['vType'])) {
  //     						updateDict['requirePostbase'] = response.data['mv']['vType'] 					      						
  //     					}
  //     				}
  //     			} else {

  //     				updateDict[[vkey]] = []

		// 	        // this.setState({
		// 	        	// [vkey]: [],
		// 	        // })      				
  //     			}
  //     		})
  //     	} else {
  //     		this.initialize('mv')
  //     	}

  //     	if ("cv" in response.data) {
  //     		updateDict['cv'] = response.data['cv']

  //     		vOptions.map((k)=>{
  //     			vkey = 'cv'+k
  //     			if (k in response.data['cv']) {
  //     				updateDict[[vkey]] = response.data['cv'][k]
  //     				if (k == 'vMood') {
  //     					if (this.requirePostbaseMood(response.data['cv']['vMood'])) {
  //     						updateDict['requirePostbase'] = response.data['cv']['vMood'] 					      						
  //     					}
  //     				}
  //     			} else {
  //     				updateDict[[vkey]] = []
		// 	        // this.setState({
		// 	        	// [vkey]: [],
		// 	        // })      				
  //     			}
  //     		})
  //     	} else {
  //     		this.initialize('cv')
  //     	}

  //     	if ("sv" in response.data) {
  //     		updateDict['sv'] = response.data['sv']

  //     		vOptions.map((k)=>{
  //     			vkey = 'sv'+k
  //     			if (k in response.data['sv']) {
  //     				updateDict[[vkey]] = response.data['sv'][k]
		// 	        // this.setState({
		// 	        // 	[vkey]: response.data['sv'][k],
		// 	        // })
  //     			} else {
  //     				updateDict[[vkey]] = []
		// 	        // this.setState({
		// 	        	// [vkey]: [],
		// 	        // })      				
  //     			}
  //     		})
  //     		// if (this.state.svvType.length > 0) {
  //     			// updateDict['svEnglish1']=[[this.state.svvType, 'svv.e']].concat(updateDict['svEnglish1'])
  //     			// this.setState({svEnglish1:[[this.state.svvType, 'svv.e']].concat(this.state.svEnglish1)})
  //     		// }
  //     	} else {
  //     		this.initialize('sv')
  //     	}

  //     	if ("np" in response.data) {
  //     		updateDict['np'] = response.data['np']

  //     		nOptions.map((k)=>{
  //     			nkey = 'np'+k
  //     			if (k in response.data['np']) {
  //     				updateDict[[nkey]] = response.data['np'][k]
  //     			} else {
  //     				updateDict[[nkey]] = []    				
  //     			}
  //     		})
  //     	} else {
  //     		this.initialize('np')
  //     	}

  //     	if ("parses" in response.data) {
  //     		updateDict['parses'] = response.data.parses
  //     	}

  //     	if ("segments" in response.data) {
  //     		if ("mv" in response.data.segments) {
  //     			if ("nObliques" in response.data.segments.mv) {
		// 	        // this.setState({
		// 	        	// mvnObliquesSegments: response.data.segments.mv.nObliques,
		// 	        // })      				
  //     				updateDict['mvnObliquesSegments'] = response.data.segments.mv.nObliques
  //     			} else {
		// 	        // this.setState({
		// 	        	// mvnObliquesSegments: [],
		// 	        // })      	      				
  //     				updateDict['mvnObliquesSegments'] = []
  //     			}
  //     			if ("v" in response.data.segments.mv) {
		// 	        // this.setState({
		// 	        	// mvvSegments: response.data.segments.mv.v,
		// 	        // })      				
  //     				updateDict['mvvSegments'] = response.data.segments.mv.v
  //     			} else {
		// 	        // this.setState({
		// 	        	// mvvSegments: "",
		// 	        // })      	      				
  //     				updateDict['mvvSegments'] = ""
  //     			}
  //     			if ("qWord" in response.data.segments.mv) {
		// 	        // this.setState({
		// 	        	// mvvSegments: response.data.segments.mv.v,
		// 	        // })      				
  //     				updateDict['mvqWordSegments'] = response.data.segments.mv.qWord
  //     			} else {
		// 	        // this.setState({
		// 	        	// mvvSegments: "",
		// 	        // })      	      				
  //     				updateDict['mvqWordSegments'] = ""
  //     			}
  //     			if ("ns" in response.data.segments.mv) {
		// 	        // this.setState({
		// 	        	// mvnsSegments: response.data.segments.mv.ns,
		// 	        // })      				
  //     				updateDict['mvnsSegments'] = response.data.segments.mv.ns
  //     			} else {
		// 	        // this.setState({
		// 	        	// mvnsSegments: [],
		// 	        // })      	      				
  //     				updateDict['mvnsSegments'] = []
  //     			}
  //     			if ("no" in response.data.segments.mv) {
		// 	        // this.setState({
		// 	        	// mvnoSegments: response.data.segments.mv.no,
		// 	        // })      				
  //     				updateDict['mvnoSegments'] = response.data.segments.mv.no
  //     			} else {
		// 	        // this.setState({
		// 	        	// mvnoSegments: [],
		// 	        // })      	      				
  //     				updateDict['mvnoSegments'] = []
  //     			}      			  
  //     		}
  //     		if ("cv" in response.data.segments) {
  //     			if ("nObliques" in response.data.segments.cv) {
		// 	        // this.setState({
		// 	        	// cvnObliquesSegments: response.data.segments.cv.nObliques,
		// 	        // })      				
  //     				updateDict['cvnObliquesSegments'] = response.data.segments.cv.nObliques
  //     			} else {
		// 	        // this.setState({
		// 	        	// cvnObliquesSegments: "",
		// 	        // })      	      				
  //     				updateDict['cvnObliquesSegments'] = ""
  //     			}      			
  //     			if ("v" in response.data.segments.cv) {
		// 	        // this.setState({
		// 	          // cvvSegments: response.data.segments.cv.v,
		// 	        // })      				
  //     				updateDict['cvvSegments'] = response.data.segments.cv.v
  //     			} else {
		// 	        // this.setState({
		// 	        	// cvvSegments: "",
		// 	        // })      	      				
  //     				updateDict['cvvSegments'] = ""
  //     			}
  //     			if ("ns" in response.data.segments.cv) {
		// 	        // this.setState({
		// 	        	// cvnsSegments: response.data.segments.cv.ns,
		// 	        // })      				
  //     				updateDict['cvnsSegments'] = response.data.segments.cv.ns
  //     			} else {
		// 	        // this.setState({
		// 	        	// cvnsSegments: [],
		// 	        // })      	      				
  //     				updateDict['cvnsSegments'] = []
  //     			}
  //     			if ("no" in response.data.segments.cv) {
		// 	        // this.setState({
		// 	        	// cvnoSegments: response.data.segments.cv.no,
		// 	        // })      				
  //     				updateDict['cvnoSegments'] = response.data.segments.cv.no
  //     			} else {
		// 	        // this.setState({
		// 	        	// cvnoSegments: [],
		// 	        // })      	      				
  //     				updateDict['cvnoSegments'] = []
  //     			}      			  
  //     		}

  //     		if ("sv" in response.data.segments) {
  //     			if ("nObliques" in response.data.segments.sv) {
		// 	        // this.setState({
		// 	        	// svnObliquesSegments: response.data.segments.sv.nObliques,
		// 	        // })      				
  //     				updateDict['svnObliquesSegments'] = response.data.segments.sv.nObliques
  //     			} else {
		// 	        // this.setState({
		// 	        	// svnObliquesSegments: "",
		// 	        // })      	      				
  //     				updateDict['svnObliquesSegments'] = ""
  //     			}   
  //     			if ("v" in response.data.segments.sv) {
		// 	        // this.setState({
		// 	        	// svvSegments: response.data.segments.sv.v,
		// 	        // })      				
  //     				updateDict['svvSegments'] = response.data.segments.sv.v
  //     			} else {
		// 	        // this.setState({
		// 	        	// svvSegments: "",
		// 	        // })      	      				
  //     				updateDict['svvSegments'] = ""
  //     			}
  //     			if ("no" in response.data.segments.sv) {
		// 	        // this.setState({
		// 	        	// svnoSegments: response.data.segments.sv.no,
		// 	        // })      				
  //     				updateDict['svnoSegments'] = response.data.segments.sv.no
  //     			} else {
		// 	        // this.setState({
		// 	        	// svnoSegments: [],
		// 	        // })      	      				
  //     				updateDict['svnoSegments'] = []
  //     			}      			  
  //     		}

  //   			if ("np" in response.data.segments) {
  //   				if ("n" in response.data.segments.np) {
		// 	        // this.setState({
		// 	        	// npnSegments: response.data.segments.np.n,
		// 	        // })      				
  //     				updateDict['npnSegments'] = response.data.segments.np.n
		// 	      } else {
		// 	        // this.setState({
		// 	        	// npnSegments: [],
		// 	        // })      	      				
  //     				updateDict['npnSegments'] = []
  //     			}
  //   			}
  //     	}


  //     	this.setState(updateDict,()=>{this.resetAfterSubmit()})

  // 		})

	}



	render() {
		console.log(this.state)
		// console.log(this.state.colorsList)
		let verbQuestionWords = [
			{value:0,key:0,text:'past tense',disabled:this.state.hasFuture},
			{value:1,key:1,text:'question: yes or no?'},
			{value:2,key:2,text:'question: why?'},
			{value:3,key:3,text:'question: how?'},
			{value:4,key:4,text:'question: where at?'},
			{value:5,key:5,text:'question: from where?'},
			// {value:5,key:5,text:'question: to where?'},
		]

		return (
			<div style={{fontFamily:customFontFam}}>

			<Transition visible={this.state.loaded} animation='fade' duration={300}>

      <Grid textAlign='center'>
      <Grid.Row  style={{height:40,paddingBottom:0}}>
      <Grid.Column style={{ maxWidth: 800, padding: 10, paddingBottom:30 }} textAlign='left'>

			<div style={{display:'flex',textDecoration:'underline'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
{/*					<Link to={{pathname: '/'}}>
					Ivaqłiqsatqiun Allanik Uqaluŋnik - Back to Search
					</Link>*/}

					{this.state.from === '' ? 
						<Link to={{pathname: '/'}}>
		          <Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
						</Link>
						:
						(this.state.from === '/' ?
							<Link to={{pathname: "/", state: { history:this.state.history }}} >
		          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
		          </Link>
							:
	          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
						)
					}

					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:['', [], 0, 1] }}} >
	          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
						</Link>
						:
						<Link to={{pathname: '/'}}>
	          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
						</Link>
					}



	      </div>


	      <div style={{flex:1,display:'flex',justifyContent:'flex-end',paddingTop:10,textDecoration:'underline'}}>
					<Link to={{pathname: '/'+ this.state.entryUrl}}>
{/*					<span>

					{'Utiġiñ Uqaluum Maŋŋuanun - See Dictionary Entry: '}
					</span>*/}
					<span style={{fontSize:'20px',fontWeight:'500'}}>
					{this.state.entryUrl.replaceAll(',',', ')}
					</span>
					</Link>
				</div>
			</div>

					<div style={{border:'1px solid #E3E3E3',marginTop:'20px'}}>

					<div className='hierarchymain'>
					<span className='span1'>Uqaluliuġun</span>
					<span className='span2'>Word Builder</span>
					</div>

					<Button onClick={()=>{this.backEndCall([['Delete',['mv',],''],['Delete',['np',],'']])}}>backEnd Delete</Button>

					<div style={{display:'flex',justifyContent:'center',marginTop:8}}>
					{this.state.dialect.map((q,qindex)=>{
						return <span style={{marginRight:'5px',cursor:'pointer'}}>
						<TagColors tags={q} width={-1} />
						</span>
					})}
					<span style={{cursor:'pointer'}}>
					<TagColors tags={this.state.baseTag} width={-1} />
					</span>
					</div>

				<Container>
					<div>
					{this.state.loaded ? this.usageEntry(this.state.num,this.state.tag) : null}
					</div>
				</Container>

				{this.state.currentPostbases.includes(22) && this.state.value1 == 's31-3(1)' ?
					<div style={{display:'flex',justifyContent:'center'}}>
						<span style={{fontStyle:'italic',fontSize:'14px',color:'#8c8c8c',paddingTop:'20px',fontWeight:'400',lineHeight:'23px'}}>"it" can refer to the situation</span>
					</div>
					:
					null
				}


				{this.state.properties.includes("transitional base") && this.state.currentPostbases.length == 0 && (this.state.verbMood == 'Indicative' || this.state.verbMood == 'Interrogative') ?
					<div style={{display:'flex',justifyContent:'center'}}>
						<span style={{fontStyle:'italic',fontSize:'14px',color:'#8c8c8c',paddingTop:'20px',fontWeight:'400',lineHeight:'23px'}}>Also immediate past tense</span>
					</div>
					:
					null
				}

				<div style={{paddingTop:'30px',paddingRight:'30px',paddingLeft:'30px',paddingBottom:'15px'}}>
						{this.state.englishPreSubject.map((q,qindex)=>{
							return <div style={{display:'flex',justifyContent:'center',paddingBottom:'20px'}}><Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px', color:this.state.colorsList[this.state.currentPostbases[qindex]], border:'1px solid '+this.state.colorsList[this.state.currentPostbases[qindex]],backgroundColor:'white'}} onClick={this.removePostbase.bind(this,qindex)}><span>{q}</span><Icon name='delete'/></Button></div>
						})}
						{this.state.englishPreNoun.map((q,qindex)=>{
							return <div style={{display:'flex',justifyContent:'center',paddingBottom:'20px'}}><Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px', color:this.state.colorsList[this.state.currentPostbases[qindex]], border:'1px solid '+this.state.colorsList[this.state.currentPostbases[qindex]],backgroundColor:'white'}} onClick={this.removePostbase.bind(this,qindex)}><span>{q}</span><Icon name='delete'/></Button></div>
						})}
						{this.state.englishPreVerb.map((q,qindex)=>{
							return <div style={{display:'flex',justifyContent:'center',paddingBottom:'20px'}}><Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px', color:this.state.colorsList[this.state.currentPostbases[qindex]], border:'1px solid '+this.state.colorsList[this.state.currentPostbases[qindex]],backgroundColor:'white'}} onClick={this.removePostbase.bind(this,qindex)}><span>{q}</span><Icon name='delete'/></Button></div>
						})}
						{this.state.nounMood !== 'Absolutive' ?
							<div style={{display:'flex',justifyContent:'center',paddingBottom:'20px'}}><Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px',color:'#852828',backgroundColor:'white',border:'1px solid #852828'}} onClick={this.changeNounEnding.bind(this,true)}><span>{getNounInfo[this.state.endingNumber]['buttontext']}</span><Icon name='delete'/></Button></div>
							:
							null
						}
						{this.state.verbMood == 'Participial' || this.state.verbMood == 'Interrogative' ?
							<div style={{display:'flex',justifyContent:'center',paddingBottom:'20px'}}><Button fluid style={{display:'flex',maxWidth:'350px',justifyContent:'space-between',paddingLeft:'14px', paddingRight:'3px',color:'#852828',backgroundColor:'white',border:'1px solid #852828'}} onClick={this.changeVerbEnding.bind(this,true)}><span>{getVerbInfo[this.state.endingNumber]['text']}</span><Icon name='delete'/></Button></div>
							:
							null
						}
				</div>


				{this.state.currentPostbases.length == 0 ? 
					<div style={{height:'56px',marginBottom:'15px'}}>
						<div style={{display:'flex',justifyContent:'center',paddingRight:'30px',paddingLeft:'30px'}}>
						  <Dropdown
						  	style={{maxWidth:'350px'}}
						    text={()=><div style={{display:'flex', flexDirection:'column'}}><span>{'Akunniġutilivsaaġuŋ'}</span><span style={{color:'grey',paddingTop:'4px'}}>{'Add a postbase'}</span></div>}
						    // text='Dropdown'
						    fluid
						    selection
						    value={this.state.postbase1}
						    selectOnBlur={false}
						    options={this.state.postbaseInformationAvailable1}
						    onChange={this.addPostbase.bind(this)}
						  />
						</div>
					</div>
					:
					(this.state.currentPostbases.length == 1 && this.state.postbasesAvailable.length !== 0 && this.state.verbMood != 'Participial' && this.state.verbMood != 'Interrogative' ?
					<div style={{height:'56px',marginBottom:'15px'}}>
						<div style={{display:'flex',justifyContent:'center',paddingRight:'30px',paddingLeft:'30px'}}>

							  <Dropdown
						  		style={{maxWidth:'350px'}}
						    	text={()=><div style={{display:'flex', flexDirection:'column'}}><span>{'Akunniġutilivsaaġuŋ'}</span><span style={{color:'grey',paddingTop:'4px'}}>{'Add a postbase'}</span></div>}
							    fluid
							    selection
							    value={this.state.postbase2}
							    selectOnBlur={false}
							    options={this.state.postbaseInformationAvailable2}
						    	onChange={this.addPostbase.bind(this)}
							  />
							
						</div>
					</div>
				  :
				  null
				  )
				}

				

				
				{this.state.tag == 'NOUN' && this.state.nounMood == 'Absolutive' ?
				<div style={{height:'56px'}}>
					<div style={{display:'flex',justifyContent:'center',paddingRight:'30px',paddingLeft:'30px'}}>
					  <Dropdown
					  	style={{maxWidth:'350px'}}
					    text={()=><div style={{display:'flex', flexDirection:'column'}}><span>{'Simmiġuŋ Isua'}</span><span style={{color:'grey',paddingTop:'4px'}}>{'Change Ending Type'}</span></div>}
					    fluid
					    selection
					    selectOnBlur={false}
					    value={this.state.ending1}
					    options={nounQuestionWords}
				    	onChange={this.changeNounEnding.bind(this,false)}
					  />
				  </div>
				</div>
				:
				null
				}


				{this.state.baseTag != 'NOUN' && this.state.verbMood != 'Participial' && this.state.verbMood != 'Interrogative' && this.state.currentPostbases.length < 2 ?
					<div style={{height:'56px'}}>
						<div style={{display:'flex',justifyContent:'center',paddingRight:'30px',paddingLeft:'30px'}}>
						  <Dropdown
						  	style={{maxWidth:'350px'}}
						    text={()=><div style={{display:'flex', flexDirection:'column'}}><span>{'Simmiġuŋ Isua'}</span><span style={{color:'grey',paddingTop:'4px'}}>{'Change Ending Type'}</span></div>}
						    fluid
						    selection
						    selectOnBlur={false}
						    value={this.state.ending1}
						    options={verbQuestionWords}
					    	onChange={this.changeVerbEnding.bind(this,false)}
						  />
					  </div>
					</div>
				  :
				  null
				}


				<div style={{height:'30px'}} />

				{this.state.tag == 'NOUN' ?
					<div style={{display:'flex',justifyContent:'center',marginBottom:'10px'}}>
					<span style={{fontSize:'14px',color:'grey',paddingRight:'15px',fontWeight:'400',lineHeight:'23px',paddingBottom:'4px'}}>Show alternate forms</span>
					<Checkbox toggle checked={this.state.showAlt} onClick={()=>{this.setState({showAlt:!this.state.showAlt})}} />
					</div>
					:
					null
				}

				{this.state.showAlt ?
					<div style={{display:'flex',justifyContent:'center',marginBottom:'10px'}}>
					<span style={{fontSize:'14px',color:'grey',paddingRight:'15px',fontWeight:'400',lineHeight:'23px',paddingBottom:'4px',fontStyle:'italic'}}>Alternate forms (alt) are less common</span>
					</div>
					:
					null
				}
				

				{this.state.sisters.length !== 0 ?

					<div>
					<div className='hierarchymain'>
					<span className='span1'>Allat Isut</span>
					<span className='span2'>Other Ending Forms</span>
					</div> 

							<List style={{marginTop:0}} divided>
							{this.state.sisters.map((i,index)=>
						    <List.Item style={{padding:7}} key={i}>
                  <List.Content style={{cursor:'pointer'}} onClick={()=>this.switchToSister(i)} floated='right'>
                    <Icon circular style={{marginTop:'8px', color:'#8f8f8f'}} size='large' name='chevron right' />
                  </List.Content>
						      <List.Content  style={{paddingRight:'16px'}}>
						        <List.Header style={{fontSize:'19px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
						          		<div> 
						              <span style={{'paddingRight':'3px',fontWeight:'400'}}>
                  				<span style={{borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190',cursor:'pointer'}} onClick={()=>this.switchToSister(i)}>
						              {this.processStyledText(i['usageWord'])}
						              </span>
						                <span style={{'marginLeft':'15px',marginRight:'6px',cursor:'pointer'}}>  
		                      		<TagColors tags={i['tag']} width={window.innerWidth} />
						                </span>

						              </span>
						              </div>
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText2(i['usageDefinition'],i['tag'])}</List.Description>
						      </List.Content>
						    </List.Item>
              	)}
							</List>
					</div>
					:
					null
				}

					</div>

						<div style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
						<span style={{fontSize:'14px',color:'grey',padding:'10px',fontWeight:'400',lineHeight:'23px'}}>
						<span style={{textDecoration:'underline'}}>Disclaimer:</span> This word builder is still in development. Please be aware that there may be mistakes with word results and translations. The automated computer rules that produce these words don't often reflect common language use, context, and cultural knowledge. As a general rule, if the English translation doesn't make sense then most likely the Iñupiaq word is not used.
						</span>
						</div>


			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'14px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>


			</Grid.Column>
			</Grid.Row>
			</Grid>
      
      </Transition>

			</div>
		);
	}
}
export default WordBuilder;