import React, { Component, createRef } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup, Segment, Table, Accordion, Checkbox, Modal, Input, Dimmer, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import { TagColors } from './HomeHelper.js';
import NewUsageBaseDisplay from './NewUsageBaseDisplay.js'
import {nounoptions1, nounoptions2, options1, options2, options3, colorsList, newColorsList, customFontFam, moodColors} from './constants/constants.js'
import {moodDescriptions} from './constants/moodDescriptions.js'
import { MoodSummaries } from './constants/MoodSummaries.js';
import SimpleWordBuilder from './SimpleWordBuilder.js';

import * as Scroll from 'react-scroll';
import fuzzysort from 'fuzzysort'
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;
let letterbutton = {paddingTop:'10px',paddingBottom:'10px',paddingRight:'14px',paddingLeft:'14px'}

let boldedtitles = '#000000DE'
let greytitles = '#000000DE'
let redboldedtitles = '#000000DE'
let plusbuttoncolor = '#000000DE'


  

let popularBases = [
    ["niġi-",0,0],
    ["niġi-",1,0],
    ["kaak-",0,0],
    ["isiq-",0,0],
    ["tautuk-",0,0],
    ["aullaq-",0,0],
    ["aŋun",0,0],
    ["aġnaq",0,0],
    ["miqłiqtuq",0,0],
    ["qimmiq",0,0], // dog
    ["iqaluk(-),qaluk(-)",0,0], // fish
    ["iñuk(-)",0,0], // person
    ["nanuq",0,0],
    ["tuttu",0,0],
    ["ui",0,0], 
    ["nuliaq(-)",0,0], 
    ["tuvaaqan",1,0],
    ["qitunġaq",0,0],
    ["piayaaq",0,0],
    ["aapiyaq",0,0],
    ["aatauraq",0,0],
    ["aqqaluk",0,0],
    ["nayaaluk",0,0],
    ["nuka,nukaq,nukatchiaq,nukaaluk",0,1],
    ["iḷḷuq,iḷḷualuk",0,0],
    ["aaka",0,0],
    ["aapa",0,0],
    ["aakaaluk",0,0],
    ["aapaaluk",0,0],
    ["panik",0,0],
    ["iñġutaq",0,0],
    ["tutik,tutaaluk",0,0],
    ["amau,amaułuk,amauluk",0,1],
]

let popularVVVNPostbases = [ // no vI or vT, only v-v-

["±ŋIt-",0,0], // ±ŋIt-not
["+niaq-",0,0], // +niaq-will
// ["aaka",0,0], // +niaŋIt-will not
["+p|vallIq-",0,0], // +p/vallIq-probably
["+[s]uu-",0,0], // +[s]uu-always (regularly)
["+[s]uk-¹",0,0], // +[s]uk-want to
["-lla-",0,0], // -lla-able to
["-lait-",0,0], // -laIt-unable to
["-rraqsI-",0,0], // -rraqsI-beginning to
["∓tiq-³",0,0], // ∓tiq-quickly
["±qqayaq-",0,0], // ±qqayaq-almost
["-llatu-",0,0], // -llatu-like
["+[s]ulait-",0,0], // +[s]ulait-dislike
["+saaġi-",0,0], // +saaġi-deliberately (purposefully)
// ["+[t]saiḷI-",0,0], // +[t]saiḷI-trying hard not to v-vI
// ["+[t]saiḷI-",1,0], // +[t]saiḷI-trying hard not to v-vT
["-pqauraq(-)",0,0], // -pqauraq-just barely
["±qqaaq(-)",0,0], // ±qqaaq-first
// ["±qasIq",0,0], // ±qasIq-with another vIvI
// ["±qasIq",0,0], // ±qasIq-with another vIvT
// ["+nI-¹",0,0], // +nI-he (subject) said that vI/vT
// ["-[t]qu-",0,0], // +[t]qu-you are asking that vI/vT
// ["∓tit-¹",0,0], // ∓tit- / +pkaq-you are letting vI/vT
// ["-[t]qusaaq-",0,0], // -[t]qusaaq-you are trying to persuade vI/vT
// ["+mmagaaq-",0,0], // +mmagaaq-you are checking to see if vI/vT

["+vIk¹,‘vIk",0,0], // v-n- +vIk¹ place, container, time to V, or person who allows one to V
["∓t|ri,~ri",0,0], // v-n- ∓t|ri one who usually V-s
// ["±qan,±qati",0,0], // {#} partner, companion in ___ing
["+niqsraq,+niqtaq",0,0], // {#} the __est {one;} the one who ___ed the most
["-yuiḷaq¹",0,0], // one which does not V

]

let popularNNNVPostbases = [
["-lluataq(-),-llautaq(-)",0,0], // -lluataq good
["+qpak(-)",1,0], // +qpak big
["-pałłuk(-)",2,0], // -pałłuk bad
["±ŋuluk(-)",0,0], // ±ŋuluk small
["-tchiaq(-)",0,0], // new
["±ŋŋuaq(-)",1,0], // fake

// [":u-¹",0,0], // :u- limited to be
["-liuq-,-lI-¹,‘=iuq-,‘=I-,~iuq-,~I-,~lI-¹",0,0], // -liuq- I
["-liuq-,-lI-¹,‘=iuq-,‘=I-,~iuq-,~I-,~lI-¹",0,1], // -liuq- T
// ["+tuq-³",0,0], // +tuq- limited to eat, wear
// ["-qaq-",0,0], // -qaq- 0 limited to have
// ["-qaq-",1,0], // -qaq- 1 limited to have
// ["-qaq-",2,0], // -qaq- 2 limited to have
["-kIt-",0,0], // -kIt-
[":It-¹,=It-,‘-It-",0,0], // :It- I
// [":It-¹,=It-,‘-It-",0,1], // :It- T
["÷muk-",0,0], // ÷muk-
["÷miñŋaq-",0,0], // ÷miñŋaq-
["‘-kuaq-,+tiguaq-",0,0], // ‘-kuaq-,+tiguaq-
]

let popularEnclitics = [
["+lu²",1,0], // encl +lu² also
["+li",0,0], // encl +li indicates a subject or topic change
["+pta",0,0], // encl +pta inevitable, inevitably, as expected
["+kiaq",0,0], // encl +kiaq I wonder, I think
["-gguuq",0,0], // encl -gguuq it has been said or reported that one is V-ing or V-ed
]



let endingProperties = {
"v":{
"Indicative Mood I":{"i":["+t/ru-","Indicative Mood I","is V-ing"],
"t":["+k/ga- ⎡or⎤ +k/gi-","Indicative Mood I","is V-ing ⎡her/him/it⎤"]},
"Participial Mood I":{"i":["+t/rua-","Participial Mood I and II","did V; the one who V-ed"],
"t":["+t/raq-","Participial Mood I and II","did V ⎡her/him/it⎤; the one who V-ed ⎡her/him/it⎤"]},
"Interrogative Mood":{"i":["+p/va- ⎡or⎤ +p/vI-","Interrogative Mood","did/is V-ing?"],
"t":["+p/va- ⎡or⎤ +p/vI-","Interrogative Mood","did/is V-ing ⎡her/him/it⎤?"]},
"Imperative Mood":{"i":["Ø","Optative-Imperative Mood","do V (command)"],
"t":["Ø","Optative-Imperative Mood","do V ⎡her/him/it⎤ (command)"]},
"Negative Imperative Mood":{"i":["+na-","Negative Optative-Imperative Mood","do not V"],
"t":["+na-","Negative Optative-Imperative Mood","do not V"]},
"Optative Mood":{"i":["+la ⎡or⎤ lI-","Optative-Imperative Mood","let V"],
"t":["+la ⎡or⎤ lI-","Optative-Imperative Mood","let V ⎡her/him/it⎤"]},
"Kiisaimmaa Mood":{"i":["+p/vu-","Kiisaimmaa Mood","finally did V"],
"t":["+p/vaq-","Kiisaimmaa Mood","finally did V ⎡her/him/it⎤"]},
"Indicative Mood II":{"i":["+sugau-","Indicative Mood II","don't you remember that ⎡or⎤ finally, despite all odds"],
"t":["+sugaaq-","Indicative Mood II","don't you remember that... ⎡or⎤ (excl.) finally despite all odds ⎡or⎤ the time it took, the subject Vs"]},
"Participial Mood II":{"t":["+kkaq","Participial Mood I and II","did V ⎡her/him/it⎤"]},
"Participial Mood III":{"i":["+k/galuaq- ⎡and⎤ +t/rua-","Participial Mood I and II","did V, but..."],
"t":["+k/galuaq- ⎡and⎤ +t/raq-","Participial Mood I and II","did V ⎡her/him/it⎤, but..."]},


"Subordinative Mood Realized":{"i":["+vlu- ⎡or⎤ +łu-","Subordinative Mood","by V-ing"],
"t":["+vlu- ⎡or⎤ +łu-","Subordinative Mood","by V-ing ⎡her/him/it⎤"]},
"Subordinative Mood Unrealized":{"i":["+lu-","Subordinative Mood","by V-ing"],
"t":["+lu-","Subordinative Mood","by V-ing ⎡her/him/it⎤"]},


"Contemporative Mood I Realized":{"i":["+pkaqłu- ⎡or⎤ +tiłłu-","Contemporative Mood I","while V-ing"],
"t":["+pkaqłu- ⎡or⎤ +tiłłu-","Contemporative Mood I","while V-ing ⎡her/him/it⎤"]},
"Contemporative Mood I Unrealized":{"i":["+pkaġlu- ⎡or⎤ +tillu-","Contemporative Mood I","while V-ing"],
"t":["+pkaġlu- ⎡or⎤ +tillu-","Contemporative Mood I","while V-ing ⎡her/him/it⎤"]},
"Contemporative Mood II":{"i":["-llaq-","Contemporative Mood II, III, IV","while simultaneously V-ing"],
"t":["-llaq-","Contemporative Mood II, III, IV","while simultaneously V-ing ⎡her/him/it⎤"]},
"Contemporative Mood II (alt)":{"t":["-llaq-","Contemporative Mood II, III, IV Alternate","while simultaneously V-ing ⎡her/him/it⎤"]},
"Contemporative Mood III":{"i":["-ŋŋaq-","Contemporative Mood II, III, IV","(durative or past) while V-ing"],
"t":["-ŋŋaq-","Contemporative Mood II, III, IV","(durative or past) while V-ing ⎡her/him/it⎤"]},
"Contemporative Mood III (alt)":{"t":["-ŋŋaq-","Contemporative Mood II, III, IV Alternate","(durative or past) while V-ing ⎡her/him/it⎤"]},
"Contemporative Mood IV":{"i":["-mmaq-","Contemporative Mood II, III, IV","while intermittently V-ing"],
"t":["-mmaq-","Contemporative Mood II, III, IV","while intermittently V-ing ⎡her/him/it⎤"]},
"Contemporative Mood IV (alt)":{"t":["-mmaq-","Contemporative Mood II, III, IV Alternate","while intermittently V-ing ⎡her/him/it⎤"]},
"Negative Precessive Mood I":{"i":["-ŋaunna-","Subordinative Mood","not before V-ing"],
"t":["-ŋaunna-","Subordinative Mood","not before V-ing ⎡her/him/it⎤"]},
"Negative Precessive Mood II (-kaluaqtinna-)":{"i":["+k/galuaqtinna-","Subordinative Mood","prior, before V-ing"],
"t":["+k/galuaqtinna-","Subordinative Mood","prior, before V-ing ⎡her/him/it⎤"]},
"Negative Precessive Mood II (-ŋaisillu-)":{"i":["±ŋaisillu-","Subordinative Mood","prior, before V-ing"],
"t":["±ŋaisillu-","Subordinative Mood","prior, before V-ing ⎡her/him/it⎤"]},
"Negative Precessive Mood III":{"i":["+k/galuaġna-","Subordinative Mood","without V-ing"],
"t":["+k/galuaġna-","Subordinative Mood","without V-ing ⎡her/him/it⎤"]},
"Concessive Mood I":{"i":["+k/galuaqłu-","Subordinative Mood","although V-ing"],
"t":["+k/galuaqłu-","Subordinative Mood","although V-ing ⎡her/him/it⎤"]},
"Concessive Mood II Realized":{"i":["+k/galuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","even when one Vs"],
"t":["+k/galuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","even when one Vs ⎡her/him/it⎤"]},
"Concessive Mood II Unrealized":{"i":["+k/galuaq- ⎡and⎤ +k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","even if one Vs"],
"t":["+k/galuaq- ⎡and⎤ +k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","even if one Vs ⎡her/him/it⎤"]},
"Concessive Mood III":{"i":["+k/galuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","because or seeing that one is V-ing"],
"t":["+k/galuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","because or seeing that one is V-ing ⎡her/him/it⎤"]},
"Conditional Mood I":{"i":["+k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","if or when (future) one Vs"],
"t":["+k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","if or when (future) one Vs ⎡her/him/it⎤"]},
"Conditional Mood II":{"i":["-tuaq- ⎡and⎤ +k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","if ever one Vs"],
"t":["-tuaq- ⎡and⎤ +k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","if ever one Vs ⎡her/him/it⎤"]},
"Consequential Mood I":{"i":["+k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","when (habitual or past) one Vs"],
"t":["+k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","when (habitual or past) one Vs ⎡her/him/it⎤"]},
"Consequential Mood II":{"i":["-tuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","whenever, every time one Vs"],
"t":["-tuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","whenever, every time one Vs ⎡her/him/it⎤"]},
"Successive Mood Realized":{"i":["+k/galuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","after V-ing for a while, one Vs..."],
"t":["+k/galuaq- ⎡and⎤ +k/ga- ⎡or⎤ +mma-","Consequential-Conditional Mood","after V-ing ⎡her/him/it⎤ for a while, one Vs..."]},
"Successive Mood Unrealized":{"i":["+k/galuaq- ⎡and⎤  +k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","after V-ing for a while, one will V..."],
"t":["+k/galuaq- ⎡and⎤  +k/gu- ⎡or⎤ +kpa-","Consequential-Conditional Mood","after V-ing ⎡her/him/it⎤ for a while, one will V..."]},

},'n':{
"Absolutive Case":{"n":["∅","Absolutive Case Possessive","dictionary stem"]},
"Relative Case":{"n":["∅","Relative Case Possessive","owner or subject of transitive verb"]},
"Modalis Case":{"n":["÷mik ⎡or⎤ ÷nik","Modalis Case Possessive","indirect object"]},
"Locative Case":{"n":["÷mI ⎡or⎤ ÷nI","Locative Case Possessive","in or at N"]},
"Terminalis Case":{"n":["÷mun ⎡or⎤ ÷nun","Terminalis Case Possessive","to N"]},
"Ablative Case":{"n":["÷miñ ⎡or⎤ ÷niñ","Ablative Case Possessive","from N"]},
"Vialis Case":{"n":["-kun ⎡or⎤ ÷tigun","Vialis Case Possessive","via, by way of N"]},
"Similaris Case":{"n":["÷tun ⎡or⎤ ÷titun","Similaris Case Possessive","like an N"]},
}
}


let subjectmarkers = [
'1Sg',
'1Du',
'1Pl',
'2Sg',
'2Du',
'2Pl',
'3Sg',
'3Du',
'3Pl',
'3RSg',
'3RDu',
'3RPl',
]

let subjectmarkersNoReflex = [
'1Sg',
'1Du',
'1Pl',
'2Sg',
'2Du',
'2Pl',
'3Sg',
'3Du',
'3Pl',
]

let objectmarkers = [
'3RSgO',
'3RDuO',
'3RPlO',
'3SgO',
'3DuO',
'3PlO',
'2SgO',
'2DuO',
'2PlO',
'1SgO',
'1DuO',
'1PlO',
]

let objectmarkersNoReflex = [
'3SgO',
'3DuO',
'3PlO',
'2SgO',
'2DuO',
'2PlO',
'1SgO',
'1DuO',
'1PlO',
]

let nounMarkertitles = {
'1Sg':['1Sg','my','1'],
'1Du':['1Du','our (2)','1'],
'1Pl':['1Pl','our (3+)','1'],
'2Sg':['2Sg','your (1)','2'],
'2Du':['2Du','your (2)','2'],
'2Pl':['2Pl','your (3+)','2'],
'3Sg':['3Sg','his/her/its (other)','3'],
'3Du':['3Du','their (2) (other)','3'],
'3Pl':['3Pl','their (3+) (other)','3'],
'3RSg':['3RSg','his/her/its own','4'],
'3RDu':['3RDu','their own (2)','4'],
'3RPl':['3RPl','their own (3+)','4'],
}

let nounUnpossessedMarkersTitles = {
  '+Sg':['Sg','singular (1)','n.b'],
  '+Du':['Du','dual (2)','n.b'],
  '+Pl':['Pl','plural (3+)','n.b'],
}


let subjectmarkertitles = {
'1Sg':['1Sg','I','1s'],
'1Du':['1Du','we (2)','1s'],
'1Pl':['1Pl','we all (3+)','1s'],
'2Sg':['2Sg','you','2s'],
'2Du':['2Du','you (2)','2s'],
'2Pl':['2Pl','you all (3+)','2s'],
'3Sg':['3Sg','he/she/it','3s'],
'3Du':['3Du','they (2)','3s'],
'3Pl':['3Pl','they all (3+)','3s'],
'3RSg':['3RSg','he/she/it','4'],
'3RDu':['3RDu','they (2)','4'],
'3RPl':['3RPl','they all (3+)','4'],
}

let objectmarkertitles = {
'3RSgO':['3RSg','him/her/it','4'],
'3RDuO':['3RDu','them (2)','4'],
'3RPlO':['3RPl','them all (3+)','4'],
'3SgO':['3Sg','him/her/it','3o'],
'3DuO':['3Du','them (2)','3o'],
'3PlO':['3Pl','them all (3+)','3o'],
'2SgO':['2Sg','you','2o'],
'2DuO':['2Du','you (2)','2o'],
'2PlO':['2Pl','you all (3+)','2o'],
'1SgO':['1Sg','me','1o'],
'1DuO':['1Du','us (2)','1o'],
'1PlO':['1Pl','us all (3+)','1o'],
}


// const tableVerbTypeOptions = [
//   {
//     key: 'Conditional Mood',
//     text: 'Conditional Mood',
//     value: 'Cond',
//   },
//   {
//     key: 'Consequential Mood',
//     text: 'Consequential Mood',
//     value: 'Conseq',
//   },
// ]



class EndingTables extends Component {
	constructor(props) {
		super(props);
    console.log(this.props)
		this.state = {
      modalType:'',
      showModal:false,
      // newBase:['niġi-', 0, 0],
      newBase: props.location.state === undefined || props.location.state.empty ? [] : props.location.state.newBase,
      newBaseCall: props.location.state === undefined || props.location.state.empty  ? [] : props.location.state.newBaseCall,
      numDefinition: props.location.state === undefined || props.location.state.empty  || !('newBase' in props.location.state) ? -1 : props.location.state.newBase[2],
      otherDefinitions: [],
      otherSpellings:[],
      numSpelling:0,
      thisSpelling:'',
      thisDefinition:'',

      // newPbs:[['+[s]uk-¹', 0, 0]],
      newPbs:[],
      // newCaseMood:"Indicative Mood I",
      newCaseMood:"",
      // newBaseCall:[],
      newEnclitics:[],

      newBaseDefinition: props.location.state === undefined || props.location.state.empty  ? '' : props.location.state.thisDefinition,


      newPbsDefinition:[],
      newEncliticsDefinition:'',
      alts:false,
      // newBaseCall:[['niġi-', 0, 0],['+[s]uk-¹', 0, 0]],

      outbase:[],
      outcaseMood:'',
      outenclitics:[],
      outalts:false,
      outAudioTable:{},

      currentlyLoading: false,
      currentlyLoadingAfter:false,

      endingRuleChartName:'',

      showEdnaOptions:false,

      base:[],
      caseMood:'',
      baseType: props.location.state === undefined || props.location.state.empty  ? [] : props.location.state.baseType,

      outbaseType:[],
      endingTable:{},
      fst:[],
      useColor:true,
      largeWindow:(window.innerWidth < 480 ? false : true),
      history: props.location.state === undefined || props.location.state.empty  ? [] : props.location.state.history,
      from: props.location.state === undefined || props.location.state.empty  ? '' : props.location.state.from,
      searchQuery:'',
      wordsList:[],
      baseVerbNoun: props.location.state === undefined || props.location.state.empty  ? [] : props.location.state.baseVerbNoun,

      outbaseVerbNoun:[],

      filteredPostbaseVI: props.postbaseusagelist.filter(entry => (entry['t1'].slice(0,2) == 'v-' || entry['t1'].slice(0,2) == 'vI')),
      filteredPostbaseVT: props.postbaseusagelist.filter(entry => (entry['t1'].slice(0,2) == 'v-' || entry['t1'].slice(0,2) == 'vT')),
      filteredPostbaseN: props.postbaseusagelist.filter(entry => (entry['t1'].slice(0,2) == 'n-')),
      filteredEnclitics: props.postbaseusagelist.filter(entry => (entry['t1'] == 'encl')),

      highlightingx: false,

		}
      this.handleRef = createRef()
	    scroll.scrollTo(0);

      // if (props.location.state != undefined && props.location.state.newBase.length > 0) {
        // this.initialize(props.location.state.newBase)
      // }
	}


  // initialize(newBase) {
  //   console.log('started')
  //   let entry = this.props.usagedictionary_dict[newBase[0]][newBase[1]]
  //   this.clickSearchEntry(newBase,entry.tag,entry.thisDefinition,'base')
  // }

  componentDidMount() {
      if (this.state.newBase.length > 0) {
        this.findOtherDefinitionsAndSpellings(this.state.newBase);
      }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({ 
			from: nextProps.location.pathname,
			// fullDefinition: nextProps.fullDefinition,
		})

	}
  componentDidUpdate(prevProps, prevState) {
    if (this.state.newBase !== prevState.newBase || this.state.newPbs !== prevState.newPbs) {
      this.setState({newBaseCall:[this.state.newBase].concat(this.state.newPbs)})
    }
    if (this.state.highlightingx !== prevState.highlightingx) {
       setTimeout(function() { 
          this.setState({highlightingx: false}) 
      }.bind(this), 300)     
    }

  }


  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replaceAll('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }
 

  aud = (link) => {
    return <Icon circular style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' onClick={()=>this.repeatAudio(link)}/>
  }
 

  processStyledText = (sentence) => {
    // sentence = sentence.replaceAll(">","")


    sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
    let matches = sentence.match(/⎡.*?⎤/g)
    let matches1 = sentence.match(/{.*?}/g)
    let matches2 = sentence.match(/_.*?_/g)
    let matches3 = sentence.match(/\^.*?\^/g)
    // console.log(matches2)
    if (matches !== null || matches1 !== null || matches2 !== null || matches3 !== null) {
      if (matches !== null) {
        matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
      }
      if (matches1 !== null) {
        matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
      }
      if (matches2 !== null) {
        matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
      }
      if (matches3 !== null) {
        matches3.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))                      
      }
      return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    } else {
      return <span>{sentence}</span>
    }


    // let matches = sentence.match(/\⎡.*?\⎤/g)
    // if (matches !== null) {
    //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
    //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
    // } else {
    //   return <span>{sentence}</span>
    // }
  }

  // handleTableVerbTypeOptions = (e, { value }) => {
  //   this.setState({ currentType:value })
  // }


  // getCorrectForm = (word, e, value) => {

  //   if (word.length !== 0) {
  //     if (this.state.currentType == 'roots') {
  //       return word.replaceAll('⎤','-').replaceAll('⎡°','').replaceAll(',',', ').toLowerCase()
  //     } else if (this.state.currentType == 'adv' && this.state.currentCase.length !== 0 && this.state.currentCase !== 'sim' && this.state.currentCase in this.props.demonstrativedata[word]['adv']) {
  //       return this.props.demonstrativedata[word]['adv'][this.state.currentCase]['word']['text']
  //     } else if (this.state.currentType == 'pro' && this.state.currentCase.length !== 0 && this.state.currentNumber.length !== 0 && this.state.currentCase in this.props.demonstrativedata[word]['pro']) {
  //       return this.props.demonstrativedata[word]['pro'][this.state.currentCase][[this.state.currentNumber]]['text']
  //     } else {
  //       return word      
  //     }      
  //   }

  // }

 newEndingTableCall = (event, data) => {
    this.setState({currentlyLoading:true,currentlyLoadingAfter:true})
    // console.log(this.state)
    axios
      .post(API_URL + "/inupiaqendingtable", {
        base:this.state.newBaseCall,
        enclitics:this.state.newEnclitics,
        alts:this.state.alts,
        caseMood:this.state.newCaseMood,
      })
      .then(response => {
        // console.log(response.data)
        // if (window.innerWidth < 800) {
          scroll.scrollTo(500);
        // }
        this.setState({
          outAudioTable:response.data.audio,
          outbase:this.state.newBase,
          outcaseMood:this.state.newCaseMood,
          outenclitics:this.state.newEnclitics,
          outalts:this.state.alts,
          outbaseVerbNoun:this.state.baseVerbNoun,
          outbaseType:this.state.baseType,
          endingTable:response.data.endingTable,
          currentlyLoading:false,
          // baseType:response.data.baseType, //i t n
          fst:response.data.fst, 
        },()=>{this.setState({currentlyLoadingAfter:false})})

    })
    
  }

  onChangeSearch = (buttonkey,newWord,tag,event,data) => {
      let word
      // console.log(tag,event,data)
      if (buttonkey) {
        word = newWord
      } else {
        word = data.value
      }
      // console.log(buttonkey,newWord,tag,word,event,data)

    // let word
    // if (bool) {
    //   word = data
    // } else {
    //   word = data.value
    //   homeMode = this.state.homeMode
    // }

    //   let new_search, wordsList
    // word = word.replaceAll("’","'").replaceAll("ḷ","ḷ").replaceAll("ł̣","ł̣").replaceAll("G","ġ").replaceAll("g.","ġ").replaceAll("l.","ḷ").replaceAll("L","ł").replaceAll("ł.","ł̣");
    // new_search = word.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',','')

    // // console.log(word, new_search)
    //   if (homeMode === 1) {
    //       wordsList = fuzzysort.go(new_search, this.props.usagedictionary, optionsUsageFuzzy).map(({ obj }) => (obj));
    //   } else if (homeMode === 0) {
    //       wordsList = fuzzysort.go(new_search, this.props.dictionary, optionsFuzzy).map(({ obj }) => (obj));
    //   } else if (homeMode === 2) {
    //       wordsList = fuzzysort.go(new_search, this.props.audiolibrary, optionsAudioFuzzy).map(({ obj }) => (obj));
    //   }
      let wordsList
      word = word.replaceAll("’","'").replaceAll("ḷ","ḷ").replaceAll("ł̣","ł̣").replaceAll("G","ġ").replaceAll("g.","ġ").replaceAll("l.","ḷ").replaceAll("L","ł").replaceAll("ł.","ł̣");
      let newSearch = word.replaceAll('ġ','g').replaceAll('ñ','n').replaceAll('ḷ','l').replaceAll('ł','l').replaceAll('ł̣','l').replaceAll('G','g').replaceAll('(','').replaceAll('-','').replaceAll(')','').toLowerCase().replaceAll('he is ','').replaceAll('she is ','').replaceAll('it is ','').replaceAll('i am ','').replaceAll(' ','').replaceAll(',','')
      // let newSearch = word.toLowerCase().replaceAll(/^is /g,'').replaceAll(/^am /g,'').replaceAll(/^are /g,'')

      let optionsFuzzy = {
        keys: ['usageWordNormal', 'thisDefinition'],
        limit: 10, // don't return more results than you need!
        threshold: -10000, // don't return bad results
      };
      let postbaseOptionsFuzzy = {
        keys: ['k0', 'd'],
        limit: 10, // don't return more results than you need!
        threshold: -10000, // don't return bad results
      };


      if (tag === 'base') {
          wordsList = fuzzysort.go(newSearch, this.props.usagedictionary, optionsFuzzy).map(({ obj }) => (obj));
          this.setState({ wordsList: wordsList, searchQuery: word });  
      } 

      if (tag === 'postbase') {
        // console.log(this.state.baseType.slice(-1)[0])
        if (this.state.baseType.slice(-1)[0] === 'i') {
          wordsList = fuzzysort.go(newSearch, this.state.filteredPostbaseVI, postbaseOptionsFuzzy).map(({ obj }) => (obj));
        } else if (this.state.baseType.slice(-1)[0] == 't') {
          wordsList = fuzzysort.go(newSearch, this.state.filteredPostbaseVT, postbaseOptionsFuzzy).map(({ obj }) => (obj));
        } else {
          wordsList = fuzzysort.go(newSearch, this.state.filteredPostbaseN, postbaseOptionsFuzzy).map(({ obj }) => (obj));
        }
          // wordsList = fuzzysort.go(newSearch, this.props.postbaseusage, postbaseOptionsFuzzy).map(({ obj }) => (obj));
          this.setState({ wordsList: wordsList, searchQuery: word });          
      }

      if (tag === 'enclitics') {
          wordsList = fuzzysort.go(newSearch, this.state.filteredEnclitics, postbaseOptionsFuzzy).map(({ obj }) => (obj));
          this.setState({ wordsList: wordsList, searchQuery: word });  
      }
      // console.log(tag, 'tag')
      // if (tag === 'timeWord') {
      //     wordsList = fuzzysort.go(newSearch, timeWordsList, timeWordOptionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });  
      // } else if (tag === 'Loc' || tag === 'Via' || tag === 'Sim' || tag === 'mvns' || tag === 'mvno' || tag === 'cvns' || tag === 'cvno' || tag === 'mvnObliques' || tag === 'posd' || tag === 'poss' || tag === 'nounnc') {
      //     wordsList = fuzzysort.go(newSearch, this.state.filteredDictN, optionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });     
      // } else {
      //     wordsList = fuzzysort.go(newSearch, this.state.filteredDictV, optionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });
      // }

      // if (tag === 'NOUN') {
      //     wordsList = fuzzysort.go(newSearch, this.state.filteredDictN, optionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });
      // } else {
      //     wordsList = fuzzysort.go(newSearch, this.state.filteredDictV, optionsFuzzy).map(({ obj }) => (obj));
      //     this.setState({ wordsList: wordsList, searchQuery: word });
      // }
  }

  checkMaintainOptions(entry) {

    // console.log(entry)
    let deletePostbasesOn = false
    let deleteEndingsOn = false
    let notAllowedEndings = ["Participial Mood II","Contemporative Mood II (alt)","Contemporative Mood III (alt)","Contemporative Mood IV (alt)","Participial Mood II"]
    if (entry.tag == 'i' && notAllowedEndings.includes(this.state.newCaseMood)) {
      deleteEndingsOn = true
    }

    this.state.newPbs.map((p)=>{
      let entry = this.props.postbaseusage[p.join(';')]
      if (!(entry.t1.slice(0,3) == 'v-v' || entry.t1.slice(0,3) == 'n-n')) {
        deletePostbasesOn = true
      }
      // console.log(entry)
      // console.log(entry.t1.slice(0,2))
    })
    //   notAllowedEndings.push("Participial Mood II")
    //   notAllowedEndings.push("Contemporative Mood II (alt)")
    //   notAllowedEndings.push("Contemporative Mood III (alt)")
    //   notAllowedEndings.push("Contemporative Mood IV (alt)")
    //   notAllowedEndings.push("Participial Mood II")
    // }
    // if (newPostbaseList.includes('+niaq-')) {
    //   notAllowedEndings.push("Participial Mood I")
    //   notAllowedEndings.push("Participial Mood II")
    //   notAllowedEndings.push("Participial Mood III")
    // }

    if (deletePostbasesOn) {
      this.setState({newPbs:[],newPbsDefinition:[],newCaseMood:'',newEnclitics:'',newEncliticsDefinition:[]})
    } else if (deleteEndingsOn) {
      this.setState({newCaseMood:'',newEnclitics:'',newEncliticsDefinition:[]})
    } 
  }

  findOtherDefinitionsAndSpellings(newKey) {
    let entry = this.props.usagedictionary_dict[newKey[0]]
    // console.log(entry,newKey[1],newKey[2])
    let otherDefinitions = []
    let thisSpelling = ''
    if (Object.keys(entry).length > 1) {
      Object.keys(entry).map((k)=>{
        if (parseInt(k) !== newKey[1]) {
          otherDefinitions.push({
            'key':k,
            'value':k,
            'text':entry[k]['thisDefinition']
          })
        }
      })
    }

    let otherSpellings = []
    if (Object.keys(entry[newKey[1]]['differentKeySpellings']).length > 1) {
      Object.keys(entry[newKey[1]]['differentKeySpellings']).map((k)=>{
        // console.log(parseInt(k),newKey[2],parseInt(k) !== newKey[2])
        if (parseInt(k) !== newKey[2]) {
          otherSpellings.push({
            'key':k,
            'value':k,
            'text':entry[newKey[1]]['differentKeySpellings'][k],
          })
        }
      })
    }
    this.setState({numSpelling:newKey[2],numDefinition:newKey[1],thisSpelling:entry[newKey[1]]['differentKeySpellings'][newKey[2]],thisDefinition: entry[newKey[1]]['thisDefinition'],otherDefinitions:otherDefinitions,otherSpellings:otherSpellings})
  }

  clickDefinition(value,event,data) {
    let entry = this.props.usagedictionary_dict[this.state.newBase[0]][value]
    // console.log(data.value)
    this.checkMaintainOptions(entry)
    this.clickSearchEntry([this.state.newBase[0],parseInt(value),0],entry.tag,entry.thisDefinition,'base')
    // console.log(event, data)
  }

  clickSpelling(value,event,data) {
    let entry = this.props.usagedictionary_dict[this.state.newBase[0]][this.state.newBase[1]]
    // console.log(value)
    this.setState({newPbs:[],newPbsDefinition:[],newCaseMood:'',newEnclitics:'',newEncliticsDefinition:[]})
    this.clickSearchEntry([this.state.newBase[0],this.state.newBase[1],parseInt(value)],entry.tag,entry.thisDefinition,'base')
    // console.log(event, data)
  }

  clickSearchEntry = (newKey,tag,thisDefinition,modalType) => {
    // console.log('clickSearchEntry',modalType,newKey,tag,thisDefinition)
    if (modalType === 'base') {
      this.findOtherDefinitionsAndSpellings(newKey)
      if (tag == 'n') {
        this.setState({baseVerbNoun:['n'],baseType:[tag],newBase:newKey,newBaseDefinition:thisDefinition})        
      } else {
        this.setState({baseVerbNoun:['v'],baseType:[tag],newBase:newKey,newBaseDefinition:thisDefinition})        
      }
    } else if (modalType == 'postbase') {
      // console.log(newKey,tag)
      let transitivetag = ''
      if (tag.slice(2) == 'vI') {
        if (!thisDefinition.includes('(i)')) {
          transitivetag = '(i) '          
        }
        this.setState({baseType:this.state.baseType.concat(['i']),baseVerbNoun:this.state.baseVerbNoun.concat(['v'])})
      } else if (tag.slice(2) == 'vT') {
        if (!thisDefinition.includes('(t)')) {
          transitivetag = '(t) '          
        }
        this.setState({baseType:this.state.baseType.concat(['t']),baseVerbNoun:this.state.baseVerbNoun.concat(['v'])})
      } else if (tag.slice(2) == 'n-') {
        this.setState({baseType:this.state.baseType.concat(['n']),baseVerbNoun:this.state.baseVerbNoun.concat(['n'])})
      } else {
        this.setState({baseType:this.state.baseType.concat(this.state.baseType.slice(-1)[0]),baseVerbNoun:this.state.baseVerbNoun.concat(this.state.baseVerbNoun.slice(-1)[0])})
      }
      this.setState({newPbs:this.state.newPbs.concat([newKey]),newPbsDefinition:this.state.newPbsDefinition.concat([transitivetag+thisDefinition])})
      //e- condition
    } else if (modalType == 'enclitics') {
      this.setState({newEnclitics:[newKey],newEncliticsDefinition:thisDefinition})
    }

    this.setState({wordsList:[], searchQuery:'', showModal:false,})
  }

  popularSearchKeys = () => {

    // let entry = p[1] //this.props.usagedictionary_dict[p[0]][p[1]]
    // let tag = p[2]//'v'
    // let url = p[3]
    // let thisDefinition = p[4]

      // let newKey = []
      // let thisDefinition = ''
      // let url = ''
      // let tag = ''
      // if (this.state.modalType === 'base') {
      //   newKey = [k['url'],k['num'],0]
      //   thisDefinition = k.thisDefinition
      //   url = k.url
      //   tag = k.tag
      // } else if (this.state.modalType === 'postbase') {
      //   newKey = [k['k0'],k['k1'],k['k2']]
      //   thisDefinition = k['d']
      //   url = k['k0']
      //   tag = k['t1']
      // }

    let entry = []

    // let popularItems = popularBases

    let newPopularList = []

    let newPostbaseList = []
    this.state.newPbs.map((k)=>{
      newPostbaseList.push(k[0])
    })
    

    if (this.state.modalType == 'base') {      
      popularBases.map((p)=>{
        entry = this.props.usagedictionary_dict[p[0]][p[1]]
        newPopularList.push([p,entry,entry.tag,entry.url,entry.thisDefinition])
      })
    } else if (this.state.modalType == 'postbase') {
      // console.log(this.state.baseType.slice(-1)[0])
      if (this.state.baseType.slice(-1)[0] == 'n') {
        popularNNNVPostbases.map((p)=>{
          // console.log(p)
          if (!newPostbaseList.includes(p[0])) {
            entry = this.props.postbaseusage[p.join(';')]
            newPopularList.push([p,entry,entry.t1,entry.k0,entry.d])
          }   
        })
      } else {
        popularVVVNPostbases.map((p)=>{
          if (!newPostbaseList.includes(p[0])) {
            entry = this.props.postbaseusage[p.join(';')]
            newPopularList.push([p,entry,entry.t1,entry.k0,entry.d])    
          }      
        })
      }
      // popularItems = popularBases

                                            //   newKey = [k['k0'],k['k1'],k['k2']]
                                            //   thisDefinition = k['d']
                                            //   url = k['k0']
                                            //   tag = k['t1']
    } else if (this.state.modalType == 'enclitics') {
      popularEnclitics.map((p)=>{
          entry = this.props.postbaseusage[p.join(';')]
          newPopularList.push([p,entry,entry.t1,entry.k0,entry.d])          
        })
    }
    // console.log(newPopularList)
    return newPopularList
  }

  displayEndingUsage = (casemood) => {
    if (casemood in moodDescriptions) {
      let currentDescription = moodDescriptions[casemood]
      return currentDescription.map((k)=>{
        if (k[0] == 'p') {
          return  <div style={{display:'flex',justifyContent:'center',margin:15,marginBottom:25}}>
                    <div style={{width:510,fontSize:'16px',lineHeight:'24px'}}>
                      {k[1].map((j)=><span style={{color:moodColors[j[1]]}} dangerouslySetInnerHTML={{__html: j[0]+' '}} />)}
                    </div>
                  </div>
        } else if (k[0] == 'ex') {
          return  <div style={{display:'flex',justifyContent:'center',margin:15,marginBottom:25}}>
                    <div style={{paddingLeft:25,width:510,fontSize:'16px'}}>
                      <div style={{paddingBottom:'6px'}}>
                      {k[1].map((j)=><span style={{color:moodColors[j[1]],fontWeight:'500',lineHeight:'24px'}} dangerouslySetInnerHTML={{__html: j[0].replaceAll('~',' ')}} />)}
                      {k[2].length !== 0 ? <Icon onClick={()=>this.repeatAudio(k[2])} circular style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />:null}
                      </div>
                      {k[3].length > 0 ?
                        <div>
                        {k[3].map((j)=><span style={{color:moodColors[j[1]],fontStyle:'italic'}} dangerouslySetInnerHTML={{__html: (j[0] == '.' || j[0] == '?' ? "" : " ")+j[0]}} />)}
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
        } else if (k[0] == 'exheader') {
          return  <div style={{display:'flex',justifyContent:'center',margin:15,marginBottom:15}}>
                    <div style={{display:'flex',width:510,fontSize:'16px'}}>
                      <span style={{width:25,color:moodColors[k[1][1]]}} dangerouslySetInnerHTML={{__html: k[1][0]}} />
                      <span style={{color:moodColors[k[2][1]],textDecoration:'underline'}} dangerouslySetInnerHTML={{__html: k[2][0]+' '}} />
                    </div>
                  </div>
        }
      })
    }
  }

  returnAllowableEndings = () => {

    let newPostbaseList = []
    this.state.newPbs.map((k)=>{
      newPostbaseList.push(k[0])
    })

    let notAllowedEndings = []

    // console.log(this.state.baseType.slice(-1)[0])

    // console.log(newPostbaseList,(newPostbaseList.includes('+niaq-')))

    if (this.state.baseType.slice(-1)[0] == 'i') {
      notAllowedEndings.push("Participial Mood II")
      notAllowedEndings.push("Contemporative Mood II (alt)")
      notAllowedEndings.push("Contemporative Mood III (alt)")
      notAllowedEndings.push("Contemporative Mood IV (alt)")
      notAllowedEndings.push("Participial Mood II")
    }
    if (newPostbaseList.includes('+niaq-')) {
      notAllowedEndings.push("Participial Mood I")
      notAllowedEndings.push("Participial Mood II")
      notAllowedEndings.push("Participial Mood III")
    }

    return <Button.Group vertical basic fluid>
    {Object.keys(endingProperties[this.state.baseVerbNoun.slice(-1)[0]]).map((p)=>{
      if (!(notAllowedEndings.includes(p))) {
        return  <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.setState({newCaseMood:p, endingRuleChartName:endingProperties[this.state.baseVerbNoun.slice(-1)[0]][p][this.state.baseType.slice(-1)[0]][1], showModal:false,})}}>
                  <div style={{lineHeight:'18px'}}>
                      <div style={{marginBottom:'7px',fontWeight:500,color:'#000000DE'}}>
                      <span>{p.replace(',',', ')}</span>
                      </div>
                      <div style={{marginLeft:'8px',marginBottom:'7px',color:'#737373'}}>
                        <span>{this.processStyledText(endingProperties[this.state.baseVerbNoun.slice(-1)[0]][p][this.state.baseType.slice(-1)[0]][0])}</span>
                        {endingProperties[this.state.baseVerbNoun.slice(-1)[0]][p][this.state.baseType.slice(-1)[0]][1].length > 0 ?
                          <span>{this.processStyledText(' + '+endingProperties[this.state.baseVerbNoun.slice(-1)[0]][p][this.state.baseType.slice(-1)[0]][1])}</span>
                          :
                          null
                        }
                      </div>
                      <div style={{marginLeft:'8px',color:'#000000CC'}}>
                        <span style={{fontStyle:'italic'}}>{this.processStyledText(endingProperties[this.state.baseVerbNoun.slice(-1)[0]][p][this.state.baseType.slice(-1)[0]][2])}</span>
                      </div>
                  </div>
                  <div style={{position:'absolute',right:'2px'}}>
                    <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'26px'}} name='chevron right' />
                  </div>   
                </Button>
      }
    })}
    </Button.Group>
  }

  // moveCursorToEnd() {
  //   var el = document.getElementById('searchinput').selectionStart
  //   // el.focus()
  //   console.log(el)
  //   // var range = el.createTextRange();
  //   // console.log(el, range)
  // }

  searchWord = () => {
    // console.log(fullData, word, tag, this.state)
    let modalType = this.state.modalType
    
    // let endingOptions = verbEndingProperties
    // if (this.state.baseType == 'n') {
    //   endingOptions = nounEndingProperties 
    // }

    return <Modal
            trigger={<span />}
            on='click'
            // position='bottom center'
            // positionFixed
            open={this.state.showModal}
            style={{
              maxWidth:500,
                    top:(window.innerWidth < 480 ? 10 : window.innerHeight/4),
            }}
            // ref={(element)=>{this.subjectMenu[tag]=element;}}
            onOpen={()=>{
              // this.setState({
                // currentlyOpen:tag,
                // overlayOn:true,
                // candidateCall:[],
                // candidateBase:[],
                // candidateDisplay:[],
                // unallowable_next_ids:[],
                // nextTenses:[],               
              // })
            }}
            onClose={()=>{
              this.setState({showModal:false,wordsList:[],searchQuery:''})
            }}
            content={
                    this.state.modalType === 'ending' ?
                    <div>
                      <div style={{fontFamily:customFontFam, color:'#737373', display:'flex',justifyContent:'center',fontSize:'18px',fontStyle:'italic',margin:13,}}>Choose an ending</div>
                                  <span>
                                    <Segment vertical style={{maxHeight:275,overflow: 'auto',padding:0,margin:"4px 7px",borderBottom:'0px solid #e2e2e2'}}>
                                      {this.returnAllowableEndings()}
                                    </Segment>
                                      <div style={{height:'4px'}}>
                                      </div>
                                  </span>

                    </div>
                    :
                    <div style={{fontSize:'16px',paddingTop:8}}>
                                <Input 
                                // icon='search' 
                                // iconPosition='left' 
                                icon={this.state.searchQuery.length > 0 ? <Icon style={{marginRight:'7px'}} name='x' color='grey' onClick={()=>{this.setState({searchQuery:''})}} link /> : null}
                                name='search'     
                                autoComplete='off'
                                autoFocus

                                // placeholder={word}
                                // width='100%'
                                id='searchinput'
                                ref={this.handleRef}
                                style={{width:'100%',padding:'0px 7px',fontSize:'18px'}}
                                value={this.state.searchQuery}
                                onChange={this.onChangeSearch.bind(this,false,null,modalType)}
                            // onClose={()=>{this.setState({searchQuery:'',wordsList:[]})}}
                            />
                                              <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'row',marginTop:'3px'}}>
                                                <Button.Group basic>
                                                  <Button onClick={()=>{this.onChangeSearch(true,this.state.searchQuery.slice(0,document.getElementById('searchinput').selectionStart)+'ġ'+this.state.searchQuery.slice(document.getElementById('searchinput').selectionStart),modalType); this.handleRef.current.focus()}} style={letterbutton}>ġ</Button>
                                                  <Button onClick={()=>{this.onChangeSearch(true,this.state.searchQuery.slice(0,document.getElementById('searchinput').selectionStart)+'ḷ'+this.state.searchQuery.slice(document.getElementById('searchinput').selectionStart),modalType); this.handleRef.current.focus()}} style={letterbutton}>ḷ</Button>
                                                  <Button onClick={()=>{this.onChangeSearch(true,this.state.searchQuery.slice(0,document.getElementById('searchinput').selectionStart)+'ł'+this.state.searchQuery.slice(document.getElementById('searchinput').selectionStart),modalType); this.handleRef.current.focus()}} style={letterbutton}>ł</Button>
                                                  <Button onClick={()=>{this.onChangeSearch(true,this.state.searchQuery.slice(0,document.getElementById('searchinput').selectionStart)+'ł̣'+this.state.searchQuery.slice(document.getElementById('searchinput').selectionStart),modalType); this.handleRef.current.focus()}} style={letterbutton}>ł̣</Button>
                                                  <Button onClick={()=>{this.onChangeSearch(true,this.state.searchQuery.slice(0,document.getElementById('searchinput').selectionStart)+'ñ'+this.state.searchQuery.slice(document.getElementById('searchinput').selectionStart),modalType); this.handleRef.current.focus()}} style={letterbutton}>ñ</Button>
                                                  <Button onClick={()=>{this.onChangeSearch(true,this.state.searchQuery.slice(0,document.getElementById('searchinput').selectionStart)+'ŋ'+this.state.searchQuery.slice(document.getElementById('searchinput').selectionStart),modalType); this.handleRef.current.focus()}} style={letterbutton}>ŋ</Button>
                                                </Button.Group>
                                              </div>
                                {this.state.wordsList.length > 0 ?
                                  <span>
                                <Segment vertical style={{maxHeight:220,overflow: 'auto',padding:0,margin:"4px 7px",borderBottom:'0px solid #e2e2e2'}}>
                                <Button.Group vertical basic fluid>
                                  {this.state.wordsList.map((k,index)=>{
                                      let newKey = []
                                      let thisDefinition = ''
                                      let word = ''
                                      let tag = ''
                                      let firsttwotag = ''
                                      let lasttwotag = ''
                                      if (this.state.modalType === 'base') {
                                        newKey = [k['url'],k['num'],0]
                                        thisDefinition = k.thisDefinition
                                        word = k['differentKeySpellings'].map(i => i+(k.tag != 'n' ? '-':'')).join(', ').toLowerCase()
                                        tag = k.tag
                                      } else if (this.state.modalType === 'postbase' || this.state.modalType === 'enclitics') {
                                        newKey = [k['k0'],k['k1'],k['k2']]
                                        thisDefinition = k['d']
                                        word = k['k0']
                                        tag = k['t1']
                                        firsttwotag = tag.slice(0,2)
                                        lasttwotag = tag.slice(2)
                                      }
                                      // console.log(k)

                                      return <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.clickSearchEntry(newKey,tag,thisDefinition,this.state.modalType)}}>
                                                <div>
                                                    <div style={{marginBottom:'6px',fontWeight:500,color:'#000000DE'}}>
                                                    <span>{word.replaceAll(',',', ')}</span>
                                                    </div>
                                                    <div style={{marginLeft:'8px',color:'#000000CC'}}>
                                                    {this.state.modalType === 'postbase' && (firsttwotag == 'n-' || firsttwotag == 'v-') && lasttwotag == 'vT' && !thisDefinition.includes('(t)') ? <span style={{fontWeight:400}}>{'(t) '}</span> : null}
                                                    {this.state.modalType === 'postbase' && (firsttwotag == 'n-' || firsttwotag == 'v-') && lasttwotag == 'vI' && !thisDefinition.includes('(i)') ? <span style={{fontWeight:400}}>{'(i) '}</span> : null}
                                                    {this.processStyledText(thisDefinition)}
                                                    </div>
                                                </div>
                                                <div style={{position:'absolute',right:'2px'}}>
                                                  <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'9px'}} name='chevron right' />
                                                </div>   
                                              </Button>
                                  })}
                                </Button.Group>
                                </Segment>
                                      <div style={{height:'4px'}}>
                                </div>
                              </span>
                              :
                              (this.state.searchQuery.length > 0 ?
                                  <div style={{fontStyle:'italic',color:'#737373',margin:"14px 18px"}}>{'No results...'}</div>
                                  :
                                  <span>
                                    <Segment vertical style={{maxHeight:220,overflow: 'auto',padding:0,margin:"4px 7px",borderBottom:'0px solid #e2e2e2'}}>
                                      <Button.Group vertical basic fluid>
                                      {this.popularSearchKeys().map((p)=>{
                                          let entry = p[1] //this.props.usagedictionary_dict[p[0]][p[1]]
                                          let tag = p[2]//'v'
                                          let url = p[3]
                                          let thisDefinition = p[4]
                                          let firsttwotag = tag.slice(0,2)
                                          let lasttwotag = tag.slice(2)
                                            // let newKey = []
                                            // let thisDefinition = ''
                                            // let url = ''
                                            // let tag = ''
                                            // if (this.state.modalType === 'base') {
                                            //   newKey = [k['url'],k['num'],0]
                                            //   thisDefinition = k.thisDefinition
                                            //   url = k.url
                                            //   tag = k.tag
                                            // } else if (this.state.modalType === 'postbase') {
                                            //   newKey = [k['k0'],k['k1'],k['k2']]
                                            //   thisDefinition = k['d']
                                            //   url = k['k0']
                                            //   tag = k['t1']
                                            // }

                                              return <Button style={{textAlign:'left',fontFamily:customFontFam,padding:'10px',fontSize:'16px'}} onClick={()=>{this.clickSearchEntry(p[0],p[2],thisDefinition,this.state.modalType)}}>
                                                   
                                                      <div>
                                                          <div style={{marginBottom:'6px',fontWeight:500,color:'#000000DE'}}>
                                                          <span>{url.replaceAll(',',', ')}</span>
                                                          </div>
                                                          <div style={{marginLeft:'8px',color:'#000000CC'}}>
                                                    {this.state.modalType === 'postbase' && (firsttwotag == 'n-' || firsttwotag == 'v-') && lasttwotag == 'vT' && !thisDefinition.includes('(t)') ? <span style={{fontWeight:400}}>{'(t) '}</span> : null}
                                                    {this.state.modalType === 'postbase' && (firsttwotag == 'n-' || firsttwotag == 'v-') && lasttwotag == 'vI' && !thisDefinition.includes('(i)') ? <span style={{fontWeight:400}}>{'(i) '}</span> : null}
                                                          {this.processStyledText(thisDefinition)}
                                                          </div>
                                                      </div>
                                                        <div style={{position:'absolute',right:'2px'}}>
                                                          <Icon style={{color:'#a3a3a3',fontSize:'18px',paddingTop:'9px'}} name='chevron right' />
                                                        </div>   
                                               </Button>
                                      })}
                                      </Button.Group>
                                    </Segment>
                                      <div style={{height:'4px'}}>
                                      </div>
                                  </span>
                                  )

                            }

                    </div>


              }
          />
  }

  returnInupiaqWord = (l,fontWeight,pindex) => {
    return (this.state.endingTable[l][0].length == 1 && this.state.endingTable[l][1].length == 0 ?
      <Table.Cell style={{fontSize:16, fontWeight:fontWeight,borderRight:((pindex+1)%3===0 ? '1px solid rgba(34,36,38,.1)':'')}}>
        {this.state.endingTable[l][0][0].map((k)=>
          <span>
            <span style={{color:(this.state.useColor ? newColorsList[k[1]] : 'black')}}>{k[0]}</span>                       
          </span>
        )}
        {l in this.state.outAudioTable ?
          (this.state.outAudioTable[l][0][0].length > 0 ?
            <Icon onClick={()=>this.repeatAudio(this.state.outAudioTable[l][0][0])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
            :
            null
            )
          :
          null
        }
      </Table.Cell>
      :
      <Table.Cell style={{fontSize:16, fontWeight:fontWeight,borderRight:((pindex+1)%3===0 ? '1px solid rgba(34,36,38,.1)':'')}}>
      <Popup hideOnScroll on='click' position='bottom' content={
        <div style={{fontFamily:customFontFam,}}>
          <div style={{fontSize:'16px',fontStyle:'italic',color:'#777777'}}>
          {'Alternates'}
          </div>
          
          {this.state.endingTable[l][0].length > 1 ?
            (this.state.endingTable[l][0].slice(1).map((n,m)=>
              <div style={{marginTop:'10px'}}>
                <span style={{fontSize:16, fontWeight:fontWeight}}>
                {this.state.endingTable[l][0].slice(1)[m].map((k)=><span style={{color:(this.state.useColor ? newColorsList[k[1]] : 'black')}}>{k[0]}</span>)}
                {l in this.state.outAudioTable ?
                  (this.state.outAudioTable[l][0].slice(1)[m].length > 0 ?
                    <Icon onClick={()=>this.repeatAudio(this.state.outAudioTable[l][0].slice(1)[m])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                    :
                    null
                    )
                  :
                  null
                }
                </span>
              </div>
            ))
            :
            null
          }
          {this.state.endingTable[l][1].length > 0 ?
            (this.state.endingTable[l][1].map((n,m)=>
              <div style={{marginTop:'10px'}}>
                <span style={{fontSize:16, fontWeight:fontWeight}}>
                {this.state.endingTable[l][1][m].map((k)=><span style={{color:(this.state.useColor ? newColorsList[k[1]] : 'black')}}>{k[0]}</span>)}
                {l in this.state.outAudioTable ?
                  (this.state.outAudioTable[l][1][m].length > 0 ?
                    <Icon onClick={()=>this.repeatAudio(this.state.outAudioTable[l][1][m])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
                    :
                    null
                    )
                  :
                  null
                }
                </span>
              </div>
            ))
            :
            null
          }

        </div>
      } trigger={
            <span style={{cursor:'pointer',paddingBottom:'1px', borderBottom:'2px dotted grey'}}>
            {this.state.endingTable[l][0].length > 0 ?
              (this.state.endingTable[l][0][0].map((k)=>
              <span>
                <span style={{color:(this.state.useColor ? newColorsList[k[1]] : 'black')}}>{k[0]}</span>                       
              </span>
              ))
              :
              null
            }
            </span>
      } />
      {l in this.state.outAudioTable ?
        (this.state.outAudioTable[l][0][0].length > 0 ?
          <Icon onClick={()=>this.repeatAudio(this.state.outAudioTable[l][0][0])} style={{cursor:'pointer',marginLeft:'8px',fontSize:'16px',color:'#4a80b5'}} name='volume up' />
          :
          null
          )
        :
        null
      }
      </Table.Cell>
    )
  }

  render() {
    // console.log(this.state)
    let currentSubjectMarker = subjectmarkersNoReflex 
    let currentObjectMarker = objectmarkersNoReflex 
    let subjectReflexive = false
    let objectReflexive = false
    let matchedToResults = (
      this.state.newCaseMood == this.state.outcaseMood &&
      this.state.newBase == this.state.outbase &&
      this.state.newEnclitics == this.state.outenclitics &&
      this.state.alts == this.state.outalts &&
      this.state.baseType == this.state.outbaseType
   )

    if ('+3RSg+1SgO' in this.state.endingTable || '+Sg+3RSg' in this.state.endingTable) {
      currentSubjectMarker = subjectmarkers
      subjectReflexive = true
    }
    if ('+1Sg+3RSgO' in this.state.endingTable) {
      currentObjectMarker = objectmarkers
      objectReflexive = true
    }

		return (
			<div style={{fontFamily:customFontFam}}>
      {this.state.showModal ?
          this.searchWord()
          :
          null
      }
			<Transition visible={this.state.loaded} animation='fade' duration={300}>
          <Grid textAlign='center'>
          <Grid.Row  style={{height:40,paddingBottom:0}}>
          <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>

			<div style={{display:'flex'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					
	      </div>


			</div>


					<div style={{border:'1px solid #E3E3E3',marginTop:'10px'}}>

					<div style={{marginBottom:25}} className='hierarchymain'>
					<span className='span1'>Maŋŋuum Naggutai</span>
					<span className='span2'>Word Generator</span>
					</div>





            {this.state.showEdnaOptions ?
              <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>

              <Button disabled={this.state.newBase.length > 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({
                newBaseCall:[['niġi-', 0, 0]],
                newEnclitics:[],
                newCaseMood:"Participial Mood I",
                baseVerbNoun:['v'],
                baseType:['i'],
              },()=>{this.newEndingTableCall()})}>niġi- intransitive participial I</Button>

              <Button disabled={this.state.newBase.length > 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({
                newBaseCall:[['niġi-', 1, 0]],
                newEnclitics:[],
                newCaseMood:"Participial Mood I",
                baseVerbNoun:['v'],
                baseType:['t'],
              },()=>{this.newEndingTableCall()})}>niġi- transitive participial I</Button>

              </div>
            :
            <div>
              {window.innerWidth > 800 ?
                <div style={{fontFamily:customFontFam,fontSize:'16px',display:'flex',justifyContent:'center'}}>
                  <div style={{fontFamily:customFontFam,fontSize:'16px',margin:'10px 0px',display:'flex',justifyContent:'center'}}>
                    <div style={{flex:1,alignItems:'center',padding:10}}>
                    {this.state.newBase.length > 0 ?
                      <div style={{justifyContent:'center',alignItems:'center',padding:10}}>
                        <div style={{fontWeight:400,color:(this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                        <Popup hideOnScroll on='click' position='bottom' content={
                          <div style={{fontFamily:customFontFam,}}>
                            Stem
                          </div>
                        } trigger={
                          <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                          Maŋŋuk
                          </span>
                        } />
                        </div>
                        <div style={{color:greytitles,padding:'12px 0px',color:'black',textAlign:'center'}}>
                        {this.state.otherSpellings.length > 0 ?
                          <Dropdown  disabled={this.state.currentlyLoading} style={{minWidth:'60px',padding:'0.7em 1.9em .7em 0.8em',marginBottom:'8px'}} selection text={<div style={{fontWeight:500,minWidth:'60px'}}>{this.processStyledText(this.state.thisSpelling+(this.state.baseVerbNoun[0] === 'v' ? '-':''))}</div>} options={this.state.otherSpellings}>
                                <Dropdown.Menu>            
                                  {Object.keys(this.state.otherSpellings).map((j)=>{
                                  return <Dropdown.Item style={{fontSize:'16px'}} onClick={this.clickSpelling.bind(this,this.state.otherSpellings[j].value)}>
                                            <div style={{fontWeight:500}}>{this.processStyledText(this.state.otherSpellings[j].text+(this.state.baseVerbNoun[0] === 'v' ? '-':''))}</div>
                                        </Dropdown.Item>
                                        })}
                                </Dropdown.Menu>
                          </Dropdown>
                          :
                          <div style={{fontWeight:500,paddingBottom:'8px'}}>{this.processStyledText(this.state.thisSpelling+(this.state.baseVerbNoun[0] === 'v' ? '-':''))}</div>
                        }
                        
                        {this.state.otherDefinitions.length > 0 ?
                          <Dropdown  disabled={this.state.currentlyLoading} style={{minWidth:'80px',padding:'0.7em 1.9em .7em 0.8em'}} selection text={<div style={{minWidth:'80px',fontStyle:'italic',lineHeight:'18px'}}>{this.processStyledText(this.state.thisDefinition)}</div>} options={this.state.otherDefinitions}>
                                <Dropdown.Menu>            
                                  {Object.keys(this.state.otherDefinitions).map((j)=>{
                                  return <Dropdown.Item style={{fontSize:'16px',padding:'0.7em 1.9em .7em 0.8em'}} onClick={this.clickDefinition.bind(this,this.state.otherDefinitions[j].value)}>
                                            <div style={{fontStyle:'italic',lineHeight:'18px'}}>{this.processStyledText(this.state.otherDefinitions[j].text)}</div>
                                        </Dropdown.Item>
                                        })}
                                </Dropdown.Menu>
                          </Dropdown>
                          :
                          <div style={{fontStyle:'italic'}}>{this.processStyledText(this.state.thisDefinition)}</div>
                        }
                        </div>
                        <div style={{color:boldedtitles,padding:'12px',textAlign:'center',fontStyle:'italic'}}>{this.state.newBase.length > 0 && this.state.newPbs.length == 0 && this.state.newCaseMood.length == 0 ? <Icon circular style={{color:'grey',cursor:'pointer'}} name='x' onClick={()=>{this.setState({numSpelling:0,numDefinition:-1,newBase:[],newBaseDefinition:'',baseVerbNoun:this.state.baseVerbNoun.slice(0,-1),baseType:this.state.baseType.slice(0,-1)})}} />:null}</div>
                      </div>
                      :
                      <div style={{alignItems:'center',padding:10}}>
                        <div style={{color:boldedtitles,padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                        <Popup hideOnScroll on='click' position='bottom' content={
                          <div style={{fontFamily:customFontFam,}}>
                            Stem
                          </div>
                        } trigger={
                          <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                          Maŋŋuk
                          </span>
                        } />
                        </div>
                        <div style={{paddingBottom:'20px'}}>
                        <div style={{padding:'20px',textAlign:'center'}}><Button disabled={this.state.newBase.length > 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({modalType:'base'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></div>
                        <div style={{padding:'6px',textAlign:'center',fontStyle:'italic'}}><span style={{color:'red',}}>*</span><span style={{color:redboldedtitles,}}>required</span></div>
                        </div>
                      </div>
                    }
                    </div>
                    {this.state.newPbs.length > 0 ?
                      this.state.newPbs.map((k,kindex)=>
                        <div style={{flex:1,alignItems:'center',padding:10}}>
                          <div style={{alignItems:'center',padding:10}}>
                            <div style={{color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Postbase
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Akunniġun
                              </span>
                            } />
                            </div>
                            <div style={{color:greytitles,padding:'12px',color:newColorsList['pb.'+(kindex+1).toString()],textAlign:'center'}}><div style={{fontWeight:500,paddingBottom:'8px'}}>{k[0].replaceAll(',',', ')}</div><div style={{fontStyle:'italic'}}>{this.processStyledText(this.state.newPbsDefinition[kindex])}</div></div>
                            <div style={{color:greytitles,padding:'12px',textAlign:'center',fontStyle:'italic'}}>{this.state.newPbs.length > 0 && this.state.newCaseMood.length == 0 && this.state.newPbs.length == kindex+1 ? <Icon circular style={{color:'grey',cursor:'pointer'}} name='x' onClick={()=>{this.setState({newPbs:this.state.newPbs.slice(0,-1),newPbsDefinition:this.state.newPbsDefinition.slice(0,-1),baseVerbNoun:this.state.baseVerbNoun.slice(0,-1),baseType:this.state.baseType.slice(0,-1)})}} />:null}</div>
                          </div>
                        </div>
                        )
                      :
                      null
                    }
                    {this.state.newPbs.length < 3 ?
                      <div style={{flex:1,alignItems:'center',padding:10}}>
                        <div style={{alignItems:'center',padding:10}}>
                          <div style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Postbase
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Akunniġun
                              </span>
                            } />
                          </div>
                          <div style={{paddingBottom:'20px'}}>
                          {this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ?
                            <div style={{padding:'20px',textAlign:'center'}}><Button basic style={{boxShadow:'0 0 0 .0em #2985ca80 inset'}} onClick={()=>this.setState({highlightingx:true})} icon><Icon style={{fontSize:'13px',color:'#aeafae'}} name='plus'/></Button></div>
                            :
                            <div style={{padding:'20px',textAlign:'center'}}><Button basic onClick={()=>this.setState({modalType:'postbase'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></div>
                          }
                          <div style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'center',fontStyle:'italic'}}>(optional)</div>
                          </div>
                        </div>
                      </div>
                      :
                      null
                    }
                    <div style={{flex:1,alignItems:'center',padding:10}}>
                    {this.state.newCaseMood.length > 0 ?
                      <div style={{alignItems:'center',padding:10}}>
                        <div style={{fontWeight:'400',color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Ending Type
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isut
                              </span>
                            } />
                        </div>
                        <div style={{color:greytitles,padding:'12px',color:newColorsList['v.m'],textAlign:'center'}}><div style={{fontWeight:500,paddingBottom:'8px'}}>{this.processStyledText(endingProperties[this.state.baseVerbNoun.slice(-1)[0]][this.state.newCaseMood][this.state.baseType.slice(-1)[0]][0])}</div><div style={{fontStyle:'italic'}}>{this.processStyledText(endingProperties[this.state.baseVerbNoun.slice(-1)[0]][this.state.newCaseMood][this.state.baseType.slice(-1)[0]][2])}</div></div>
                        <div style={{fontWeight:'bold',color:boldedtitles,padding:'12px',textAlign:'center',fontStyle:'italic'}}>{this.state.newCaseMood.length > 0 && this.state.newEnclitics.length == 0 ? <Icon disabled={this.state.currentlyLoading} circular style={{color:'grey',boxShadow:(this.state.highlightingx ? '0 0 0 .1em #00000066 inset':'0 0 0 .1em rgba(0,0,0,.1) inset'),cursor:(this.state.currentlyLoading ? '' : 'pointer')}} name='x'  onClick={()=>{this.setState({newCaseMood:''})}} />:null}</div>
                      </div>
                      :
                      <div style={{alignItems:'center',padding:10}}>
                        <div style={{color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':boldedtitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Ending Type
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isut
                              </span>
                            } />
                        </div>
                        <div style={{paddingBottom:'20px'}}>
                        <div style={{padding:'20px',textAlign:'center'}}><Button disabled={this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({modalType:'ending'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></div>
                        <div style={{padding:'6px',textAlign:'center',fontStyle:'italic'}}><span style={{color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':'red')}}>*</span><span style={{color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':redboldedtitles)}}>required</span></div>
                        </div>
                      </div>
                    }
                    </div>
                    <div style={{flex:1,alignItems:'center',padding:10}}>
                    {this.state.newEnclitics.length > 0 ?
                      <div style={{alignItems:'center',padding:10}}>
                        <div style={{color:(this.state.newCaseMood.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Enclitic
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isukłiqpiat
                              </span>
                            } />
                        </div>
                        <div style={{color:greytitles,padding:'12px',color:newColorsList['e.1'],textAlign:'center'}}><div style={{fontWeight:500,paddingBottom:'8px'}}>{this.state.newEnclitics[0][0]}</div><div style={{fontStyle:'italic'}}>{this.processStyledText(this.state.newEncliticsDefinition)}</div></div>
                        <div style={{color:greytitles,padding:'12px',textAlign:'center',fontStyle:'italic'}}>{this.state.newEnclitics.length > 0 ? <Icon circular disabled={this.state.currentlyLoading} style={{color:'grey',boxShadow:(this.state.highlightingx ? '0 0 0 .1em #00000066 inset':'0 0 0 .1em rgba(0,0,0,.1) inset'),cursor:(this.state.currentlyLoading ? '' : 'pointer')}} name='x' onClick={()=>{this.setState({newEnclitics:'',newEncliticsDefinition:this.state.newEncliticsDefinition.slice(0,-1)})}} />:null}</div>
                      </div>
                      :
                      <div style={{alignItems:'center',padding:10}}>
                        <div style={{color:(this.state.newCaseMood.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'12px',textAlign:'center',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Enclitic
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isukłiqpiat
                              </span>
                            } />
                        </div>
                        <div style={{paddingBottom:'20px'}}>                        
                        <div style={{padding:'20px',textAlign:'center'}}><Button disabled={this.state.newCaseMood.length == 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({modalType:'enclitics'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></div>
                        <div style={{color:(this.state.newCaseMood.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'center',fontStyle:'italic'}}>(optional)</div>
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                </div>
                :
                <Grid style={{fontFamily:customFontFam,fontSize:'16px',margin:'10px 0px',display:'flex',}}>
                  {this.state.newBase.length > 0 ?
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px'}}>
                      <Grid.Column width={4} style={{fontWeight:'400',color:(this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                        <Popup hideOnScroll on='click' position='bottom' content={
                          <div style={{fontFamily:customFontFam,}}>
                            Stem
                          </div>
                        } trigger={
                          <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                          Maŋŋuk
                          </span>
                        } />
                      </Grid.Column>
                      <Grid.Column width={8} style={{color:greytitles,padding:'0px',color:'black',textAlign:'center'}}>
                        {this.state.otherSpellings.length > 0 ?
                          <div>
                          <Dropdown disabled={this.state.currentlyLoading} style={{minWidth:'60px',padding:'0.7em 1.9em .7em 0.8em',marginBottom:'6px'}} selection text={<div style={{fontWeight:500,minWidth:'60px'}}>{this.processStyledText(this.state.thisSpelling+(this.state.baseVerbNoun[0] === 'v' ? '-':''))}</div>} options={this.state.otherSpellings}>
                                <Dropdown.Menu>            
                                  {Object.keys(this.state.otherSpellings).map((j)=>{
                                  return <Dropdown.Item style={{fontSize:'16px'}} onClick={this.clickSpelling.bind(this,this.state.otherSpellings[j].value)}>
                                            <div style={{fontWeight:500}}>{this.processStyledText(this.state.otherSpellings[j].text+(this.state.baseVerbNoun[0] === 'v' ? '-':''))}</div>
                                        </Dropdown.Item>
                                        })}
                                </Dropdown.Menu>
                          </Dropdown>
                          </div>
                          :
                          <div style={{fontWeight:500,paddingBottom:'8px'}}>{this.processStyledText(this.state.thisSpelling+(this.state.baseVerbNoun[0] === 'v' ? '-':''))}</div>
                        }
                        
                        {this.state.otherDefinitions.length > 0 ?
                          <div>
                          <Dropdown disabled={this.state.currentlyLoading}  style={{minWidth:'80px',padding:'0.7em 1.9em .7em 0.8em'}} selection text={<div style={{minWidth:'80px',fontStyle:'italic',lineHeight:'18px'}}>{this.processStyledText(this.state.thisDefinition)}</div>} options={this.state.otherDefinitions}>
                                <Dropdown.Menu>            
                                  {Object.keys(this.state.otherDefinitions).map((j)=>{
                                  return <Dropdown.Item style={{fontSize:'16px',padding:'0.7em 1.9em .7em 0.8em'}} onClick={this.clickDefinition.bind(this,this.state.otherDefinitions[j].value)}>
                                            <div style={{fontStyle:'italic',lineHeight:'18px'}}>{this.processStyledText(this.state.otherDefinitions[j].text)}</div>
                                        </Dropdown.Item>
                                        })}
                                </Dropdown.Menu>
                          </Dropdown>
                          </div>
                          :
                          <div style={{fontStyle:'italic'}}>{this.processStyledText(this.state.thisDefinition)}</div>
                        }
                      </Grid.Column>

                      {/*<div style={{fontWeight:500,paddingBottom:'8px'}}>{this.state.newBase[0]}</div>*/}
                      {/*<div style={{padding:'3px',fontStyle:'italic'}}>{this.processStyledText(this.state.newBaseDefinition)}</div>*/}

                      <Grid.Column width={4} style={{color:greytitles,padding:'6px',textAlign:'center',fontStyle:'italic'}}>{this.state.newBase.length > 0 && this.state.newPbs.length == 0 && this.state.newCaseMood.length == 0 ? <Icon circular style={{color:'grey',cursor:'pointer'}} name='x' onClick={()=>{this.setState({newBase:[],newBaseDefinition:'',baseVerbNoun:this.state.baseVerbNoun.slice(0,-1),baseType:this.state.baseType.slice(0,-1)})}} />:null}</Grid.Column>
                    </Grid.Row>
                    :
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px',margin:'9px 0px'}}>
                      <Grid.Column width={4} style={{color:boldedtitles,padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                        <Popup hideOnScroll on='click' position='bottom' content={
                          <div style={{fontFamily:customFontFam,}}>
                            Stem
                          </div>
                        } trigger={
                          <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                          Maŋŋuk
                          </span>
                        } />
                      </Grid.Column>
                      <Grid.Column width={8} style={{padding:'0px',textAlign:'center'}}><Button disabled={this.state.newBase.length > 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({modalType:'base'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></Grid.Column>
                      <Grid.Column width={4} style={{color:redboldedtitles,padding:'6px',textAlign:'left',fontStyle:'italic'}}><span style={{color:'red'}}>*</span><span style={{color:redboldedtitles}}>required</span></Grid.Column>
                    </Grid.Row>
                  }
                  {this.state.newPbs.length > 0 ?
                    this.state.newPbs.map((k,kindex)=>
                      <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px'}}>
                        <Grid.Column width={4} style={{color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Postbase
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Akunniġun
                              </span>
                            } />
                        </Grid.Column>
                        <Grid.Column width={8} style={{color:greytitles,padding:'0px',color:newColorsList['pb.'+(kindex+1).toString()],textAlign:'center'}}><div style={{fontWeight:500,paddingBottom:'8px'}}>{k[0]}</div><div style={{padding:'3px',fontStyle:'italic'}}>{this.processStyledText(this.state.newPbsDefinition[kindex])}</div></Grid.Column>
                        <Grid.Column width={4} style={{color:greytitles,padding:'6px',textAlign:'center',fontStyle:'italic'}}>{this.state.newPbs.length > 0 && this.state.newCaseMood.length == 0 && this.state.newPbs.length == kindex+1 ? <Icon circular style={{color:'grey',boxShadow:(this.state.highlightingx ? '0 0 0 .1em #00000066 inset':'0 0 0 .1em rgba(0,0,0,.1) inset'),cursor:'pointer'}} name='x' onClick={()=>{this.setState({newPbs:this.state.newPbs.slice(0,-1),newPbsDefinition:this.state.newPbsDefinition.slice(0,-1),baseVerbNoun:this.state.baseVerbNoun.slice(0,-1),baseType:this.state.baseType.slice(0,-1)})}} />:null}</Grid.Column>
                      </Grid.Row>
                      )
                    :
                    null
                  }
                  {this.state.newPbs.length < 3 ?
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px',margin:'9px 0px'}}>
                      <Grid.Column width={4} style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Postbase
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Akunniġun
                              </span>
                            } />
                      </Grid.Column>
                      {this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ?
                        <Grid.Column width={8} style={{padding:'0px',textAlign:'center'}}><Button basic style={{boxShadow:'0 0 0 .0em #2985ca80 inset'}} onClick={()=>this.setState({highlightingx:true})} icon><Icon style={{fontSize:'13px',color:'#aeafae'}} name='plus'/></Button></Grid.Column>
                        :
                        <Grid.Column width={8} style={{padding:'0px',textAlign:'center'}}><Button basic onClick={()=>this.setState({modalType:'postbase'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></Grid.Column>
                      }
                      <Grid.Column width={4} style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'left',fontStyle:'italic'}}>(optional)</Grid.Column>
                    </Grid.Row>
                    :
                    null
                  }
                  {this.state.newCaseMood.length > 0 ?
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px'}}>
                      <Grid.Column width={4} style={{fontWeight:'400',color:(this.state.newBase.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Ending Type
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isut
                              </span>
                            } />
                      </Grid.Column>
                      <Grid.Column width={8} style={{color:greytitles,padding:'0px',color:newColorsList['v.m'],textAlign:'center'}}><div style={{fontWeight:500,paddingBottom:'8px'}}>{this.processStyledText(endingProperties[this.state.baseVerbNoun.slice(-1)[0]][this.state.newCaseMood][this.state.baseType.slice(-1)[0]][0])}</div><div style={{padding:'3px',fontStyle:'italic'}}>{this.processStyledText(endingProperties[this.state.baseVerbNoun.slice(-1)[0]][this.state.newCaseMood][this.state.baseType.slice(-1)[0]][2])}</div></Grid.Column>
                      <Grid.Column width={4} style={{color:greytitles,padding:'6px',textAlign:'center',fontStyle:'italic'}}>{this.state.newCaseMood.length > 0 && this.state.newEnclitics.length == 0 ? <Icon disabled={this.state.currentlyLoading} circular style={{color:'grey',boxShadow:(this.state.highlightingx ? '0 0 0 .1em #00000066 inset':'0 0 0 .1em rgba(0,0,0,.1) inset'),cursor:(this.state.currentlyLoading ? '' : 'pointer')}} name='x'  onClick={()=>{this.setState({newCaseMood:''})}} />:null}</Grid.Column>
                    </Grid.Row>
                    :
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px',margin:'9px 0px'}}>
                      <Grid.Column width={4} style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':boldedtitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Ending Type
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isut
                              </span>
                            } />
                      </Grid.Column>
                      <Grid.Column width={8} style={{padding:'0px',textAlign:'center'}}><Button disabled={this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({modalType:'ending'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></Grid.Column>
                      <Grid.Column width={4} style={{padding:'6px',textAlign:'left',fontStyle:'italic'}}><span style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':'red')}}>*</span><span style={{color:(this.state.newBase.length == 0 || this.state.newCaseMood.length > 0 || this.state.currentlyLoading ? '#CCCCCC':redboldedtitles)}}>required</span></Grid.Column>
                    </Grid.Row>
                  }
                  {this.state.newEnclitics.length > 0 ?
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px'}}>
                      <Grid.Column width={4} style={{color:(this.state.newCaseMood.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Enclitic
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isukłiqpiat
                              </span>
                            } />
                      </Grid.Column>
                      <Grid.Column width={8} style={{color:greytitles,padding:'0px',color:newColorsList['e.1'],textAlign:'center'}}><div style={{fontWeight:500,paddingBottom:'8px'}}>{this.state.newEnclitics[0][0]}</div><div style={{padding:'3px',fontStyle:'italic'}}>{this.processStyledText(this.state.newEncliticsDefinition)}</div></Grid.Column>
                      <Grid.Column width={4} style={{color:greytitles,padding:'6px',textAlign:'center',fontStyle:'italic'}}>{this.state.newEnclitics.length > 0 ? <Icon circular disabled={this.state.currentlyLoading} style={{color:'grey',boxShadow:(this.state.highlightingx ? '0 0 0 .1em #00000066 inset':'0 0 0 .1em rgba(0,0,0,.1) inset'),cursor:(this.state.currentlyLoading ? '' : 'pointer')}} name='x' onClick={()=>{this.setState({newEncliticsDefinition:'',newEnclitics:this.state.newEnclitics.slice(0,-1)})}} />:null}</Grid.Column>
                    </Grid.Row>
                    :
                    <Grid.Row columns={3} style={{alignItems:'center',padding:'15px 3px',margin:'9px 0px'}}>
                      <Grid.Column width={4} style={{color:(this.state.newCaseMood.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'right',fontStyle:'italic'}}>
                            <Popup hideOnScroll on='click' position='bottom' content={
                              <div style={{fontFamily:customFontFam,}}>
                              Enclitic
                              </div>
                            } trigger={
                              <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>
                              Isukłiqpiat
                              </span>
                            } />
                      </Grid.Column>
                      <Grid.Column width={8} style={{padding:'0px',textAlign:'center'}}><Button disabled={this.state.newCaseMood.length == 0 || this.state.currentlyLoading} basic onClick={()=>this.setState({modalType:'enclitics'},()=>{this.setState({showModal:true})})} icon><Icon style={{fontSize:'13px',color:plusbuttoncolor}} name='plus'/></Button></Grid.Column>
                      <Grid.Column width={4} style={{color:(this.state.newCaseMood.length == 0 || this.state.currentlyLoading ? '#CCCCCC':greytitles),padding:'6px',textAlign:'left',fontStyle:'italic'}}>(optional)</Grid.Column>
                    </Grid.Row>
                  }
                </Grid>
              }


            <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',}}>
              <Button style={{fontFamily:customFontFam, fontSize:'16px'}} disabled={this.state.currentlyLoading} basic onClick={()=>this.setState({newBase:[], newBaseDefinition:'', newPbs:[], newPbsDefinition:[], newCaseMood:'', newEnclitics:[], newEncliticsDefinition:"", baseType:[],baseVerbNoun:[]})}><div style={{fontWeight:500,color:'#585858'}}>Utiġiñ</div><div style={{paddingTop:'8px',fontStyle:'italic',fontWeight:400}}>Reset</div></Button>
              <Button style={{fontFamily:customFontFam, fontSize:'16px', marginLeft:'15px'}} disabled={matchedToResults || this.state.newCaseMood.length == 0 || this.state.currentlyLoading} onClick={()=>this.newEndingTableCall()} primary><div style={{fontWeight:500}}>Naggutit</div><div style={{paddingTop:'8px',fontStyle:'italic',fontWeight:400}}>Generate Table</div></Button>
            </div>

            <Checkbox disabled={this.state.currentlyLoading} checked={this.state.alts} onClick={()=>{if (!this.state.currentlyLoading) {this.setState({alts:!this.state.alts})}}} style={{fontFamily:customFontFam,display:'flex',justifyContent:'center',margin:'30px 10px',fontSize:'16px'}} label='Generate alternates (longer processing time)' />
            </div>
          }

            {!this.state.currentlyLoading ?
            <Transition visible={Object.keys(this.state.endingTable).length > 0 && !this.state.currentlyLoadingAfter} animation='fade' duration={400}>
            {Object.keys(this.state.endingTable).length > 0 ? 
              <div>
                <Divider />
                <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',padding:'10px',fontSize:'16px'}}>
                {true ? 
                  <span style={{paddingBottom:'1px',borderBottom:'1px solid #858585'}}>{this.state.outcaseMood+(this.state.outbaseVerbNoun == 'n' ? ' Nouns' : ' Verbs')}</span>
                  :
                  <Popup hideOnScroll on='click' position='bottom' content={
                    <div style={{fontFamily:customFontFam,}}>
                      Words
                    </div>
                  } trigger={
                    <span style={{cursor:'pointer',paddingBottom:'3px', borderBottom:'2px dotted #f1f1f1'}}>{this.state.outcaseMood+(this.state.outbaseVerbNoun == 'n' ? ' Nouns' : ' Verbs')}</span>
                  } />
                }
                </div>


            <div style={{height:10}} />

            {this.state.outbaseType.slice(-1) == 'i' && Object.keys(this.state.endingTable).length > 0 ?
              <div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>
                <div style={{display:'flex',flexDirection:'row',overflow: 'auto',}}>
                  <Segment style={{margin:0,marginLeft:10,padding:0,border:'none',fontSize:16}}>
                  <Table style={{whiteSpace:'nowrap'}} unstackable basic compact collapsing>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell style={{fontStyle:'italic',fontWeight:(this.state.useColor ? 400:400),color:(this.state.useColor ? '#777777' :'black')}}>Subject:</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {subjectmarkers.map((l,lindex) =>
                      ('+'+l in this.state.endingTable ? 
                      <Table.Row>
                        {this.returnInupiaqWord('+'+l,'400',0)}
                        <Table.Cell style={{display:'flex',alignItems:'center',fontSize:16,fontWeight:(this.state.useColor ? 400:400),fontStyle:'italic',color:(this.state.useColor ? newColorsList[subjectmarkertitles[l][2]] :'black')}}>{this.state.largeWindow ? subjectmarkertitles[l][1] : subjectmarkertitles[l][0]} {subjectmarkertitles[l][2]==4 ? <TagColors tags={'rsub'} width={window.innerWidth} /> : null}</Table.Cell>
                      </Table.Row>
                      :
                      null
                      )
                    )}
                    </Table.Body>
                  </Table>
                  </Segment>
                </div>
              </div>
              :
              null
            }

            {this.state.outbaseType.slice(-1) == 'n' && Object.keys(this.state.endingTable).length > 0  ?
              <div>

                <div style={{display:'flex',justifyContent:'center',flexDirection:'row',marginTop:10}}>
                  <div style={{display:'flex',flexDirection:'row',overflow: 'auto',}}>
                    <Segment style={{margin:0,marginLeft:10,padding:0,border:'none',fontSize:16}}>
                    <Table style={{whiteSpace:'nowrap'}} unstackable basic compact collapsing>
                      <Table.Body>
                      {['+Sg','+Du','+Pl'].map((l,lindex) =>
                        <Table.Row>
                          <Table.Cell style={{display:'flex',alignItems:'center',fontSize:16,fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? newColorsList[nounUnpossessedMarkersTitles[l][2]] :'black')}}>{this.state.largeWindow ? nounUnpossessedMarkersTitles[l][1] : nounUnpossessedMarkersTitles[l][0]}</Table.Cell>
                          {this.returnInupiaqWord(l,'400',0)}
                        </Table.Row>
                      )}
                      </Table.Body>
                    </Table>
                    </Segment>
                  </div>
                </div>
                <div style={{marginTop:33,marginBottom:20,textAlign:'center',fontStyle:'italic',fontSize:'16px',color:'#777777'}}>{'Possessed Endings'}</div>
                <div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>
                  <div style={{display:'flex',flexDirection:'row',overflow: 'auto',}}>
                    <Segment style={{margin:0,marginLeft:10,padding:0,border:'none',fontSize:16}}>
                    <Table style={{whiteSpace:'nowrap',borderRight:'none'}} unstackable basic compact collapsing>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell style={{color:'white'}}>{'*'}</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Header>
                        <Table.Row style={{lineHeight:'23px'}}>
                          <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? '#777777' :'black')}}>Possessor:</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                      {currentSubjectMarker.map((l,lindex) =>
                        <Table.Row>
                          <Table.Cell style={{display:'flex',alignItems:'center',fontSize:16,fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? newColorsList[nounMarkertitles[l][2]] :'black')}}>{this.state.largeWindow ? nounMarkertitles[l][1] : nounMarkertitles[l][0]} {nounMarkertitles[l][2]==4 ? <TagColors tags={'rsub'} width={window.innerWidth} /> : null} </Table.Cell>
                        </Table.Row>
                      )}
                      </Table.Body>
                    </Table>
                    </Segment>
                    <Segment style={{margin:0,marginRight:10,padding:0,border:'none',overflow: 'auto',fontSize:16}}>

                      <Table style={{whiteSpace:'nowrap',}} unstackable basic compact collapsing>
                        <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? '#777777' :'black')}}>Number:</Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                              <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                            <Table.Row style={{lineHeight:'23px'}}>
                                <Table.HeaderCell style={{fontSize:16,fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? '#000000DE' :'black')}}>{'singular (1)'}</Table.HeaderCell>
                                <Table.HeaderCell style={{fontSize:16,fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? '#000000DE' :'black')}}>{'dual (2)'}</Table.HeaderCell>
                                <Table.HeaderCell style={{fontSize:16,fontWeight:(this.state.useColor ? 500:500),color:(this.state.useColor ? '#000000DE' :'black')}}>{'plural (3+)'}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {currentSubjectMarker.map((l,lindex) =>
                          <Table.Row>
                            {['+Sg','+Du','+Pl'].map((p,pindex)=>
                              (p+'+'+l in this.state.endingTable ? 
                                this.returnInupiaqWord(p+'+'+l,'400',0)
                                :
                                <Table.Cell />
                              )
                              )}
                          </Table.Row>
                        )}
                        </Table.Body>
                      </Table>
                    </Segment>
                  </div>
                </div>
              </div>
              :
              null
            }

            {this.state.outbaseType.slice(-1) == 't' && Object.keys(this.state.endingTable).length > 0  ?
  						<div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>
                <div style={{display:'flex',flexDirection:'row',overflow: 'auto',}}>
                  <Segment style={{margin:0,marginLeft:10,padding:0,border:'none',fontSize:16}}>
                  <Table style={{whiteSpace:'nowrap',borderRight:'none'}} unstackable basic compact collapsing>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{color:'white'}}>{'*'}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Header>
                      <Table.Row style={{lineHeight:'23px'}}>
                        <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 400:700),color:(this.state.useColor ? '#777777' :'black')}}>Subject:</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {currentSubjectMarker.map((l,lindex) =>
                      <Table.Row>
                        <Table.Cell style={{display:'flex',alignItems:'center',fontSize:16,fontWeight:(this.state.useColor ? 500:700),color:(this.state.useColor ? newColorsList[subjectmarkertitles[l][2]] :'black')}}>{this.state.largeWindow ? subjectmarkertitles[l][1] : subjectmarkertitles[l][0]} {subjectmarkertitles[l][2]==4 ? <TagColors tags={'rsub'} width={window.innerWidth} /> : null} </Table.Cell>
                      </Table.Row>
                    )}
                    </Table.Body>
                  </Table>
                  </Segment>
                  <Segment style={{margin:0,marginRight:10,padding:0,border:'none',overflow: 'auto',fontSize:16}}>

    			          <Table style={{whiteSpace:'nowrap',}} unstackable basic compact collapsing>
    			            <Table.Header>
                        {objectReflexive ? 
                          <Table.Row>
                            <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 400:700),color:(this.state.useColor ? '#777777' :'black')}}>Object:</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>→</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 400:700),color:(this.state.useColor ? '#777777' :'black')}}>Object:</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                          </Table.Row>
                          :
                          <Table.Row>
                            <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 400:700),color:(this.state.useColor ? '#777777' :'black')}}>Object:</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>→</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell style={{fontWeight:(this.state.useColor ? 400:700),color:(this.state.useColor ? '#777777' :'black')}}>Object:</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                          </Table.Row>
                        }
      			              <Table.Row style={{lineHeight:'23px'}}>
                            {currentObjectMarker.map((k,kindex)=>
                              <Table.HeaderCell style={{borderRight:((kindex+1)%3===0 ? '1px solid rgba(34,36,38,.1)':''), fontSize:16,fontWeight:(this.state.useColor ? 500:700),color:(this.state.useColor ? newColorsList[objectmarkertitles[k][2]] :'black')}}>{this.state.largeWindow ? objectmarkertitles[k][1] : objectmarkertitles[k][0]}  {objectmarkertitles[k][2]==4 ? <TagColors tags={'robj'} width={window.innerWidth} /> : null}</Table.HeaderCell>
                              )}
      			              </Table.Row>
    			            </Table.Header>
    			            <Table.Body>
    			            {currentSubjectMarker.map((l,lindex) =>
    			              <Table.Row>
                          {['Subordinative Mood',"Contemporative Mood I"].includes(this.state.endingRuleChartName) ?
                          (currentObjectMarker.map((p,pindex)=>
                            ('+'+currentObjectMarker[pindex] in this.state.endingTable ? 
                              this.returnInupiaqWord('+'+currentObjectMarker[pindex],'400',pindex)
                              :
                              <Table.Cell style={{color:'white',borderRight:((pindex+1)%3===0 ? '1px solid rgba(34,36,38,.1)':''),fontSize:16}}>{'*'}</Table.Cell>
                            )
                            )
                          )
                          :
                          (currentObjectMarker.map((p,pindex)=>
                            ('+'+l+'+'+currentObjectMarker[pindex] in this.state.endingTable ? 
                              this.returnInupiaqWord('+'+l+'+'+currentObjectMarker[pindex],'400',pindex)
                              :
                              <Table.Cell style={{color:'white',borderRight:((pindex+1)%3===0 ? '1px solid rgba(34,36,38,.1)':''),fontSize:16}}>{'*'}</Table.Cell>
                            )
                            ))
                          }
    			              </Table.Row>
    			            )}
    			            </Table.Body>
    			          </Table>
                  </Segment>
                </div>
  						</div>
              :
              null
            }


            <div style={{height:15}} />
              <div style={{display:'flex',justifyContent:'center',marginBottom:'10px'}}>
                <span style={{fontSize:'14px',color:'#737373',padding:'15px',fontWeight:'400',lineHeight:'23px'}}>
                <span style={{textDecoration:'underline'}}>Disclaimer:</span> {window.innerWidth < 480 ? <span> Table may be easier to view on a computer. </span>:null}Please be aware that sentences may be generated which do not apply to certain contexts.
                </span>
              </div>




                <Checkbox checked={this.state.useColor} onClick={()=>{this.setState({useColor:!this.state.useColor})}} style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',marginBottom:'10px',fontSize:'16px'}} label='See morphology colors' />
                <Checkbox checked={this.state.largeWindow} onClick={()=>{this.setState({largeWindow:!this.state.largeWindow})}} style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',marginBottom:'10px',fontSize:'16px'}} label='See subject and object labels' />


              {this.state.showEdnaOptions ?
                null
                :
                <div style={{textAlign:'center',fontFamily:customFontFam,fontSize:'16px',marginTop:'25px',marginBottom:'30px'}}>
                    <a href={"/"+endingProperties[this.state.outbaseVerbNoun.slice(-1)][this.state.outcaseMood][this.state.outbaseType.slice(-1)][1].replaceAll(" ","_")+".pdf"} target="_blank" ><Button basic>{this.state.outcaseMood in endingProperties[this.state.outbaseVerbNoun.slice(-1)] ? this.processStyledText('See '+endingProperties[this.state.outbaseVerbNoun.slice(-1)][this.state.outcaseMood][this.state.outbaseType.slice(-1)][1]+' Endings') : null} <Icon style={{padding:0,margin:0,paddingLeft:'5px',color:'#000000cc'}}name='external alternate' /></Button></a>
                </div>
              }

              {true ?
                null
                :
                <div style={{display:'flex', justifyContent:'center'}}>
                  <div style={{flexDirection:'column'}}>
                  {this.state.fst.map((j,index)=>
                    (typeof j === 'string' ?
                      <Grid style={{maxWidth:300,margin:'10px 0px',display:'flex',justifyContent:'center'}}>
                        <Grid.Row columns={1} style={{paddingBottom:0}}>
                          <Grid.Column style={{textAlign:'center'}}>
                              <span style={{fontFamily:customFontFam,fontSize:'16px'}}>
                                 <span style={{fontWeight:'400',color:(this.state.useColor ? newColorsList['v.m']:'black')}}>{this.state.outcaseMood in endingProperties[this.state.outbaseVerbNoun.slice(-1)[0]] ? this.processStyledText(this.state.outcaseMood + ' Marker') : null}</span>
                              </span>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                          <Grid.Column>
                            <span style={{height:'28px'}}>
                              <span style={{fontSize:'16px',fontWeight:'500',color:(this.state.useColor ? newColorsList['v.m']:'black'), paddingBottom:'3px', borderBottom:'2px solid '+(this.state.useColor ? newColorsList['v.m']:'black')}}>{this.state.outcaseMood in endingProperties[this.state.outbaseVerbNoun.slice(-1)[0]] ? this.processStyledText(endingProperties[this.state.outbaseVerbNoun.slice(-1)[0]][this.state.outcaseMood][this.state.outbaseType.slice(-1)][0]) : null}</span>
                            </span>
                          </Grid.Column>
                          <Grid.Column style={{padding:0}}>
                              <span style={{fontFamily:customFontFam,fontSize:'16px'}}>
                                 <span style={{fontStyle:'italic',fontWeight:'400',color:(this.state.useColor ? newColorsList['v.m']:'black')}}>{this.state.outcaseMood in endingProperties[this.state.outbaseVerbNoun.slice(-1)[0]] ? this.processStyledText(endingProperties[this.state.outbaseVerbNoun.slice(-1)[0]][this.state.outcaseMood][this.state.outbaseType.slice(-1)][2]) : null}</span>
                              </span>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      :
                      <Grid style={{maxWidth:300,margin:'10px 0px',display:'flex',justifyContent:'center'}}>
                          <Grid.Row columns={2} style={{paddingTop:(index > 0 ? 0 : 15)}}>
                            <Grid.Column>
                              <span style={{height:'28px'}}>
                                {j[0].map((k,kindex)=>{
                                    return <span style={{fontSize:'16px',fontWeight:'500',color:(this.state.useColor ? newColorsList[k[1]]:'black'), paddingBottom:'3px', borderBottom:'2px solid '+(this.state.useColor ? newColorsList[k[1]]:'black')}}>{this.processStyledText(k[0].replace(',',', '))}</span>
                                })}
                              </span>
                            </Grid.Column>
                            <Grid.Column style={{padding:0}}>
                                <span style={{fontFamily:customFontFam,fontSize:'16px'}}>
                                    {j[2][0].map((l,lindex)=>{
                                        return <span style={{fontStyle:'italic',fontWeight:'400',color:(this.state.useColor ? newColorsList[l[1]]:'black')}}>{this.processStyledText(l[0])}</span>
                                    })}
                                    {j[2][2] !== "" ?
                                        <div style={{fontStyle:'italic',fontWeight:'400',color:'#7b7b7b',fontSize:'16px',paddingTop:'10px'}}>
                                            <div>
                                                {j[2][1]}
                                            </div>
                                            <div>
                                                {j[2][2]}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </span>
                            </Grid.Column>
                          </Grid.Row>
                      </Grid>
                    )
                  )}
                  </div>
                </div>
              }


{/*              {this.state.showEdnaOptions ?
                null
                :
                (this.state.outcaseMood in moodDescriptions ?
                  <div style={{textAlign:'center',fontFamily:customFontFam,fontSize:'16px',marginBottom:'25px',marginTop:'20px'}}>
                     <span style={{fontWeight:'bold',color:'#000000DE',textDecoration:'underline'}}>{this.processStyledText(this.state.outcaseMood+' Description')}</span>
                  </div>
                  :
                  null
                )
              }*/}
              <div style={{display:'flex',justifyContent:'center',margin:15,marginBottom:25}}>
                <div style={{width:510,fontSize:'16px',lineHeight:'24px'}}>              
                  <MoodSummaries aud={this.aud} mood={this.state.outcaseMood}/>
                </div>
              </div>
              <div style={{height:'10px'}} />
            </div>
            :
            null
          }
					</Transition>
          :
          <div style={{display:'flex',justifyContent:'center',margin:25}}>
            <Loader inline size='large' active><span style={{fontFamily:customFontFam,fontSize:'16px'}}>Loading</span></Loader>
          </div>
        }

					</div>

			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'16px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>

        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:90,paddingBottom:15,paddingTop:25}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>
            <div style={{textAlign:'center',color:'#737373',paddingBottom:'15px'}}><div style={{paddingBottom:'5px'}}> For a description about Iñupiaq symbols, please read the following introduction. </div> <a style={{textDecoration:'underline',color:'#4a80b5',textAlign:'center'}} href="/Inupiaq-General-Introduction.pdf" target="_blank" ><span style={{fontWeight:'600'}}>Mannisaat Makpiġaat</span> <span>General Introduction</span></a> </div>

				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
			</div>
		);
	}
}
export default EndingTables;