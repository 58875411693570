

export const chineseCharacters = [
["1.","1.mp3","1","1"],
["這","2.mp3","Zhè","this"],
["這是他的名字","3.mp3","zhè shì tā de míngzì","this is his name"],
["這裡有一些蘋果","4.mp3","zhèlǐ yǒuyīxiē píngguǒ","here are some apples"],
["2.","5.mp3","2","2"],
["那","6.mp3","Nà","That"],
["醫院在那裡","7.mp3","yīyuàn zài nàlǐ","the hospital is there"],
["那個人在吃飯","8.mp3","nàgèrén zài chīfàn","that person is eating"],
["3.","9.mp3","3","3"],
["哪","10.mp3","Nǎ","where"],
["你要去哪裡玩?","11.mp3","nǐ yào qù nǎlǐ wán?","where are you going to play"],
["你喜歡哪一個?","12.mp3","Nǐ xǐhuān nǎ yīgè?","Which one do you like?"],
["4.","13.mp3","4","4"],
["是","14.mp3","Shì","yes"],
["他是台灣人嗎?","15.mp3","tā shì táiwān rén ma?","Is he Taiwanese?"],
["這是我的書","16.mp3","Zhè shì wǒ de shū","this is my book"],
["5.","17.mp3","5","5"],
["有","18.mp3","Yǒu","have"],
["桌上有三本書","19.mp3","zhuō shàng yǒusān běnshū","There are three books on the table"],
["那裡有兩隻狗","20.mp3","nàlǐ yǒu liǎng zhī gǒu","there are two dogs"],
["6.","21.mp3","6","6"],
["在","22.mp3","Zài","exist"],
["妹妹在家裡","23.mp3","mèimei zàijiālǐ","sister at home"],
["他在穿鞋子","24.mp3","tā zài chuān xiézi","he is wearing shoes"],
["7.","25.mp3","7","7"],
["沒","26.mp3","Méi","without"],
["桌上沒有東西","27.mp3","zhuō shàng méiyǒu dōngxī","nothing on the table"],
["有沒有問題?","28.mp3","yǒu méiyǒu wèntí?","any questions?"],
["8.","29.mp3","8","8"],
["不","30.mp3","Bù","No"],
["我不喜歡喝酒","31.mp3","wǒ bù xǐhuān hējiǔ","i don't like drinking"],
["他不喝咖啡","32.mp3","tā bù hē kāfēi","he doesn't drink coffee"],
["9.","33.mp3","9","9"],
["的","34.mp3","De","of"],
["漂亮的房子","35.mp3","piàoliang de fángzi","beautiful house"],
["這是他的名字","36.mp3","zhè shì tā de míngzì","this is his name"],
["10.","37.mp3","10","10"],
["嗎","38.mp3","Ma","Is it"],
["你兒子今年十四歲嗎?","39.mp3","nǐ érzi jīnnián shísì suì ma?","Is your son fourteen years old?"],
["你是林老師嗎?","40.mp3","Nǐ shì lín lǎoshī ma?","Are you Mr. Lin?"],
["11.","41.mp3","11","11"],
["什麼","42.mp3","Shénme","What"],
["小張星期天要做什麼?","43.mp3","xiǎozhāng xīngqítiān yào zuò shénme?","What is Xiao Zhang going to do on Sunday?"],
["大明，你在做什麼?","44.mp3","Dàmíng, nǐ zài zuò shénme?","Daming, what are you doing?"],
["12.","45.mp3","12","12"],
["怎麼","46.mp3","Zěnme","how"],
["請問新新醫院怎麼去?","47.mp3","qǐngwèn xīn xīn yīyuàn zěnme qù?","How can I get to Xinxin Hospital?"],
["這個怎麼用?","48.mp3","Zhège zěnme yòng?","how to use this"],
["13.","49.mp3","13","13"],
["怎麼樣","50.mp3","Zěnme yàng","How about it"],
["這本小說怎麼樣?","51.mp3","zhè běn xiǎoshuō zěnme yàng?","How about this novel?"],
["你覺得這支手機怎麼樣?","52.mp3","Nǐ juédé zhè zhī shǒujī zěnme yàng?","What do you think of this phone?"],
["14.","53.mp3","14","14"],
["為什麼","54.mp3","Wèishéme","Why"],
["為什麼你不喜歡她?","55.mp3","wèishéme nǐ bù xǐhuān tā?","why don't you like her"],
["為什麼他不能來?","56.mp3","Wèishéme tā bùnéng lái?","why can't he come"],
["15.","57.mp3","15","15"],
["要","58.mp3","Yào","want"],
["我要喝茶","59.mp3","wǒ yào hē chá","I want tea"],
["星期天我要跟朋友去踢足球","60.mp3","xīngqítiān wǒ yào gēn péngyǒuqù tī zúqiú","I'm going to play football with my friends on Sunday"],
["16.","61.mp3","16","16"],
["請","62.mp3","Qǐng","please"],
["請坐","63.mp3","qǐng zuò","please sit down"],
["張先生請她吃法國菜","64.mp3","zhāng xiānshēng qǐng tā chī fàguó cài","Mr. Zhang invited her to eat French food"],
["17.","65.mp3","17","17"],
["請問","66.mp3","Qǐngwèn","Excuse me"],
["請問你是張先生嗎?","67.mp3","qǐngwèn nǐ shì zhāng xiānshēng ma?","Are you Mr. Zhang?"],
["請問這裡有網路嗎？","68.mp3","Qǐngwèn zhèlǐ yǒu wǎng lù ma?","Is there internet here?"],
["18.","69.mp3","18","18"],
["謝謝","70.mp3","Xièxiè","Thanks"],
["謝謝! 謝謝!","71.mp3","xièxiè! Xièxiè!","thanks, thanks!"],
["真的謝謝你!","72.mp3","Zhēn de xièxiè nǐ!","Thank you so much!"],
["19.","73.mp3","19","19"],
["再見","74.mp3","Zàijiàn","goodbye"],
["再見","75.mp3","zàijiàn","goodbye"],
["台灣人不常說再見","76.mp3","táiwān rén bù cháng shuō zàijiàn","Taiwanese don't often say goodbye"],
["20.","77.mp3","20","20"],
["對不起","78.mp3","Duìbùqǐ","sorry"],
["對不起! 對不起!","79.mp3","duìbùqǐ! Duìbùqǐ!","sorry Sorry!"],
["對不起! 麻煩你了!","80.mp3","Duìbùqǐ! Máfan nǐle!","Sorry! Sorry to trouble you!"],
["21.","81.mp3","21","twenty one."],
["沒關係","82.mp3","Méiguānxì","It doesn't matter"],
["A: 對不起!","83.mp3","A: Duìbùqǐ!","A: Sorry!"],
["B: 沒關係","84.mp3","B: Méiguānxì","B: It's okay"],
["22.","85.mp3","22","twenty two."],
["我","86.mp3","Wǒ","I"],
["我要喝茶","87.mp3","wǒ yào hē chá","I want tea"],
["我愛你","88.mp3","wǒ ài nǐ","I love you"],
["23.","89.mp3","23","twenty three."],
["你","90.mp3","Nǐ","you"],
["你今年要去日本玩嗎?","91.mp3","nǐ jīnnián yào qù rìběn wán ma?","Are you going to Japan this year?"],
["你愛我嗎?","92.mp3","Nǐ ài wǒ ma?","Do you love me?"],
["24.","93.mp3","24","twenty four."],
["妳","94.mp3","Nǎi","you"],
["妳是王美英嗎?","95.mp3","nǎi shì wángměiyīng ma?","Are you Wang Meiying?"],
["妳想喝咖啡還是喝茶?","96.mp3","Nǎi xiǎng hē kāfēi háishì hē chá?","Would you like coffee or tea?"],
["25.","97.mp3","25","25"],
["他","98.mp3","Tā","he"],
["他今年十七歲了","99.mp3","tā jīnnián shíqī suìle","he is seventeen years old"],
["他叫李大同","100.mp3","tā jiào lǐ dàtóng","His name is Li Datong"],
["26.","101.mp3","26","26"],
["她","102.mp3","Tā","she"],
["她覺得考試很難","103.mp3","tā juédé kǎoshì hěn nán","she finds the exam difficult"],
["她在找東西","104.mp3","tā zài zhǎo dōngxī","she is looking for something"],
["27.","105.mp3","27","27"],
["我們","106.mp3","Wǒmen","us"],
["我們是好朋友","107.mp3","wǒmen shì hǎo péngyǒu","we are good friends"],
["星期天我們去看電影吧?","108.mp3","xīngqítiān wǒmen qù kàn diànyǐng ba?","Shall we go to the movies on Sunday?"],
["28.","109.mp3","28","28"],
["你們","110.mp3","Nǐmen","you"],
["你們是同學嗎?","111.mp3","nǐmen shì tóngxué ma?","Are you classmates?"],
["你們會說日文嗎?","112.mp3","Nǐmen huì shuō rìwén ma?","Do you speak Japanese?"],
["29.","113.mp3","29","29"],
["妳們","114.mp3","Nǎimen","you guys"],
["妳們都是學生嗎?","115.mp3","nǎimen dōu shì xuéshēng ma?","Are you all students?"],
["妳們喜歡打籃球嗎?","116.mp3","Nǎimen xǐhuān dǎ lánqiú ma?","Do you like playing basketball?"],
["30.","117.mp3","30","30"],
["他們","118.mp3","Tāmen","them"],
["他們在吃飯","119.mp3","tāmen zài chīfàn","they are eating"],
["他們都不會說英文","120.mp3","tāmen dōu bù huì shuō yīngwén","none of them speak english"],
["31.","121.mp3","31","31"],
["她們","122.mp3","Tāmen","they"],
["她們在睡覺","123.mp3","tāmen zài shuìjiào","they are sleeping"],
["她們是好朋友嗎?","124.mp3","tāmen shì hǎo péngyǒu ma?","Are they good friends?"],
["32.","125.mp3","32","32"],
["您","126.mp3","Nín","you"],
["您好! 歡迎! 歡迎!","127.mp3","nín hǎo! Huānyíng! Huānyíng!","Hello! Welcome! Welcome!"],
["您是新新公司的陳先生嗎?","128.mp3","Nín shì xīn xīn gōngsī de chén xiānshēng ma?","Are you Mr. Chen from Xinxin Company?"],
["33.","129.mp3","33","33"],
["誰","130.mp3","Shuí","who"],
["她是誰? 你的女朋友嗎?","131.mp3","tā shì shuí? Nǐ de nǚ péngyǒu ma?","Who is she? Your girlfriend?"],
["請問你找誰?","132.mp3","Qǐngwèn nǐ zhǎo shuí?","who are you looking for?"],
["34.","133.mp3","34","34"],
["大家","134.mp3","Dàjiā","Everyone"],
["大家好","135.mp3","dàjiā hǎo","Hello everyone"],
["大家都幫她慶祝生日","136.mp3","dàjiā dōu bāng tā qìngzhù shēngrì","Everyone celebrates her birthday"],
["35.","137.mp3","35","35"],
["人","138.mp3","Rén","people"],
["我是台北人，想學英文","139.mp3","wǒ shì táiběi rén, xiǎng xué yīngwén","I am from Taipei and want to learn English"],
["教室裡有一個外國人","140.mp3","jiàoshì lǐ yǒuyīgè wàiguó rén","There is a foreigner in the classroom"],
["36.","141.mp3","36","36"],
["男","142.mp3","Nán","male"],
["你有男朋友嗎?","143.mp3","nǐ yǒu nán péngyǒu ma?","do you have a boyfriend?"],
["那個男生正在賣麵包","144.mp3","Nàgè nánshēng zhèngzài mài miànbāo","that boy is selling bread"],
["37.","145.mp3","37","37"],
["女","146.mp3","Nǚ","female"],
["那個小女孩旁邊有一隻狗","147.mp3","nàgè xiǎo nǚhái pángbiān yǒuyī zhī gǒu","There is a dog next to the little girl"],
["這位女客人買了兩杯果汁","148.mp3","zhè wèi nǚ kèrén mǎile liǎng bēi guǒzhī","The female guest bought two glasses of juice"],
["38.","149.mp3","38","38"],
["名字","150.mp3","Míngzì","name"],
["你叫什麼名字?","151.mp3","nǐ jiào shénme míngzì?","May I have your name?"],
["李天華是他的名字","152.mp3","Lǐtiānhuá shì tā de míngzì","Li Tianhua is his name"],
["39.","153.mp3","39","39"],
["姓","154.mp3","Xìng","surname"],
["A: 您貴姓?","155.mp3","A: Nín guìxìng?","A: What is your last name?"],
["B: 我姓陳","156.mp3","B: Wǒ xìng chén","B: My name is Chen"],
["40.","157.mp3","40","40"],
["叫","158.mp3","Jiào","Call"],
["A: 你的狗叫什麼名字？","159.mp3","A: Nǐ de gǒu jiào shénme míngzì?","A: What's your dog's name?"],
["B: 它叫天天","160.mp3","B: Tā jiào tiāntiān","B: It's called Tiantian"],
["41.","161.mp3","41","41"],
["小姐","162.mp3","Xiǎojiě","Miss"],
["李小姐在那裡!","163.mp3","lǐ xiǎojiě zài nàlǐ!","Miss Li is there!"],
["請問是李小姐嗎?","164.mp3","Qǐngwèn shì lǐ xiǎojiě ma?","Is this Miss Li?"],
["42.","165.mp3","42","42"],
["先生","166.mp3","Xiānshēng","gentlemen"],
["王先生正在買麵包","167.mp3","wáng xiānshēng zhèngzài mǎi miànbāo","Mr. Wang is buying bread"],
["請問是張先生嗎?","168.mp3","qǐngwèn shì zhāng xiānshēng ma?","Is this Mr. Zhang?"],
["43.","169.mp3","43","43"],
["爸爸","170.mp3","Bàba","dad"],
["我爸爸想買手機","171.mp3","wǒ bàba xiǎng mǎi shǒujī","my dad wants to buy a mobile phone"],
["我爸爸是音樂老師","172.mp3","wǒ bàba shì yīnyuè lǎoshī","my dad is a music teacher"],
["44.","173.mp3","44","44"],
["媽媽","174.mp3","Māmā","Mother"],
["媽媽每天六點起床","175.mp3","māmā měitiān liù diǎn qǐchuáng","Mom gets up at six every day"],
["我媽媽是數學老師","176.mp3","wǒ māmā shì shùxué lǎoshī","my mother is a math teacher"],
["45.","177.mp3","45","45"],
["哥哥","178.mp3","Gēgē","elder brother"],
["哥哥今年二十歲，是大學生","179.mp3","gēgē jīnnián èrshí suì, shì dàxuéshēng","My brother is twenty years old and a college student"],
["我跟哥哥一樣高","180.mp3","wǒ gēn gēgē yīyàng gāo","i'm as tall as my brother"],
["46.","181.mp3","46","46"],
["弟弟","182.mp3","Dìdì","younger brother"],
["弟弟很喜歡運動","183.mp3","dìdì hěn xǐhuān yùndòng","brother likes sports"],
["弟弟叫李明華","184.mp3","dìdì jiào lǐmínghuá","My brother is Li Minghua"],
["47.","185.mp3","47","47"],
["姐姐","186.mp3","Jiějiě","elder sister"],
["我有一個哥哥，兩個姐姐","187.mp3","wǒ yǒu yīgè gēgē, liǎng gè jiějiě","I have a brother and two sisters"],
["姐姐的生日是七月十一號","188.mp3","jiějiě de shēngrì shì qī yuè shíyī hào","My sister's birthday is July eleventh"],
["48.","189.mp3","48","48"],
["妹妹","190.mp3","Mèimei","younger sister"],
["妹妹的生日是十二月三十一號","191.mp3","mèimei de shēngrì shì shí'èr yuè sānshíyī hào","sister's birthday is december 31st"],
["很多人幫妹妹慶祝生日","192.mp3","hěnduō rén bāng mèimei qìngzhù shēngrì","Many people help my sister celebrate her birthday"],
["49.","193.mp3","49","49"],
["太太","194.mp3","Tàitài","Mrs"],
["她是我太太","195.mp3","tā shì wǒ tàitài","she is my wife"],
["王太太在醫院工作","196.mp3","wáng tàitài zài yīyuàn gōngzuò","Mrs. Wang works in the hospital"],
["50.","197.mp3","50","50"],
["孩子","198.mp3","Háizi","child"],
["孩子在喝水","199.mp3","háizi zài hē shuǐ","child drinking water"],
["你有幾個孩子?","200.mp3","nǐ yǒu jǐ gè háizi?","how many kids do you have?"],
["51.","201.mp3","51","51"],
["女兒","202.mp3","Nǚ'ér","daughter"],
["我有兩個女兒","203.mp3","wǒ yǒu liǎng gè nǚ'ér","i have two daughters"],
["我女兒在房間休息","204.mp3","wǒ nǚ'ér zài fángjiān xiūxí","my daughter is resting in the room"],
["52.","205.mp3","52","52"],
["兒子","206.mp3","Érzi","son"],
["張太太的兒子是學生嗎?","207.mp3","zhāng tàitài de érzi shì xuéshēng ma?","Is Mrs. Zhang's son a student?"],
["我兒子在日本工作","208.mp3","Wǒ érzi zài rìběn gōngzuò","my son works in japan"],
["53.","209.mp3","53","53"],
["朋友","210.mp3","Péngyǒu","friend"],
["我的朋友這個週末要來找我","211.mp3","wǒ de péngyǒu zhège zhōumò yào lái zhǎo wǒ","my friend is coming to see me this weekend"],
["我常和朋友一起去公園運動","212.mp3","wǒ cháng hé péngyǒu yīqǐ qù gōngyuán yùndòng","I often go to the park to exercise with my friends"],
["54.","213.mp3","54","54"],
["一","1.mp3","Yī","one"],
["今天是一月一號","214.mp3","jīntiān shì yī yuè yī hào","today is january 1st"],
["那裡有一個人","215.mp3","nàlǐ yǒu yīgèrén","there is a person"],
["55.","216.mp3","55","55"],
["二","217.mp3","Èr","two"],
["今天是一月二號","218.mp3","jīntiān shì yī yuè èr hào","today is january 2"],
["二號房間在那裡","219.mp3","èr hào fángjiān zài nàlǐ","room two is there"],
["56.","220.mp3","56","56"],
["三","221.mp3","Sān","three"],
["明天是一月三號","222.mp3","míngtiān shì yī yuè sān hào","tomorrow is january 3rd"],
["這裡有三張照片","223.mp3","zhèlǐ yǒusān zhāng zhàopiàn","here are three photos"],
["57.","224.mp3","57","57"],
["四","225.mp3","Sì","Four"],
["現在四點半","226.mp3","xiànzài sì diǎn bàn","it's half past four"],
["我住在四樓","227.mp3","wǒ zhù zài sì lóu","i live on the fourth floor"],
["58.","228.mp3","58","58"],
["五","229.mp3","Wǔ","five"],
["現在五點五十五分","230.mp3","xiànzài wǔ diǎn wǔshíwǔ fēn","It's five fifty-five"],
["我家有五個人","231.mp3","wǒjiā yǒu wǔ gèrén","There are five people in my family"],
["59.","232.mp3","59","59"],
["六","233.mp3","Liù","six"],
["現在六點五十五分","234.mp3","xiànzài liù diǎn wǔshíwǔ fēn","it's six fifty five"],
["弟弟今年六歲","235.mp3","dìdì jīnnián liù suì","brother is six years old"],
["60.","236.mp3","60","60"],
["七","237.mp3","Qī","seven"],
["現在早上七點十分","238.mp3","xiànzài zǎoshang qī diǎn shí fēn","it's seven ten in the morning"],
["七號公車來了","239.mp3","qī hào gōngchē láile","No. 7 bus is here"],
["61.","240.mp3","61","61"],
["八","241.mp3","Bā","eight"],
["現在早上八點半","242.mp3","xiànzài zǎoshang bā diǎn bàn","it's half past eight in the morning"],
["奶奶今年八十歲","243.mp3","nǎinai jīnnián bāshí suì","Grandma is eighty years old this year"],
["62.","244.mp3","62","62"],
["九","245.mp3","Jiǔ","Nine"],
["現在晚上九點十五分","246.mp3","xiànzài wǎnshàng jiǔ diǎn shíwǔ fēn","it's nine fifteen in the evening"],
["爺爺今年九十歲","247.mp3","yéyé jīnnián jiǔshí suì","Grandpa is ninety years old this year"],
["63.","248.mp3","63","63"],
["十","249.mp3","Shí","ten"],
["現在晚上十點十分","250.mp3","xiànzài wǎnshàng shí diǎn shí fēn","it's ten ten in the evening"],
["十月十號是雙十國慶日","251.mp3","shí yuè shí hào shì shuāng shí guóqìng rì","October 10 is Double Ten National Day"],
["64.","252.mp3","64","64"],
["零","253.mp3","Líng","zero"],
["我喜歡007的電影","254.mp3","wǒ xǐhuān 007 de diànyǐng","i like 007 movies"],
["你知道台北一零一嗎?","255.mp3","nǐ zhīdào táiběi yī líng yī ma?","Do you know Taipei 101?"],
["65.","256.mp3","65","65"],
["兩","257.mp3","Liǎng","two"],
["兩杯熱咖啡，謝謝","258.mp3","liǎng bēi rè kāfēi, xièxiè","two cups of hot coffee, thank you"],
["那裡有兩隻狗","259.mp3","nàlǐ yǒu liǎng zhī gǒu","there are two dogs"],
["66.","260.mp3","66","66"],
["百","261.mp3","Bǎi","Hundred"],
["這個皮包要六百多","262.mp3","zhège píbāo yào liùbǎi duō","This leather bag costs more than 600"],
["這個東西六百五十元","263.mp3","zhège dōngxī liùbǎi wǔshí yuán","This thing is six hundred and fifty yuan"],
["67.","264.mp3","67","67"],
["半","265.mp3","Bàn","Half"],
["現在是早上六點半","266.mp3","xiànzài shì zǎoshang liù diǎn bàn","it's six thirty in the morning"],
["半個小時是三十分鐘","267.mp3","bàn gè xiǎoshí shì sānshí fēnzhōng","half an hour is thirty minutes"],
["68.","268.mp3","68","68"],
["幾","269.mp3","Jǐ","Several"],
["他有幾隻狗？","270.mp3","tā yǒu jǐ zhī gǒu?","How many dogs does he have?"],
["教室裡有幾個人?","271.mp3","Jiàoshì lǐ yǒu jǐ gèrén?","How many people are there in the classroom?"],
["69.","272.mp3","69","69"],
["多少","273.mp3","Duōshǎo","How many"],
["請問這個多少錢?","274.mp3","qǐngwèn zhège duōshǎo qián?","How much is this please?"],
["這家店一杯咖啡賣多少？","275.mp3","Zhè jiā diàn yībēi kāfēi mài duōshǎo?","How much does this shop sell for a cup of coffee?"],
["70.","276.mp3","70","70"],
["第","277.mp3","Dì","No."],
["這是我第一次來台灣","278.mp3","zhè shì wǒ dì yīcì lái táiwān","This is my first time to Taiwan"],
["天華是我第一個男朋友","279.mp3","tiān huá shì wǒ dì yīgè nán péngyǒu","Tianhua is my first boyfriend"],
["71.","280.mp3","71","71"],
["個","281.mp3","Gè","indivual"],
["我的名字有三個字","282.mp3","wǒ de míngzì yǒusān gè zì","my name has three letters"],
["教室有幾個學生?","283.mp3","jiàoshì yǒu jǐ gè xuéshēng?","How many students are there in the classroom?"],
["72.","284.mp3","72","72"],
["歲","285.mp3","Suì","age"],
["你今年幾歲？","286.mp3","nǐ jīnnián jǐ suì?","How old are you?"],
["我快要30歲了","287.mp3","Wǒ kuàiyào 30 suìle","I'm going to be 30"],
["73.","288.mp3","73","73"],
["本","289.mp3","Běn","Book"],
["這本書很有趣","290.mp3","zhè běnshū hěn yǒuqù","this book is very interesting"],
["我們老師用這本課本","291.mp3","wǒmen lǎoshī yòng zhè běn kèběn","Our teacher uses this textbook"],
["74.","292.mp3","74","74"],
["張","293.mp3","Zhāng","open"],
["這張桌子太小了","294.mp3","zhè zhāng zhuōzi tài xiǎole","this table is too small"],
["你認識張先生嗎?","295.mp3","nǐ rènshí zhāng xiānshēng ma?","Do you know Mr. Zhang?"],
["75.","296.mp3","75","75"],
["次","297.mp3","Cì","Second-rate"],
["可以再說一次嗎?","298.mp3","kěyǐ zàishuō yīcì ma?","Can I say that again?"],
["可以再寫一次嗎?","299.mp3","Kěyǐ zài xiě yī cì ma?","Can I write it again?"],
["76.","300.mp3","76","76"],
["雙","301.mp3","Shuāng","pair"],
["王小姐買了三雙鞋子","302.mp3","wáng xiǎojiě mǎile sān shuāng xiézi","Miss Wang bought three pairs of shoes"],
["我想買一雙襪子","303.mp3","wǒ xiǎng mǎi yī shuāng wàzi","i want to buy a pair of socks"],
["77.","304.mp3","77","77"],
["件","305.mp3","Jiàn","pieces"],
["這件衣服很好看","306.mp3","zhè jiàn yīfú hěn hǎokàn","this dress is beautiful"],
["小美帶了好幾件衣服","307.mp3","xiǎo měi dàile hǎojǐ jiàn yīfú","Xiaomei brought several clothes"],
["78.","308.mp3","78","78"],
["現在","309.mp3","Xiànzài","Now"],
["現在幾點?","310.mp3","xiànzài jǐ diǎn?","what time is it now?"],
["現在快要八點了","311.mp3","Xiànzài kuàiyào bā diǎnle","It's almost eight o'clock"],
["79.","312.mp3","79","79"],
["今年","313.mp3","Jīnnián","This year"],
["林太太的兒子今年幾歲?","314.mp3","lín tàitài de érzi jīnnián jǐ suì?","How old is Mrs. Lin's son?"],
["你今年要去哪裡玩？","315.mp3","Nǐ jīnnián yào qù nǎlǐ wán?","Where are you going to play this year?"],
["80.","316.mp3","80","80"],
["明年","317.mp3","Míngnián","next year"],
["明年我想去台灣找工作","318.mp3","míngnián wǒ xiǎng qù táiwān zhǎo gōngzuò","Next year I want to go to Taiwan to find a job"],
["明年我十八歲，可以騎摩托車了","319.mp3","míngnián wǒ shíbā suì, kěyǐ qí mótuō chēle","I'll be eighteen next year and I can ride a motorcycle"],
["81.","320.mp3","81","81"],
["去年","321.mp3","Qùnián","last year"],
["去年我十七歲","322.mp3","qùnián wǒ shíqī suì","I was seventeen last year"],
["去年沒有很多颱風","323.mp3","qùnián méiyǒu hěnduō táifēng","There were not many typhoons last year"],
["82.","324.mp3","82","82"],
["今天","325.mp3","Jīntiān","today"],
["今天雨很大，也很冷","326.mp3","jīntiān yǔ hěn dà, yě hěn lěng","It's raining a lot today and it's cold"],
["今天我想在家看電視","327.mp3","jīntiān wǒ xiǎng zàijiā kàn diànshì","I want to watch TV at home today"],
["83.","328.mp3","83","83"],
["明天","329.mp3","Míngtiān","tomorrow"],
["明天你有空嗎?","330.mp3","míngtiān nǐ yǒu kòng ma?","are you free tomorrow?"],
["明天我朋友要來找我","331.mp3","Míngtiān wǒ péngyǒu yào lái zhǎo wǒ","My friend is coming to see me tomorrow"],
["84.","332.mp3","84","84"],
["昨天","333.mp3","Zuótiān","yesterday"],
["昨天是大明生日","334.mp3","zuótiān shì dàmíng shēngrì","Yesterday was Daming's birthday"],
["昨天我去朋友家","335.mp3","zuótiān wǒ qù péngyǒu jiā","I went to my friend's house yesterday"],
["85.","336.mp3","85","85"],
["星期","337.mp3","Xīngqí","Week"],
["今天星期幾?","338.mp3","jīntiān xīngqí jǐ?","What day is it today?"],
["很多人不喜歡星期一","339.mp3","Hěnduō rén bù xǐhuān xīngqí yī","many people don't like mondays"],
["86.","340.mp3","86","86"],
["星期天","341.mp3","Xīngqítiān","Sunday"],
["明華每個星期天都去游泳","342.mp3","míng huá měi gè xīngqítiān dū qù yóuyǒng","Minghua goes swimming every Sunday"],
["上個星期天我在家休息","343.mp3","shàng gè xīngqítiān wǒ zàijiā xiūxí","I rested at home last Sunday"],
["87.","344.mp3","87","87"],
["星期日","345.mp3","Xīngqírì","Sunday"],
["明天是星期日","346.mp3","míngtiān shì xīngqírì","tomorrow is sunday"],
["星期日沒有人去上班","347.mp3","xīngqírì méiyǒu rén qù shàngbān","no one goes to work on sunday"],
["88.","348.mp3","88","88"],
["週末","349.mp3","Zhōumò","weekend"],
["你這個週末要做什麼?","350.mp3","nǐ zhège zhōumò yào zuò shénme?","what are you doing this weekend"],
["週末晚上我常常跟朋友去打球","351.mp3","Zhōumò wǎnshàng wǒ chángcháng gēn péngyǒuqù dǎqiú","I often go to play ball with my friends on weekend nights"],
["89.","352.mp3","89","89"],
["生日","353.mp3","Shēngrì","Birthday"],
["今年生日你想要什麼 ?","354.mp3","jīnnián shēngrì nǐ xiǎng yào shénme?","What do you want for your birthday this year?"],
["她收到很多生日禮物","355.mp3","Tā shōu dào hěnduō shēngrì lǐwù","she received a lot of birthday presents"],
["90.","356.mp3","90","90"],
["年","357.mp3","Nián","Year"],
["今年是龍年","358.mp3","jīnnián shì lóng nián","this year is the year of the dragon"],
["今年三月我朋友會來台北找我","359.mp3","jīnnián sān yuè wǒ péngyǒu huì lái táiběi zhǎo wǒ","This March my friend will come to Taipei to find me"],
["91.","360.mp3","91","91"],
["月","361.mp3","Yuè","moon"],
["A: 你知道美心的生日是幾月幾號嗎?","362.mp3","A: Nǐ zhīdào měi xīn de shēngrì shì jǐ yuè jǐ hào ma?","A: Do you know what month is Meixin's birthday?"],
["B: 三月五號吧?! 是嗎?!","363.mp3","B: Sān yuè wǔ hào ba?! Shì ma?!","B: March 5th?! Really?!"],
["92.","365.mp3","92","92"],
["日","366.mp3","Rì","day"],
["今天幾月幾日 ?","367.mp3","jīntiān jǐ yuè jǐ rì?","What day is it today?"],
["國慶日是十月十日","368.mp3","Guóqìng rì shì shí yuè shí rì","national day is october tenth"],
["93.","369.mp3","93","93"],
["號","370.mp3","Hào","Number"],
["你的手機號碼是幾號?","371.mp3","nǐ de shǒujī hàomǎ shì jǐ hào?","What is your cell phone number?"],
["她在等六十七號公車","372.mp3","Tā zài děng liùshíqī hào gōngchē","She is waiting for the No. 67 bus"],
["94.","373.mp3","94","94"],
["天","374.mp3","Tiān","sky"],
["李先生喝咖啡好幾天了","375.mp3","lǐ xiānshēng hē kāfēi hǎojǐ tiānle","Mr. Li has been drinking coffee for several days"],
["我想去台北幾天","376.mp3","wǒ xiǎng qù táiběi jǐ tiān","I want to go to Taipei for a few days"],
["95.","377.mp3","95","95"],
["早上","378.mp3","Zǎoshang","Morning"],
["每天早上我去跑步跑半個小時","379.mp3","měitiān zǎoshang wǒ qù pǎobù pǎo bàn gè xiǎoshí","Every morning I go for a run for half an hour"],
["妹妹平常早上八點起床","380.mp3","mèimei píngcháng zǎoshang bā diǎn qǐchuáng","My sister usually gets up at eight in the morning"],
["96.","381.mp3","96","96"],
["上午","382.mp3","Shàngwǔ","morning"],
["這家餐廳上午休息","383.mp3","zhè jiā cāntīng shàngwǔ xiūxí","This restaurant is closed in the morning"],
["現在上午十一點半","384.mp3","xiànzài shàngwǔ shíyīdiǎn bàn","it's half past eleven"],
["97.","385.mp3","97","97"],
["中午","386.mp3","Zhōngwǔ","noon"],
["你中午想吃什麼?","387.mp3","nǐ zhōngwǔ xiǎng chī shénme?","what do you want for lunch"],
["這家餐廳中午很多人","388.mp3","Zhè jiā cāntīng zhōngwǔ hěnduō rén","There are many people in this restaurant at noon"],
["98.","389.mp3","98","98"],
["下午","390.mp3","Xiàwǔ","afternoon"],
["我下午三點去機場接你","391.mp3","wǒ xiàwǔ sān diǎn qù jīchǎng jiē nǐ","I will pick you up at the airport at three o'clock in the afternoon"],
["我朋友下午五點會來找我","392.mp3","wǒ péngyǒu xiàwǔ wǔ diǎn huì lái zhǎo wǒ","My friend will come to see me at five o'clock in the afternoon"],
["99.","393.mp3","99","99"],
["晚上","394.mp3","Wǎnshàng","night"],
["晚上要不要吃越南菜?","395.mp3","wǎnshàng yào bùyào chī yuènán cài?","Do you want to eat Vietnamese food tonight?"],
["晚上要不要一起去看電影 ?","396.mp3","Wǎnshàng yào bùyào yīqǐ qù kàn diànyǐng?","Do you want to go to the movies tonight?"],
["100.","397.mp3","100","100"],
["點","398.mp3","Diǎn","point"],
["我平常都晚上十點半睡覺","399.mp3","wǒ píngcháng dōu wǎnshàng shí diǎn bàn shuìjiào","I usually go to bed at ten thirty in the evening"],
["現在快要八點了","400.mp3","xiànzài kuàiyào bā diǎnle","It's almost eight o'clock"],
["101.","a-01.mp3","101","101"],
["分","a-02.mp3","Fēn","point"],
["半個小時是三十分鐘","a-03.mp3","bàn gè xiǎoshí shì sānshí fēnzhōng","half an hour is thirty minutes"],
["現在是十二點零五分","a-04.mp3","xiànzài shì shí'èr diǎn líng wǔ fēn","it's twelve past five"],
["102.","a-08.mp3","102","102"],
["時候","a-09.mp3","Shíhòu","when"],
["你什麼時候有空?","a-10.mp3","nǐ shénme shíhòu yǒu kòng?","when are you free?"],
["我小時候常常來這裡","a-11.mp3","Wǒ xiǎoshíhòu chángcháng lái zhèlǐ","I used to come here when I was a kid"],
["103.","a-15.mp3","103","103"],
["以前","a-16.mp3","Yǐqián","before"],
["小吃店九點以前休息","a-17.mp3","xiǎochī diàn jiǔ diǎn yǐqián xiūxí","The snack bar closes before nine o'clock"],
["以前，這個地方非常多人","a-18.mp3","yǐqián, zhège dìfāng fēicháng duō rén","Before, this place was very crowded"],
["104.","a-22.mp3","104","104"],
["以後","a-23.mp3","Yǐhòu","after"],
["中午以後買票比較貴","a-24.mp3","zhōngwǔ yǐhòu mǎi piào bǐjiào guì","Tickets are more expensive after noon"],
["這家店兩點後休息","a-25.mp3","zhè jiā diàn liǎng diǎn hòu xiūxí","The store closes after two o'clock"],
["105.","a-29.mp3","105","105"],
["先","a-30.mp3","Xiān","First"],
["李先生先去公司，再去郵局","a-31.mp3","lǐ xiānshēng xiān qù gōngsī, zài qù yóujú","Mr. Li goes to the company first, then to the post office"],
["我們先在學校門口見面，再一起去打球","a-32.mp3","wǒmen xiān zài xuéxiào ménkǒu jiànmiàn, zài yīqǐ qù dǎqiú","Let's meet at the school gate first, and then go play together"],
["106.","a-36.mp3","106","106"],
["放","a-37.mp3","Fàng","put"],
["桌子上放著三種水果","a-38.mp3","zhuōzi shàng fàng zháo sānzhǒng shuǐguǒ","three fruits on the table"],
["桌子上放著蘋果、香蕉和西瓜","a-39.mp3","zhuōzi shàng fàngzhe píngguǒ, xiāngjiāo hé xīguā","Apples, bananas and watermelon on the table"],
["107.","a-43.mp3","107","107"],
["這裡","a-44.mp3","Zhèlǐ","here"],
["這裡是桃園機場","a-45.mp3","zhèlǐ shì táoyuán jīchǎng","This is Taoyuan Airport"],
["這裡不可以吃東西","a-46.mp3","zhèlǐ bù kěyǐ chī dōngxī","no food here"],
["108.","a-50.mp3","108","108"],
["這(兒)","a-51.mp3","Zhè (er)","here)"],
["這(兒)是你家嗎?","a-52.mp3","zhè (er) shì nǐ jiā ma?","Is this (child) your home?"],
["這(兒)是我媽媽工作的地方","a-53.mp3","Zhè (er) shì wǒ māmā gōngzuò dì dìfāng","This is where my mother works"],
["109.","a-57.mp3","109","109"],
["那裡","a-58.mp3","Nàlǐ","There"],
["那裡是火車站","a-59.mp3","nàlǐ shì huǒchēzhàn","there is the train station"],
["為什麼警察站在那裡?","a-60.mp3","wèishéme jǐngchá zhàn zài nàlǐ?","Why are the police standing there?"],
["110.","a-64.mp3","110","110"],
["那兒","a-65.mp3","Nà'er","there"],
["那兒是醫院","a-66.mp3","nà'er shì yīyuàn","there is a hospital"],
["那兒是警察局","a-67.mp3","nà'er shì jǐngchá jú","there is the police station"],
["111.","a-71.mp3","111","111"],
["哪裡","a-72.mp3","Nǎlǐ","where"],
["請問廁所在哪裡?","a-73.mp3","qǐngwèn cèsuǒ zài nǎlǐ?","Where is the toilet, please?"],
["我的手機在哪裡?","a-74.mp3","Wǒ de shǒujī zài nǎlǐ?","Where is my cellphone?"],
["112.","a-78.mp3","112","112"],
["哪兒","a-79.mp3","Nǎ'er","where"],
["請問台北車站在哪兒?","a-80.mp3","qǐngwèn táiběi chēzhàn zài nǎ'er?","Where is Taipei Main Station?"],
["哪兒可以買車票?","a-81.mp3","Nǎ'er kěyǐ mǎi chē piào?","Where can I buy a bus ticket?"],
["113.","a-85.mp3","113","113"],
["上面","a-86.mp3","Shàngmiàn","above"],
["課本在電腦上面","a-87.mp3","kèběn zài diànnǎo shàngmiàn","textbook on computer"],
["手錶在課本上面","a-88.mp3","shǒubiǎo zài kèběn shàngmiàn","The watch is on the textbook"],
["114.","a-92.mp3","114","114"],
["下面","a-93.mp3","Xiàmiàn","under"],
["A: 幫我看一下! 我的課本是不是在電腦下面?","a-94.mp3","A: Bāng wǒ kàn yīxià! Wǒ de kèběn shì bùshì zài diànnǎo xiàmiàn?","A: Check it out for me! Are my textbooks under the computer?"],
["B: 對! 在電腦下面","a-95.mp3","B: Duì! Zài diànnǎo xiàmiàn","B: Yes! Under the computer"],
["115.","a-99.mp3","115","115"],
["外面","a-100.mp3","Wàimiàn","Outside"],
["A: 現在外面天氣怎麼樣?","a-101.mp3","A: Xiànzài wàimiàn tiānqì zěnme yàng?","A: How is the weather outside now?"],
["B: 外面在下大雨","a-102.mp3","B: Wàimiàn zàixià dàyǔ","B: It's raining heavily outside"],
["116.","a-106.mp3","116","116"],
["前面","a-107.mp3","Qiánmiàn","Front"],
["男孩走在女孩的前面","a-108.mp3","nánhái zǒu zài nǚhái de qiánmiàn","boy walks ahead of girl"],
["火車站在你的前面","a-109.mp3","huǒchē zhàn zài nǐ de qiánmiàn","The train station is in front of you"],
["117.","a-113.mp3","117","117"],
["後面","a-114.mp3","Hòumiàn","later"],
["飯店後面有便利商店","a-115.mp3","fàndiàn hòumiàn yǒu biànlì shāngdiàn","There is a convenience store behind the hotel"],
["小心!有一些人站在車子後面","a-116.mp3","xiǎoxīn! Yǒu yīxiē rén zhàn zài chēzi hòumiàn","Be careful! There are some people standing behind the car"],
["118.","a-120.mp3","118","118"],
["左邊","a-121.mp3","Zuǒbiān","left"],
["飯店就在你的左邊","a-122.mp3","fàndiàn jiù zài nǐ de zuǒbiān","the restaurant is on your left"],
["百貨公司就在你的左邊","a-123.mp3","bǎihuò gōngsī jiù zài nǐ de zuǒbiān","The department store is on your left"],
["119.","a-127.mp3","119","119"],
["右邊","a-128.mp3","Yòubiān","right"],
["便利商店的右邊是郵局","a-129.mp3","biànlì shāngdiàn de yòubiān shì yóujú","To the right of the convenience store is the post office"],
["銀行就在你的右邊","a-130.mp3","yínháng jiù zài nǐ de yòubiān","the bank is on your right"],
["120.","a-134.mp3","120","120"],
["旁邊","a-135.mp3","Pángbiān","beside"],
["我家旁邊有一間超市","a-136.mp3","wǒjiā pángbiān yǒu yī jiàn chāoshì","There is a supermarket next to my house"],
["餐廳旁邊停了幾輛車","a-137.mp3","cāntīng pángbiān tíngle jǐ liàng chē","A few cars parked next to the restaurant"],
["121.","a-141.mp3","121","121"],
["中間","a-142.mp3","Zhōngjiān","middle"],
["圖書館在餐廳和醫院的中間","a-143.mp3","túshū guǎn zài cāntīng hé yīyuàn de zhōngjiān","The library is between the restaurant and the hospital"],
["小孩坐在爸爸媽媽中間","a-144.mp3","xiǎohái zuò zài bàba māmā zhōngjiān","child sitting between parents"],
["122.","a-148.mp3","122","122"],
["附近","a-149.mp3","Fùjìn","nearby"],
["A: 請問這附近有銀行嗎?","a-150.mp3","A: Qǐngwèn zhè fùjìn yǒu yínháng ma?","A: Is there a bank near here?"],
["B: 這附近有兩間銀行","a-151.mp3","B: Zhè fùjìn yǒu liǎng jiān yínháng","B: There are two banks near here"],
["123.","a-155.mp3","123","123"],
["地方","a-156.mp3","Dìfāng","place"],
["這是什麼地方?","a-157.mp3","zhè shì shénme dìfāng?","What is this place?"],
["這個地方叫什麼?","a-158.mp3","Zhège dìfāng jiào shénme?","what is this place called"],
["124.","a-162.mp3","124","124"],
["臺灣/台灣","a-163.mp3","Táiwān/táiwān","Taiwan / Taiwan"],
["下個月我有一個朋友要來台灣玩","a-164.mp3","xià gè yuè wǒ yǒu yī gè péngyǒu yào lái táiwān wán","I have a friend coming to Taiwan next month"],
["台灣有很多山","a-165.mp3","táiwān yǒu hěnduō shān","Taiwan has many mountains"],
["125.","a-169.mp3","125","125"],
["中國","a-170.mp3","Zhōngguó","China"],
["中國非常大","a-171.mp3","zhōngguó fēicháng dà","China is very big"],
["中國有很多熊貓","a-172.mp3","zhōngguó yǒu hěnduō xióngmāo","There are many pandas in China"],
["126.","a-176.mp3","126","126"],
["明年","a-177.mp3","Míngnián","next year"],
["天華希望明年能跟朋友到日本玩","a-178.mp3","tiān huá xīwàng míngnián néng gēn péngyǒu dào rìběn wán","Tianhua hopes to go to Japan with friends next year"],
["希望明年能再去台灣玩","a-179.mp3","xīwàng míngnián néng zài qù táiwān wán","Hope to go to Taiwan again next year"],
["127.","a-183.mp3","127","127"],
["美國","a-184.mp3","Měiguó","USA"],
["很多學生想去美國學英文","a-185.mp3","hěnduō xuéshēng xiǎng qù měiguó xué yīngwén","Many students want to go to America to learn English"],
["很多人愛看美國電影","a-186.mp3","hěnduō rén ài kàn měiguó diànyǐng","Many people like to watch American movies"],
["128.","a-190.mp3","128","128"],
["近","a-191.mp3","Jìn","close"],
["銀行離這裡很近嗎?","a-192.mp3","yínháng lí zhèlǐ hěn jìn ma?","Is the bank near here?"],
["對，非常近","a-193.mp3","Duì, fēicháng jìn","yes very close"],
["129.","a-197.mp3","129","129"],
["遠","a-198.mp3","Yuǎn","Far"],
["他家離學校非常遠","a-199.mp3","tā jiā lí xuéxiào fēicháng yuǎn","His home is very far from school"],
["這裡離機場有一點遠","a-200.mp3","zhèlǐ lí jīchǎng yǒu yīdiǎn yuǎn","It's a bit far from the airport"],
["130.","a-204.mp3","130","130"],
["手","a-205.mp3","Shǒu","hand"],
["爸爸的手很大","a-206.mp3","bàba de shǒu hěn dà","daddy has big hands"],
["我的手非常小","a-207.mp3","wǒ de shǒu fēicháng xiǎo","my hands are very small"],
["131.","a-211.mp3","131","131"],
["身體","a-212.mp3","Shēntǐ","Body"],
["爸爸的身體非常好","a-213.mp3","bàba de shēntǐ fēicháng hǎo","Dad is in good health"],
["希望你身體健康","a-214.mp3","xīwàng nǐ shēntǐ jiànkāng","wishing you good health"],
["132.","a-218.mp3","132","132"],
["眼睛","a-219.mp3","Yǎnjīng","Eye"],
["月美的眼睛好漂亮","a-220.mp3","yuè měide yǎnjīng hǎo piàoliang","Yuemei's eyes are so beautiful"],
["你玩電腦玩太久了，眼睛休息一下!","a-221.mp3","nǐ wán diànnǎo wán tài jiǔle, yǎnjīng xiūxí yīxià!","You have been playing computer games for too long, take a rest for your eyes!"],
["133.","a-225.mp3","133","133"],
["藥","a-226.mp3","Yào","medicine"],
["我去藥局拿藥","a-227.mp3","wǒ qù yào jú ná yào","I go to the pharmacy to get the medicine"],
["醫生說一天要吃三次藥","a-228.mp3","yīshēng shuō yītiān yào chī sāncì yào","The doctor said to take medicine three times a day"],
["134.","a-232.mp3","134","134"],
["大","a-233.mp3","Dà","big"],
["這雙鞋子，有沒有大一號的?","a-234.mp3","zhè shuāng xiézi, yǒu méiyǒu dà yī hào de?","Do these shoes come in a larger size?"],
["那邊有好幾隻大狗","a-235.mp3","Nà biān yǒu hǎo jǐ zhī dà gǒu","there are several big dogs over there"],
["135.","a-239.mp3","135","135"],
["小","a-240.mp3","Xiǎo","Small"],
["這雙鞋子，有沒有小一號的?","a-241.mp3","zhè shuāng xiézi, yǒu méiyǒu xiǎo yī hào de?","Do these shoes come in a smaller size?"],
["小狗正在睡覺","a-242.mp3","Xiǎo gǒu zhèngzài shuìjiào","puppy is sleeping"],
["136.","a-246.mp3","136","136"],
["熱","a-247.mp3","Rè","hot"],
["她覺得很熱","a-248.mp3","tā juédé hěn rè","she feels hot"],
["今天外面太熱了","a-249.mp3","jīntiān wàimiàn tài rèle","it's too hot outside today"],
["137.","a-253.mp3","137","137"],
["冷","a-254.mp3","Lěng","cold"],
["美華覺得今天天氣非常冷","a-255.mp3","měihuá juédé jīntiān tiānqì fēicháng lěng","Meihua thinks the weather is very cold today"],
["今天外面好冷","a-256.mp3","jīntiān wàimiàn hǎo lěng","It's cold outside today"],
["138.","a-260.mp3","138","138"],
["快","a-261.mp3","Kuài","quick"],
["快一點! 快一點!","a-262.mp3","kuài yīdiǎn! Kuài yī diǎn!","hurry up! hurry up!"],
["美華跑步跑得很快","a-263.mp3","Měihuá pǎobù pǎo dé hěn kuài","Meihua runs fast"],
["139.","a-267.mp3","139","139"],
["慢","a-268.mp3","Màn","slow"],
["慢慢來","a-269.mp3","màn man lái","Take it easy"],
["明華跑步跑得很慢","a-270.mp3","míng huá pǎobù pǎo dé hěn màn","Minghua runs very slowly"],
["140.","a-274.mp3","140","140"],
["多","a-275.mp3","Duō","many"],
["這裡車子太多了!","a-276.mp3","zhèlǐ chēzi tài duōle!","There are too many cars here!"],
["桌子上有很多菜","a-277.mp3","Zhuōzi shàng yǒu hěnduō cài","there are many dishes on the table"],
["141.","a-281.mp3","141","141"],
["少","a-282.mp3","Shǎo","few"],
["教室裡有不少學生","a-283.mp3","jiàoshì lǐ yǒu bù shǎo xuéshēng","There are many students in the classroom"],
["桌上的菜很少","a-284.mp3","zhuō shàng de cài hěn shǎo","There are few dishes on the table"],
["142.","a-288.mp3","142","142"],
["新","a-289.mp3","Xīn","new"],
["這是不是你的新手機?","a-290.mp3","zhè shì bùshì nǐ de xīn shǒujī?","Is this your new phone?"],
["他買了非常多新東西","a-291.mp3","Tā mǎile fēicháng duō xīn dōngxī","He bought a lot of new things"],
["143.","a-295.mp3","143","143"],
["長","a-296.mp3","Zhǎng","long"],
["王小美長得怎麼樣?","a-297.mp3","wángxiǎoměi zhǎng dé zěnme yàng?","What does Wang Xiaomei look like?"],
["那個長頭髮的小姐是誰?","a-298.mp3","Nà gè zhǎng tóufà de xiǎojiě shì shuí?","Who is that lady with long hair?"],
["144.","a-302.mp3","144","144"],
["高","a-303.mp3","Gāo","high"],
["你和哥哥一樣高嗎?","a-304.mp3","nǐ hé gēgē yīyàng gāo ma?","Are you as tall as your brother?"],
["哥哥比我高","a-305.mp3","Gēgē bǐ wǒ gāo","brother is taller than me"],
["145.","a-309.mp3","145","145"],
["好看","a-310.mp3","Hǎokàn","nice"],
["A: 這雙運動鞋好看嗎?","a-311.mp3","A: Zhè shuāng yùndòng xié hǎokàn ma?","A: Do these sneakers look good?"],
["B: 好看 !","a-312.mp3","B: Hǎokàn!","B: Looks good!"],
["146.","a-316.mp3","146","146"],
["漂亮","a-317.mp3","Piàoliang","pretty"],
["漂亮的房子","a-318.mp3","piàoliang de fángzi","beautiful house"],
["你的新家好漂亮","a-319.mp3","nǐ de xīnjiā hǎo piàoliang","your new home is so beautiful"],
["147.","a-323.mp3","147","147"],
["一樣","a-324.mp3","Yīyàng","Same"],
["我跟你一樣大","a-325.mp3","wǒ gēn nǐ yīyàng dà","i'm as old as you"],
["這兩種手機一樣貴","a-326.mp3","zhè liǎng zhǒng shǒujī yīyàng guì","Both phones are equally expensive"],
["148.","a-330.mp3","148","148"],
["高興","a-331.mp3","Gāoxìng","Happy"],
["老師很高興","a-332.mp3","lǎoshī hěn gāoxìng","teacher is happy"],
["這三位客人都很高興","a-333.mp3","zhè sān wèi kèrén dōu hěn gāoxìng","All three guests are happy"],
["149.","a-337.mp3","149","149"],
["快樂","a-338.mp3","Kuàilè","hapiness"],
["祝你生日快樂!","a-339.mp3","zhù nǐ shēngrì kuàilè!","Happy birthday to you!"],
["我現在的生活很快樂","a-340.mp3","Wǒ xiànzài de shēnghuó hěn kuàilè","my life is very happy now"],
["150.","a-344.mp3","150","150"],
["有趣","a-345.mp3","Yǒu qù","interesting"],
["A: 美心覺得這次考試怎麼樣?","a-346.mp3","A: Měi xīn juédé zhècì kǎoshì zěnme yàng?","A: What does Maxim like about this exam?"],
["B: 她覺得考試很有趣","a-347.mp3","B: Tā juédé kǎoshì hěn yǒu qù","B: She thinks the exam is interesting"],
["151.","a-351.mp3","151","151"],
["知道","a-352.mp3","Zhīdào","Know"],
["你知道明天是美心的生日嗎?","a-353.mp3","nǐ zhīdào míngtiān shì měi xīn de shēngrì ma?","Did you know that tomorrow is Meixin's birthday?"],
["你知道那個人的名字嗎?","a-354.mp3","Nǐ zhīdào nà gèrén de míngzì ma?","Do you know that person's name?"],
["152.","a-358.mp3","152","152"],
["認識","a-359.mp3","Rènshí","know"],
["A: 你認識他嗎?","a-360.mp3","A: Nǐ rènshí tā ma?","A: Do you know him?"],
["B: 我知道他叫李天明，但是我不","a-361.mp3","B: Wǒ zhīdào tā jiào lǐ tiānmíng, dànshì wǒ bù","B: I know his name is Li Tianming, but I don’t"],
["153.","a-365.mp3","153","153"],
["懂","a-366.mp3","Dǒng","Understand"],
["我聽不太懂","a-367.mp3","wǒ tīng bù tài dǒng","I don't understand"],
["我不懂他在想什麼","a-368.mp3","wǒ bù dǒng tā zài xiǎng shénme","I don't know what he's thinking"],
["154.","a-372.mp3","154","154"],
["想","a-373.mp3","Xiǎng","think"],
["這個週末我想去游泳","a-374.mp3","zhège zhōumò wǒ xiǎng qù yóuyǒng","I want to go swimming this weekend"],
["我想喝熱咖啡","a-375.mp3","wǒ xiǎng hē rè kāfēi","i want hot coffee"],
["155.","a-379.mp3","155","155"],
["要","a-380.mp3","Yào","want"],
["我明天要跟天華去看電影","a-381.mp3","wǒ míngtiān yào gēn tiān huá qù kàn diànyǐng","I'm going to the movies with Tianhua tomorrow"],
["這個包子要二十塊","a-382.mp3","zhège bāozi yào èrshí kuài","This bun costs 20 yuan"],
["156.","a-386.mp3","156","156"],
["覺得","a-387.mp3","Juédé","feel"],
["A: 你覺得英文考試怎麼樣?","a-388.mp3","A: Nǐ juédé yīngwén kǎoshì zěnme yàng?","A: What do you think of the English test?"],
["B: 我覺得不難","a-389.mp3","B: Wǒ juédé bù nán","B: I don't think it's difficult"],
["157.","a-393.mp3","157","157"],
["喜歡","a-394.mp3","Xǐhuān","like"],
["小美非常喜歡喝咖啡","a-395.mp3","xiǎo měi fēicháng xǐhuān hē kāfēi","Xiaomei likes drinking coffee very much"],
["王大同喜歡跟朋友一起看電影","a-396.mp3","wáng dàtóng xǐhuān gēn péngyǒu yīqǐ kàn diànyǐng","Wang Datong likes to watch movies with his friends"],
["158.","a-400.mp3","158","158"],
["愛","a-401.mp3","Ài","like"],
["我愛你","a-402.mp3","wǒ ài nǐ","I love you"],
["大同非常愛打棒球","a-403.mp3","dàtóng fēicháng ài dǎ bàngqiú","Datong likes to play baseball very much"],
["159.","a-407.mp3","159","159"],
["笑","a-408.mp3","Xiào","laugh"],
["不要笑!","a-409.mp3","bùyào xiào!","do not laugh!"],
["老師為什麼笑了 ?","a-410.mp3","Lǎoshī wèishéme xiàole?","Why did the teacher laugh?"],
["160.","a-414.mp3","160","160"],
["意思","a-415.mp3","Yìsi","mean"],
["這位小姐的意思是什麼?","a-416.mp3","zhè wèi xiǎojiě de yìsi shì shénme?","What does this lady mean?"],
["這個字什麼意思?","a-417.mp3","Zhège zì shénme yìsi?","What does this word mean?"],
["161.","a-421.mp3","161","161"],
["做","a-422.mp3","Zuò","Do"],
["他們正在做什麼?","a-423.mp3","tāmen zhèngzài zuò shénme?","what are they doing"],
["那位先生正在做什麼?","a-424.mp3","Nà wèi xiānshēng zhèngzài zuò shénme?","what is that gentleman doing"],
["162.","a-428.mp3","162","162"],
["用","a-429.mp3","Yòng","use"],
["你會用筷子嗎?","a-430.mp3","nǐ huì yòng kuàizi ma?","Can you use chopsticks?"],
["有些人不會用筷子吃飯","a-431.mp3","Yǒuxiē rén bù huì yòng kuàizi chīfàn","Some people don't know how to eat with chopsticks"],
["163.","a-435.mp3","163","163"],
["去","a-436.mp3","Qù","go"],
["大同明天要去美心家玩","a-437.mp3","dàtóng míngtiān yào qù měi xīn jiā wán","Datong is going to play at Maxim's tomorrow"],
["明天我們去餐廳吃飯，好不好?","a-438.mp3","míngtiān wǒmen qù cāntīng chīfàn, hǎobù hǎo?","Let's go to the restaurant for dinner tomorrow, shall we?"],
["164.","a-442.mp3","164","164"],
["來","a-443.mp3","Lái","Come"],
["大同明天要來我家看電影","a-444.mp3","dàtóng míngtiān yào lái wǒjiā kàn diànyǐng","Datong is coming to my house to watch a movie tomorrow"],
["公車快要來了 !","a-445.mp3","gōngchē kuàiyào láile!","The bus is coming soon!"],
["165.","a-449.mp3","165","165"],
["到","a-450.mp3","Dào","arrive"],
["到大同家要多久?","a-451.mp3","dào dàtóng jiā yào duōjiǔ?","How long does it take to get to Datong's house?"],
["我快要到了! 你到了嗎?","a-452.mp3","Wǒ kuàiyào dàole! Nǐ dàole ma?","I'm almost there! Are you there yet?"],
["166.","a-456.mp3","166","166"],
["回","a-457.mp3","Huí","back"],
["天易什麼時候要回國?","a-458.mp3","tiān yì shénme shíhòu yào huíguó?","When is Tianyi going back to China?"],
["明天下午要回台北","a-459.mp3","Míngtiān xiàwǔ yào huí táiběi","I'm going back to Taipei tomorrow afternoon"],
["167.","a-463.mp3","167","167"],
["走","a-464.mp3","Zǒu","Walk"],
["走吧!","a-465.mp3","zǒu ba!","let's go!"],
["他們從學校走出來","a-466.mp3","Tāmen cóng xuéxiào zǒu chūlái","they come out of school"],
["168.","a-470.mp3","168","168"],
["走路","a-471.mp3","Zǒulù","walk"],
["他每天走路去學校","a-472.mp3","tā měitiān zǒulù qù xuéxiào","He walks to school every day"],
["走路去學校要走多久?","a-473.mp3","zǒulù qù xuéxiào yào zǒu duōjiǔ?","How long does it take to walk to school?"],
["169.","a-477.mp3","169","169"],
["跑","a-478.mp3","Pǎo","run"],
["你喜歡跑步嗎?","a-479.mp3","nǐ xǐhuān pǎobù ma?","do you like running"],
["大同他跑步跑得很快","a-480.mp3","Dàtóng tā pǎobù pǎo dé hěn kuài","Datong, he runs very fast"],
["170.","a-484.mp3","170","170"],
["進來","a-485.mp3","Jìnlái","Come in"],
["進來! 進來!","a-486.mp3","jìnlái! Jìnlái!","Come in! Come in!"],
["進來要脫鞋子","a-487.mp3","Jìnlái yào tuō xiézi","come in and take off your shoes"],
["171.","a-491.mp3","171","171"],
["出去","a-492.mp3","Chūqù","go out"],
["哥哥跟他女朋友常常晚上出去吃飯","a-493.mp3","gēgē gēn tā nǚ péngyǒu chángcháng wǎnshàng chūqù chīfàn","My brother and his girlfriend often go out for dinner at night"],
["小天今天晚上想在家，不想出去","a-494.mp3","xiǎo tiān jīntiān wǎnshàng xiǎng zài jiā, bùxiǎng chūqù","Xiaotian wants to be at home tonight and doesn't want to go out"],
["172.","a-498.mp3","172","172"],
["站","a-499.mp3","Zhàn","stand"],
["很多學生站在外面","a-500.mp3","hěnduō xuéshēng zhàn zài wàimiàn","many students standing outside"],
["有一些人站在房子旁邊","a-501.mp3","yǒu yīxiē rén zhàn zài fángzi pángbiān","there are some people standing by the house"],
["173.","a-505.mp3","173","173"],
["坐","a-506.mp3","Zuò","sit"],
["他們坐在咖啡館裡面","a-507.mp3","tāmen zuò zài kāfēi guǎn lǐmiàn","they sat in the cafe"],
["李先生想要走路去還是坐車去?","a-508.mp3","lǐ xiānshēng xiǎng yào zǒulù qù háishì zuòchē qù?","Mr. Li, do you want to go on foot or by car?"],
["174.","a-512.mp3","174","174"],
["說","a-513.mp3","Shuō","explain"],
["你說得太快了","a-514.mp3","nǐ shuō dé tài kuàile","you speak too fast"],
["易天的英文說得很好","a-515.mp3","yì tiān de yīngwén shuō dé hěn hǎo","Yi Tian speaks English very well"],
["175.","a-519.mp3","175","175"],
["說話","a-520.mp3","Shuōhuà","say"],
["這裡不可以說話","a-521.mp3","zhèlǐ bù kěyǐ shuōhuà","no talking here"],
["在圖書館不可以大聲說話","a-522.mp3","zài túshū guǎn bù kěyǐ dà shēng shuōhuà","No talking loudly in the library"],
["176.","a-526.mp3","176","176"],
["聽","a-527.mp3","Tīng","listen"],
["我喜歡聽音樂","a-528.mp3","wǒ xǐhuān tīng yīnyuè","i like listening to music"],
["李先生開車的時候喜歡聽音樂","a-529.mp3","lǐ xiānshēng kāichē de shíhòu xǐhuān tīng yīnyuè","Mr. Li likes to listen to music while driving"],
["177.","a-533.mp3","177","177"],
["問","a-534.mp3","Wèn","ask"],
["學生在問老師問題","a-535.mp3","xuéshēng zài wèn lǎoshī wèntí","student asking teacher question"],
["可以問你一個問題嗎?","a-536.mp3","kěyǐ wèn nǐ yī gè wèntí ma?","May I ask you a question?"],
["178.","a-540.mp3","178","178"],
["讀","a-541.mp3","Dú","read"],
["王小明每天讀中文小說","a-542.mp3","wángxiǎomíng měitiān dú zhōngwén xiǎoshuō","Wang Xiaoming reads Chinese novels every day"],
["很多人在圖書館讀書","a-543.mp3","hěnduō rén zài túshū guǎn dúshū","many people reading in the library"],
["179.","a-547.mp3","179","179"],
["寫","a-548.mp3","Xiě","Write"],
["這張紙上寫什麼?","a-549.mp3","zhè zhāng zhǐshàng xiě shénme?","what is written on this paper"],
["我忘了這個字怎麼寫","a-550.mp3","Wǒ wàngle zhège zì zěnme xiě","I forgot how to write this word"],
["180.","a-554.mp3","180","180"],
["練習","a-555.mp3","Liànxí","practise"],
["老李每天練習打太極拳","a-556.mp3","lǎo lǐ měitiān liànxí dǎ tàijí quán","Lao Li practices Tai Chi every day"],
["大華正在練習寫字","a-557.mp3","dàhuá zhèngzài liànxí xiě zì","Dahua is practicing writing"],
["181.","a-561.mp3","181","181"],
["看","a-562.mp3","Kàn","look"],
["我明天要去看我朋友","a-563.mp3","wǒ míngtiān yào qù kàn wǒ péngyǒu","I'm going to see my friend tomorrow"],
["今天你想看什麼電影?","a-564.mp3","jīntiān nǐ xiǎng kàn shénme diànyǐng?","What movie do you want to watch today?"],
["182.","a-568.mp3","182","182"],
["找","a-569.mp3","Zhǎo","try to find"],
["他在找東西","a-570.mp3","tā zài zhǎo dōngxī","he is looking for something"],
["我的朋友這個週末要來找我","a-571.mp3","wǒ de péngyǒu zhège zhōumò yào lái zhǎo wǒ","my friend is coming to see me this weekend"],
["183.","a-575.mp3","183","183"],
["準備","a-576.mp3","Zhǔnbèi","Prepare"],
["準備好了嗎?","a-577.mp3","zhǔnbèi hǎole ma?","Are you ready?"],
["他們準備下個月搬家","a-578.mp3","Tāmen zhǔnbèi xià gè yuè bānjiā","They are going to move next month"],
["184.","a-582.mp3","184","184"],
["開","a-583.mp3","Kāi","open"],
["可以幫我開窗戶嗎?","a-584.mp3","kěyǐ bāng wǒ kāi chuānghù ma?","Can you open the window for me?"],
["張先生開車的時候喜歡聽音樂","a-585.mp3","Zhāng xiānshēng kāichē de shíhòu xǐhuān tīng yīnyuè","Mr. Zhang likes to listen to music while driving"],
["185.","a-589.mp3","185","185"],
["開始","a-590.mp3","Kāishǐ","start"],
["李先生上個月開始學中文","a-591.mp3","lǐ xiānshēng shàng gè yuè kāishǐ xué zhōngwén","Mr. Li started learning Chinese last month"],
["電影快要開始了","a-592.mp3","diànyǐng kuàiyào kāishǐle","movie is about to start"],
["186.","a-596.mp3","186","186"],
["等","a-597.mp3","Děng","wait"],
["等一下! 等我五分鐘","a-598.mp3","děng yīxià! Děng wǒ wǔ fēnzhōng","wait a minute wait for me five minutes"],
["他在旅館門口等車","a-599.mp3","tā zài lǚguǎn ménkǒu děng chē","He is waiting at the door of the hotel"],
["187.","a-603.mp3","187","187"],
["拿","a-604.mp3","Ná","take"],
["等車的小姐拿著一袋水果","a-605.mp3","děng chē de xiǎojiě názhe yī dài shuǐguǒ","The lady waiting for the bus is holding a bag of fruit"],
["穿裙子的小姐拿著皮包","a-606.mp3","chuān qúnzi de xiǎojiě názhe píbāo","lady in dress holding purse"],
["188.","a-610.mp3","188","188"],
["放","a-611.mp3","Fàng","put"],
["桌子上放著三種水果","a-612.mp3","zhuōzi shàng fàng zháo sānzhǒng shuǐguǒ","three fruits on the table"],
["桌子放在沙發前面","a-613.mp3","zhuōzi fàng zài shāfā qiánmiàn","table in front of sofa"],
["189.","a-617.mp3","189","189"],
["換","a-618.mp3","Huàn","Change"],
["老李想要回家換衣服","a-619.mp3","lǎo lǐ xiǎng yào huí jiā huàn yīfú","Lao Li wants to go home and change clothes"],
["我想要換手機","a-620.mp3","wǒ xiǎng yào huàn shǒujī","i want to change my phone"],
["190.","a-624.mp3","190","190"],
["給","a-625.mp3","Gěi","Give"],
["這個給你!","a-626.mp3","zhège gěi nǐ!","this is for you!"],
["王老師給每個人兩本書","a-627.mp3","Wáng lǎoshī gěi měi gèrén liǎng běnshū","Teacher Wang gave everyone two books"],
["191.","a-631.mp3","191","191"],
["送","a-632.mp3","Sòng","deliver"],
["朋友送給小美很多禮物","a-633.mp3","péngyǒu sòng gěi xiǎo měi hěnduō lǐwù","Friends gave Xiaomei many gifts"],
["我開車送你去車站","a-634.mp3","wǒ kāichē sòng nǐ qù chē zhàn","I will drive you to the station"],
["192.","a-638.mp3","192","192"],
["帶","a-639.mp3","Dài","bring"],
["我帶了衣服","a-640.mp3","wǒ dàile yīfú","i brought clothes"],
["A: 你覺得我可以帶我朋友去哪裡玩? B: 你可以帶他們到天母棒球場","a-641.mp3","A: Nǐ juédé wǒ kěyǐ dài wǒ péngyǒu qù nǎlǐ wán?","A: Where do you think I can take my friends to play?"],
["193.","a-645.mp3","193","193"],
["介紹","a-646.mp3","Jièshào","introduce"],
["可以介紹一下自己嗎?","a-647.mp3","kěyǐ jièshào yīxià zìjǐ ma?","Can you introduce yourself?"],
["我跟你介紹一下我們新的車","a-648.mp3","Wǒ gēn nǐ jièshào yīxià wǒmen xīn de chē","let me introduce our new car to you"],
["194.","a-652.mp3","194","194"],
["參加","a-653.mp3","Cānjiā","join"],
["我下個月要參加中文考試","a-654.mp3","wǒ xià gè yuè yào cānjiā zhōngwén kǎoshì","I will take the Chinese test next month"],
["我也想帶我朋友來參加這個活動","a-655.mp3","wǒ yě xiǎng dài wǒ péngyǒu lái cānjiā zhège huódòng","I also want to bring my friends to this event"],
["195.","a-659.mp3","195","195"],
["生活","a-660.mp3","Shēnghuó","Life"],
["台灣的生活很方便","a-661.mp3","táiwān de shēnghuó hěn fāngbiàn","Life in Taiwan is very convenient"],
["怡君很喜歡她的大學生活","a-662.mp3","yí jūn hěn xǐhuān tā de dàxuéshēnghuó","Yijun likes her college life very much"],
["196.","a-666.mp3","196","196"],
["東西","a-667.mp3","Dōngxī","thing"],
["小文在買東西","a-668.mp3","xiǎo wén zài mǎi dōngxī","Xiaowen is shopping"],
["弟弟在洗東西","a-669.mp3","dìdì zài xǐ dōngxī","Brother is washing things"],
["197.","a-673.mp3","197","197"],
["電話","a-674.mp3","Diànhuà","Telephone"],
["這個是公司的電話","a-675.mp3","zhège shì gōngsī de diànhuà","This is the company's phone number"],
["學校的電話號碼幾號?","a-676.mp3","xuéxiào de diànhuà hàomǎ jǐ hào?","What's the school's phone number?"],
["198.","a-680.mp3","198","198"],
["打電話","a-681.mp3","Dǎ diànhuà","Call up"],
["弟弟每天打電話給媽媽","a-682.mp3","dìdì měitiān dǎ diànhuà gěi māmā","Brother calls mom every day"],
["小文每天晚上打電話跟我聊天","a-683.mp3","xiǎo wén měitiān wǎnshàng dǎ diànhuà gēn wǒ liáotiān","Xiaowen calls and chats with me every night"],
["199.","a-687.mp3","199","199"],
["電視","a-688.mp3","Diànshì","television"],
["我很少看電視","a-689.mp3","wǒ hěn shǎokàn diànshì","i rarely watch tv"],
["你打電話給我的時候，我正在看電視","a-690.mp3","nǐ dǎ diànhuà gěi wǒ de shíhòu, wǒ zhèngzài kàn diànshì","I was watching tv when you called me"],
["200.","a-694.mp3","200","200"],
["電腦","a-695.mp3","Diànnǎo","computer"],
["圖書館有很多電腦","a-696.mp3","túshū guǎn yǒu hěnduō diànnǎo","There are many computers in the library"],
["A: 學生們怎麼上課? B: 他們用電腦上課","a-697.mp3","A: Xuéshēngmen zěnme shàngkè? B: Tāmen yòng diànnǎo shàngkè","A: How do the students have class? B: They use computers for class"],
["201.","b-01.mp3","201","201"],
["手機","b-02.mp3","Shǒujī","cell phone"],
["我的手機不能上網","b-03.mp3","wǒ de shǒujī bùnéng shàngwǎng","my phone can't access the internet"],
["A: 學生們怎麼上課? B: 他們用手機上課","b-04.mp3","A: Xuéshēngmen zěnme shàngkè? B: Tāmen yòng shǒujī shàngkè","A: How do students attend class? B: They use mobile phones to attend class"],
["202.","b-08.mp3","202","202"],
["號碼","b-09.mp3","Hàomǎ","Number"],
["你的手機號碼幾號?","b-10.mp3","nǐ de shǒujī hàomǎ jǐ hào?","What is your cell phone number?"],
["那個阿姨的手機號碼幾號?","b-11.mp3","Nàgè āyí de shǒujī hàomǎ jǐ hào?","What is that aunt's cell phone number?"],
["203.","b-15.mp3","203","203"],
["衣服","b-16.mp3","Yīfú","clothing"],
["昨天阿姨拿來的那件衣服怎麼樣?","b-17.mp3","zuótiān āyí ná lái dì nà jiàn yīfú zěnme yàng?","How about the dress that aunt brought yesterday?"],
["妹妹在洗衣服","b-18.mp3","Mèimei zài xǐ yīfú","sister doing laundry"],
["204.","b-22.mp3","204","204"],
["鞋子","b-23.mp3","Xiézi","shoe"],
["昨天阿姨拿來的那雙鞋子怎麼樣?","b-24.mp3","zuótiān āyí ná lái dì nà shuāng xiézi zěnme yàng?","How about the pair of shoes that aunt brought yesterday?"],
["弟弟沒有穿鞋子","b-25.mp3","Dìdì méiyǒu chuān xiézi","brother has no shoes"],
["205.","b-29.mp3","205","205"],
["褲子","b-30.mp3","Kùzi","Pants"],
["昨天阿姨拿來的那件褲子怎麼樣?","b-31.mp3","zuótiān āyí ná lái dì nà jiàn kùzi zěnme yàng?","How about the pants my aunt brought yesterday?"],
["美美在百貨公司買了一件褲子","b-32.mp3","Měiměi zài bǎihuò gōngsī mǎile yī jiàn kùzi","Meimei bought a pair of pants in a department store"],
["206.","b-36.mp3","206","206"],
["穿","b-37.mp3","Chuān","wear"],
["李小姐頭髮長長的，穿裙子","b-38.mp3","lǐ xiǎojiě tóufà zhǎng zhǎng de, chuān qúnzi","Miss Li has long hair and wears a skirt"],
["我明天想穿裙子","b-39.mp3","wǒ míngtiān xiǎng chuān qúnzi","i want to wear a skirt tomorrow"],
["207.","b-43.mp3","207","207"],
["紙","b-44.mp3","Zhǐ","Paper"],
["可以給我一張紙嗎？","b-45.mp3","kěyǐ gěi wǒ yī zhāng zhǐ ma?","can i have a piece of paper"],
["紙上寫著什麼?","b-46.mp3","Zhǐshàng xiězhe shénme?","what is written on the paper"],
["208.","b-50.mp3","208","208"],
["信","b-51.mp3","Xìn","letter"],
["美美正在寫信","b-52.mp3","měiměi zhèngzài xiě xìn","Meimei is writing a letter"],
["妹妹看完信以後，心情很好","b-53.mp3","mèimei kàn wán xìn yǐhòu, xīnqíng hěn hǎo","My sister is in a good mood after reading the letter"],
["209.","b-57.mp3","209","209"],
["家","b-58.mp3","Jiā","Home"],
["我家在台北市","b-59.mp3","wǒjiā zài táiběi shì","my home is in taipei"],
["他們的新家好了，正在準備搬家","b-60.mp3","tāmen de xīnjiā hǎole, zhèngzài zhǔnbèi bānjiā","Their new home is ready and ready to move"],
["210.","b-64.mp3","210","210"],
["房子","b-65.mp3","Fángzi","house"],
["房子的前面停了幾輛車","b-66.mp3","fángzi de qiánmiàn tíngle jǐ liàng chē","Several cars parked in front of the house"],
["結婚以後我們想買房子","b-67.mp3","jiéhūn yǐhòu wǒmen xiǎng mǎi fángzi","We want to buy a house after we get married"],
["211.","b-71.mp3","211","211"],
["門","b-72.mp3","Mén","Door"],
["醫院的門口停了幾輛車","b-73.mp3","yīyuàn de ménkǒu tíngle jǐ liàng chē","Several cars parked in front of the hospital"],
["我們可以在學校門口見","b-74.mp3","wǒmen kěyǐ zài xuéxiào ménkǒu jiàn","we can meet at the school gate"],
["212.","b-78.mp3","212","212"],
["客廳","b-79.mp3","Kètīng","living room"],
["妹妹一個人在客廳寫信","b-80.mp3","mèimei yīgèrén zài kètīng xiě xìn","My sister is writing a letter in the living room alone"],
["爸爸在客廳看電視","b-81.mp3","bàba zài kètīng kàn diànshì","Dad is watching TV in the living room"],
["213.","b-85.mp3","213","213"],
["住","b-86.mp3","Zhù","live"],
["你住附近嗎？","b-87.mp3","nǐ zhù fùjìn ma?","Do you live nearby?"],
["我住在學校附近","b-88.mp3","Wǒ zhù zài xuéxiào fùjìn","i live near the school"],
["214.","b-92.mp3","214","214"],
["平常","b-93.mp3","Píngcháng","usually"],
["你平常幾點起床?","b-94.mp3","nǐ píngcháng jǐ diǎn qǐchuáng?","what time do you usually get up"],
["他平常都十點半以前睡覺","b-95.mp3","Tā píngcháng dōu shí diǎn bàn yǐqián shuìjiào","He usually goes to bed before ten thirty"],
["215.","b-99.mp3","215","215"],
["睡覺","b-100.mp3","Shuìjiào","sleep"],
["小明平常都在沙發上睡覺","b-101.mp3","xiǎomíng píngcháng dōu zài shāfā shàng shuìjiào","Xiao Ming usually sleeps on the sofa"],
["小明跟他的狗正在床上睡覺","b-102.mp3","xiǎomíng gēn tā de gǒu zhèngzài chuángshàng shuìjiào","Xiao Ming and his dog are sleeping on the bed"],
["216.","b-106.mp3","216","216"],
["洗","b-107.mp3","Xǐ","wash"],
["他洗車的時候喜歡聽音樂","b-108.mp3","tā xǐchē de shíhòu xǐhuān tīng yīnyuè","He likes to listen to music while washing the car"],
["我幫你洗衣服","b-109.mp3","wǒ bāng nǐ xǐ yīfú","I will help you do the laundry"],
["217.","b-113.mp3","217","217"],
["洗澡","b-114.mp3","Xǐzǎo","bath"],
["我平常都早上洗澡","b-115.mp3","wǒ píngcháng dōu zǎoshang xǐzǎo","I usually take a shower in the morning"],
["弟弟正在幫狗洗澡","b-116.mp3","dìdì zhèngzài bāng gǒu xǐzǎo","Brother is bathing the dog"],
["218.","b-120.mp3","218","218"],
["學校","b-121.mp3","Xuéxiào","School"],
["美美今天沒有來學校","b-122.mp3","měiměi jīntiān méiyǒu lái xuéxiào","Meimei didn't come to school today"],
["好幾個人站在學校門口","b-123.mp3","hǎojǐ gèrén zhàn zài xuéxiào ménkǒu","Several people are standing at the gate of the school"],
["219.","b-127.mp3","219","219"],
["大學","b-128.mp3","Dàxué","University"],
["這間大學很有名","b-129.mp3","zhè jiān dàxué hěn yǒumíng","this university is famous"],
["哥哥是大學生","b-130.mp3","gēgē shì dàxuéshēng","brother is a college student"],
["220.","b-134.mp3","220","220"],
["中學","b-135.mp3","Zhōngxué","middle school"],
["我從中學開始學英文","b-136.mp3","wǒ cóng zhōngxué kāishǐ xué yīngwén","I have learned English since middle school"],
["很多中學生在打籃球","b-137.mp3","hěnduō zhōngxuéshēng zài dǎ lánqiú","Many middle school students are playing basketball"],
["221.","b-141.mp3","221","221"],
["年級","b-142.mp3","Niánjí","grade"],
["天明現在高中三年級","b-143.mp3","tiānmíng xiànzài gāo zhòng sān niánjí","Tianming is now in the third year of high school"],
["妹妹今年小學一年級","b-144.mp3","mèimei jīnnián xiǎoxué yī niánjí","My sister is in first grade this year"],
["222.","b-148.mp3","222","222"],
["老師","b-149.mp3","Lǎoshī","teacher"],
["王老師是音樂老師","b-150.mp3","wáng lǎoshī shì yīnyuè lǎoshī","Teacher Wang is a music teacher"],
["老師正在黑板上寫字","b-151.mp3","lǎoshī zhèngzài hēibǎn shàng xiě zì","The teacher is writing on the blackboard"],
["223.","b-155.mp3","223","223"],
["學生","b-156.mp3","Xuéshēng","student"],
["很多學生在打棒球","b-157.mp3","hěnduō xuéshēng zài dǎ bàngqiú","Many students are playing baseball"],
["很多大學生喜歡騎摩托車","b-158.mp3","hěnduō dàxuéshēng xǐhuān qí mótuō chē","Many college students like to ride motorcycles"],
["224.","b-162.mp3","224","224"],
["學","b-163.mp3","Xué","study"],
["王文書想學吉他","b-164.mp3","wáng wénshū xiǎng xué jítā","Wang Wenshu wants to learn guitar"],
["安同不想學中文字","b-165.mp3","ān tóng bùxiǎng xué zhōngwén zì","An Tong does not want to learn Chinese characters"],
["225.","b-169.mp3","225","225"],
["學習","b-170.mp3","Xuéxí","study"],
["小文非常喜歡學習","b-171.mp3","xiǎo wén fēicháng xǐhuān xuéxí","Xiaowen likes to study very much"],
["學習語言也能學習文化","b-172.mp3","xuéxí yǔyán yě néng xuéxí wénhuà","Learn language and learn culture"],
["226.","b-176.mp3","226","226"],
["上課","b-177.mp3","Shàngkè","Attend class"],
["快要上課了!","b-178.mp3","kuàiyào shàngkèle!","Class is about to begin!"],
["他一星期上一次課","b-179.mp3","Tā yī xīngqí shàng yīcì kè","he has a class once a week"],
["227.","b-183.mp3","227","227"],
["下課","b-184.mp3","Xiàkè","after class"],
["快要下課了!","b-185.mp3","kuàiyào xiàkèle!","get out of class is about to end!"],
["你什麼時候下課?","b-186.mp3","Nǐ shénme shíhòu xiàkè?","when do you finish class"],
["228.","b-190.mp3","228","228"],
["考試","b-191.mp3","Kǎoshì","take an exam"],
["這次的考試很難","b-192.mp3","zhècì de kǎoshì hěn nán","This test is difficult"],
["準備考試很辛苦","b-193.mp3","zhǔnbèi kǎoshì hěn xīnkǔ","Preparing for the exam is hard"],
["229.","b-197.mp3","229","229"],
["問題","b-198.mp3","Wèntí","question"],
["我有一個問題","b-199.mp3","wǒ yǒuyīgè wèntí","I have a question"],
["大家還有問題嗎？","b-200.mp3","dàjiā hái yǒu wèntí ma?","Do you have any questions?"],
["230.","b-204.mp3","230","230"],
["英文","b-205.mp3","Yīngwén","English"],
["她會唱英文歌嗎？","b-206.mp3","tā huì chàng yīngwén gē ma?","Can she sing English songs?"],
["美心會說英文，也會唱英文歌","b-207.mp3","Měi xīn huì shuō yīngwén, yě huì chàng yīngwén gē","Maxim can speak English and sing English songs"],
["231.","b-211.mp3","231","231"],
["數學","b-212.mp3","Shùxué","math"],
["A: 數學難不難?","b-213.mp3","A: Shùxué nàn bù nán?","A: Is math difficult?"],
["B: 我跟我同學都覺得數學很難","b-214.mp3","B: Wǒ gēn wǒ tóngxué dōu juédé shùxué hěn nán","B: Both my classmates and I think math is difficult"],
["232.","b-218.mp3","232","232"],
["歷史","b-219.mp3","Lìshǐ","history"],
["歷史很有趣","b-220.mp3","lìshǐ hěn yǒuqù","history is interesting"],
["陳老師是歷史老師，不是音樂老師","b-221.mp3","chén lǎoshī shì lìshǐ lǎoshī, bùshì yīnyuè lǎoshī","Teacher Chen is a history teacher, not a music teacher"],
["233.","b-225.mp3","233","233"],
["字","b-226.mp3","Zì","Character"],
["我每天寫中文字","b-227.mp3","wǒ měitiān xiě zhōngwén zì","I write Chinese characters every day"],
["月華的字很漂亮","b-228.mp3","yuè huá de zì hěn piàoliang","Yuehua's handwriting is beautiful"],
["234.","b-232.mp3","234","234"],
["筆","b-233.mp3","Bǐ","Pen"],
["A: 桌上有筆嗎?","b-234.mp3","A: Zhuō shàng yǒu bǐ ma?","A: Is there a pen on the table?"],
["B: 有啊，你要哪一隻筆?","b-235.mp3","B: Yǒu a, nǐ yào nǎ yī zhī bǐ?","B: Yes, which pen do you want?"],
["235.","b-239.mp3","235","235"],
["書","b-240.mp3","Shū","Book"],
["這本書介紹台灣的歷史","b-241.mp3","zhè běnshū jièshào táiwān de lìshǐ","This book introduces the history of Taiwan"],
["我很喜歡這本歷史小說","b-242.mp3","wǒ hěn xǐhuān zhè běn lìshǐ xiǎoshuō","i love this historical novel"],
["236.","b-246.mp3","236","236"],
["圖書館","b-247.mp3","Túshū guǎn","library"],
["圖書館在餐廳和醫院的中間","b-248.mp3","túshū guǎn zài cāntīng hé yīyuàn de zhōngjiān","The library is between the restaurant and the hospital"],
["圖書館有很多書","b-249.mp3","túshū guǎn yǒu hěnduō shū","there are many books in the library"],
["237.","b-253.mp3","237","237"],
["難","b-254.mp3","Nán","Disaster"],
["A: 你覺得歷史考試難嗎?","b-255.mp3","A: Nǐ juédé lìshǐ kǎoshì nán ma?","A: Do you think the history test is difficult?"],
["B: 有一點難","b-256.mp3","B: Yǒuyīdiǎn nán","B: a little difficult"],
["238.","b-260.mp3","238","238"],
["容易","b-261.mp3","Róngyì","easy"],
["A: 張太太覺得開車難嗎?","b-262.mp3","A: Zhāng tàitài juédé kāichē nán ma?","A: Does Mrs. Zhang find it difficult to drive?"],
["B: 她覺得非常容易","b-263.mp3","B: Tā juédé fēicháng róngyì","B: She finds it very easy"],
["239.","b-267.mp3","239","239"],
["工作","b-268.mp3","Gōngzuò","Work"],
["月華的工作是什麼?","b-269.mp3","yuè huá de gōngzuò shì shénme?","What is Yuehua's job?"],
["現在工作不好找","b-270.mp3","Xiànzài gōngzuò bù hǎo zhǎo","It's hard to find a job now"],
["240.","b-274.mp3","240","240"],
["公司","b-275.mp3","Gōngsī","company"],
["公司的同事人都很好","b-276.mp3","gōngsī de tóngshì rén dōu hěn hǎo","The colleagues in the company are very nice"],
["在公司大家都用電腦工作","b-277.mp3","zài gōngsī dàjiā dōu yòng diànnǎo gōngzuò","Everyone in the company works with computers"],
["241.","b-281.mp3","241","241"],
["醫院","b-282.mp3","Yīyuàn","Hospital"],
["醫院在餐廳和圖書館的中間","b-283.mp3","yīyuàn zài cāntīng hé túshū guǎn de zhōngjiān","The hospital is between the restaurant and the library"],
["有很多車停在醫院門口","b-284.mp3","yǒu hěnduō chē tíng zài yīyuàn ménkǒu","There are many cars parked in front of the hospital"],
["242.","b-288.mp3","242","242"],
["醫生","b-289.mp3","Yīshēng","doctor"],
["醫生說什麼？","b-290.mp3","yīshēng shuō shénme?","What did the doctor say?"],
["醫生說我感冒了","b-291.mp3","Yīshēng shuō wǒ gǎnmàole","The doctor said I had a cold"],
["243.","b-295.mp3","243","243"],
["忙","b-296.mp3","Máng","busy"],
["這幾天媽媽很忙","b-297.mp3","zhè jǐ tiān māmā hěn máng","Mom is busy these days"],
["她在忙什麼？","b-298.mp3","tā zài máng shénme?","What is she up to?"],
["244.","b-302.mp3","244","244"],
["好吃","b-303.mp3","Hào chī","tasty"],
["A: 三明治好吃嗎?","b-304.mp3","A: Sānmíngzhì hào chī ma?","A: Is the sandwich delicious?"],
["B: 非常好吃","b-305.mp3","B: Fēicháng hào chī","B: very delicious"],
["245.","b-309.mp3","245","245"],
["累","b-310.mp3","Lèi","tired"],
["A: 爸爸怎麼了?","b-311.mp3","A: Bàba zěnmeliǎo?","A: What's wrong with Dad?"],
["B: 他工作很累，正在睡覺","b-312.mp3","B: Tā gōngzuò hěn lèi, zhèngzài shuìjiào","B: He is very tired from work and is sleeping"],
["246.","b-316.mp3","246","246"],
["吃","b-317.mp3","Chī","eat"],
["你想吃什麼?","b-318.mp3","nǐ xiǎng chī shénme?","What do you want to eat?"],
["圖書館裡面不可以吃東西","b-319.mp3","Túshū guǎn lǐmiàn bù kěyǐ chī dōngxī","No eating in the library"],
["247.","b-323.mp3","247","247"],
["飯","b-324.mp3","Fàn","meal"],
["爸爸正在做飯","b-325.mp3","bàba zhèngzài zuò fàn","dad is cooking"],
["要不要一起吃中飯？","b-326.mp3","yào bùyào yīqǐ chī zhōng fàn?","Do you want to have lunch together?"],
["248.","b-330.mp3","248","248"],
["菜","b-331.mp3","Cài","vegetable"],
["你會做菜嗎？","b-332.mp3","nǐ huì zuò cài ma?","Can you cook?"],
["很多小孩不喜歡吃青菜","b-333.mp3","Hěnduō xiǎohái bù xǐhuān chī qīngcài","Many children do not like to eat green vegetables"],
["249.","b-337.mp3","249","249"],
["肉","b-338.mp3","Ròu","Meat"],
["她正在買豬肉","b-339.mp3","tā zhèngzài mǎi zhūròu","she is buying pork"],
["桌上只有菜，沒有肉","b-340.mp3","zhuō shàng zhǐyǒu cài, méiyǒu ròu","There are only vegetables on the table, no meat"],
["250.","b-344.mp3","250","250"],
["魚","b-345.mp3","Yú","fish"],
["李太太正在買魚","b-346.mp3","lǐ tàitài zhèngzài mǎi yú","Mrs. Li is buying fish"],
["她買了魚、牛肉，還沒買水果","b-347.mp3","tā mǎile yú, niúròu, hái méi mǎi shuǐguǒ","She bought fish, beef, but no fruit"],
["251.","b-351.mp3","251","251"],
["蘋果","b-352.mp3","Píngguǒ","apple"],
["小天買了一些蘋果","b-353.mp3","xiǎo tiān mǎile yīxiē píngguǒ","Xiaotian bought some apples"],
["桌上有幾顆蘋果?","b-354.mp3","zhuō shàng yǒu jǐ kē píngguǒ?","How many apples are there on the table?"],
["252.","b-358.mp3","252","252"],
["喝","b-359.mp3","Hē","drink"],
["我想喝蘋果汁","b-360.mp3","wǒ xiǎng hē píngguǒ zhī","i want to drink apple juice"],
["台灣人很喜歡喝茶","b-361.mp3","táiwān rén hěn xǐhuān hē chá","Taiwanese people like to drink tea very much"],
["253.","b-365.mp3","253","253"],
["酒","b-366.mp3","Jiǔ","liquor"],
["我不會喝酒","b-367.mp3","wǒ bù huì hējiǔ","I can not drink"],
["老王喜歡喝紅酒","b-368.mp3","lǎo wáng xǐhuān hē hóngjiǔ","Pharaoh likes to drink red wine"],
["254.","b-372.mp3","254","254"],
["水","b-373.mp3","Shuǐ","water"],
["可以給我一杯熱水嗎?","b-374.mp3","kěyǐ gěi wǒ yībēi rè shuǐ ma?","Can you give me a cup of hot water?"],
["很多人水喝的不夠多","b-375.mp3","Hěnduō rén shuǐ hē de bùgòu duō","Many people don't drink enough water"],
["255.","b-379.mp3","255","255"],
["咖啡","b-380.mp3","Kāfēi","coffee"],
["很多人喜歡早上只喝咖啡","b-381.mp3","hěnduō rén xǐhuān zǎoshang zhǐ hē kāfēi","Many people like to drink only coffee in the morning"],
["長頭髮的小姐在喝咖啡 ","b-382.mp3","zhǎng tóufà de xiǎojiě zài hē kāfēi","lady with long hair is drinking coffee"],
["256.","b-386.mp3","256","256"],
["茶","b-387.mp3","Chá","Tea"],
["短頭髮的小姐在喝茶","b-388.mp3","duǎn tóufà de xiǎojiě zài hē chá","lady with short hair is drinking tea"],
["這家餐廳只賣茶","b-389.mp3","zhè jiā cāntīng zhǐ mài chá","This restaurant only serves tea"],
["257.","b-393.mp3","257","257"],
["瓶","b-394.mp3","Píng","bottle"],
["A: 你要幾瓶啤酒 ?","b-395.mp3","A: Nǐ yào jǐ píng píjiǔ?","A: How many bottles of beer would you like?"],
["B: 三瓶!謝謝","b-396.mp3","B: Sānpíng! Xièxiè","B: Three bottles! Thank you"],
["258.","b-400.mp3","258","258"],
["杯","b-401.mp3","Bēi","cup"],
["A: 可以給我兩個杯子嗎?","b-402.mp3","A: Kěyǐ gěi wǒ liǎng gè bēizi ma?","A: Can I have two glasses?"],
["B: 兩個杯子? 好!","b-403.mp3","B: Liǎng gè bēizi? Hǎo!","B: Two cups? Good!"],
["259.","b-407.mp3","259","259"],
["餐廳","b-408.mp3","Cāntīng","Dining room"],
["這家餐廳不賣飲料","b-409.mp3","zhè jiā cāntīng bù mài yǐnliào","This restaurant does not sell drinks"],
["餐廳裡一個人都沒有","b-410.mp3","cāntīng lǐ yīgèrén dōu méiyǒu","no one in the restaurant"],
["260.","b-414.mp3","260","260"],
["飯館","b-415.mp3","Fànguǎn","restaurant"],
["這家飯館很有名","b-416.mp3","zhè jiā fànguǎn hěn yǒumíng","this restaurant is famous"],
["這家飯館不賣飲料，只賣餃子","b-417.mp3","zhè jiā fànguǎn bù mài yǐnliào, zhǐ mài jiǎozi","This restaurant does not sell drinks, only dumplings"],
["261.","b-421.mp3","261","261"],
["電影","b-422.mp3","Diànyǐng","Movie"],
["A: 你想看台灣電影還是美國電影?","b-423.mp3","A: Nǐ xiǎng kàn táiwān diànyǐng háishì měiguó diànyǐng?","A: Do you want to watch Taiwanese movies or American movies?"],
["B: 我比較想看台灣電影，可以學中文","b-424.mp3","B: Wǒ bǐjiào xiǎng kàn táiwān diànyǐng, kěyǐ xué zhōngwén","B: I prefer to watch Taiwanese movies, I can learn Chinese"],
["262.","b-428.mp3","262","262"],
["畫","b-429.mp3","Huà","painting"],
["老王正畫著小天的臉","b-430.mp3","lǎo wáng zhèng huàzhe xiǎo tiān de liǎn","Old Wang is drawing Xiaotian's face"],
["小天正畫著他的狗的臉","b-431.mp3","xiǎo tiānzhèng huàzhe tā de gǒu de liǎn","Xiaotian is drawing his dog's face"],
["263.","b-435.mp3","263","263"],
["音樂","b-436.mp3","Yīnyuè","music"],
["我要去公園聽音樂會，一起來吧!","b-437.mp3","wǒ yào qù gōngyuán tīng yīnyuè huì, yīqǐlái ba!","I'm going to the park for a concert, come along!"],
["張先生開車的時候喜歡聽音樂","b-438.mp3","Zhāng xiānshēng kāichē de shíhòu xǐhuān tīng yīnyuè","Mr. Zhang likes to listen to music while driving"],
["264.","b-442.mp3","264","264"],
["票","b-443.mp3","Piào","ticket"],
["一張門票多少錢?","b-444.mp3","yī zhāng ménpiào duōshǎo qián?","How much is a ticket?"],
["我的火車票不見了","b-445.mp3","Wǒ de huǒchē piào bùjiànle","my train ticket is missing"],
["265.","b-449.mp3","265","265"],
["照片","b-450.mp3","Zhàopiàn","photo"],
["你這張照片很好看","b-451.mp3","nǐ zhè zhāng zhàopiàn hěn hǎo kàn","Your picture is beautiful"],
["我們旅行的時候照了非常多照片","b-452.mp3","wǒmen lǚxíng de shíhòu zhàole fēicháng duō zhàopiàn","We took a lot of photos when we traveled"],
["266.","b-456.mp3","266","266"],
["打","b-457.mp3","Dǎ","beat"],
["小天平常喜歡去打籃球","b-458.mp3","xiǎo tiān píngcháng xǐhuān qù dǎ lánqiú","Xiaotian usually likes to play basketball"],
["很多台灣人喜歡打棒球","b-459.mp3","hěnduō táiwān rén xǐhuān dǎ bàngqiú","Many Taiwanese like to play baseball"],
["267.","b-463.mp3","267","267"],
["玩","b-464.mp3","Wán","Play"],
["A: 圖片裡的小女孩在玩什麼?","b-465.mp3","A: Túpiàn lǐ de xiǎo nǚhái zài wán shénme?","A: What is the little girl playing in the picture?"],
["B: 她正在院子裡玩球","b-466.mp3","B: Tā zhèngzài yuànzi lǐ wán qiú","B: She is playing ball in the yard"],
["268.","b-470.mp3","268","268"],
["運動","b-471.mp3","Yùndòng","sports"],
["老王每天早上去公園運動","b-472.mp3","lǎo wáng měitiān zǎoshang qù gōngyuán yùndòng","Lao Wang goes to the park to exercise every morning"],
["李先生一個星期運動三次","b-473.mp3","lǐ xiānshēng yīgè xīngqí yùndòng sāncì","Mr. Li exercises three times a week"],
["269.","b-477.mp3","269","269"],
["運動","b-478.mp3","Yùndòng","sports"],
["你喜歡看什麼運動比賽?","b-479.mp3","nǐ xǐhuān kàn shénme yùndòng bǐsài?","What sports do you like to watch?"],
["這個運動節目很早就開始了","b-480.mp3","Zhège yùndòng jiémù hěn zǎo jiù kāishǐle","The sports show starts early"],
["270.","b-484.mp3","270","270"],
["唱歌","b-485.mp3","Chànggē","Sing"],
["這個唱歌比賽很早就開始了","b-486.mp3","zhège chànggē bǐsài hěn zǎo jiù kāishǐle","This singing competition started early"],
["他喜歡一邊洗澡，一邊唱歌","b-487.mp3","tā xǐhuān yībiān xǐzǎo, yībiān chànggē","He likes to take a shower while singing"],
["271.","b-491.mp3","271","271"],
["買","b-492.mp3","Mǎi","purchase"],
["陳小姐買了兩杯飲料、一個包子","b-493.mp3","chén xiǎojiě mǎile liǎng bēi yǐnliào, yīgè bāozi","Ms. Chen bought two drinks and a bun"],
["那位先生正在買麵包","b-494.mp3","nà wèi xiānshēng zhèngzài mǎi miànbāo","the gentleman is buying bread"],
["272.","b-498.mp3","272","272"],
["賣","b-499.mp3","Mài","Sell"],
["他們沒賣三明治","b-500.mp3","tāmen méi mài sānmíngzhì","they don't sell sandwiches"],
["他們沒賣麵包，只賣包子","b-501.mp3","tāmen méi mài miànbāo, zhǐ mài bāozi","They don't sell bread, only buns"],
["273.","b-505.mp3","273","273"],
["錢","b-506.mp3","Qián","money"],
["我忘記帶錢了","b-507.mp3","wǒ wàngjì dài qiánle","i forgot to bring money"],
["我的錢不見了","b-508.mp3","wǒ de qián bùjiànle","my money is gone"],
["274.","b-512.mp3","274","274"],
["元","b-513.mp3","Yuán","Yuan"],
["A: 小姐，這件衣服是九百元","b-514.mp3","A: Xiǎojiě, zhè jiàn yīfú shì jiǔbǎi yuán","A: Miss, this dress is 900 yuan"],
["B: 九百元嗎? 好!","b-515.mp3","B: Jiǔbǎi yuán ma? Hǎo!","B: Nine hundred yuan? Good!"],
["275.","b-519.mp3","275","275"],
["塊","b-520.mp3","Kuài","piece"],
["這個皮包賣兩百多塊","b-521.mp3","zhège píbāo mài liǎng bǎi duō kuài","This leather bag sells for more than two hundred yuan"],
["王小姐說這件褲子賣一百多塊","b-522.mp3","wáng xiǎojiě shuō zhè jiàn kùzi mài yībǎi duō kuài","Ms. Wang said that this pair of pants sold for more than 100 yuan"],
["276.","b-526.mp3","276","276"],
["貴","b-527.mp3","Guì","expensive"],
["這個皮包太貴了","b-528.mp3","zhège píbāo tài guìle","this bag is too expensive"],
["這個有一點貴，我們買別的東西吧!","b-529.mp3","zhège yǒu yīdiǎn guì, wǒmen mǎi bié de dōngxī ba!","This one is a bit expensive, let's buy something else!"],
["277.","b-533.mp3","277","277"],
["便宜","b-534.mp3","Piányí","Cheap"],
["A:您好，請問有什麼事嗎?","b-535.mp3","A: Nín hǎo, qǐngwèn yǒu shé me shì ma?","A: Hello, what can I do?"],
["B:這張紙上寫著現在學游泳，比較便宜 A:是的，星期一到星期五的早上比較便宜","b-536.mp3","B: Zhè zhāng zhǐshàng xiězhe xiànzài xué yóuyǒng, bǐjiào piányí A: Shì de, xīngqí yī dào xīngqíwǔ de zǎoshang bǐjiào piányí","B: This piece of paper says that it is cheaper to learn to swim now A: Yes, it is cheaper from Monday to Friday morning"],
["278.","b-540.mp3","278","278"],
["市場","b-541.mp3","Shìchǎng","market"],
["A:請問這附近有超級市場嗎?","b-542.mp3","A: Qǐngwèn zhè fùjìn yǒu chāojí shìchǎng ma?","A: Is there a supermarket near here?"],
["B:有，那邊有一間超市!","b-543.mp3","B: Yǒu, nà biān yǒu yī jiàn chāoshì!","B: Yes, there is a supermarket over there!"],
["279.","b-547.mp3","279","279"],
["車","b-548.mp3","Chē","car"],
["有車子停在門口","b-549.mp3","yǒu chēzi tíng zài ménkǒu","There is a car parked at the door"],
["他洗車的時候喜歡聽音樂","b-550.mp3","tā xǐchē de shíhòu xǐhuān tīng yīnyuè","He likes to listen to music while washing the car"],
["280.","b-554.mp3","280","280"],
["開車","b-555.mp3","Kāichē","drive"],
["你會開車嗎？","b-556.mp3","nǐ huì kāichē ma?","can you drive?"],
["李先生開車的時候喜歡唱歌","b-557.mp3","Lǐ xiānshēng kāichē de shíhòu xǐhuān chànggē","Mr. Li likes to sing while driving"],
["281.","b-561.mp3","281","281"],
["公車","b-562.mp3","Gōngchē","the bus"],
["有一位小姐在等七十六號公車","b-563.mp3","yǒu yī wèi xiǎojiě zài děng qīshíliù hào gōngchē","There is a lady waiting for the No. 76 bus"],
["公車快要來了!","b-564.mp3","gōngchē kuàiyào láile!","The bus is coming soon!"],
["282.","b-568.mp3","282","282"],
["地鐵","b-569.mp3","Dìtiě","subway"],
["我們做地鐵去博物館吧!","b-570.mp3","wǒmen zuò dìtiě qù bówùguǎn ba!","Let's do the subway to the museum!"],
["地鐵跟捷運一樣嗎？","b-571.mp3","Dìtiě gēn jié yùn yīyàng ma?","Is the subway the same as the MRT?"],
["283.","b-575.mp3","283","283"],
["路","b-576.mp3","Lù","road"],
["你可以走這條路，比較快","b-577.mp3","nǐ kěyǐ zǒu zhè tiáo lù, bǐjiào kuài","You can go this way, it's faster"],
["路上有很多車，小心!","b-578.mp3","lùshàng yǒu hěnduō chē, xiǎoxīn!","There are many cars on the road, be careful!"],
["284.","b-582.mp3","284","284"],
["方便","b-583.mp3","Fāngbiàn","convenient"],
["台北捷運很方便","b-584.mp3","táiběi jié yùn hěn fāngbiàn","Taipei MRT is very convenient"],
["便利商店非常方便","b-585.mp3","biànlì shāngdiàn fēicháng fāngbiàn","Convenience store is very convenient"],
["285.","b-589.mp3","285","285"],
["天","b-590.mp3","Tiān","sky"],
["今天是晴天","b-591.mp3","jīntiān shì qíngtiān","It's sunny"],
["昨天是雨天","b-592.mp3","zuótiān shì yǔtiān","yesterday was rainy"],
["286.","b-596.mp3","286","286"],
["天氣","b-597.mp3","Tiānqì","weather"],
["今天天氣怎麼樣？","b-598.mp3","jīntiān tiānqì zěnme yàng?","How's the weather today?"],
["天氣非常好，太陽很大","b-599.mp3","Tiānqì fēicháng hǎo, tàiyáng hěn dà","The weather is very good and the sun is big"],
["287.","b-603.mp3","287","287"],
["山","b-604.mp3","Shān","Mountain"],
["A: 我們去山上走一走，好不好?","b-605.mp3","A: Wǒmen qù shānshàng zǒu yī zǒu, hǎobù hǎo?","A: Let's go for a walk on the mountain, shall we?"],
["B: 可是山上的天氣不好，風很大","b-606.mp3","B: Kěshì shānshàng de tiānqì bù hǎo, fēng hěn dà","B: But the weather on the mountain is not good, the wind is very strong"],
["288.","b-610.mp3","288","288"],
["河","b-611.mp3","Hé","river"],
["這裡有很多小河","b-612.mp3","zhèlǐ yǒu hěnduō xiǎohé","there are many small rivers"],
["晚上去河邊走一走，怎麼樣?","b-613.mp3","wǎnshàng qù hé biān zǒu yī zǒu, zěnme yàng?","How about going for a walk by the river at night?"],
["289.","b-617.mp3","289","289"],
["海","b-618.mp3","Hǎi","ocean"],
["明天我們去看海，好不好?","b-619.mp3","míngtiān wǒmen qù kàn hǎi, hǎobù hǎo?","Let's go to the sea tomorrow, shall we?"],
["好啊，去海邊走一走！","b-620.mp3","Hǎo a, qù hǎibiān zǒu yī zǒu!","OK, let's go to the beach for a walk!"],
["290.","b-624.mp3","290","290"],
["風","b-625.mp3","Fēng","wind"],
["今天風好大","b-626.mp3","jīntiān fēng hào dà","It's very windy today"],
["這幾天晚上的風好大","b-627.mp3","zhè jǐ tiān wǎnshàng de fēng hào dà","It's been so windy these nights"],
["291.","b-631.mp3","291","291"],
["花","b-632.mp3","Huā","flower"],
["花好漂亮！","b-633.mp3","huā hǎo piàoliang!","The flowers are so beautiful!"],
["哥哥常常送他女朋友花","b-634.mp3","Gēgē chángcháng sòng tā nǚ péngyǒu huā","Brother often sends flowers to his girlfriend"],
["292.","b-638.mp3","292","292"],
["樹","b-639.mp3","Shù","Tree"],
["公園裡有很多樹","b-640.mp3","gōngyuán lǐ yǒu hěnduō shù","there are many trees in the park"],
["這裡有樹和一間房子","b-641.mp3","zhèlǐ yǒu shù hé yī jiàn fángzi","Here are trees and a house"],
["293.","b-645.mp3","293","293"],
["狗","b-646.mp3","Gǒu","dog"],
["那隻狗在睡覺","b-647.mp3","nà zhī gǒu zài shuìjiào","the dog is sleeping"],
["樹下有ㄧ隻狗","b-648.mp3","shùxià yǒuyi1 zhī gǒu","There is a dog under the tree"],
["294.","b-652.mp3","294","294"],
["很","b-653.mp3","Hěn","very"],
["今天天氣很好！","b-654.mp3","jīntiān tiānqì hěn hǎo!","The weather today is good!"],
["天氣很熱！","b-655.mp3","Tiānqì hěn rè!","Hot weather!"],
["295.","b-659.mp3","295","295"],
["非常","b-660.mp3","Fēicháng","Very"],
["老闆今天非常高興","b-661.mp3","lǎobǎn jīntiān fēicháng gāoxìng","The boss is very happy today"],
["妹妹非常喜歡打網球","b-662.mp3","mèimei fēicháng xǐhuān dǎ wǎngqiú","My sister likes playing tennis very much"],
["296.","b-666.mp3","296","296"],
["真","b-667.mp3","Zhēn","real"],
["真對不起","b-668.mp3","zhēn duìbùqǐ","I am so sorry"],
["這個公園真大!","b-669.mp3","zhège gōngyuán zhēn dà!","This park is so big!"],
["297.","b-673.mp3","297","297"],
["太","b-674.mp3","Tài","too"],
["今天40度，太熱了！","b-675.mp3","jīntiān 40 dù, tài rèle!","40 degrees today, too hot!"],
["你的房間太乾淨了","b-676.mp3","Nǐ de fángjiān tài qiánjìngle","your room is too clean"],
["298.","b-680.mp3","298","298"],
["好","b-681.mp3","Hǎo","good"],
["好熱！好熱！","b-682.mp3","hǎo rè! Hǎo rè!","So hot! So hot!"],
["風好大！","b-683.mp3","Fēng hào dà!","The wind is so strong!"],
["299.","b-687.mp3","299","299"],
["再","b-688.mp3","Zài","Again"],
["再一次! 我想再練習一次","b-689.mp3","zài yīcì! Wǒ xiǎng zài liànxí yīcì","Again! I want to practice again"],
["我不想要再感冒了","b-690.mp3","wǒ bùxiǎng yào zài gǎnmàole","I don't want to catch a cold anymore"],
["300.","b-694.mp3","300","300"],
["每天","b-695.mp3","Měitiān","every day"],
["爸爸每天要到醫院去看奶奶","b-696.mp3","bàba měitiān yào dào yīyuàn qù kàn nǎinai","Dad has to go to the hospital to see grandma every day"],
["我每天晚上七點半下班","b-697.mp3","wǒ měitiān wǎnshàng qī diǎn bàn xiàbān","I get off work at half past seven every night"],
["301.","c-01.mp3","301","301"],
["常常","c-02.mp3","Chángcháng","Frequent"],
["小美常常跟爸爸一起爬山","c-03.mp3","xiǎo měi chángcháng gēn bàba yīqǐ páshān","Xiaomei often climbs mountains with her father"],
["小華常常騎腳踏車去上學","c-04.mp3","xiǎo huá chángcháng qí jiǎotàchē qù shàngxué","Xiaohua often goes to school by bicycle"],
["302.","c-08.mp3","302","302"],
["也","c-09.mp3","Yě","also"],
["弟弟喜歡騎腳踏車，爸爸也是","c-10.mp3","dìdì xǐhuān qí jiǎotàchē, bàba yěshì","My brother likes to ride a bicycle, and so does my father"],
["李先生買了豬肉，也買了牛肉","c-11.mp3","lǐ xiānshēng mǎile zhūròu, yě mǎile niúròu","Mr. Li bought pork and beef"],
["303.","c-15.mp3","303","303"],
["都","c-16.mp3","Dōu","All"],
["王小姐每一天都覺得很累","c-17.mp3","wáng xiǎojiě měi yītiān dū juédé hěn lèi","Miss Wang feels very tired every day"],
["學生都在教室等老師","c-18.mp3","xuéshēng dōu zài jiàoshì děng lǎoshī","Students are waiting for the teacher in the classroom"],
["304.","c-22.mp3","304","304"],
["只","c-23.mp3","Zhǐ","Only"],
["這家店只賣皮包和鞋子","c-24.mp3","zhè jiā diàn zhǐ mài píbāo hé xiézi","This shop only sells leather bags and shoes"],
["媽媽只買了鞋子和裙子","c-25.mp3","māmā zhǐ mǎile xiézi hé qúnzi","Mom only bought shoes and a skirt"],
["305.","c-29.mp3","305","305"],
["多","c-30.mp3","Duō","many"],
["這次的音樂會人非常多","c-31.mp3","zhècì de yīnyuè huì rén fēicháng duō","This concert is very crowded"],
["早上來學游泳的人很多","c-32.mp3","zǎoshang lái xué yóuyǒng de rén hěnduō","There are many people who come to learn swimming in the morning"],
["306.","c-36.mp3","306","306"],
["一點","c-37.mp3","Yīdiǎn","a little"],
["多吃一點!","c-38.mp3","duō chī yīdiǎn!","eat more!"],
["不要哭! 勇敢一點","c-39.mp3","Bùyào kū! Yǒnggǎn yīdiǎn","don't cry! be brave"],
["307.","c-43.mp3","307","307"],
["一些","c-44.mp3","Yīxiē","Some"],
["我在超市買了一些香蕉","c-45.mp3","wǒ zài chāoshì mǎile yīxiē xiāngjiāo","I bought some bananas at the supermarket"],
["有一些人站在餐廳門口","c-46.mp3","yǒu yīxiē rén zhàn zài cāntīng ménkǒu","there are some people standing at the door of the restaurant"],
["308.","c-50.mp3","308","308"],
["一起","c-51.mp3","Yīqǐ","Together"],
["要不要跟我們一起去旅行?","c-52.mp3","yào bùyào gēn wǒmen yīqǐ qù lǚxíng?","Do you want to travel with us?"],
["三個人一起來可以便宜$1000","c-53.mp3","Sān gèrén yīqǐlái kěyǐ piányí $1000","Three people can save $1000"],
["309.","c-57.mp3","309","309"],
["比","c-58.mp3","Bǐ","Compare"],
["哥哥比我高","c-59.mp3","gēgē bǐ wǒ gāo","brother is taller than me"],
["咖啡比酒便宜","c-60.mp3","kāfēi bǐ jiǔ piányí","coffee is cheaper than wine"],
["310.","c-64.mp3","310","310"],
["和","c-65.mp3","Hé","and"],
["他和朋友一起運動","c-66.mp3","tā hé péngyǒu yīqǐ yùndòng","He plays sports with his friends"],
["公園有很多小貓和小狗","c-67.mp3","gōngyuán yǒu hěnduō xiǎo māo hé xiǎo gǒu","There are many kittens and puppies in the park"],
["311.","c-71.mp3","311","311"],
["跟","c-72.mp3","Gēn","and"],
["他跟朋友一起運動","c-73.mp3","tā gēn péngyǒu yīqǐ yùndòng","He plays sports with his friends"],
["公園有很多小貓跟小狗","c-74.mp3","gōngyuán yǒu hěnduō xiǎo māo gēn xiǎo gǒu","There are many kittens and puppies in the park"],
["312.","c-78.mp3","312","312"],
["可是","c-79.mp3","Kěshì","But"],
["我想學中文可是沒有時間","c-80.mp3","wǒ xiǎng xué zhōngwén kěshì méiyǒu shíjiān","I want to learn Chinese but I don't have time"],
["我想去旅行可是沒有錢","c-81.mp3","wǒ xiǎng qù lǚxíng kěshì méiyǒu qián","i want to travel but i have no money"],
["313.","c-85.mp3","313","313"],
["還是","c-86.mp3","Háishì","still"],
["你要喝咖啡還是紅茶?","c-87.mp3","nǐ yào hē kāfēi háishì hóngchá?","Would you like coffee or black tea?"],
["中文比較難還是德文比較難?","c-88.mp3","Zhōngwén bǐjiào nán háishì dé wén bǐjiào nán?","Is Chinese more difficult or German more difficult?"],
["314.","c-92.mp3","314","314"],
["因為","c-93.mp3","Yīnwèi","because"],
["因為天氣太冷，我不想出去","c-94.mp3","yīn wéi tiānqì tài lěng, wǒ bùxiǎng chūqù","I don't want to go out because it's too cold"],
["小天很緊張，因為明天有考試","c-95.mp3","xiǎo tiān hěn jǐnzhāng, yīn wéi míngtiān yǒu kǎoshì","Xiaotian is very nervous because there is an exam tomorrow"],
["315.","c-99.mp3","315","315"],
["所以","c-100.mp3","Suǒyǐ","so"],
["天華頭痛，所以沒來上課","c-101.mp3","tiān huá tóutòng, suǒyǐ méi lái shàngkè","Tianhua had a headache, so he didn't come to class"],
["因為如玉感冒，所以在家休息","c-102.mp3","yīnwèi rú yù gǎnmào, suǒyǐ zàijiā xiūxí","Because Ruyu has a cold, she is resting at home"],
["316.","c-106.mp3","316","316"],
["會","c-107.mp3","Huì","meeting"],
["你媽媽會煮牛肉麵嗎?","c-108.mp3","nǐ māmā huì zhǔ niúròu miàn ma?","Can your mother cook beef noodles?"],
["我明天會去郵局寄信","c-109.mp3","Wǒ míngtiān huì qù yóujú jì xìn","I will go to the post office tomorrow to mail the letter"],
["317.","c-113.mp3","317","317"],
["能","c-114.mp3","Néng","able"],
["這個東西壞了不能吃","c-115.mp3","zhège dōngxī huài liǎo bùnéng chī","This thing is broken and can't be eaten"],
["醫生說我不能打球","c-116.mp3","yīshēng shuō wǒ bùnéng dǎqiú","The doctor said I can't play"],
["318.","c-120.mp3","318","318"],
["可以","c-121.mp3","Kěyǐ","Can"],
["可以幫我拿茶杯嗎?","c-122.mp3","kěyǐ bāng wǒ ná chábēi ma?","Could you get me a cup of tea?"],
["A: 老師! 可以問你一個問題嗎? B: 可以!","c-123.mp3","A: Lǎoshī! Kěyǐ wèn nǐ yīgè wèntí ma? B: Kěyǐ!","A: Teacher! Can I ask you a question? B: Yes!"],
["319.","c-127.mp3","319","319"],
["可能","c-128.mp3","Kěnéng","possible"],
["老王說他身體不舒服，可能不能來了","c-129.mp3","lǎo wáng shuō tā shēntǐ bú shūfú, kěnéng bùnéng láile","Lao Wang said he was not feeling well and might not be able to come"],
["明天可能會下雪","c-130.mp3","míngtiān kěnéng huì xià xuě","it may snow tomorrow"],
["320.","c-134.mp3","320","320"],
["得","c-135.mp3","Dé","have to"],
["A: 大明打棒球打得怎麼樣? B: 他打得不太好，他弟弟打得比較好","c-136.mp3","A: Dàmíng dǎ bàngqiú dǎ dé zěnme yàng? B: Tā dǎ dé bù tài hǎo, tā dìdì dǎ dé bǐjiào hǎo","A: How does Daming play baseball? B: He doesn’t play very well. His younger brother plays better"],
["如玉的中文說得很好","c-137.mp3","rú yù de zhōngwén shuō dé hěn hǎo","Ruyu speaks Chinese very well"],
["321.","c-141.mp3","321","321"],
["吧","c-142.mp3","Ba","Bar"],
["可能吧! 我不太確定","c-143.mp3","kěnéng ba! Wǒ bù tài quèdìng","maybe! i'm not sure"],
["今天晚上跟我們一起吃飯吧!","c-144.mp3","jīntiān wǎnshàng gēn wǒmen yīqǐ chīfàn ba!","Join us for dinner tonight!"],
["322.","c-148.mp3","322","322"],
["正","c-149.mp3","Zhèng","just"],
["老王正畫著小天的臉","c-150.mp3","lǎo wáng zhèng huàzhe xiǎo tiān de liǎn","Old Wang is drawing Xiaotian's face"],
["小天正寫著作業","c-151.mp3","xiǎo tiānzhèng xiě zhù zuòyè","Xiaotian is writing homework"],
["323.","c-155.mp3","323","323"],
["呢","c-156.mp3","Ne","Woolen cloth"],
["你呢？","c-157.mp3","nǐ ne?","And you?"],
["我要去火車站，你呢 ？","c-158.mp3","Wǒ yào qù huǒchē zhàn, nǐ ne?","I'm going to the train station, how about you?"],
["324.","c-162.mp3","324","324"],
["了","c-163.mp3","Le","up"],
["我吃飽了","c-164.mp3","wǒ chī bǎ le","I'm stuffed"],
["","c-165.mp3","",""],
["325.","c-169.mp3","325","325"],
["過","c-170.mp3","Guò","Pass"],
["你看過這部電影嗎？","c-171.mp3","nǐ kàn guò zhè bù diànyǐng ma?","Have you watched this movie?"],
["媽媽還沒有跟爸爸爬過阿里山","c-172.mp3","Māmā hái méiyǒu gēn bàba páguò ālǐ shān","Mom hasn't climbed Alishan with Dad yet"],
["326.","c-176.mp3","326","326"],
["喂","c-177.mp3","Wèi","Hello"],
["A: 喂 請問林老師在嗎?","c-178.mp3","A: Wèi qǐngwèn lín lǎoshī zài ma?","A: Hello, is Teacher Lin there?"],
["B: 喂，我就是","c-179.mp3","B: Wèi, wǒ jiùshì","B: Hello, I am"],
["327.","c-183.mp3","327","327"],
["給","c-184.mp3","Gěi","Give"],
["明天是母親節，我想買禮物給媽媽","c-185.mp3","míngtiān shì mǔqīn jié, wǒ xiǎng mǎi lǐwù gěi māmā","Tomorrow is Mother's Day, I want to buy a gift for my mother"],
["醫生給了我一些藥","c-186.mp3","yīshēng gěile wǒ yīxiē yào","The doctor gave me some medicine"],
["328.","c-190.mp3","328","328"],
["從","c-191.mp3","Cóng","from"],
["他們從學校走出來","c-192.mp3","tāmen cóng xuéxiào zǒu chūlái","they come out of school"],
["我從今天開始要準備考試","c-193.mp3","wǒ cóng jīntiān kāishǐ yāo zhǔnbèi kǎoshì","I'm going to prepare for the exam starting today"],
["329.","c-197.mp3","329","329"],
["離","c-198.mp3","Lí","Leave"],
["我:你家離學校近嗎?","c-199.mp3","wǒ: Nǐ jiā lí xuéxiào jìn ma?","Me: Is your home close to the school?"],
["王同學: 我家離學校非常近，走路五分鐘就到了. 我:我家離學校比較遠，要騎腳踏車","c-200.mp3","Wáng tóngxué: Wǒjiā lí xuéxiào fēicháng jìn, zǒulù wǔ fēnzhōng jiù dàole. Wǒ: Wǒjiā lí xuéxiào bǐjiào yuǎn, yào qí jiǎotàchē","Classmate Wang: My home is very close to the school, and it takes only five minutes to walk there. Me: My home is far from the school, so I have to ride a bicycle"],
["330.","c-204.mp3","330","330"],
["往","c-205.mp3","Wǎng","Past"],
["你往前走，火車站就在前面","c-206.mp3","nǐ wǎng qián zǒu, huǒchē zhàn jiù zài qiánmiàn","You go forward, the train station is in front"],
["你往前走五分鐘，公園就在你的左邊","c-207.mp3","nǐ wǎng qián zǒu wǔ fēnzhōng, gōngyuán jiù zài nǐ de zuǒbiān","You go forward for five minutes and the park is on your left"],
["331.","d-01.mp3","331","331"],
["男生","d-02.mp3","Nánshēng","boys"],
["男生宿舍在1樓","d-03.mp3","nánshēng sùshè zài 1 lóu","The male dormitory is on the 1st floor"],
["我不認識這個男生","d-04.mp3","wǒ bù rènshí zhège nánshēng","I don't know this boy"],
["332.","d-08.mp3","332","332"],
["女生","d-09.mp3","Nǚshēng","girl"],
["你往前走，女生宿舍就在前面","d-10.mp3","nǐ wǎng qián zǒu, nǚshēng sùshè jiù zài qiánmiàn","You go forward, the girls' dormitory is in front"],
["女生宿舍在男生宿舍旁邊","d-11.mp3","nǚshēng sùshè zài nánshēng sùshè pángbiān","The girls' dormitory is next to the boys' dormitory"],
["333.","d-15.mp3","333","333"],
["小孩","d-16.mp3","Xiǎohái","Child"],
["李小姐的小孩已經出生了","d-17.mp3","lǐ xiǎojiě de xiǎohái yǐjīng chūshēngle","Miss Li's child has been born"],
["她的小孩很可愛","d-18.mp3","tā de xiǎohái hěn kě'ài","her baby is cute"],
["334.","d-22.mp3","334","334"],
["家人","d-23.mp3","Jiārén","family"],
["李小姐的家人都在醫院","d-24.mp3","lǐ xiǎojiě de jiārén dōu zài yīyuàn","Miss Li's family members are in the hospital"],
["老王正在幫這家人照相","d-25.mp3","lǎo wáng zhèngzài bāng zhè jiārén zhàoxiàng","Lao Wang is taking pictures of the family"],
["335.","d-29.mp3","335","335"],
["姓名","d-30.mp3","Xìngmíng","Name"],
["請寫下您的姓名","d-31.mp3","qǐng xiě xià nín de xìngmíng","please write down your name"],
["銀行人員要我們寫下姓名、電話、住址","d-32.mp3","yínháng rényuán yào wǒmen xiě xià xìngmíng, diànhuà, zhùzhǐ","The bank staff asked us to write down our name, phone number and address"],
["336.","d-36.mp3","336","336"],
["千","d-37.mp3","Qiān","thousand"],
["A: 我只有一千塊，夠嗎?","d-38.mp3","A: Wǒ zhǐyǒu yīqiān kuài, gòu ma?","A: I only have one thousand yuan, is that enough?"],
["B: 這裡住一晚要兩千塊","d-39.mp3","B: Zhèlǐ zhù yī wǎn yào liǎng qiān kuài","B: It costs 2,000 yuan to stay here for one night"],
["337.","d-43.mp3","337","337"],
["萬","d-44.mp3","Wàn","Ten thousand"],
["這個皮包賣兩萬塊","d-45.mp3","zhège píbāo mài liǎng wàn kuài","This leather bag sells for 20,000 yuan"],
["兩個人一起去韓國旅行，只要一萬塊","d-46.mp3","liǎng gè rén yīqǐ qù hánguó lǚxíng, zhǐyào yī wàn kuài","It only costs 10,000 yuan for two people to travel to Korea together"],
["338.","d-50.mp3","338","338"],
["樓","d-51.mp3","Lóu","building"],
["A: 我找如玉，請問她住幾樓？","d-52.mp3","A: Wǒ zhǎo rú yù, qǐngwèn tā zhù jǐ lóu?","A: I'm looking for Ruyu, which floor does she live on?"],
["B: 三樓!","d-53.mp3","B: Sān lóu!","B: The third floor!"],
["339.","d-57.mp3","339","339"],
["公斤","d-58.mp3","Gōngjīn","Kilogram"],
["你幾公斤 ?","d-59.mp3","nǐ jǐ gōngjīn?","how many kilograms are you"],
["這包米幾公斤?","d-60.mp3","Zhè bāo mǐ jǐ gōngjīn?","How many kilograms is this bag of rice?"],
["340.","d-64.mp3","340","340"],
["片","d-65.mp3","Piàn","piece"],
["小王早上只吃一片麵包和一杯咖啡","d-66.mp3","xiǎo wáng zǎoshang zhǐ chī yīpiàn miànbāo hé yībēi kāfēi","Xiao Wang only eats a piece of bread and a cup of coffee in the morning"],
["這片草地好大!","d-67.mp3","zhè piàn cǎodì hào dà!","This lawn is huge!"],
["341.","d-71.mp3","341","341"],
["一半","d-72.mp3","Yībàn","half"],
["弟弟早餐只吃一半","d-73.mp3","dìdì zǎocān zhǐ chī yībàn","Brother only eats half of breakfast"],
["我吃不下了，我們一人一半?","d-74.mp3","wǒ chī bùxiàle, wǒmen yīrén yībàn?","I can't take it anymore, we are half of each?"],
["342.","d-78.mp3","342","342"],
["新年","d-79.mp3","Xīnnián","new year"],
["新年快樂!","d-80.mp3","xīnnián kuàilè!","Happy New Year!"],
["你新年想要怎麼慶祝?","d-81.mp3","Nǐ xīnnián xiǎng yào zěnme qìngzhù?","How would you like to celebrate New Years?"],
["343.","d-85.mp3","343","343"],
["春天","d-86.mp3","Chūntiān","spring"],
["春天是我最喜歡的天氣！","d-87.mp3","chūntiān shì wǒ zuì xǐhuān de tiānqì!","Spring is my favorite weather!"],
["春天的天氣很舒服","d-88.mp3","Chūntiān de tiānqì hěn shūfú","The weather in spring is very comfortable"],
["344.","d-92.mp3","344","344"],
["夏天","d-93.mp3","Xiàtiān","summer"],
["台灣的夏天非常熱","d-94.mp3","táiwān de xiàtiān fēicháng rè","Taiwan's summer is very hot"],
["很多人夏天喜歡去海邊游泳","d-95.mp3","hěnduō rén xiàtiān xǐhuān qù hǎibiān yóuyǒng","Many people like to go to the beach to swim in summer"],
["345.","d-99.mp3","345","345"],
["秋天","d-100.mp3","Qiūtiān","autumn"],
["秋天是我最喜歡的季節","d-101.mp3","qiūtiān shì wǒ zuì xǐhuān de jìjié","autumn is my favorite season"],
["秋天的天氣很涼快，不冷不熱","d-102.mp3","qiūtiān de tiānqì hěn liángkuai, bù lěng bù rè","The weather in autumn is cool, neither hot nor cold"],
["346.","d-106.mp3","346","346"],
["冬天","d-107.mp3","Dōngtiān","winter"],
["好冷！冬天快來了！","d-108.mp3","hǎo lěng! Dōngtiān kuài láile!","so cold! Winter is coming!"],
["台灣的冬天很少下雪","d-109.mp3","Táiwān de dōngtiān hěn shǎo xià xuě","Taiwan rarely snows in winter"],
["347.","d-113.mp3","347","347"],
["小時","d-114.mp3","Xiǎoshí","Hour"],
["吉他課要一個小時","d-115.mp3","jítā kè yào yīgè xiǎoshí","one hour guitar lesson"],
["我家離學校有一點遠，開車要半個小時","d-116.mp3","wǒjiā lí xuéxiào yǒu yīdiǎn yuǎn, kāichē yào bàn gè xiǎoshí","My home is a little far from school, it takes half an hour to drive"],
["348.","d-120.mp3","348","348"],
["點鐘","d-121.mp3","Diǎn zhōng","o'clock"],
["明天考試上午十一點鐘開始","d-122.mp3","míngtiān kǎoshì shàngwǔ shíyī diǎn zhōng kāishǐ","The exam starts tomorrow at eleven o'clock in the morning"],
["籃球比賽下午三點鐘開始","d-123.mp3","lánqiú bǐsài xiàwǔ sān diǎn zhōng kāishǐ","The basketball game starts at three o'clock in the afternoon"],
["349.","d-127.mp3","349","349"],
["分鐘","d-128.mp3","Fēnzhōng","minute"],
["等一下! 給我十分鐘，我看一下","d-129.mp3","děng yīxià! Gěi wǒ shí fēnzhōng, wǒ kàn yīxià","wait a minute , give me ten minutes and i will take a look"],
["一個小時是三十分鐘","d-130.mp3","yīgè xiǎoshí shì sānshí fēnzhōng","an hour is thirty minutes"],
["350.","d-134.mp3","350","350"],
["久","d-135.mp3","Jiǔ","Long"],
["A: 請問從這裡到火車站走路要多久 ?","d-136.mp3","A: Qǐngwèn cóng zhèlǐ dào huǒchē zhàn zǒulù yào duōjiǔ?","A: How long does it take to walk from here to the train station?"],
["B: 走路太久了，坐公車比較快","d-137.mp3","B: Zǒulù tài jiǔle, zuò gōngchē bǐjiào kuài","B: It’s too long to walk, it’s faster to take a bus"],
["351.","d-141.mp3","351","351"],
["有時候","d-142.mp3","Yǒu shíhòu","sometimes"],
["有時候早上我跟爸爸去跑步","d-143.mp3","yǒu shíhòu zǎoshang wǒ gēn bàba qù pǎobù","Sometimes in the morning I go for a run with my dad"],
["有時候我在家吃飯，有時候去餐廳吃","d-144.mp3","yǒu shíhòu wǒ zàijiā chīfàn, yǒu shíhòu qù cāntīng chī","Sometimes I eat at home, sometimes I eat at a restaurant"],
["352.","d-148.mp3","352","352"],
["在","d-149.mp3","Zài","exist"],
["他在找東西","d-150.mp3","tā zài zhǎo dōngxī","he is looking for something"],
["她在電影院門口等人","d-151.mp3","tā zài diànyǐngyuàn ménkǒu děng rén","She is waiting at the entrance of the cinema"],
["353.","d-155.mp3","353","353"],
["對面","d-156.mp3","Duìmiàn","opposite"],
["電影院對面就是餐廳","d-157.mp3","diànyǐngyuàn duìmiàn jiùshì cāntīng","Opposite the cinema is the restaurant"],
["男生宿舍對面就是女生宿舍","d-158.mp3","nánshēng sùshè duìmiàn jiùshì nǚshēng sùshè","Opposite the male dormitory is the female dormitory"],
["354.","d-162.mp3","354","354"],
["裡面","d-163.mp3","Lǐmiàn","in"],
["咖啡館裡面很多人","d-164.mp3","kāfēi guǎn lǐmiàn hěnduō rén","many people in the cafe"],
["圖書館裡面很多學生","d-165.mp3","túshū guǎn lǐmiàn hěnduō xué shēng","Many students in the library"],
["355.","d-169.mp3","355","355"],
["國家","d-170.mp3","Guójiā","nation"],
["你去過幾個國家?","d-171.mp3","nǐ qùguò jǐ gè guójiā?","How many countries have you been to?"],
["明華去過很多國家","d-172.mp3","Míng huá qùguò hěnduō guójiā","Minghua has been to many countries"],
["356.","d-176.mp3","356","356"],
["城市","d-177.mp3","Chéngshì","City"],
["你喜歡住在大城市嗎?","d-178.mp3","nǐ xǐhuān zhù zài dà chéngshì ma?","Do you like living in a big city?"],
["王先生只住過大城市","d-179.mp3","Wáng xiānshēng zhǐ zhù guodà chéngshì","Mr. Wang has only lived in big cities"],
["357.","d-183.mp3","357","357"],
["街","d-184.mp3","Jiē","street"],
["這條街非常安靜","d-185.mp3","zhè tiáo jiē fēicháng ānjìng","this street is very quiet"],
["我常常到這條街買東西","d-186.mp3","wǒ chángcháng dào zhè tiáo jiē mǎi dōngxī","I often go shopping on this street"],
["358.","d-190.mp3","358","358"],
["海邊","d-191.mp3","Hǎibiān","seaside"],
["昨天我跟幾個朋友一起去海邊","d-192.mp3","zuótiān wǒ gēn jǐ gè péngyǒu yī qǐ qù hǎibiān","I went to the beach with some friends yesterday"],
["星期六我們一起去海邊玩吧!","d-193.mp3","xīngqíliù wǒmen yī qǐ qù hǎibiān wán ba!","Let's go to the beach together on Saturday!"],
["359.","d-197.mp3","359","359"],
["公園","d-198.mp3","Gōngyuán","garden"],
["有一些人正在公園運動","d-199.mp3","yǒu yīxiē rén zhèngzài gōngyuán yùndòng","some people are exercising in the park"],
["早上的公園非常安靜","d-200.mp3","zǎoshang de gōngyuán fēicháng ānjìng","The park is very quiet in the morning"],
["360.","d-204.mp3","360","360"],
["郵局","d-205.mp3","Yóujú","post office"],
["爸爸要去郵局寄信","d-206.mp3","bàba yào qù yóujú jì xìn","Dad is going to the post office to deliver a letter"],
["郵局離銀行不遠，就在前面","d-207.mp3","yóujú lí yínháng bù yuǎn, jiù zài qiánmiàn","The post office is not far from the bank, just in front"],
["361.","d-211.mp3","361","361"],
["銀行","d-212.mp3","Yínháng","bank"],
["李大同現在在銀行","d-213.mp3","lǐ dàtóng xiànzài zài yínháng","Li Datong is in the bank now"],
["王先生站在銀行門等大同","d-214.mp3","Wáng xiānshēng zhàn zài yínháng mén děng dàtóng","Mr. Wang stood at the gate of the bank waiting for Datong"],
["362.","d-218.mp3","362","362"],
["警察","d-219.mp3","Jǐngchá","police"],
["這個人是警察","d-220.mp3","zhège rén shì jǐngchá","this man is a policeman"],
["警察告訴大同，他現在還不可以騎車","d-221.mp3","jǐngchá gàosù dàtóng, tā xiànzài hái bù kěyǐ qí chē","The police told Datong that he is not allowed to ride yet"],
["363.","d-225.mp3","363","363"],
["飯店","d-226.mp3","Fàndiàn","Restaurant"],
["這間飯店很便宜","d-227.mp3","zhè jiān fàndiàn hěn piányí","this restaurant is cheap"],
["這間飯店住一晚要一千多塊","d-228.mp3","zhè jiān fàndiàn zhù yī wǎn yào yīqiān duō kuài","This hotel costs more than 1,000 yuan a night"],
["364.","d-232.mp3","364","364"],
["旅館","d-233.mp3","Lǚguǎn","hostel"],
["這間旅館住一晚要兩百多塊","d-234.mp3","zhè jiān lǚguǎn zhù yī wǎn yào liǎng bǎi duō kuài","This hotel costs more than 200 yuan a night"],
["他在旅館前面等車","d-235.mp3","tā zài lǚguǎn qiánmiàn děng chē","He is waiting in front of the hotel"],
["365.","d-239.mp3","365","365"],
["商店","d-240.mp3","Shāngdiàn","shop"],
["這是一家網路商店","d-241.mp3","zhè shì yījiā wǎng lù shāngdiàn","This is an online store"],
["商店門口很多人","d-242.mp3","shāngdiàn ménkǒu hěnduō rén","There are many people in front of the store"],
["366.","d-246.mp3","366","366"],
["店","d-247.mp3","Diàn","shop"],
["這家店非常有名","d-248.mp3","zhè jiā diàn fēicháng yǒumíng","this store is very famous"],
["這家店門口站著很多人","d-249.mp3","zhè jiā diàn ménkǒu zhànzhe hěnduō rén","There are many people standing in front of this store"],
["367.","d-253.mp3","367","367"],
["樓上","d-254.mp3","Lóu shàng","upstairs"],
["樓上有游泳池","d-255.mp3","lóu shàng yǒu yóuyǒngchí","There is a swimming pool upstairs"],
["妹妹在樓上","d-256.mp3","mèimei zài lóu shàng","sister upstairs"],
["368.","d-260.mp3","368","368"],
["樓下","d-261.mp3","Lóu xià","downstairs"],
["樓下有廚房","d-262.mp3","lóu xià yǒu chúfáng","There is a kitchen downstairs"],
["客廳跟臥室都在樓下","d-263.mp3","kètīng gēn wòshì dōu zài lóu xià","The living room and bedroom are downstairs"],
["369.","d-267.mp3","369","369"],
["頭","d-268.mp3","Tóu","head"],
["我頭很痛","d-269.mp3","wǒ tóu hěn tòng","i have a headache"],
["小心!你的頭!","d-270.mp3","xiǎoxīn! Nǐ de tóu!","Careful! Your head!"],
["370.","d-274.mp3","370","370"],
["頭髮","d-275.mp3","Tóufà","hair"],
["李小姐的頭髮比朋友的長","d-276.mp3","lǐ xiǎojiě de tóufà bǐ péngyǒu de cháng","Miss Li's hair is longer than her friend's"],
["那個短頭髮的學生是美心","d-277.mp3","nàgè duǎn tóufà de xuéshēng shì měi xīn","The student with short hair is Maxim"],
["371.","d-281.mp3","371","371"],
["耳朵","d-282.mp3","Ěrduǒ","ear"],
["爺爺的耳朵不太好","d-283.mp3","yéyé de ěrduǒ bù tài hǎo","Grandpa's ears are not good"],
["美美說她的耳朵很痛","d-284.mp3","měiměi shuō tā de ěrduǒ hěn tòng","Meimei said her ears hurt"],
["372.","d-288.mp3","372","372"],
["手指(頭)","d-289.mp3","Shǒuzhǐ (tóu)","finger (head)"],
["我的手指頭短短的","d-290.mp3","wǒ de shǒuzhǐ tou duǎn duǎn de","my fingers are short"],
["弟弟的手指髒髒的","d-291.mp3","dìdì de shǒuzhǐ zāng zāng de","My brother's fingers are dirty"],
["373.","d-295.mp3","373","373"],
["肚子","d-296.mp3","Dùzi","abdomen"],
["我肚子痛","d-297.mp3","wǒ dùzi tòng","my stomach hurts"],
["爸爸的肚子大大的","d-298.mp3","bàba de dùzi dàdà de","daddy's big belly"],
["374.","d-302.mp3","374","374"],
["健康","d-303.mp3","Jiànkāng","healthy"],
["現在我的身體很健康","d-304.mp3","xiànzài wǒ de shēntǐ hěn jiànkāng","now my body is very healthy"],
["祝你身體健康!","d-305.mp3","zhù nǐ shēntǐ jiànkāng!","Stay healthy!"],
["375.","d-309.mp3","375","375"],
["生病","d-310.mp3","Shēngbìng","Get ill"],
["我生病了","d-311.mp3","wǒ shēngbìngle","I am sick"],
["天氣冷很容易生病","d-312.mp3","tiānqì lěng hěn róngyì shēngbìng","It's easy to get sick in cold weather"],
["376.","d-316.mp3","376","376"],
["感冒","d-317.mp3","Gǎnmào","cold"],
["我感冒了，頭很痛不舒服","d-318.mp3","wǒ gǎnmàole, tóu hěn tòng bú shūfú","I have a cold, my head hurts and I feel uncomfortable"],
["醫生說我感冒了，給了我一些藥","d-319.mp3","yīshēng shuō wǒ gǎnmàole, gěile wǒ yīxiē yào","The doctor said I had a cold and gave me some medicine"],
["377.","d-323.mp3","377","377"],
["受傷","d-324.mp3","Shòushāng","Injuried"],
["小狗受傷了","d-325.mp3","xiǎo gǒu shòushāngle","puppy hurt"],
["林先生的頭受傷了","d-326.mp3","lín xiānshēng de tóu shòushāngle","Mr. Lin's head hurt"],
["378.","d-330.mp3","378","378"],
["痛","d-331.mp3","Tòng","pain"],
["我肚子有一點痛","d-332.mp3","wǒ dùzi yǒu yīdiǎn tòng","i have a stomachache"],
["我頭有一點痛，今天要早一點睡覺","d-333.mp3","wǒ tóu yǒu yīdiǎn tòng, jīntiān yào zǎo yīdiǎn shuìjiào","I have a headache, I need to go to bed early today"],
["379.","d-337.mp3","379","379"],
["胖","d-338.mp3","Pàng","fat"],
["天華小時候胖胖的","d-339.mp3","tiān huá xiǎoshíhòu pàng pàng de","Tianhua was fat when he was a child"],
["現在他不胖了","d-340.mp3","xiànzài tā bù pàngle","now he is not fat"],
["380.","d-344.mp3","380","380"],
["瘦","d-345.mp3","Shòu","thin"],
["A: 這隻狗好瘦","d-346.mp3","A: Zhè zhī gǒu hǎo shòu","A: This dog is so skinny"],
["A: 你是不是瘦了?! B: 對啊!我正在減肥","d-347.mp3","A: Nǐ shì bùshì shòule?! B: Duì a! Wǒ zhèngzài jiǎnféi","A: Are you losing weight?! B: Yes! I am losing weight"],
["381.","d-351.mp3","381","381"],
["矮","d-352.mp3","Ǎi","short"],
["小男孩不高不矮","d-353.mp3","xiǎo nánhái bù gāo bù ǎi","little boy neither tall nor short"],
["你覺得我太矮了嗎?","d-354.mp3","nǐ juédé wǒ tài ǎile ma?","Do you think I'm too short?"],
["382.","d-358.mp3","382","382"],
["短","d-359.mp3","Duǎn","short"],
["短頭髮的小姐在喝酒","d-360.mp3","duǎn tóufà de xiǎojiě zài hējiǔ","lady with short hair is drinking"],
["這位老師的頭髮短短的","d-361.mp3","zhè wèi lǎoshī de tóufà duǎn duǎn de","The teacher has short hair"],
["383.","d-365.mp3","383","383"],
["壞","d-366.mp3","Huài","bad"],
["這台電腦已經壞了","d-367.mp3","zhè tái diànnǎo yǐjīng huàile","this computer is broken"],
["這些水果都已經壞了","d-368.mp3","zhèxiē shuǐguǒ dōu yǐjīng huàile","These fruits are already spoiled"],
["384.","d-372.mp3","384","384"],
["不錯","d-373.mp3","Bùcuò","good"],
["這部電影不錯","d-374.mp3","zhè bù diànyǐng bùcuò","this movie is good"],
["妹妹籃球打得很不錯","d-375.mp3","mèimei lánqiú dǎ dé hěn bùcuò","My sister plays basketball very well"],
["385.","d-379.mp3","385","385"],
["重要","d-380.mp3","Zhòngyào","important"],
["家人很重要","d-381.mp3","jiārén hěn zhòngyào","family is important"],
["身體健康最重要","d-382.mp3","shēntǐ jiànkāng zuì zhòngyào","Physical health is the most important"],
["386.","d-386.mp3","386","386"],
["安靜","d-387.mp3","Ānjìng","Quiet"],
["圖書館非常安靜","d-388.mp3","túshū guǎn fēicháng ānjìng","the library is very quiet"],
["我喜歡安靜的地方","d-389.mp3","wǒ xǐhuān ānjìng dì dìfāng","i like quiet places"],
["387.","d-393.mp3","387","387"],
["年輕","d-394.mp3","Niánqīng","young"],
["年輕的時候，爸爸去過很多國家","d-395.mp3","niánqīng de shíhòu, bàba qùguò hěnduō guójiā","When I was young, my father traveled to many countries"],
["這個地方很多年輕人來玩，很熱鬧!","d-396.mp3","zhège dìfāng hěnduō niánqīng rén lái wán, hěn rènào!","Many young people come to play in this place, it is very lively!"],
["388.","d-400.mp3","388","388"],
["老","d-401.mp3","Lǎo","old"],
["公園裡有很多老人在運動","d-402.mp3","gōngyuán lǐ yǒu hěnduō lǎorén zài yùndòng","There are many old people exercising in the park"],
["爺爺看起來一點都不老","d-403.mp3","yéyé kàn qǐlái yīdiǎn dōu bùlǎo","Grandpa doesn't look old at all"],
["389.","d-407.mp3","389","389"],
["可愛","d-408.mp3","Kě'ài","cute"],
["你的狗好可愛","d-409.mp3","nǐ de gǒu hǎo kě'ài","your dog is so cute"],
["小嬰兒的臉胖胖的，好可愛","d-410.mp3","xiǎo yīng'ér de liǎn pàng pàng de, hǎo kě'ài","The baby's face is chubby, so cute"],
["390.","d-414.mp3","390","390"],
["緊張","d-415.mp3","Jǐnzhāng","nervous"],
["明天的考試我很緊張","d-416.mp3","míngtiān de kǎoshì wǒ hěn jǐnzhāng","I'm very nervous about the exam tomorrow"],
["這次的比賽，志明一點都不緊張","d-417.mp3","zhècì de bǐsài, zhìmíng yīdiǎn dōu bù jǐnzhāng","This time, Zhiming is not nervous at all."],
["391.","d-421.mp3","391","391"],
["舒服","d-422.mp3","Shūfú","Comfortable"],
["今天的天氣很舒服","d-423.mp3","jīntiān de tiānqì hěn shūfú","Today's weather is very comfortable"],
["志明說他感冒了，不太舒服","d-424.mp3","zhìmíng shuō tā gǎnmàole, bù tài shūfú","Zhiming said he had a cold and was not feeling well"],
["392.","d-428.mp3","392","392"],
["希望","d-429.mp3","Xīwàng","hope"],
["她希望明年能到德國去玩","d-430.mp3","tā xīwàng míngnián néng dào déguó qù wán","She hopes to travel to Germany next year"],
["我希望能認識很多朋友","d-431.mp3","wǒ xīwàng néng rènshí hěnduō péngyǒu","I hope to meet many friends"],
["393.","d-435.mp3","393","393"],
["歡迎","d-436.mp3","Huānyíng","welcome"],
["歡迎! 歡迎!","d-437.mp3","huānyíng! Huānyíng!","welcome!"],
["歡迎來到台灣!","d-438.mp3","Huānyíng lái dào táiwān!","Welcome to Taiwan!"],
["394.","d-442.mp3","394","394"],
["記得","d-443.mp3","Jìdé","Remember"],
["你記得我嗎?","d-444.mp3","nǐ jìdé wǒ ma?","do you remember me?"],
["記得要吃藥!","d-445.mp3","Jìdé yào chī yào!","Remember to take your medicine!"],
["395.","d-449.mp3","395","395"],
["一定要","d-450.mp3","Yīdìng yào","must"],
["你明年一定要來台灣玩!","d-451.mp3","nǐ míngnián yīdìng yào lái táiwān wán!","You must come to Taiwan to play next year!"],
["你一定要看這部電影!","d-452.mp3","Nǐ yīdìng yào kàn zhè bù diànyǐng!","You must see this movie!"],
["396.","d-456.mp3","396","396"],
["決定","d-457.mp3","Juédìng","Decide"],
["我決定了!","d-458.mp3","wǒ juédìngle!","I made a decision!"],
["我們全家決定下個月搬家","d-459.mp3","Wǒmen quánjiā juédìng xià gè yuè bānjiā","Our family has decided to move next month"],
["397.","d-463.mp3","397","397"],
["注意","d-464.mp3","Zhùyì","Notice"],
["注意安全!","d-465.mp3","zhùyì ānquán!","be safe!"],
["醫生要我多注意自己的身體!","d-466.mp3","Yīshēng yào wǒ duō zhùyì zìjǐ de shēntǐ!","The doctor told me to pay more attention to my body!"],
["398.","d-470.mp3","398","398"],
["跑步","d-471.mp3","Pǎobù","running"],
["志明喜歡早上去公園跑步","d-472.mp3","zhìmíng xǐhuān zǎoshang qù gōngyuán pǎobù","Zhiming likes to go for a run in the park in the morning"],
["醫生要我多運動、多跑步","d-473.mp3","yīshēng yào wǒ duō yùndòng, duō pǎobù","The doctor told me to exercise more and run more"],
["399.","d-477.mp3","399","399"],
["騎","d-478.mp3","Qí","ride"],
["李小美每天騎腳踏車上學","d-479.mp3","lǐxiǎoměi měitiān qí jiǎotàchē shàngxué","Li Xiaomei goes to school by bicycle every day"],
["騎摩托車不可以騎太快","d-480.mp3","qí mótuō chē bù kěyǐ qí tài kuài","Don't ride a motorcycle too fast"],
["400.","d-484.mp3","400","400"],
["踢","d-485.mp3","Tī","Kick"],
["我不會踢足球","d-486.mp3","wǒ bù huì tī zúqiú","i don't know how to play football"],
["志明什麼球都玩，但是最喜歡踢足球","d-487.mp3","zhìmíng shénme qiú dōu wán, dànshì zuì xǐhuān tī zúqiú","Zhiming can play all kinds of balls, but he likes football the most"],
["401.","d-491.mp3","401","401"],
["關","d-492.mp3","Guān","close"],
["教室的門是關著的","d-493.mp3","jiàoshì de mén shì guānzhe de","The classroom door is closed"],
["窗戶的門是關著的","d-494.mp3","chuānghù de mén shì guānzhe de","the window door is closed"],
["402.","d-498.mp3","402","402"],
["回答","d-499.mp3","Huídá","answer"],
["王太太不知道怎麼回答這個問題","d-500.mp3","wáng tàitài bù zhīdào zěnme huídá zhège wèntí","Mrs. Wang doesn't know how to answer this question"],
["李太太的回答是什麼?","d-501.mp3","lǐ tàitài de huídá shì shénme?","What is Mrs. Lee's answer?"],
["403.","d-505.mp3","403","403"],
["寄","d-506.mp3","Jì","send"],
["張太太明天要去郵局寄信","d-507.mp3","zhāng tàitài míngtiān yào qù yóujú jì xìn","Mrs. Zhang is going to post a letter at the post office tomorrow"],
["姊姊的朋友寄了一張明信片給她","d-508.mp3","jiě jie de péngyǒu jìle yī zhāng míngxìnpiàn gěi tā","My sister's friend sent her a postcard"],
["404.","d-512.mp3","404","404"],
["接","d-513.mp3","Jiē","catch"],
["謝謝你來接我們","d-514.mp3","xièxiè nǐ lái jiē wǒmen","thanks for picking us up"],
["張先生明天要開車來接我們","d-515.mp3","zhāng xiānshēng míngtiān yào kāichē lái jiē wǒmen","Mr. Zhang will drive to pick us up tomorrow"],
["405.","d-519.mp3","405","405"],
["過","d-520.mp3","Guò","Pass"],
["我過10分鐘打給你","d-521.mp3","w.Guò 10 fēnzhōng dǎ gěi nǐ","I'll call you in 10 minutes"],
["過了前面的路口，再往前走一會兒就到醫院了","d-522.mp3","guò le qiánmiàn de lùkǒu, zài wǎng qián zǒu yīhuǐ'er jiù dào yīyuànle","After passing the intersection in front, go forward for a while and you will arrive at the hospital"],
["406.","d-526.mp3","406","406"],
["游泳","d-527.mp3","Yóuyǒng","swim"],
["我平常早上八點游泳，今天晚了半個小時","d-528.mp3","wǒ píngcháng zǎoshang bā diǎn yóuyǒng, jīntiān wǎnle bàn gè xiǎoshí","I usually swim at 8 o'clock in the morning, but today I am half an hour late"],
["我一個星期游泳一次","d-529.mp3","wǒ yīgè xīngqí yóuyǒng yīcì","i swim once a week"],
["407.","d-533.mp3","407","407"],
["幫忙","d-534.mp3","Bāngmáng","help"],
["需要幫忙嗎?","d-535.mp3","xūyào bāngmáng ma?","Do you need help?"],
["學校希望老師可以多幫忙","d-536.mp3","Xuéxiào xīwàng lǎoshī kěyǐ duō bāngmáng","The school hopes that teachers can help more"],
["408.","d-540.mp3","408","408"],
["電子郵件","d-541.mp3","Diànzǐ yóujiàn","e-mail"],
["我收到你的電子郵件了","d-542.mp3","wǒ shōu dào nǐ de diànzǐ yóujiànle","I got your email"],
["電子郵件也可以說 email","d-543.mp3","diànzǐ yóujiàn yě kěyǐ shuō email","email can also say email"],
["409.","d-547.mp3","409","409"],
["冷氣(機)","d-548.mp3","Lěngqì (jī)","Air conditioner)"],
["好熱! 可以開冷氣嗎？","d-549.mp3","hǎo rè! Kěyǐ kāi lěngqì ma?","It's so hot! Can I turn on the air conditioner?"],
["冷氣機壞了","d-550.mp3","Lěngqì jī huàile","air conditioner is broken"],
["410.","d-554.mp3","410","410"],
["報紙","d-555.mp3","Bàozhǐ","newspaper"],
["這裡有英文報紙嗎?","d-556.mp3","zhèlǐ yǒu yīngwén bàozhǐ ma?","Are there any English newspapers here?"],
["希望有一天我可以看得懂中文報紙","d-557.mp3","Xīwàng yǒu yītiān wǒ kěyǐ kàn dé dǒng zhōngwén bàozhǐ","I hope one day I can read Chinese newspapers"],
["411.","d-561.mp3","411","411"],
["禮物","d-562.mp3","Lǐwù","Gift"],
["妹妹收到很多禮物","d-563.mp3","mèimei shōu dào hěnduō lǐwù","sister received many gifts"],
["樹下有很多禮物","d-564.mp3","shùxià yǒu hěnduō lǐwù","There are many gifts under the tree"],
["412.","d-568.mp3","412","412"],
["信封","d-569.mp3","Xìnfēng","envelope"],
["信封上寫著李天華三個字","d-570.mp3","xìnfēng shàng xiězhe lǐtiānhuá sān gè zì","On the envelope is written the words Li Tianhua"],
["我要去郵局買信封","d-571.mp3","wǒ yào qù yóujú mǎi xìnfēng","I'm going to the post office to buy envelopes"],
["413.","d-575.mp3","413","413"],
["地圖","d-576.mp3","Dìtú","map"],
["天華一邊開車，一邊看地圖","d-577.mp3","tiān huá yībiān kāichē, yībiān kàn dìtú","Tian Hua looked at the map while driving"],
["我不太會看地圖","d-578.mp3","wǒ bù tài huì kàn dìtú","I don't know much about maps"],
["414.","d-582.mp3","414","414"],
["眼鏡","d-583.mp3","Yǎnjìng","Glasses"],
["李小姐頭髮短短的，戴著眼鏡","d-584.mp3","lǐ xiǎojiě tóufà duǎn duǎn de, dài zhuó yǎnjìng","Miss Li has short hair and wears glasses"],
["眼鏡放在書上","d-585.mp3","yǎnjìng fàng zài shū shàng","glasses on book"],
["415.","d-589.mp3","415","415"],
["襪子","d-590.mp3","Wàzi","sock"],
["你的襪子破了","d-591.mp3","nǐ de wàzi pòle","your sock is torn"],
["他買了一雙鞋子、一雙襪子","d-592.mp3","tā mǎile yī shuāng xiézi, yī shuāng wàzi","He bought a pair of shoes, a pair of socks"],
["416.","d-596.mp3","416","416"],
["裙子","d-597.mp3","Qúnzi","skirt"],
["這件裙子有一點長","d-598.mp3","zhè jiàn qúnzi yǒu yīdiǎn zhǎng","this skirt is a little long"],
["這位老師今天穿裙子","d-599.mp3","zhè wèi lǎoshī jīntiān chuān qúnzi","This teacher is wearing a skirt today"],
["417.","d-603.mp3","417","417"],
["顏色","d-604.mp3","Yánsè","color"],
["A: 請問這個皮包有別的顏色嗎 ?","d-605.mp3","A: Qǐngwèn zhège píbāo yǒu bié de yánsè ma?","A: Is this leather bag available in other colors?"],
["B: 不好意思，別的顏色都賣完了","d-606.mp3","B: Bù hǎoyìsi, bié de yánsè dōu mài wánliǎo","B: Sorry, all other colors are sold out"],
["418.","d-610.mp3","418","418"],
["黃色","d-611.mp3","Huángsè","yellow"],
["黃色皮包賣完了","d-612.mp3","huángsè píbāo mài wánliǎo","yellow leather bag sold out"],
["姊姊最喜歡黃色","d-613.mp3","jiě jie zuì xǐhuān huángsè","sister likes yellow"],
["419.","d-617.mp3","419","419"],
["白色","d-618.mp3","Báisè","White"],
["白板是白色的","d-619.mp3","báibǎn shì báisè de","whiteboard is white"],
["醫生的外套是白色的","d-620.mp3","yīshēng de wàitào shì báisè de","doctor's coat is white"],
["420.","d-624.mp3","420","420"],
["電梯","d-625.mp3","Diàntī","elevator"],
["好累!我們搭電梯吧!","d-626.mp3","hǎo lèi! Wǒmen dā diàntī ba!","Tired! Let's take the elevator!"],
["坐電梯比較快","d-627.mp3","Zuò diàntī bǐjiào kuài","It's faster to take the elevator"],
["421.","d-631.mp3","421","421"],
["房間","d-632.mp3","Fángjiān","Room"],
["房間很乾淨","d-633.mp3","fángjiān hěn qiánjìng","room is clean"],
["小天一個星期打掃房間一次","d-634.mp3","xiǎo tiān yīgè xīngqí dǎsǎo fángjiān yīcì","Xiaotian cleans the room once a week"],
["422.","d-638.mp3","422","422"],
["廚房","d-639.mp3","Chúfáng","kitchen"],
["小明在廚房裡煮飯","d-640.mp3","xiǎomíng zài chúfáng lǐ zhǔ fàn","Xiao Ming is cooking in the kitchen"],
["新廚房又大又乾淨","d-641.mp3","xīn chúfáng yòu dà yòu qiánjìng","The new kitchen is big and clean"],
["423.","d-645.mp3","423","423"],
["洗手間","d-646.mp3","Xǐshǒujiān","Toilet"],
["A: 不好意思，洗手間在哪裡?","d-647.mp3","A: Bù hǎoyìsi, xǐshǒujiān zài nǎlǐ?","A: Excuse me, where is the restroom?"],
["B: 一直走，洗手間就在前面","d-648.mp3","B: Yīzhí zǒu, xǐshǒujiān jiù zài qiánmiàn","B: Go straight, the restroom is right in front"],
["424.","d-652.mp3","424","424"],
["廁所","d-653.mp3","Cèsuǒ","bathroom"],
["A: 請問廁所在哪裡 ？","d-654.mp3","A: Qǐngwèn cèsuǒ zài nǎlǐ?","A: Where is the toilet, please?"],
["B: 廁所在廚房旁邊","d-655.mp3","B: Cèsuǒ zài chúfáng pángbiān","B: The toilet is next to the kitchen"],
["425.","d-659.mp3","425","425"],
["窗戶","d-660.mp3","Chuānghù","window"],
["房間窗戶是關著的","d-661.mp3","fángjiān chuānghùshì guānzhe de","The room windows are closed"],
["可以把窗戶打開嗎?","d-662.mp3","kěyǐ bǎ chuānghù dǎkāi ma?","Can the window be opened?"],
["426.","d-666.mp3","426","426"],
["家具","d-667.mp3","Jiājù","furniture"],
["我們全家下個月要搬家，有不少家具","d-668.mp3","wǒmen quánjiā xià gè yuè yào bānjiā, yǒu bù shǎo jiājù","Our family is moving next month and we have a lot of furniture"],
["這些家具都好新","d-669.mp3","zhèxiē jiājù dōu hǎo xīn","These furniture are so new"],
["427.","d-673.mp3","427","427"],
["椅子","d-674.mp3","Yǐzi","Chair"],
["把椅子搬到二樓，可以嗎?","d-675.mp3","bǎ yǐzi bān dào èr lóu, kěyǐ ma?","Can you move the chair to the second floor?"],
["小張正在把椅子搬到二樓","d-676.mp3","Xiǎozhāng zhèngzài bǎ yǐzi bān dào èr lóu","Xiao Zhang is moving the chair to the second floor"],
["428.","d-680.mp3","428","428"],
["桌子","d-681.mp3","Zhuōzi","table"],
["桌子上放著三種水果","d-682.mp3","zhuōzi shàng fàng zháo sānzhǒng shuǐguǒ","three fruits on the table"],
["桌子上放著什麼水果?","d-683.mp3","zhuōzi shàng fàngzhe shénme shuǐguǒ?","What fruit is on the table?"],
["429.","d-687.mp3","429","429"],
["沙發","d-688.mp3","Shāfā","sofa"],
["弟弟躺在沙發上睡覺","d-689.mp3","dìdì tǎng zài shāfā shàng shuìjiào","brother sleeping on sofa"],
["戴著眼鏡的小女孩正在沙發上看書","d-690.mp3","dài zhuó yǎnjìng de xiǎo nǚhái zhèngzài shāfā shàng kànshū","little girl with glasses is reading a book on the sofa"],
["430.","d-694.mp3","430","430"],
["床","d-695.mp3","Chuáng","bed"],
["小天在床上睡覺","d-696.mp3","xiǎo tiān zài chuángshàng shuìjiào","Xiaotian is sleeping on the bed"],
["小男孩不舒服，躺在床上休息","d-697.mp3","xiǎo nánhái bú shūfú, tǎng zài chuángshàng xiūxí","Little boy is not feeling well, resting in bed"],
["431.","d-701.mp3","431","431"],
["休息","d-702.mp3","Xiūxí","rest"],
["小吃店九點到十二點休息","d-703.mp3","xiǎochī diàn jiǔ diǎn dào shí'èr diǎn xiūxí","The snack bar is closed from 9:00 to 12:00"],
["十二點以後可以去小吃店吃飯","d-704.mp3","shí'èr diǎn yǐhòu kěyǐ qù xiǎochī diàn chīfàn","After twelve o'clock, you can go to the snack bar to eat"],
["432.","e-01.mp3","432","432"],
["結婚","e-02.mp3","Jiéhūn","marry"],
["你什麼時候要結婚?","e-03.mp3","nǐ shénme shíhòu yào jiéhūn?","when are you going to get married"],
["結婚以後一定要有小孩嗎?","e-04.mp3","Jiéhūn yǐhòu yīdìng yào yǒu xiǎohái ma?","Do you have to have children after marriage?"],
["433.","e-08.mp3","433","433"],
["晚會","e-09.mp3","Wǎnhuì","party"],
["這次的晚會人很多","e-10.mp3","zhècì de wǎnhuì rén hěnduō","There are a lot of people at this party"],
["昨天的音樂晚會非常熱鬧","e-11.mp3","zuótiān de yīnyuè wǎnhuì fēicháng rènào","Yesterday's music evening was very lively"],
["434.","e-15.mp3","434","434"],
["不客氣","e-16.mp3","Bù kèqì","You're welcome"],
["A: 謝謝","e-17.mp3","A: Xièxiè","A: thank you"],
["B: 不客氣","e-18.mp3","B: Bù kèqì","B: You're welcome"],
["435.","e-22.mp3","435","435"],
["學","e-23.mp3","Xué","study"],
["學中文不用錢","e-24.mp3","xué zhōngwén bùyòng qián","Learning Chinese is free"],
["如果你想學中文，可以找我","e-25.mp3","rúguǒ nǐ xiǎng xué zhōngwén, kěyǐ zhǎo wǒ","If you want to learn Chinese, you can find me"],
["436.","e-29.mp3","436","436"],
["上學","e-30.mp3","Shàngxué","go to school"],
["小女孩每天騎腳踏車上學","e-31.mp3","xiǎo nǚhái měitiān qí jiǎotàchē shàngxué","little girl rides bicycle to school every day"],
["爸爸每天帶妹妹去上學","e-32.mp3","bàba měitiān dài mèimei qù shàngxué","Dad takes sister to school every day"],
["437.","e-36.mp3","437","437"],
["教室","e-37.mp3","Jiàoshì","classroom"],
["她正在教室裡寫作業","e-38.mp3","tā zhèngzài jiàoshì lǐ xiě zuòyè","She is doing homework in the classroom"],
["教室的門是關著的","e-39.mp3","jiàoshì de mén shì guānzhe de","The classroom door is closed"],
["438.","e-43.mp3","438","438"],
["語言","e-44.mp3","Yǔyán","language"],
["A: 志明會說幾種語言?","e-45.mp3","A: Zhìmíng huì shuō jǐ zhǒng yǔyán?","A: How many languages ​​does Zhiming speak?"],
["B: 他好像會說三種語言","e-46.mp3","B: Tā hǎoxiàng huì shuō sānzhǒng yǔyán","B: He seems to speak three languages"],
["439.","e-50.mp3","439","439"],
["華語","e-51.mp3","Huáyǔ","Chinese"],
["二樓是華語中心","e-52.mp3","èr lóu shì huáyǔ zhòng xīn","The second floor is the Chinese Center"],
["很多外國人來台灣學華語","e-53.mp3","hěnduō wàiguó rén lái táiwān xué huáyǔ","Many foreigners come to Taiwan to learn Chinese"],
["440.","e-57.mp3","440","440"],
["華文","e-58.mp3","Huáwén","Chinese"],
["華語教室裡有很多華文書","e-59.mp3","huáyǔ jiàoshì lǐ yǒu hěnduō huáwénshū","There are many Chinese books in the Chinese classroom"],
["這本華文書叫做「快樂學華文 」","e-60.mp3","zhè běn huá wénshū jiàozuò `kuài yuè xué huá wén'","This Chinese book is called 'Happy Learning Chinese'"],
["441.","e-64.mp3","441","441"],
["科學","e-65.mp3","Kēxué","science"],
["你相信科學嗎?","e-66.mp3","nǐ xiāngxìn kēxué ma?","do you believe in science"],
["我相信科學","e-67.mp3","Wǒ xiāngxìn kēxué","i believe in science"],
["442.","e-71.mp3","442","442"],
["作業","e-72.mp3","Zuòyè","Operation"],
["很多學生在圖書館寫作業","e-73.mp3","hěnduō xuéshēng zài túshū guǎn xiě zuòyè","Lots of students doing homework in the library"],
["為什麼作業每次都寫不完","e-74.mp3","wèishéme zuòyè měi cì dōu xiě bù wán","Why can't I finish my homework every time?"],
["443.","e-78.mp3","443","443"],
["功課","e-79.mp3","Gōngkè","homework"],
["功課寫完了嗎?","e-80.mp3","gōngkè xiě wánliǎo ma?","Have you finished your homework?"],
["記得把功課寫完!","e-81.mp3","Jìdé bǎ gōngkè xiě wán!","Remember to finish your homework!"],
["444.","e-85.mp3","444","444"],
["黑板","e-86.mp3","Hēibǎn","blackboard"],
["老師正在黑板上寫字","e-87.mp3","lǎoshī zhèngzài hēibǎn shàng xiě zì","The teacher is writing on the blackboard"],
["老師叫小美把她的名字寫在黑板上","e-88.mp3","lǎoshī jiào xiǎo měi bǎ tā de míngzì xiě zài hēibǎn shàng","The teacher asked Xiaomei to write her name on the blackboard"],
["445.","e-92.mp3","445","445"],
["課本","e-93.mp3","Kèběn","textbook"],
["各位同學! 把課本打開!","e-94.mp3","gèwèi tóngxué! Bǎ kèběn dǎkāi!","Dear students! Open your textbooks!"],
["媽，你看見我的課本嗎?","e-95.mp3","Mā, nǐ kànjiàn wǒ de kèběn ma?","Mom, have you seen my textbook?"],
["446.","e-99.mp3","446","446"],
["句子","e-100.mp3","Jùzi","sentence"],
["你會看到一個句子和三張圖片","e-101.mp3","nǐ huì kàn dào yīgè jùzi hé sān zhāng túpiàn","You will see a sentence and three pictures"],
["老師，這個句子是什麼意思?","e-102.mp3","lǎoshī, zhège jùzi shì shénme yìsi?","Teacher, what does this sentence mean?"],
["447.","e-106.mp3","447","447"],
["讀書","e-107.mp3","Dúshū","read"],
["有的學生在圖書館讀書，有的學生在寫功課","e-108.mp3","yǒu de xuéshēng zài túshū guǎn dúshū, yǒu de xuéshēng zài xiě gōngkè","Some students are reading in the library, some students are doing their homework"],
["很多老師認為讀書是最重要的事","e-109.mp3","hěnduō lǎoshī rènwéi dúshū shì zuì zhòngyào de shì","Many teachers think that reading is the most important thing"],
["448.","e-113.mp3","448","448"],
["上班","e-114.mp3","Shàngbān","work"],
["公司規定九點上班","e-115.mp3","gōngsī guīdìng jiǔ diǎn shàngbān","The company stipulates that you go to work at nine o'clock"],
["老張今天上班遲到，晚了半個小時","e-116.mp3","lǎo zhāng jīntiān shàngbān chídào, wǎnle bàn gè xiǎoshí","Lao Zhang is late for work today, half an hour late"],
["449.","e-120.mp3","449","449"],
["請假","e-121.mp3","Qǐngjià","Ask for leave"],
["我明天想請假","e-122.mp3","wǒ míngtiān xiǎng qǐngjià","I want to ask for leave tomorrow"],
["很多員工生病但是不敢請假","e-123.mp3","hěnduō yuángōng shēngbìng dànshì bù gǎn qǐngjià","Many employees are sick but afraid to ask for leave"],
["450.","e-127.mp3","450","450"],
["辦公室","e-128.mp3","Bàngōngshì","office"],
["辦公室有人嗎?","e-129.mp3","bàngōngshì yǒurén ma?","Is there anyone in the office?"],
["經理正在辦公室開會","e-130.mp3","Jīnglǐ zhèngzài bàngōngshì kāihuì","Manager is having a meeting in the office"],
["451.","e-134.mp3","451","451"],
["同事","e-135.mp3","Tóngshì","colleague"],
["我跟同事想要請假去韓國滑雪","e-136.mp3","wǒ gēn tóngshì xiǎng yàoqǐngjià qù hánguó huáxuě","My colleagues and I want to ask for leave to go skiing in South Korea"],
["今天中午老闆想要請所有同事吃飯","e-137.mp3","jīntiān zhōngwǔ lǎobǎn xiǎng yào qǐng suǒyǒu tóngshì chīfàn","Today at noon the boss wants to invite all colleagues to dinner"],
["452.","e-141.mp3","452","452"],
["工廠","e-142.mp3","Gōngchǎng","factory"],
["這間工廠又舊又髒","e-143.mp3","zhè jiān gōngchǎng yòu jiù yòu zāng","This factory is old and dirty"],
["台灣的公司有很多工廠都在中國","e-144.mp3","táiwān de gōngsī yǒu hěnduō gōngchǎng dōu zài zhōngguó","Taiwanese companies have many factories in China"],
["453.","e-148.mp3","453","453"],
["工人","e-149.mp3","Gōngrén","Worker"],
["這家工廠有幾個工人?","e-150.mp3","zhè jiā gōngchǎng yǒu jǐ gè gōngrén?","How many workers are there in this factory?"],
["這裡有一千多個工人","e-151.mp3","Zhèlǐ yǒuyīqiān duō gè gōngrén","There are more than a thousand workers here"],
["454.","e-155.mp3","454","454"],
["司機","e-156.mp3","Sījī","driver"],
["這位公車司機人很好","e-157.mp3","zhè wèi gōngchē sījī rén hěn hǎo","This bus driver is very nice"],
["司機好像不知道路在哪裡","e-158.mp3","sījī hǎoxiàng bùzhīdàolù zài nǎlǐ","The driver doesn't seem to know where the road is"],
["455.","e-162.mp3","455","455"],
["護士","e-163.mp3","Hùshì","Nurse"],
["醫院的護士不多","e-164.mp3","yīyuàn de hùshì bù duō","There are not many nurses in the hospital"],
["姊姊說希望長大以後想當護士","e-165.mp3","jiě jie shuō xīwàng zhǎngdà yǐhòu xiǎng dāng hù shì","My sister said that she wants to be a nurse when she grows up"],
["456.","e-169.mp3","456","456"],
["食物","e-170.mp3","Shíwù","food"],
["不要浪費食物!","e-171.mp3","bùyào làngfèi shíwù!","Don't waste food!"],
["夜市有各種不一樣的食物","e-172.mp3","Yèshì yǒu gèzhǒng bù yīyàng de shíwù","The night market has a variety of food"],
["457.","e-176.mp3","457","457"],
["包子","e-177.mp3","Bāozi","steamed stuffed bun"],
["這家小吃店賣的包子很好吃","e-178.mp3","zhè jiā xiǎochī diàn mài de bāozi hěn hào chī","The buns sold in this snack bar are delicious"],
["他們賣各種不一樣的包子","e-179.mp3","tāmen mài gèzhǒng bù yīyàng de bāozi","They sell all kinds of buns"],
["458.","e-183.mp3","458","458"],
["餃子","e-184.mp3","Jiǎozi","dumpling"],
["這家店什麼都不賣，只賣餃子","e-185.mp3","zhè jiā diàn shénme dōu bù mài, zhǐ mài jiǎozi","This shop doesn't sell anything but dumplings"],
["牛排比餃子貴","e-186.mp3","niúpái bǐ jiǎo zǐ guì","Steak is more expensive than dumplings"],
["459.","e-190.mp3","459","459"],
["麵","e-191.mp3","Miàn","noodle"],
["小吃店老闆說炒麵賣完了","e-192.mp3","xiǎochī diàn lǎobǎn shuō chǎomiàn mài wánliǎo","The owner of the snack shop said that the chow mein was sold out"],
["炒麵比炒飯貴一點","e-193.mp3","chǎomiàn bǐ chǎofàn guì yīdiǎn","Chow mein is a bit more expensive than fried rice"],
["460.","e-197.mp3","460","460"],
["湯","e-198.mp3","Tāng","Soup"],
["喝湯對身體好","e-199.mp3","hē tāng duì shēntǐ hǎo","Drinking soup is good for your health"],
["感冒的時候我想喝雞湯","e-200.mp3","gǎnmào de shíhòu wǒ xiǎng hē jītāng","I want to drink chicken soup when I have a cold"],
["461.","e-204.mp3","461","461"],
["蛋","e-205.mp3","Dàn","Egg"],
["冰箱裡有很多食物，有蛋、肉、還有青菜","e-206.mp3","bīngxiāng lǐ yǒu hěnduō shíwù, yǒu dàn, ròu, hái yǒu qīngcài","There are a lot of food in the refrigerator, including eggs, meat, and vegetables"],
["這顆蛋是不是壞了?","e-207.mp3","zhè kē dànshì bùshì huàile?","Is this egg broken?"],
["462.","e-211.mp3","462","462"],
["麵包","e-212.mp3","Miànbāo","bread"],
["這家店沒有賣麵包","e-213.mp3","zhè jiā diàn méiyǒu mài miànbāo","This store does not sell bread"],
["李先生正在買麵包","e-214.mp3","lǐ xiānshēng zhèngzài mǎi miànbāo","Mr. Li is buying bread"],
["463.","e-218.mp3","463","463"],
["巧克力","e-219.mp3","Qiǎokèlì","chocolate"],
["巧克力太甜了","e-220.mp3","qiǎokèlì tài tiánle","chocolate is too sweet"],
["吃太多巧克力對身體不好","e-221.mp3","chī tài duō qiǎokèlì duì shēntǐ bù hǎo","Eating too much chocolate is bad for your health"],
["464.","e-225.mp3","464","464"],
["蛋糕","e-226.mp3","Dàngāo","cake"],
["我想吃巧克力蛋糕","e-227.mp3","wǒ xiǎng chī qiǎokèlì dàngāo","i want to eat chocolate cake"],
["弟弟把冰箱的蛋糕都吃完了","e-228.mp3","dìdì bǎ bīngxiāng de dàngāo dōu chī wánliǎo","My brother ate all the cakes in the refrigerator"],
["465.","e-232.mp3","465","465"],
["水果","e-233.mp3","Shuǐguǒ","fruit"],
["冰箱裡有青菜，也有水果","e-234.mp3","bīngxiāng lǐ yǒu qīngcài, yěyǒu shuǐguǒ","There are vegetables and fruits in the refrigerator"],
["桌上有三種水果，有香蕉、西瓜、蘋果","e-235.mp3","zhuō shàng yǒusānzhǒng shuǐguǒ, yǒu xiāngjiāo, xīguā, píngguǒ","There are three kinds of fruits on the table, banana, watermelon, apple"],
["466.","e-239.mp3","466","466"],
["香蕉","e-240.mp3","Xiāngjiāo","banana"],
["台灣的香蕉很甜","e-241.mp3","táiwān de xiāngjiāo hěn tián","Bananas in Taiwan are very sweet"],
["猴子最喜歡的食物是香蕉","e-242.mp3","hóuzi zuì xǐhuān de shíwù shì xiāngjiāo","Monkey's favorite food is banana"],
["467.","e-246.mp3","467","467"],
["飲料","e-247.mp3","Yǐnliào","drinks"],
["姐姐最愛的飲料是冰紅茶","e-248.mp3","jiějiě zuì'ài de yǐnliào shì bīng hóngchá","My sister's favorite drink is iced black tea"],
["台灣的飲料店賣各種不一樣的飲料","e-249.mp3","táiwān de yǐnliào diàn mài gèzhǒng bù yīyàng de yǐnliào","Beverage shops in Taiwan sell a variety of different drinks"],
["468.","e-253.mp3","468","468"],
["牛奶","e-254.mp3","Niúnǎi","milk"],
["弟弟把冰箱的牛奶喝完","e-255.mp3","dìdì bǎ bīngxiāng de niúnǎi hē wán","My brother drank the milk in the refrigerator"],
["小天一個月沒喝咖啡了","e-256.mp3","xiǎo tiān yīgè yuè méi hē kāfēile","Xiaotian hasn't drank coffee for a month"],
["469.","e-260.mp3","469","469"],
["果汁","e-261.mp3","Guǒzhī","juice"],
["美心很愛喝果汁","e-262.mp3","měi xīn hěn ài hē guǒzhī","Maxim likes to drink juice"],
["她已經三個月沒喝果汁了","e-263.mp3","tā yǐjīng sān gè yuè méi hē guǒzhīle","She hasn't had juice for three months"],
["470.","e-267.mp3","470","470"],
["碗","e-268.mp3","Wǎn","bowl"],
["可以給我一個碗嗎?","e-269.mp3","kěyǐ gěi wǒ yīgè wǎn ma?","can i have a bowl"],
["小華剛剛吃完一碗飯","e-270.mp3","Xiǎo huá gānggāng chī wán yī wǎn fàn","Xiao Hua just finished a bowl of rice"],
["471.","e-274.mp3","471","471"],
["餓","e-275.mp3","È","Hungry"],
["媽媽在煮飯因為小孩餓了","e-276.mp3","māmā zài zhǔ fàn yīnwèi xiǎohái èle","Mom is cooking because the child is hungry"],
["妹妹剛剛吃完牛肉麵，現在又餓了","e-277.mp3","mèimei gānggāng chī wán niúròu miàn, xiànzài yòu èle","My sister just finished eating beef noodles, and now she is hungry again"],
["472.","e-281.mp3","472","472"],
["渴","e-282.mp3","Kě","thirsty"],
["我口很渴","e-283.mp3","wǒ kǒu hěn kě","I am very thirsty"],
["弟弟剛剛喝很多水，現在又渴了","e-284.mp3","dìdì gānggāng hē hěnduō shuǐ, xiànzài yòu kěle","Brother just drank a lot of water, now he is thirsty again"],
["473.","e-288.mp3","473","473"],
["甜","e-289.mp3","Tián","sweet"],
["爸爸不喜歡吃甜的東西","e-290.mp3","bàba bù xǐhuān chī tián de dōngxī","Dad doesn't like sweet things"],
["巧克力蛋糕太甜了","e-291.mp3","qiǎokèlì dàngāo tài tiánle","chocolate cake is too sweet"],
["474.","e-295.mp3","474","474"],
["菜單","e-296.mp3","Càidān","menu"],
["菜單上很多字我看不懂，但是我可以看圖片","e-297.mp3","càidān shàng hěnduō zì wǒ kàn bù dǒng, dànshì wǒ kěyǐ kàn túpiàn","I can't read many words on the menu, but I can see pictures"],
["現在很多餐廳都提供英文菜單","e-298.mp3","xiànzài hěnduō cāntīng dōu tígōng yīngwén càidān","Many restaurants now offer English menus"],
["475.","e-302.mp3","475","475"],
["盤子","e-303.mp3","Pánzi","plate"],
["我常幫媽媽洗盤子","e-304.mp3","wǒ cháng bāng māmā xǐ pánzi","I often help my mother wash the dishes"],
["把盤子擦一擦、洗一洗","e-305.mp3","bǎ pánzi cā yī cā, xǐ yī xǐ","Wipe the dishes, wash them"],
["476.","e-309.mp3","476","476"],
["杯子","e-310.mp3","Bēizi","cup"],
["這是我在英國買的杯子","e-311.mp3","zhè shì wǒ zài yīngguó mǎi de bēizi","This is the cup I bought in the UK"],
["把杯子擦一擦、洗一洗","e-312.mp3","bǎ bēizi cā yī cā, xǐ yī xǐ","Wipe the cup, wash it"],
["477.","e-316.mp3","477","477"],
["老闆","e-317.mp3","Lǎobǎn","boss"],
["老闆看起來不高興","e-318.mp3","lǎobǎn kàn qǐlái bu gāoxìng","boss looks unhappy"],
["老闆，可以給我一個碗嗎?","e-319.mp3","lǎobǎn, kěyǐ gěi wǒ yīgè wǎn ma?","Boss, can I have a bowl?"],
["478.","e-323.mp3","478","478"],
["電影院","e-324.mp3","Diànyǐngyuàn","Cinema"],
["郵局在銀行和電影院的中間","e-325.mp3","yóujú zài yínháng hé diànyǐngyuàn de zhōngjiān","The post office is between the bank and the movie theater"],
["女學生在電影院門口等她男朋友","e-326.mp3","nǚ xuéshēng zài diànyǐngyuàn ménkǒu děng tā nán péngyǒu","Schoolgirl waiting for her boyfriend at the entrance of the cinema"],
["479.","e-330.mp3","479","479"],
["表演","e-331.mp3","Biǎoyǎn","Performance"],
["小天的朋友常常帶他去看表演","e-332.mp3","xiǎo tiān de péngyǒu chángcháng dài tā qù kàn biǎoyǎn","Xiaotian's friends often take him to see performances"],
["這個表演要兩個鐘頭半","e-333.mp3","zhège biǎoyǎn yào liǎng gè zhōngtóu bàn","The show will take two and a half hours"],
["480.","e-337.mp3","480","480"],
["足球","e-338.mp3","Zúqiú","football"],
["志明最愛踢足球，也很愛看足球比賽","e-339.mp3","zhìmíng zuì'ài tī zúqiú, yě hěn ài kàn zúqiú bǐsài","Zhiming likes to play football the most, and also loves to watch football matches"],
["足球是志明最喜歡的運動","e-340.mp3","zúqiú shì zhìmíng zuì xǐhuān de yùndòng","Football is Zhiming's favorite sport"],
["481.","e-344.mp3","481","481"],
["籃球","e-345.mp3","Lánqiú","basketball"],
["很多人喜歡看籃球比賽","e-346.mp3","hěnduō rén xǐhuān kàn lánqiú bǐsài","Many people like to watch basketball games"],
["你喜歡哪一位籃球明星?","e-347.mp3","nǐ xǐhuān nǎ yī wèi lánqiú míngxīng?","Which basketball star do you like?"],
["482.","e-351.mp3","482","482"],
["網球","e-352.mp3","Wǎngqiú","tennis"],
["小安網球打得非常好","e-353.mp3","xiǎo'ān wǎngqiú dǎ dé fēicháng hǎo","Xiaoan plays tennis very well"],
["他一星期上幾次網球課?","e-354.mp3","tā yī xīngqí shàng jǐ cì wǎngqiú kè?","How many times a week does he have tennis lessons?"],
["483.","e-358.mp3","483","483"],
["棒球","e-359.mp3","Bàngqiú","baseball"],
["棒球跟籃球你喜歡哪一個?","e-360.mp3","bàngqiú gēn lánqiú nǐ xǐhuān nǎ yīgè?","Which do you like, baseball or basketball?"],
["我覺得棒球有一點無聊","e-361.mp3","Wǒ juédé bàngqiú yǒu yīdiǎn wúliáo","i think baseball is kind of boring"],
["484.","e-365.mp3","484","484"],
["比賽","e-366.mp3","Bǐsài","Contest"],
["棒球比賽要多久?","e-367.mp3","bàngqiú bǐsài yào duōjiǔ?","How long is a baseball game?"],
["明天的比賽大家都很緊張","e-368.mp3","Míngtiān de bǐsài dàjiā dōu hěn jǐnzhāng","Everyone is very nervous for tomorrow's game"],
["485.","e-372.mp3","485","485"],
["輸","e-373.mp3","Shū","lose"],
["他籃球比賽輸了","e-374.mp3","tā lánqiú bǐsài shūle","he lost the basketball game"],
["他們比賽輸了，觀眾都很難過","e-375.mp3","tāmen bǐsài shūle, guānzhòng dōu hěn nánguò","They lost the game and the audience was sad"],
["486.","e-379.mp3","486","486"],
["贏","e-380.mp3","Yíng","win"],
["贏了!","e-381.mp3","yíngle!","Won!"],
["他們比賽贏了，觀眾都非常興奮","e-382.mp3","Tāmen bǐsài yíngle, guānzhòng dōu fēicháng xīngfèn","They won the game and the audience was very excited"],
["487.","e-386.mp3","487","487"],
["上網","e-387.mp3","Shàngwǎng","go online"],
["現在的手機都能上網","e-388.mp3","xiànzài de shǒujī dōu néng shàngwǎng","Mobile phones can now access the Internet"],
["這裡沒有網路，不能上網","e-389.mp3","zhèlǐ méiyǒu wǎng lù, bùnéng shàngwǎng","There is no internet here, no internet access"],
["488.","e-393.mp3","488","488"],
["旅行","e-394.mp3","Lǚxíng","travel"],
["他們打算明年夏天去台灣旅行","e-395.mp3","tāmen dǎsuàn míngnián xiàtiān qù táiwān lǚxíng","They plan to travel to Taiwan next summer"],
["明年冬天我們全家想去日本旅行","e-396.mp3","míngnián dōngtiān wǒmen quánjiā xiǎng qù rìběn lǚxíng","Our family wants to travel to Japan next winter"],
["489.","e-400.mp3","489","489"],
["參觀","e-401.mp3","Cānguān","visit"],
["下個月我們去參觀故宮，好不好?","e-402.mp3","xià gè yuè wǒmen qù cānguān gùgōng, hǎobù hǎo?","Let's visit the Forbidden City next month, shall we?"],
["三個人一起去參觀博物館可以少付一千塊","e-403.mp3","Sān gèrén yīqǐ qù cānguān bówùguǎn kěyǐ shǎo fù yīqiān kuài","Three people can visit the museum together and pay a thousand dollars less"],
["490.","e-407.mp3","490","490"],
["照相機","e-408.mp3","Zhàoxiàngjī","camera"],
["這台照相機只要兩千多","e-409.mp3","zhè tái zhàoxiàngjī zhǐyào liǎng qiān duō","This camera only costs more than two thousand"],
["老王很喜歡用這台照相機","e-410.mp3","lǎo wáng hěn xǐhuān yòng zhè tái zhàoxiàngjī","Pharaoh likes to use this camera very much"],
["491.","e-414.mp3","491","491"],
["照相","e-415.mp3","Zhàoxiàng","take pictures"],
["可以幫我們照相嗎?","e-416.mp3","kěyǐ bāng wǒmen zhàoxiàng ma?","Can you take a picture for us?"],
["老王正在幫這些外國學生照相","e-417.mp3","Lǎo wáng zhèngzài bāng zhèxiē wàiguó xuéshēng zhàoxiàng","Lao Wang is taking pictures of these foreign students"],
["492.","e-421.mp3","492","492"],
["圖片","e-422.mp3","Túpiàn","picture"],
["我找不到這張圖片","e-423.mp3","wǒ zhǎo bù dào zhè zhāng túpiàn","i can't find this picture"],
["網路上有很多圖片，但是要小心用","e-424.mp3","wǎng lùshàng yǒu hěnduō túpiàn, dànshì yào xiǎoxīn yòng","There are many pictures on the Internet, but be careful with them"],
["493.","e-428.mp3","493","493"],
["畫","e-429.mp3","Huà","painting"],
["牆上的風景畫好美!","e-430.mp3","qiáng shàng de fēngjǐng huà hǎoměi!","Beautiful scenery on the wall!"],
["老王正畫著小天的臉","e-431.mp3","Lǎo wáng zhèng huàzhe xiǎo tiān de liǎn","Old Wang is drawing Xiaotian's face"],
["494.","e-435.mp3","494","494"],
["腳踏車","e-436.mp3","Jiǎotàchē","bicycle"],
["哥哥每天騎腳踏車上學","e-437.mp3","gēgē měitiān qí jiǎotàchē shàngxué","My brother rides his bike to school every day"],
["我喜歡一邊騎腳踏車，一邊聽音樂","e-438.mp3","wǒ xǐhuān yībiān qí jiǎotàchē, yībiān tīng yīnyuè","I like to ride my bike while listening to music"],
["495.","e-442.mp3","495","495"],
["自行車","e-443.mp3","Zìxíngchē","bike"],
["腳踏車跟自行車一樣嗎?","e-444.mp3","jiǎotàchē gēn zìxíngchē yīyàng ma?","Are bicycles the same as bicycles?"],
["騎自行車去學校太慢了","e-445.mp3","Qí zìxíngchē qù xuéxiào tài mànle","Cycling to school is too slow"],
["496.","e-449.mp3","496","496"],
["計程車","e-450.mp3","Jìchéngchē","taxi"],
["你可以坐計程車到機場","e-451.mp3","nǐ kěyǐ zuò jìchéngchē dào jīchǎng","you can take a taxi to the airport"],
["坐計程車很快就到了","e-452.mp3","zuò jìchéngchē hěn kuài jiù dàole","I'll be there soon by taxi"],
["497.","e-456.mp3","497","497"],
["火車站","e-457.mp3","Huǒchēzhàn","TRAIN STATION"],
["A: 請問火車站怎麼走?","e-458.mp3","A: Qǐngwèn huǒchēzhàn zěnme zǒu?","A: How do I get to the train station?"],
["B: 你一直往前走，車站在你的右邊","e-459.mp3","B: Nǐ yīzhí wǎng qián zǒu, chēzhàn zài nǐ de yòubiān","B: You go straight ahead, the station is on your right"],
["498.","e-463.mp3","498","498"],
["車站","e-464.mp3","Chēzhàn","station"],
["A: 請問車站在附近嗎?","e-465.mp3","A: Qǐngwèn chē zhàn zài fùjìn ma?","A: Is the station nearby?"],
["B: 對， 你過了前面的路口，再走五分鐘，就到車站了","e-466.mp3","B: Duì, nǐguòle qiánmiàn de lùkǒu, zài zǒu wǔ fēnzhōng, jiù dào chēzhànle","B: Yes, you pass the intersection ahead and walk for another five minutes to the station"],
["499.","e-470.mp3","499","499"],
["飛機","e-471.mp3","Fēijī","airplane"],
["坐飛機又舒服又快","e-472.mp3","zuò fēijī yòu shūfú yòu kuài","Flying is comfortable and fast"],
["從台北坐飛機到德國要多久?","e-473.mp3","cóng táiběi zuò fēijī dào déguó yào duōjiǔ?","How long is the flight from Taipei to Germany?"],
["500.","e-477.mp3","500","500"],
["機場","e-478.mp3","Jīchǎng","Airport"],
["你可以坐公車去松山機場","e-479.mp3","nǐ kěyǐ zuò gōngchē qù sōngshān jīchǎng","You can go to Songshan Airport by bus"],
["這個機場很新也很乾淨","e-480.mp3","zhège jīchǎng hěn xīn yě hěn qiánjìng","The airport is new and clean"],
["501.","e-484.mp3","501","501"],
["馬路","e-485.mp3","Mǎlù","road"],
["小心! 過馬路一定要小心!","e-486.mp3","xiǎoxīn!Guò mǎlù yīdìng yào xiǎoxīn!","Be careful! Be careful when crossing the road!"],
["過了這個馬路，再往前走一會兒就到醫院了","e-487.mp3","Guò le zhège mǎlù, zài wǎng qián zǒu yīhuǐ'er jiù dào yīyuànle","After crossing this road, go forward for a while and you will arrive at the hospital"],
["502.","e-491.mp3","502","502"],
["十字路口","e-492.mp3","Shízìlù kǒu","crossroads"],
["過了十字路口，再右轉就到電影院了","e-493.mp3","guò le shízìlù kǒu, zài yòu zhuǎn jiù dào diànyǐngyuànle","After crossing the intersection, turn right and you will arrive at the cinema"],
["你往前走，過了十字路口右轉，火車站就在你前面","e-494.mp3","nǐ wǎng qián zǒu,guòle shízìlù kǒu yòu zhuǎn, huǒchē zhàn jiù zài nǐ qiánmiàn","You go straight, turn right after crossing the intersection, the train station is in front of you"],
["503.","e-498.mp3","503","503"],
["小心","e-499.mp3","Xiǎoxīn","careful"],
["小心!你杯子裡的水快要滿了!","e-500.mp3","xiǎoxīn! Nǐ bēizi lǐ de shuǐ kuàiyào mǎnle!","Watch out! Your glass is almost full!"],
["小心!過馬路很危險!","e-501.mp3","Xiǎoxīn!Guò mǎlù hěn wéixiǎn!","Be careful! Crossing the road is dangerous!"],
["504.","e-505.mp3","504","504"],
["風景","e-506.mp3","Fēngjǐng","landscape"],
["老王正在看著窗戶外面的風景","e-507.mp3","lǎo wáng zhèngzài kànzhe chuānghù wàimiàn de fēngjǐng","Old Wang is looking at the scenery outside the window"],
["玉山國家公園的風景很美","e-508.mp3","yùshān guójiā gōngyuán de fēngjǐng hěn měi","The scenery of Yushan National Park is beautiful"],
["505.","e-512.mp3","505","505"],
["太陽","e-513.mp3","Tàiyáng","sun"],
["今天太陽很大","e-514.mp3","jīntiān tàiyáng hěn dà","today the sun is big"],
["小男孩正在畫太陽","e-515.mp3","xiǎo nánhái zhèngzài huà tàiyáng","little boy is drawing the sun"],
["506.","e-519.mp3","506","506"],
["湖","e-520.mp3","Hú","lake"],
["不可以在這個湖裡游泳","e-521.mp3","bù kěyǐ zài zhège hú lǐ yóuyǒng","no swimming in this lake"],
["老王常常跟他太太去湖邊散步","e-522.mp3","lǎo wáng chángcháng gēn tā tàitài qù hú biān sànbù","Lao Wang often goes for a walk by the lake with his wife"],
["507.","e-526.mp3","507","507"],
["下雨","e-527.mp3","Xià yǔ","rain"],
["現在正在下雨","e-528.mp3","xiànzài zhèngzài xià yǔ","it's raining now"],
["明天好像會下大雨","e-529.mp3","míngtiān hǎoxiàng huì xià dàyǔ","It seems to be raining tomorrow"],
["508.","e-533.mp3","508","508"],
["下雪","e-534.mp3","Xià xuě","snow"],
["下雪了！","e-535.mp3","xià xuěle!","Snowing!"],
["玉山的冬天有時候會下雪","e-536.mp3","Yùshān de dōngtiān yǒu shíhòu huì xià xuě","Yushan sometimes snows in winter"],
["509.","e-540.mp3","509","509"],
["馬","e-541.mp3","Mǎ","horse"],
["很多人來這個公園練習騎馬","e-542.mp3","hěnduō rén lái zhège gōngyuán liànxí qímǎ","Many people come to this park to practice riding"],
["騎馬的小孩看起來很緊張","e-543.mp3","qímǎ de xiǎohái kàn qǐlái hěn jǐnzhāng","Little child riding a horse looks nervous"],
["510.","e-547.mp3","510","510"],
["鳥","e-548.mp3","Niǎo","bird"],
["有幾隻小鳥停在屋子上面","e-549.mp3","yǒu jǐ zhī xiǎoniǎo tíng zài wūzi shàngmiàn","There are a few birds sitting on the house"],
["小狗正在追一隻鳥","e-550.mp3","xiǎo gǒu zhèngzài zhuī yī zhī niǎo","puppy is chasing a bird"],
["511.","e-554.mp3","511","511"],
["貓","e-555.mp3","Māo","cat"],
["小貓正在追小鳥","e-556.mp3","xiǎo māo zhèngzài zhuī xiǎoniǎo","kitten chasing bird"],
["那隻貓正在屋子裡睡覺","e-557.mp3","nà zhī māo zhèngzài wūzi lǐ shuìjiào","the cat is sleeping in the house"],
["512.","e-561.mp3","512","512"],
["雞","e-562.mp3","Jī","chicken"],
["這隻雞每天都叫我們全家起床","e-563.mp3","zhè zhī jī měitiān dū jiào wǒmen quánjiā qǐchuáng","This chicken wakes our whole family up every day"],
["院子裡有三隻雞","e-564.mp3","yuànzi lǐ yǒusān zhī jī","There are three chickens in the yard"],
["513.","f-01.mp3","513","513"],
["這些","f-02.mp3","Zhèxiē","These"],
["這些垃圾不能放在這裡","f-03.mp3","zhèxiē lèsè bùnéng fàng zài zhèlǐ","this garbage can't be put here"],
["這些樂器都是音樂老師的","f-04.mp3","zhèxiē yuèqì dōu shì yīnyuè lǎoshī de","These instruments belong to the music teacher"],
["514.","f-08.mp3","514","514"],
["那些","f-09.mp3","Nàxiē","Those ones"],
["那些機器都是老李的","f-10.mp3","nàxiē jīqì dōu shì lǎo lǐ de","Those machines belong to Lao Li"],
["把那些英文小說都給文華吧!","f-11.mp3","bǎ nàxiē yīngwén xiǎoshuō dōu gěi wénhuá ba!","Give all those English novels to Wenhua!"],
["515.","f-15.mp3","515","515"],
["這麼","f-16.mp3","Zhème","so"],
["你的房間怎麼這麼亂啊!","f-17.mp3","nǐ de fángjiān zěnme zhème luàn a!","Why is your room such a mess!"],
["李小姐的行李這麼多啊!","f-18.mp3","Lǐ xiǎojiě de xínglǐ zhème duō a!","Miss Li has so much luggage!"],
["516.","f-22.mp3","516","516"],
["那麼","f-23.mp3","Nàme","So"],
["A:明天五點可以嗎? B: 好，那(麼)明天五點見!","f-24.mp3","A: Míngtiān wǔ diǎn kěyǐ ma? B: Hǎo, nà (me) míngtiān wǔ diǎn jiàn!","A: Is five o'clock tomorrow okay? B: OK, see you at five o'clock tomorrow!"],
["我的鞋子沒有小文那麼大","f-25.mp3","Wǒ de xiézi méiyǒu xiǎo wén nàme dà","My shoes are not as big as Xiaowen"],
["517.","f-29.mp3","517","517"],
["沒有","f-30.mp3","Méiyǒu","No"],
["他沒有看過這部電影","f-31.mp3","tā méiyǒu kànguò zhè bù diànyǐng","he has not seen the movie"],
["我沒有去過台北101","f-32.mp3","wǒ méiyǒu qùguò táiběi 101","I have never been to Taipei 101"],
["518.","f-36.mp3","518","518"],
["怎麼","f-37.mp3","Zěnme","how"],
["小文怎麼哭了?","f-38.mp3","xiǎo wén zěnme kūle?","Why is Xiaowen crying?"],
["文華怎麼不來晚會了?","f-39.mp3","Wénhuá zěnme bù lái wǎnhuìle?","Why didn't Wenhua come to the party?"],
["519.","f-43.mp3","519","519"],
["怎麼辦","f-44.mp3","Zěnme bàn","what to do"],
["A: 怎麼辦! 我的錢包不見了","f-45.mp3","A: Zěnme bàn! Wǒ de qiánbāo bùjiànle","A: What to do! My wallet is missing"],
["B: 我也不知道應該怎麼辦","f-46.mp3","B: Wǒ yě bù zhī dào yīnggāi zěnme bàn","B: I don't know what to do either"],
["520.","f-50.mp3","520","520"],
["不好意思","f-51.mp3","Bù hǎoyìsi","Excuse me"],
["不好意思，請問附近有沒有銀行?","f-52.mp3","bù hǎoyìsi, qǐngwèn fùjìn yǒu méiyǒu yínháng?","Excuse me, is there a bank nearby?"],
["不好意思，請問郵局怎麼走?","f-53.mp3","Bù hǎoyìsi, qǐngwèn yóujú zěnme zǒu?","Excuse me, how do I get to the post office?"],
["521.","f-57.mp3","521","521"],
["為了","f-58.mp3","Wèile","for"],
["為了這個表演，小明每天練習吉他","f-59.mp3","wèile zhège biǎoyǎn, xiǎomíng měitiān liànxí jítā","For this performance, Xiao Ming practiced guitar every day"],
["為了這個比賽，小文每天練習三個鐘頭","f-60.mp3","wèile zhège bǐsài, xiǎo wén měitiān liànxí sān gè zhōngtóu","For this competition, Xiaowen practiced for three hours every day"],
["522.","f-64.mp3","522","522"],
["自己","f-65.mp3","Zìjǐ","Own"],
["自己的時間，應該要自己好好管理","f-66.mp3","zìjǐ de shíjiān, yīnggāi yào zìjǐ hǎohǎo guǎnlǐ","You should manage your own time well"],
["我自己的衣服，自己洗","f-67.mp3","wǒ zìjǐ de yīfú, zìjǐ xǐ","I wash my own clothes"],
["523.","f-71.mp3","523","523"],
["別人","f-72.mp3","Biérén","other people"],
["如果說話聲音太大聲的話，會吵到別人","f-73.mp3","rúguǒ shuōhuà shēngyīn tài dà shēng dehuà, huì chǎo dào biérén","If you speak too loudly, you will disturb others"],
["我做這件事情是為了自己，不是別人","f-74.mp3","wǒ zuò zhè jiàn shìqíng shì wèile zìjǐ, bùshì biérén","I do this for myself, not others"],
["524.","f-78.mp3","524","524"],
["男人","f-79.mp3","Nánrén","man"],
["是不是很多男人都不做家事?","f-80.mp3","shì bùshì hěnduō nánrén dōu bù zuò jiāshì?","Do many men not do housework?"],
["你覺得台灣的男人溫柔嗎?","f-81.mp3","Nǐ juédé táiwān de nánrén wēnróu ma?","Do you think Taiwanese men are gentle?"],
["525.","f-85.mp3","525","525"],
["女人","f-86.mp3","Nǚrén","woman"],
["很多人認為女人很愛聊天，真的嗎?","f-87.mp3","hěnduō rén rènwéi nǚrén hěn ài liáotiān, zhēn de ma?","Many people think that women love to chat, is it true?"],
["世界上，女人比較多還是男人比較多?","f-88.mp3","Shìjiè shàng, nǚrén bǐjiào duō háishì nánrén bǐjiào duō?","Are there more women or men in the world?"],
["526.","f-92.mp3","526","526"],
["大人","f-93.mp3","Dàrén","grown ups"],
["小美十八歲，已經是大人了","f-94.mp3","xiǎo měi shíbā suì, yǐjīng shì dàrénle","Xiaomei is eighteen years old, she is already an adult"],
["小良是大人但是像小孩子一樣","f-95.mp3","xiǎo liáng shì dàrén dàn shì xiàng xiǎo háizi yīyàng","Xiao Liang is an adult but acts like a child"],
["527.","f-99.mp3","527","527"],
["老人","f-100.mp3","Lǎorén","elder"],
["公園裡有很多老人正在打太極拳","f-101.mp3","gōngyuán lǐ yǒu hěnduō lǎorén zhèngzài dǎ tàijí quán","Many old people are doing Tai Chi in the park"],
["有的老人在打太極拳，有的老人在聊天","f-102.mp3","yǒu de lǎorén zài dǎ tàijí quán, yǒu de lǎorén zài liáotiān","Some old people are doing Tai Chi, some old people are chatting"],
["528.","f-106.mp3","528","528"],
["父親","f-107.mp3","Fùqīn","Father"],
["我父親是警察","f-108.mp3","wǒ fùqīn shì jǐngchá","my father is a policeman"],
["您的父親身體都好嗎?","f-109.mp3","nín de fùqīn shēntǐ dōu hǎo ma?","Is your father in good health?"],
["529.","f-113.mp3","529","529"],
["母親","f-114.mp3","Mǔqīn","Mother"],
["李先生的母親是英文老師","f-115.mp3","lǐ xiānshēng de mǔqīn shì yīngwén lǎoshī","Mr. Li's mother is an English teacher"],
["我父親住在對面的大樓","f-116.mp3","wǒ fùqīn zhù zài duìmiàn de dàlóu","my father lives in the building opposite"],
["530.","f-120.mp3","530","530"],
["父母","f-121.mp3","Fùmǔ","parents"],
["你父母都退休了嗎?","f-122.mp3","nǐ fùmǔ dōu tuìxiūle ma?","Are your parents retired?"],
["我父母都還住在台灣","f-123.mp3","Wǒ fùmǔ dōu hái zhù zài táiwān","My parents still live in Taiwan"],
["531.","f-127.mp3","531","531"],
["爺爺","f-128.mp3","Yéyé","grandfather"],
["爺爺正在房間裡畫畫","f-129.mp3","yéyé zhèngzài fángjiān lǐ huà huà","Grandpa is drawing in the room"],
["爺爺一邊運動，一邊聊天","f-130.mp3","yéyé yībiān yùndòng, yībiān liáotiān","Grandpa is exercising and chatting at the same time"],
["532.","f-134.mp3","532","532"],
["奶奶","f-135.mp3","Nǎinai","grandmother"],
["奶奶正在看著窗戶外面的風景","f-136.mp3","nǎinai zhèngzài kànzhe chuānghù wàimiàn de fēngjǐng","Grandma is looking at the scenery outside the window"],
["奶奶什麼菜都會做","f-137.mp3","nǎinai shénme cài dūhuì zuò","Grandma can cook anything"],
["533.","f-141.mp3","533","533"],
["家庭","f-142.mp3","Jiātíng","family"],
["現代的家庭跟以前的家庭不一樣","f-143.mp3","xiàndài de jiātíng gēn yǐqián de jiātíng bù yīyàng","Modern families are different from previous families"],
["這本書叫「幸福的家庭」","f-144.mp3","zhè běnshū jiào `xìngfú de jiātíng'","The book is called 'Happy Family'"],
["534.","f-148.mp3","534","534"],
["關係","f-149.mp3","Guānxì","relation"],
["他跟同事關係很好","f-150.mp3","tā gēn tóngshì guānxì hěn hǎo","He has a good relationship with his colleagues"],
["他們兩個關係很好","f-151.mp3","tāmen liǎng gè guānxì hěn hǎo","they have a good relationship"],
["535.","f-155.mp3","535","535"],
["年紀","f-156.mp3","Niánjì","age"],
["妹妹的年紀還太小，不能看這個節目","f-157.mp3","mèimei de niánjì hái tài xiǎo, bùnéng kàn zhège jiémù","My sister is too young to watch this show"],
["警察說她年紀還太小不可以騎車","f-158.mp3","jǐngchá shuō tā niánjì hái tài xiǎo bù kěyǐ qí chē","The police say she's too young to ride a bike"],
["536.","f-162.mp3","536","536"],
["位","f-163.mp3","Wèi","bit"],
["那位客人站在餐廳門口，等了很久","f-164.mp3","nà wèi kèrén zhàn zài cāntīng ménkǒu, děngle hěnjiǔ","The customer stood at the door of the restaurant and waited for a long time"],
["這位老師的頭髮短短的","f-165.mp3","zhè wèi lǎoshī de tóufà duǎn duǎn de","The teacher has short hair"],
["537.","f-169.mp3","537","537"],
["口","f-170.mp3","Kǒu","mouth"],
["給我吃一口","f-171.mp3","gěi wǒ chī yīkǒu","give me a bite"],
["這是一口井","f-172.mp3","zhè shì yīkǒu jǐng","this is a well"],
["538.","f-176.mp3","538","538"],
["班","f-177.mp3","Bān","class"],
["三點這班火車快要來了","f-178.mp3","sān diǎn zhè bān huǒchē kuàiyào láile","The train is coming at three o'clock"],
["因為颱風這班飛機會晚到","f-179.mp3","yīnwèi táifēng zhè bān fēijī huì wǎn dào","This flight will be late because of the typhoon"],
["539.","f-183.mp3","539","539"],
["隻","f-184.mp3","Zhī","Only"],
["一隻小貓和小狗正在屋子裡睡覺","f-185.mp3","yī zhī xiǎo māo hé xiǎo gǒu zhèngzài wūzi lǐ shuìjiào","A kitten and a puppy are sleeping in the house"],
["幾隻小鳥停在屋子上面","f-186.mp3","jǐ zhī xiǎoniǎo tíng zài wūzi shàngmiàn","A few birds perched on the house"],
["540.","f-190.mp3","540","540"],
["條","f-191.mp3","Tiáo","strip"],
["這條路什麼人都沒有","f-192.mp3","zhè tiáo lù shénme rén dōu méiyǒu","there's no one on this road"],
["這條路平常很熱鬧","f-193.mp3","zhè tiáo lù píngcháng hěn rènào","This road is usually very busy"],
["541.","f-197.mp3","541","541"],
["包","f-198.mp3","Bāo","Bag"],
["我幫媽媽買了兩包米","f-199.mp3","wǒ bāng māmā mǎile liǎng bāo mǐ","I bought two packs of rice for my mother"],
["我很喜歡你的皮包，在哪裡買的?","f-200.mp3","wǒ hěn xǐhuān nǐ de píbāo, zài nǎlǐ mǎi de?","I like your leather bag very much, where did you buy it?"],
["542.","f-204.mp3","542","542"],
["間","f-205.mp3","Jiān","between"],
["這間房間是客人的","f-206.mp3","zhè jiān fángjiān shì kèrén de","this room is the guest's"],
["好多人站在這間餐廳門口","f-207.mp3","hǎoduō rén zhàn zài zhè jiān cāntīng ménkǒu","There are many people standing at the door of this restaurant"],
["543.","f-211.mp3","543","543"],
["支","f-212.mp3","Zhī","branch"],
["A: 你有沒有看到我新買的那支筆?","f-213.mp3","A: Nǐ yǒu méiyǒu kàn dào wǒ xīn mǎi dì nà zhī bǐ?","A: Have you seen the new pen I bought?"],
["B: 哪一支?","f-214.mp3","B: Nǎ yī zhī?","B: Which one?"],
["544.","f-218.mp3","544","544"],
["種","f-219.mp3","Zhǒng","kind"],
["夜市有各種好吃的小吃","f-220.mp3","yèshì yǒu gèzhǒng hào chī de xiǎochī","There are all kinds of delicious snacks in the night market"],
["桌上有三種不一樣的水果","f-221.mp3","zhuō shàng yǒu sānzhǒng bù yīyàng de shuǐguǒ","There are three different fruits on the table"],
["545.","f-225.mp3","545","545"],
["遍","f-226.mp3","Biàn","all over"],
["A: 可以再說一遍嗎?","f-227.mp3","A: Kěyǐ zàishuō yībiàn ma?","A: Can you say it again?"],
["B: 我已經解釋很多遍了","f-228.mp3","B: Wǒ yǐjīng jiěshì hěnduō biànle","B: I have explained it many times"],
["546.","f-232.mp3","546","546"],
["度","f-233.mp3","Dù","Spend"],
["我女兒昨天發燒到40度","f-234.mp3","wǒ nǚ'ér zuótiān fāshāo dào 40 dù","My daughter had a fever of 40 degrees yesterday"],
["晚上非常冷，只有一度","f-235.mp3","wǎnshàng fēicháng lěng, zhǐyǒu yīdù","Very cold at night, only one degree"],
["547.","f-239.mp3","547","547"],
["公里","f-240.mp3","Gōnglǐ","kilometer"],
["這次的跑步比賽要跑10公里","f-241.mp3","zhècì de pǎobù bǐsài yào pǎo 10 gōnglǐ","This running race will run 10 kilometers"],
["從郵局到車站大概要走一公里","f-242.mp3","cóng yóujú dào chē zhàn dàgài yào zǒu yī gōnglǐ","It takes about one kilometer to walk from the post office to the station"],
["548.","f-246.mp3","548","548"],
["日期","f-247.mp3","Rìqí","date"],
["這班飛機的日期錯了","f-248.mp3","zhè bān fēijī de rìqí cuòle","The date of this flight is wrong"],
["可以幫我改日期嗎?","f-249.mp3","kěyǐ bāng wǒ gǎi rìqí ma?","Can you change the date for me?"],
["549.","f-253.mp3","549","549"],
["春","f-254.mp3","Chūn","spring"],
["春天的時候，很多人喜歡野餐","f-255.mp3","chūntiān de shíhòu, hěnduō rén xǐhuān yěcān","In spring, many people like to have a picnic"],
["春天快要到了","f-256.mp3","chūntiān kuàiyào dàole","spring is coming"],
["550.","f-260.mp3","550","550"],
["夏","f-261.mp3","Xià","summer"],
["不少人喜歡夏天的時候去海邊游泳","f-262.mp3","bù shǎo rén xǐhuān xiàtiān de shíhòu qù hǎibiān yóuyǒng","Many people like to go to the beach to swim in summer"],
["夏天的時候，台北又悶又熱","f-263.mp3","xiàtiān de shíhòu, táiběi yòu mèn yòu rè","In summer, Taipei is stuffy and hot"],
["551.","f-267.mp3","551","551"],
["秋","f-268.mp3","Qiū","Autumn"],
["秋天的天氣很舒服","f-269.mp3","qiūtiān de tiānqì hěn shūfú","The weather in autumn is very comfortable"],
["中秋節很多家庭都會烤肉","f-270.mp3","zhōngqiū jié hěnduō jiātíng dūhuì kǎoròu","During the Mid-Autumn Festival, many families will barbecue"],
["552.","f-274.mp3","552","552"],
["冬天","f-275.mp3","Dōngtiān","winter"],
["冬天很容易感冒","f-276.mp3","dōngtiān hěn róngyì gǎnmào","easy to catch a cold in winter"],
["冬天的時候，很多人喜歡去山上滑雪","f-277.mp3","dōngtiān de shíhòu, hěnduō rén xǐhuān qù shānshàng huáxuě","In winter, many people like to go skiing in the mountains"],
["553.","f-281.mp3","553","553"],
["前天","f-282.mp3","Qiántiān","the day before yesterday"],
["前天我跟弟弟去打球","f-283.mp3","qiántiān wǒ gēn dìdì qù dǎqiú","The day before yesterday I went to play with my brother"],
["小美前天和朋友去百貨公司買點心","f-284.mp3","xiǎo měi qiántiān hé péngyǒu qù bǎihuò gōngsī mǎi diǎnxīn","The day before yesterday, Xiaomei and her friends went to the department store to buy some snacks"],
["554.","f-288.mp3","554","554"],
["後天","f-289.mp3","Hòutiān","the day after tomorrow"],
["後天是李美美的生日","f-290.mp3","hòutiān shì lǐ měiměi de shēngrì","The day after tomorrow is Li Meimei's birthday"],
["後天我們全家會去野餐","f-291.mp3","hòutiān wǒmen quánjiā huì qù yěcān","The day after tomorrow our family will have a picnic"],
["555.","f-295.mp3","555","555"],
["禮拜","f-298.mp3","Lǐbài","worship"],
["明天禮拜幾?","f-296.mp3","míngtiān lǐbài jǐ?","What day is it tomorrow?"],
["一個禮拜有七天","f-297.mp3","Yīgè lǐbài yǒu qītiān","there are seven days in a week"],
["556.","f-301.mp3","556","556"],
["禮拜天","f-302.mp3","Lǐbài tiān","sunday"],
["在歐洲很多店禮拜天都關門","f-303.mp3","zài ōuzhōu hěnduō diàn lǐbài tiān dū guānmén","Many stores in Europe are closed on Sundays"],
["在台灣禮拜天很多店還是開門","f-304.mp3","zài táiwān lǐbài tiān hěnduō diàn háishì kāimén","Many shops are still open on Sunday in Taiwan"],
["557.","f-308.mp3","557","557"],
["時間","f-309.mp3","Shíjiān","time"],
["時間快到了，走吧!","f-310.mp3","shíjiān kuài dàole, zǒu ba!","The time is almost up, let's go!"],
["考試的時候要注意時間","f-311.mp3","Kǎoshì de shíhòu yào zhùyì shíjiān","Pay attention to the time when taking the exam"],
["558.","f-315.mp3","558","558"],
["晚","f-316.mp3","Wǎn","Night"],
["這麼晚了，我要趕快去睡了","f-317.mp3","zhème wǎnle, wǒ yào gǎnkuài qù shuìle","It's so late, I have to go to bed quickly"],
["小良平常都六點起床，可是今天晚了半小時","f-318.mp3","xiǎo liáng píngcháng dōu liù diǎn qǐchuáng, kěshì jīntiān wǎnle bàn xiǎoshí","Xiaoliang usually gets up at six o'clock, but today he was half an hour late"],
["559.","f-323.mp3","559","559"],
["夜","f-324.mp3","Yè","night"],
["夜裡的景色很美","f-325.mp3","yèlǐ de jǐngsè hěn měi","The scenery at night is beautiful"],
["今夜的月亮很圓","f-326.mp3","jīnyè de yuèliàng hěn yuán","The moon is very round tonight"],
["560.","f-330.mp3","560","560"],
["鐘頭","f-331.mp3","Zhōngtóu","hour"],
["看網球比賽要大概三個鐘頭","f-332.mp3","kàn wǎngqiú bǐsài yào dàgài sān gè zhōngtóu","It takes about three hours to watch a tennis match"],
["爸爸每天練習吉他一個鐘頭","f-333.mp3","bàba měitiān liànxí jítā yīgè zhōngtóu","Dad practices guitar for an hour every day"],
["561.","f-337.mp3","561","561"],
["從前","f-338.mp3","Cóngqián","once upon a time"],
["從前從前有一個小女孩","f-339.mp3","cóngqián cóngqián yǒu yīgè xiǎo nǚhái","once upon a time there was a little girl"],
["從前有一個地方很久都不下雨","f-340.mp3","cóngqián yǒu yīgè dìfāng hěnjiǔ dōu bùxià yǔ","Once upon a time there was a place where it didn't rain for a long time"],
["562.","f-344.mp3","562","562"],
["一會(兒)","f-345.mp3","Yī huǐ (er)","for a while"],
["這幾天的天氣一會兒冷， 一會兒熱","f-346.mp3","zhè jǐ tiān de tiānqì yīhuǐ'er lěng, yīhuǐ'er rè","These days the weather is sometimes cold and sometimes hot"],
["我兒子發燒了，覺得一會兒冷， 一會兒熱","f-347.mp3","wǒ ér zǐ fāshāole, juédé yīhuǐ'er lěng, yīhuǐ'er rè","My son has a fever, he feels cold for a while and hot for a while"],
["563.","f-351.mp3","563","563"],
["不久","f-352.mp3","Bùjiǔ","soon"],
["我先搬來這個社區，不久，也有很多人搬來了","f-353.mp3","wǒ xiān bān lái zhège shèqū, bùjiǔ, yěyǒu hěnduō rén bān láile","I moved to this community first, and soon, many people also moved here"],
["吃完飯，不久，爸爸就在沙發上睡著了","f-354.mp3","chī wán fàn, bùjiǔ, bàba jiù zài shāfā shàng shuìzhele","Soon after eating, Dad fell asleep on the sofa"],
["564.","f-358.mp3","564","564"],
["後來","f-359.mp3","Hòulái","later"],
["A: 後來呢?","f-360.mp3","A: Hòulái ne?","A: Then what?"],
["B: 一開始我很緊張，後來就不緊張了","f-361.mp3","B: Yī kāishǐ wǒ hěn jǐnzhāng, hòulái jiù bù jǐnzhāngle","B: I was nervous at first, but then I became less nervous"],
["565.","f-365.mp3","565","565"],
["最近","f-366.mp3","Zuìjìn","recent"],
["最近在忙什麼?","f-367.mp3","zuìjìn zài máng shénme?","What are you up to now?"],
["最近在準備考試","f-368.mp3","Zuìjìn zài zhǔnbèi kǎoshì","I'm preparing for the exam"],
["566.","f-372.mp3","566","566"],
["最後","f-373.mp3","Zuìhòu","at last"],
["最後我問他要不要來參加學校的舞會","f-374.mp3","zuìhòu wǒ wèn tā yào bùyào lái cānjiā xuéxiào de wǔhuì","Finally I asked him if he wanted to come to the school dance"],
["小美和朋友想到百貨公司買帽子，可是最後只買了點心就離開了","f-375.mp3","xiǎo měihé péngyǒu xiǎngdào bǎihuò gōngsī mǎi màozi, kěshì zuìhòu zhǐ mǎile diǎnxīn jiù líkāile","Xiaomei and her friends wanted to buy hats in a department store, but in the end they only bought some snacks and left"],
["567.","f-379.mp3","567","567"],
["邊","f-380.mp3","Biān","side"],
["他們站在窗戶旁邊討論舞會的事情","f-381.mp3","tāmen zhàn zài chuānghù pángbiān tǎolùn wǔhuì de shìqíng","They were standing by the window talking about the dance"],
["不要站在車邊，很危險","f-382.mp3","bùyào zhàn zài chē biān, hěn wéixiǎn","Don't stand next to the car, it's dangerous"],
["568.","f-386.mp3","568","568"],
["這邊","f-387.mp3","Zhè biān","here"],
["這邊不太安全","f-388.mp3","zhè biān bù tài ānquán","It's not safe here"],
["這邊的交通很方便","f-389.mp3","zhè biān de jiāotōng hěn fāngbiàn","The traffic here is very convenient"],
["569.","f-393.mp3","569","569"],
["那邊","f-394.mp3","Nà biān","there"],
["那邊不能停車","f-395.mp3","nà biān bùnéng tíngchē","no parking there"],
["小美和朋友在那邊買帽子","f-396.mp3","xiǎo měihé péngyǒu zài nà biān mǎi màozi","Xiaomei and her friends are buying hats there"],
["570.","f-400.mp3","570","570"],
["左","f-401.mp3","Zuǒ","Left"],
["左手邊那邊有一間郵局","f-402.mp3","zuǒshǒu biān nà biān yǒu yī jiàn yóujú","There's a post office over there on the left"],
["往左轉，再往前走五分鐘，就到了","f-403.mp3","wǎng zuǒ zhuǎn, zài wǎng qián zǒu wǔ fēnzhōng, jiù dàole","Turn left and walk forward for five minutes, you will arrive at"],
["571.","f-407.mp3","571","571"],
["右","f-408.mp3","Yòu","right"],
["小良站在右邊，他的弟弟站在左邊","f-409.mp3","xiǎo liáng zhàn zài yòubiān, tā de dìdì zhàn zài zuǒbiān","Xiaoliang stands on the right, his younger brother stands on the left"],
["醫院在那邊，很快，往右轉就到了","f-410.mp3","yīyuàn zài nà biān, hěn kuài, wǎng yòu zhuǎn jiù dàole","The hospital is over there, soon, turn right and you will be there"],
["572.","f-414.mp3","572","572"],
["裡","f-415.mp3","Lǐ","inside"],
["有三隻貓在房間裡","f-416.mp3","yǒu sān zhī māo zài fángjiān lǐ","There are three cats in the room"],
["屋子裡面什麼都沒有","f-417.mp3","wūzi lǐmiàn shénme dōu méiyǒu","there is nothing in the house"],
["573.","f-421.mp3","573","573"],
["方向","f-422.mp3","Fāngxiàng","direction"],
["在山上如果天氣不好，可能會找不到方向","f-423.mp3","zài shānshàng rúguǒ tiānqì bù hǎo, kěnéng huì zhǎo bù dào fāngxiàng","If the weather is bad on the mountain, it may be difficult to find your way"],
["從這個方向看不到玉山","f-424.mp3","cóng zhège fāngxiàng kàn bù dào yùshān","Yushan is not visible from this direction"],
["574.","f-428.mp3","574","574"],
["東","f-429.mp3","Dōng","East"],
["東","f-430.mp3","dōng","East"],
["東","f-431.mp3","dōng","East"],
["575.","f-435.mp3","575","575"],
["東部","f-436.mp3","Dōngbù","east"],
["我跟朋友下禮拜要去東部玩","f-437.mp3","wǒ gēn péngyǒu xià lǐbài yào qù dōngbù wán","My friend and I are going east next week"],
["台灣東部的風景特別漂亮","f-438.mp3","táiwān dōngbù de fēngjǐng tèbié piàoliang","The scenery in eastern Taiwan is particularly beautiful"],
["576.","f-442.mp3","576","576"],
["東邊","f-443.mp3","Dōngbian","east side"],
["東邊在那個方向","f-444.mp3","dōngbian zài nàgè fāngxiàng","east in that direction"],
["太陽從東邊升起","f-445.mp3","tàiyáng cóng dōngbian shēng qǐ","the sun rises from the east"],
["577.","f-449.mp3","577","577"],
["西","f-450.mp3","Xī","West"],
["西","f-451.mp3","xī","West"],
["西","f-452.mp3","xī","West"],
["578.","f-456.mp3","578","578"],
["西部","f-457.mp3","Xībù","west"],
["李阿姨最喜歡看西部牛仔電影","f-458.mp3","lǐ āyí zuì xǐhuān kàn xībù niúzǎi diànyǐng","Aunt Li likes to watch cowboy movies"],
["台灣的西部很無聊嗎?","f-459.mp3","táiwān de xībù hěn wúliáo ma?","Is the western part of Taiwan boring?"],
["579.","f-463.mp3","579","579"],
["西邊","f-464.mp3","Xībian","west side"],
["西邊是哪個方向?","f-465.mp3","xībian shì nǎge fāngxiàng?","Which direction is west?"],
["那邊就是西邊","f-466.mp3","Nà biān jiùshì xībian","that's west"],
["580.","f-470.mp3","580","580"],
["南","f-471.mp3","Nán","South"],
["南","f-472.mp3","nán","South"],
["南","f-473.mp3","nán","South"],
["581.","f-477.mp3","581","581"],
["南部","f-478.mp3","Nánbù","south"],
["我暑假要跟朋友去南部玩幾天","f-479.mp3","wǒ shǔjià yào gēn péngyǒu qù nánbù wán jǐ tiān","I will go to the south with my friends for a few days during summer vacation"],
["聽說台灣的南部人比北部人熱情","f-480.mp3","tīng shuō táiwān de nánbù rén bǐ běibù rén rèqíng","I heard that the southerners of Taiwan are more enthusiastic than the northerners"],
["582.","f-484.mp3","582","582"],
["南邊","f-485.mp3","Nánbian","the south"],
["A: 這個方向是南邊嗎?","f-486.mp3","A: Zhège fāngxiàng shì nánbian ma?","A: Is this direction south?"],
["B: 不是啦，南邊在那邊!","f-487.mp3","B: Bùshì la, nánbian zài nà biān!","B: No, the south is over there!"],
["583.","f-491.mp3","583","583"],
["北","f-492.mp3","Běi","north"],
["北","f-493.mp3","běi","north"],
["北","f-494.mp3","běi","north"],
["584.","f-498.mp3","584","584"],
["北部","f-499.mp3","Běibù","the north"],
["北部的天氣比南部冷","f-500.mp3","běibù de tiānqì bǐ nánbù lěng","The weather in the north is colder than in the south"],
["我北部的朋友要來找我","f-501.mp3","wǒ běibù de péngyǒu yào lái zhǎo wǒ","my friends from the north are coming to see me"],
["585.","f-505.mp3","585","585"],
["北邊","f-506.mp3","Běibian","the north"],
["A: 這個方向是北邊嗎?","f-507.mp3","A: Zhège fāngxiàng shì běibian ma?","A: Is this direction north?"],
["B: 地圖上寫這邊是北邊","f-508.mp3","B: Dìtú shàng xiě zhè biān shì běibian","B: It says on the map that this is the north side"],
["586.","f-512.mp3","586","586"],
["向","f-513.mp3","Xiàng","Towards"],
["故事寫 「老人一直向南走，不回頭」","f-514.mp3","gùshì xiě `lǎorén yīzhí xiàng nán zǒu, bù huítóu'","The story writes 'The old man has been walking south without looking back'"],
["你聽過一首歌叫 「一路向北」嗎 ?","f-515.mp3","nǐ tīngguò yī shǒu gē jiào `yīlù xiàng běi'ma?","Have you ever heard a song called 'All the Way North'?"],
["587.","f-519.mp3","587","587"],
["地址","f-520.mp3","Dìzhǐ","address"],
["媽媽幫妹妹把地址寫在紙上","f-521.mp3","māmā bāng mèimei bǎ dìzhǐ xiě zài zhǐshàng","Mother helping sister to write address on paper"],
["紙上寫著我們家的地址","f-522.mp3","zhǐshàng xiězhe wǒmen jiā dìdìzhǐ","The address of our home is written on the paper"],
["588.","f-526.mp3","588","588"],
["世界","f-527.mp3","Shìjiè","world"],
["這個世界有各種不同的人","f-528.mp3","zhège shìjiè yǒu gèzhǒng bùtóng de rén","There are all kinds of people in this world"],
["這個世界這麼大，去哪兒都可以","f-529.mp3","zhège shìjiè zhème dà, qù nǎ'er dōu kěyǐ","The world is so big, you can go anywhere"],
["589.","f-533.mp3","589","589"],
["國","f-534.mp3","Guó","country"],
["安同是哪一國人?","f-535.mp3","ān tóngshì nǎ yī guórén?","What country is An Tong from?"],
["每個國家有不同的文化","f-536.mp3","Měi gè guójiā yǒu bùtóng de wénhuà","Every country has a different culture"],
["590.","f-540.mp3","590","590"],
["外國","f-541.mp3","Wàiguó","foreign country"],
["夜市的入口前面有很多外國觀光客","f-542.mp3","yèshì de rùkǒu qiánmiàn yǒu hěnduō wàiguó guānguāng kè","There are many foreign tourists in front of the entrance of the night market"],
["老師叫外國學生把他們的中文名字寫在黑板上","f-543.mp3","lǎoshī jiào wàiguó xuéshēng bǎ tāmen de zhōngwén míngzì xiě zài hēibǎn shàng","The teacher asked the foreign students to write their Chinese names on the blackboard"],
["591.","f-547.mp3","591","591"],
["市","f-548.mp3","Shì","city"],
["你去過高雄市嗎?","f-549.mp3","nǐ qùguò gāoxióng shì ma?","Have you ever been to Kaohsiung City?"],
["高雄市在台灣南部","f-550.mp3","Gāoxióng shì zài táiwān nánbù","Kaohsiung city in southern Taiwan"],
["592.","f-554.mp3","592","592"],
["鄉下","f-555.mp3","Xiāngxià","countryside"],
["年輕人決定離開鄉下，去大城市找工作","f-556.mp3","niánqīng rén juédìng líkāi xiāngxià, qù dà chéngshì zhǎo gōngzuò","Young man decides to leave the countryside for a job in a big city"],
["鄉下的生活比城市生活放鬆","f-557.mp3","xiāngxià de shēnghuó bǐ chéngshì shēnghuó fàngsōng","Country life is more relaxed than city life"],
["593.","f-561.mp3","593","593"],
["書店","f-562.mp3","Shūdiàn","bookstore"],
["如果想知道這家書店的住址，請打電話02-2511-1687","f-563.mp3","rúguǒ xiǎng zhīdào zhè jiā shūdiàn de zhùzhǐ, qǐng dǎ diànhuà 02-2511-1687","If you want to know the address of this bookstore, please call 02-2511-1687"],
["這家書店有老師要我們買的書","f-564.mp3","zhè jiā shūdiàn yǒu lǎoshī yào wǒmen mǎi de shū","This bookstore has books that the teacher wants us to buy"],
["594.","f-568.mp3","594","594"],
["教堂","f-569.mp3","Jiàotáng","church"],
["星期天早上教堂門口都站著很多人","f-570.mp3","xīngqítiān zǎoshang jiàotáng ménkǒu dōu zhànzhe hěnduō rén","There are many people standing at the door of the church on Sunday morning"],
["很多人喜歡在教堂結婚","f-571.mp3","hěnduō rén xǐhuān zài jiàotáng jiéhūn","Many people like to get married in church"],
["595.","f-575.mp3","595","595"],
["橋","f-576.mp3","Qiáo","bridge"],
["很多外國觀光客正在橋上拍照","f-577.mp3","hěnduō wàiguó guānguāng kè zhèngzài qiáoshàng pāizhào","Many foreign tourists are taking pictures on the bridge"],
["這座橋太高了! 好恐怖","f-578.mp3","zhè zuò qiáo tài gāole! Hǎo kǒngbù","The bridge is so high! It's scary"],
["596.","f-582.mp3","596","596"],
["臉","f-583.mp3","Liǎn","Face"],
["小天的臉上髒髒的","f-584.mp3","xiǎo tiān de liǎn shàng zāng zāng de","Xiaotian's face is dirty"],
["老王正畫著小天的臉","f-585.mp3","lǎo wáng zhèng huàzhe xiǎo tiān de liǎn","Old Wang is drawing Xiaotian's face"],
["597.","f-589.mp3","597","597"],
["鼻子","f-590.mp3","Bízi","nose"],
["弟弟的鼻子一直流鼻水","f-591.mp3","dìdì de bízi yīzhí liú bíshuǐ","My brother has a runny nose"],
["我鼻子有一點痛，一直流鼻水","f-592.mp3","wǒ bízi yǒuyīdiǎn tòng, yīzhí liú bíshuǐ","My nose hurts a little and I keep running nose"],
["598.","f-596.mp3","598","598"],
["嘴巴","f-597.mp3","Zuǐbā","mouth"],
["天美的臉很漂亮，嘴巴小小的","f-598.mp3","tiān měide liǎn hěn piàoliang, zuǐbā xiǎo xiǎo de","Tianmei has a beautiful face and a small mouth"],
["你嘴巴旁邊有東西","f-599.mp3","nǐ zuǐbā pángbiān yǒu dōngxī","there's something next to your mouth"],
["599.","f-603.mp3","599","599"],
["脖子","f-604.mp3","Bózi","neck"],
["我跟同事用電腦用太久，脖子都很痛","f-605.mp3","wǒ gēn tóng shì yòng diànnǎo yòng tài jiǔ, bózi dōu hěn tòng","My neck hurts from using the computer with my colleagues for too long"],
["如果脖子還是不舒服，就去看醫生吧!","f-606.mp3","rúguǒ bó zǐ huán shì bú shūfú, jiù qù kàn yīshēng ba!","If your neck is still uncomfortable, see your doctor!"],
["600.","f-610.mp3","600","600"],
["背","f-611.mp3","Bèi","back"],
["A: 我騎車騎了五個小時，背非常痛","f-612.mp3","A: Wǒ qí chē qíle wǔ gè xiǎoshí, bèi fēicháng tòng","A: I rode for five hours and my back hurts so much"],
["B: 要不要先躺下來?!","f-613.mp3","B: Yào bùyào xiān tǎng xiàlái?!","B: Do you want to lie down first?!"],
["601.","f-617.mp3","601","601"],
["腿","f-618.mp3","Tuǐ","leg"],
["那個明星的腿好長 !","f-619.mp3","nàgè míngxīng de tuǐ hǎo zhǎng!","That star's legs are so long!"],
["如果腿還是很痛，就休息一下吧!","f-620.mp3","Rúguǒ tuǐ háishì hěn tòng, jiù xiūxí yīxià ba!","If your legs are still sore, take a break!"],
["602.","f-624.mp3","602","602"],
["腳","f-625.mp3","Jiǎo","foot"],
["小李走路走了三個小時才到家，所以腳非常痛","f-626.mp3","xiǎo lǐ zǒulù zǒule sān gè xiǎoshí cái dàojiā, suǒyǐ jiǎo fēicháng tòng","Xiao Li walked for three hours to get home, so his feet hurt very much"],
["如果腳很痛，那就休息一下吧!","f-627.mp3","rúguǒ jiǎo hěn tòng, nà jiù xiūxí yīxià ba!","If your feet hurt, take a break!"],
["603.","f-631.mp3","603","603"],
["健康","f-632.mp3","Jiànkāng","healthy"],
["李阿姨平常吃得很健康","f-633.mp3","lǐ āyí píngcháng chī dé hěn jiànkāng","Aunt Li usually eats very healthy"],
["媽媽常常說身體健康最重要","f-634.mp3","māmā chángcháng shuō shēntǐ jiànkāng zuì zhòngyào","My mother always said that health is the most important thing"],
["604.","f-638.mp3","604","604"],
["病","f-639.mp3","Bìng","sick"],
["我生病了","f-640.mp3","wǒ shēngbìngle","I am sick"],
["李小姐病了好幾天，今天回到公司上班了","f-641.mp3","lǐ xiǎojiě bìngle hǎo jǐ tiān, jīntiān huí dào gōngsī shàngbānle","Ms. Li has been ill for several days, and she returned to work today"],
["605.","f-645.mp3","605","605"],
["咳嗽","f-646.mp3","Késòu","cough"],
["天明一直咳嗽，咳了好幾天了","f-647.mp3","tiānmíng yīzhí késòu, hāile hǎo jǐ tiānle","Tianming has been coughing for several days."],
["他咳嗽好幾天了，已經吃了藥了","f-648.mp3","tā késòu hǎo jǐ tiānle, yǐjīng chīle yàole","He has been coughing for several days and has already taken medicine"],
["606.","f-652.mp3","606","606"],
["發燒","f-653.mp3","Fāshāo","fever"],
["我發燒了","f-654.mp3","wǒ fāshāole","I have a fever"],
["李小姐的女兒發燒到40度","f-655.mp3","lǐ xiǎojiě de nǚ'ér fāshāo dào 40 dù","Miss Li's daughter had a fever of 40 degrees"],
["607.","f-659.mp3","607","607"],
["疼","f-660.mp3","Téng","pain"],
["我的牙齒很疼，不想去唱歌","f-661.mp3","wǒ de yáchǐ hěn téng, bùxiǎng qù chànggē","My teeth hurt so bad I don't want to sing"],
["如果你牙齒還是很疼，就趕快去看牙醫!","f-662.mp3","rúguǒ nǐ yáchǐ háishì hěn téng, jiù gǎnkuài qù kàn yáyī!","If your tooth still hurts, go to the dentist immediately!"],
["608.","f-666.mp3","608","608"],
["病人","f-667.mp3","Bìngrén","patient"],
["這位護士正在照顧病人","f-668.mp3","zhè wèi hùshì zhèngzài zhàogù bìngrén","The nurse is taking care of the patient"],
["那位牙醫正在跟病人解釋為什麼他牙齒疼","f-669.mp3","nà wèi yáyī zhèngzài gēn bìngrén jiěshì wèishéme tā yáchǐ téng","The dentist is explaining to the patient why his tooth hurts"],
["609.","f-673.mp3","609","609"],
["救護車","f-674.mp3","Jiùhù chē","ambulance"],
["趕快叫救護車！","f-675.mp3","gǎnkuài jiào jiùhù chē!","Call an ambulance quickly!"],
["剛剛救護車經過","f-676.mp3","Gānggāng jiùhù chē jīngguò","an ambulance just passed by"],
["610.","f-680.mp3","610","610"],
["救命","f-681.mp3","Jiùmìng","Help"],
["救命 !","f-682.mp3","jiùmìng!","Help !"],
["醫生救了我一命","f-683.mp3","Yīshēng jiùle wǒ yī mìng","doctor saved my life"],
["611.","f-687.mp3","611","611"],
["掛號","f-688.mp3","Guàhào","registered"],
["早上八點開始可以網路掛號","f-689.mp3","zǎoshang bā diǎn kāishǐ kěyǐ wǎng lù guàhào","Online registration is available from 8:00 am"],
["如果不舒服的話，我現在幫你去醫院掛號","f-690.mp3","rúguǒ bú shūfú dehuà, wǒ xiànzài bāng nǐ qù yīyuàn guàhào","If you feel uncomfortable, I will help you go to the hospital to register now"],
["612.","f-694.mp3","612","612"],
["看病","f-695.mp3","Kànbìng","see a doctor"],
["如果還是頭痛，就趕快去看病","f-696.mp3","rúguǒ háishì tóutòng, jiù gǎnkuài qù kànbìng","If you still have a headache, go see a doctor"],
["很多病人正在診所門口前等看病","f-697.mp3","hěnduō bìngrén zhèngzài zhěnsuǒ ménkǒu qián děng kànbìng","Many patients are waiting in front of the clinic"],
["613.","g-01.mp3","613","613"],
["藥房","g-02.mp3","Yàofáng","pharmacy"],
["可以幫我去藥房拿藥嗎?","g-03.mp3","kěyǐ bāng wǒ qù yàofáng ná yào ma?","Can you help me go to the pharmacy to get my medicine?"],
["如果還是不舒服，就趕快去藥房買藥","g-04.mp3","Rúguǒ háishì bú shūfú, jiù gǎnkuài qù yàofáng mǎi yào","If you still feel unwell, go to the pharmacy to buy medicine"],
["614.","g-08.mp3","614","614"],
["恢復","g-09.mp3","Huīfù","recover"],
["你的身體恢復的很好","g-10.mp3","nǐ de shēntǐ huīfù de hěn hǎo","your body is recovering well"],
["爺爺的傷口恢復的很好","g-11.mp3","yéyé de shāngkǒu huīfù de hěn hǎo","Grandpa's wound is recovering well"],
["615.","g-15.mp3","615","615"],
["保險","g-16.mp3","Bǎoxiǎn","Insurance"],
["這張卡叫做健康保險卡，也叫健保卡","g-17.mp3","zhè zhāng kǎ jiàozuò jiànkāng bǎoxiǎn kǎ, yě jiào jiànbǎo kǎ","This card is called a health insurance card, also called a health insurance card"],
["妳有保險嗎？","g-18.mp3","nǎi yǒu bǎoxiǎn ma?","do you have insurance"],
["616.","g-22.mp3","616","616"],
["牙刷","g-23.mp3","Yáshuā","toothbrush"],
["浴室裡有兩隻牙刷","g-24.mp3","yùshì lǐ yǒu liǎng zhī yáshuā","There are two toothbrushes in the bathroom"],
["這是我的牙刷喔，不是你的!","g-25.mp3","zhè shì wǒ de yáshuā ō, bùshì nǐ de!","This is my toothbrush, not yours!"],
["617.","g-29.mp3","617","617"],
["早","g-30.mp3","Zǎo","morning"],
["張經理希望所有員工明天早一個小時到公司","g-31.mp3","zhāng jīnglǐ xīwàng suǒyǒu yuángōng míngtiān zǎo yīgè xiǎoshí dào gōngsī","Manager Zhang hopes that all employees will arrive at the company an hour earlier tomorrow"],
["沒有人想要這麼早到","g-32.mp3","méiyǒu rén xiǎng yào zhème zǎo dào","no one wants to arrive so early"],
["618.","g-36.mp3","618","618"],
["輕","g-37.mp3","Qīng","light"],
["張太太的皮包又輕又小","g-38.mp3","zhāng tàitài de píbāo yòu qīng yòu xiǎo","Mrs. Zhang's purse is light and small"],
["李小姐只帶了一個很輕的行李","g-39.mp3","lǐ xiǎojiě zhǐ dàile yīgè hěn qīng de xínglǐ","Miss Li only brought a very light luggage"],
["619.","g-43.mp3","619","619"],
["重","g-44.mp3","Zhòng","Heavy"],
["李小姐的行李一點也不重","g-45.mp3","lǐ xiǎojiě de xínglǐ yīdiǎn yě bù chóng","Miss Li's luggage is not heavy at all"],
["你的行李太重了，不能帶上飛機喔!","g-46.mp3","nǐ de xínglǐ tài zhòngle, bùnéng dài shàng fēijī ō!","Your luggage is too heavy to take on the plane!"],
["620.","g-50.mp3","620","620"],
["舊","g-51.mp3","Jiù","old"],
["這個舊衣櫃太重了","g-52.mp3","zhège jiù yīguì tài zhòngle","This old wardrobe is too heavy"],
["這些舊的英文小說還要嗎？","g-53.mp3","zhèxiē jiù de yīngwén xiǎoshuō hái yào ma?","Do you still want these old English novels?"],
["621.","g-57.mp3","621","621"],
["好","g-58.mp3","Hǎo","good"],
["小文在旅行的時候，遇到了很多好人","g-59.mp3","xiǎo wén zài lǚxíng de shíhòu, yù dàole hěnduō hǎorén","When Xiaowen was traveling, he met many good people"],
["來台灣以後，小文交了很幾個好朋友","g-60.mp3","lái táiwān yǐhòu, xiǎo wén jiāole hěn jǐ gè hǎo péngyǒu","After coming to Taiwan, Xiaowen made many good friends"],
["622.","g-64.mp3","622","622"],
["美","g-65.mp3","Měi","beautiful"],
["太魯閣國家公園的風景很美","g-66.mp3","tài lǔ gé guójiā gōngyuán de fēngjǐng hěn měi","The scenery of Taroko National Park is beautiful"],
["你看! 風景好美!","g-67.mp3","nǐ kàn! Fēngjǐng hǎoměi!","Look! The scenery is so beautiful!"],
["623.","g-71.mp3","623","623"],
["美麗","g-72.mp3","Měilì","beauty"],
["從前從前，有一位美麗的白雪公主","g-73.mp3","cóngqián cóngqián, yǒuyī wèi měilì de báixuě gōngzhǔ","Once upon a time, there was a beautiful Snow White"],
["不久，美麗公主嫁給了王子","g-74.mp3","bùjiǔ, měilì gōngzhǔ jià gěile wángzǐ","Soon, the beautiful princess married the prince"],
["624.","g-78.mp3","624","624"],
["圓","g-79.mp3","Yuán","round"],
["月亮又亮又圓","g-80.mp3","yuèliàng yòu liàng yòu yuán","the moon is bright and round"],
["小嬰兒的臉圓圓的","g-81.mp3","xiǎo yīng'ér de liǎn yuán yuán de","round baby face"],
["625.","g-85.mp3","625","625"],
["涼快","g-86.mp3","Liángkuai","cool"],
["有一些人去圖書館睡覺，因為又安靜又涼快","g-87.mp3","yǒu yīxiē rén qù túshū guǎn shuìjiào, yīnwèi yòu ānjìng yòu liángkuai","Some people go to the library to sleep because it is quiet and cool"],
["秋天的天氣很涼快","g-88.mp3","qiūtiān de tiānqì hěn liángkuai","autumn weather is cool"],
["626.","g-92.mp3","626","626"],
["暖和","g-93.mp3","Nuǎnhuo","warm"],
["現在天氣很暖和","g-94.mp3","xiànzài tiānqì hěn nuǎnhuo","it's warm now"],
["春天的天氣很暖活","g-95.mp3","chūntiān de tiānqì hěn nuǎn huó","The weather in spring is very warm"],
["627.","g-99.mp3","627","627"],
["溫暖","g-100.mp3","Wēnnuǎn","warmth"],
["這碗湯溫暖了我的心","g-101.mp3","zhè wǎn tāng wēnnuǎnle wǒ de xīn","This bowl of soup warms my heart"],
["外面好冷，房間裡面好溫暖","g-102.mp3","wàimiàn hǎo lěng, fángjiān lǐmiàn hǎo wēnnuǎn","It's cold outside, but it's warm inside"],
["628.","g-106.mp3","628","628"],
["乾","g-107.mp3","Qián","Dry"],
["A: 幫我看一下! 衣服乾了嗎?","g-108.mp3","A: Bāng wǒ kàn yīxià! Yīfú qiánle ma?","A: Check it out for me! Are the clothes dry?"],
["B: 還沒乾","g-109.mp3","B: Hái méi qián","B: Not dry yet"],
["629.","g-113.mp3","629","629"],
["乾淨","g-114.mp3","Qiánjìng","clean"],
["天華的房間怎麼這麼乾淨?","g-115.mp3","tiān huá de fángjiān zěnme zhème qiánjìng?","Why is Tianhua's room so clean?"],
["他的房間很乾淨","g-116.mp3","Tā de fángjiān hěn qiánjìng","his room is clean"],
["630.","g-120.mp3","630","630"],
["髒","g-121.mp3","Zāng","dirty"],
["房間這麼髒，你今天一定要打掃!","g-122.mp3","fángjiān zhème zāng, nǐ jīntiān yīdìng yào dǎsǎo!","The room is so dirty, you must clean it today!"],
["弟弟的房間很髒也很亂","g-123.mp3","Dìdì de fángjiān hěn zāng yě hěn luàn","My brother's room is dirty and messy"],
["631.","g-127.mp3","631","631"],
["簡單","g-128.mp3","Jiǎndān","Simple"],
["這件事情一點也不簡單","g-129.mp3","zhè jiàn shìqíng yīdiǎn yě bù jiǎndān","it's not easy at all"],
["你覺得中文簡單嗎？","g-130.mp3","nǐ juédé zhōngwén jiǎndān ma?","Do you think Chinese is easy?"],
["632.","g-134.mp3","632","632"],
["有用","g-135.mp3","Yǒuyòng","it works"],
["老師的方法很有用","g-136.mp3","lǎoshī de fāngfǎ hěn yǒuyòng","The teacher's method is very useful"],
["這本課本很有用","g-137.mp3","zhè běn kèběn hěn yǒuyòng","this textbook is very useful"],
["633.","g-141.mp3","633","633"],
["危險","g-142.mp3","Wéixiǎn","Danger"],
["這個社區一點也不危險，很安全","g-143.mp3","zhège shèqū yīdiǎn yě bù wéixiǎn, hěn ānquán","This neighborhood is not dangerous at all, it's safe"],
["跟網路上不認識的人見面很危險","g-144.mp3","gēn wǎng lùshàng bù rènshí de rén jiànmiàn hěn wéixiǎn","Meeting someone you don't know online is dangerous"],
["634.","g-148.mp3","634","634"],
["社區","g-149.mp3","Shèqū","Community"],
["安全真的很重要！","g-150.mp3","ānquán zhēn de hěn zhòngyào!","Safety is really important!"],
["警察說這個社區晚上不安全","g-151.mp3","Jǐngchá shuō zhège shèqū wǎnshàng bù ānquán","Police say this neighborhood is not safe at night"],
["635.","g-155.mp3","635","635"],
["對","g-156.mp3","Duì","right"],
["這樣對嗎?","g-157.mp3","zhèyàng duì ma?","is this correct?"],
["偷東西是不對的","g-158.mp3","Tōu dōngxī shì bùduì de","stealing is wrong"],
["636.","g-162.mp3","636","636"],
["錯","g-163.mp3","Cuò","wrong"],
["我說錯了","g-164.mp3","wǒ shuō cuòle","I was wrong"],
["你打錯電話了","g-165.mp3","nǐ dǎ cuò diànhuàle","you've got the wrong number"],
["637.","g-169.mp3","637","637"],
["熱鬧","g-170.mp3","Rènào","lively"],
["昨天的演唱會非常熱鬧","g-171.mp3","zuótiān de yǎnchàng huì fēicháng rènào","Yesterday's concert was very lively"],
["這個音樂晚會一點也不熱鬧","g-172.mp3","zhège yīnyuè wǎnhuì yīdiǎn yě bù rènào","this music evening is not lively at all"],
["638.","g-176.mp3","638","638"],
["吵","g-177.mp3","Chǎo","Quarrel"],
["樓上的鄰居好吵！","g-178.mp3","lóu shàng de línjū hǎo chǎo!","The upstairs neighbors are so noisy!"],
["你講話太大聲了，不要吵到別人","g-179.mp3","Nǐ jiǎnghuà tài dà shēngle, bùyào chǎo dào biérén","You speak too loudly, don't disturb others"],
["639.","g-183.mp3","639","639"],
["有空","g-184.mp3","Yǒu kòng","available"],
["A: 你下個禮拜有空嗎?","g-185.mp3","A: Nǐ xià gè lǐbài yǒu kòng ma?","A: Are you free next week?"],
["B: 禮拜五、禮拜六三點以後都有空","g-186.mp3","B: Lǐbài wǔ, lǐbài liùsān diǎn yǐhòu dōu yǒu kòng","B: I'm free after 3 o'clock on Friday and Saturday"],
["640.","g-190.mp3","640","640"],
["滿","g-191.mp3","Mǎn","Full"],
["這家餐廳客滿了","g-192.mp3","zhè jiā cāntīng kè mǎnle","this restaurant is full"],
["小心!你杯子裡的水快要滿了!","g-193.mp3","xiǎoxīn! Nǐ bēizi lǐ de shuǐ kuàiyào mǎnle!","Watch out! Your glass is almost full!"],
["641.","g-197.mp3","641","641"],
["飽","g-198.mp3","Bǎo","full"],
["我吃飽了","g-199.mp3","wǒ chī bǎole","I'm stuffed"],
["我真的吃飽了，吃不下了","g-200.mp3","wǒ zhēn de chī bǎole, chī bùxiàle","I'm really full, I can't eat anymore"],
["642.","g-204.mp3","642","642"],
["夠","g-205.mp3","Gòu","enough"],
["可能你水喝得不夠多，應該要多喝水","g-206.mp3","kěnéng nǐ shuǐ hē dé bùgòu duō, yīnggāi yào duō hē shuǐ","Maybe you don't drink enough water, you should drink more water"],
["爸爸常常說 「錢夠用就好」","g-207.mp3","bàba chángcháng shuō `qián gòu yòng jiù hǎo'","Dad always said 'It's enough money'"],
["643.","g-211.mp3","643","643"],
["清楚","g-212.mp3","Qīngchǔ","clear"],
["我不太清楚後來發生的事","g-213.mp3","wǒ bù tài qīngchǔ hòulái fāshēng de shì","I don't know what happened next"],
["老師解釋得很清楚","g-214.mp3","lǎoshī jiěshì dé hěn qīngchǔ","The teacher explained clearly"],
["644.","g-218.mp3","644","644"],
["奇怪","g-219.mp3","Qíguài","strangeness"],
["這件事情太奇怪了","g-220.mp3","zhè jiàn shìqíng tài qíguàile","this thing is so weird"],
["這裡晚上有很多奇怪的人","g-221.mp3","zhèlǐ wǎnshàng yǒu hěnduō qíguài de rén","There are a lot of strange people here at night"],
["645.","g-225.mp3","645","645"],
["客氣","g-226.mp3","Kèqì","polite"],
["你太客氣了","g-227.mp3","nǐ tài kèqìle","you are too polite"],
["天明的女朋友很客氣又有禮貌","g-228.mp3","tiānmíng de nǚ péngyǒu hěn kèqì yòu yǒu lǐmào","Tianming's girlfriend is very polite and polite"],
["646.","g-232.mp3","646","646"],
["聰明","g-233.mp3","Cōngmíng","clever"],
["天華是個很聰明的學生","g-234.mp3","tiān huá shìgè hěn cōngmíng de xuéshēng","Tianhua is a very clever student"],
["美心很聰明也很努力","g-235.mp3","měi xīn hěn cōngmíng yě hěn nǔlì","Meixin is smart and hardworking"],
["647.","g-239.mp3","647","647"],
["認真","g-240.mp3","Rènzhēn","serious"],
["很多學生為了考試正在圖書館認真準備","g-241.mp3","hěnduō xuéshēng wèile kǎoshì zhèngzài túshū guǎn rènzhēn zhǔnbèi","Many students are seriously preparing for the exam in the library"],
["美心很聰明也很認真","g-242.mp3","měi xīn hěn cōngmíng yě hěn rènzhēn","Mei Xin is very smart and serious"],
["648.","g-246.mp3","648","648"],
["勇敢","g-247.mp3","Yǒnggǎn","brave"],
["這個小孩什麼都不怕，非常勇敢","g-248.mp3","zhège xiǎohái shénme dōu bùpà, fēicháng yǒnggǎn","This kid is not afraid of anything, very brave"],
["如果做錯事，就要勇敢承認","g-249.mp3","rúguǒ zuò cuò shì, jiù yào yǒnggǎn chéngrèn","If you do something wrong, be brave enough to admit it"],
["649.","g-253.mp3","649","649"],
["害羞","g-254.mp3","Hàixiū","shy"],
["美心第一次跟網路上認識的人見面，很害羞","g-255.mp3","měi xīn dì yī cì gēn wǎng lùshàng rènshí de rén jiànmiàn, hěn hàixiū","Meixin was very shy when meeting someone she met online for the first time"],
["小文不想跟朋友一起唱歌，太害羞了","g-256.mp3","xiǎo wén bùxiǎng gēn péngyǒu yīqǐ chànggē, tài hàixiūle","Xiaowen doesn't want to sing with her friends, she's too shy"],
["650.","g-260.mp3","650","650"],
["真","g-261.mp3","Zhēn","real"],
["跟網友見面真害羞","g-262.mp3","gēn wǎngyǒu jiànmiàn zhēn hàixiū","I'm so shy to meet friends"],
["那個小女孩真勇敢","g-263.mp3","nàgè xiǎo nǚhái zhēn yǒnggǎn","that little girl is so brave"],
["651.","g-267.mp3","651","651"],
["特別","g-268.mp3","Tèbié","special"],
["大文是一個很特別的人","g-269.mp3","dà wén shì yīgè hěn tèbié de rén","Dawen is a very special person"],
["小張特別喜歡早上去運動","g-270.mp3","xiǎozhāng tèbié xǐhuān zǎoshang qù yùndòng","Xiao Zhang especially likes to go to exercise in the morning"],
["652.","g-274.mp3","652","652"],
["一般","g-275.mp3","Yībān","generally"],
["一般學生不能進來這個教室","g-276.mp3","yībān xuéshēng bùnéng jìnlái zhège jiàoshì","Ordinary students are not allowed to enter this classroom"],
["小孩子一般都很怕痛","g-277.mp3","xiǎo háizi yībān dōu hěn pà tòng","Children are generally afraid of pain"],
["653.","g-281.mp3","653","653"],
["不同","g-282.mp3","Bùtóng","different"],
["小文跟大家介紹各種不同的食物","g-283.mp3","xiǎo wén gēn dàjiā jièshào gèzhǒng bùtóng de shíwù","Xiaowen introduces various foods to you"],
["地鐵跟捷運有什麼不同?","g-284.mp3","dìtiě gēn jié yùn yǒu shé me bùtóng?","What is the difference between subway and MRT?"],
["654.","g-288.mp3","654","654"],
["差","g-289.mp3","Chà","Difference"],
["小華的記憶很差","g-290.mp3","xiǎo huá de jìyì hěn chà","Xiaohua's memory is very poor"],
["老師說這次大家考試都考得很差","g-291.mp3","lǎoshī shuō zhècì dàjiā kǎoshì dōu kǎo dé hěn chà","The teacher said that everyone did very poorly in the exam this time."],
["655.","g-295.mp3","655","655"],
["差不多","g-296.mp3","Chàbùduō","almost"],
["哥哥跟弟弟差不多高","g-297.mp3","gēgē gēn dìdì chàbùduō gāo","Brother is about the same height as brother"],
["我們差不多重","g-298.mp3","wǒmen chàbùduō chóng","we weigh about the same"],
["656.","g-302.mp3","656","656"],
["樣子","g-303.mp3","Yàngzi","look like"],
["老闆的樣子看起來很累","g-304.mp3","lǎobǎn de yàngzi kàn qǐlái hěn lèi","Boss looks tired"],
["老師上完課以後，看起來很開心的樣子","g-305.mp3","lǎoshī shàng wán kè yǐhòu, kàn qǐlái hěn kāixīn de yàngzi","The teacher looked very happy after the class"],
["657.","g-309.mp3","657","657"],
["愉快","g-310.mp3","Yúkuài","joy"],
["乾淨的房間，讓人覺得心情很愉快","g-311.mp3","qiánjìng de fángjiān, ràng rén juédé xīnqíng hěn yúkuài","Clean room, makes people feel very happy"],
["妹妹看完信以後，心情覺得很愉快","g-312.mp3","mèimei kàn wán xìn yǐhòu, xīnqíng juédé hěn yúkuài","After reading the letter, my sister felt very happy"],
["658.","g-316.mp3","658","658"],
["開心","g-317.mp3","Kāixīn","happy"],
["今天聊天聊得很開心","g-318.mp3","jīntiān liáotiān liáo dé hěn kāixīn","I had a great time chatting today"],
["今天真的很開心","g-319.mp3","jīntiān zhēn de hěn kāixīn","really happy today"],
["659.","g-323.mp3","659","659"],
["幸福","g-324.mp3","Xìngfú","happiness"],
["小王覺得每一天都很開心，很幸福","g-325.mp3","xiǎo wáng juédé měi yītiān dū hěn kāixīn, hěn xìngfú","Xiao Wang feels that every day is very happy and happy"],
["讓員工有幸福感應該是未來企業努力的方向","g-326.mp3","ràng yuángōng yǒu xìngfú gǎn yìng gāi shì wèilái qǐyè nǔlì de fāngxiàng","Making employees feel happy should be the direction of future enterprise efforts"],
["660.","g-330.mp3","660","660"],
["滿意","g-331.mp3","Mǎnyì","satisfy"],
["這家餐廳的服務非常好，客人都很滿意","g-332.mp3","zhè jiā cāntīng de fúwù fēicháng hǎo, kèrén dōu hěn mǎnyì","The service of this restaurant is very good and the customers are very satisfied"],
["這家企業希望讓每一個客人都覺得滿意","g-333.mp3","zhè jiā qì yè xīwàng ràng měi yīgè kèrén dōu juédé mǎnyì","This business wants to make every guest happy"],
["661.","g-337.mp3","661","661"],
["感謝","g-338.mp3","Gǎnxiè","grateful"],
["感謝大家","g-339.mp3","gǎnxiè dàjiā","Thank you everyone"],
["這個小男孩為了感謝醫生，他寫了一張感謝卡片","g-340.mp3","zhège xiǎo nánhái wèile gǎnxiè yīshēng, tā xiěle yī zhāng gǎnxiè kǎpiàn","This little boy wrote a thank you card to thank his doctor"],
["662.","g-344.mp3","662","662"],
["擔心","g-345.mp3","Dānxīn","Worry"],
["A:小天這麼晚還沒回家，我很擔心","g-346.mp3","A: Xiǎo tiān zhème wǎn hái méi huí jiā, wǒ hěn dānxīn","A: Xiaotian hasn't come home so late, I'm worried"],
["B:先不要擔心，我現在馬上打電話給他","g-347.mp3","B: Xiān bùyào dānxīn, wǒ xiànzài mǎshàng dǎ diànhuà gěi tā","B: Don't worry, I'll call him right now"],
["663.","g-351.mp3","663","663"],
["生氣","g-352.mp3","Shēngqì","angry"],
["A: 老闆生氣了嗎?","g-353.mp3","A: Lǎobǎn shēngqìle ma?","A: Is the boss angry?"],
["B: 她看起來心情不錯，沒有生氣","g-354.mp3","B: Tā kàn qǐlái xīnqíng bùcuò, méiyǒu shēngqì","B: She seems to be in a good mood, not angry"],
["664.","g-358.mp3","664","664"],
["著急","g-359.mp3","Zhāojí","in a hurry"],
["客人的錢包掉了，很著急","g-360.mp3","kèrén de qiánbāo diàole, hěn zhāojí","The customer lost his wallet and was in a hurry"],
["先不要擔心，不要著急","g-361.mp3","xiān bùyào dānxīn, bùyào zhāojí","don't worry, don't worry"],
["665.","g-365.mp3","665","665"],
["辛苦","g-366.mp3","Xīnkǔ","Hard"],
["你的工作辛苦嗎?","g-367.mp3","nǐ de gōngzuò xīnkǔ ma?","Is your job hard?"],
["謝謝老師! 辛苦了","g-368.mp3","Xièxiè lǎoshī! Xīnkǔle","thank you teacher! hard work"],
["666.","g-372.mp3","666","666"],
["難過","g-373.mp3","Nánguò","sad"],
["我有一點難過","g-374.mp3","wǒ yǒu yīdiǎn nánguò","I'm a little sad"],
["小玉看完信以後，非常難過","g-375.mp3","xiǎoyù kàn wán xìn yǐhòu, fēicháng nánguò","After Xiaoyu read the letter, she was very sad"],
["667.","g-379.mp3","667","667"],
["傷心","g-380.mp3","Shāngxīn","sad"],
["看到你傷心，我也很難過","g-381.mp3","kàn dào nǐ shāngxīn, wǒ yě hěn nánguò","Seeing you sad makes me sad too"],
["看完新聞以後，每一個人都很傷心","g-382.mp3","kàn wán xīnwén yǐhòu, měi yīgèrén dōu hěn shāngxīn","Everyone is sad after watching the news"],
["668.","g-386.mp3","668","668"],
["哭","g-387.mp3","Kū","cry"],
["明華不想跟女朋友分手，哭了","g-388.mp3","míng huá bùxiǎng gēn nǚ péngyǒu fēnshǒu, kūle","Minghua didn't want to break up with his girlfriend and cried"],
["如果你想哭，就哭吧!","g-389.mp3","rúguǒ nǐ xiǎng kū, jiù kū ba!","If you want to cry, cry!"],
["669.","g-393.mp3","669","669"],
["怕","g-394.mp3","Pà","Afraid"],
["爸爸怕坐飛機，所以沒和我們去旅行","g-395.mp3","bàba pà zuò fēijī, suǒyǐ méi hé wǒmen qù lǚxíng","Dad was afraid of flying, so he didn't travel with us"],
["因為媽媽怕高，所以沒和爸爸一起爬過山","g-396.mp3","yīnwèi māmā pà gāo, suǒyǐ méi hé bàba yīqǐ páguò shān","Because my mother is afraid of heights, I didn't climb the mountain with my father"],
["670.","g-400.mp3","670","670"],
["可怕","g-401.mp3","Kěpà","horrible"],
["張阿姨生氣的樣子非常可怕","g-402.mp3","zhāng āyí shēngqì de yàngzi fēicháng kěpà","Aunt Zhang looks very scary when she is angry"],
["這部電影好可怕","g-403.mp3","zhè bù diànyǐng hǎo kěpà","this movie is terrible"],
["671.","g-407.mp3","671","671"],
["無聊","g-408.mp3","Wúliáo","boring"],
["歷史課很無聊，快要睡著了","g-409.mp3","lìshǐ kè hěn wúliáo, kuàiyào shuìzhele","History class is boring, almost falling asleep"],
["電影太無聊，我想要換一部","g-410.mp3","diànyǐng tài wúliáo, wǒ xiǎng yào huàn yī bù","The movie is so boring, I want another one"],
["672.","g-414.mp3","672","672"],
["可憐","g-415.mp3","Kělián","Pitiful"],
["好可憐，我們來幫他們","g-416.mp3","hǎo kělián, wǒmen lái bāng tāmen","Poor, let's help them"],
["小文一聽到可憐的故事就會哭","g-417.mp3","xiǎo wén yī tīngdào kělián de gùshì jiù huì kū","Xiaowen will cry when he hears the poor story"],
["673.","g-421.mp3","673","673"],
["相信","g-422.mp3","Xiāngxìn","believe"],
["警察不相信李先生說的話","g-423.mp3","jǐngchá bù xiāngxìn lǐ xiānshēng shuō dehuà","The police did not believe what Mr. Li said"],
["觀眾都相信他會拿第一名","g-424.mp3","guānzhòng dōu xiāngxìn tā huì ná dì yī míng","The audience believed that he would take the first place"],
["674.","g-428.mp3","674","674"],
["關心","g-429.mp3","Guānxīn","care"],
["老師特別關心小華","g-430.mp3","lǎoshī tèbié guānxīn xiǎo huá","The teacher is very concerned about Xiaohua"],
["謝謝你的關心","g-431.mp3","xièxiè nǐ de guānxīn","Thank you for your concern"],
["675.","g-435.mp3","675","675"],
["認為","g-436.mp3","Rènwéi","think"],
["這位醫生認為病人應該怎麼做比較好?","g-437.mp3","zhè wèi yīshēng rènwéi bìngrén yīnggāi zěnme zuò bǐjiào hǎo?","What does the doctor think the patient should do better?"],
["這位小姐認為男同學應該去哪裡找書?","g-438.mp3","Zhè wèi xiǎojiě rènwéi nán tóngxué yīnggāi qù nǎlǐ zhǎo shū?","Where does this lady think male students should go to find books?"],
["676.","g-442.mp3","676","676"],
["忘","g-443.mp3","Wàng","forget"],
["不要忘記公司的新規定","g-444.mp3","bùyào wàngjì gōngsī de xīn guīdìng","Don't forget about the company's new rules"],
["我忘記你剛剛說什麼了","g-445.mp3","wǒ wàngjì nǐ gānggāng shuō shénme liǎo","i forgot what you just said"],
["677.","g-449.mp3","677","677"],
["記","g-450.mp3","Jì","remember"],
["我記得醫生說一天要吃三次藥","g-451.mp3","wǒ jìdé yīshēng shuō yītiān yào chī sāncì yào","I remember the doctor said to take medicine three times a day"],
["老師 :同學，你們都記下來了嗎?","g-452.mp3","lǎoshī: Tóngxué, nǐmen dōu jì xiàláile ma?","Teacher: Classmates, have you all written it down?"],
["678.","g-456.mp3","678","678"],
["發現","g-457.mp3","Fāxiàn","Discover"],
["我發現你跟我穿一樣的鞋子!","g-458.mp3","wǒ fāxiàn nǐ gēn wǒ chuān yīyàng de xiézi!","I found you wearing the same shoes as me!"],
["曾有一項調查發現，很多員工生病的時候不敢請假","g-459.mp3","Céng yǒu yīxiàng diàochá fāxiàn, hěnduō yuángōng shēngbìng de shíhòu bù gǎn qǐngjià","A survey once found that many employees are afraid to ask for leave when they are sick"],
["679.","g-463.mp3","679","679"],
["需要","g-464.mp3","Xūyào","need"],
["李小姐說她家裡有事，所以需要請假","g-465.mp3","lǐ xiǎojiě shuō tā jiālǐ yǒushì, suǒyǐ xūyào qǐngjià","Ms. Li said that she had something to do at home, so she needed to ask for leave"],
["你需要躺下來休息!","g-466.mp3","nǐ xūyào tǎng xiàlái xiūxí!","You need to lie down and rest!"],
["680.","g-470.mp3","680","680"],
["了解","g-471.mp3","Liǎojiě","learn"],
["了解 !","g-472.mp3","liǎojiě!","learn !"],
["這位新同學不太了解學校的規定","g-473.mp3","Zhè wèi xīn tóngxué bù tài liǎojiě xuéxiào de guīdìng","This new classmate doesn't know much about the school's rules"],
["681.","g-477.mp3","681","681"],
["應該","g-478.mp3","Yīnggāi","should"],
["你應該要做七十六號公車","g-479.mp3","nǐ yīnggāi yào zuò qīshíliù hào gōngchē","you should do bus number seventy six"],
["醫生說多喝水、多休息，應該很快就會好","g-480.mp3","yīshēng shuō duō hē shuǐ, duō xiūxí, yīnggāi hěn kuài jiù huì hǎo","The doctor said to drink plenty of water and rest more, it should be fine soon"],
["682.","g-484.mp3","682","682"],
["不用","g-485.mp3","Bùyòng","Need not"],
["不用擔心！","g-486.mp3","bùyòng dānxīn!","Don't worry!"],
["不用擔心，你的傷應該很快就會好！","g-487.mp3","Bùyòng dānxīn, nǐ de shāng yīnggāi hěn kuài jiù huì hǎo!","Don't worry, your injury should heal soon!"],
["683.","g-491.mp3","683","683"],
["別","g-492.mp3","Bié","Don't"],
["別擔心! 不用想太多喔!","g-493.mp3","bié dānxīn! Bùyòng xiǎng tài duō ō!","Don't worry! Don't think too much!"],
["別急，警察應該很快就會找到你的錢包！","g-494.mp3","Bié jí, jǐngchá yīnggāi hěn kuài jiù huì zhǎodào nǐ de qiánbāo!","But wait, the police should find your wallet soon!"],
["684.","g-498.mp3","684","684"],
["打算","g-499.mp3","Dǎsuàn","Intend"],
["A: 聽說你們最近打算搬家?","g-500.mp3","A: Tīng shuō nǐmen zuìjìn dǎsuàn bānjiā?","A: I heard that you plan to move recently?"],
["B: 對啊，我們打算搬到有院子的房子住","g-501.mp3","B: Duì a, wǒmen dǎsuàn bān dào yǒu yuànzi de fángzi zhù","B: Yes, we plan to move to a house with a yard"],
["685.","g-505.mp3","685","685"],
["願意","g-506.mp3","Yuànyì","willing"],
["媽媽最後不願意搬家","g-507.mp3","māmā zuìhòu bù yuànyì bānjiā","Mom finally didn't want to move"],
["李小姐最後不願意嫁給她男朋友","g-508.mp3","lǐ xiǎojiě zuìhòu bù yuànyì jià gěi tā nán péngyǒu","In the end Miss Li was unwilling to marry her boyfriend"],
["686.","g-512.mp3","686","686"],
["心","g-513.mp3","Xīn","Heart"],
["我心碎了","g-514.mp3","wǒ xīn suìle","my heart is broken"],
["這首歌叫「月亮代表我的心 」","g-515.mp3","zhèshǒu gē jiào `yuèliàng dàibiǎo wǒ de xīn'","The song is called 'The Moon Represents My Heart'"],
["687.","g-519.mp3","687","687"],
["心情","g-520.mp3","Xīnqíng","Feeling"],
["經理看起來心情很好","g-521.mp3","jīnglǐ kàn qǐlái xīnqíng hěn hǎo","the manager seems to be in a good mood"],
["妹妹跟朋友聊完天，心情很好","g-522.mp3","mèimei gēn péngyǒu liáo wán tiān, xīnqíng hěn hǎo","My sister is in a good mood after chatting with her friends"],
["688.","g-526.mp3","688","688"],
["感覺","g-527.mp3","Gǎnjué","Feel"],
["感覺經理今天心情很好","g-528.mp3","gǎnjué jīnglǐ jīntiān xīnqíng hěn hǎo","I feel the manager is in a good mood today"],
["看完這部電影你有什麼感覺?","g-529.mp3","kàn wán zhè bù diànyǐng nǐ yǒu shé me gǎnjué?","How did you feel after watching this movie?"],
["689.","g-533.mp3","689","689"],
["興趣","g-534.mp3","Xìngqù","interest"],
["張小姐平常的興趣是什麼?","g-535.mp3","zhāng xiǎojiě píngcháng de xìngqù shì shénme?","What are Miss Zhang's usual hobbies?"],
["我對台灣文化很有興趣","g-536.mp3","Wǒ duì táiwān wénhuà hěn yǒu xìngqù","I am very interested in Taiwanese culture"],
["690.","g-540.mp3","690","690"],
["上","g-541.mp3","Shàng","superior"],
["上車的時候不要忘記付錢","g-542.mp3","shàng chē de shíhòu bùyào wàngjì fù qián","Don't forget to pay when you get in the car"],
["你可以上山看夜景","g-543.mp3","nǐ kěyǐ shàngshān kàn yèjǐng","You can go up the mountain to see the night view"],
["691.","g-547.mp3","691","691"],
["下","g-548.mp3","Xià","Down"],
["下車的時候記得要付錢!","g-549.mp3","xiàchē de shíhòu jìdé yào fù qián!","Remember to pay when you get off!"],
["下山比較危險，要小心!","g-550.mp3","Xiàshān bǐjiào wéixiǎn, yào xiǎoxīn!","Going down the mountain is more dangerous, be careful!"],
["692.","g-554.mp3","692","692"],
["出","g-555.mp3","Chū","out"],
["我出一百塊買這個手錶","g-556.mp3","wǒ chū yībǎi kuài mǎi zhège shǒubiǎo","I offer a hundred dollars for this watch"],
["李先生願意出一百萬買這間房子","g-557.mp3","lǐ xiānshēng yuànyì chū yībǎi wàn mǎi zhè jiān fángzi","Mr. Li is willing to pay one million to buy this house"],
["693.","g-561.mp3","693","693"],
["出來","g-562.mp3","Chūlái","come out"],
["你幾點可以出來?","g-563.mp3","nǐ jǐ diǎn kěyǐ chūlái?","what time can you come out"],
["出來一下，我有事跟你說","g-564.mp3","Chūlái yīxià, wǒ yǒushì gēn nǐ shuō","Come out, I have something to tell you"],
["694.","g-568.mp3","694","694"],
["進","g-569.mp3","Jìn","Enter"],
["進來!進來!","g-570.mp3","jìnlái! Jìnlái!","come in! come in!"],
["小貓跑進房間了","g-571.mp3","Xiǎo māo pǎo jìn fángjiānle","The kitten ran into the room"],
["695.","g-575.mp3","695","695"],
["進去","g-576.mp3","Jìnqù","go in"],
["爸爸進去房間休息了","g-577.mp3","bàba jìnqù fángjiān xiūxíle","Dad went into the room to rest"],
["學生都進去教室了","g-578.mp3","xuéshēng dōu jìnqù jiàoshìle","The students are all in the classroom"],
["696.","g-582.mp3","696","696"],
["回去","g-583.mp3","Huíqù","go back"],
["你什麼時候想回去?","g-584.mp3","nǐ shénme shíhòu xiǎng huíqù?","when do you want to go back"],
["我們先去買咖啡，然後再回去","g-585.mp3","Wǒmen xiān qù mǎi kāfēi, ránhòu zài huíqù","Let's go get coffee first and then go back"],
["697.","g-589.mp3","697","697"],
["起來","g-590.mp3","Qǐlái","stand up"],
["爺爺起來了嗎?","g-591.mp3","yéyé qǐláile ma?","is grandpa up?"],
["我早上起來的時候覺得肚子痛","g-592.mp3","Wǒ zǎoshang qǐlái de shíhòu juédé dùzi tòng","I have a stomach ache when I wake up in the morning"],
["698.","g-596.mp3","698","698"],
["出發","g-597.mp3","Chūfā","Set off"],
["A: 我們明天幾點出發?","g-598.mp3","A: Wǒmen míngtiān jǐ diǎn chūfā?","A: What time shall we leave tomorrow?"],
["B: 大概九點出發","g-599.mp3","B: Dàgài jiǔ diǎn chūfā","B: Depart at about nine o'clock"],
["699.","g-603.mp3","699","699"],
["離開","g-604.mp3","Líkāi","leave"],
["我們明天下午三點離開台北","g-605.mp3","wǒmen míngtiān xiàwǔ sān diǎn líkāi táiběi","we leave taipei at three o'clock tomorrow afternoon"],
["小美和朋友想到百貨公司買帽子，可是最後只買了點心就離開了","g-606.mp3","xiǎo měihé péngyǒu xiǎngdào bǎihuò gōngsī mǎi màozi, kěshì zuìhòu zhǐ mǎile diǎnxīn jiù líkāile","Xiaomei and her friends wanted to buy hats in a department store, but in the end they only bought some snacks and left"],
["700.","g-610.mp3","700","700"],
["經過","g-611.mp3","Jīngguò","go through"],
["經過這麼多年，我們都變了","g-612.mp3","jīngguò zhème duōnián, wǒmen dōu biànle","After all these years we've all changed"],
["去郵局的路上你會經過一個公園","g-613.mp3","qù yóujú de lùshàng nǐ huì jīngguò yīgè gōngyuán","on the way to the post office you will pass a park"],
["701.","g-617.mp3","701","701"],
["過去","g-618.mp3","Guòqù","past"],
["過去事情都已經過去了","g-619.mp3","guòqù shìqíng dōu yǐjīng guòqùle","the past is the past"],
["我晚一點會過去看看","g-620.mp3","wǒ wǎn yīdiǎn huì guòqù kàn kàn","I'll check it out later"],
["702.","g-624.mp3","702","702"],
["動","g-625.mp3","Dòng","move"],
["不要動!","g-626.mp3","bùyào dòng!","Do not move!"],
["拍照的時候不要動","g-627.mp3","Pāizhào de shíhòu bùyào dòng","don't move while taking pictures"],
["703.","g-631.mp3","703","703"],
["停","g-632.mp3","Tíng","stop"],
["有不少車停在停車場裡","g-633.mp3","yǒu bù shǎo chē tíng zài tíngchēchǎng lǐ","There are many cars parked in the parking lot"],
["好多輛車停在機場門口","g-634.mp3","hǎoduō liàng chē tíng zài jīchǎng ménkǒu","Lots of cars parked at the gate of the airport"],
["704.","g-638.mp3","704","704"],
["排隊","g-639.mp3","Páiduì","queue"],
["一定要排隊喔 ! 不可以插隊!","g-640.mp3","yīdìng yào páiduì ō! Bù kěyǐ chāduì!","Be sure to line up! No jumping in line!"],
["很多人正在電影院前面排隊買票","g-641.mp3","Hěnduō rén zhèngzài diànyǐngyuàn qiánmiàn páiduì mǎi piào","Many people are queuing in front of the cinema to buy tickets"],
["705.","g-645.mp3","705","705"],
["轉","g-646.mp3","Zhuǎn","change"],
["A: 去火車站需要轉車嗎?","g-647.mp3","A: Qù huǒchēzhàn xūyào zhuǎnchē ma?","A: Do I need to transfer to the train station?"],
["B: 要，需要轉車轉兩次才能到車站","g-648.mp3","B: Yào, xūyào zhuǎnchē zhuǎn liǎng cì cáinéng dào chē zhàn","B: Yes, you need to transfer twice to get to the station"],
["706.","g-652.mp3","706","706"],
["出現","g-653.mp3","Chūxiàn","Appear"],
["已經開始開會了，老闆還是沒有出現","g-654.mp3","yǐjīng kāishǐ kāihuìle, lǎobǎn háishì méiyǒu chūxiàn","The meeting has already started, but the boss still hasn't shown up"],
["病人的臉出現了希望","g-655.mp3","bìngrén de liǎn chūxiànle xīwàng","Hope appears on the patient's face"],
["707.","g-659.mp3","707","707"],
["弄","g-660.mp3","Nòng","alley"],
["想吃什麼? 我幫你弄","g-661.mp3","xiǎng chī shénme? Wǒ bāng nǐ nòng","What do you want to eat? I'll make it for you"],
["你的房間太髒了，趕快弄乾淨!","g-662.mp3","nǐ de fángjiān tài zāngle, gǎnkuài nòng qiánjìng!","Your room is too dirty, clean it up!"],
["708.","g-666.mp3","708","708"],
["幫","g-667.mp3","Bāng","help"],
["妳想喝什麼?我幫妳弄","g-668.mp3","nǎi xiǎng hē shénme? Wǒ bāng nǎi nòng","What do you want to drink? I'll make it for you"],
["我幫你洗碗","g-669.mp3","wǒ bāng nǐ xǐ wǎn","I help you wash the dishes"],
["709.","g-673.mp3","709","709"],
["打開","g-674.mp3","Dǎkāi","Open"],
["可以把窗戶打開嗎? 空氣很差","g-675.mp3","kěyǐ bǎ chuānghù dǎkāi ma? Kōngqì hěn chà","Can you open the window? The air is very bad"],
["可以把門打開嗎?","g-676.mp3","kěyǐ bǎmén dǎkāi ma?","can you open the door"],
["710.","g-680.mp3","710","710"],
["交","g-681.mp3","Jiāo","pay"],
["老師說明天要交報告","g-682.mp3","lǎoshī shuōmíng tiān yào jiāo bàogào","The teacher said that the report will be handed in tomorrow"],
["學校希望大家三十號以前交暑假作業","g-683.mp3","xuéxiào xīwàng dàjiā sānshí hào yǐqián jiāo shǔjià zuòyè","The school hopes that everyone will hand in their summer homework before the 30th"],
["711.","g-687.mp3","711","711"],
["收","g-688.mp3","Shōu","receive"],
["她收到很多禮物","g-689.mp3","tā shōu dào hěnduō lǐwù","she received many gifts"],
["小華收到一封信","g-690.mp3","xiǎo huá shōu dào yī fēng xìn","Xiaohua received a letter"],
["712.","g-694.mp3","712","712"],
["借","g-695.mp3","Jiè","borrow"],
["A: 請問這本書圖書館有嗎? B: 這本書兩個禮拜以前已經被借走了。","g-696.mp3","A: Qǐngwèn zhè běnshū túshū guǎn yǒu ma? B: Zhè běnshū liǎng gè lǐbài yǐqián yǐjīng bèi jiè zǒule.","A: Is this book available in the library? B: This book was checked out two weeks ago."],
["A: 我還可以去哪裡借這本書?","g-697.mp3","A: Wǒ hái kěyǐ qù nǎlǐ jiè zhè běnshū?","A: Where else can I borrow this book?"],
["713.","h-01.mp3","713","713"],
["租","h-02.mp3","Zū","rent"],
["在這個社區租房子不便宜","h-03.mp3","zài zhège shèqū zū fángzi bù piányí","Renting is not cheap in this neighborhood"],
["你的房租一個月多少?","h-04.mp3","nǐ de fángzū yīgè yuè duōshǎo?","How much is your monthly rent?"],
["714.","h-08.mp3","714","714"],
["搬","h-09.mp3","Bān","move"],
["幫我把這張桌子搬到窗戶那邊","h-10.mp3","bāng wǒ bǎ zhè zhāng zhuōzi bān dào chuānghù nà biān","help me move this table over to the window"],
["我們全家下個月就要搬到院子裡有大樹的房子住了","h-11.mp3","wǒmen quánjiā xià gè yuè jiù yào bān dào yuànzi lǐ yǒu dàshù de fángzi zhùle","Our family will move to a house with a big tree in the yard next month"],
["715.","h-15.mp3","715","715"],
["掛","h-16.mp3","Guà","hang"],
["小美家的牆上掛著很多風景畫","h-17.mp3","xiǎo měi jiā de qiáng shàng guàzhe hěnduō fēngjǐng huà","There are many landscape paintings hanging on the wall of Xiaomei's house"],
["牆上掛著一張世界地圖","h-18.mp3","qiáng shàng guàzhe yī zhāng shìjiè dìtú","A world map hangs on the wall"],
["716.","h-22.mp3","716","716"],
["掉","h-23.mp3","Diào","Lose"],
["如果我的錢包掉了，當然會著急","h-24.mp3","rúguǒ wǒ de qiánbāo diàole, dāngrán huì zhāojí","If my wallet is lost, of course I will be in a hurry"],
["先生，你的錢掉了","h-25.mp3","xiānshēng, nǐ de qián diàole","sir, you lost your money"],
["717.","h-29.mp3","717","717"],
["丟","h-30.mp3","Diū","leave"],
["我每天負責丟垃圾","h-31.mp3","wǒ měitiān fùzé diū lèsè","I take out trash every day"],
["我想把這些舊書都丟掉","h-32.mp3","wǒ xiǎng bǎ zhèxiē jiùshū dōu diūdiào","I want to throw away all these old books"],
["718.","h-36.mp3","718","718"],
["偷","h-37.mp3","Tōu","steal"],
["上禮拜我的車子差一點被偷","h-38.mp3","shàng lǐbài wǒ de chēzi chà yīdiǎn bèi tōu","My car was almost stolen last week"],
["上次在歐洲旅行的時候，小明的手機被偷了","h-39.mp3","shàng cì zài ōuzhōu lǚxíng de shíhòu, xiǎomíng de shǒujī bèi tōule","During the last trip in Europe, Xiao Ming's mobile phone was stolen"],
["719.","h-43.mp3","719","719"],
["試","h-44.mp3","Shì","try"],
["試試看!","h-45.mp3","shì shìkàn!","try it!"],
["可以試吃嗎?","h-46.mp3","Kěyǐ shì chī ma?","Can I try it?"],
["720.","h-50.mp3","720","720"],
["戴","h-51.mp3","Dài","Wear"],
["戴著帽子的小男孩在看電視","h-52.mp3","dàizhe màozi de xiǎo nánhái zài kàn diànshì","little boy in hat watching tv"],
["戴著眼鏡的小女孩在看書","h-53.mp3","dài zhuó yǎnjìng de xiǎo nǚhái zài kànshū","little girl with glasses reading a book"],
["721.","h-57.mp3","721","721"],
["裝","h-58.mp3","Zhuāng","Pack"],
["萬聖節你想裝成什麼?","h-59.mp3","wànshèngjié nǐ xiǎng zhuāng chéng shénme?","What do you want to pretend to be for halloween?"],
["不要裝了!","h-60.mp3","Bùyào zhuāngle!","Stop pretending!"],
["722.","h-64.mp3","722","722"],
["躺","h-65.mp3","Tǎng","lie"],
["天氣好的時候，我跟朋友喜歡躺在草地上聊天","h-66.mp3","tiānqì hǎo de shíhòu, wǒ gēn péngyǒu xǐhuān tǎng zài cǎodì shàng liáotiān","When the weather is good, my friends and I like to lie on the grass and chat"],
["小華頭痛，他想要躺在沙發上休息一下","h-67.mp3","xiǎo huá tóutòng, tā xiǎng yào tǎng zài shāfā shàng xiūxí yīxià","Xiaohua has a headache, he wants to rest on the sofa"],
["723.","h-71.mp3","723","723"],
["死","h-72.mp3","Sǐ","die"],
["他死了，所有人都很傷心","h-73.mp3","tā sǐle, suǒyǒu rén dōu hěn shāngxīn","He died and everyone was sad"],
["這隻貓年紀大死了，妹妹很難過","h-74.mp3","zhè zhī māo niánjì dà sǐle, mèimei hěn nánguò","The cat died at an old age, and the sister was sad"],
["724.","h-78.mp3","724","724"],
["看見","h-79.mp3","Kànjiàn","see"],
["小天看不見黑板上老師寫的字","h-80.mp3","xiǎo tiān kàn bùjiàn hēibǎn shàng lǎoshī xiě de zì","Xiaotian can't see the words written by the teacher on the blackboard"],
["今天天氣不好，看不見台北一零一","h-81.mp3","jīntiān tiānqì bù hǎo, kàn bùjiàn táiběi yī líng yī","The weather is bad today, I can't see Taipei 101"],
["725.","h-85.mp3","725","725"],
["算","h-86.mp3","Suàn","Calculate"],
["老闆正在算錢","h-87.mp3","lǎobǎn zhèngzài suàn qián","The boss is counting the money"],
["我算了一下，你要付一千塊","h-88.mp3","wǒ suànle yīxià, nǐ yào fù yīqiān kuài","I did the math, you have to pay a thousand dollars"],
["726.","h-92.mp3","726","726"],
["叫","h-93.mp3","Jiào","Call"],
["媽媽叫弟弟出來，但是他還在寫功課","h-94.mp3","māmā jiào dì dì chūlái, dànshì tā hái zài xiě gōngkè","Mom called my brother out, but he was still doing his homework"],
["爸爸叫妹妹先去洗手，再吃飯","h-95.mp3","bàba jiào mèimei xiān qù xǐshǒu, zài chīfàn","Dad told my sister to wash her hands before eating"],
["727.","h-99.mp3","727","727"],
["聽說","h-100.mp3","Tīng shuō","It is said that"],
["聽說你們全家下個月要去旅行?","h-101.mp3","tīng shuō nǐmen quánjiā xià gè yuè yào qù lǚxíng?","I heard that your family is going on a trip next month?"],
["聽說天華全家下個月就要搬到有院子的房子住了","h-102.mp3","Tīng shuō tiān huá quánjiā xià gè yuè jiù yào bān dào yǒu yuànzi de fángzi zhùle","I heard that Tianhua's family will move to a house with a yard next month"],
["728.","h-106.mp3","728","728"],
["談","h-107.mp3","Tán","talk"],
["我們來談一下合約的內容","h-108.mp3","wǒmen lái tán yī xià héyuē de nèiróng","Let's talk about the content of the contract"],
["經理正在跟李小姐談工作的內容","h-109.mp3","jīnglǐ zhèngzài gēn lǐ xiǎojiě tán gōngzuò de nèiróng","The manager is talking about work with Ms. Li"],
["729.","h-113.mp3","729","729"],
["講","h-114.mp3","Jiǎng","speak"],
["老闆正在跟員工講合約的內容","h-115.mp3","lǎobǎn zhèngzài gēn yuángōng jiǎng héyuē de nèiróng","The boss is talking about the content of the contract with the employees"],
["我不想講了","h-116.mp3","wǒ bùxiǎng jiǎngle","I don't want to talk"],
["730.","h-120.mp3","730","730"],
["講話","h-121.mp3","Jiǎnghuà","to speak"],
["你講話講太快了","h-122.mp3","nǐ jiǎnghuà jiǎng tài kuàile","you talk too fast"],
["爸爸跟鄰居在家門口講話","h-123.mp3","bàba gēn línjū zàijiā ménkǒu jiǎnghuà","Dad talking to neighbors at the door"],
["731.","h-127.mp3","731","731"],
["告訴","h-128.mp3","Gàosù","Tell"],
["警察告訴妹妹她年紀還太小不能騎車","h-129.mp3","jǐngchá gàosù mèimei tā niánjì hái tài xiǎo bùnéng qí chē","Cops tell sister she's too young to ride"],
["美心告訴我這是她第一次跟男生出去約會","h-130.mp3","měi xīn gàosù wǒ zhè shì tā dì yīcì gēn nánshēng chūqù yuēhuì","Maxim told me this is her first date with a guy"],
["732.","h-134.mp3","732","732"],
["通知","h-135.mp3","Tōngzhī","notify"],
["我沒有收到政府的通知","h-136.mp3","wǒ méiyǒu shōu dào zhèngfǔ de tōngzhī","I have not received notification from the government"],
["李小姐收到信後，馬上打電話通知客人","h-137.mp3","lǐ xiǎojiě shōu dào xìn hòu, mǎshàng dǎ diànhuà tōngzhī kèrén","After receiving the letter, Ms. Li immediately called the customer"],
["733.","h-141.mp3","733","733"],
["討論","h-142.mp3","Tǎolùn","discuss"],
["他們在討論什麼?","h-143.mp3","tāmen zài tǎolùn shénme?","what are they discussing"],
["老師跟學生正在討論歷史問題","h-144.mp3","Lǎoshī gēn xuéshēng zhèngzài tǎolùn lìshǐ wèntí","Teacher and students are discussing historical issues"],
["734.","h-148.mp3","734","734"],
["開會","h-149.mp3","Kāihuì","the meeting"],
["今天開會我們要討論什麼?","h-150.mp3","jīntiān kāihuì wǒmen yào tǎolùn shénme?","What are we going to discuss at today's meeting?"],
["開會的時候經理不想講話，什麼都不同意","h-151.mp3","Kāihuì de shíhòu jīnglǐ bùxiǎng jiǎnghuà, shénme dōu bù tóngyì","Manager doesn't want to talk during meeting, doesn't agree on anything"],
["735.","h-155.mp3","735","735"],
["決定","h-156.mp3","Juédìng","Decide"],
["李先生跟李小姐決定好了嗎?","h-157.mp3","lǐ xiānshēng gēn lǐ xiǎojiě juédìng hǎole ma?","Have Mr. Li and Ms. Li made up their minds?"],
["他們什麼都還沒決定","h-158.mp3","Tāmen shénme dōu hái méi juédìng","they haven't decided anything yet"],
["736.","h-162.mp3","736","736"],
["管理","h-163.mp3","Guǎnlǐ","manage"],
["時間管理很重要","h-164.mp3","shíjiān guǎnlǐ hěn zhòngyào","time management is important"],
["天華負責管理這間工廠的安全","h-165.mp3","tiān huá fùzé guǎnlǐ zhè jiān gōngchǎng de ānquán","Tianhua is in charge of managing the safety of this factory"],
["737.","h-169.mp3","737","737"],
["檢查","h-170.mp3","Jiǎnchá","examine"],
["電腦好奇怪，可以幫我檢查一下嗎?","h-171.mp3","diànnǎo hào qíguài, kěyǐ bāng wǒ jiǎnchá yīxià ma?","The computer is strange, can you check it for me?"],
["考試寫完後，一定要檢查","h-172.mp3","Kǎoshì xiě wán hòu, yīdìng yào jiǎnchá","After the exam is written, be sure to check"],
["738.","h-176.mp3","738","738"],
["習慣","h-177.mp3","Xíguàn","Habit"],
["A: 可欣習慣台北的生活了嗎?","h-178.mp3","A: Kě xīn xíguàn táiběi de shēnghuóle ma?","A: Has Kexin gotten used to life in Taipei?"],
["B: 她還是不習慣大城市的生活","h-179.mp3","B: Tā háishì bù xíguàn dà chéngshì de shēnghuó","B: She is still not used to life in a big city"],
["739.","h-183.mp3","739","739"],
["練習","h-184.mp3","Liànxí","practise"],
["老師希望每個學生能多練習講中文","h-185.mp3","lǎoshī xīwàng měi gè xuéshēng néng duō liànxí jiǎng zhōngwén","The teacher hopes that every student can practice speaking Chinese more"],
["多練習才會進步","h-186.mp3","duō liànxí cái huì jìnbù","more practice will improve"],
["740.","h-190.mp3","740","740"],
["努力","h-191.mp3","Nǔlì","effort"],
["曾經有一句話說「努力才會成功」","h-192.mp3","céngjīng yǒuyījù huàshuō `nǔlì cái huì chénggōng'","There used to be a saying that says 'work hard to succeed'"],
["你這麼努力工作，不要忘記照顧自己","h-193.mp3","nǐ zhème nǔlì gōngzuò, bùyào wàngjì zhàogù zìjǐ","You work so hard, don't forget to take care of yourself"],
["741.","h-197.mp3","741","741"],
["進步","h-198.mp3","Jìnbù","progress"],
["老師覺得可欣的中文進步了","h-199.mp3","lǎoshī juédé kě xīn de zhōngwén jìnbùle","The teacher feels that Kexin's Chinese has improved"],
["每天練習，才會進步得快","h-200.mp3","měitiān liànxí, cái huì jìnbù dé kuài","Practice every day to improve fast"],
["742.","h-204.mp3","742","742"],
["成功","h-205.mp3","Chénggōng","success"],
["曾經有一句話說「不努力就不會成功」","h-206.mp3","céngjīng yǒuyījù huàshuō `bù nǔlì jiù bù huì chénggōng'","There used to be a saying, 'If you don't work hard, you won't succeed'"],
["曾經有人說「成功其實是一種選擇」","h-207.mp3","céngjīng yǒurén shuō `chénggōng qíshí shì yīzhǒng xuǎnzé'","Someone once said 'success is actually a choice'"],
["743.","h-211.mp3","743","743"],
["見面","h-212.mp3","Jiànmiàn","Meet"],
["我們什麼時候要見面?","h-213.mp3","wǒmen shénme shíhòu yào jiànmiàn?","When shall we meet?"],
["美心覺得一個人跟網友見面不太安全","h-214.mp3","Měi xīn juédé yīgèrén gēn wǎngyǒu jiànmiàn bù tài ānquán","Meixin feels that it is not safe to meet with netizens alone"],
["744.","h-218.mp3","744","744"],
["約","h-219.mp3","Yuē","about"],
["我們要約什麼時候見面?","h-220.mp3","wǒmen yāoyuē shénme shíhòu jiànmiàn?","When are we going to meet up?"],
["可欣想約明華看電影","h-221.mp3","Kě xīn xiǎng yuē míng huá kàn diànyǐng","Kexin wants to ask Minghua to watch a movie"],
["745.","h-225.mp3","745","745"],
["慶祝","h-226.mp3","Qìngzhù","celebrate"],
["很多人幫妹妹慶祝生日","h-227.mp3","hěnduō rén bāng mèimei qìngzhù shēngrì","Many people help my sister celebrate her birthday"],
["什麼時候有空? 我想幫你慶祝生日","h-228.mp3","shénme shíhòu yǒu kòng? Wǒ xiǎng bāng nǐ qìngzhù shēngrì","When are you free? I want to help you celebrate your birthday"],
["746.","h-232.mp3","746","746"],
["變","h-233.mp3","Biàn","Change"],
["我覺得這個地方變了很多","h-234.mp3","wǒ juédé zhège dìfāngbiànle hěnduō","I think this place has changed a lot"],
["小張的同事發現他個性變了很多","h-235.mp3","xiǎozhāng de tóngshì fāxiàn tā gèxìng biànle hěnduō","Xiao Zhang's colleagues found that his personality has changed a lot"],
["747.","h-239.mp3","747","747"],
["發生","h-240.mp3","Fāshēng","occur"],
["發生了什麼事?","h-241.mp3","fāshēngle shénme shì?","What happened?"],
["警察正在和每一個人解釋發生什麼事","h-242.mp3","Jǐngchá zhèngzài hé měi yīgèrén jiěshì fāshēng shénme shì","The police are explaining to everyone what happened"],
["748.","h-246.mp3","748","748"],
["結束","h-247.mp3","Jiéshù","Finish"],
["數學課八點開始，九點結束","h-248.mp3","shùxué kè bā diǎn kāishǐ, jiǔ diǎn jiéshù","Math class starts at eight o'clock and ends at nine o'clock"],
["比賽結束後，觀眾都非常興奮","h-249.mp3","bǐsài jiéshù hòu, guānzhòng dōu fēicháng xīngfèn","After the game, the audience was very excited"],
["749.","h-253.mp3","749","749"],
["完","h-254.mp3","Wán","over"],
["吃完後記得要洗碗!","h-255.mp3","chī wán hòujìdé yào xǐ wǎn!","Remember to wash the dishes after eating!"],
["上完課以後，我們約在校門口見面","h-256.mp3","Shàng wán kè yǐhòu, wǒmen yuē zài xiào ménkǒu jiànmiàn","After class, we will meet at the school gate"],
["750.","h-260.mp3","750","750"],
["影響","h-261.mp3","Yǐngxiǎng","Influence"],
["在電影院如果說話太大聲，會影響到其他人","h-262.mp3","zài diànyǐngyuàn rúguǒ shuōhuà tài dà shēng, huì yǐngxiǎngdào qítā rén","If you speak too loudly in the movie theater, it will affect other people"],
["如果有人帶了一些有奇怪味道的食物進電影院，也很容易影響其他看電影的人","h-263.mp3","rúguǒ yǒurén dàile yīxiē yǒu qíguài wèidào de shíwù jìn diànyǐngyuàn, yě hěn róngyì yǐngxiǎng qítā kàn diànyǐng de rén","If someone brings some food with a strange taste into the movie theater, it will easily affect other moviegoers"],
["751.","h-267.mp3","751","751"],
["傳真","h-268.mp3","Chuánzhēn","fax"],
["公司越來越少人用傳真機了","h-269.mp3","gōngsī yuè lái yuè shǎo rén yòng chuánzhēn jīle","Fewer and fewer people in the company use fax machines"],
["現在傳真機這個字，越來越少用了","h-270.mp3","xiànzài chuánzhēn jī zhège zì, yuè lái yuè shǎo yòngle","Now the word fax machine is less and less used"],
["752.","h-274.mp3","752","752"],
["洗衣機","h-275.mp3","Xǐyījī","washing machine"],
["A: 洗衣機放在哪裡?","h-276.mp3","A: Xǐyījī fàng zài nǎlǐ?","A: Where is the washing machine?"],
["B: 洗衣機好像放在二樓","h-277.mp3","B: Xǐyījī hǎoxiàng fàng zài èr lóu","B: The washing machine seems to be on the second floor"],
["753.","h-281.mp3","753","753"],
["光","h-282.mp3","Guāng","Light"],
["晚會的飲料都被喝光了，因為大家一次拿好多杯","h-283.mp3","wǎnhuì de yǐnliào dōu bèi hē guāngle, yīn wéi dàjiā yì cì ná hǎoduō bēi","The drinks at the party were all run out because everyone took multiple glasses at once"],
["明天記得上網買演唱會的票喔! 我怕票會賣光","h-284.mp3","míngtiān jìdé shàngwǎng mǎi yǎnchàng huì de piào ō! Wǒ pà piào huì mài guāng","Remember to buy concert tickets online tomorrow! I'm afraid the tickets will be sold out"],
["754.","h-288.mp3","754","754"],
["冰箱","h-289.mp3","Bīngxiāng","refrigerator"],
["我把冰箱的牛奶喝完了","h-290.mp3","wǒ bǎ bīngxiāng de niúnǎi hē wánliǎo","I drank the milk in the fridge"],
["妹妹把蛋糕的蛋糕吃完了","h-291.mp3","mèimei bǎ dàngāo de dàngāo chī wánliǎo","sister ate the cake"],
["755.","h-295.mp3","755","755"],
["錢包","h-296.mp3","Qiánbāo","wallet"],
["如果客人的錢包不見了，我就幫他們找","h-297.mp3","rúguǒ kèrén de qiánbāo bùjiànle, wǒ jiù bāng tāmen zhǎo","If a customer loses their wallet, I help them find it"],
["小張的錢包不見了，很著急","h-298.mp3","xiǎozhāng de qiánbāo bùjiànle, hěn zhāojí","Xiao Zhang's wallet is missing, I'm in a hurry"],
["756.","h-302.mp3","756","756"],
["鑰匙","h-303.mp3","Yàoshi","key"],
["A: 媽! 你有沒有看到我的鑰匙?","h-304.mp3","A: Mā! Nǐ yǒu méiyǒu kàn dào wǒ de yàoshi?","A: Mom! Did you see my key?"],
["B: 在那邊啊! 你的鑰匙在錢包上面","h-305.mp3","B: Zài nà biān a! Nǐ de yàoshi zài qiánbāo shàngmiàn","B: Over there! Your keys are on the wallet"],
["757.","h-309.mp3","757","757"],
["手錶","h-310.mp3","Shǒubiǎo","watch"],
["可欣的手錶跟鑰匙都放在桌上","h-311.mp3","kě xīn de shǒubiǎo gēn yàoshi dōu fàng zài zhuō shàng","Kexin's watch and keys are on the table"],
["小李的手錶被偷了，他很難過","h-312.mp3","xiǎo lǐ de shǒubiǎo bèi tōule, tā hěn nánguò","Xiao Li's watch was stolen, he was very sad"],
["758.","h-316.mp3","758","758"],
["襯衫","h-317.mp3","Chènshān","shirt"],
["我發現你的襯衫跟小美的襯衫一樣","h-318.mp3","wǒ fāxiàn nǐ de chènshān gēn xiǎo měide chènshān yīyàng","I found your shirt is the same as Mei's shirt"],
["小美和朋友想到百貨公司買點心，可是最後只買了襯衫就離開了","h-319.mp3","xiǎo měihé péngyǒu xiǎngdào bǎihuò gōngsī mǎi diǎnxīn, kěshì zuìhòu zhǐ mǎile chènshān jiù líkāile","Xiaomei and her friends wanted to buy snacks in the department store, but in the end they only bought shirts and left"],
["759.","h-323.mp3","759","759"],
["毛衣","h-324.mp3","Máoyī","sweater"],
["天華在百貨公司買了兩件襯衫、一雙鞋子","h-325.mp3","tiān huá zài bǎihuò gōngsī mǎile liǎng jiàn chènshān, yī shuāng xiézi","Tianhua bought two shirts and a pair of shoes in a department store"],
["小華原本要買襯衫，但是後來只買了毛衣就離開了","h-326.mp3","xiǎo huáyuánběn yāomǎi chènshān, dànshì hòulái zhǐ mǎile máoyī jiù líkāile","Xiaohua originally wanted to buy a shirt, but then he only bought a sweater and left"],
["760.","h-330.mp3","760","760"],
["牛仔褲","h-331.mp3","Niúzǎikù","jeans"],
["A: 這件牛仔褲有大一點的嗎? B: 黑色40號好嗎?","h-332.mp3","A: Zhè jiàn niúzǎikù yǒu dàyīdiǎn de ma? B: Hēisè 40 hào hǎo ma?","A: Is there a larger size for this pair of jeans? B: How about black size 40?"],
["我變胖了，那些牛仔褲我都穿不下了","h-333.mp3","Wǒ biàn pàngle, nàxiē niúzǎikù wǒ dū chuān bùxiàle","I'm getting fat and I can't fit in those jeans anymore"],
["761.","h-337.mp3","761","761"],
["內衣","h-338.mp3","Nèiyī","underwear"],
["A: 我的內衣都穿不下了","h-339.mp3","A: Wǒ de nèiyī dōu chuān bùxiàle","A: I can't even fit in my underwear"],
["B: 那我帶你去百貨公司買新內衣","h-340.mp3","B: Nà wǒ dài nǐ qù bǎihuò gōngsī mǎi xīn nèiyī","B: Then I'll take you to a department store to buy new underwear"],
["762.","h-344.mp3","762","762"],
["雨衣","h-345.mp3","Yǔyī","raincoat"],
["A:老師，我們這次旅行要帶什麼?","h-346.mp3","A: Lǎoshī, wǒmen zhècì lǚxíng yào dài shénme?","A: Teacher, what should we bring for this trip?"],
["B:那裡常下雨，帶件雨衣吧!","h-347.mp3","B: Nàlǐ cháng xià yǔ, dài jiàn yǔyī ba!","B: It rains a lot there, so bring a raincoat!"],
["763.","h-351.mp3","763","763"],
["大衣","h-352.mp3","Dàyī","coat"],
["A: 小姐，這件大衣是九百元，請問您還要買別的東西嗎?","h-353.mp3","A: Xiǎojiě, zhè jiàn dà yī shì jiǔbǎi yuán, qǐngwèn nín hái yāomǎi bié de dōngxī ma?","A: Miss, this coat is 900 yuan. Would you like to buy anything else?"],
["B: 嗯，還有我手上這一件","h-354.mp3","B: Ń, hái yǒu wǒ shǒu shàng zhè yī jiàn","B: Well, and this one in my hand"],
["764.","h-358.mp3","764","764"],
["外套","h-359.mp3","Wàitào","coat"],
["A: 老師，我們這次旅行要帶什麼?","h-360.mp3","A: Lǎoshī, wǒmen zhècì lǚxíng yào dài shénme?","A: Teacher, what should we bring for this trip?"],
["B: 那裡很冷，帶件外套吧!","h-361.mp3","B: Nàlǐ hěn lěng, dài jiàn wàitào ba!","B: It's cold there, take a coat!"],
["765.","h-365.mp3","765","765"],
["帽子","h-366.mp3","Màozi","hat"],
["那個戴著帽子的女生就是可欣","h-367.mp3","nàgè dàizhe màozi de nǚshēng jiùshì kě xīn","That girl in the hat is Kexin"],
["可欣跟朋友想到百貨公司買襯衫，可是最後只買了帽子就離開了","h-368.mp3","kě xīn gēn péngyǒu xiǎng dào bǎihuò gōngsī mǎi chènshān, kěshì zuìhòu zhǐ mǎile màozi jiù líkāile","Kexin and her friends wanted to buy shirts in a department store, but in the end they only bought a hat and left"],
["766.","h-372.mp3","766","766"],
["皮包","h-373.mp3","Píbāo","purse"],
["A:這個皮包有別的顏色嗎?","h-374.mp3","A: Zhège píbāo yǒu bié de yánsè ma?","A: Is this leather bag available in other colors?"],
["B:有黑色跟紅色，要拿來給你看嗎?","h-375.mp3","B: Yǒu hēisè gēn hóngsè, yào ná lái gěi nǐ kàn ma?","B: There are black and red, do you want to show them to you?"],
["767.","h-379.mp3","767","767"],
["流行","h-380.mp3","Liúxíng","Popularity"],
["聽說這種皮包今年很流行","h-381.mp3","tīng shuō zhè zhǒng píbāo jīnnián hěn liúxíng","I heard that this kind of leather bag is very popular this year"],
["你喜歡聽流行音樂嗎?","h-382.mp3","nǐ xǐhuān tīng liúxíng yīnyuè ma?","Do you like listening to pop music?"],
["768.","h-386.mp3","768","768"],
["藍色","h-387.mp3","Lán sè","blue"],
["藍色的天空","h-388.mp3","lán sè de tiānkōng","blue sky"],
["穿藍色衣服是我女朋友","h-389.mp3","chuān lán sè yīfú shì wǒ nǚ péngyǒu","wearing blue is my girlfriend"],
["769.","h-393.mp3","769","769"],
["綠色","h-394.mp3","Lǜsè","green"],
["綠色的草地","h-395.mp3","lǜsè de cǎodì","green grass"],
["可欣穿綠色裙子很好看","h-396.mp3","kě xīn chuān lǜsè qúnzi hěn hǎokàn","Kexin looks great in a green dress"],
["770.","h-400.mp3","770","770"],
["紅色","h-401.mp3","Hóngsè","red"],
["紅包的鞋子","h-402.mp3","hóngbāo de xiézi","red envelope shoes"],
["聽說過年的時候穿紅色的衣服會帶來好運","h-403.mp3","tīng shuō guònián de shíhòu chuān hóngsè de yīfú huì dài lái hǎoyùn","It is said that wearing red clothes during the New Year will bring good luck"],
["771.","h-407.mp3","771","771"],
["黑色","h-408.mp3","Hēisè","black"],
["A:請問這件襯衫有黑色的嗎?","h-409.mp3","A: Qǐngwèn zhè jiàn chènshān yǒu hēisè de ma?","A: Is this shirt available in black?"],
["B:有啊! 有黑色的","h-410.mp3","B: Yǒu a! Yǒu hēisè de","B: Yes! There are black ones"],
["772.","h-414.mp3","772","772"],
["明信片","h-415.mp3","Míngxìnpiàn","postcard"],
["小王對旅遊很有興趣，每次旅行的時候都會寄明信片給我","h-416.mp3","xiǎo wáng duì lǚyóu hěn yǒu xìngqù, měi cì lǚxíng de shíhòu dūhuì jì míngxìnpiàn gěi wǒ","Xiao Wang is very interested in traveling, and he will send me postcards every time he travels"],
["他在明信片上跟我說，雖然他弄丟行李，但是旅館的老闆幫他找到了","h-417.mp3","tā zài míngxìnpiàn shàng gēn wǒ shuō, suīrán tā nòng diū xínglǐ, dànshì lǚguǎn de lǎobǎn bāng tā zhǎodàole","He told me in a postcard that although he lost his luggage, the owner of the hotel found it for him"],
["773.","h-421.mp3","773","773"],
["卡片","h-422.mp3","Kǎpiàn","card"],
["美美要去郵局寄生日卡片給她的朋友","h-423.mp3","měiměi yào qù yóujú jìshēngrì kǎpiàn gěi tā de péngyǒu","Meimei is going to post a birthday card to her friend at the post office"],
["卡片上寫著 「祝你生日快樂」","h-424.mp3","kǎpiàn shàng xiězhe `zhù nǐ shēng rì kuàilè'","The card reads 'Happy Birthday to you'"],
["774.","h-428.mp3","774","774"],
["盒子","h-429.mp3","Hézi","Box"],
["盒子裡是不是有幾封信?","h-430.mp3","hézi lǐ shì bùshì yǒu jǐ fēng xìn?","Are there any letters in the box?"],
["盒子裡有一封爸爸寫的信","h-431.mp3","Hézi lǐ yǒuyī fēng bàba xiě de xìn","There is a letter from dad in the box"],
["775.","h-435.mp3","775","775"],
["網站","h-436.mp3","Wǎngzhàn","website"],
["現在學生可以自己在這個網站上借書，很方便","h-437.mp3","xiànzài xuéshēng kěyǐ zìjǐ zài zhège wǎngzhàn shàng jiè shū, hěn fāngbiàn","Now students can borrow books on this website by themselves, which is very convenient"],
["我晚一點把網站寄給你","h-438.mp3","wǒ wǎn yīdiǎn bǎ wǎngzhàn jì gěi nǐ","I will send you the website later"],
["776.","h-442.mp3","776","776"],
["雜誌","h-443.mp3","Zázhì","Magazine"],
["桌上有一本雜誌、三隻筆、一張紙","h-444.mp3","zhuō shàng yǒu yī běn zázhì, sān zhī bǐ, yī zhāng zhǐ","There is a magazine, three pens and a piece of paper on the table"],
["奇怪! 我放在桌上的雜誌不見了","h-445.mp3","qíguài! Wǒ fàng zài zhuō shàng de zázhì bùjiànle","Strange! The magazines I put on the table are gone"],
["777.","h-449.mp3","777","777"],
["新聞","h-450.mp3","Xīnwén","news"],
["新聞上說這家電影院快要關門了","h-451.mp3","xīnwén shàng shuō zhè jiā diànyǐngyuàn kuàiyào guānménle","The news says this movie theater is closing soon"],
["爸爸習慣每天早上上班前看新聞","h-452.mp3","bàba xíguàn měitiān zǎoshang shàngbān qián kàn xīnwén","Dad is used to watching the news every morning before going to work"],
["778.","h-456.mp3","778","778"],
["節目","h-457.mp3","Jiémù","programme"],
["爸爸正看著運動節目","h-458.mp3","bàba zhèng kànzhe yùndòng jiémù","dad is watching sports"],
["最近的節目越來越無聊了","h-459.mp3","zuìjìn de jiémù yuè lái yuè wúliáole","Shows are getting boring lately"],
["779.","h-463.mp3","779","779"],
["政治","h-464.mp3","Zhèngzhì","politics"],
["這個政治節目正在討論台灣跟中國的關係","h-465.mp3","zhège zhèngzhì jiémù zhèngzài tǎolùn táiwān gēn zhōngguó de guānxì","This political show is discussing Taiwan's relationship with China"],
["有人說上課不應該談討論政治，你覺得呢?","h-466.mp3","yǒurén shuō shàngkè bù yìng gāi tán tǎolùn zhèngzhì, nǐ juédé ne?","Some people say that politics should not be discussed in class, what do you think?"],
["780.","h-470.mp3","780","780"],
["消息","h-471.mp3","Xiāoxī","information"],
["公司說一有消息，就打電話給我","h-472.mp3","gōngsī shuō yī yǒu xiāoxī, jiù dǎ diànhuà gěi wǒ","The company said to call me as soon as there is news"],
["最後一天公司才打給我，太晚了!","h-473.mp3","zuìhòu yītiān gōngsī cái dǎ gěi wǒ, tài wǎnle!","The company called me on the last day, it was too late!"],
["781.","h-477.mp3","781","781"],
["房租","h-478.mp3","Fángzū","rent"],
["你的房租一個月多少?","h-479.mp3","nǐ de fángzū yīgè yuè duōshǎo?","How much is your monthly rent?"],
["這間屋子的房租一個月只要八千塊","h-480.mp3","Zhè jiān wūzi de fángzū yīgè yuè zhǐyào bāqiān kuài","The monthly rent for this house is only 8,000 yuan"],
["782.","h-484.mp3","782","782"],
["出口","h-485.mp3","Chūkǒu","exit"],
["請問出口在哪裡?","h-486.mp3","qǐngwèn chūkǒu zài nǎlǐ?","Where is the exit?"],
["門上寫著出口兩個字","h-487.mp3","Mén shàng xiězhe chūkǒu liǎng gè zì","The word EXIT is written on the door"],
["783.","h-491.mp3","783","783"],
["門口","h-492.mp3","Ménkǒu","the door"],
["門口這麼小，沙發搬得進去嗎?","h-493.mp3","ménkǒu zhème xiǎo, shāfā bān dé jìnqù ma?","The door is so small, can the sofa fit in?"],
["他們約好下課以後，先在學校門口見面，再一起去打球","h-494.mp3","Tāmen yuē hǎo xiàkè yǐhòu, xiān zài xuéxiào ménkǒu jiànmiàn, zài yīqǐ qù dǎqiú","They made an appointment to meet at the school gate after class, and then go to play together"],
["784.","h-498.mp3","784","784"],
["公寓","h-499.mp3","Gōngyù","apartment"],
["張先生和張太太想要先進去公寓看一看","h-500.mp3","zhāng xiānshēng hé zhāng tàitài xiǎng yào xiān jìnqù gōngyù kàn yī kàn","Mr. Zhang and Mrs. Zhang want to go to the apartment first to have a look"],
["老舊的公寓沒有新的公寓這麼貴","h-501.mp3","lǎo jiù de gōngyù méiyǒu xīn de gōngyù zhème guì","Older apartments are not as expensive as newer ones"],
["785.","h-505.mp3","785","785"],
["大樓","h-506.mp3","Dàlóu","the building"],
["通常公寓沒有大樓這麼貴","h-507.mp3","tōngcháng gōngyù méiyǒu dàlóu zhème guì","Usually apartments are not as expensive as buildings"],
["你去過台北一零一大樓嗎?","h-508.mp3","nǐ qùguò táiběi yī líng yī dàlóu ma?","Have you been to Taipei 101?"],
["786.","h-512.mp3","786","786"],
["樓梯","h-513.mp3","Lóutī","stairs"],
["走樓梯比坐電梯慢","h-514.mp3","zǒu lóutī bǐ zuò diàntī màn","Taking the stairs is slower than taking the elevator"],
["這間舊公寓沒有樓梯，沒有大樓這麼方便","h-515.mp3","zhè jiān jiù gōngyù méiyǒu lóutī, méiyǒu dàlóu zhème fāng biàn","This old apartment has no stairs, not as convenient as the building"],
["787.","h-519.mp3","787","787"],
["牆上","h-520.mp3","Qiáng shàng","the wall"],
["牆上掛著一幅媽媽畫的畫","h-521.mp3","qiáng shàng guàzhe yīfú māmā huà dehuà","On the wall is a picture painted by my mother"],
["牆上寫著不要亂丟垃圾六個字","h-522.mp3","qiáng shàng xiězhe bùyào luàn diū lèsè liù gè zì","Don't litter six words written on the wall"],
["788.","h-526.mp3","788","788"],
["燈","h-527.mp3","Dēng","lamp"],
["可以把燈打開嗎?","h-528.mp3","kěyǐ bǎ dēng dǎkāi ma?","Can I turn on the light?"],
["你每次都忘記關燈","h-529.mp3","Nǐ měi cì dōu wàngjì guān dēng","you forget to turn off the lights every time"],
["789.","h-533.mp3","789","789"],
["臥室","h-534.mp3","Wòshì","bedroom"],
["大明臥室的燈還開著，可能還沒睡吧","h-535.mp3","dàmíng wòshì de dēng hái kāizhe, kěnéng hái méi shuì ba","The light in Daming's bedroom is still on, maybe he hasn't slept yet"],
["媽媽叫大明趕快把臥室打掃乾淨","h-536.mp3","māmā jiào dàmíng gǎnkuài bǎ wòshì dǎsǎo qiánjìng","Mom told Daming to clean up the bedroom quickly"],
["790.","h-540.mp3","790","790"],
["浴室","h-541.mp3","Yùshì","bathroom"],
["李先生總是忘記關浴室電燈","h-542.mp3","lǐ xiānshēng zǒng shì wàngjì guān yùshì diàndēng","Mr. Li always forgets to turn off the light in the bathroom"],
["浴室在2樓","h-543.mp3","yùshì zài 2 lóu","The bathroom is on the 2nd floor"],
["791.","h-547.mp3","791","791"],
["鄰居","h-548.mp3","Línjū","Neighbor"],
["昨天睡得很不好因為樓上鄰居很吵","h-549.mp3","zuótiān shuì dé hěn bù hǎo yīnwèi lóu shàng línjū hěn chǎo","Didn't sleep well yesterday because the upstairs neighbors were noisy"],
["我昨天才搬來這裡，一個鄰居都不認識","h-550.mp3","wǒ zuótiān cái bān lái zhèlǐ, yīgè línjū dōu bù rènshí","I just moved here yesterday and I don't know a single neighbor"],
["792.","h-554.mp3","792","792"],
["活動","h-555.mp3","Huódòng","Activity"],
["美心原本很害羞，但是現在開始參加各種學校的活動","h-556.mp3","měi xīn yuán běn hěn hàixiū, dànshì xiànzài kāishǐ cānjiā gèzhǒng xuéxiào de huódòng","Meixin was originally very shy, but now she starts to participate in various school activities"],
["我昨天才搬來這個社區，一個活動都沒參加過","h-557.mp3","wǒ zuótiān cái bān lái zhège shèqū, yīgè huódòng dōu méi cānjiāguò","I just moved into this neighborhood yesterday and haven't been to a single event"],
["793.","h-561.mp3","793","793"],
["舞會","h-562.mp3","Wǔhuì","prom"],
["美美學校的舞會比我們學校的熱鬧","h-563.mp3","měiměi xuéxiào de wǔhuì bǐ wǒmen xuéxiào de rènào","The prom at Meimei School is more lively than our school's"],
["如果有空的話，要不要來參加我們學校的舞會?","h-564.mp3","rúguǒ yǒu kòng dehuà, yào bùyào lái cānjiā wǒmen xuéxiào de wǔhuì?","If you are free, would you like to come to our school dance?"],
["794.","h-568.mp3","794","794"],
["座位","h-569.mp3","Zuòwèi","seat"],
["這家小吃店只有10個座位","h-570.mp3","zhè jiā xiǎochī diàn zhǐyǒu 10 gè zuòwèi","This snack bar has only 10 seats"],
["座位不夠，客人只能站著吃","h-571.mp3","zuòwèi bùgòu, kèrén zhǐ néng zhànzhe chī","Not enough seats, customers can only stand to eat"],
["795.","h-575.mp3","795","795"],
["小偷","h-576.mp3","Xiǎotōu","Thief"],
["小張的摩托車被小偷偷了","h-577.mp3","xiǎozhāng de mótuō chē bèi xiǎotōu tōule","Xiao Zhang's motorcycle was stolen by a thief"],
["警察馬上就找到小偷了!","h-578.mp3","jǐngchá mǎshàng jiù zhǎodào xiǎotōule!","The police found the thief in no time!"],
["796.","h-582.mp3","796","796"],
["加油","h-583.mp3","Jiāyóu","come on"],
["A: 請問加油站怎麼走? B: 一直往前走，加油站在你的右邊","h-584.mp3","A: Qǐngwèn jiāyóu zhàn zěnme zǒu? B: Yīzhí wǎng qián zǒu, jiāyóu zhàn zài nǐ de yòubiān","A: Excuse me, how to get to the gas station? B: Go straight ahead, the gas station is on your right"],
["加油 !","h-585.mp3","jiāyóu!","come on !"],
["797.","h-589.mp3","797","797"],
["事","h-590.mp3","Shì","thing"],
["請問有什麼事嗎?","h-591.mp3","qǐngwèn yǒu shé me shì ma?","can I help you?"],
["我今天剛好有事，不能去","h-592.mp3","Wǒ jīntiān gānghǎo yǒushì, bùnéng qù","I just have something to do today, so I can't go"],
["798.","h-596.mp3","798","798"],
["事情","h-597.mp3","Shìqíng","matter"],
["很多人總是把事情先想得很糟","h-598.mp3","hěnduō rén zǒng shì bǎ shìqíng xiān xiǎng dé hěn zāo","Many people always think badly first"],
["事情沒有那麼糟，不要想得那麼糟","h-599.mp3","shìqíng méiyǒu nàme zāo, bùyào xiǎng dé nàme zāo","It's not that bad, don't think so bad"],
["799.","h-603.mp3","799","799"],
["辦法","h-604.mp3","Bànfǎ","Method"],
["你有什麼辦法嗎?","h-605.mp3","nǐ yǒu shé me bànfǎ ma?","what can you do"],
["小張的摩托車被偷了，沒有辦法去上班","h-606.mp3","Xiǎozhāng de mótuō chē bèi tōule, méiyǒu bànfǎ qù shàngbān","Xiao Zhang's motorcycle was stolen and he couldn't go to work"],
["800.","h-610.mp3","800","800"],
["方法","h-611.mp3","Fāngfǎ","method"],
["張經理已經試過這個方法了","h-612.mp3","zhāng jīnglǐ yǐjīng shìguò zhège fāngfǎle","Manager Zhang has already tried this method"],
["這個方法很有用","h-613.mp3","zhège fāngfǎ hěn yǒuyòng","This method is very useful"],
["801.","h-617.mp3","801","801"],
["麻煩","h-618.mp3","Máfan","trouble"],
["麻煩幫我微波包子，謝謝","h-619.mp3","máfan bāng wǒ wéibō bāozi, xièxiè","Please help me to microwave the steamed buns, thank you"],
["這個新員工帶給同事很多麻煩","h-620.mp3","zhège xīn yuángōng dài gěi tóngshì hěnduō máfan","This new employee has caused a lot of trouble to his colleagues"],
["802.","h-624.mp3","802","802"],
["機會","h-625.mp3","Jīhuì","Chance"],
["如果有機會希望可以去歐洲旅行","h-626.mp3","rúguǒ yǒu jīhuì xīwàng kěyǐ qù ōuzhōu lǚxíng","I hope to travel to Europe if I have the chance"],
["如果有機會希望有一天可以見面","h-627.mp3","rúguǒ yǒu jīhuì xīwàng yǒu yītiān kěyǐ jiànmiàn","If there is a chance, I hope to meet one day"],
["803.","h-631.mp3","803","803"],
["可能","h-632.mp3","Kěnéng","possible"],
["小張一考試就緊張，他可能要先放鬆一下","h-633.mp3","xiǎozhāng yī kǎoshì jiù jǐnzhāng, tā kěnéng yào xiān fàngsōng yīxià","Xiao Zhang is nervous when he takes the exam, he may need to relax first"],
["網站上寫如果下雨的話，演唱會可能就取消了","h-634.mp3","wǎngzhàn shàng xiě rúguǒ xià yǔ dehuà, yǎnchàng huì kěnéng jiù qǔxiāole","It says on the website that if it rains, the concert may be canceled"],
["804.","h-638.mp3","804","804"],
["解釋","h-639.mp3","Jiěshì","explain"],
["房東正在跟大明解釋怎麼開冷氣","h-640.mp3","fángdōng zhèngzài gēn dàmíng jiěshì zěnme kāi lěngqì","The landlord is explaining to Daming how to turn on the air conditioner"],
["老師正在跟同學解釋歷史問題","h-641.mp3","lǎoshī zhèngzài gēn tóngxué jiěshì lìshǐ wèntí","The teacher is explaining historical issues to the students"],
["805.","h-645.mp3","805","805"],
["經驗","h-646.mp3","Jīngyàn","experience"],
["我的工作經驗沒有哥哥的多","h-647.mp3","wǒ de gōngzuò jīngyàn méiyǒu gēgē de duō","I don't have as much work experience as my brother"],
["對我來說，去台灣生活是一個很好的經驗","h-648.mp3","duì wǒ lái shuō, qù táiwān shēnghuó shì yīgè hěn hǎo de jīngyàn","For me, going to live in Taiwan is a great experience"],
["806.","h-652.mp3","806","806"],
["習慣","h-653.mp3","Xíguàn","Habit"],
["張先生習慣每天七點起床，然後去公園慢跑","h-654.mp3","zhāng xiānshēng xíguàn měitiān qī diǎn qǐchuáng, ránhòu qù gōngyuán mànpǎo","Mr. Zhang is used to getting up at seven o'clock every day, and then go jogging in the park"],
["培養運動的好習慣可以改善健康","h-655.mp3","péiyǎng yùndòng de hǎo xíguàn kěyǐ gǎishàn jiànkāng","Developing good habits of exercise can improve health"],
["807.","h-659.mp3","807","807"],
["禮貌","h-660.mp3","Lǐmào","polite"],
["這家餐廳的服務生都很有禮貌，服務也非常好","h-661.mp3","zhè jiā cāntīng de fúwù shēng dōu hěn yǒu lǐmào, fúwù yě fēicháng hǎo","The waiters in this restaurant are very polite and the service is very good"],
["東明的新女朋友又有禮貌又有耐心","h-662.mp3","dōngmíng de xīn nǚ péngyǒu yòu yǒu lǐmào yòu yǒu nàixīn","Dongming's new girlfriend is polite and patient"],
["808.","h-666.mp3","808","808"],
["出生","h-667.mp3","Chūshēng","born"],
["我在台北出生、長大","h-668.mp3","wǒ zài táiběi chūshēng, zhǎng dà","I was born and raised in Taipei"],
["王小姐的孩子快要出生了","h-669.mp3","wáng xiǎojiě de háizi kuàiyào chūshēngle","Miss Wang's baby is about to be born"],
["809.","h-673.mp3","809","809"],
["照顧","h-674.mp3","Zhàogù","take care of"],
["好的公司應該要照顧員工","h-675.mp3","hǎo de gōngsī yīnggāi yào zhàogù yuángōng","A good company should take care of its employees"],
["王小姐跟王太太正在照顧剛出生的孩子","h-676.mp3","wáng xiǎojiě gēn wáng tàitài zhèngzài zhàogù gāng chūshēng de háizi","Ms. Wang and Mrs. Wang are taking care of their newborn baby"],
["810.","h-680.mp3","810","810"],
["聊天","h-681.mp3","Liáotiān","chat"],
["爸爸很喜歡跟鄰居聊天","h-682.mp3","bàba hěn xǐhuān gēn línjū liáotiān","Dad likes to chat with the neighbors"],
["很多人喜歡跟計程車司機聊天","h-683.mp3","hěnduō rén xǐhuān gēn jìchéngchē sījī liáotiān","Many people like to chat with taxi drivers"],
["811.","h-687.mp3","811","811"],
["大聲","h-688.mp3","Dà shēng","loudly"],
["在圖書館如果講話太大聲會影響別人","h-689.mp3","zài túshū guǎn rúguǒ jiǎnghuà tài dà shēng huì yǐngxiǎng biérén","Talking too loudly in the library will disturb others"],
["在電影院如果講話太大聲會吵到別人","h-690.mp3","zài diànyǐngyuàn rúguǒ jiǎnghuà tài dà shēng huì chǎo dào biérén","If you speak too loudly in a movie theater, you will disturb others"],
["812.","h-694.mp3","812","812"],
["聲音","h-695.mp3","Shēngyīn","sound"],
["鄰居聊天的聲音太大聲了，很吵","h-696.mp3","línjū liáotiān de shēngyīn tài dà shēngle, hěn chǎo","Neighbors chatting too loudly, very noisy"],
["手機的聲音好小聲，我想拿去檢查","h-697.mp3","shǒujī de shēngyīn hǎo xiǎo shēng, wǒ xiǎng ná qù jiǎnchá","The sound of the mobile phone is so low, I want to check it"],
["813.","i-01.mp3","813","813"],
["留言","i-02.mp3","Liúyán","message"],
["如果有問題的話，可以留言給老師","i-03.mp3","rúguǒ yǒu wèntí de huà, kěyǐ liúyán gěi lǎoshī","If you have any questions, you can leave a message to the teacher"],
["寫留言的時候記得要有禮貌","i-04.mp3","xiě liúyán de shíhòu jìdé yào yǒu lǐmào","Remember to be polite when writing a message"],
["814.","i-08.mp3","814","814"],
["散步","i-09.mp3","Sànbù","walk"],
["吃太飽了，我們去散步吧！","i-10.mp3","chī tài bǎole, wǒmen qù sànbù ba!","Too full to eat, let's go for a walk!"],
["培養散步的好習慣可以改善健康","i-11.mp3","Péiyǎng sànbù de hǎo xíguàn kěyǐ gǎishàn jiànkāng","Getting into the habit of walking can improve your health"],
["815.","i-15.mp3","815","815"],
["睡","i-16.mp3","Shuì","sleep"],
["不要在沙發上睡覺，進去房間睡!","i-17.mp3","bùyào zài shāfā shàng shuìjiào, jìnqù fángjiān shuì!","Don't sleep on the couch, go to your room and sleep!"],
["小李平常晚上十一點睡，今天為了看比賽，晚一個小時才睡","i-18.mp3","Xiǎo lǐ píngcháng wǎnshàng shíyīdiǎn shuì, jīntiān wèile kàn bǐsài, wǎn yīgè xiǎoshí cái shuì","Xiao Li usually goes to bed at 11 o'clock in the evening, but today he went to bed an hour later to watch the game"],
["816.","i-22.mp3","816","816"],
["打掃","i-23.mp3","Dǎsǎo","clean"],
["我負責打掃房間，我太太負責洗衣服","i-24.mp3","wǒ fùzé dǎsǎo fángjiān, wǒ tàitài fùzé xǐ yī fú","I clean the room and my wife does the laundry"],
["為了保持乾淨，李太太一個禮拜打掃兩次","i-25.mp3","wèile bǎochí qiánjìng, lǐ tàitài yīgè lǐbài dǎsǎo liǎng cì","In order to keep it clean, Mrs. Lee cleans it twice a week"],
["817.","i-29.mp3","817","817"],
["剪","i-30.mp3","Jiǎn","Cut"],
["A:你剪頭髮了嗎？ 變短了!","i-31.mp3","A: Nǐ jiǎn tóufàle ma? Biàn duǎnle!","A: Have you cut your hair yet? shortened!"],
["B: 對！我昨天去剪頭髮了","i-32.mp3","B: Duì! Wǒ zuótiān qù jiǎn tóufàle","B: Yes! I went to have my hair cut yesterday"],
["818.","i-36.mp3","818","818"],
["過年","i-37.mp3","Guònián","new year"],
["A:今年過年有計劃做什麼嗎?","i-38.mp3","A: Jīnnián guònián yǒu jìhuà zuò shénme ma?","A: Do you have any plans for Chinese New Year this year?"],
["B:我們全家計畫回南部過年","i-39.mp3","B: Wǒmen quánjiā jì huà huí nánbù guònián","B: Our family plans to go back to the south for the New Year"],
["819.","i-43.mp3","819","819"],
["同學","i-44.mp3","Tóngxué","classmate"],
["安同是我的大學同學，也是我小學同學","i-45.mp3","ān tóng shì wǒ de dàxué tóngxué, yěshì wǒ xiǎoxué tóngxué","An Tong is my college classmate and my elementary school classmate"],
["老師: 同學，明天要考試喔 ! 大家記得要準備","i-46.mp3","lǎoshī: Tóngxué, míngtiān yào kǎoshì ō! Dàjiā jì dé yào zhǔnbèi","Teacher: Classmates, there is an exam tomorrow! Everyone remember to prepare"],
["820.","i-50.mp3","820","820"],
["小學生","i-51.mp3","Xiǎoxuéshēng","pupils"],
["現在的小學生比以前的小學生自由","i-52.mp3","xiànzài de xiǎoxuéshēng bǐ yǐqián de xiǎoxuéshēng zìyóu","Today's primary school students are freer than former primary school students"],
["以前的小學生下課以後都要寫很多作業","i-53.mp3","yǐqián de xiǎoxuéshēng xiàkè yǐhòu dōu yào xiě hěnduō zuòyè","In the past, primary school students had to do a lot of homework after class"],
["821.","i-57.mp3","821","821"],
["大學","i-58.mp3","Dàxué","University"],
["大學生的生活是不是越來越自由了?","i-59.mp3","dàxuéshēng de shēnghuó shì bùshì yuè lái yuè zìyóule?","Is the life of college students more and more free?"],
["安同是大學生，在台灣大學唸書","i-60.mp3","Ān tóng shì dàxuéshēng, zài táiwān dàxué niànshū","An Tong is a college student studying at National Taiwan University"],
["822.","i-64.mp3","822","822"],
["教師","i-65.mp3","Jiàoshī","teacher"],
["這個學年的教師一共有20位","i-66.mp3","zhège xuénián de jiàoshī yīgòng yǒu 20 wèi","There are 20 teachers in this school year"],
["新聞說今年很多學校缺教師","i-67.mp3","xīnwén shuō jīnnián hěnduō xuéxiào quē jiàoshī","The news says that many schools are short of teachers this year"],
["823.","i-71.mp3","823","823"],
["校長","i-72.mp3","Xiàozhǎng","headmaster"],
["我從來沒有看過我們學校的校長","i-73.mp3","wǒ cónglái méiyǒu kànguò wǒmen xuéxiào de xiàozhǎng","I have never seen the principal of our school"],
["爺爺以前是這間學校的校長，現在退休了","i-74.mp3","yéyé yǐqián shì zhè jiān xuéxiào de xiàozhǎng, xiànzài tuìxiūle","Grandpa used to be the principal of this school, but now he is retired"],
["824.","i-78.mp3","824","824"],
["校園","i-79.mp3","Xiàoyuán","campus"],
["你們的校園比我們的大好多","i-80.mp3","nǐmen de xiàoyuán bǐ wǒmen de dà hǎoduō","Your campus is much bigger than ours"],
["上完課以後可欣喜歡在校園裡走一走","i-81.mp3","shàng wán kè yǐhòu kě xīn xǐhuān zài xiàoyuán lǐ zǒu yī zǒu","Kexin likes to take a walk around the campus after class"],
["825.","i-85.mp3","825","825"],
["操場","i-86.mp3","Cāochǎng","playground"],
["下課以後他們約好要在操場上見面","i-87.mp3","xiàkè yǐhòu tāmen yuē hǎo yào zài cāochǎng shàng jiànmiàn","They made an appointment to meet on the playground after class"],
["操場上有些人在打棒球，有些人在跑步","i-88.mp3","cāochǎng shàng yǒuxiē rén zài dǎ bàngqiú, yǒuxiē rén zài pǎobù","On the playground some people are playing baseball and some are running"],
["826.","i-92.mp3","826","826"],
["學院","i-93.mp3","Xuéyuàn","college"],
["左邊是語言學院，右邊是商業學院","i-94.mp3","zuǒbiān shì yǔyán xuéyuàn, yòubiān shì shāngyè xuéyuàn","On the left is the School of Languages, on the right is the School of Business"],
["語言學院的學生跟商業學院的一樣多","i-95.mp3","yǔyán xuéyuàn de xuéshēng gēn shāngyè xuéyuàn de yīyàng duō","There are as many students in the language school as there are in the business school"],
["827.","i-99.mp3","827","827"],
["學期","i-100.mp3","Xuéqí","semester"],
["一年有兩個學期","i-101.mp3","yī nián yǒu liǎng gè xuéqí","There are two semesters a year"],
["老師常說「新的學期一定比上學期更好」","i-102.mp3","lǎoshī cháng shuō `xīn de xuéqí yīdìng bǐ shàng xuéqí gèng hǎo'","Teachers often say 'the new semester must be better than the last semester'"],
["828.","i-106.mp3","828","828"],
["學費","i-107.mp3","Xuéfèi","tuition fee"],
["哥哥念私立學校，一學期的學費比妹妹貴","i-108.mp3","gēgē niàn sīlì xuéxiào, yī xuéqí de xuéfèi bǐ mèimei guì","The elder brother attends a private school, and the tuition fee for one semester is more expensive than that of the younger sister"],
["通常公立的學校的一學期的學費比私立學校便宜","i-109.mp3","tōngcháng gōnglì de xuéxiào de yī xuéqí de xuéfèi bǐ sīlì xuéxiào piányí","Generally, the tuition fee for one semester of public schools is cheaper than that of private schools"],
["829.","i-113.mp3","829","829"],
["成績","i-114.mp3","Chéngjī","score"],
["李明華最近交新女朋友，成績越來越差","i-115.mp3","lǐmínghuá zuìjìn jiāo xīn nǚ péngyǒu, chéngjī yuè lái yuè chà","Li Minghua recently made a new girlfriend, and his grades are getting worse"],
["校長認為成績才是最重要的，但是李先生不這樣認為","i-116.mp3","xiàozhǎng rènwéi chéngjī cái shì zuì zhòngyào de, dànshì lǐ xiānshēng bù zhèyàng rènwéi","The principal thinks grades are the most important thing, but Mr. Li doesn't think so"],
["830.","i-120.mp3","830","830"],
["班","i-121.mp3","Bān","class"],
["我們班才十五個人，非常少!","i-122.mp3","wǒmen bān cái shíwǔ gèrén, fēicháng shǎo!","There are only fifteen people in our class, very few!"],
["快要畢業了，我們全班都很興奮","i-123.mp3","Kuàiyào bìyèle, wǒmen quán bān dōu hěn xīngfèn","Graduation is coming soon, our whole class is very excited"],
["831.","i-127.mp3","831","831"],
["課","i-128.mp3","Kè","class"],
["吉他課一個小時才三百塊，這麼便宜!","i-129.mp3","jítā kè yīgè xiǎoshí cái sānbǎi kuài, zhème piányí!","Guitar lessons are only 300 yuan an hour, so cheap!"],
["你一個星期上幾次的英文課?","i-130.mp3","Nǐ yīgè xīngqí shàng jǐ cì de yīngwén kè?","How many times a week do you take English classes?"],
["832.","i-134.mp3","832","832"],
["中文","i-135.mp3","Zhōngwén","Chinese"],
["你學中文學多久了?","i-136.mp3","nǐ xué zhōngwénxué duōjiǔle?","How long have you been studying Chinese?"],
["我一個禮拜才上ㄧ次中文課，不夠","i-137.mp3","Wǒ yīgè lǐbài cái shàngyi1 cì zhōngwén kè, bùgòu","I only take Chinese class once a week, not enough"],
["833.","i-141.mp3","833","833"],
["漢語","i-142.mp3","Hànyǔ","Chinese"],
["這本漢語課本是誰的?","i-143.mp3","zhè běn hànyǔ kèběn shì shuí de?","Whose is this Chinese textbook?"],
["漢語跟中文的意思一樣","i-144.mp3","Hànyǔ gēn zhōngwén de yìsi yīyàng","Chinese means the same as Chinese"],
["834.","i-148.mp3","834","834"],
["漢字","i-149.mp3","Hànzì","Chinese character"],
["這個漢字是什麼意思?","i-150.mp3","zhège hànzì shì shénme yìsi?","What does this Chinese character mean?"],
["老師要我把這個漢字寫在黑板上","i-151.mp3","Lǎoshī yào wǒ bǎ zhège hànzì xiě zài hēibǎn shàng","The teacher asked me to write this Chinese character on the blackboard"],
["835.","i-155.mp3","835","835"],
["語法","i-156.mp3","Yǔfǎ","grammar"],
["王阿姨常常教我中文的語法","i-157.mp3","wáng āyí chángcháng jiào wǒ zhōngwén de yǔfǎ","Aunt Wang often teaches me Chinese grammar"],
["聽說德文的語法比中文的難","i-158.mp3","tīng shuō dé wén de yǔfǎ bǐ zhōngwén de nán","I heard German grammar is more difficult than Chinese"],
["836.","i-162.mp3","836","836"],
["文章","i-163.mp3","Wénzhāng","article"],
["這篇網路文章討論了很多有趣的台灣文化","i-164.mp3","zhè piān wǎng lù wénzhāng tǎolùnle hěnduō yǒuqù de táiwān wénhuà","This internet article discusses a lot of interesting Taiwanese culture"],
["你覺得這篇文章告訴了我們什麼事?","i-165.mp3","nǐ juédé zhè piān wénzhāng gàosùle wǒmen shénme shì?","What do you think this article tells us?"],
["837.","i-169.mp3","837","837"],
["美術","i-170.mp3","Měishù","art"],
["文華上完美術課，就去打球了","i-171.mp3","wénhuá shàng wán měishù kè, jiù qù dǎqiúle","Wenhua finished her art class and went to play"],
["只有十幾個人在美術教室畫畫","i-172.mp3","zhǐyǒu shí jǐ gèrén zài měishù jiàoshì huà huà","There are only a dozen people drawing in the art classroom"],
["838.","i-176.mp3","838","838"],
["藝術","i-177.mp3","Yìshù","Art"],
["藝術有的時候很難懂","i-178.mp3","yìshù yǒu de shíhòu hěn nán dǒng","Art is sometimes difficult to understand"],
["這幅畫是藝術，沒辦法用金錢衡量","i-179.mp3","zhè fú huà shì yìshù, méi bànfǎ yòng jīnqián héngliáng","This painting is art, there is no way to measure it in money"],
["839.","i-183.mp3","839","839"],
["書法","i-184.mp3","Shūfǎ","calligraphy"],
["很多人想上書法課，每次的教室的座位都是滿的","i-185.mp3","hěnduō rén xiǎng shàng shūfǎ kè, měi cì de jiàoshì de zuòwèi dōu shì mǎn de","Many people want to take calligraphy class, every time the classroom seats are full"],
["書法課的學費跟美術課的學費一樣貴","i-186.mp3","shūfǎ kè de xuéfèi gēn měishù kè de xuéfèi yīyàng guì","Calligraphy class tuition is as expensive as art class tuition"],
["840.","i-190.mp3","840","840"],
["文化","i-191.mp3","Wénhuà","culture"],
["很多外國人來台灣因為想多了解台灣文化","i-192.mp3","hěnduō wàiguó rén lái táiwān yīnwèi xiǎng duō liǎojiě táiwān wénhuà","Many foreigners come to Taiwan because they want to know more about Taiwanese culture"],
["文化跟語言一樣重要","i-193.mp3","wénhuà gēn yǔyán yīyàng zhòngyào","Culture is as important as language"],
["841.","i-197.mp3","841","841"],
["體育","i-198.mp3","Tǐyù","physical education"],
["體育老師要我們跑操場三圈","i-199.mp3","tǐyù lǎoshī yào wǒmen pǎo cāochǎng sān quān","The physical education teacher asked us to run three laps on the playground"],
["體育老師不是教我們游泳，就是教我們打棒球，大家都有一點累","i-200.mp3","tǐyù lǎoshī bùshì jiào wǒmen yóuyǒng, jiùshì jiào wǒmen dǎ bàngqiú, dàjiā dōu yǒu yī diǎn lèi","The physical education teacher is not teaching us to swim, is to teach us to play baseball, everyone is a little tired"],
["842.","i-204.mp3","842","842"],
["書桌","i-205.mp3","Shūzhuō","desk"],
["A: 媽，你看見我的課本嗎?","i-206.mp3","A: Mā, nǐ kànjiàn wǒ de kèběn ma?","A: Mom, have you seen my textbook?"],
["B: 書桌那裡沒有嗎? 你的課本不是放書桌上，就是放在跟字典放在一起","i-207.mp3","B: Shūzhuō nàlǐ méiyǒu ma? Nǐ de kèběn bùshì fàng shūzhuō shàng, jiùshì fàng zài gēn zìdiǎn fàng zài yīqǐ","B: Are there no desks? Your textbooks are either placed on the desk, or put together with the dictionary"],
["843.","i-211.mp3","843","843"],
["字典","i-212.mp3","Zìdiǎn","dictionary"],
["小玉把課本跟字典一起放在書桌上","i-213.mp3","xiǎoyù bǎ kèběn gēn zìdiǎn yīqǐ fàng zài shūzhuō shàng","Xiaoyu put the textbook and the dictionary on the desk"],
["快要上課了，但是我還是找不到我的字典","i-214.mp3","kuàiyào shàngkèle, dànshì wǒ háishì zhǎo bù dào wǒ de zìdiǎn","It's almost time for class, but I still can't find my dictionary"],
["844.","i-218.mp3","844","844"],
["鉛筆","i-219.mp3","Qiānbǐ","pencil"],
["老師規定小學一年級的學生只能用鉛筆寫字","i-220.mp3","lǎoshī guīdìng xiǎoxué yī niánjí de xuéshēng zhǐ néng yòng qiānbǐ xiě zì","The teacher stipulates that the students in the first grade of elementary school can only write with pencils"],
["弟弟每天都畫畫，不是用鉛筆畫，就是用水彩筆畫","i-221.mp3","dìdì měitiān dū huà huà, bùshì yòng qiānbǐhuà, jiùshì yòng shuǐcǎi bǐhuà","My younger brother draws every day, either with pencils or watercolors"],
["845.","i-225.mp3","845","845"],
["尺","i-226.mp3","Chǐ","ruler"],
["老師規定鉛筆盒裡面要有尺、鉛筆、橡皮擦","i-227.mp3","lǎoshī guīdìng qiānbǐ hé lǐmiàn yào yǒu chǐ, qiānbǐ, xiàngpí cā","The teacher stipulates that there must be a ruler, pencil, and eraser in the pencil box."],
["書桌上放著一把尺、三支鉛筆，還有一本小美的字典","i-228.mp3","shūzhuō shàng fàngzhe yī bǎ chǐ, sān zhī qiānbǐ, hái yǒu yī běn xiǎo měide zìdiǎn","On the desk is a ruler, three pencils, and a little beauty's dictionary"],
["846.","i-232.mp3","846","846"],
["毛筆","i-233.mp3","Máobǐ","writing brush"],
["你會用毛筆寫字嗎?","i-234.mp3","nǐ huì yòng máobǐ xiě zì ma?","Can you write with a brush?"],
["書桌上除了毛筆，什麼都沒有","i-235.mp3","Shūzhuō shàng chúle máobǐ, shénme dōu méiyǒu","There is nothing on the desk except brushes"],
["847.","i-239.mp3","847","847"],
["開學","i-240.mp3","Kāixué","start of school"],
["開學第一天，每個學生都很興奮","i-241.mp3","kāixué dì yī tiān, měi gè xuéshēng dōu hěn xīngfèn","Every student is excited about the first day of school"],
["快要開學了，很多學生都還沒有寫完暑假作業","i-242.mp3","kuàiyào kāixuéle, hěnduō xuéshēng dōu hái méiyǒu xiě wán shǔjià zuòyè","School is about to start, and many students haven't finished their summer homework yet"],
["848.","i-246.mp3","848","848"],
["放假","i-247.mp3","Fàngjià","Have a holiday"],
["放假的時候，旅遊景點到處都是人，非常熱鬧","i-248.mp3","fàngjià de shíhòu, lǚyóu jǐngdiǎn dàochù dōu shì rén, fēicháng rènào","During the holidays, the tourist attractions are full of people, very lively"],
["明天放假，李小姐比較想要自己在家休息","i-249.mp3","míngtiān fàngjià, lǐ xiǎojiě bǐjiào xiǎng yào zìjǐ zàijiā xiūxí","Tomorrow is a holiday, Ms. Li prefers to rest at home by herself"],
["849.","i-253.mp3","849","849"],
["暑假","i-254.mp3","Shǔjià","summer vacation"],
["A:暑假好玩嗎? 有沒有出去?","i-255.mp3","A: Shǔjià hǎowán ma? Yǒu méiyǒu chūqù?","A: Is summer vacation fun? Did you go out?"],
["B:暑假...很好玩啊，我們全家去日本玩五天，然後我還去南部找以前的同學","i-256.mp3","B: Shǔjià... Hěn hǎowán a, wǒmen quánjiā qù rìběn wán wǔ tiān, ránhòu wǒ hái qù nánbù zhǎo yǐqián de tóngxué","B: Summer vacation...it’s fun. Our family went to Japan for five days, and then I went to the south to find my former classmates."],
["850.","i-260.mp3","850","850"],
["寒假","i-261.mp3","Hánjià","winter vacation"],
["A:寒假快到了，你打算做什麼?","i-262.mp3","A: Hánjià kuài dàole, nǐ dǎsuàn zuò shénme?","A: The winter vacation is coming, what are you going to do?"],
["B:我們全家要去歐洲玩兩個禮拜，你呢?","i-263.mp3","B: Wǒmen quánjiā yào qù ōuzhōu wán liǎng gè lǐbài, nǐ ne?","B: Our family is going to Europe for two weeks, how about you?"],
["851.","i-267.mp3","851","851"],
["畢業","i-268.mp3","Bìyè","graduate"],
["小良想要問他喜歡的女生要不要來參加畢業舞會","i-269.mp3","xiǎo liáng xiǎng yào wèn tā xǐhuān de nǚshēng yào bùyào lái cānjiā bìyè wǔhuì","Xiaoliang wants to ask the girl he likes if she wants to come to the prom"],
["一畢業後，很多人馬上去找工作","i-270.mp3","yī bìyè hòu, hěnduō rén mǎshàng qù zhǎo gōngzuò","Immediately after graduation, many people immediately look for a job"],
["852.","i-274.mp3","852","852"],
["教","i-275.mp3","Jiào","teach"],
["李天明正在找學中文的外國人，他可以教他們中文","i-276.mp3","lǐ tiānmíng zhèngzài zhǎo xué zhōngwén de wàiguó rén, tā kěyǐ jiào tāmen zhōngwén","Li Tianming is looking for foreigners to learn Chinese, he can teach them Chinese"],
["天明住台北，想教外國人中文，不用錢","i-277.mp3","tiānmíng zhù táiběi, xiǎng jiào wàiguó rén zhōng wén, bùyòng qián","Tianming lives in Taipei and wants to teach foreigners Chinese without money"],
["853.","i-281.mp3","853","853"],
["教書","i-282.mp3","Jiāoshū","to teach"],
["一畢業後，妹妹就在大學教書","i-283.mp3","yī bìyè hòu, mèimei jiù zài dàxué jiāoshū","After graduating, my sister taught at the university"],
["天明在高中教書教了三年多了","i-284.mp3","tiānmíng zài gāozhōng jiāoshū jiàole sān nián duōle","Tianming has been teaching in high school for more than three years"],
["854.","i-288.mp3","854","854"],
["唸","i-289.mp3","Niàn","read"],
["這張紙上面的字我看不懂，怎麼唸?","i-290.mp3","zhè zhāng zhǐ shàngmiàn de zì wǒ kàn bù dǒng, zěnme niàn?","I can't read the words on this piece of paper, how do I pronounce it?"],
["明華唸得不清楚，沒有一個人聽得懂","i-291.mp3","Míng huá niàn dé bù qīngchǔ, méiyǒu yīgèrén tīng dé dǒng","Minghua couldn't read clearly, no one could understand"],
["855.","i-295.mp3","855","855"],
["遲到","i-296.mp3","Chídào","be late"],
["天明遲到了，晚了一個小時才到","i-297.mp3","tiānmíng chídàole, wǎnle yīgè xiǎoshí cái dào","Dawn is late, arrived an hour late"],
["明天的活動很重要! 不要遲到","i-298.mp3","míngtiān de huódòng hěn zhòngyào! Bùyào chídào","Tomorrow's event is important! Don't be late"],
["856.","i-302.mp3","856","856"],
["職業","i-303.mp3","Zhíyè","Profession"],
["李經理要求每個人寫下姓名、地址、職業","i-304.mp3","lǐ jīnglǐ yāoqiú měi gèrén xiě xià xìngmíng, dìzhǐ, zhíyè","Manager Li asked everyone to write down their name, address, occupation"],
["請問您父親的職業是什麼 ?","i-305.mp3","qǐngwèn nín fùqīn de zhíyè shì shénme?","What is your father's occupation?"],
["857.","i-309.mp3","857","857"],
["經理","i-310.mp3","Jīnglǐ","manager"],
["王經理認為每件事情都有解決辦法","i-311.mp3","wáng jīnglǐ rènwéi měi jiàn shìqíng dōu yǒu jiějué bànfǎ","Manager Wang believes that everything has a solution"],
["經理認為客戶的要求沒有道理，不想答應","i-312.mp3","jīnglǐ rènwéi kèhù de yāoqiú méiyǒu dàolǐ, bùxiǎng dāyìng","The manager thinks the customer's request is unreasonable and does not want to agree"],
["858.","i-316.mp3","858","858"],
["記者","i-317.mp3","Jìzhě","reporter"],
["記者每天都要寫很多文章","i-318.mp3","jìzhě měitiān dū yào xiě hěnduō wénzhāng","Journalists write a lot of articles every day"],
["記者正在訪問學生工作經驗","i-319.mp3","jìzhě zhèngzài fǎngwèn xuéshēng gōngzuò jīngyàn","Journalists are visiting students for work experience"],
["859.","i-323.mp3","859","859"],
["服務員","i-324.mp3","Fúwùyuán","waiter"],
["這家店有很多服務員","i-325.mp3","zhè jiā diàn yǒu hěnduō fúwùyuán","There are many waiters in this store"],
["台灣人比較少用服務員這三個字","i-326.mp3","táiwān rén bǐjiào shǎo yòng fúwùyuán zhè sān gè zì","Taiwanese people seldom use the word waiter"],
["860.","i-330.mp3","860","860"],
["服務生","i-331.mp3","Fúwù shēng","waiter"],
["這家餐廳的服務生服務態度很好也很有禮貌","i-332.mp3","zhè jiā cāntīng de fúwù shēng fúwù tàidù hěn hǎo yě hěn yǒu lǐmào","The waiters in this restaurant are very nice and polite"],
["客人對服務生的服務非常滿意","i-333.mp3","kèrén duì fúwù shēng de fúwù fēicháng mǎnyì","Customers are very satisfied with the waiter's service"],
["861.","i-337.mp3","861","861"],
["客人","i-338.mp3","Kèrén","guest"],
["這位客人下午三點才到旅館，帶了好幾件大行李","i-339.mp3","zhè wèi kèrén xiàwǔ sān diǎn cái dào lǚguǎn, dàile hǎojǐ jiàn dà xínglǐ","The guest arrived at the hotel at three o'clock in the afternoon and brought several large pieces of luggage"],
["旅館經理告訴客人，現在住一晚要兩百多塊","i-340.mp3","lǚguǎn jīnglǐ gàosù kèrén, xiànzài zhù yī wǎn yào liǎng bǎi duō kuài","The hotel manager told the guests that it costs more than 200 yuan to stay a night now"],
["862.","i-344.mp3","862","862"],
["顧客","i-345.mp3","Gùkè","customer"],
["有一句很有名的話「顧客永遠是對的」","i-346.mp3","yǒuyījù hěn yǒumíng dehuà `gùkè yǒngyuǎn shì duì de'","There is a famous saying 'the customer is always right'"],
["這家企業的精神就是「顧客永遠是對的」","i-347.mp3","zhè jiā qì yè de jīngshén jiùshì `gùkè yǒngyuǎn shì duì de'","The spirit of this company is 'the customer is always right'"],
["863.","i-351.mp3","863","863"],
["服務","i-352.mp3","Fúwù","Serve"],
["這家飲料店的服務態度非常好","i-353.mp3","zhè jiā yǐnliào diàn de fúwù tàidù fēicháng hǎo","The service attitude of this beverage store is very good"],
["客人非常滿意店員的服務","i-354.mp3","kèrén fēicháng mǎnyì diànyuán de fúwù","Customers are very satisfied with the service of the store staff"],
["864.","i-358.mp3","864","864"],
["下班","i-359.mp3","Xiàbān","get off work"],
["我平常下班以後，就馬上回家","i-360.mp3","wǒ píngcháng xiàbān yǐhòu, jiù mǎshàng huí jiā","I usually go home immediately after get off work"],
["姊姊平常沒空，下班以後才可以做自己的事","i-361.mp3","jiě jie píngcháng méi kōng, xiàbān yǐhòu cái kěyǐ zuò zìjǐ de shì","My sister is usually not free, so she can only do her own things after get off work"],
["865.","i-365.mp3","865","865"],
["開會","i-366.mp3","Kāihuì","the meeting"],
["開會的時候，老闆說如果對公司有意見要勇敢說出來","i-367.mp3","kāihuì de shíhòu, lǎobǎn shuō rúguǒ duì gōngsī yǒu yìjiàn yào yǒnggǎn shuō chūlái","During the meeting, the boss said that if you have an opinion about the company, you should speak up bravely"],
["張經理希望大家可以思考一下公司的新方向","i-368.mp3","zhāng jīnglǐ xīwàng dàjiā kěyǐ sīkǎo yīxià gōngsī de xīn fāngxiàng","Manager Zhang hopes that everyone can think about the new direction of the company"],
["866.","i-372.mp3","866","866"],
["報告","i-373.mp3","Bàogào","Report"],
["報告收到了嗎? 我昨天已經寄給你了喔!","i-374.mp3","bàogào shōu dàole ma? Wǒ zuótiān yǐjīng jì gěi nǐle ō!","Did you get the report? I sent it to you yesterday!"],
["這份報告提到了我們為什麼常常覺得身體很累的原因","i-375.mp3","Zhè fèn bàogào tí dàole wǒmen wèishéme chángcháng juédé shēntǐ hěn lèi de yuányīn","This report touches on the reasons why we often feel physically tired"],
["867.","i-379.mp3","867","867"],
["計劃","i-380.mp3","Jìhuà","plan"],
["張經理目前對公司有什麼新計畫嗎?","i-381.mp3","zhāng jīnglǐ mùqián duì gōngsī yǒu shé me xīn jì huà ma?","Does Manager Zhang have any new plans for the company?"],
["現在公司正在計畫未來公司的新方向","i-382.mp3","Xiànzài gōngsī zhèngzài jì huà wèilái gōngsī de xīn fāngxiàng","Now the company is planning a new direction for the company in the future"],
["868.","i-386.mp3","868","868"],
["宿舍","i-387.mp3","Sùshè","dormitory"],
["這個工廠有員工宿舍","i-388.mp3","zhège gōngchǎng yǒu yuángōng sùshè","This factory has staff quarters"],
["學生宿舍前面有一個牌子，寫著講話請小聲","i-389.mp3","xuéshēng sùshè qiánmiàn yǒu yīgè páizi, xiě zhuó jiǎnghuà qǐng xiǎo shēng","There is a sign in front of the student dormitory that says please keep your voice down"],
["869.","i-393.mp3","869","869"],
["餐","i-394.mp3","Cān","meal"],
["許多人認為早餐是最重要的一餐","i-395.mp3","xǔduō rén rènwéi zǎocān shì zuì zhòngyào de yī cān","Many people believe that breakfast is the most important meal"],
["我非常期待今天的烤肉大餐","i-396.mp3","wǒ fēicháng qídài jīntiān de kǎoròu dà cān","I am very much looking forward to today's barbecue dinner"],
["870.","i-400.mp3","870","870"],
["米","i-401.mp3","Mǐ","rice"],
["我特別喜歡台東的米，又香又軟","i-402.mp3","wǒ tèbié xǐhuān táidōng de mǐ, yòu xiāng yòu ruǎn","I especially like the rice in Taitung, it is fragrant and soft"],
["你洗米了嗎?米一定要洗乾淨","i-403.mp3","nǐ xǐmǐle ma? Mǐ yīdìng yào xǐ qiánjìng","Have you washed the rice? The rice must be washed clean"],
["871.","i-407.mp3","871","871"],
["點心","i-408.mp3","Diǎnxīn","dessert"],
["這家店只賣點心，沒有賣飲料","i-409.mp3","zhè jiā diàn zhǐ màidiǎnxīn, méiyǒu mài yǐnliào","This shop only sells snacks, no drinks"],
["小美和朋友想到百貨公司買帽子，可是最後只買了點心就離開了","i-410.mp3","xiǎo měihé péngyǒu xiǎngdào bǎihuò gōngsī mǎi màozi, kěshì zuìhòu zhǐ mǎile diǎn xīn jiù líkāile","Xiaomei and her friends wanted to buy hats in a department store, but in the end they only bought some snacks and left"],
["872.","i-414.mp3","872","872"],
["牛排","i-415.mp3","Niúpái","steak"],
["A:請問準備好點餐了嗎?","i-416.mp3","A: Qǐngwèn zhǔnbèi hǎo diǎn cānle ma?","A: Are you ready to order?"],
["B:好了，我要一份牛排，一杯中杯冰紅茶","i-417.mp3","B: Hǎole, wǒ yào yī fèn niúpái, yībēi zhōng bēi bīng hóngchá","B: OK, I'd like a steak and a medium glass of iced black tea"],
["873.","i-421.mp3","873","873"],
["漢堡","i-422.mp3","Hànbǎo","Hamburger"],
["店員: 您好，現在買兩個漢堡，就送牛奶，請問您要點什麼呢? 女","i-423.mp3","diànyuán: Nín hǎo, xiànzài mǎi liǎng gè hànbǎo, jiù sòng niúnǎi, qǐngwèn nín yàodiǎn shénme ne? Nǚ","Clerk: Hello, buy two hamburgers now and deliver milk, what would you like to order? Female"],
["客人:我要兩個漢堡，還有，我要冰牛奶喔，謝謝!","i-424.mp3","kèrén: Wǒ yào liǎng gè hànbǎo, hái yǒu, wǒ yào bīng niúnǎi ō, xièxiè!","Guest: I want two hamburgers, and I want iced milk, thank you!"],
["874.","i-428.mp3","874","874"],
["三明治","i-429.mp3","Sānmíngzhì","sandwich"],
["店員: 您好，現在買兩個三明治，就送紅茶，請問您要點什麼呢?","i-430.mp3","diànyuán: Nín hǎo, xiànzài mǎi liǎng gè sānmíngzhì, jiù sòng hóngchá, qǐngwèn nín yàodiǎn shénme ne?","Clerk: Hello, if you buy two sandwiches, you will get free black tea. What would you like?"],
["女客人:我要兩個三明治，還有，我要冰紅茶喔，謝謝!","i-431.mp3","Nǚ kèrén: Wǒ yào liǎng gè sānmíngzhì, hái yǒu, wǒ yào bīng hóngchá ō, xièxiè!","Female guest: I want two sandwiches, and I want iced tea, thank you!"],
["875.","i-435.mp3","875","875"],
["青菜","i-436.mp3","Qīngcài","Green vegetables"],
["根據報告，青菜對身體非常好","i-437.mp3","gēnjù bàogào, qīngcài duì shēntǐ fēicháng hǎo","According to the report, green vegetables are very good for the body"],
["小明只吃肉，不吃青菜","i-438.mp3","xiǎomíng zhǐ chī ròu, bù chī qīngcài","Xiao Ming only eats meat, not vegetables"],
["876.","i-442.mp3","876","876"],
["冰淇淋","i-443.mp3","Bīngqílín","ice cream"],
["店員: 您好，現在買冰淇淋，就送咖啡，請問您要點什麼 呢?","i-444.mp3","diànyuán: Nín hǎo, xiànzài mǎi bīngqílín, jiù sòng kāfēi, qǐngwèn nín yàodiǎn shénme ne?","Clerk: Hello, if you buy ice cream now, you will get free coffee. What would you like?"],
["女客人:那我要巧克力冰淇淋，還有，熱咖啡，謝謝!","i-445.mp3","Nǚ kèrén: Nà wǒ yào qiǎokèlì bīngqílín, hái yǒu, rè kāfēi, xièxiè!","Female guest: Then I want chocolate ice cream, and hot coffee, thank you!"],
["877.","i-449.mp3","877","877"],
["餅乾","i-450.mp3","Bǐngqián","biscuit"],
["那個短頭髮，正在吃著餅乾的男生就是我弟弟","i-451.mp3","nàgè duǎn tóufà, zhèngzài chīzhe bǐngqián de nánshēng jiùshì wǒ dìdì","The guy with the short hair eating the biscuit is my brother"],
["小天跟朋友買完餅乾以後，就離開了","i-452.mp3","xiǎo tiān gēn péngyǒu mǎi wán bǐngqián yǐhòu, jiù líkāile","Xiaotian left after buying biscuits with his friends"],
["878.","i-456.mp3","878","878"],
["西瓜","i-457.mp3","Xīguā","watermelon"],
["你喝過西瓜牛奶嗎?","i-458.mp3","nǐ hēguò xīguā niúnǎi ma?","Have you ever drank watermelon milk?"],
["爸爸早上去市場買了一顆西瓜，還有一些青菜","i-459.mp3","Bàba zǎoshang qù shìchǎng mǎile yī kē xīguā, hái yǒu yīxiē qīngcài","Dad went to the market in the morning and bought a watermelon and some vegetables"],
["879.","i-463.mp3","879","879"],
["開水","i-464.mp3","Kāishuǐ","boiling water"],
["媽 我要吃藥了，可以幫我倒溫開水嗎?","i-465.mp3","mā wǒ yào chī yàole, kěyǐ bāng wǒ dào wēn kāishuǐ ma?","Mom, I'm going to take medicine, can you pour warm water for me?"],
["這是開水可以喝","i-466.mp3","Zhè shì kāishuǐ kěyǐ hē","This is boiled water to drink"],
["880.","i-470.mp3","880","880"],
["紅茶","i-471.mp3","Hóngchá","black tea"],
["聽說日月潭的紅茶很香也很好喝","i-472.mp3","tīng shuō rìyuètán de hóngchá hěn xiāng yě hěn hǎo hē","I heard that the black tea from Sun Moon Lake is very fragrant and delicious"],
["這個旅行公司的網站上寫，現在三個人一起來，一個人便宜，一千元，再送好喝的紅茶","i-473.mp3","zhège lǚxíng gōngsī de wǎngzhàn shàng xiě, xiànzài sān gèrén yīqǐlái, yīgèrén piányí, yīqiān yuán, zàisòng hǎo hē de hóngchá","The website of this travel company states that now three people come together, one person is cheaper, 1,000 yuan, and free delicious black tea"],
["881.","i-477.mp3","881","881"],
["啤酒","i-478.mp3","Píjiǔ","beer"],
["你喝過台灣啤酒嗎?","i-479.mp3","nǐ hēguò táiwān píjiǔ ma?","Have you ever drank Taiwanese beer?"],
["店員說現在買六瓶啤酒，便宜三百塊，再送一個小禮物","i-480.mp3","Diànyuán shuō xiànzài mǎi liù píng píjiǔ, piányí sānbǎi kuài, zàisòng yīgè xiǎo lǐwù","The clerk said that if you buy six bottles of beer now, it will be 300 yuan cheaper, and you will get a small gift"],
["882.","i-484.mp3","882","882"],
["鹽","i-485.mp3","Yán","Salt"],
["這個菜沒什麼味道，你是不是忘記加鹽了","i-486.mp3","zhège cài méi shénme wèidào, nǐ shì bùshì wàngjì jiā yánle","This dish has no taste, did you forget to add salt?"],
["廚師忘記加鹽巴了","i-487.mp3","chúshī wàngjì jiā yánbāle","The chef forgot to add salt"],
["883.","i-491.mp3","883","883"],
["糖","i-492.mp3","Táng","sugar"],
["店員:您的飲料要無糖還是半糖?","i-493.mp3","diànyuán: Nín de yǐnliào yào wú táng háishì bàn táng?","Clerk: Would you like your drink to be sugar-free or half-sugar?"],
["客人:半糖，謝謝","i-494.mp3","Kèrén: Bàn táng, xièxiè","Guest: half sugar, thank you"],
["884.","i-498.mp3","884","884"],
["味道","i-499.mp3","Wèidào","smell"],
["你聞! 有一個奇怪的味道!","i-500.mp3","nǐ wén! Yǒu yīgè qíguài de wèidào!","You smell! There is a strange smell!"],
["好像很多人怕臭豆腐的味道","i-501.mp3","Hǎoxiàng hěnduō rén pà chòu dòufu de wèidào","It seems that many people are afraid of the taste of stinky tofu"],
["885.","i-505.mp3","885","885"],
["鹹","i-506.mp3","Xián","salty"],
["為了健康，爸爸不敢吃太鹹的食物","i-507.mp3","wèile jiànkāng, bàba bù gǎn chī tài xián de shíwù","For the sake of health, Dad dare not eat too salty food"],
["這份報告提到如果吃太鹹，對身體不好","i-508.mp3","zhè fèn bàogào tí dào rúguǒ chī tài xián, duì shēntǐ bù hǎo","This report mentioned that if you eat too much salt, it is not good for your health"],
["886.","i-512.mp3","886","886"],
["辣","i-513.mp3","Là","hot"],
["A: 你敢吃辣嗎？","i-514.mp3","A: Nǐ gǎn chī là ma?","A: Do you dare to eat spicy food?"],
["B: 我不敢吃辣","i-515.mp3","B: Wǒ bù gǎn chī là","B: I dare not eat spicy food"],
["887.","i-519.mp3","887","887"],
["酸","i-520.mp3","Suān","acid"],
["有人說懷孕的女人喜歡吃酸的食物","i-521.mp3","yǒurén shuō huáiyùn de nǚrén xǐhuān chī suān de shíwù","Some people say that pregnant women like to eat sour food"],
["這瓶牛奶壞掉了，味道是酸的","i-522.mp3","zhè píng niúnǎi huài diàole, wèidào shì suān de","This bottle of milk is spoiled and tastes sour"],
["888.","i-526.mp3","888","888"],
["苦","i-527.mp3","Kǔ","bitter"],
["A:苦瓜好苦 我不想吃!","i-528.mp3","A: Kǔguā hǎo kǔ wǒ bùxiǎng chī!","A: Bitter melon is so bitter, I don't want to eat it!"],
["B:快一點把苦瓜吃掉!對你身體好","i-529.mp3","B: Kuài yīdiǎn bǎ kǔguā chī diào! Duì nǐ shēntǐ hǎo","B: Hurry up and eat the bitter melon! It's good for your health"],
["889.","i-533.mp3","889","889"],
["新鮮","i-534.mp3","Xīnxiān","Fresh"],
["昨天經理請我們吃新鮮的海鮮","i-535.mp3","zuótiān jīnglǐ qǐng wǒmen chī xīnxiān dì hǎixiān","Yesterday the manager invited us to eat fresh seafood"],
["爸爸每次都買又新鮮又好吃的水果","i-536.mp3","bàba měi cì dōu mǎi yòu xīnxiān yòu hào chī de shuǐguǒ","Dad always buys fresh and delicious fruits"],
["890.","i-540.mp3","890","890"],
["香","i-541.mp3","Xiāng","fragrant"],
["A:阿姨煮的牛肉麵怎麼樣?","i-542.mp3","A: Āyí zhǔ de niúròu miàn zěnme yàng?","A: How about the beef noodles cooked by Auntie?"],
["B:又香又好吃","i-543.mp3","B: Yòu xiāng yòu hào chī","B: It is fragrant and delicious"],
["891.","i-547.mp3","891","891"],
["點菜","i-548.mp3","Diǎn cài","a la carte"],
["A:準備好要點菜了嗎?","i-549.mp3","A: Zhǔnbèi hǎo yàodiǎn càile ma?","A: Are you ready to order?"],
["B:還沒，我們還在等朋友來","i-550.mp3","B: Hái méi, wǒmen hái zài děng péngyǒu lái","B: Not yet, we are still waiting for our friends to come"],
["892.","i-554.mp3","892","892"],
["請客","i-555.mp3","Qǐngkè","dinner"],
["今天我請客","i-556.mp3","jīntiān wǒ qǐngkè","today my treat"],
["聽說今天老闆打算請客","i-557.mp3","tīng shuō jīntiān lǎobǎn dǎsuàn qǐngkè","I heard that the boss is going to have a treat today"],
["893.","i-561.mp3","893","893"],
["嚐","i-562.mp3","Cháng","taste"],
["要不要嚐一口?","i-563.mp3","yào bùyào cháng yīkǒu?","Would you like to have a sip?"],
["觀眾也想嚐一口","i-564.mp3","Guānzhòng yě xiǎng cháng yīkǒu","The audience also wants to have a taste"],
["894.","i-568.mp3","894","894"],
["吃飽","i-569.mp3","Chī bǎo","satiated"],
["A:吃飽了嗎?","i-570.mp3","A: Chī bǎole ma?","A: Are you full?"],
["B:很飽，已經吃不下了","i-571.mp3","B: Hěn bǎo, yǐjīng chī bùxiàle","B: I'm so full, I can't eat anymore"],
["895.","i-575.mp3","895","895"],
["煮","i-576.mp3","Zhǔ","cook"],
["A:媽!今天晚上你要煮什麼？","i-577.mp3","A: Mā! Jīntiān wǎnshàng nǐ yào zhǔ shénme?","A: Mom! What are you cooking tonight?"],
["B:煮牛肉麵喔!","i-578.mp3","B: Zhǔ niúròu miàn ō!","B: Cook beef noodles!"],
["896.","i-582.mp3","896","896"],
["烤","i-583.mp3","Kǎo","bake"],
["這家韓國餐廳只賣烤肉，其他什麼都不賣","i-584.mp3","zhè jiā hánguó cāntīng zhǐ mài kǎoròu, qítā shénme dōu bù mài","This Korean restaurant only sells bulgogi and nothing else"],
["你吃過北京烤鴨嗎？","i-585.mp3","nǐ chīguò běijīng kǎoyā ma?","Have you ever eaten Peking duck?"],
["897.","i-589.mp3","897","897"],
["刀子","i-590.mp3","Dāozi","knife"],
["老闆叫我們把刀子洗一洗","i-591.mp3","lǎobǎn jiào wǒmen bǎ dāozi xǐ yī xǐ","The boss told us to wash the knife"],
["洗完刀子後，再把盤子洗一洗","i-592.mp3","xǐ wán dāozi hòu, zài bǎ pánzi xǐ yī xǐ","After washing the knives, wash the dishes"],
["898.","i-596.mp3","898","898"],
["叉子","i-597.mp3","Chāzi","fork"],
["老闆叫我們把叉子洗一洗","i-598.mp3","lǎobǎn jiào wǒmen bǎ chāzi xǐ yī xǐ","The boss told us to wash the forks"],
["洗完叉子後，再把客人用過的盤子洗一洗","i-599.mp3","xǐ wán chāzi hòu, zài bǎ kèrén yòngguò de pánzi xǐ yī xǐ","After washing the forks, wash the dishes used by the guests"],
["899.","i-603.mp3","899","899"],
["湯匙","i-604.mp3","Tāngchí","tablespoon"],
["小天不喜歡用湯匙喝湯","i-605.mp3","xiǎo tiān bù xǐhuān yòng tāngchí hē tāng","Xiaotian doesn't like to drink soup with a spoon"],
["經理說洗完這些湯匙後，還要把湯匙都擦乾淨","i-606.mp3","jīnglǐ shuō xǐ wán zhèxiē tāngchí hòu, hái yào bǎ tāngchí dōu cā qiánjìng","The manager said that after washing these spoons, they should be wiped clean"],
["900.","i-610.mp3","900","900"],
["筷子","i-611.mp3","Kuàizi","Chopsticks"],
["有一些外國人不會用筷子吃飯，還是習慣用叉子","i-612.mp3","yǒu yīxiē wàiguó rén bù huì yòng kuàizi chīfàn, háishì xíguàn yòng chāzi","Some foreigners don't know how to eat with chopsticks, but they are used to using forks"],
["爸爸正在教妹妹用筷子吃飯","i-613.mp3","bàba zhèngzài jiào mèimei yòng kuàizi chīfàn","Dad is teaching sister to eat with chopsticks"],
["901.","i-617.mp3","901","901"],
["碗","i-618.mp3","Wǎn","bowl"],
["我想再吃ㄧ碗飯!","i-619.mp3","wǒ xiǎng zài chīyi1 wǎn fàn!","I want to eat another bowl of rice!"],
["媽媽叫弟弟吃完飯要把碗洗一洗","i-620.mp3","Māmā jiào dìdì chī wán fàn yào bǎ wǎn xǐ yī xǐ","Mom told my brother to wash the dishes after eating"],
["902.","i-624.mp3","902","902"],
["盤","i-625.mp3","Pán","plate"],
["A: 又來了! 把你的碗盤洗一洗啦!","i-626.mp3","A: Yòu láile! Bǎ nǐ de wǎn pán xǐ yī xǐ la!","A: Here we go again! Wash your dishes!"],
["B: 才一個盤子! 好啦，我還在吃水果，等一下我就去洗啦","i-627.mp3","B: Cái yīgè pánzi! Hǎo la, wǒ hái zài chī shuǐguǒ, děng yīxià wǒ jiù qù xǐ la","B: It's only one plate! Well, I'm still eating fruit, I'll wash it later"],
["903.","i-631.mp3","903","903"],
["旅遊","i-632.mp3","Lǚyóu","travel"],
["現在是旅遊季節，到處都是人","i-633.mp3","xiànzài shì lǚyóu jìjié, dàochù dōu shì rén","It's tourist season and it's full of people"],
["張先生是這家旅遊公司的經理","i-634.mp3","zhāng xiānshēng shì zhè jiā lǚyóu gōngsī de jīnglǐ","Mr. Zhang is the manager of this travel company"],
["904.","i-638.mp3","904","904"],
["護照","i-639.mp3","Hùzhào","passport"],
["護照在書包旁邊","i-640.mp3","hùzhào zài shūbāo pángbiān","Passport next to school bag"],
["天明到了機場後，才發現他忘記帶護照了","i-641.mp3","tiānmíng dàole jīchǎng hòu, cái fāxiàn tā wàngjì dài hùzhàole","When Tianming arrived at the airport, he found out that he forgot to bring his passport"],
["905.","i-645.mp3","905","905"],
["行李","i-646.mp3","Xínglǐ","luggage"],
["王先生說他只有一件行李","i-647.mp3","wáng xiānshēng shuō tā zhǐyǒu yī jiàn xínglǐ","Mr. Wang said he only has one piece of luggage"],
["李小姐十一點才到旅館，有好幾件行李","i-648.mp3","lǐ xiǎojiě shíyīdiǎn cái dào lǚguǎn, yǒu hǎojǐ jiàn xínglǐ","Ms. Li didn't arrive at the hotel until eleven o'clock, and she had several pieces of luggage"],
["906.","i-652.mp3","906","906"],
["博物館","i-653.mp3","Bówùguǎn","museum"],
["A:請問博物館怎麼走?","i-654.mp3","A: Qǐngwèn bówùguǎn zěnme zǒu?","A: How can I get to the museum?"],
["B:先右轉，一直往前走，走十五分鐘就到了","i-655.mp3","B: Xiān yòu zhuǎn, yīzhí wǎng qián zǒu, zǒu shíwǔ fēnzhōng jiù dàole","B: Turn right first, go straight ahead, and you will be there in fifteen minutes"],
["907.","i-659.mp3","907","907"],
["動物園","i-660.mp3","Dòngwùyuán","zoo"],
["A:你知道什麼時候去動物園比較便宜嗎?","i-661.mp3","A: Nǐ zhīdào shénme shíhòu qù dòngwùyuán bǐjiào piányí ma?","A: Do you know when it is cheaper to go to the zoo?"],
["B:這張紙上寫著星期一到星期五的白天來比較便宜","i-662.mp3","B: Zhè zhāng zhǐshàng xiězhe xīngqí yī dào xīngqíwǔ de báitiān lái bǐjiào piányí","B: This piece of paper says that it is cheaper to come during the daytime from Monday to Friday"],
["908.","i-666.mp3","908","908"],
["花園","i-667.mp3","Huāyuán","garden"],
["爸爸喜歡白天的時候來這個花園散步","i-668.mp3","bàba xǐhuān báitiān de shíhòu lái zhège huāyuán sànbù","Dad likes to come to this garden for a walk during the day"],
["弟弟正在追著他的朋友，兩人在花園裡玩得很開心","i-669.mp3","dìdì zhèngzài zhuīzhe tā de péngyǒu, liǎng rén zài huāyuán lǐ wán dé hěn kāixīn","The younger brother is chasing his friend, the two are having fun in the garden"],
["909.","i-673.mp3","909","909"],
["中心","i-674.mp3","Zhōngxīn","center"],
["七樓是語言中心","i-675.mp3","qī lóu shì yǔyán zhōngxīn","The seventh floor is the language center"],
["請問華語中心在幾樓？","i-676.mp3","qǐngwèn huáyǔ zhòng xīn zài jǐ lóu?","What floor is the Chinese Language Center?"],
["910.","i-680.mp3","910","910"],
["爬","i-681.mp3","Pá","climb"],
["希望有一天我可以去爬玉山","i-682.mp3","xīwàng yǒu yītiān wǒ kěyǐ qù pá yùshān","I hope one day I can climb Yushan"],
["因為媽媽怕高，所以沒和爸爸一起爬過山","i-683.mp3","yīnwèi māmā pà gāo, suǒyǐ méi hé bàba yīqǐ páguò shān","Because my mother is afraid of heights, I didn't climb the mountain with my father"],
["911.","i-687.mp3","911","911"],
["跳","i-688.mp3","Tiào","Jump"],
["小男孩他不想再學跳舞了","i-689.mp3","xiǎo nánhái tā bùxiǎng zài xué tiàowǔle","Little boy he doesn't want to learn to dance anymore"],
["雖然小天很害羞，但是還是問他喜歡的女生要不要跳舞","i-690.mp3","suīrán xiǎo tiān hěn hàixiū, dànshì háishì wèn tā xǐhuān de nǚshēng yào bùyào tiàowǔ","Although Xiaotian is very shy, he still asks the girl he likes if he wants to dance"],
["912.","i-694.mp3","912","912"],
["唱","i-695.mp3","Chàng","Sing"],
["A: 我們去唱歌，好嗎?","i-696.mp3","A: Wǒmen qù chànggē, hǎo ma?","A: Let's go sing, shall we?"],
["B: 我牙齒好痛，我不想去","i-697.mp3","B: Wǒ yáchǐ hǎo tòng, wǒ bùxiǎng qù","B: My tooth hurts so much, I don’t want to go"],
["913.","j-01.mp3","913","913"],
["歌","j-02.mp3","Gē","Song"],
["好多小鳥在樹上唱歌","j-03.mp3","hǎoduō xiǎoniǎo zài shù shàng chànggē","many birds singing on the tree"],
["我平常喜歡一邊洗澡，一邊唱歌","j-04.mp3","wǒ píngcháng xǐhuān yībiān xǐzǎo, yībiān chànggē","I usually like to take a shower while singing"],
["914.","j-08.mp3","914","914"],
["拉","j-09.mp3","Lā","pull"],
["你會拉小提琴嗎？","j-10.mp3","nǐ huì lā xiǎotíqín ma?","Can you play the violin?"],
["雖然拉小提琴很難，大明還是不放棄","j-11.mp3","Suīrán lā xiǎotíqín hěn nán, dàmíng háishì bù fàngqì","Although it is difficult to play the violin, Daming still does not give up"],
["915.","j-15.mp3","915","915"],
["樂器","j-16.mp3","Yuèqì","musical instrument"],
["你會用這些樂器嗎?","j-17.mp3","nǐ huì yòng zhèxiē yuèqì ma?","Do you know how to use these instruments?"],
["雖然這些樂器都是新的，但是很久沒有人用了","j-18.mp3","Suīrán zhèxiē yuèqì dōu shì xīn de, dànshì hěnjiǔ méiyǒu rén yòngle","Although these instruments are new, no one has used them for a long time"],
["916.","j-22.mp3","916","916"],
["吉他","j-23.mp3","Jítā","Guitar"],
["A: 為什麼我買給你的吉他，你一次都沒有彈過?","j-24.mp3","A: Wèishéme wǒ mǎi gěi nǐ de jítā, nǐ yīcì dōu méiyǒu dànguò?","A: Why have you never played the guitar I bought for you?"],
["B: 最近比較忙，考試完後我就可以彈吉他了","j-25.mp3","B: Zuìjìn bǐjiào máng, kǎoshì wán hòu wǒ jiù kěyǐ dàn jítāle","B: I'm busy recently, I can play the guitar after the exam"],
["917.","j-29.mp3","917","917"],
["鋼琴","j-30.mp3","Gāngqín","piano"],
["A: 樓上的人又在彈鋼琴! 這麼晚還在彈了!","j-31.mp3","A: Lóu shàng de rén yòu zài dàn gāngqín! Zhème wǎn hái zài dànle!","A: The people upstairs are playing the piano again! Still playing it so late!"],
["B: 對啊! 那個鄰居經常晚上彈鋼琴，吵得不得了，我上次已經. 找警察幫忙了，他們去跟他談，可是一點用也沒有","j-32.mp3","B: Duì a! Nàgè línjū jīngcháng wǎnshàng dàn gāngqín, chǎo dé bùdéle, wǒ shàng cì yǐjīng. Zhǎojǐngchá bāngmángle, tāmen qù gēn tā tán, kěshì yīdiǎn yòng yě méiyǒu","B: That’s right! That neighbor often plays the piano at night and makes a lot of noise. I already asked the police for help last time. They went to talk to him, but it didn’t help at all."],
["918.","j-36.mp3","918","918"],
["跳舞","j-37.mp3","Tiàowǔ","Dance"],
["很多年輕人喜歡去夜店跳舞","j-38.mp3","hěnduō niánqīng rén xǐhuān qù yèdiàn tiàowǔ","Many young people like to go to nightclubs to dance"],
["因為小華害羞，所以沒和朋友去夜店跳過舞","j-39.mp3","yīnwèi xiǎo huá hàixiū, suǒyǐ méi hé péngyǒu qù yèdiàn tiàoguò wǔ","Because Xiaohua is shy, she didn't go to the nightclub to dance with her friends"],
["919.","j-43.mp3","919","919"],
["小說","j-44.mp3","Xiǎoshuō","novel"],
["A: 不好意思，我想找這本小說. B: 好，我看一下! 這本小說已經被借走了喔!","j-45.mp3","A: Bù hǎoyìsi, wǒ xiǎng zhǎo zhè běn xiǎoshuō. B: Hǎo, wǒ kàn yīxià! Zhè běn xiǎoshuō yǐjīng bèi jiè zǒule ō!","A: Excuse me, I want to find this novel. B: OK, let me take a look! This novel has been borrowed!"],
["A: 啊 那怎麼辦，因為這本小說其實是暑假作業，請問要等到什麼時候呢?","j-46.mp3","A: A nà zěnme bàn, yīnwèi zhè běn xiǎoshuō qíshí shì shǔjià zuòyè, qǐngwèn yào děngdào shénme shíhòu ne?","A: Ah, so what should I do, because this novel is actually a summer homework, when will I have to wait?"],
["920.","j-50.mp3","920","920"],
["故事","j-51.mp3","Gùshì","story"],
["看完這個故事，作者想要告訴我們什麼事?","j-52.mp3","kàn wán zhège gùshì, zuòzhě xiǎng yào gàosù wǒmen shénme shì?","After reading this story, what does the author want to tell us?"],
["這個故事告訴了我們什麼事？","j-53.mp3","Zhège gùshì gàosùle wǒmen shénme shì?","What does this story tell us?"],
["921.","j-57.mp3","921","921"],
["價格","j-58.mp3","Jiàgé","price"],
["客人習慣先看價格再決定要不要買","j-59.mp3","kèrén xíguàn xiān kàn jiàgé zài juédìng yào bù yāo mǎi","Customers are accustomed to look at the price before deciding whether to buy"],
["如果早上十一點以前去KTV唱歌，價格會比較便宜","j-60.mp3","rúguǒ zǎoshang shíyīdiǎn yǐqián qù KTV chànggē, jiàgé huì bǐjiào piányí","If you go to KTV to sing before 11 o'clock in the morning, the price will be cheaper"],
["922.","j-64.mp3","922","922"],
["價錢","j-65.mp3","Jiàqián","Price"],
["牌子上寫著晚上去KTV唱歌，價錢比較貴","j-66.mp3","páizi shàng xiězhe wǎnshàng qù KTV chànggē, jiàqián bǐjiào guì","The sign says to go to KTV to sing at night, the price is more expensive"],
["早上學游泳的價錢比較便宜","j-67.mp3","zǎoshang xué yóuyǒng de jiàqián bǐjiào piányí","The price of learning to swim in the morning is relatively cheap"],
["923.","j-71.mp3","923","923"],
["付","j-72.mp3","Fù","pay"],
["我要付多少錢?","j-73.mp3","wǒ yào fù duōshǎo qián?","How much do I need to pay?"],
["客人只買了帽子，付了錢以後就離開了","j-74.mp3","Kèrén zhǐ mǎile màozi, fùle qián yǐhòu jiù líkāile","The customer only bought the hat, paid the money and left"],
["924.","j-78.mp3","924","924"],
["毛","j-79.mp3","Máo","hair"],
["一毛錢","j-80.mp3","yī máo qián","one cent"],
["我一毛錢都不想付","j-81.mp3","wǒ yī máo qián dōu bùxiǎng fù","I don't want to pay a dime"],
["925.","j-85.mp3","925","925"],
["信用卡","j-86.mp3","Xìnyòngkǎ","credit card"],
["我可以用信用卡付錢嗎?","j-87.mp3","wǒ kěyǐ yòng xìnyòngkǎ fù qián ma?","Can I pay by credit card?"],
["這家店規定客人只能用信用卡付錢","j-88.mp3","Zhè jiā diàn guīdìng kèrén zhǐ néng yòng xìnyòngkǎ fù qián","This store stipulates that customers can only pay by credit card"],
["926.","j-92.mp3","926","926"],
["超級市場","j-93.mp3","Chāojí shìchǎng","supermarket"],
["請問這附近有超市嗎?","j-94.mp3","qǐngwèn zhè fùjìn yǒu chāoshì ma?","Is there a supermarket near here?"],
["雖然我家離這間超級市場很近，但是我一次都沒去過","j-95.mp3","Suīrán wǒjiā lí zhè jiān chāojí shìchǎng hěn jìn, dànshì wǒ yīcì dōu méi qùguò","Although my home is very close to this supermarket, I have never been to it once"],
["927.","j-99.mp3","927","927"],
["袋子","j-100.mp3","Dàizi","bag"],
["這個袋子又輕又漂亮，但是李太太一次都沒有用過","j-101.mp3","zhège dàizi yòu qīng yòu piàoliang, dànshì lǐ tàitài yīcì dōu méiyǒu yòngguò","The bag is light and beautiful, but Mrs. Lee has never used it once"],
["買東西的時候一定要記得自己帶袋子","j-102.mp3","mǎi dōngxī de shíhòu yīdìng yào jìdé zìjǐ dài dàizi","Remember to bring your own bag when shopping"],
["928.","j-106.mp3","928","928"],
["交通","j-107.mp3","Jiāotōng","transportation"],
["台北的交通非常方便","j-108.mp3","táiběi de jiāotōng fēicháng fāngbiàn","The traffic in Taipei is very convenient"],
["通常大城市的交通都很方便","j-109.mp3","tōngcháng dà chéngshì de jiāotōng dū hěn fāngbiàn","Usually the traffic in big cities is very convenient"],
["929.","j-113.mp3","929","929"],
["汽車","j-114.mp3","Qìchē","car"],
["你知道汽車是誰發明的嗎?","j-115.mp3","nǐ zhīdào qìchē shì shuí fāmíng de ma?","Do you know who invented the car?"],
["汽車，口語比較少說","j-116.mp3","Qìchē, kǒuyǔ bǐjiào shǎo shuō","Cars, less spoken"],
["930.","j-120.mp3","930","930"],
["輛","j-121.mp3","Liàng","vehicle"],
["好幾輛車停在學校門口","j-122.mp3","hǎo jǐ liàng chē tíng zài xuéxiào ménkǒu","Several cars parked in front of the school"],
["這輛車一直停在醫院門口，其他車都不能進去","j-123.mp3","zhè liàng chē yīzhí tíng zài yīyuàn ménkǒu, qítā chē dōu bùnéng jìnqù","This car has been parked in front of the hospital, and no other cars can enter."],
["931.","j-127.mp3","931","931"],
["船","j-128.mp3","Chuán","Boat"],
["妹妹從來沒有坐過船，她非常興奮 !","j-129.mp3","mèimei cónglái méiyǒu zuòguò chuán, tā fēicháng xīngfèn!","My sister has never been on a boat and she is so excited !"],
["坐船讓小張覺得很不舒服","j-130.mp3","Zuò chuán ràng xiǎozhāng juédé hěn bú shūfú","Taking the boat made Xiao Zhang feel very uncomfortable"],
["932.","j-134.mp3","932","932"],
["搭","j-135.mp3","Dā","take"],
["A:不好意思，我要到機場，請問應該怎麼去? B:你可以搭公車到機場站，很快就到了","j-136.mp3","A: Bù hǎoyìsi, wǒ yào dào jīchǎng, qǐngwèn yīnggāi zěnme qù? B: Nǐ kěyǐ dā gōngchē dào jīchǎng zhàn, hěn kuài jiù dàole","A: Excuse me, I want to go to the airport, how can I get there? B: You can take the bus to the airport station, and you will be there soon"],
["A:那請問要搭幾號? B:可以搭二六二，公車站就在前面!","j-137.mp3","A: Nà qǐngwèn yào dā jǐ hào? B: Kěyǐ dā èrliù'èr, gōngchē zhàn jiù zài qiánmiàn!","A: Then, what number do you want to take? B: You can take 262. The bus stop is right in front!"],
["933.","j-141.mp3","933","933"],
["起飛","j-142.mp3","Qǐfēi","take off"],
["每次飛機起飛前，妹妹就非常緊張","j-143.mp3","měi cì fēijī qǐfēi qián, mèimei jiù fēicháng jǐnzhāng","Every time before the plane takes off, my sister is very nervous"],
["飛機起飛前打電話給我","j-144.mp3","fēijī qǐfēi qián dǎ diànhuà gěi wǒ","call me before the plane takes off"],
["934.","j-148.mp3","934","934"],
["停車場","j-149.mp3","Tíngchēchǎng","PARKING LOT"],
["停車場都滿了","j-150.mp3","tíngchēchǎng dōu mǎnle","parking lot is full"],
["現在停車場沒有位子，沒有一輛車可以進去","j-151.mp3","xiànzài tíngchēchǎng méiyǒu wèizi, méiyǒu yī liàng chē kěyǐ jìnqù","There are no seats in the parking lot now, no car can go in"],
["935.","j-155.mp3","935","935"],
["路口","j-156.mp3","Lùkǒu","intersection"],
["往前走看到十字路口，左轉就到火車站了","j-157.mp3","wǎng qián zǒu kàn dào shízìlùkǒu, zuǒ zhuǎn jiù dào huǒchē zhàn le","Go forward and see the intersection, turn left and you will arrive at the train station"],
["過了前面的路口，再往前走一會兒就到醫院了","j-158.mp3","guò le qiánmiàn de lù kǒu, zài wǎng qián zǒu yīhuǐ'er jiù dào yīyuànle","After passing the intersection in front, go forward for a while and you will arrive at the hospital"],
["936.","j-162.mp3","936","936"],
["紅綠燈","j-163.mp3","Hónglǜdēng","traffic light"],
["看到前面的紅綠燈了嗎?","j-164.mp3","kàn dào qiánmiàn de hónglǜdēngle ma?","See the traffic lights ahead?"],
["過了前面的紅綠燈，再右轉就是醫院了","j-165.mp3","Guò le qiánmiàn de hónglǜdēng, zài yòu zhuǎn jiùshì yīyuànle","After passing the traffic lights ahead, turn right and you will see the hospital."],
["937.","j-169.mp3","937","937"],
["地球","j-170.mp3","Dìqiú","Earth"],
["老師在黑板上寫著保護地球是每個人的責任","j-171.mp3","lǎoshī zài hēibǎn shàng xiězhe bǎohù dìqiú shì měi gèrén de zérèn","The teacher wrote on the blackboard that protecting the earth is everyone's responsibility"],
["你知道地球上住了多少人嗎?","j-172.mp3","nǐ zhīdào dìqiú shàng zhùle duōshǎo rén ma?","Do you know how many people live on earth?"],
["938.","j-176.mp3","938","938"],
["月亮","j-177.mp3","Yuèliàng","moon"],
["故事書上寫月亮上住著一隻兔子","j-178.mp3","gùshì shū shàng xiě yuèliàng shàng zhùzhe yī zhī tùzǐ","In the storybook, there lived a rabbit on the moon"],
["中秋節的時候我們全家喜歡一邊烤肉，一邊看月亮","j-179.mp3","zhōngqiū jié de shíhòu wǒmen quánjiā xǐhuān yībiān kǎoròu, yībiān kàn yuèliàng","During the Mid-Autumn Festival, our family likes to barbecue while watching the moon"],
["939.","j-183.mp3","939","939"],
["星星","j-184.mp3","Xīngxīng","Star"],
["今天晚上的星星好美","j-185.mp3","jīntiān wǎnshàng de xīngxīng hǎoměi","The stars are so beautiful tonight"],
["我最喜歡躺在草地上看星星","j-186.mp3","wǒ zuì xǐhuān tǎng zài cǎodì shàng kàn xīngxīng","I like lying on the grass and looking at the stars the most"],
["940.","j-190.mp3","940","940"],
["雲","j-191.mp3","Yún","cloud"],
["大安森林公園裡，好多人躺在草地上看雲","j-192.mp3","dà'ān sēnlín gōngyuán lǐ, hǎoduō rén tǎng zài cǎodì shàng kàn yún","In Daan Forest Park, many people lie on the grass and look at the clouds"],
["今天太陽很大，沒有雲","j-193.mp3","jīntiān tàiyáng hěn dà, méiyǒu yún","Today the sun is bright and there are no clouds"],
["941.","j-197.mp3","941","941"],
["地","j-198.mp3","De","land"],
["天氣好的時候，我跟朋友常常躺在草地上看星星","j-199.mp3","tiānqì hǎo de shíhòu, wǒ gēn péngyǒu chángcháng tǎng zài cǎodì shàng kàn xīngxīng","When the weather is good, my friends and I often lie on the grass and look at the stars"],
["李先生在南部買了一塊地","j-200.mp3","lǐ xiānshēng zài nánbù mǎile yīkuài dì","Mr. Li bought a piece of land in the south"],
["942.","j-204.mp3","942","942"],
["森林","j-205.mp3","Sēnlín","forest"],
["你去過台北的大安森林公園嗎?","j-206.mp3","nǐ qùguò táiběi de dà'ān sēnlín gōngyuán ma?","Have you ever been to Daan Forest Park in Taipei?"],
["每個禮拜天我們全家都會去大安森林公園騎腳踏車","j-207.mp3","Měi gè lǐbài tiān wǒmen quánjiā dūhuì qù dà'ān sēnlín gōngyuán qí jiǎotàchē","Every Sunday our family will go to Daan Forest Park to ride bicycles"],
["943.","j-211.mp3","943","943"],
["草","j-212.mp3","Cǎo","Grass"],
["這邊有好多小草，小心蟲子!","j-213.mp3","zhè biān yǒu hǎoduō xiǎo cǎo, xiǎoxīn chóngzi!","There are a lot of grass here, watch out for bugs!"],
["天氣好的時候，我跟朋友都會在大安森林公園的草地上野餐","j-214.mp3","Tiānqì hǎo de shíhòu, wǒ gēn péngyǒu dūhuì zài dà'ān sēnlín gōngyuán de cǎodì shàng yěcān","When the weather is fine, my friends and I will have a picnic on the grass in Daan Forest Park"],
["944.","j-218.mp3","944","944"],
["火","j-219.mp3","Huǒ","fire"],
["不要玩火! 很危險","j-220.mp3","bùyào wán huǒ! Hěn wéixiǎn","don't play with fire! it's dangerous"],
["小心!火不要開太大","j-221.mp3","xiǎoxīn! Huǒ bùyào kāi tài dà","Be careful! Don't turn on the fire too much"],
["945.","j-225.mp3","945","945"],
["晴天","j-226.mp3","Qíngtiān","sunny"],
["氣象報告說未來的一個星期都是晴天","j-227.mp3","qìxiàng bàogào shuō wèilái de yīgè xīngqí dōu shìqíngtiān","The weather report says the week ahead will be sunny"],
["氣象報告說明天是晴天，太陽會很大","j-228.mp3","qìxiàng bàogào shuōmíng tiān shì qíngtiān, tàiyáng huì hěn dà","The weather report says it will be sunny and the sun will be very bright"],
["946.","j-232.mp3","946","946"],
["溫度","j-233.mp3","Wēndù","temperature"],
["台北一月的平均溫度大概幾度?","j-234.mp3","táiběi yī yuè de píngjūn wēndù dàgài jǐdù?","What is the average temperature in Taipei in January?"],
["氣象報告說未來的一個星期台北的溫度會很高","j-235.mp3","Qìxiàng bàogào shuō wèilái de yīgè xīngqí táiběi de wēndù huì hěn gāo","The weather report says that the temperature in Taipei will be very high in the coming week"],
["947.","j-239.mp3","947","947"],
["雨","j-240.mp3","Yǔ","rain"],
["今天雨很大，風也很大","j-241.mp3","jīntiān yǔ hěn dà, fēng yě hěn dà","It's raining and windy today"],
["颱風快要來了，現在雨很大","j-242.mp3","táifēng kuàiyào láile, xiànzài yǔ hěn dà","The typhoon is coming soon, and now it is raining heavily"],
["948.","j-246.mp3","948","948"],
["雪","j-247.mp3","Xuě","Snow"],
["冬天如果非常冷，台灣的山上有時候會下雪","j-248.mp3","dōngtiān rúguǒ fēicháng lěng, táiwān de shānshàng yǒu shíhòu huì xià xuě","If it is very cold in winter, sometimes it will snow on the mountains of Taiwan"],
["小美從來沒有看過雪","j-249.mp3","xiǎo měi cónglái méiyǒu kànguò xuě","Mei has never seen snow"],
["949.","j-253.mp3","949","949"],
["空氣","j-254.mp3","Kōngqì","Air"],
["通常山上的空氣比山下好","j-255.mp3","tōngcháng shānshàng de kōngqì bǐ shānxià hǎo","Usually the air is better up the mountain than down the mountain"],
["想要有新鮮的空氣，先把窗戶打開，再把門打開","j-256.mp3","xiǎng yào yǒu xīnxiān de kōngqì, xiān bǎ chuānghù dǎkāi, zài bǎmén dǎkāi","For fresh air, open the windows first, then open the door"],
["950.","j-260.mp3","950","950"],
["環境","j-261.mp3","Huánjìng","environment"],
["這裡的環境很舒服，很安全也很安靜","j-262.mp3","zhèlǐ de huánjìng hěn shūfú, hěn ānquán yě hěn ānjìng","The environment here is comfortable, safe and quiet"],
["A: 人多不好嗎 ? B: 我喜歡安靜的環境","j-263.mp3","A: Rén duō bù hǎo ma? B: Wǒ xǐhuān ānjìng de huánjìng","A: Is there a lot of people? B: I like a quiet environment"],
["951.","j-267.mp3","951","951"],
["動物","j-268.mp3","Dòngwù","animal"],
["你最喜歡什麼動物?","j-269.mp3","nǐ zuì xǐhuān shénme dòngwù?","what is your favorite animal"],
["美心跟她的男朋友約好先在動物園門口見面，再一起進去","j-270.mp3","Měi xīn gēn tā de nán péngyǒu yuē hǎo xiān zài dòngwùyuán ménkǒu jiànmiàn, zài yīqǐ jìnqù","Meixin and her boyfriend made an appointment to meet at the gate of the zoo first, and then go in together"],
["952.","j-274.mp3","952","952"],
["牛","j-275.mp3","Niú","ox"],
["明年是牛年","j-276.mp3","míngnián shì niú nián","next year is the year of the ox"],
["農場裡的牛被照顧得很好","j-277.mp3","nóngchǎng lǐ de niú bèi zhàogù dé hěn hǎo","The cows on the farm are well cared for"],
["953.","j-281.mp3","953","953"],
["虎","j-282.mp3","Hǔ","Tiger"],
["你知道有一首很有名的歌叫兩隻老虎嗎?","j-283.mp3","nǐ zhīdào yǒu yī shǒu hěn yǒumíng de gē jiào liǎng zhī lǎohǔ ma?","Did you know there is a famous song called Two Tigers?"],
["我媽媽屬虎，姊姊也屬虎","j-284.mp3","Wǒ māmā shǔ hǔ, jiě jie yě shǔ hǔ","My mother is a tiger, and my sister is also a tiger"],
["954.","j-288.mp3","954","954"],
["羊","j-289.mp3","Yáng","sheep"],
["羊奶味道比較重，妹妹不敢喝","j-290.mp3","yáng nǎi wèidào bǐjiào zhòng, mèimei bù gǎn hē","Goat milk has a strong taste, my sister dare not drink it"],
["農場裡的羊吃得好，睡得好，被照顧得很好","j-291.mp3","nóngchǎng lǐ de yáng chī dé hǎo, shuì dé hǎo, bèi zhàogù dé hěn hǎo","The sheep on the farm eat well, sleep well and are well cared for"],
["955.","j-295.mp3","955","955"],
["豬肉","j-296.mp3","Zhūròu","pork"],
["張太太不吃豬肉，只吃牛肉","j-297.mp3","zhāng tàitài bù chī zhūròu, zhǐ chī niúròu","Mrs. Zhang doesn't eat pork, only beef"],
["媽媽正在買豬肉","j-298.mp3","māmā zhèngzài mǎi zhūròu","Mom is buying pork"],
["956.","j-302.mp3","956","956"],
["就","j-303.mp3","Jiù","At once"],
["A: 明華到了嗎? B: 他十點就來了","j-304.mp3","A: Míng huá dàole ma? B: Tā shí diǎn jiù láile","A: Has Minghua arrived? B: He will be here at ten o'clock"],
["兩個人一起去，就有紅茶可以喝","j-305.mp3","liǎng gè rén yīqǐ qù, jiù yǒu hóngchá kěyǐ hē","If two people go together, there is black tea to drink"],
["957.","j-309.mp3","957","957"],
["才","j-310.mp3","Cái","talent"],
["他說他兩點到，但是他三點才來","j-311.mp3","tā shuō tā liǎng diǎn dào, dànshì tā sān diǎn cái lái","He said he would be there at two o'clock, but he didn't come until three o'clock"],
["想看電影要中午以後才行","j-312.mp3","xiǎng kàn diànyǐng yào zhōngwǔ yǐhòu cái xíng","I want to watch a movie after noon"],
["958.","j-316.mp3","958","958"],
["馬上","j-317.mp3","Mǎshàng","immediately"],
["我馬上去","j-318.mp3","wǒ mǎshàng qù","I'll go"],
["因為空氣不好，妹妹馬上把窗戶打開","j-319.mp3","yīnwèi kōngqì bù hǎo, mèimei mǎshàng bǎ chuānghù dǎkāi","Because the air is not good, my sister immediately opened the window"],
["959.","j-323.mp3","959","959"],
["當","j-324.mp3","Dāng","when"],
["考試前，當你緊張的時候，就試著放鬆","j-325.mp3","kǎoshì qián, dāng nǐ jǐnzhāng de shíhòu, jiù shìzhe fàngsōng","Before a test, when you're nervous, try to relax"],
["當你難過的時候，你會想做什麼?","j-326.mp3","dāng nǐ nánguò de shíhòu, nǐ huì xiǎng zuò shénme?","When you are sad, what do you want to do?"],
["960.","j-330.mp3","960","960"],
["正在","j-331.mp3","Zhèngzài","is"],
["A:妹妹正在做什麼?","j-332.mp3","A: Mèimei zhèngzài zuò shénme?","A: What is your sister doing?"],
["B:她正在找上課要用的書","j-333.mp3","B: Tā zhèngzài zhǎo shàngkè yào yòng de shū","B: She is looking for books to use in class"],
["961.","j-337.mp3","961","961"],
["剛剛","j-338.mp3","Gānggāng","just"],
["妹妹剛剛收到一張好朋友寄來的明信片","j-339.mp3","mèimei gānggāng shōu dào yī zhāng hǎo péngyǒu jì lái de míngxìnpiàn","My sister just received a postcard from a good friend"],
["我剛剛遇到一位老朋友","j-340.mp3","wǒ gānggāng yù dào yī wèi lǎo péngyǒu","i just met an old friend"],
["962.","j-344.mp3","962","962"],
["剛才","j-345.mp3","Gāngcái","Just now"],
["A: 他剛才不能接電話因為在開會","j-346.mp3","A: Tā gāngcái bùnéng jiē diànhuà yīnwèi zài kāihuì","A: He couldn't answer the phone just now because he was in a meeting"],
["A: 請問十點的火車來了嗎? B: 火車剛才開走了","j-347.mp3","A: Qǐngwèn shí diǎn de huǒchē láile ma? B: Huǒchē gāngcái kāi zǒule","A: Excuse me, is the ten o'clock train coming? B: The train just left"],
["963.","j-351.mp3","963","963"],
["已經","j-352.mp3","Yǐjīng","already"],
["客人已經到公司了","j-353.mp3","kèrén yǐjīng dào gōngsīle","The customer has arrived at the company"],
["小華原本找不到上課要用的書，現在已經找到了","j-354.mp3","xiǎo huáyuán běn zhǎo bù dào shàngkè yào yòng de shū, xiànzài yǐjīng zhǎodàole","Xiaohua couldn't find the books he needed for class, but now he has found them"],
["964.","j-358.mp3","964","964"],
["然後","j-359.mp3","Ránhòu","Then"],
["媽媽說一定要先洗手，然後再吃飯","j-360.mp3","māmā shuō yīdìng yào xiān xǐshǒu, ránhòu zài chīfàn","Mom said you must wash your hands before eating"],
["我們先約在學校門口見面，然後再一起去打籃球，好嗎?","j-361.mp3","wǒmen xiān yuē zài xuéxiào ménkǒu jiànmiàn, ránhòu zài yī qǐ qù dǎ lánqiú, hǎo ma?","Let's make an appointment to meet at the school gate first, and then go play basketball together, shall we?"],
["965.","j-365.mp3","965","965"],
["最","j-366.mp3","Zuì","most"],
["這場棒球比賽是我看過最熱鬧的比賽","j-367.mp3","zhè chǎng bàngqiú bǐsài shì wǒ kànguò zuì rènào de bǐsài","This baseball game is the most hilarious game I've ever seen"],
["志明什麼球都玩，但是最喜歡玩足球","j-368.mp3","zhìmíng shénme qiú dōu wán, dànshì zuì xǐhuān wán zúqiú","Zhiming can play all kinds of balls, but he likes football the most"],
["966.","j-372.mp3","966","966"],
["更","j-373.mp3","Gèng","Even"],
["只要試著做出一些改變，想讓自己更健康一點也不難","j-374.mp3","zhǐyào shìzhe zuò chū yīxiē gǎibiàn, xiǎng ràng zìjǐ gèng jiànkāng yīdiǎn yě bù nán","It's not hard at all to make yourself healthier, just try to make some changes"],
["常關心別人的人更有責任感","j-375.mp3","cháng guānxīn biérén de rén gèng yǒu zérèngǎn","People who care about others are more responsible"],
["967.","j-379.mp3","967","967"],
["還","j-380.mp3","Hái","return"],
["今天還是很冷，比昨天更冷了","j-381.mp3","jīntiān háishì hěn lěng, bǐ zuótiān gèng lěngle","It's still cold today, even colder than yesterday"],
["我的報告還沒有做完","j-382.mp3","wǒ de bàogào hái méiyǒu zuò wán","my report is not done yet"],
["968.","j-386.mp3","968","968"],
["經常","j-387.mp3","Jīngcháng","often"],
["體育老師經常說想讓自己更健康一點也不難","j-388.mp3","tǐyù lǎoshī jīngcháng shuō xiǎng ràng zìjǐ gèng jiànkāng yīdiǎn yě bù nán","Physical education teachers often say that it is not difficult at all to make yourself healthier"],
["為了讓客人滿意，公司經常做出一些改變","j-389.mp3","wèi le ràng kèrén mǎnyì, gōngsī jīngcháng zuò chū yīxiē gǎibiàn","Companies often make changes to keep customers happy"],
["969.","j-393.mp3","969","969"],
["平常","j-394.mp3","Píngcháng","usually"],
["A:爸爸平常喜歡做什麼?","j-395.mp3","A: Bàba píngcháng xǐhuān zuò shénme?","A: What does Dad usually like to do?"],
["B:他平常喜晚上去公園走一走","j-396.mp3","B: Tā píngcháng xǐ wǎnshàng qù gōngyuán zǒu yī zǒu","B: He usually likes to go for a walk in the park at night"],
["970.","j-400.mp3","970","970"],
["總是","j-401.mp3","Zǒng shì","always"],
["我最近總是覺得很累、很不舒服。我想請假","j-402.mp3","wǒ zuìjìn zǒng shì juédé hěn lèi, hěn bú shūfú. Wǒ xiǎng qǐngjià","I've been feeling very tired and uncomfortable lately. i want to ask for leave"],
["很多人總是把事情想得太壞","j-403.mp3","hěnduō rén zǒng shì bǎ shì qíng xiǎng dé tài huài","Many people always think things too badly"],
["971.","j-407.mp3","971","971"],
["一直","j-408.mp3","Yīzhí","Always"],
["雖然李先生一直很忙，但是一點也不覺得累","j-409.mp3","suīrán lǐ xiānshēng yīzhí hěn máng, dànshì yī diǎn yě bù juédé lèi","Although Mr. Li has been very busy, he doesn't feel tired at all."],
["這幾天體育課一直考試，一點也不好玩","j-410.mp3","zhè jǐ tiān tǐyù kè yīzhí kǎoshì, yī diǎn yě bù hǎowán","The physical education class has been taking exams these days, it's not fun at all"],
["972.","j-414.mp3","972","972"],
["永遠","j-415.mp3","Yǒngyuǎn","forever"],
["我想要永遠住在這個海邊小屋","j-416.mp3","wǒ xiǎng yào yǒngyuǎn zhù zài zhège hǎibiān xiǎowū","I want to live in this beach house forever"],
["許多人認為銀行裡的錢永遠都不夠","j-417.mp3","xǔduō rén rènwéi yínháng lǐ de qián yǒngyuǎn dōu bùgòu","Many people think that there is never enough money in the bank"],
["973.","j-421.mp3","973","973"],
["個","j-422.mp3","Gè","indivual"],
["一個好的公司應該能照顧員工，而不是讓他們拿健康去換錢","j-423.mp3","yīgè hǎo de gōngsī yīnggāi néng zhàogù yuángōng, ér bùshì ràng tāmen ná jiànkāng qù huànqián","A good company should be able to take care of its employees instead of exchanging their health for money"],
["我想請假一個月，如果不行，兩個星期也可以","j-424.mp3","wǒ xiǎng qǐngjià yīgè yuè, rúguǒ bùxíng, liǎng gè xīngqí yě kěyǐ","I would like to take a month off, if not, two weeks is fine"],
["974.","j-428.mp3","974","974"],
["又","j-429.mp3","Yòu","again"],
["你昨天忘記帶錢包，今天又忘記了","j-430.mp3","nǐ zuótiān wàngjì dài qiánbāo, jīntiān yòu wàngjìle","You forgot your wallet yesterday, and you forgot today"],
["李先生每天總是遲到，今天又遲到了","j-431.mp3","lǐ xiānshēng měitiān zǒng shì chídào, jīntiān yòu chídàole","Mr. Li is always late every day, and he is late again today"],
["975.","j-435.mp3","975","975"],
["比較","j-436.mp3","Bǐjiào","Compare"],
["A:您好，請問有什麼事嗎? B:你好，這張紙上寫著現在學游泳，比較便宜","j-437.mp3","A: Nín hǎo, qǐngwèn yǒu shé me shì ma? B: Nǐ hǎo, zhè zhāng zhǐshàng xiězhe xiànzài xué yóuyǒng, bǐjiào piányí","A: Hello, what can I do? B: Hello, this piece of paper says that it is cheaper to learn to swim now"],
["A: 是的，星期一到星期五的早上比較便宜","j-438.mp3","A: Shì de, xīngqí yī dào xīngqíwǔ de zǎoshang bǐjiào piányí","A: Yes, Monday to Friday mornings are cheaper"],
["977.","j-442.mp3","977","977"],
["像","j-443.mp3","Xiàng","picture"],
["這朵雲就像大象一樣!","j-444.mp3","zhè duǒ yún jiù xiàng dà xiàng yīyàng!","This cloud is like an elephant!"],
["你的小孩跟你很像","j-445.mp3","Nǐ de xiǎohái gēn nǐ hěn xiàng","your kid is just like you"],
["978.","j-449.mp3","978","978"],
["差不多","j-450.mp3","Chàbùduō","almost"],
["這個旅館住一晚差不多要兩百多塊","j-451.mp3","zhège lǚguǎn zhù yī wǎn chàbùduō yào liǎng bǎi duō kuài","This hotel costs about 200 yuan a night"],
["志明學中文差不多學了五年","j-452.mp3","zhìmíng xué zhōngwén chàbùduō xuéle wǔ nián","Zhiming has been learning Chinese for almost five years"],
["979.","j-456.mp3","979","979"],
["一共","j-457.mp3","Yīgòng","total"],
["A:這裡一共是幾天的藥?","j-458.mp3","A: Zhèlǐ yīgòng shì jǐ tiān di yào?","A: How many days of medicine are here in total?"],
["B:一共是三天的藥，一天吃三粒","j-459.mp3","B: Yīgòng shì sān tiān di yào, yītiān chī sān lì","B: It's a three-day medicine in total, take three pills a day"],
["980.","j-463.mp3","980","980"],
["全部","j-464.mp3","Quánbù","all"],
["全部的書都已經被借走了，真糟糕!","j-465.mp3","quánbù de shū dōu yǐjīng bèi jiè zǒule, zhēn zāogāo!","All the books have been borrowed, too bad!"],
["我變胖了，全部的衣服都穿不下了，糟糕!","j-466.mp3","Wǒ biàn pàngle, quánbù de yīfú dōu chuān bùxiàle, zāogāo!","I'm getting fat and I can't fit all my clothes anymore, oops!"],
["981.","j-470.mp3","981","981"],
["所有","j-471.mp3","Suǒyǒu","all"],
["所有員工還在等經理的回答","j-472.mp3","suǒyǒu yuángōng hái zài děng jīnglǐ de huídá","All employees are still waiting for the manager's answer"],
["電影院要求，所有的觀眾都不能帶外面買的食物進去電影院","j-473.mp3","diànyǐngyuàn yāoqiú, suǒyǒu de guānzhòng dōu bùnéng dài wàimiàn mǎi de shíwù jìnqù diànyǐngyuàn","The movie theater requires that all audiences are not allowed to bring food bought outside into the movie theater"],
["982.","j-477.mp3","982","982"],
["任何","j-478.mp3","Rènhé","any"],
["三天當中，他任何人都不見","j-479.mp3","sān tiān dāngzhōng, tā rènhé rén dōu bùjiàn","For three days he didn't see anyone"],
["如果有任何想法都可以跟我們討論","j-480.mp3","rúguǒ yǒu rènhé xiǎngfǎ dōu kěyǐ gēn wǒmen tǎolùn","If you have any ideas, you can discuss with us"],
["983.","j-484.mp3","983","983"],
["許多","j-485.mp3","Xǔduō","many"],
["根據報導，許多觀眾看完電影以後垃圾都留在椅子上","j-486.mp3","gēnjù bàodǎo, xǔduō guānzhòng kàn wán diànyǐng yǐhòu lèsè dōuliú zài yǐzi shàng","According to reports, many viewers left trash on the chairs after watching the movie"],
["說到錢，許多人對它的想法都不一樣","j-487.mp3","shuō dào qián, xǔduō rén duì tā de xiǎngfǎ dōu bù yīyàng","When it comes to money, many people think differently about it"],
["984.","j-491.mp3","984","984"],
["其他","j-492.mp3","Qítā","other"],
["在圖書館不可以大聲說話，你會影響其他正在讀書的人","j-493.mp3","zài túshū guǎn bù kěyǐ dà shēng shuōhuà, nǐ huì yǐngxiǎng qítā zhèngzài dúshū de rén","Don't speak loudly in the library, you will affect other people who are reading"],
["如果有人帶了一些有奇怪味道的食物進電影院，也很容易影響其他看電影的人","j-494.mp3","rúguǒ yǒurén dàile yīxiē yǒu qíguài wèidào de shíwù jìn diànyǐngyuàn, yě hěn róngyì yǐngxiǎng qítā kàn diànyǐng de rén","If someone brings some food with a strange taste into the movie theater, it will easily affect other moviegoers"],
["985.","j-498.mp3","985","985"],
["或","j-499.mp3","Huò","or"],
["上課的時候，不可以用手機或電腦找答案","j-500.mp3","shàngkè de shíhòu, bù kěyǐ yòng shǒujī huò diànnǎo zhǎo dá'àn","During class, you are not allowed to use your mobile phone or computer to find answers"],
["星期三或星期一開會，經理都可以","j-501.mp3","xīngqísān huò xīngqí yī kāihuì, jīnglǐ dōu kěyǐ","Meeting on Wednesday or Monday, the manager is ok"],
["986.","j-505.mp3","986","986"],
["或是","j-506.mp3","Huò shì","or"],
["上課的時候，不可以用手機或是電腦找答案","j-507.mp3","shàngkè de shíhòu, bù kěyǐ yòng shǒujī huò shì diànnǎo zhǎo dá'àn","During class, you are not allowed to use your mobile phone or computer to find answers"],
["星期禮拜一或是禮拜三學游泳，我都可以","j-508.mp3","xīngqí lǐbài yī huò shì lǐbài sān xué yóuyǒng, wǒ dū kěyǐ","learn to swim on monday or wednesday, i can do it"],
["987.","j-512.mp3","987","987"],
["但是","j-513.mp3","Dànshì","but"],
["許多人很努力工作，但是忘記休息也很重要","j-514.mp3","xǔduō rén hěn nǔlì gōngzuò, dànshì wàngjì xiūxí yě hěn zhòngyào","Many people work very hard, but forgetting to rest is also important"],
["許多人總是照顧別人，但是忘記照顧自己","j-515.mp3","xǔduō rén zǒng shì zhàogù biérén, dànshì wàngjì zhàogù zìjǐ","Many people are always taking care of others, but forget to take care of themselves"],
["988.","j-519.mp3","988","988"],
["而且","j-520.mp3","Érqiě","and"],
["看電影的時候，很多觀眾帶外面買的食物進去，而且那些食物味道還很重","j-521.mp3","kàn diànyǐng de shíhòu, hěnduō guānzhòng dài wàimiàn mǎi de shíwù jìnqù, érqiě nàxiē shíwù wèidào huán hěn zhòng","When watching a movie, many viewers bring in food bought outside, and the food has a strong smell"],
["志明很喜歡運動，每天早上去跑步，而且晚上還去游泳","j-522.mp3","zhìmíng hěn xǐhuān yùndòng, měitiān zǎoshang qù pǎobù, érqiě wǎnshàng hái qù yóuyǒng","Zhiming likes sports very much. He goes for a run every morning and goes swimming at night"],
["989.","j-526.mp3","989","989"],
["不但","j-527.mp3","Bùdàn","not only"],
["這位老先生雖然年紀大，但是身體健康，而且精神也很好","j-528.mp3","zhè wèi lǎo xiānshēng suīrán niánjì dà, dànshì shēntǐ jiànkāng, érqiě jīngshén yě hěn hǎo","Although the old man is old, he is in good health and in good spirits"],
["小玉這幾天不但總是覺得累，也很不舒服","j-529.mp3","xiǎoyù zhè jǐ tiān bùdàn zǒng shì juédé lèi, yě hěn bú shūfú","Xiaoyu these days not only always feel tired, but also very uncomfortable"],
["990.","j-533.mp3","990","990"],
["雖然","j-534.mp3","Suīrán","Although"],
["根據調查，許多員工雖然生病了，但是不敢告訴老闆","j-535.mp3","gēnjù diàochá, xǔduō yuángōng suīrán shēngbìngle, dànshì bù gǎn gàosù lǎobǎn","According to the survey, many employees are afraid to tell their bosses when they are sick"],
["雖然錢很重要，但是身體健康更重要","j-536.mp3","suīrán qián hěn zhòngyào, dànshì shēntǐ jiànkāng gèng zhòngyào","Money is important, but health is more important"],
["991.","j-540.mp3","991","991"],
["如果","j-541.mp3","Rúguǒ","if"],
["如果想吃東西，只能買電影院裡賣的食物","j-542.mp3","rúguǒ xiǎng chī dōngxī, zhǐ néng mǎi diànyǐngyuàn lǐ mài de shíwù","If you want to eat, you can only buy food sold in the cinema"],
["如果你每天都覺得身體很累，有一份報告或許可以告訴你原因","j-543.mp3","rúguǒ nǐ měitiān dū juédé shēntǐ hěn lèi, yǒuyī fèn bàogào huòxǔ kěyǐ gàosù nǐ yuányīn","If you feel tired every day, a report may tell you why"],
["992.","j-547.mp3","992","992"],
["要是","j-548.mp3","Yàoshi","if"],
["要是明天你的背還是很痛，就去看醫生","j-549.mp3","yàoshi míngtiān nǐ de bèi háishì hěn tòng, jiù qù kàn yīshēng","If your back still hurts tomorrow, go to the doctor"],
["要是你水喝得不夠多，或是吃得不健康，就很容易累","j-550.mp3","yàoshi nǐ shuǐ hē dé bùgòu duō, huò shì chī dé bùjiànkāng, jiù hěn róngyì lèi","Tired easily if you don't drink enough water or eat unhealthy"],
["993.","j-554.mp3","993","993"],
["原來","j-555.mp3","Yuánlái","turn out to be"],
["原來小張不是台灣人，是日本人啊!","j-556.mp3","yuánlái xiǎozhāng bùshì táiwān rén, shì rìběn rén a!","It turns out that Xiao Zhang is not Taiwanese, but Japanese!"],
["原來你不是在銀行工作啊!","j-557.mp3","Yuánlái nǐ bùshì zài yínháng gōngzuò a!","So you don't work in a bank!"],
["994.","j-561.mp3","994","994"],
["本來","j-562.mp3","Běnlái","originally"],
["美心本來很害羞，後來認識了很多台灣朋友，就不害羞了","j-563.mp3","měi xīn běnlái hěn hàixiū, hòulái rènshíle hěnduō táiwān péngyǒu, jiù bù hàixiūle","Meixin was very shy at first, but after meeting a lot of Taiwanese friends, she is no longer shy"],
["這個地方本來人很少，後來人越來越多","j-564.mp3","zhège dìfāng běnlái rén hěn shǎo, hòulái rén yuè lái yuè duō","There were very few people in this place, and then more and more people"],
["995.","j-568.mp3","995","995"],
["必須","j-569.mp3","Bìxū","must"],
["有些人認為必須賺很多錢，有些人覺得錢夠用就好","j-570.mp3","yǒuxiē rén rènwéi bìxū zhuàn hěnduō qián, yǒuxiē rén juédé qián gòu yòng jiù hǎo","Some people think it is necessary to make a lot of money, some people think it is enough money"],
["牆上寫著ㄧ句話 「心必須先安靜，才能專心」","j-571.mp3","qiáng shàng xiězheyi1 jù huà `xīn bìxū xiān ānjìng, cáinéng zhuānxīn'","There is a sentence written on the wall 'The heart must be quiet before you can concentrate'"],
["996.","j-575.mp3","996","996"],
["當然","j-576.mp3","Dāngrán","certainly"],
["錢重要，可是身體當然更重要","j-577.mp3","qián zhòngyào, kěshì shēntǐ dāngrán gēng zhòngyào","Money is important, but of course the body is more important"],
["想要吃什麼東西當然是自己的自由","j-578.mp3","xiǎng yào chī shénme dōngxī dāngrán shì zìjǐ de zìyóu","You are free to eat whatever you want"],
["997.","j-582.mp3","997","997"],
["也許","j-583.mp3","Yěxǔ","Maybe"],
["也許老闆會答應李小姐的要求，讓她請兩個月的假","j-584.mp3","yěxǔ lǎobǎn huì dāyìng lǐ xiǎojiě de yāoqiú, ràng tā qǐng liǎng gè yuè de jiǎ","Maybe the boss will agree to Miss Li's request and ask her to take two months' leave"],
["也許我們要先改變自己，才能改變別人","j-585.mp3","yěxǔ wǒmen yào xiān gǎibiàn zìjǐ, cáinéng gǎibiàn biérén","Maybe we have to change ourselves before we can change others"],
["998.","j-589.mp3","998","998"],
["只好","j-590.mp3","Zhǐhǎo","had to"],
["雖然觀眾不願意，但是只好配合電影院的規定","j-591.mp3","suīrán guānzhòng bù yuànyì, dànshì zhǐhǎo pèihé diànyǐngyuàn de guīdìng","Although the audience was unwilling, they had no choice but to cooperate with the cinema's regulations"],
["我忘了預約，只好去排隊","j-592.mp3","wǒ wàngle yùyuē, zhǐhǎo qù páiduì","I forgot to make an appointment and had to queue"],
["999.","j-596.mp3","999","999"],
["被","j-597.mp3","Bèi","quilt"],
["這本書的作者不希望被別人知道他真的名字","j-598.mp3","zhè běnshū de zuòzhě bù xīwàng bèi biérén zhīdào tā zhēn de míngzì","The author of this book does not want others to know his real name"],
["每個人應該都想被照顧，被關心吧!","j-599.mp3","měi gèrén yīnggāi dōu xiǎng bèi zhàogù, bèi guānxīn ba!","Everyone should want to be cared for and cared about!"],
["1000.","j-603.mp3","1000","1000"],
["起","j-604.mp3","Qǐ","rise"],
["店門口前面寫著全部的皮包五百元起","j-605.mp3","diàn ménkǒu qiánmiàn xiězhe quánbù de píbāo wǔbǎi yuán qǐ","In front of the store entrance, it says that all leather bags start at 500 yuan"],
["牆上的海報寫著九折起","j-606.mp3","qiáng shàng dì hǎibào xiězhe jiǔ zhé qǐ","The poster on the wall says 10% off"],
["1001.","j-610.mp3","1001","1001"],
["啊","j-611.mp3","A","ah"],
["當然好啊!","j-612.mp3","dāngrán hǎo a!","Of course!"],
["郵局就在你前面啊!","j-613.mp3","Yóujú jiù zài nǐ qiánmiàn a!","The post office is right in front of you!"],
["1002.","j-617.mp3","1002","1002"],
["讓","j-618.mp3","Ràng","let"],
["作者不告訴我們答案，應該是要讓我們自己去思考","j-619.mp3","zuòzhě bù gàosù wǒmen dá'àn, yīnggāi shì yào ràng wǒmen zìjǐ qù sīkǎo","The author does not tell us the answer, it should let us think for ourselves"],
["因此，讓員工有幸福感，應該是未來企業努力的方向","j-620.mp3","yīncǐ, ràng yuángōng yǒu xìngfú gǎn, yīnggāi shì wèilái qǐyè nǔlì de fāngxiàng","Therefore, making employees feel happy should be the direction of future enterprise efforts"],
["1003.","j-624.mp3","1003","1003"],
["把","j-625.mp3","Bǎ","Bundle"],
["A: 牛奶呢？B: 我把牛奶喝完了","j-626.mp3","A: Niúnǎi ne?B: Wǒ bǎ niúnǎi hē wánliǎo","A: What about milk? B: I finished drinking the milk"],
["先幫我把沙發搬到樓上、然後把書桌搬到一樓，再把地板擦乾淨","j-627.mp3","xiān bāng wǒ bǎ shāfā bān dào lóu shàng, ránhòu bǎ shūzhuō bān dào yī lóu, zài bǎ dìbǎn cā qiánjìng","First help me move the sofa upstairs, then move the desk to the first floor, and then clean the floor"],
]
