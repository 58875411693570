
import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, Dropdown, List, Label, Popup, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { API_URL } from '../App.js';
import axios from 'axios';
import {retrieveSubjectObject, nounoptions1, nounoptions2, nounoptions3, nounoptionsmodalis, options1, options2, options3, verbPostbases, nounPostbases} from './constants/constants.js'
import {postbasesEndingsUnderlying} from './constants/underlying.js'
import palette from 'google-palette';
import shuffle from 'shuffle-array';
import { TagColors } from './HomeHelper.js';
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;
let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"


class Report extends Component {
	constructor(props) {
		// console.log(props)
		super(props);
		this.state = {
			// history: props.location.state === undefined ? [] : props.location.state.history,
			// from: props.location.state === undefined ? '' : props.location.state.from,
		}	   
		scroll.scrollTo(0);

	}


	render() {
		// console.log(this.state)
		// console.log(this.state.colorsList)

		return (
			<div style={{fontFamily:customFontFam}}>


			<Grid textAlign='center'>
			<Grid.Row  style={{}}>
			<Grid.Column textAlign='left'>

				{this.state.from === '/' ?
					<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          			<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	         	 	</Link>
						:
          			<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
				}
					


			</Grid.Column>
			</Grid.Row>
			<Grid.Row style={{padding:0,margin:0}}>
				<Grid.Column style={{padding:0,margin:0}}>
					<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdxJbG2wj52KSV_YYlcQDQWv6JAjb4HdbtPNvVCZhCIiP0FMg/viewform?embedded=true" width="360" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
				</Grid.Column>
			</Grid.Row>
			</Grid>

			</div>
		);
	}
}
export default Report;
