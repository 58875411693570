import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Popup, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import { TagColors } from './HomeHelper.js';
import {nounoptions1, nounoptions2, nounoptions3, options1, options2, options3} from './constants/constants.js'
import * as Scroll from 'react-scroll';

var scroll    = Scroll.animateScroll;

let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

class NewUsageBaseDisplay extends Component {
	constructor(props) {
		super(props);
		// console.log(props)
		this.state = {
			playingAudio:false,
			// word: props.location.state === undefined ? [] : props.location.state.word,
			// currentProps: props.location.state === undefined ? [] : props.location.state.props,
			// currentState: props.location.state === undefined ? [] : props.location.state.state,
		}
	}



//   componentWillReceiveProps(nextProps) {
//     // console.log(nextProps)
//     this.setState({ 
// 			// keySplit: [],
// 			definition: nextProps.definition,
// 			fullDefinition: nextProps.fullDefinition,
// 			ind:nextProps.index,
// 			entry:nextProps.entry,
// 			audioMatrix:nextProps.audioMatrix,
// 			word:nextProps.word,
// 			halfTransitive:nextProps.halfTransitive,
// 			key: '',
// 			// verbalIllustrations: [],
// 			// etymology: [],
// 			// parent: '',
// 			// children: [],
// 			// childrenEntries: {},
// 			// parentEntries: {},
// 			// postbaseEntries: [],
// 			// parentAndEtymology: [],
// 			// extraInfo: [],
// 			subject:'it',
//       people: 1,
//       person: 3,
//       nounvalue1: "1",
//       nounvalue2: "00(3)",
//       value1: "s31-1(1)",
//       value2: "o31-3(2)",
//       editMode: false,
//       transitiveLeftOfObject:'',
//       transitiveRightOfObject:'',
//       // definitionTag: '',
//       // entryBase: [["atigi"],["atigi","nakuu"],["atigi"]],
//       // entryBaseCase: ["atigi","atigiruq","atigigaa"],
//       // entryBaseCaseDefinition: ["one parka, pullover-style fur parka with traditional fur ruff for the hood","he is putting on a parka","she is putting a parka on <her/him> later"],
//       entryModified: [],
//       entryModifiedAlt: [],
//       // entryModifiedDefinition: ["parka, pullover-style fur parka with traditional fur ruff for the hood","putting on a parka","putting a parka on <her/him> later"],
//       activeEditIndex:-1,
//       baseOptions:[],
//       activeKeyInEditIndex:0,
//       nounMood:'Absolutive',
//       verbMood:'Indicative',
//       fstCall:'',
//       // irregular:[],
//       // extraInfo:[],
//       objectPossessed:false,

//     });  
//     this.defaultFstCall(nextProps.index, nextProps.definition)
//   }



// 	componentDidMount() {
// 		this.defaultFstCall(this.props.index, this.props.definition)
// 	}






//   getFSTParse(fstCall,definition,activeEditIndex,activeKeyInEditIndex) {
//   	// console.log(fstCall,activeEditIndex,activeKeyInEditIndex)
//   	let FSTsearch = definition[activeEditIndex][3][activeKeyInEditIndex][1] + fstCall
// 		// console.log(FSTsearch,activeEditIndex)

// 		let baseOptions = []
// 		this.state.definition[activeEditIndex][3].map((m,ind)=>{
// 			baseOptions.push({id:ind,value:ind,text:m[0]})
// 		})
// 		this.setState({baseOptions:baseOptions})

// 		// console.log(encodeURIComponent(FSTsearch))
//     axios
//       .get(API_URL + "/inupiaqsegment/" + encodeURIComponent(FSTsearch))
//       .then(response => {
//         // console.log(response.data);

//         let words1 = []
//         let words2 = []
//         response.data.words[0].map((k,ind)=>{
//         	words1.push(k)
//         })
//         response.data.words[1].map((k,ind)=>{
//         	words2.push(k)
//         })
//         this.setState({
//         	// englishColorList: slicedColors,
//         	// inupiaqColorList: ['#000000'].concat(slicedColors.slice(0).reverse()).concat(['#852828']),
//           entryModified: words1,
//           entryModifiedAlt: words2,
//         });

 
//       });
//   }

//   identifyObjectCase = (ind,match) => {
//   	// console.log(match, match.includes('<him ='))
// 		let value1 = ''
// 		let value2 = ''
// 		let possessed = false
// 		if (ind % 2 == 0) {
// 		value1 = "s31-2(1)"  
// 		} else {
// 		value1 = "s31-1(1)"  
// 		}
// 		if (match == '<her/him/it>') {
//   		value2 = "o31-3(2)"
// 		} else if (match == '<her/him>') {
//   		if (ind % 2 == 0) {
//   		value2 = "o31-1(2)"			
//   		} else {
//   		value2 = "o31-2(2)"
//   		}
// 		} else if (match == '<her/his>') {
//   		if (ind % 2 == 0) {
//   		value2 = "o31-1(2)"			
//   		} else {
//   		value2 = "o31-2(2)"
//   		}
// 		} else if (match == '<her>') {
//   		value2 = "o31-2(2)"
// 		} else if (match == '<him>') {
//   		value2 = "o31-1(2)"
// 		} else if (match.includes('<him =')) {
//   		value2 = "o31-1(2)"
// 		} else if (match == '<a person/it>') {
//   		value2 = "o31-3(2)"
// 		} else if (match == '<it>') {
//   		value2 = "o31-3(2)"
// 		} else if (match == '<them>') {
//   		value2 = "o33(2)"
// 		} else if (match == '<someone/something>') {
//   		value2 = "o31-3(2)"
// 		} else if (match == '<her/his/its>') {
// 			possessed = true
//   		value2 = "o31-3(2)"
// 		} else {
//   		value2 = "o31-3(2)"			
// 		}
// 		this.setState({objectPossessed:possessed})
//   	return [value1, value2]


//   }

// 	defaultFstCall = (ind, definition) => {
// 		// console.log(ind,definition)
// 		let tag = definition[ind][2]
// 		let value1 = ''
// 		let value2 = ''
// 		let nounvalue1 = ''
// 		let nounvalue2 = ''
// 		let owner = ''
// 		// console.log(tag,ind)
// 		if (tag === '(i) INTRANSITIVE VERB') {
//   		if (ind % 2 == 0) {
//   		value1 = "s31-2(1)"  			
//   		} else {
//   		value1 = "s31-1(1)"  
//   		}

//   		// console.log(definition[ind][5])
//   		if (definition[ind][5].includes("(of")) {
//   			value1 = "s31-3(1)"
//   		}
// 			// let primaryVerb = definition[ind][5]
// 			// if (definition[ind][7].includes('momentary base')) {
// 			// 	primaryVerb = definition[ind][6][1]
// 			// }
// 			// this.setState({
// 			// 	primaryVerb: primaryVerb,
// 			// })

// 			// console.log(definition[ind][5])
// 			// console.log(/⟨.*?⟩/g.exec(definition[ind][5]))

// 			let verbTenseMatch = /⟨.*?⟩/g.exec(definition[ind][5])
// 			let splitLeftOfObject = []
// 			if (verbTenseMatch.length == 1) {
// 				splitLeftOfObject = definition[ind][5].split(verbTenseMatch[0])
// 			}

// 			let primaryVerb = verbTenseMatch[0].replace("⟨","").replace("⟩","")
// 			if (definition[ind][7].includes('momentary base')) {
// 				primaryVerb = definition[ind][6][1]
// 			}

// 			this.setState({
// 				leftOfVerb: splitLeftOfObject[0],
// 				rightOfVerb: splitLeftOfObject[1],
// 				primaryVerb: primaryVerb,
// 			});

// 			this.setIntransitive(ind,definition,true,value1,undefined,undefined)
// 			// this.setState({person: 3,people: 1, value1: "31-1(1)",activeEditIndex:ind}); this.getFSTParse(fstCall,ind,0); 
// 		} else if (tag === 'NOUN') {
//   		nounvalue1="1"
//   		nounvalue2="00(3)"
//   		owner = ''
// 			this.setNoun(ind,definition,true,nounvalue1,nounvalue2,owner,undefined,undefined)
// 		} else if (tag === '(t) TRANSITIVE VERB') {
// 			let matches = definition[ind][5].match(/<.*?>/g)
// 			// console.log(matches)
// 			let splitSentence = []
// 			if (matches.length == 1) {
// 				splitSentence = definition[ind][5].split(matches[0])
// 			}

// 			// let matches2 = splitSentence[0].match(/\⟨.*?\⟩/g)

// 			// console.log(splitSentence[0],'-',splitSentence[1])

// 			// console.log(this.state.entry[5])

// 			let verbTenseMatch = splitSentence[0].match(/⟨.*?⟩/g)
// 			let splitLeftOfObject = []
// 			if (verbTenseMatch.length == 1) {
// 				splitLeftOfObject = splitSentence[0].split(verbTenseMatch[0])
// 			}

// 			let primaryVerb = verbTenseMatch[0].replace("⟨","").replace("⟩","")
// 			if (definition[ind][7].includes('momentary base')) {
// 				primaryVerb = definition[ind][6][1]
// 			}

// 			this.setState({
// 				leftOfVerb: splitLeftOfObject[0],
// 				rightOfVerb: splitLeftOfObject[1],
// 				primaryVerb: primaryVerb,
// 				transitiveRightOfObject: splitSentence[1].replace("⟨","").replace("⟩","").trim(),
// 			});

// 			let values = this.identifyObjectCase(ind,matches[0])

// 			this.setTransitive(ind,definition,true,values[0],values[1],undefined,undefined)
// 		}
// 	}

//   setIntransitive(ind, definition, initializing, value1, e, data) {
//   	// console.log(ind, e, data)
//   	if (!initializing) {
//   		value1 = data.value
//   		// person = data.value[0]
//   		// people = data.value[1]
//   	}

//   	let fstCall = ''
//     if (this.state.verbMood === 'Indicative') {
//     	fstCall = '>+V+Ind+Prs+' + value1[1] + peopleDict[value1[2]]
//     }

//   	if (value1.slice(0,3) == "s31") {
//   		this.setState({notEdited:true})
//   	} else {
//   		this.setState({notEdited:false})  		
//   	}

//     this.setState({
//       value1: value1,
//       activeEditIndex:ind,
//       // person: person,
//       // people: people,
//       fstCall: fstCall,
//     });

//     this.getFSTParse(fstCall,definition,ind,this.state.activeKeyInEditIndex)
//   }


//   setNoun(ind, definition, initializing, nounvalue1, nounvalue2, owner, e, data) {
//   	// console.log(e,data,ind,initializing)
//   	if (!initializing) {
//   		if (data.value.length == 1) {
// 	  		nounvalue1=data.value
// 	  		nounvalue2=this.state.nounvalue2
//   		} else {
// 	  		nounvalue1=this.state.nounvalue1
// 	  		nounvalue2=data.value	
//   		}


//   		if (nounvalue2[0] !== '0') {
//   			owner = '+'+nounvalue2[0]+peopleDict[nounvalue2[1]]
//   		} else {
//   			owner = ''
//   		}
//   		// console.log(owner)
//   		// value1 = data.value
//   		// person = data.value[0]
//   		// people = data.value[1]
//   	}

//   	if (nounvalue1 == "1" && nounvalue2 == "00(3)") {
//   		this.setState({notEdited:true})
//   	} else {
//   		this.setState({notEdited:false})  		
//   	}

//   	let fstCall = ''
//     if (this.state.nounMood === 'Absolutive') {
//     	fstCall = '>+N+Abs+' + peopleDict[nounvalue1] + owner
//     }
//     // console.log(nounvalue1, nounvalue2)
//     if ((definition[ind][7].includes('dispensable noun -- food') || definition[ind][7].includes('dispensable noun -- kin')) && nounvalue2[0] !== '0') {
//     	fstCall = '>un' + fstCall
//     	if (definition[ind][7].includes('dispensable noun -- food')) {
//     		this.setState({ displayDispense: true })	
//     	} else {
//     		this.setState({ displayDispense: false })	    		
//     	}
//     } else {
//     	this.setState({ displayDispense: false })	    		    	
//     }


//     fstCall = fstCall.replace('4','3R')
//     // console.log(fstCall, nounvalue2)

//     this.setState({
//       nounvalue1: nounvalue1,
//       nounvalue2: nounvalue2,
//       value1: "s31-3(1)",
//       activeEditIndex:ind,
//       // person: person,
//       // people: people,
//       fstCall: fstCall,
//     });

//     this.getFSTParse(fstCall,definition, ind,this.state.activeKeyInEditIndex)
//   }


//   setTransitive(ind, definition, initializing, value1, value2, e, data) {
//   	// console.log(e, data, ind, initializing)
//   	if (!initializing) {
//   		console.log(data.value)
//   		if (data.value[0] == 's') {
// 	  		value1 = data.value
// 	  		value2 = this.state.value2
//   		} else {
// 	  		value1 = this.state.value1
// 	  		value2 = data.value	
//   		}
//   		// person = data.value[0]
//   		// people = data.value[1]
//   	}

//   	// console.log(value1,value2)
//   	let flag1 = false
//   	let flag2 = false
//   	let newoptions1 = []
//   	let newoptions2 = []
//   	let newoptions3 = []

//   	if (value1[1]=='1' || value1[1]=='2') {
// 	  	for (var i = 0; i < 11; i++) {
// 	  		if (value1[1]=='1') {
// 	  			if (![0,5,8].includes(i)) {
// 			  		newoptions2.push(options2[i])
// 			  		newoptions3.push(options3[i])
// 	  			}
// 	  		} else if (value1[1]=='2') {
// 	  			if (![1,6,9].includes(i)) {
// 			  		newoptions2.push(options2[i])
// 			  		newoptions3.push(options3[i])
// 	  			}
// 	  		}
// 	  	}
// 	  } else {
//   		newoptions2 = options2
//   		newoptions3 = options3
// 	  }
  	
//   	if (value2[1]=='1' || value2[1]=='2') {
// 	  	for (var i = 0; i < 11; i++) {
// 	  		if (value2[1]=='1') {
// 	  			if (![0,5,8].includes(i)) {
// 	  				newoptions1.push(options1[i])
// 	  			}
// 	  		} else if (value2[1]=='2') {
// 	  			if (![1,6,9].includes(i)) {
// 	  				newoptions1.push(options1[i])
// 	  			}
// 	  		}
// 	  	}
//   	} else {
//   		newoptions1 = options1
//   	}

//   	if (value1.slice(0,3) == "s31" && value2.slice(0,3) == "o31") {
//   		this.setState({notEdited:true})
//   	} else {
//   		this.setState({notEdited:false})  		
//   	}

//   	let fstCall = ''
//     if (this.state.verbMood === 'Indicative') {
//     	fstCall = '>+V+Ind+Prs+' + value1[1] + peopleDict[value1[2]] + '+' + value2[1]+peopleDict[value2[2]] + 'O'
//     }

//     this.setState({
//       value1: value1,
//       value2: value2,
//       activeEditIndex:ind,
//       options1: newoptions1,
//       options2: newoptions2,
//       options3: newoptions3,
//       // person: person,
//       // people: people,
//       fstCall: fstCall,
//     });

//     this.getFSTParse(fstCall,definition,ind,this.state.activeKeyInEditIndex)
//   }


//   changeActiveUsageKey(entry, data) {
//   	// console.log(data)
//     this.setState({
//       // value1: data.value,
//       activeKeyInEditIndex:data.value,
//       // notEdited:false,
//     });
//     this.getFSTParse(this.state.fstCall,this.state.definition,this.state.activeEditIndex,data.value)
//   }


// 	getSubjectIs = (personN, peopleN) => {
// 	  let subjectis = '';
// 	  if (!this.state.definition[this.state.ind][7].includes('momentary base')) {
// 		  if (peopleN === '1' && personN === '1') {
// 		    subjectis = 'am '
// 		  } else if (peopleN === '1' && personN === '3') {
// 		    subjectis = 'is '
// 		  } else {
// 		    subjectis = 'are '
// 		  }
// 	  }
// 	  return subjectis
// 	};


//   repeatAudio(audio, event, data) {
//     // console.log(audio)
//     // var a = new Audio(API_URL+'static/WoW-Link.mp3');
//     // a.play();
//     // let a;
//     // axios
//     //   .get(API_URL + "/audiolibrary/" + audio.mp3)
//     //   .then(response => {
//     //     // let end = now();
//     //     // ReactGA.timing({
//     //     //   category: 'Loading',
//     //     //   variable: 'dictionary',
//     //     //   value: (end-start).toFixed(3),
//     //     //   label: 'Dictionary loading'
//     //     // });
//     //     var a = new Audio(response.data);
//     //     console.log(a)
//     //     a.play();
//     //     // fuse.setCollection(dictionary);
//     //     // fuse1.setCollection(dictionary);
//     //     // console.log('Fetched dictionary');

//     //     // dictionary.forEach(entry => dictionary_dict[entry.keyString] = entry.definitionString) // create dictionary_dict dictionary
//     //     // // dictionary_prepared = fuzzysort.prepare(dictionary)

//     //     // this.setState({ dictionary: dictionary });
//     //   });
//     if (!this.state.playingAudio) {

//       let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replace('/','~'));
//       this.setState({playingAudio: true});

//       sound.play()

//       sound.onended=()=>{
//         this.setState({playingAudio: false});
//       }
//     }
//   }

// 	usageEntry = (ind,tag) => {
// 		// console.log(this.state)
// 		let showAudio = this.state.audioMatrix[ind].length > 0 && this.state.notEdited && this.state.activeKeyInEditIndex === 0
// 		if (tag === 'NOUN') {
// 			return (	
// 							<div>
// 								<div style={{display:'flex',alignItems:'center',height:'34px',marginTop:'15px', marginLeft:'45px',fontSize:'20px',color:'#000000',fontWeight:'300'}}>
// 								{this.state.entryModified.map((modifiedword, m)=>
// 									<span>
// 									{m > 0 ?
// 										', '
// 										:
// 										null
// 									}
// 									{this.state.entry[3].length == 1 ?
// 										(modifiedword.split('>').map((q,index) =>
// 											<span style={{color:(index == 0 ? '#000000': '#852828')}}>{q}</span>
// 											))
// 										:
// 										<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{color:(index == 0 ? '#000000': '#852828')}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
// 									}
// 									</span>
// 									)}
// 								{showAudio ?
// 									<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
// 									:
// 									null
// 								}
								
// {/*								<Link to={{pathname: '/wordbuilder/' + this.state.word, state: {audioMatrix:this.state.audioMatrix, activeKeyInEditIndex:this.state.activeKeyInEditIndex,fullDefinition: this.state.fullDefinition, word: this.state.entry[4], num: this.state.ind, usageDefinition: this.state.entry[5], baseCase:this.state.definition[this.state.ind][3][this.state.activeKeyInEditIndex], nounvalue1: this.state.nounvalue1, nounvalue2: this.state.nounvalue2, value1: this.state.value1, value2: this.state.value2, }}}>
// 									<Icon circular style={{marginLeft:(showAudio ? '5px': '15px'),fontSize:'18px',color:'#8f8f8f',cursor:'pointer'}} name='pencil' />
// 								</Link>*/}

// 								</div>
// 								<div style={{marginTop:'15px', marginBottom:'15px',marginLeft:'45px',fontSize:'18px',color:'#0D0D0D',fontWeight:'300'}}>
// 								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setNoun.bind(this,this.state.activeEditIndex,this.state.definition,false,'','','')} value={this.state.nounvalue2} options={nounoptions1} />
// 								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setNoun.bind(this,this.state.activeEditIndex,this.state.definition,false,'','','')} value={this.state.nounvalue1} options={this.state.displayDispense ? nounoptions3 : nounoptions2} />
// 								<span style={{color:'#777777',lineHeight:'26px'}}>{this.processStyledText(this.state.entry[5])}</span>
// 								</div>

// 								<Link to={{pathname: '/wordbuilder/' + this.state.word, state: {audioMatrix:this.state.audioMatrix, activeKeyInEditIndex:this.state.activeKeyInEditIndex,fullDefinition: this.state.fullDefinition, word: this.state.entry[4], num: this.state.ind, usageDefinition: this.state.entry[5], baseCase:this.state.definition[this.state.ind][3][this.state.activeKeyInEditIndex], nounvalue1: this.state.nounvalue1, nounvalue2: this.state.nounvalue2, value1: this.state.value1, value2: this.state.value2, }}}>
// 									<Button basic compact style={{paddingRight:8,paddingLeft:8,paddingTop:8,paddingBottom:8,marginLeft:'45px',fontSize:'16px',fontWeight:'300',marginBottom:'20px'}}>
// 									<span style={{fontFamily:customFontFam}}>
// 									Try Word Builder
// 									</span>
// 									<Icon name='chevron right' />
// 									</Button>
// 								</Link>


// 							</div>		
// 						)
// 		} else if (tag === '(i) INTRANSITIVE VERB') {
// 			return (	
// 							<div>
// 								{this.state.definition[this.state.ind][7].includes('half transitive') ?
// 								<div style={{marginLeft:'45px',fontSize:'14px',color:'grey'}}> {'half-transitive form: '+this.state.halfTransitive[0][0]} </div>
// 								:
// 									null
// 								}
// 								<div style={{display:'flex',alignItems:'center',height:'34px',marginTop:'21px', marginLeft:'45px',fontSize:'20px',color:'#000000',fontWeight:'300'}}>
// 								{this.state.entryModified.map((modifiedword, m)=>
// 									<span>
// 									{m > 0 ?
// 										', '
// 										:
// 										null
// 									}
// 									{this.state.entry[3].length == 1 ?
// 										(modifiedword.split('>').map((q,index) =>
// 											<span style={{color:(modifiedword.split(">").length-1 == index ?'#852828':'#000000')}}>{q}</span>
// 											))
// 										:
// 										<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{color:(modifiedword.split(">").length-1 == index ?'#852828':'#000000')}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
// 									}
// 									</span>
// 									)}

// 								{showAudio ?
// 									<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
// 									:
// 									null
// 								}

// {/*								<Link to={{pathname: '/wordbuilder/' + this.state.word, state: { audioMatrix:this.state.audioMatrix, activeKeyInEditIndex:this.state.activeKeyInEditIndex,fullDefinition: this.state.fullDefinition, word: this.state.entry[4], num: this.state.ind, usageDefinition: this.state.entry[5], baseCase:this.state.definition[this.state.ind][3][this.state.activeKeyInEditIndex], nounvalue1: this.state.nounvalue1, nounvalue2: this.state.nounvalue2, value1: this.state.value1, value2: this.state.value2, }}}>
// 									<Icon circular style={{marginLeft:(showAudio ? '5px': '15px'),fontSize:'18px',color:'#B1B1B1',cursor:'pointer'}} name='pencil' />
// 								</Link>*/}

// 								</div>
// 								<div style={{marginTop:'12px', marginBottom:'22px',marginLeft:'45px',fontSize:'18px',color:'#0D0D0D',fontWeight:'300'}}>
// 								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setIntransitive.bind(this,this.state.activeEditIndex, this.state.definition,false, '')} value={this.state.value1} options={options1} />
// 								<span style={{color:'#777777'}}>{this.getSubjectIs(this.state.value1[1],this.state.value1[2])}</span>

// 								<span style={{color:'#777777'}}>{this.processStyledText(this.state.leftOfVerb)}</span>
// 								<span style={{color:'#777777'}}>{this.processStyledText(this.state.primaryVerb)}</span>
// 								<span style={{color:'#777777'}}>{this.processStyledText(this.state.rightOfVerb)}</span>

// 								</div>

// 							{this.state.definition[this.state.ind][7].includes("transitional base") ?
// 								<div style={{marginLeft:'45px',paddingBottom:'10px'}}>
// 									<span style={{fontSize:'14px',fontStyle:'italic',color:'#8c8c8c',fontWeight:'400',lineHeight:'23px'}}>Also immediate past tense</span>
// 								</div>
// 								:
// 								null
// 							}

// 								<Link to={{pathname: '/wordbuilder/' + this.state.word, state: { audioMatrix:this.state.audioMatrix, activeKeyInEditIndex:this.state.activeKeyInEditIndex,fullDefinition: this.state.fullDefinition, word: this.state.entry[4], num: this.state.ind, usageDefinition: this.state.entry[5], baseCase:this.state.definition[this.state.ind][3][this.state.activeKeyInEditIndex], nounvalue1: this.state.nounvalue1, nounvalue2: this.state.nounvalue2, value1: this.state.value1, value2: this.state.value2, }}}>
// 									<Button basic compact style={{paddingRight:8,paddingLeft:8,paddingTop:8,paddingBottom:8,marginLeft:'45px',fontSize:'16px',fontWeight:'300',marginBottom:'20px'}}>
// 									<span style={{fontFamily:customFontFam}}>
// 									Try Word Builder
// 									</span>
// 									<Icon name='chevron right' />
// 									</Button>
// 								</Link>




// 							</div>		
// 						)
// 		} else if (tag === '(t) TRANSITIVE VERB') {
// 			return (	
// 							<div>
// 								<div style={{display:'flex',alignItems:'center',height:'34px',marginTop:'15px', marginLeft:'45px',fontSize:'20px',color:'#000000',fontWeight:'300'}}>
// 								{this.state.entryModified.map((modifiedword, m)=>
// 									<span>
// 									{m > 0 ?
// 										', '
// 										:
// 										null
// 									}
// 									{this.state.entry[3].length == 1 ?
// 										(modifiedword.split('>').map((q,index) =>
// 											<span style={{color:(modifiedword.split(">").length-1 == index ?'#852828':'#000000')}}>{q}</span>
// 											))
// 										:
// 										<Dropdown inline scrolling onChange={this.changeActiveUsageKey.bind(this)} text={(modifiedword.split('>').map((q,index) => <span style={{color:(modifiedword.split(">").length-1 == index ?'#852828':'#000000')}}>{q}</span>))} value={this.state.activeKeyInEditIndex} options={this.state.baseOptions} />
// 									}
// 									</span>
// 									)}
// 								{showAudio ?
// 									<Icon circular onClick={()=>this.repeatAudio(this.state.audioMatrix[ind])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
// 									:
// 									null
// 								}
// {/*								<Link to={{pathname: '/wordbuilder/' + this.state.word, state: {audioMatrix:this.state.audioMatrix, activeKeyInEditIndex:this.state.activeKeyInEditIndex, fullDefinition: this.state.fullDefinition, word: this.state.entry[4], num: this.state.ind, usageDefinition: this.state.entry[5], baseCase:this.state.definition[this.state.ind][3][this.state.activeKeyInEditIndex], nounvalue1: this.state.nounvalue1, nounvalue2: this.state.nounvalue2, value1: this.state.value1, value2: this.state.value2, }}}>
// 									<Icon circular style={{marginLeft:(showAudio ? '5px': '15px'),fontSize:'18px',color:'#B1B1B1',cursor:'pointer'}} name='pencil' />
// 								</Link>*/}

// 								</div>
// 								<div style={{marginTop:'12px', marginBottom:'22px',marginLeft:'45px',fontSize:'18px',color:'#0D0D0D',fontWeight:'300'}}>
// 								<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setTransitive.bind(this,this.state.activeEditIndex,this.state.definition,false,'','')} value={this.state.value1} options={this.state.options1} />
// 								<span style={{color:'#777777'}}>{this.getSubjectIs(this.state.value1[1],this.state.value1[2])}</span>

// 								<span style={{color:'#777777'}}>{this.processStyledText(this.state.leftOfVerb)}</span>
// 								<span style={{color:'#777777'}}>{this.processStyledText(this.state.primaryVerb)}</span>
// 								<span style={{color:'#777777'}}>{this.processStyledText(this.state.rightOfVerb)}</span>

// 								{this.state.objectPossessed ?
// 									<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setTransitive.bind(this,this.state.activeEditIndex,this.state.definition,false,'','')} value={this.state.value2} options={this.state.options3} />
// 									:
// 									<Dropdown inline scrolling style={{border:'solid 1px #22242626',color:'#852828',fontSize:'18px',fontWeight:'300',padding:'5px',borderRadius:'5px',marginRight:'4px'}} onChange={this.setTransitive.bind(this,this.state.activeEditIndex,this.state.definition,false,'','')} value={this.state.value2} options={this.state.options2} />
// 								}
// 								<span style={{color:'#777777'}}>{this.state.transitiveRightOfObject}</span>
// 								</div>

// 								{this.state.definition[this.state.ind][7].includes("transitional base") ?
// 									<div style={{marginLeft:'45px',paddingBottom:'10px'}}>
// 										<span style={{fontSize:'14px',fontStyle:'italic',color:'#8c8c8c',fontWeight:'400',lineHeight:'23px'}}>Also immediate past tense</span>
// 									</div>
// 									:
// 									null
// 								}

// 								<Link to={{pathname: '/wordbuilder/' + this.state.word, state: {audioMatrix:this.state.audioMatrix, activeKeyInEditIndex:this.state.activeKeyInEditIndex, fullDefinition: this.state.fullDefinition, word: this.state.entry[4], num: this.state.ind, usageDefinition: this.state.entry[5], baseCase:this.state.definition[this.state.ind][3][this.state.activeKeyInEditIndex], nounvalue1: this.state.nounvalue1, nounvalue2: this.state.nounvalue2, value1: this.state.value1, value2: this.state.value2, }}}>
// 									<Button basic compact style={{paddingRight:8,paddingLeft:8,paddingTop:8,paddingBottom:8,marginLeft:'45px',fontSize:'16px',fontWeight:'300',marginBottom:'20px'}}>
// 									<span style={{fontFamily:customFontFam}}>
// 									Try Word Builder
// 									</span>
// 									<Icon name='chevron right' />
// 									</Button>
// 								</Link>


// 							</div>		
// 						)			
// 		}
// 	}


// 	processStyledText = (sentence) => {			
// 		sentence = sentence.replace("⟨","").replace("⟩","")
// 		let matches = sentence.match(/⎡.*?⎤/g)
// 		let matches1 = sentence.match(/{.*?}/g)
// 		if (matches !== null || matches1 !== null) {
// 			if (matches !== null) {
// 				matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))						
// 			}
// 			if (matches1 !== null) {
// 				matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))						
// 			}
// 			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
// 		} else {
// 			return <span>{sentence}</span>
// 		}
// 	}

// 	processStyledText2 = (sentence,ind,tag) => {
// 		// console.log(sentence,ind,tag)
// 		sentence = sentence.replace("⟨","").replace("⟩","")
// 		if (tag == 'NOUN') {
// 			sentence = sentence
// 		} else {
// 		  if (ind % 2 == 0) {
// 			sentence = 'she is '+sentence 			
// 			} else {
// 			sentence = 'he is '+sentence
// 			}			
// 		}
// 		let matches = sentence.match(/⎡.*?⎤/g)
// 		if (matches !== null) {
// 			matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))		

// 			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
// 		} else {
// 			return <span>{sentence.replace("<","").replace(">","")}</span>
// 		}
// 	}

  	processStyledText = (sentence) => {
      // sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
  	}


    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","").replaceAll("↞","").replaceAll("&","").replaceAll("↠","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }

  repeatAudio(audio, event, data) {

    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replace('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }

  usageCalls(usageDetails,properties) {
  	let tag = usageDetails[0]
		let index = usageDetails[1]
		
		// backend calls for usage
		if (tag === 'v') { // verb
		  let verbBase = usageDetails[2]
		  let verbTrans = usageDetails[3]
		  if (index === 0) {
		    	// '>+V+Ind+Prs+3Sg'
		    	return [['Insert',['mv',], [verbBase, verbTrans, 'Ind+Prs']],]
			} else if (index === 1) {
			  // '>+V+Ind+Pst+3Sg'
				if (properties.includes('non-human subject')) {
					// it subject
			    return [['Insert',['mv',], [verbBase, verbTrans, 'Ind+Pst']],]
				} else {
					// she subject
			    return [['Insert',['mv',], [verbBase, verbTrans, 'Ind+Pst']],['Update',['mv','vs'], [3,1,2]],]
				}
			} else if (index === 2) {
			    // '>+V+Int+3Sg'
			    return [['Insert',['mv',], [verbBase, verbTrans, 'Int', 'Intrg']],]
			} else if (index === 3) {
			    // '>+V+Opt+3Sg'
				if (properties.includes('non-human subject')) {
					// it subject
			    return [['Insert',['mv',], [verbBase, verbTrans, 'Opt']],]
				} else {
					// she subject
			    return [['Insert',['mv',], [verbBase, verbTrans, 'Opt']],['Update',['mv','vs'], [3,1,2]],]
				}
			} else if (index === 4) {
			    // '>+V+Imp+2Sg'
			    return [['Insert',['mv',], [verbBase, verbTrans, 'Imp']],]
			}
		} else if (tag == 'n') { // noun
		  let nounBase = usageDetails[2]
			if (index === 0) {
			    // '>+N+Abs+Sg'
			    return [['Insert',['np',], [nounBase, [0,0,0,1], 'Abs']],]
			} else if (index === 1) {
			    // '>+N+Abs+Sg+1Sg'
			    return [['Insert',['np',], [nounBase, [1,1,0,1], 'Abs']],]
			} else if (index === 2) {
			    // '>+N+Loc+Sg'
			    return [['Insert',['np',], [nounBase, [0,0,0,1], 'Loc']],]
			} else if (index === 3) {
			    // una + '>+N+Abs+Sg'
			    if (this.props.word.properties.includes('ext')) {
			    	return [['Insert',['np',], [nounBase, [0,0,0,1], 'Abs']],['Insert',['np','nc'], [[["manna",0,0]], [0,0,0,1]]],]
			    } else {
			    	return [['Insert',['np',], [nounBase, [0,0,0,1], 'Abs']],['Insert',['np','nc'], [[["una",0,0]], [0,0,0,1]]],]
			    }
			    
			}
		}
  }

	render() {

    let returnNewUsageBaseDisplayLabel = {
      'verb phrase':[
        'statement',
        'past statement',
        'question',
        'polite command',
        'command',
      ],
      'noun':[
        'stem',
        'possessive',
        'locative noun case',
        'with pronoun',
      ],
    }

    let word = this.props.word
    let state = this.props.currentState
    let props = this.props.currentProps
    let sentenceBuilder = this.props.sentenceBuilder
    // console.log(this.props)
		// console.log(this.props)


    return  <List.Item style={{paddingBottom:'15px',paddingTop:'15px'}}>
              <List.Content style={{fontSize:'16px',paddingBottom:'10px'}}>
              	{ word.properties.includes('half transitive') ?
              		('halftransitiveKeys' in word ? 
              			<span style={{paddingRight:'15px',fontStyle:'italic'}}>{word.halftransitiveKeys}</span>
              			:
              			null
              		)
              		:
              		<span style={{paddingRight:'15px',fontStyle:'italic'}}>{word.url.replaceAll(',',', ')}</span>
              	}
              	<span style={{marginRight:'15px',cursor:'pointer'}}><TagColors tags={word.t} /></span>
              	{this.props.width < 600 ?
	              	<div style={{fontStyle:'italic', padding:'10px 0px 0px 0px',color:'#262626'}}>{this.processStyledText(word.thisDefinition)}</div>
              		:
	              	<span style={{fontStyle:'italic'}}>{this.processStyledText(word.thisDefinition)}</span>
              	}
              </List.Content>
              {word.newUsageDefinitions.map((u,uindex)=>
              (word.properties.includes('elemental') && (uindex == 3 || uindex == 4) ?
              null
              :
              <List.Content style={{paddingLeft:(this.props.width < 600 ? '10px':'20px')}}>
              	{sentenceBuilder ?
		              <List.Content onClick={()=>{scroll.scrollTo(0); this.props.newBackEndCall(this.usageCalls(word['backendCalls'][uindex],word.properties))}} floated='right'>
		                <Icon circular style={{cursor:'pointer',marginTop:'3px', color:'#8f8f8f'}} name='chevron right' />
		              </List.Content>
              		:
	                <Link to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:this.usageCalls(word['backendCalls'][uindex],word.properties),usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList,state.Yposition, state.activeTabIndex] }}}>
	                  <List.Content floated='right'>
	                    <Icon circular style={{cursor:'pointer',marginTop:'3px', color:'#8f8f8f'}} name='chevron right' />
	                  </List.Content>
	                </Link>
	              }
                {this.props.width < 600 ?
	                <List.Content style={{fontSize:'16px',paddingBottom:'3px',paddingTop:'4px',fontFamily:customFontFam,lineHeight:'25px'}}>
	                  <div>
	                  <div style={{display:'flex','paddingRight':'3px',fontWeight:'500'}}>
              			{sentenceBuilder ?
		                  <span onClick={()=>{scroll.scrollTo(0);this.props.newBackEndCall(this.usageCalls(word['backendCalls'][uindex],word.properties))}} style={{cursor:'pointer',borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}}>
		                  {this.processStyledTextLink(word.usageWords[uindex])}
		                  </span>
		                  :
		                  <Link style={{cursor:'pointer',borderBottom:'1px solid #858585',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:this.usageCalls(word['backendCalls'][uindex],word.properties),usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList, state.Yposition, state.activeTabIndex] }}}>
		                  {this.processStyledTextLink(word.usageWords[uindex])}
		                  </Link>
		                }
	                  {word.audioMatrix[uindex] ?
	                    <Icon circular onClick={()=>this.repeatAudio(word.audioMatrix[uindex])} style={{cursor:'pointer',marginLeft:'7px',fontSize:'14px',color:'#4e7498'}} name='volume up' />
	                    :
	                    null
	                  }
	                  <span style={{flex:3,fontFamily:customFontFam,fontWeight:'400',color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
	                  </div>
	                  </div>
	                  <div style={{fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'2px'}}>{this.processStyledText(word.newUsageDefinitions[uindex])}</div>

	                </List.Content>
	                :
	                <List.Content style={{display:'flex',fontSize:'16px',paddingBottom:'5px',paddingTop:'5px',fontFamily:customFontFam,lineHeight:'25px'}}>
	                  <span style={{flex:3,'paddingRight':'3px',fontWeight:'500'}}>
              			{sentenceBuilder ?
		                  <span onClick={()=>{scroll.scrollTo(0);this.props.newBackEndCall(this.usageCalls(word['backendCalls'][uindex],word.properties))}} style={{cursor:'pointer',borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}}>
		                  {this.processStyledTextLink(word.usageWords[uindex])}
		                  </span>
		                  :
		                  <Link style={{cursor:'pointer',borderBottom:'1px solid #858585',paddingBottom:'1px',color:'#306190'}} to={{pathname: '/wordbuilder/' + word.url, state: {initBackendCall:this.usageCalls(word['backendCalls'][uindex],word.properties),usagedictionary_dict: word.usagedictionary_dict, activeKeyInEditIndex:0, value1: 's31-1(1)',value2: 'o31-3(2)', nounvalue1: '1', nounvalue2: '00(3)', fullDefinition: word.fullDefinition, word: word.usageWord, num: word.num, usageDefinition: word.usageDefinition, from: props.location.pathname, history:[state.search,state.wordsList, state.Yposition, state.activeTabIndex] }}}>
		                  {this.processStyledTextLink(word.usageWords[uindex])}
		                  </Link>
		                }
	                  {word.audioMatrix[uindex] ?
	                    <Icon circular onClick={()=>this.repeatAudio(word.audioMatrix[uindex])} style={{cursor:'pointer',marginLeft:'7px',fontSize:'14px',color:'#4e7498'}} name='volume up' />
	                    :
	                    null
	                  }
	                  </span>
	                  <span style={{flex:7,fontSize:'16px',color:'#000000cc',fontWeight:'400',lineHeight:'23px',paddingTop:'2px'}}>{this.processStyledText(word.newUsageDefinitions[uindex])}</span>
	                  <span style={{flex:4,color:'#BEBEBE',fontStyle:'italic',textAlign:'right'}}>{returnNewUsageBaseDisplayLabel[word.t][uindex]}</span>
	                </List.Content>
	              }
              </List.Content>
              )
              )}
				{word.properties.includes('elemental') ?
					<div style={{marginTop:'20px',fontStyle:'italic',fontSize:'16px',lineHeight:'20px',color:'grey',marginBottom:0}}> This weather/elemental stem can also take transitive endings, with "weather" acting as the agent.
					<Popup position='bottom center' on='click' content={
	                          <div style={{fontSize:'12pt',color:'#737373'}}>
	                            <div style={{marginBottom:'5px', textDecoration:'underline'}}>For example:</div>
	                            <div>sialuktuq "it is raining"</div>
	                            <div>sialukkaa "it (the weather) is raining on it"</div>
	                          </div>
					} trigger={<Label size='mini' style={{cursor:'pointer',fontSize:'11px',backgroundColor:'#f3f3f3',marginLeft:'5px',whiteSpace:'nowrap'}}><Icon style={{fontSize:'10px',margin:0,padding:0}} name='info' /></Label>} />
					</div>
					:
					null
				}

            <Link to={{pathname: '/endingtables', state: {newBase:word.backendCalls[0][2][0], newBaseCall:[word.backendCalls[0][2][0]], tag:word.tag, thisDefinition:word.thisDefinition,baseVerbNoun:(word.tag == 'n' ? ['n'] : ['v']), baseType:[word.tag], from: props.location.pathname, history:[state.search,state.wordsList,state.Yposition, state.activeTabIndex] }}}>
              <div style={{display:'flex',justifyContent:'flex-end',paddingTop:'15px'}}><Button basic>See other ending types (isut)</Button></div>
            </Link>		

			
            </List.Item>  
	}
}
export default NewUsageBaseDisplay;