import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import { TagColors } from './HomeHelper.js';
import NewUsageBaseDisplay from './NewUsageBaseDisplay.js'
import {nounoptions1, nounoptions2, options1, options2, options3} from './constants/constants.js'
import {appendixInformation} from './constants/appendixConstants.js'

import SimpleWordBuilder from './SimpleWordBuilder.js';
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")

var scroll    = Scroll.animateScroll;
let customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

class Entry extends Component {
	constructor(props) {
		super(props);
		// console.log(props)
		this.state = {
			show: false,
			word: decodeURI(props.match.params.word),
			history: props.location.state === undefined ? [] : props.location.state.history,
			from: props.location.state === undefined ? '' : props.location.state.from,
			entryData: {},
			keySplit: [],
			definition: [],
			key: '',
			verbalIllustrations: [],
			etymology: [],
			parent: '',
			appendix: [],
			children: [],
			childrenEntries: {},
			relatedWords: {},
			relatedWordsEntries: {},
			parentEntries: {},
			postbaseEntries: [],
			halfTransitive: [],
			halfTransitiveEtymology: [],
			postbaseExamples: [],
			audioMatrix: [],
			exampleAudioMatrix: [],
			extraInfo: [],
      entryModified: [],
      activeEditIndex:-1,
      literalTranslation:[],
      baseOptions:[],
      activeKeyInEditIndex:0,
      nounMood:'Absolutive',
      verbMood:'Indicative',
      fstCall:'',
      irregular:[],
      extraInfo:[],
      objectPossessed:false,
      loaded:false,
      entryUsageData: props.usagedictionary_dict[decodeURI(props.match.params.word)] === undefined ? {} : this.props.usagedictionary_dict[decodeURI(props.match.params.word)],
		}
	    this.getWord = this.getWord.bind(this);
	    // this.getWord(decodeURI(props.match.params.word));
	    scroll.scrollTo(0);
	}

	componentDidMount(){
		this.getWord(decodeURI(this.props.match.params.word));
	}

  componentDidUpdate(prevProps, prevState) {
    if (prevState.key !== this.state.key) {
      this.getWord(this.state.key);
    }

    else if (prevProps.match.params.word !== this.props.match.params.word) {
    	this.getWord(decodeURI(this.props.match.params.word));
    }
  }


  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({ 
			from: nextProps.location.pathname,
			// fullDefinition: nextProps.fullDefinition,
		})
	}


  getWord(word) {
  	scroll.scrollTo(0);
    // axios
    //   .get(API_URL + "/inupiaqusage/" + encodeURIComponent(word))
    //   .then(response => {
    //     this.setState({
    //       entryUsageData: response.data,
    //     });
    //   })
    let entryUsageData = {}
    if (this.props.usagedictionary_dict[decodeURI(this.props.match.params.word)] !== undefined) {
    		entryUsageData = this.props.usagedictionary_dict[decodeURI(this.props.match.params.word)]
    }
    		

    axios
      .get(API_URL + "/inupiaqword/" + encodeURIComponent(word))
      .catch(error => {
      	// console.log(error,this.props)
		    return this.props.history.push('/')
		  })
      .then(response => {
      	if (response !== undefined) {
	        // console.log(response.data);
	        this.setState({
	          word:word,
	          appendix: response.data.appendix,
	          entryData: response.data,
	          keySplit: response.data.keySplit,
	          definition: response.data.definition,
	          verbalIllustrations: response.data.verbalIllustrations,
	          etymology: response.data.etymology,
	          parent: response.data.parent,
	          children: response.data.children,
	          childrenEntries: response.data.childrenEntries,
	          relatedWords: response.data.relatedWords,
	          relatedWordsEntries: response.data.relatedWordsEntries,
	          extraInfo: response.data.extraInfo,
	          parentEntries: response.data.parentEntries,
	          postbaseEntries: response.data.postbaseEntries,
	          postbaseExamples: response.data.postbaseExamples,
	          irregular: response.data.irregular,
	          extraInfo: response.data.extraInfo,
	          fullDefinition: response.data.fullDefinition,
	          audioMatrix: response.data.audioMatrix,
	          exampleAudioMatrix: response.data.exampleAudioMatrix,
	          halfTransitive: response.data.halfTransitive,
	          halfTransitiveEtymology: response.data.halfTransitiveEtymology,
	          literalTranslation: response.data.literalTranslation,
	          entryUsageData: entryUsageData,
	        },()=>{
	        	this.setState({loaded:true})
	        })      		
      	}
      });
  }

	parentEntry = () => {
		  return <List.Item style={{padding:7}}>
		    <Link to={{pathname: '/' + this.state.parentEntries[this.state.parent[0]]['url'], state: { key: this.state.parentEntries[this.state.parent[0]]['keySplit'], from: this.props.location.pathname }}}>
          <List.Content floated='right'>
            <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
          </List.Content>
		    </Link>
		      <List.Content  style={{paddingRight:'16px'}}>
		        <List.Header style={{fontSize:'18px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
		          {this.state.parentEntries[this.state.parent[0]]['keySplit'].map((w,index) =>
		              <div style={{display:'flex'}}>
		    					<Link style={{color:'#306190'}} to={{pathname: '/' + this.state.parentEntries[this.state.parent[0]]['url'], state: { key: this.state.parentEntries[this.state.parent[0]]['keySplit'], from: this.props.location.pathname  }}}>

							      <span style={{'paddingRight':'3px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
		              <span style={{fontWeight:'400'}}>
		    					{this.state.parent[0].includes('perhaps') ? <span style={{color:'grey',fontSize:'16px',fontStyle:'italic','paddingRight':'3px'}} >{'perhaps '}</span> : null}
		              </span>
			              {this.processStyledTextLink(w[0])}
			              </span>
		              </Link>


		              {w[2] !== [""] ?
		                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  

				              {w[1][0] !== '' ?
				                <span style={{marginRight:'5px',cursor:'pointer'}}>
				                  <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
				                </span>
				                :
				                ''
				              }  

		                  {w[2].map((tag) => 
		                    <span style={{marginRight:'5px',cursor:'pointer'}}>
		                      <TagColors tags={tag} width={window.innerWidth} />
		                    </span>
		                    )}
		                </span>
		                :
		                ''
		              }
		              </div>
		            )}
		        </List.Header>
		        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(this.state.parentEntries[this.state.parent[0]]['fullDefinition'])}</List.Description>
		      </List.Content>
		    </List.Item>
		}

	thisEntry = () => {
		  return <List.Item style={{padding:7}}>
		      <List.Content  style={{paddingRight:'16px'}}>
		        <List.Header style={{fontSize:'18px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
		          {this.state.keySplit.map((w,index) =>
		              <div style={{display:'flex'}}>

							      <span style={{'paddingRight':'3px',fontWeight:'400',paddingBottom:'2px',}}>
		              <span style={{fontWeight:'400'}}>
		              </span>
			              {this.processStyledTextLink(w[0])}
			              </span>


		              {w[3] !== [""] ?
		                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  

				              {w[2][0] !== '' ?
				                <span style={{marginRight:'5px',cursor:'pointer'}}>
				                  <TagColors tags={w[2].join(', ')} width={window.innerWidth} />
				                </span>
				                :
				                ''
				              }  

		                  {w[3].map((tag) => 
		                    <span style={{marginRight:'5px',cursor:'pointer'}}>
		                      <TagColors tags={tag} width={window.innerWidth} />
		                    </span>
		                    )}
		                </span>
		                :
		                ''
		              }
		              </div>
		            )}
		        </List.Header>
		        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
		        	{this.processStyledText(this.state.definition.filter(x => x[2] === "(t) TRANSITIVE VERB").map(x => x[0]).join(", "))}
		        </List.Description>
		      </List.Content>
		    </List.Item>
		}

	processStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","").replace("«","").replace("»","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/{.*?}/g)
		let matches2 = sentence.match(/_.*?_/g)
		if (matches !== null || matches1 !== null || matches2 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))						
			}
			if (matches2 !== null) {
				matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}

    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


	processUnderlyingStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/\^.*?\^/g)
		let matches2 = sentence.match(/_.*?_/g)
		if (matches !== null || matches1 !== null || matches2 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>['+m.slice(1,-1)+']</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
			}
			if (matches2 !== null) {
				matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}

	processPostbaseExampleRow = (sentence) => {     
    let matches = sentence.match(/\`.*?(\`|\')/g)
    let matches2 = sentence.match(/\(.*?\)/g)

    // console.log(matches2)
    if (matches !== null) {
      matches.map((m) => sentence = '<b>'+sentence.replace(m,'<span style="font-weight:normal">'+m.slice(1,-1)+'</span>')+'</b>')
    }      
    if (matches2 !== null) {
      matches2.map((m) => sentence = sentence.replace(m,'<span style="font-weight:normal"><i>'+m+'</i></span>'))      
    }
    if (matches !== null || matches2 !== null) {
      return <span dangerouslySetInnerHTML={{__html: sentence}} />          
    } else {
      return <span>{sentence}</span>
    }
  }

  processPostbaseTableRowHTML = (sentence) => {
		let matchesB = sentence.match(/\{.*?\}(?!\w)/g)
    let matchesI = sentence.match(/⎡.*?⎤/g)
		let matchesSup = sentence.match(/\^.*?\^/g)
		let matchesSub = sentence.match(/_.*?_/g)
		if (matchesB !== null || matchesI !== null || matchesSup !== null || matchesSub !== null) {
			if (matchesB !== null) {
				matchesB.map((m) => sentence = sentence.replaceAll(m,'<b>'+m.slice(1,-1)+'</b>'))								
			}
			if (matchesI !== null) {
				matchesI.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matchesSub !== null) {
				matchesSub.map((m) => sentence = sentence.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			if (matchesSup !== null) {
				matchesSup.map((m) => sentence = sentence.replaceAll(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
			}
			return sentence
		} else {
			return sentence
		}
  }

  processPostbaseTableRow = (sentence) => {     
		let matchesBnL = sentence.match(/\{(.*?)\}⟨(.*?)⟩/g)
		let splitSentence = []
		let sentenceBank = []
		let restOfSentence = sentence
		if (matchesBnL !== null) {
				let before = ''
				let after = ''
				matchesBnL.map((m) => {
					before = restOfSentence.slice(0, restOfSentence.indexOf(m));
					after = restOfSentence.slice(restOfSentence.indexOf(m)+m.length + 1);
					sentenceBank.push(before)
					restOfSentence = after
				})
				sentenceBank.push(after)
				splitSentence = sentence.split(matchesBnL[0])
				return <span>
		 		<span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(sentenceBank[0])}} />

			 		{matchesBnL.map((k,index)=><span>			 			
					<Link style={{color:'#306190'}} to={{pathname: k.match(/⟨.*?⟩/g)[0].slice(1,-1)}} onClick={()=>{this.setState({key:k.match(/⟨.*?⟩/g)[0].slice(1,-1), from:this.props.location.pathname})}}><b>{k.match(/\{.*?\}/g)[0].slice(1,-1)}</b></Link>
					{' '}
			 		<span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(sentenceBank[index+1])}} />			
			 		</span>	 			
		 		)}
				</span>
		} else {
		 	return <span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(this.processPostbaseTableRowHTML(sentence))}} />		
		}
  }

  // processPostbaseTableRow = (sentence) => {     
		// let matchesBnL = sentence.match(/\{(.*?)\}⟨(.*?)⟩/g)
		// let matchesB = sentence.match(/\{.*?\}(?!\w)/g)
  //   let matchesI = sentence.match(/⎡.*?⎤/g)
		// let matchesSup = sentence.match(/\^.*?\^/g)
		// let matchesSub = sentence.match(/_.*?_/g)
		// if (matchesBnL !== null || matchesB !== null || matchesI !== null || matchesSup !== null || matchesSub !== null) {
		// 	// if (matchesBnL !== null) {
		// 	// 	matchesBnL.map((m) => {
		// 	// 		sentence = sentence.replaceAll(m,
		// 	// 		"<Link style={{color:'#306190'}} to={{pathname: '/' +"+m.match(/⟨.*?⟩/g).slice(1,-1)+"}} onClick={()=>{this.setState({key:"+m.match(/⟨.*?⟩/g).slice(1,-1)+", from:"+this.props.location.pathname+"})}}><b>"+m.match(/{.*?}/g).slice(1,-1)+"</b></Link>"
		// 	// 			)
		// 	// 	})								
		// 	// }
		// 	if (matchesB !== null) {
		// 		matchesB.map((m) => sentence = sentence.replaceAll(m,'<b>'+m.slice(1,-1)+'</b>'))								
		// 	}
		// 	if (matchesI !== null) {
		// 		matchesI.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))						
		// 	}
		// 	if (matchesSub !== null) {
		// 		matchesSub.map((m) => sentence = sentence.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
		// 	}
		// 	if (matchesSup !== null) {
		// 		matchesSup.map((m) => sentence = sentence.replaceAll(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
		// 	}
		// 	return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		// } else {
		// 	return <span>{sentence}</span>
		// }
  // }

  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replace('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }



	render() {
		// console.log(this.state)
		let postbaseTableOn = false
    let postbaseExampleOn = false
		// console.log(this.props.location)
		// console.log(this.props)
		return (
			<div style={{fontFamily:customFontFam}}>
			<Transition visible={this.state.loaded} animation='fade' duration={300}>
      <Grid textAlign='center'>
      <Grid.Row  style={{height:40,paddingBottom:0}}>
      <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					

					<div style={{fontSize:'23px',marginTop:'20px',fontFamily:customFontFam}}>
					{this.state.keySplit.map((w) => 
						<div>
          		<div style={{display:'flex',paddingBottom:'5px'}}> 
              <span style={{fontWeight:'500',lineHeight:'30px'}}>
              {this.processStyledTextLink(w[0])}
              </span>
              {w[3] !== [""] ?
                <span style={{'marginLeft':'10px',marginRight:'6px'}}>
		              {w[2][0] !== '' ?
		                <span style={{marginRight:'5px',cursor:'pointer'}}>
		                  <TagColors tags={w[2].join(', ')} width={-1} />
		                </span>
		                :
		                ''
		              }                  
		              {w[1] != '' && window.innerWidth > 480  ?
			              <span style={{marginRight:'10px',fontFamily:"Times New Roman",fontStyle:'italic',lineHeight:'30px'}}>
			              {this.processUnderlyingStyledText(w[1])}
			              </span>
		              :
		              null
		            	}
									{w[3].map((tag) => 
										<span style={{marginRight:'5px',cursor:'pointer'}}>
											<TagColors tags={tag} width={-1} />
										</span>
										)}
                </span>
                :
                ''
              }
              </div>
              {w[1] != '' && window.innerWidth < 481  ?
	              <div style={{paddingBottom:'5px',fontFamily:"Times New Roman",fontStyle:'italic',lineHeight:'30px'}}>
	              {this.processUnderlyingStyledText(w[1])}
	              </div>
              :
              null
            	}
              </div>
						)}
						</div>

					{this.state.irregular !== [] ?
						<div style={{fontStyle:'italic',fontSize:'18px',marginTop:'5px',color:'#000000cc'}}>
							{this.state.irregular.map((k)=>
								<div style={{marginBottom:'8px'}}>
								{k.replace("⎡","[").replace("⎤","]").replace('pl.','plural').replace("⎡or⎤","or")+'\n\n'}
								</div>
								)}
						</div>
						:
						null
					}



					<div style={{border:'1px solid #E3E3E3',marginTop:'10px'}}>

					<div className='hierarchymain'>
					<span className='span1'>Sivuniŋa Uqaluum</span>
					<span className='span2'>Definition</span>
					</div>

					<div style={{marginTop:'17px',marginBottom:'18px'}}>
					{this.state.definition.map((d,ind) => 
						<div>
						<div style={{display:'flex'}}>
						<span style={{marginLeft:'10px',color:'#777777',fontSize:'20px','fontWeight':'300'}}>{'○'}</span>
						<span style={{marginLeft:'20px',color:'#000000',fontSize:'20px',lineHeight:'24px'}}>
						{d[1].length > 0 ?
							(d[1][0].length > 0 ?
								<span style={{marginRight:'10px',verticalAlign:'3px',color:'#0D0D0D',fontSize:'15px'}}>
									{d[1].map((tag) => 
										<span style={{marginRight:'5px',cursor:'pointer'}}>
			 								<TagColors tags={tag} width={-1} />
			 							</span>
		 							)}
								</span>
								:
								null
							)						
							:
							null
						}

						{this.processStyledText(d[0])}
						</span>
						</div>


						{this.state.definition[ind][2].length > 0 ?
						<div style={{marginTop:'6px',marginBottom:'15px',marginLeft:'45px',fontSize:'11px',color:'#A7A7A7'}}>
							<span style={{marginRight:'5px',cursor:'pointer'}}>
 								<TagColors tags={this.state.definition[ind][2]} width={-1} />
 							</span>
 							{this.state.definition[ind][7].includes('half transitive') ?
 								<span style={{cursor:'pointer'}}>
 									<TagColors tags={'half transitive'} width={-1} />
 								</span>
 							:
 								null
 							}
						</div>
						:
						(this.state.definition.length-1 !== ind ?
							<div style={{height:'10px'}} />
							:
							null
						)
						}
						{this.state.halfTransitive.length > 0 && this.state.definition[ind][7].includes('half transitive') ?
							<div style={{marginLeft:'45px',fontSize:'16px',color:'grey',marginBottom:'18px'}}> {'half-transitive form: '+this.state.halfTransitive.map(x => x[0]).join(', ')} </div>
							:
							null
						}
						{this.state.literalTranslation.length > 0 ?
							<div style={{marginLeft:'45px',fontSize:'16px',color:'grey',marginBottom:'18px'}}> {this.processStyledText(this.state.literalTranslation.map(x => x).join(', '))}</div>
							:
							null
						}
						</div>

						)}
				</div>

				{Object.keys(this.state.entryUsageData).length !== 0 ?
					<div style={{marginTop:'24px'}}>
    					<div className='hierarchymain'>
    					<span className='span1'>Isuliqługu Maŋŋuk</span>
    					<span className='span2'>With Endings</span>
    					</div> 
    					<List style={{marginLeft:20,marginRight:7,marginTop:4}} divided>
    					{Object.keys(this.state.entryUsageData).map((i)=>
                       <NewUsageBaseDisplay sentenceBuilder={false} currentProps={this.props} currentState={this.state} word={this.state.entryUsageData[i]} width={window.innerWidth} />
              			)}
							</List>
					</div>
					:
					null
				}

					
					{ "demLink" in this.state.entryData ?
						<div>
							<div className='hierarchymain'>
								<span className='span1'>Tikkuaġautim Maŋŋua</span>
								<span className='span2'>Demonstrative Stem</span>
							</div>
							<div style={{marginTop:'18px',marginBottom:'18px'}} className='extrainformation'>
								<span>{'See demonstrative table: '}</span>
			          <Link style={{color:'#306190'}} to={{pathname: '/demonstratives/' + this.state.entryData.demLink[0], state: { history:this.state.history, demTableType:[this.state.entryData.demLink[1],this.state.entryData.demLink[2]]}}}>
			            <span style={{'marginRight':'10px','paddingRight':'5px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
			            {this.processStyledTextLink(this.state.entryData.demLink[0])}
			            </span>
			          </Link>
							</div>
						</div>


		           :
		           null
          }


					{this.state.extraInfo.length !== 0 || ("restrictions" in this.state.entryData && Object.keys(this.state.entryData.restrictions).length !== 0) ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Allat Suli Iḷisimaraksratin</span>
							<span className='span2'>Extra Information</span>
							</div>
							{this.state.extraInfo.map((d) => 
								<div style={{marginTop:'18px',marginBottom:'18px'}} className='extrainformation'>{this.processStyledText(d)}</div>
							)}
							{'restrictions' in this.state.entryData ?
								(Object.keys(this.state.entryData.restrictions).map((d) => 
									<div style={{marginTop:'18px',marginBottom:'18px'}} className='extrainformation'>{this.processStyledText(d)} {this.processStyledText(this.state.entryData.restrictions[d])}</div>
								))
								:
								null
							}
						</div>
						:
						null
					}


					{this.state.verbalIllustrations.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Uqaluk Urriqun</span>
							<span className='span2'>Example Sentence</span>
							</div>

							{this.state.verbalIllustrations.map((d,ind) => 
								<div style={{marginTop:'18px'}}>



									<div style={{marginTop:'15px',marginLeft:'22px',marginBottom:'8px',fontSize:'20px'}}>
									{this.processStyledText(d[0])}
									{this.state.exampleAudioMatrix[ind].length > 0 ?
										<Icon circular onClick={()=>this.repeatAudio(this.state.exampleAudioMatrix[ind])} style={{marginLeft:'16px',fontSize:'17px',color:'#8F8F8F',cursor:'pointer'}} name='volume up' />
										:
										null
									}
									</div>


									<div style={{marginBottom:'18px',marginLeft:'22px',fontSize:'16px',color:'#000000DE'}}>
									<span>
									{this.processStyledText(d[1])}
									</span>
									<span style={{marginLeft:'4px'}}>
									{this.processStyledText(d[2])}
									</span>
									</div>

								</div>
								)}
						</div>
						:
						null
					}


					{this.state.postbaseExamples.length !== 0 ?
            <div>
              <div className='hierarchymain'>
              <span className='span1'>Postbase Examples</span>
              </div>

              {this.state.postbaseExamples.map((entry,i) => {
                if (postbaseTableOn) {
                  if (entry == '</table>') {
                    postbaseTableOn = false
                  } else {
                    let items = []
                    if (entry.split("\"").length > 1) {
                      entry = entry.replace("\",\"","@@").replace(",\"","@@").replace("\",","@@").replaceAll("\"","")
                      items = entry.split('@@') ;                 
                    } else {
                      items = entry.split(',')
                    }

                    if (items.length !== 1) {
	                    return <div style={{margin:15,fontSize:'16px',color:'#000000cc',display:'flex'}}>
	                            <div style={{flex:1,padding:5,lineHeight:'23px'}}>
	                              {this.processPostbaseTableRow(items[0])}
	                            </div>
	                            <div style={{flex:1,padding:5,lineHeight:'23px'}}>
	                              {this.processPostbaseTableRow(items[1])}
	                            </div>
	                          </div> 
                    } else {
                    	return <div style={{margin:15,fontSize:'16px',color:'#000000cc',display:'flex'}}>
	                            <div style={{flex:1,padding:5,lineHeight:'23px'}}>
	                              {}
	                            </div>
	                            <div style={{flex:1,padding:5,lineHeight:'23px'}}>
	                              {this.processPostbaseTableRow(items[0])}
	                            </div>
	                          </div> 
                    }
                  }
                } else if (postbaseExampleOn) {
                  if (entry == '</example>') { 
                    postbaseExampleOn = false                  
                  } else {
                    return <div style={{display:'flex',lineHeight:'23px'}}>{this.processPostbaseExampleRow(entry)}</div> 
                  }
                } else {
                  if (entry == '<table>') {              
                    postbaseTableOn = true
                  } else if (entry == '<example>') { 
                    postbaseExampleOn = true       
                    return <div style={{margin:15,fontSize:'16px',lineHeight:'23px'}}><span style={{fontStyle:'italic'}}>{'Example Sentences'}</span></div>           
                  } else {
                    return <div style={{margin:20,fontSize:'16px',lineHeight:'23px'}}><span>{this.processPostbaseTableRow(entry)}</span></div>                                                    
                  }               
                }
              })}
            </div>
            :
            null
          }

					{this.state.postbaseEntries.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Imaŋi Uqaluum</span>
							<span className='span2'>Etymology</span>
							</div>
							{this.state.postbaseEntries.map((p,pind) => 
							<div>
								{this.state.postbaseEntries.length > 1 ?
									<div className='hierarchymain2'>
									<span className='span1'>{'Etymology '+(pind+1)}</span>
									</div>		
									:
									null
								}						
								<List style={{marginTop:0}} >
								{Object.keys(this.state.parentEntries).length !== 0 ? 
									(this.state.word.includes(' ') && pind != 0 ?
										null
										:
										this.parentEntry() 
										)
									: 
									null
								}
								{this.state.postbaseEntries[pind].map((u,uind) =>
									(Object.keys(this.state.postbaseEntries[pind][uind]).map((v,vind) =>
								    <List.Item style={{padding:7,paddingLeft:15+15*(uind+1)-((this.state.word.includes(' ') && pind != 0) || this.state.keySplit[0][3].includes('postbase') ? 15 : 0)}}>
								      {'keySplit' in this.state.postbaseEntries[pind][uind][v] ?
								      <Link to={{pathname: '/' + this.state.postbaseEntries[pind][uind][v]['key']}} onClick={()=>{this.setState({key:this.state.postbaseEntries[pind][uind][v]['key'], from: this.props.location.pathname})}}>
			                  <List.Content floated='right'>
			                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
			                  </List.Content>
									    </Link>
									    :
													null
											}
								      <List.Content  style={{paddingRight:'16px'}}>
								        <List.Header style={{fontSize:'18px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px',fontWeight:'400'}}>
								        {'keySplit' in this.state.postbaseEntries[pind][uind][v] ?
								        <Link style={{color:'#306190'}} to={{pathname: '/' + this.state.postbaseEntries[pind][uind][v]['key']}} onClick={()=>{this.setState({key:this.state.postbaseEntries[pind][uind][v]['key'], from: this.props.location.pathname})}}>
					              <span style={{'marginRight':'10px','paddingRight':'5px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
					              {this.processStyledText(v)}
					              </span>
					             	</Link>
					             	:
					              <span style={{'marginRight':'10px','paddingRight':'5px',fontWeight:'400',paddingBottom:'2px',}}>
												{this.processStyledText(v)}
					              </span>
												}
								        {'keySplit' in this.state.postbaseEntries[pind][uind][v] ?
													<span style={{marginRight:'5px',cursor:'pointer'}}>
														<TagColors tags={this.state.postbaseEntries[pind][uind][v]['keySplit'][0][3][0]} width={window.innerWidth} />
													</span>
													:
													null
												}
												</List.Header>
								        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
								        {Object.keys(this.state.postbaseEntries[pind][uind][v]).length === 0 ?
								        	null
								        	:
									        this.processStyledText(this.state.postbaseEntries[pind][uind][v]['fullDefinition'])
									      }
								        </List.Description>
								      </List.Content>
								    </List.Item>
									))
								)}
								</List>
							</div>
							)}
						</div>
						:
						(Object.keys(this.state.parentEntries).length !== 0 ? 
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Imaŋi Uqaluum</span>
							<span className='span2'>Etymology</span>
							</div>
							<List style={{marginTop:0}} divided>
							{this.parentEntry()}
							</List>
						</div>
						:
						null
						)
					}

					{this.state.halfTransitiveEtymology.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Imaŋi Uqaluum</span>
							<span className='span2'>Half Transitive Etymology</span>
							</div>
							{this.state.halfTransitiveEtymology.map((p,pind) => 
							<div>
								{this.state.halfTransitiveEtymology.length > 1 ?
									<div className='hierarchymain2'>
									<span className='span1'>{'Etymology '+(pind+1)}</span>
									</div>		
									:
									null
								}						
								<List style={{marginTop:0}} divided>
								{this.thisEntry()}

								{this.state.halfTransitiveEtymology[pind].map((u,uind) =>
									(Object.keys(this.state.halfTransitiveEtymology[pind][uind]).map((v,vind) =>
								    <List.Item style={{padding:7,paddingLeft:15+15*(uind+1)-((this.state.word.includes(' ') && pind != 0) || this.state.keySplit[0][3].includes('postbase') ? 15 : 0)}}>
								      {'keySplit' in this.state.halfTransitiveEtymology[pind][uind][v] ?
								      <Link to={{pathname: '/' + this.state.halfTransitiveEtymology[pind][uind][v]['key']}} onClick={()=>{this.setState({key:this.state.halfTransitiveEtymology[pind][uind][v]['key'], from: this.props.location.pathname})}}>
			                  <List.Content floated='right'>
			                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
			                  </List.Content>
									    </Link>
									    :
													null
											}
								      <List.Content  style={{paddingRight:'16px'}}>
								        <List.Header style={{fontSize:'18px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px',fontWeight:'400'}}>
								        {'keySplit' in this.state.halfTransitiveEtymology[pind][uind][v] ?
								        <Link style={{color:'#306190'}} to={{pathname: '/' + this.state.halfTransitiveEtymology[pind][uind][v]['key']}} onClick={()=>{this.setState({key:this.state.halfTransitiveEtymology[pind][uind][v]['key'], from: this.props.location.pathname})}}>
					              <span style={{'marginRight':'10px','paddingRight':'5px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
					              {this.processStyledText(v)}
					              </span>
					             	</Link>
					             	:
					              <span style={{'marginRight':'10px','paddingRight':'5px',fontWeight:'400',paddingBottom:'2px',}}>
												{this.processStyledText(v)}
					              </span>
												}
								        {'keySplit' in this.state.halfTransitiveEtymology[pind][uind][v] ?
													<span style={{marginRight:'5px',cursor:'pointer'}}>
														<TagColors tags={this.state.halfTransitiveEtymology[pind][uind][v]['keySplit'][0][3][0]} width={window.innerWidth} />
													</span>
													:
													null
												}
												</List.Header>
								        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
								        {Object.keys(this.state.halfTransitiveEtymology[pind][uind][v]).length === 0 ?
								        	null
								        	:
									        this.processStyledText(this.state.halfTransitiveEtymology[pind][uind][v]['fullDefinition'])
									      }
								        </List.Description>
								      </List.Content>
								    </List.Item>
									))
								)}
								</List>
							</div>
							)}
						</div>
						:
						null
					}


					{Object.keys(this.state.relatedWordsEntries).length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Isumaqatauruat Uqaluit</span>
							<span className='span2'>Related Entries</span>
							</div>
							<List style={{marginTop:0}} divided>
							{Object.keys(this.state.relatedWordsEntries).map((key, index) =>
						    <List.Item style={{padding:7}} key={this.state.relatedWordsEntries[key].url}>
						    <Link to={{pathname: '/' + this.state.relatedWordsEntries[key].url}} onClick={()=>{this.setState({key:this.state.relatedWordsEntries[key].url, from: this.props.location.pathname})}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
						    </Link>
						      <List.Content  style={{paddingRight:'16px'}}>
						        <List.Header style={{fontSize:'18px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
						          {this.state.relatedWordsEntries[key].keySplit.map((w,index) =>
						          		<div style={{display:'flex'}}> 
						    					<Link style={{color:'#306190'}} to={{pathname: '/' + this.state.relatedWordsEntries[key].url}} onClick={()=>{this.setState({key:this.state.relatedWordsEntries[key].url, from: this.props.location.pathname})}}>
						              <span style={{'paddingRight':'3px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
						              {this.processStyledTextLink(w[0])}
						              </span>
						             	</Link>
						              {w[2] !== [""] ?
						                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  

								              {w[1][0] !== '' ?
								                <span style={{marginRight:'5px',cursor:'pointer'}}>
								                  <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
								                </span>
								                :
								                ''
								              }   
															{w[2].map((tag) => 
																<span style={{marginRight:'5px',cursor:'pointer'}}>
																	<TagColors tags={tag} width={window.innerWidth} />
																</span>
																)}
						                </span>
						                :
						                ''
						              }
						              </div>
						            )}
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(this.state.relatedWordsEntries[key].fullDefinition)}</List.Description>
						      </List.Content>
						    </List.Item>
              	)}
							</List>

						</div>
						:
						null
					}
					{Object.keys(this.state.childrenEntries).length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Uqaluit Maŋŋuqatauruat</span>
							<span className='span2'>Subentries</span>
							</div>
							<List style={{marginTop:0}} divided>
							{this.state.children.map((key, index) =>
						    <List.Item style={{padding:7}} key={this.state.childrenEntries[key].url}>
						    <Link to={{pathname: '/' + this.state.childrenEntries[key].url}} onClick={()=>{this.setState({key:this.state.childrenEntries[key].url, from: this.props.location.pathname})}}>
                  <List.Content floated='right'>
                    <Icon circular style={{marginTop:'2px', color:'#8f8f8f'}} name='chevron right' />
                  </List.Content>
						    </Link>
						      <List.Content  style={{paddingRight:'16px'}}>
						        <List.Header style={{fontSize:'18px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
						          {this.state.childrenEntries[key].keySplit.map((w,index) =>
						          		<div style={{display:'flex'}}> 
						    					<Link style={{display:'flex',alignItems:'center',color:'#306190'}} to={{pathname: '/' + this.state.childrenEntries[key].url}} onClick={()=>{this.setState({key:this.state.childrenEntries[key].url, from: this.props.location.pathname})}}>
						              <span style={{'paddingRight':'3px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'1px',}}>
						              {this.processStyledTextLink(w[0])}
						              </span>
						             	</Link>

						              {w[2] !== [""] ?
						                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  

								              {w[1][0] !== '' ?
								                <span style={{marginRight:'5px',cursor:'pointer'}}>
								                  <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
								                </span>
								                :
								                ''
								               }

															{w[2].map((tag) => 
																<span style={{marginRight:'5px',cursor:'pointer'}}>
																	<TagColors tags={tag} width={window.innerWidth} />
																</span>
																)}
						                </span>
						                :
						                ''
						              }
						              </div>
						            )}
						        </List.Header>
						        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(this.state.childrenEntries[key].fullDefinition)}</List.Description>
						      </List.Content>
						    </List.Item>
              	)}
							</List>

						</div>
						:
						null
					}



					{this.state.appendix.length !== 0 ?
						<div>
							<div className='hierarchymain'>
							<span className='span1'>Iiguutit Savaamun</span>
							<span className='span2'>Appendices</span>
							</div>
							{this.state.appendix.map((d,ind) => 
								<div style={{marginTop:'20px',marginLeft:'22px',}}>
									<div style={{marginBottom:'20px',fontSize:'16px'}}>
										<span>{'See Appendix '+d+': '}</span>
									<Link to={{pathname: "/appendix/"+d, state: { appendix:d, history:this.state.history }}}>
									<span style={{borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
										<span style={{fontWeight:500, color:'#306190'}}>{this.processStyledText(appendixInformation[d]['inupTitle'])}</span>
										<span>{' - '}</span>
										<span style={{fontStyle:'italic', color:'#306190'}}>{this.processStyledText(appendixInformation[d]['engTitle'])}</span>
									</span>
									</Link>
									</div>
								</div>
								)}
						</div>
						:
						null
					}

					</div>


			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'14px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>

        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:90,paddingBottom:15,paddingTop:25}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>

				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
			</div>
		);
	}
}
export default Entry;