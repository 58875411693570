    
    
    
    
    
    
    
    
    
    
    
    
    
export const  moodDescriptions = {  
"Conditional Mood I":[  
["p",[  
    ["Conditional Mood I is most commonly expressed in English as “when (in the future)” and “if”.","blacksoft"],
]], 
["ex",[ 
    ["iti","black"],
    ["ġu","1stmm"],
    ["mi~","mainsub"],
    ["uqallautiniaġa","black"],
    ["a","mainsub"],
    ["tin","obj"],
],"",[  
    ["when","1stmm"],
    ["he","mainsub"],
    ["wakes up,","black"],
    ["he","mainsub"],
    ["will tell","black"],
    ["you","obj"],
]], 
["ex",[ 
    ["iti","black"],
    ["qpa","1stmm"],
    ["n~","obj"],
    ["uqallautisaġ","black"],
    ["iñ","secondsub"],
],"",[  
    ["when","1stmm"],
    ["he","obj"],
    ["wakes up,","black"],
    ["you","secondsub"],
    ["tell","black"],
    ["him","obj"],
]], 
["ex",[ 
    ["akima","black"],
    ["kpa","1stmm"],
    ["n~","obj"],
    ["quyapalugniaqtu","black"],
    ["ŋa","mainsub"],
],"",[  
    ["if","1stmm"],
    ["he","obj"],
    ["wins,","black"],
    ["I","mainsub"],
    ["will be happy","black"],
]], 
["ex",[ 
    ["iḷisaġi","black"],
    ["kpa","1stmm"],
    ["n","obj"],
    ["ni~","mainsub"],
    ["qimaaniaq","black"],
    ["tuq","mainsub"],
],"",[  
    ["when","1stmm"],
    ["they (3+)","obj"],
    ["recognize","black"],
    ["him,","mainsub"],
    ["he","mainsub"],
    ["will run away","black"],
]], 
["p",[  
    ["This dependent verb is usually paired with an independent verb in the future tense or imperative mood. Its mood markers are ","blacksoft"],
    ["+k/gu- ","1stmm"],
    ["for the 1st, 2nd, and 3rd person reflexive, and ","blacksoft"],
    ["+kpa- ","1stmm"],
    ["for the 3rd person non-reflexive.","blacksoft"],
]], 
["p",[  
    ["The","blacksoft"],
    ["Conditional Mood I","1stmm"],
    ["has an “unrealized” aspect expressed in English as “when (in the future)” and “if” , while the","blacksoft"],
    ["Consequential Mood I","2ndmm"],
    ["has a “realized” aspect expressed in English as “when (in the past)”, and “whenever”.","blacksoft"],
]], 
["exheader",    
    ["a.","blacksoft"],
    ["Conditional:","blacksoft"],
],  
["ex",[ 
    ["ai","black"],
    ["gu","1stmm"],
    ["mi~","mainsub"],
    ["niġiñiaq","black"],
    ["tuq","mainsub"],
],"",[  
    ["when","1stmm"],
    ["he","mainsub"],
    ["comes home,","black"],
    ["he","mainsub"],
    ["will eat","black"],
]], 
["exheader",    
    ["","blacksoft"],
    ["Consequential:","blacksoft"],
],  
["ex",[ 
    ["ai","black"],
    ["ga","2ndmm"],
    ["mi~","mainsub"],
    ["niġirua","black"],
    ["q","mainsub"],
],"",[  
    ["when","2ndmm"],
    ["he","mainsub"],
    ["came home,","black"],
    ["he","mainsub"],
    ["ate","black"],
]], 
["exheader",    
    ["b.","blacksoft"],
    ["Conditional:","blacksoft"],
],  
["ex",[ 
    ["ikayu","black"],
    ["ġu","1stmm"],
    ["m","obj"],
    ["ni~","mainsub"],
    ["Siġmiaq~","mainsub"],
    ["quyaniaqtu","black"],
    ["q","mainsub"],
],"",[  
    ["if","1stmm"],
    ["I","obj"],
    ["help","black"],
    ["him,","mainsub"],
    ["Siġmiaq","mainsub"],
    ["will be thankful","black"],
]], 
["exheader",    
    ["","blacksoft"],
    ["Consequential:","blacksoft"],
],  
["ex",[ 
    ["ikayu","black"],
    ["ġa","2ndmm"],
    ["m","obj"],
    ["ni~","mainsub"],
    ["Siġmiaq~","mainsub"],
    ["quyasuur","black"],
    ["uq","mainsub"],
],"",[  
    ["whenever","2ndmm"],
    ["I","obj"],
    ["help","black"],
    ["him,","mainsub"],
    ["Siġmiaq","mainsub"],
    ["is always thankful","black"],
]], 
    
    
    
    
    
],  
    
"Indicative Mood I":[   
["p",[  
    ["The Indicative Mood expresses a present statement (currently happening or immediate past).","blacksoft"],
]], 
    
["ex",[ 
    ["Tautuktuq.","black"],
],"INU_01838.mp3",[ 
    ["He sees something.","black"],
]], 
    
["ex",[ 
    ["Aŋun tautuktuq tuttumik.","black"],
],"INU_04381.mp3",[ 
    ["The man sees a caribou.","black"],
]], 
    
["ex",[ 
    ["Tautukkaa.","black"],
],"INU_04992.mp3",[ 
    ["He sees it.","black"],
]], 
    
["ex",[ 
    ["Aŋutim tautukkaa tuttu.","black"],
],"",[  
    ["The man sees the caribou.","black"],
]], 
    
["p",[  
    ["The following examples express present (currently happening and the immediate past), future, and past events.","blacksoft"],
]], 
    
["exheader",    
    ["a.","blacksoft"],
    ["Indicative Mood I","blacksoft"],
],  
    
["ex",[ 
    ["Niġiruq.","black"],
],"INU_02900.mp3",[ 
    ["He/she is eating.","black"],
]], 
    
["ex",[ 
    ["Siquruq.","black"],
],"",[  
    ["He/she is falling asleep.","black"],
]], 
    
["exheader",    
    ["b.","blacksoft"],
    ["Indicative Mood I + future postbase","blacksoft"],
],  
    
["ex",[ 
    ["Niġiñiaqtuq.","black"],
],"INU_02771.mp3",[ 
    ["He/she will eat.","black"],
]], 
    
["ex",[ 
    ["Siquniaqtuq.","black"],
],"",[  
    ["He/she will fall asleep.","black"],
]], 
    
["exheader",    
    ["c.","blacksoft"],
    ["Participial Mood I","blacksoft"],
],  
    
["ex",[ 
    ["Niġiruaq.","black"],
],"INU_03438.mp3",[ 
    ["He/she ate.","black"],
]], 
    
["ex",[ 
    ["Siquruaq.","black"],
],"",[  
    ["He/she has fallen asleep.","black"],
]], 
    
["p",[  
    ["The Indicative Mood is an independent verb and can be paired with dependent verb moods.","blacksoft"],
]], 
    
["ex",[ 
    ["Quviasukłuni itiqtuq.","black"],
],"",[  
    ["He woke up happy.","black"],
]], 
    
["ex",[ 
    ["Itiqpan uqallautisaġiñ.","black"],
],"",[  
    ["Tell him when he wakes up.","black"],
]], 
    
["ex",[ 
    ["Iglaqtuulammaġmi quliaqtuaġutiraatigut.","black"],
],"",[  
    ["She told us while intermittently having fits of laughter.","black"],
]], 
    
],  
    
    
"Subordinative Mood Realized":[ 
["p",[  
    ["The","blacksoft"],
    ["Subordinative Mood with a realized aspect (+lu-)","1stmm"],
    ["is a dependent verb that provides more information about the situation which the main verb describes. It tells how (in what manner, by what means, or in what role), or why an action is performed.","blacksoft"],
]], 
    
["ex",[ 
    ["Aqpałłuni~","1stmm"],
    ["tikitchuq.","black"],
    ["",""],
],"",[  
    ["He arrived","black"],
    ["running","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Puuvraqłutik~","1stmm"],
    ["utiqpat?","black"],
    ["",""],
],"",[  
    ["Did they (two) return","black"],
    ["by swimming","1stmm"],
    ["?","black"],
]], 
    
["ex",[ 
    ["Naluvlugu~","1stmm"],
    ["atuŋitkiga.","black"],
    ["",""],
],"",[  
    ["I did not use it","black"],
    ["because I did not know of it","1stmm"],
    [".","black"],
]], 
    
    
["p",[  
    ["When the main verb expresses an action that is ongoing, completed or habitual, the realized aspect (+lu-) is used to describe <i><b>how</b></i> or <i><b>why</b></i> the action is done.","blacksoft"],
    
    
]], 
    
["exheader",    
    ["a.","blacksoft"],
    ["Ongoing (Indicative) main verb + realized Subordinative","blacksoft"],
],  
    
["ex",[ 
    ["Atuqtuq~","black"],
    ["makitavluni","1stmm"],
    [".","black"],
],"",[  
    ["She is singing","black"],
    ["standing up","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iriqtuq~","black"],
    ["iqsivḷuni","1stmm"],
    [".","black"],
],"",[  
    ["She hid (just now)","black"],
    ["because she is afraid","1stmm"],
    [".","black"],
]], 
    
["exheader",    
    ["b.","blacksoft"],
    ["Completed (Participial) main verb + realized Subordinative","blacksoft"],
],  
    
["ex",[ 
    ["Atuqtuaq~","black"],
    ["makitavluni","1stmm"],
    [".",""],
],"",[  
    ["She sang","black"],
    ["standing up","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iriqtuaq~","black"],
    ["iqsivḷuni","1stmm"],
    [".",""],
],"",[  
    ["She hid","black"],
    ["because she is afraid","1stmm"],
    [".","black"],
]], 
    
["exheader",    
    ["c.","blacksoft"],
    ["Habitual main verb + realized Subordinative","blacksoft"],
],  
    
["ex",[ 
    ["Atuġuuruq~","black"],
    ["makitavluni","1stmm"],
    [".",""],
],"",[  
    ["She sings","black"],
    ["standing up","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iriġuuruq~","black"],
    ["iqsivḷuni","1stmm"],
    [".",""],
],"",[  
    ["She usually hides","black"],
    ["because she is a fearful person","1stmm"],
    [".","black"],
]], 
    
["p",[  
    ["The realized aspect (+lu-) is also used when the action or state of being indicated is an established fact.","blacksoft"],
    
    
]], 
    
["ex",[ 
    ["Tiŋŋun atuġniaŋitkaa~","black"],
    ["naviŋavluni","1stmm"],
    [".","black"],
],"",[  
    ["He will not use the plane","black"],
    ["because it is broken","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iñuqaqłuŋa~","1stmm"],
    ["aimmiñiaqtuŋa.","black"],
    ["",""],
],"",[  
    ["I will stay home","black"],
    ["since I have guests","1stmm"],
    [".","black"],
]], 
    
    
["p",[  
    ["When the main verb expresses a future action, the realized aspect (+lu-) of the Subordinative verb is used to explain <b><i>why</i></b> the future action will occur if it is definitely planned to take place.","blacksoft"],
    
    
]], 
    
["ex",[ 
    ["Savaŋniaqłuta~","1stmm"],
    ["qaiñiaŋitchugut.","black"],
    ["",""],
],"",[  
    ["We will not come","black"],
    ["because we will be working","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iñuqaġniaqłuŋa~","1stmm"],
    ["aimmiñiaqtuŋa.","black"],
    ["",""],
],"",[  
    ["I will remain at home","black"],
    ["because I will have guests","1stmm"],
    [".","black"],
]], 
    
    
["p",[  
    ["Subordinative verbs in combination with the conjunction aasii(ñ) “and then” may be used to describe sequential events.","blacksoft"],
    
    
]], 
    
["ex",[ 
    ["Makittuaq aasii~","black"],
    ["kigutiksaqłuni","1stmm"],
    [".","black"],
],"",[  
    ["He stood up and then","black"],
    ["brushed his teeth","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Maliŋniaġaak, aasii~","black"],
    ["salummaksaġlutiglu, tilaglutiglu, iqaqsriḷutiglu uvliñiaqtuk","1stmm"],
    [".","black"],
],"",[  
    ["They (two) will go (followed) with her, and","black"],
    ["they (two) will spend the day cleaning up, mopping the floors, and doing the laundry","1stmm"],
    [".","black"],
]], 
    
["p",[  
    ["The","blacksoft"],
    ["Subordinative Mood also has an unrealized aspect (+vlu- and +łu-).","2ndmm"],
    ["When the main verb expresses a future action, the unrealized aspect is used to explain <b><i>how</i></b> a future action will take place, or in rare cases, to explain <b><i>why</i></b> an action will occur if it is a presupposition, or a wish.","blacksoft"],
]], 
    
    
    
    
["ex",[ 
    ["Pisuaġluŋa~","2ndmm"],
    ["qaiñiaqtuŋa.","black"],
    ["",""],
],"",[  
    ["I will come","black"],
    ["walking","2ndmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Quviasugluni~","2ndmm"],
    ["itiġniaqtuq.","black"],
    ["",""],
],"",[  
    ["He will wake up","black"],
    ["happy","2ndmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iḷausuglunuk~","2ndmm"],
    ["uqallautiniaġikpuk.","black"],
    ["",""],
],"",[  
    ["We (two) will tell him that","black"],
    ["we (two) wish to participate","2ndmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Pisuglugu~","2ndmm"],
    ["piuŋ.","black"],
    ["",""],
],"",[  
    ["(You) Tell him that","black"],
    ["you want to have it","2ndmm"],
    [".","black"],
]], 
    
],  
    
    
"Subordinative Mood Unrealized":[   
["p",[  
    ["The","blacksoft"],
    ["Subordinative Mood verb with an unrealized aspect (+vlu- and +łu-)","1stmm"],
    ["is a dependent verb that provides more information about the situation which the main verb describes. It tells how (in what manner, by what means, or in what role), or why an action will be performed. When the main verb expresses a future action, the unrealized aspect (+vlu- and +łu-) is used to explain <b><i>how</i></b> a future action will take place.","blacksoft"],
]], 
    
    
    
    
["ex",[ 
    ["Qamŋuiḷuni~","1stmm"],
    ["itiqtinniaġaa.","black"],
    ["",""],
],"",[  
    ["He will wake her up","black"],
    ["with his snoring","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Pisuaġluŋa~","1stmm"],
    ["qaiñiaqtuŋa.","black"],
    ["",""],
],"",[  
    ["I will come","black"],
    ["walking","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Quviasugluni~","1stmm"],
    ["itiġniaqtuq.","black"],
    ["",""],
],"",[  
    ["He will wake up","black"],
    ["happy","1stmm"],
    [".","black"],
]], 
    
["p",[  
    ["In rare cases, the unrealized aspect (+vlu- and +łu-) is also used to explain <b><i>why</i></b> an action will occur if it is a presupposition, or a wish.","blacksoft"],
    
    
]], 
    
["ex",[ 
    ["Iḷausuglunuk~","1stmm"],
    ["uqallautiniaġikpuk.","black"],
    ["",""],
],"",[  
    ["We (two) will tell him that","black"],
    ["we (two) wish to participate","1stmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Pisuglugu~","1stmm"],
    ["piuŋ.","black"],
    ["",""],
],"",[  
    ["(You) Tell him that","black"],
    ["you want to have it","1stmm"],
    [".","black"],
]], 
    
["p",[  
    ["The","blacksoft"],
    ["Subordinative Mood also has a realized aspect (+lu-).","2ndmm"],
    ["When the main verb expresses an action that is ongoing, completed or habitual, the realized aspect is used to describe <i><b>how</b></i> or <i><b>why</b></i> the action is done.","blacksoft"],
]], 
    
["ex",[ 
    ["Atuqtuaq~","black"],
    ["makitavluni","2ndmm"],
    [".",""],
],"",[  
    ["She sang","black"],
    ["standing up","2ndmm"],
    [".","black"],
]], 
    
["ex",[ 
    ["Iriqtuaq~","black"],
    ["iqsivḷuni","2ndmm"],
    [".",""],
],"",[  
    ["She hid","black"],
    ["because she is afraid","2ndmm"],
    [".","black"],
]], 
    
["p",[  
    ["The realized aspect (+lu-) is also used when the action or state of being indicated is an established fact.","blacksoft"],
    
    
]], 
    
["ex",[ 
    ["Tiŋŋun atuġniaŋitkaa~","black"],
    ["naviŋavluni","2ndmm"],
    [".","black"],
],"",[  
    ["He will not use the plane","black"],
    ["because it is broken","2ndmm"],
    [".","black"],
]], 
    
    
["p",[  
    ["When the main verb expresses a future action, the realized aspect (+lu-) of the Subordinative verb is used to explain <b><i>why</i></b> the future action will occur if it is definitely planned to take place.","blacksoft"],
    
    
]], 
    
["ex",[ 
    ["Savaŋniaqłuta~","2ndmm"],
    ["qaiñiaŋitchugut.","black"],
    ["",""],
],"",[  
    ["We will not come","black"],
    ["because we will be working","2ndmm"],
    [".","black"],
]], 
    
    
],  
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
}   
    
    
    
    
    
    
    
    