import './MoodSummaries.css';
import { Icon } from 'semantic-ui-react';

// import { MoodSummaries } from './constants/MoodSummaries.js';
// <MoodSummaries mood={this.state.outcaseMood}/>

export const MoodSummaries = (props) => {
	console.log(props.mood)
if (props.mood === 'Absolutive Case') {
return <div>
    <h2 className="c11" id="h.e1zvmynpo1ww"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.7ui8bfafloka"><span className="c2">Absolutive Case</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c5">An </span><span className="c5 c27">Absolutive Case</span><span className="c4">&nbsp;noun can be singular, dual, or plural.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c23 c16 c5">a&#289;naq{props.aud(`INU_01715.mp3`)}</span></p>
    <p className="c8"><span className="c15 c5 c27 c17">the one woman</span></p>
    <p className="c0"><span className="c15 c34 c5 c27"></span></p>
    <p className="c8"><span className="c23 c16 c5">a&#289;nak{props.aud(`INU_03964.mp3`)}</span></p>
    <p className="c8"><span className="c15 c5 c27 c17">the two women</span></p>
    <p className="c0"><span className="c15 c5 c27 c34"></span></p>
    <p className="c8"><span className="c23 c16 c5">a&#289;nat{props.aud(`INU_01761.mp3`)}</span></p>
    <p className="c8"><span className="c15 c5 c27 c17">the 3+ women</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Absolutive Case nouns may be possessed. The Relative Case noun is used for the possessor.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c16 c5">kamig</span><span className="c23 c6 c5">a{props.aud(`summaries-01.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c42 c5 c17">my</span><span className="c5 c17">&nbsp;</span><span className="c15 c5 c17 c27">one boot</span></p>
    <p className="c7"><span className="c4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c16 c5">kamiŋa{props.aud(`summaries-02.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c15 c5 c27 c17">his or her one boot</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c24 c5">nukatpiam </span><span className="c16 c5">kamiŋa{props.aud(`summaries-03.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c1">the young man's </span><span className="c15 c5 c27 c17">one boot</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c16 c5">qimmi&#331;ik{props.aud(`summaries-04.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c15 c5 c27 c17">his or her two dogs</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c24 c5">nukatpiam </span><span className="c16 c5"> qimmiŋik{props.aud(`summaries-05.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c1">the young man's </span><span className="c15 c5 c27 c17">two dogs</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Absolutive Case noun can be the subject of an intransitive verb and the direct object of a transitive verb.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c50 c5">Subject of an Intransitive Verb</span></p>
    <p className="c7 c14"><span className="c23 c16 c5"></span></p>
    <p className="c8"><span className="c16 c5">Iglu</span><span className="c24 c5">&nbsp;kaviqsuq.{props.aud(`summaries-06.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">The house</span><span className="c1">&nbsp;is red.</span></p>
    <p className="c0"><span className="c23 c16 c5"></span></p>
    <p className="c8"><span className="c16 c5">Aqpaluk</span><span className="c24 c5">&nbsp;qamutiqaqtuq.{props.aud(`summaries-07.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">Aqpaluk</span><span className="c1">&nbsp;has a car.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c50 c5">Object of a Transitive Verb</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ni&#289;ipka&#289;aa </span><span className="c16 c5">qimmi&#289;</span><span className="c23 c6 c5">a.{props.aud(`summaries-08.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He is feeding </span><span className="c42 c5 c17">my</span><span className="c15 c5 c27 c17">&nbsp;dog.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">I&#7735;isaurri&#331;ma tautukkai </span><span className="c16 c5">iglut.{props.aud(`summaries-09.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">My teacher sees </span><span className="c15 c5 c27 c17">the houses.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">An </span><span className="c5 c27">Absolutive Case </span><span className="c4">noun can take third person reflexive (3R) possessive endings. It can function as a direct object of a transitive verb and refer back to the main subject.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">A&#289;nam nutqaqtitkaa</span><span className="c16 c5">&nbsp;qamuti</span><span className="c13 c5">ni.{props.aud(`summaries-10.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The woman stopped her own </span><span className="c15 c5 c27 c17">car.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The addition of the enclitic +lu to a possessed </span><span className="c5 c27">Absolutive Case </span><span className="c4">noun enables it to function as part of the compound subject.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c16 c5">Aakanilu</span><span className="c24 c5">&nbsp;nutqaqtuk.{props.aud(`summaries-11.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">Her own mother (and herself)</span><span className="c1">&nbsp;stopped.</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
</div>
} else if (props.mood === 'Relative Case') {
return <div>
    <h2 className="c11" id="h.vcgej8b8twef"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.ryr1vy8h74fg"><span className="c2">Relative Case</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">A noun in the</span><span className="c5 c43">&nbsp;Relative Case </span><span className="c4">functions as the subject of a transitive verb and the possessor of another noun.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Subject of a Transitive Verb</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c19 c5">A&#331;utim</span><span className="c13 c5">&nbsp;ikayu&#289;aa.{props.aud(`summaries-12.mp3`)}</span></p>
    <p className="c8"><span className="c5 c43 c17">The man</span><span className="c1">&nbsp;is helping her.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c19 c5">Aapiya&#289;</span><span className="c5 c6">ma</span><span className="c13 c5">&nbsp;i&#7735;isimagaa.{props.aud(`summaries-13.mp3`)}</span></p>
    <p className="c8"><span className="c42 c5 c17">My</span><span className="c5 c17">&nbsp;</span><span className="c5 c43 c17">older brother</span><span className="c1">&nbsp;knows her.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Possessor of Another Noun</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c5 c19">A&#289;nam</span><span className="c13 c5">&nbsp;qamuti&#331;a/qamutaa.{props.aud(`summaries-14.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The </span><span className="c5 c17 c43">woman&rsquo;s</span><span className="c1">&nbsp;car.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c19 c5">Aakak</span><span className="c24 c5 c56">pi&ntilde;</span><span className="c13 c5">&nbsp;avilaitqati&#331;a i&#7735;isimaruq.{props.aud(`summaries-15.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17 c56">Your</span><span className="c5 c17">&nbsp;</span><span className="c5 c43 c17">mother&rsquo;s</span><span className="c1">&nbsp;friend knows.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c19 c5">Aaka</span><span className="c16 c5">mi</span><span className="c24 c5">&nbsp;</span><span className="c19 c5">avilaitqati&#331;ata</span><span className="c13 c5">&nbsp;i&#7735;isimagaa.{props.aud(`summaries-16.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">His </span><span className="c5 c43 c17">mother&rsquo;s</span><span className="c5 c17">&nbsp;</span><span className="c5 c43 c17">friend</span><span className="c1">&nbsp;knows her.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The dual and plural for Absolutive and Relative Case unpossessed nouns are the same. The unpossessed singular Relative Case noun differs from Absolutive and its mood markers are -m, &#39;-m, :um.</span></p>
    <p className="c0"><span className="c15 c34 c5 c43"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c2"></span></p>
</div>
} else if (props.mood === 'Modalis Case') {
return <div>
    <h2 className="c11" id="h.c0yfyuheb7ur"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.2anb9rq0xc1v"><span className="c2">Modalis Case</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c31">Modalis Case</span><span className="c4">&nbsp;has several functions: as an indefinite object, as an instrument or means to do an action, or as a way to specify an object or an action.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Indefinite Object of Intransitive Verb</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Tautuktu</span><span className="c16 c5">q</span><span className="c24 c5">&nbsp;</span><span className="c23 c24 c5 c31">qimmimik.{props.aud(`summaries-17.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">She</span><span className="c5 c17">&nbsp;sees</span><span className="c15 c5 c17 c31">&nbsp;a dog.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Tautukt</span><span className="c16 c5">uq</span><span className="c24 c5">&nbsp;</span><span className="c23 c24 c5 c31">qimmi&ntilde;ik.{props.aud(`summaries-18.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">She</span><span className="c5 c17">&nbsp;sees </span><span className="c15 c5 c17 c31">some dogs.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Atu&#289;niaqtu</span><span className="c6 c5">&#331;a</span><span className="c24 c5">&nbsp;</span><span className="c23 c24 c5 c31">qamuti&#331;anik/qamutaanik.{props.aud(`summaries-19.mp3`)}</span></p>
    <p className="c8"><span className="c42 c5 c17">I </span><span className="c5 c17">will use </span><span className="c15 c5 c17 c31">his car.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Means or Instrument </span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5 c31">Mattumi&#331;a savigmik</span><span className="c13 c5">&nbsp;pi&#7735;agu&#331;.{props.aud(`summaries-20.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">(You 1), butcher it </span><span className="c15 c5 c17 c31">with this knife.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ai&ntilde;ia&#289;i</span><span className="c6 c5">ga</span><span className="c24 c5">&nbsp;</span><span className="c24 c5 c31">qamuti</span><span className="c6 c5">m</span><span className="c23 c24 c5 c31">nik.{props.aud(`summaries-21.mp3`)}</span></p>
    <p className="c8"><span className="c42 c5 c17">I</span><span className="c5 c17">&nbsp;will fetch her </span><span className="c5 c17 c31">with</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c42">my</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c31">car.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c50">Specification</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Uluuraqaqtu</span><span className="c6 c5">&#331;a</span><span className="c24 c5">&nbsp;</span><span className="c23 c24 c5 c31">a&#331;iruamik.{props.aud(`summaries-22.mp3`)}</span></p>
    <p className="c8"><span className="c42 c5 c17">I </span><span className="c5 c17">have a ulu </span><span className="c15 c5 c17 c31">(a big one).</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Aitchuqta&#331;a </span><span className="c23 c24 c5 c31">mal&#289;u&#331;nik.{props.aud(`summaries-23.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She gave him </span><span className="c15 c5 c17 c31">(two).</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">I&#289;&ntilde;iqaqtu</span><span className="c6 c5">&#331;a</span><span className="c23 c24 c5 c31">&nbsp;tallimanik.{props.aud(`summaries-24.mp3`)}</span></p>
    <p className="c8"><span className="c42 c5 c17">I</span><span className="c5 c17">&nbsp;have sons</span><span className="c15 c5 c17 c31">&nbsp;(five of them).</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">d.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Adverb (limited usage) </span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Savaguuruq </span><span className="c23 c24 c5 c31">ataramik.{props.aud(`summaries-25.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She works </span><span className="c15 c5 c17 c31">constantly.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c23 c24 c5 c31">Qilamik </span><span className="c24 c5">isiġiñ.{props.aud(`summaries-26.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Come in </span><span className="c15 c5 c17 c31">quickly.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c31">Modalis Case</span><span className="c4">&nbsp;also indicates &ldquo;by oneself &rdquo; when attached to personal noun stems.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5 c31">Uvaptinnik</span><span className="c13 c5">&nbsp;piraqput.{props.aud(`summaries-27.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">We did it </span><span className="c5 c17 c31">ourselves.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5 c31">Ili&#331;nik</span><span className="c13 c5">&nbsp;piu&#331;.{props.aud(`summaries-28.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">You, do it </span><span className="c15 c5 c17 c31">yourself.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5 c31">Ilimi&ntilde;ik</span><span className="c13 c5">&nbsp;piraa.{props.aud(`summaries-29.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He did it </span><span className="c15 c5 c17 c31">by himself.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c2"></span></p>
</div>
} else if (props.mood === 'Locative Case') {
return <div>
    <h2 className="c11" id="h.o1isw6lq89ol"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.plhsknzefko9"><span className="c2">Locative Case</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c33">Locative Case</span><span className="c4">&nbsp;identifies the place or time where or when an event occurs. It can also be possessed.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Place</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Utqiag&#775;vig</span><span className="c24 c5 c33">n&#771;i</span><span className="c13 c5">&nbsp;in&#771;uuruq.{props.aud(`summaries-30.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She lives </span><span className="c5 c17 c33">in</span><span className="c1">&nbsp;Barrow.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Uuktuag&#775;u&#331; tamanna suppun igluata tunua</span><span className="c24 c5 c33">ni</span><span className="c13 c5">.{props.aud(`summaries-31.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Try that gun out </span><span className="c5 c17 c33">(in the place)</span><span className="c1">&nbsp;behind his house.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Space</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Sava&#331;niaqtu&#331;a uva</span><span className="c24 c5 c33">ni</span><span className="c13 c5">.{props.aud(`summaries-32.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I will work </span><span className="c5 c17 c33">(in the space)</span><span className="c1">&nbsp;right here.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c7"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Time</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Savaguuruq uvlaa</span><span className="c24 c5 c33">mi</span><span className="c13 c5">.{props.aud(`summaries-33.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He works </span><span className="c5 c17 c33">in</span><span className="c1">&nbsp;the morning.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c24 c5">Annivia</span><span className="c24 c5 c33">ni</span><span className="c13 c5">&nbsp;tikitchuaq.{props.aud(`summaries-34.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She arrived </span><span className="c5 c17 c33">on</span><span className="c1">&nbsp;his birthday.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Aippaa</span><span className="c24 c5 c33">ni</span><span className="c13 c5">&nbsp;tainna ittuq.{props.aud(`summaries-35.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">It was like that </span><span className="c5 c17 c33">in</span><span className="c1">&nbsp;ancient times.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Taima</span><span className="c24 c5 c33">ni</span><span className="c13 c5">&nbsp;tainna pisuuruat.{props.aud(`summaries-36.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">They did it like that </span><span className="c5 c17 c33">(in the time of)</span><span className="c1">&nbsp;long ago.</span></p>
    <p className="c9"><span className="c2"></span></p>
</div>
} else if (props.mood === 'Terminalis Case') {
return <div>
    <h2 className="c11" id="h.n9ndyfit956z"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.7rzhjfd60qvi"><span className="c2">Terminalis Case</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c33">Terminalis Case </span><span className="c5">noun identifies the place, time, or entity where an event or action stops.</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Utqiag&#775;vig</span><span className="c24 c5 c33">nun&#771;</span><span className="c13 c5">&nbsp;tikin&#771;n&#771;iaqtugut.{props.aud(`summaries-37.mp3`)}</span></p>
    <p className="c8"><span className="c1">We will reach (to) Barrow.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Savain&#771;n&#771;ig&#775;</span><span className="c24 c5 c33">mun</span><span className="c13 c5">&nbsp;maaniinniaqtu&#331;a.{props.aud(`summaries-38.mp3`)}</span></p>
    <p className="c8"><span className="c1">I will be here until Sunday.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Savagvim</span><span className="c24 c5 c33">n&#771;un</span><span className="c13 c5">&nbsp;utaqqiyyaqtuaq.{props.aud(`summaries-39.mp3`)}</span></p>
    <p className="c8"><span className="c1">He went to wait at my place of employment.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The Terminalis Case also identifies </span><span className="c5 c35">the subject of the embedded verb</span><span className="c4">. The embedding &ldquo;verb&rdquo; (i.e. embedding postbase) may be a request, a causative, or a reportative.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Subject of Embedded Verb</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Ag&#775;naq </span><span className="c22 c5">ikayu</span><span className="c24 c5">quruq </span><span className="c22 c5">nukatpiamun</span><span className="c13 c5">.{props.aud(`summaries-40.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The woman would like </span><span className="c5 c35 c17">the young man</span><span className="c5 c17">&nbsp;</span><span className="c5 c35 c17">to help</span><span className="c5 c17">&nbsp;her.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c22 c5">Qimmimiñun</span><span className="c24 c5">&nbsp;</span><span className="c22 c5">nig&#775;i</span><span className="c13 c5">pkaqta&#331;i iqaluit.{props.aud(`summaries-41.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She let </span><span className="c5 c35 c17">her own dogs eat </span><span className="c1">the fish (3+).</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ag&#775;nam </span><span className="c22 c5">isiq</span><span className="c24 c5">ugaa qattaq iglumun </span><span className="c22 c5">nukatpiamun</span><span className="c13 c5">.{props.aud(`summaries-42.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The woman wants </span><span className="c5 c17 c35">the young man</span><span className="c5 c17">&nbsp;</span><span className="c5 c35 c17">to bring </span><span className="c5 c17">the water barrel</span><span className="c5 c35 c17">&nbsp;inside</span><span className="c1">&nbsp;the house.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">A&#331;utim </span><span className="c22 c5">nig&#775;i</span><span className="c24 c5">n&#771;ira&#331;i iqaluit </span><span className="c22 c5">qimmin&#771;un</span><span className="c13 c5">.{props.aud(`summaries-43.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The man reported that </span><span className="c5 c35 c17">the dogs</span><span className="c5 c17">&nbsp;</span><span className="c5 c35 c17">ate</span><span className="c5 c17">&nbsp;the fish.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c5 c22">Ili&#331;nun</span><span className="c24 c5">&nbsp;</span><span className="c22 c5">sava</span><span className="c13 c5">qugiga.{props.aud(`summaries-44.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I would like for </span><span className="c5 c35 c17">you</span><span className="c5 c17">&nbsp;</span><span className="c5 c35 c17">to work</span><span className="c1">&nbsp;on it.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Ablative Case') {
return <div>
    <h2 className="c41 c40 c39" id="h.nb1kj9deuln2"><span className="c2">Ablative Case</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c5">The</span><span className="c5 c33">&nbsp;Ablative Case</span><span className="c4">&nbsp;identifies the origin of an item or an event, or the source of comparison.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c50 c5">Origin</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Utqiag&#775;vig</span><span className="c24 c5 c33">n&#771;in&#771;</span><span className="c13 c5">&nbsp;tikitchut umiaqtuqtuat.{props.aud(`summaries-45.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The ones who traveled by boat have arrived </span><span className="c5 c17 c33">from</span><span className="c1">&nbsp;Barrow.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Aippaa</span><span className="c24 c5 c33">nin&#771;</span><span className="c13 c5">&nbsp;qa&#331;a tainnaittuaq tamanna nuna.{props.aud(`summaries-46.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">That piece of land has been that way </span><span className="c5 c17 c33">from</span><span className="c1">&nbsp;time immemorial.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Iktuma&#331;&#331;a ag&#775;na</span><span className="c24 c5 c33">min&#771;</span><span className="c24 c5">&nbsp;aitchuusia&#331;a tamanna suppun ukiutqik Christmag&#775;mata.{props.aud(`summaries-47.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">That gun is his Christmas gift </span><span className="c5 c17 c33">from</span><span className="c1">&nbsp;that woman over there the year before.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c24 c5">Ivaa&#331;in&#771;</span><span className="c24 c5 c33">n&#771;in&#771;</span><span className="c5 c13">&nbsp;qimmiag&#775;ruksraqtuaq.{props.aud(`summaries-48.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She got a puppy </span><span className="c5 c17 c33">from</span><span className="c1">&nbsp;its litter.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Umia&#331;a</span><span className="c24 c5 c33">nin&#771; </span><span className="c13 c5">supputiksraqtuaq ag&#775;naq.{props.aud(`summaries-49.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The woman got a gun </span><span className="c5 c17 c33">from</span><span className="c1">&nbsp;his boat.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Source of Comparison</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">A&#331;un</span><span className="c24 c5 c33">min&#771;</span><span className="c13 c5">&nbsp;miki&#322;haaqtu&#331;a.{props.aud(`summaries-50.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I am shorter </span><span className="c5 c17 c33">than</span><span className="c1">&nbsp;the man.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Mattuma&#331;&#331;a umia</span><span className="c24 c5 c33">min&#771;</span><span className="c13 c5">&nbsp;a&#331;i&#322;haaqtuq.{props.aud(`summaries-51.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">It is bigger </span><span className="c5 c17 c33">than</span><span className="c1">&nbsp;this boat here.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Uvam</span><span className="c24 c5 c33">nin&#771;</span><span className="c13 c5">&nbsp;mikiruq.{props.aud(`summaries-52.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He is smaller </span><span className="c5 c17 c33">than</span><span className="c1">&nbsp;I.</span></p>
</div>
} else if (props.mood === 'Vialis Case') {
return <div>    
    <h2 className="c41 c40" id="h.9lhoqe1fllto"><span className="c2">Vialis Case</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c33">Vialis Case</span><span className="c4">&nbsp;identifies the venue, time, entity, or circumstances through which an event occurs or the source of a comparison of differences.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Venue</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Seattle-</span><span className="c24 c5 c33">kun</span><span className="c13 c5">&nbsp;Hawaii-muktuat.{props.aud(`summaries-53.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">They went to Hawaii </span><span className="c5 c17 c33">by way of</span><span className="c1">&nbsp;Seattle.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Nunap</span><span className="c24 c5 c33">kun</span><span className="c13 c5">&nbsp;apqusiuqtut.{props.aud(`summaries-54.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">They are building a road </span><span className="c5 c17 c33">through</span><span className="c1">&nbsp;my land.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Time</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Unnua</span><span className="c24 c5 c33">kun</span><span className="c13 c5">&nbsp;iglauruagut.{props.aud(`summaries-55.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">We traveled </span><span className="c5 c17 c33">at</span><span className="c1">&nbsp;night.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Piviksrauti&#331;a</span><span className="c24 c5 c33">gun</span><span className="c13 c5">&nbsp;pin&#771;iaqtu&#331;a.{props.aud(`summaries-56.mp3`)}</span></p>
    <p className="c8"><span className="c1">I will take his time slot.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Entity</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Qairuagut ti&#331;&#331;utiti</span><span className="c24 c5 c33">gun</span><span className="c13 c5">.{props.aud(`summaries-57.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">We came </span><span className="c5 c17 c33">in</span><span className="c1">&nbsp;airplanes.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">d.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Circumstance</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c24 c5">Savaap</span><span className="c24 c5 c33">kun</span><span className="c13 c5">&nbsp;Anchorage-muktua&#331;a.{props.aud(`summaries-58.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I went to Anchorage </span><span className="c5 c17 c33">on account of </span><span className="c1">my job.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Savaa&#331;a</span><span className="c24 c5 c33">gun</span><span className="c13 c5">&nbsp;nang&#775;aqtag&#775;a.{props.aud(`summaries-59.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I praised </span><span className="c5 c17 c33">(on account of)</span><span className="c5 c17">&nbsp;his work.</span></p>
</div>
} else if (props.mood === 'Similaris Case') {
return <div>
    <h2 className="c41 c40 c39" id="h.828tc1r89c9t"><span className="c2">Similaris Case</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c33">Similaris Case</span><span className="c4">&nbsp;indicates similarities between entities.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Utqiag&#775;vik</span><span className="c24 c5 c33">sun</span><span className="c13 c5">&nbsp;Qikiqtag&#775;ruk qauk&#322;iqaqtuq ag&#775;namik.{props.aud(`summaries-60.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Kotzebue, </span><span className="c5 c17 c33">like</span><span className="c1">&nbsp;Barrow, has a female mayor.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Uvap</span><span className="c24 c5 c33">tun</span><span className="c13 c5">&nbsp;miqug&#775;nagu.{props.aud(`summaries-61.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Don&rsquo;t sew it </span><span className="c5 c17 c33">like</span><span className="c1">&nbsp;I did mine.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Qamuti&#331;a</span><span className="c24 c5 c33">tun</span><span className="c13 c5">&nbsp;qamutiqaqtu&#331;a.{props.aud(`summaries-62.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I have a car </span><span className="c5 c17 c33">like</span><span className="c1">&nbsp;his car.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ilik</span><span className="c24 c5 c33">tun</span><span className="c13 c5">&nbsp;mi&#331;ulig&#775;niag&#775;iga.{props.aud(`summaries-63.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I will paint it </span><span className="c5 c17 c33">like</span><span className="c1">&nbsp;you did yours.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c5 c33">Similaris Case</span><span className="c4">&nbsp;also indicates &ldquo;of its/one&rsquo;s own volition,&rdquo; when coupled with the third person reflexive pronoun stems.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ilimi</span><span className="c24 c5 c33">sun</span><span className="c13 c5">&nbsp;qamiruaq.{props.aud(`summaries-64.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">It became unoperational </span><span className="c5 c17 c33">by</span><span className="c1">&nbsp;itself.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Inmi</span><span className="c24 c5 c33">sun </span><span className="c13 c5">a&#331;maqtuaq.{props.aud(`summaries-65.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">It opened </span><span className="c5 c17 c33">by</span><span className="c1">&nbsp;itself.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Katchi ilimi</span><span className="c24 c5 c33">sun</span><span className="c13 c5">&nbsp;aatchaqtuq.{props.aud(`summaries-66.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">The wall cracked open </span><span className="c5 c17 c33">by</span><span className="c1">&nbsp;itself.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Indicative Mood I') {
return <div>
    <h2 className="c11" id="h.nsxpxsnj5up7"><span className="c2"></span></h2>
    <h2 className="c40 c39 c41" id="h.dnfs3nobga7r"><span className="c2">Indicative Mood I</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Indicative Mood expresses a present statement (currently happening or immediate past).</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautuktuq.{props.aud(`INU_01838.mp3`)}</span></p>
    <p className="c8"><span className="c1">He sees something.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">A&#331;un tautuktuq tuttumik.{props.aud(`summaries-67.mp3`)}</span></p>
    <p className="c8"><span className="c1">The man sees a caribou.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkaa.{props.aud(`INU_04992.mp3`)}</span></p>
    <p className="c8"><span className="c1">He sees it.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">A&#331;utim tautukkaa tuttu.{props.aud(`summaries-68.mp3`)}</span></p>
    <p className="c8"><span className="c1">The man sees the caribou.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The following examples express present (currently happening and the immediate past), future, and past events.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Indicative Mood I</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;iruq.{props.aud(`INU_02900.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she is eating.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Siquruq.{props.aud(`summaries-69.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she is falling asleep.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Indicative Mood I + future postbase</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;i&ntilde;iaqtuq.{props.aud(`INU_02771.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she will eat.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Siquniaqtuq.{props.aud(`summaries-70.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she will fall asleep.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Participial Mood I</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;iruaq.{props.aud(`INU_03438.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she ate.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Siquruaq.{props.aud(`summaries-71.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she has fallen asleep.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Indicative Mood is an independent verb and can be paired with dependent verb moods.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Quviasuk&#322;uni itiqtuq.{props.aud(`summaries-72.mp3`)}</span></p>
    <p className="c8"><span className="c1">He woke up happy.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Itiqpan uqallautisa&#289;i&ntilde;.{props.aud(`summaries-73.mp3`)}</span></p>
    <p className="c8"><span className="c1">Tell him when he wakes up.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Iglaqtuulamma&#289;mi quliaqtua&#289;utiraatigut.{props.aud(`summaries-74.mp3`)}</span></p>
    <p className="c8"><span className="c1">She told us while intermittently having fits of laughter.</span></p>
</div>
} else if (props.mood === 'Indicative Mood II') {
return <div>
    <p className="c0"><span className="c1"></span></p>
    <h2 className="c41 c40 c39" id="h.ceph1t60723j"><span className="c2">Indicative Mood II</span></h2>
    <p className="c14 c21"><span className="c10"></span></p>
    <p className="c7"><span className="c5">The Indicative Mood II markers </span><span className="c24 c5">+sugau-</span><span className="c5">&nbsp;and </span><span className="c24 c5">+sugaaq-</span><span className="c5">&nbsp;</span><span className="c4">generally mean &ldquo;recall, don&rsquo;t you remember; finally despite all odds&rdquo;.</span></p>
    <p className="c9 c38"><span className="c1"></span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Aitchuqsugaatin ukiaq.{props.aud(`summaries-75.mp3`)}</span></p>
    <p className="c8"><span className="c1">Don&rsquo;t you recall that she gave you one last fall.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Tikitchugaugut</span><span className="c1">.{props.aud(`summaries-76.mp3`)}</span></p>
    <p className="c8"><span className="c1">Oh (indicating an observation), we finally arrived.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Uqallautisugaatigut unnuaq tainna&#289;nia&#289;nivḷugu.{props.aud(`summaries-77.mp3`)}</span></p>
    <p className="c8"><span className="c1">Don&rsquo;t you recall we were told last night that it would happen that way/that he would react that way.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Uumitchaya&#331;nisugaa tai&#289;ña agnaq.{props.aud(`summaries-78.mp3`)}</span></p>
    <p className="c8"><span className="c1">Don&rsquo;t you remember he said that that woman becomes angry quickly.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Nalunisugauq unnuaq apiqsru&#289;aptigu.{props.aud(`summaries-79.mp3`)}</span></p>
    <p className="c8"><span className="c1">Recall that he said he did not know when we asked him last night.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Uvli&ntilde;ia&#289;nisugauk maani.{props.aud(`summaries-80.mp3`)}</span></p>
    <p className="c8"><span className="c1">Recall they said they would spend the day here.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c0"><span className="c13 c5"></span></p>
</div>
} else if (props.mood === 'Participial Mood I') {
return <div>
    <h2 className="c11" id="h.2642gwwh33hs"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.9ukiv1u39f9k"><span className="c2">Participial Mood I</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Participial Mood I expresses a past statement.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautuktuaq.{props.aud(`summaries-81.mp3`)}</span></p>
    <p className="c8"><span className="c1">He saw something.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">A&#331;un tautuktuaq tuttumik.{props.aud(`summaries-82.mp3`)}</span></p>
    <p className="c8"><span className="c1">The man saw a caribou.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautuktaa.{props.aud(`summaries-83.mp3`)}</span></p>
    <p className="c8"><span className="c1">He saw it.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">A&#331;utim tautuktaa tuttu.{props.aud(`summaries-84.mp3`)}</span></p>
    <p className="c8"><span className="c1">The man saw the caribou.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The following examples express past, present (currently happening and the immediate past), future events.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Participial Mood I</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;iruaq.{props.aud(`INU_03438.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she ate.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Siquruaq.{props.aud(`summaries-71.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she had fallen asleep.</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Indicative Mood I</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;iruq.{props.aud(`INU_02900.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she is eating.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Siquruq.{props.aud(`summaries-69.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she fell asleep.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Indicative Mood I + future postbase</span></p>
    <p className="c7 c14"><span className="c2"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;i&ntilde;iaqtuq.{props.aud(`INU_02771.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she will eat.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Siquniaqtuq.{props.aud(`summaries-70.mp3`)}</span></p>
    <p className="c8"><span className="c1">He/she will fall asleep.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c21 c40"><span className="c4">This verb mood can also function as a noun.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c21 c40"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c24 c5">Ni&#289;iruaq.{props.aud(`INU_03438.mp3`)}</span></p>
    <p className="c8"><span className="c1">The one who ate </span></p>
    <p className="c8 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ni&#289;iraata </span><span className="c5">or</span><span className="c13 c5">&nbsp;ni&#289;ira&#331;ata.{props.aud(`summaries-85.mp3`)}</span></p>
    <p className="c8"><span className="c1">The one who ate it </span></p>
    <p className="c8 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Isiqtua&#331;a tikkua&#289;aa&#331;a.{props.aud(`summaries2-01.mp3`)}</span></p>
    <p className="c8"><span className="c1">He pointed at me (I, who came in).</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Participial Mood I is considered an independent verb and can be paired with dependent verb moods.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Atuqtuaq makitavluni.{props.aud(`summaries2-02.mp3`)}</span></p>
    <p className="c8"><span className="c1">She sang standing up.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Aimmiuraaqti&#322;&#322;ugu isiqtuaguk.{props.aud(`summaries2-03.mp3`)}</span></p>
    <p className="c8"><span className="c1">We entered while she was at home.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkalua&#289;nagu aniruaq.{props.aud(`summaries2-04.mp3`)}</span></p>
    <p className="c8"><span className="c1">She left without seeing him.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Iti&#289;ami uqallautiraa.{props.aud(`summaries2-05.mp3`)}</span></p>
    <p className="c21 c45"><span className="c5 c17 c58">He told her when he woke up.</span></p>
    <p className="c21 c14 c45"><span className="c10"></span></p>
</div>
} else if (props.mood === 'Participial Mood II') {
return <div>
    <h2 className="c11" id="h.l7wdzbcn8m1h"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.5p97k2fxwkvu"><span className="c2">Participial Mood II</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c5">The Participial Mood II marker </span><span className="c24 c5">+kkaq(-)</span><span className="c5">&nbsp;generally means</span><span className="c5 c17">&nbsp;&ldquo;did V her/him/it; an item V-ed&rdquo;</span><span className="c5">. &nbsp;It is used to express a past event; or the entity that has been V-ed. &nbsp;It serves as an alternate form to the Participial Mood I which has the marker </span><span className="c24 c5">+t/raq(-)</span><span className="c5">&nbsp;to express a past </span><span className="c5">event</span><span className="c4">; and also to express a possessed noun.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c24 c5">Ni&#289;ikka&#331;a.{props.aud(`summaries2-07.mp3`)}</span></p>
    <p className="c8"><span className="c1">She did eat it.</span></p>
    <p className="c8"><span className="c1">The one she ate.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Ni&#289;ikka&#289;a.{props.aud(`summaries2-06.mp3`)}</span></p>
    <p className="c8"><span className="c1">I ate it.</span></p>
    <p className="c8"><span className="c1">The one I ate.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5 c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c24 c5">Uqallautikka&#331;igguuq aapa&#331;atta uvlaami.&sup1;{props.aud(`summaries2-08.mp3`)}</span></p>
    <p className="c7"><span className="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Their father had told them that morning.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nukaaluugni piqpagikkagni tammaikamigik.&sup1;{props.aud(`summaries2-09.mp3`)}</span></p>
    <p className="c8"><span className="c1">When he lost his two younger siblings whom he loved so much.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c21 c45"><span className="c24 c5">Immagguuq akkumi tatkiani aniiqamik tikitqaaqamik tautukka&#331;iik attaurat.&sup1;{props.aud(`summaries2-10.mp3`)}</span></p>
    <p className="c21 c45"><span className="c5 c17">Recalling that a while ago, while they were outside right after they arrived, they had seen the dumping area.</span></p>
    <p className="c9"><span className="c24 c34 c5 c57"></span></p>
    <p className="c7"><span className="c1">&sup1; (Nageak, V. Ataatalugiik (1971))</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c9"><span className="c1"></span></p>
</div>
} else if (props.mood === 'Participial Mood III') {
return <div>
    <h2 className="c30" id="h.7fikfb5mvx9r"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.t89kcwu49wy8"><span className="c2">Participial Mood III</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c5">Participial Mood III </span><span className="c24 c5">+t/raq- </span><span className="c5">generally means &ldquo;</span><span className="c5 c17">did V, but&hellip;&rdquo;. </span><span className="c4">It is used to express an event which happened but did not produce the desired result.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c21"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c13 c5">Aigaluaqta&#289;a&hellip;</span></p>
    <p className="c21"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c1">&nbsp;&nbsp;&nbsp;I went to fetch it, but&hellip;</span></p>
    <p className="c21 c14"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Aigaluaqta&#289;a pitqu&#331;itcha&#331;a.{props.aud(`summaries2-11.mp3`)}</span></p>
    <p className="c8"><span className="c1">I went to get it, but he told me not to take it.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Apiqsru&#289;aluaqtaqput&hellip;</span></p>
    <p className="c8"><span className="c1">We asked her, but&hellip;</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Apiqsru&#289;aluaqtaqput i&#7735;ausuk&#322;uta&hellip;</span></p>
    <p className="c8"><span className="c1">We asked to be included, but&hellip; </span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Atigaluaqta&#331;a&hellip;</span></p>
    <p className="c8"><span className="c1">He put it on, but&hellip;</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Atigaluaqta&#331;a mattaqtitta&#289;a.{props.aud(`summaries2-12.mp3`)}</span></p>
    <p className="c8"><span className="c1">He put it on, but I made him take it off.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;isa&#289;igaluaqtaa&hellip;</span></p>
    <p className="c8"><span className="c1">He recognized her, but&hellip;</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">I&#7735;isa&#289;igaluaqtaa aglaan ulla&#331;itkaa.{props.aud(`summaries2-13.mp3`)}</span></p>
    <p className="c8"><span className="c1">He recognized her, but he did not approach her.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Qaitqugaluaqtaatiguk&hellip;</span></p>
    <p className="c21 c40"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c1">&nbsp;&nbsp;&nbsp;He invited us to come, but&hellip;</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Qaitqugaluaqtaatiguk kamagi&#331;itkikpuk.{props.aud(`summaries2-14.mp3`)}</span></p>
    <p className="c8"><span className="c1">He invited us (two) to come, but we did not obey him.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Isi&#289;aluaqtuaq, aglaan qilamik anitqiksuaq.{props.aud(`summaries2-15.mp3`)}</span></p>
    <p className="c8"><span className="c1">He went inside, but he came out quickly.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Malikkaluaqtaa, aglaan tammaimaraa.{props.aud(`summaries2-16.mp3`)}</span></p>
    <p className="c8"><span className="c1">She followed him, but eventually she lost sight of him.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkaluaqtaa aglaan i&#7735;isa&#289;i&#331;itkaa.{props.aud(`summaries2-17.mp3`)}</span></p>
    <p className="c8"><span className="c1">He saw her, but he did not recognize her.</span></p>
    <p className="c0"><span className="c10"></span></p>
</div>
} else if (props.mood === 'Interrogative Mood') {
return <div>
    <p className="c0"><span className="c10"></span></p>
    <h2 className="c41 c40 c39" id="h.z88w33slsgvz"><span className="c2">Interrogative Mood</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Interrogative Mood is the question form. When used without a question word, it&#39;s a yes-no question. The Interrogative Mood can be both present and past tense, depending on context.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;isallatuvigi?{props.aud(`summaries2-18.mp3`)}</span></p>
    <p className="c8"><span className="c1">Do you enjoy studying them?</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ti&#331;&#331;utikun Aa&#331;alu Si&#289;mia&#289;lu Utqia&#289;vi&#331;mu&#331;niaqpak?{props.aud(`summaries2-19.mp3`)}</span></p>
    <p className="c8"><span className="c1">Will Aa&#331;a and Si&#289;miaq go to Utqia&#289;vik via a plane?</span></p>
    <p className="c7 c14"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Panii&ntilde; il&#803;isaurrirraqsiva uvlupak?{props.aud(`summaries2-20.mp3`)}</span></p>
    <p className="c8"><span className="c1">Did your daughter begin teaching today?</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The use of +k/galuaq- with Interrogative endings asks for confirmation of an action.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c13 c5">Il&#803;isa&#289;igaluaqpigik?{props.aud(`summaries2-21.mp3`)}</span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c17">Are you sure you recognize them (2)?</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The following Interrogative Mood examples include a question word (who, when, where, why, how, etc.).</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Kia ikayuqpau&#331; a&#289;naq?{props.aud(`summaries2-22.mp3`)}</span></p>
    <p className="c8"><span className="c1">Who is helping the woman?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Ki&ntilde;a tautukpiu&#331;?{props.aud(`summaries2-23.mp3`)}</span></p>
    <p className="c8"><span className="c1">Who do you see?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Suna tautukpiu&#331;?{props.aud(`summaries2-24.mp3`)}</span></p>
    <p className="c8"><span className="c1">What do you see?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Qa&#331;a tikitpa ti&#331;&#331;un?{props.aud(`summaries2-25.mp3`)}</span></p>
    <p className="c8"><span className="c1">When did the plane arrive?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Qakugu ni&#289;i&ntilde;iaqpi&ntilde;uk?{props.aud(`summaries2-26.mp3`)}</span></p>
    <p className="c8"><span className="c1">When are we (two) going to eat?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Sumi iqaluit iganiaqpisigi&#331;?{props.aud(`summaries2-27.mp3`)}</span></p>
    <p className="c8"><span className="c1">Where will you all (3+) cook the fish? </span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Qavsi&ntilde;ik iqalu&#331;nik aitchu&#289;niaqpau&#331;?{props.aud(`summaries2-28.mp3`)}</span></p>
    <p className="c8"><span className="c1">How many fish will he give her?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Qanuq il&#803;isimava?{props.aud(`summaries2-29.mp3`)}</span></p>
    <p className="c8"><span className="c1">How does he know?</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Sumi&ntilde; tauqsiqparru&#331; umiaq?{props.aud(`summaries2-30.mp3`)}</span></p>
    <p className="c8"><span className="c1">From where did they (3+) buy the umiaq?</span></p>
    <p className="c9"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Kimiñ tauqsiqpauŋ umiaq?{props.aud(`summaries2-31.mp3`)}</span></p>
    <p className="c8"><span className="c1">From whom did he buy the boat?</span></p>
    <p className="c9"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Summan iglaqpi&ntilde;?{props.aud(`summaries2-32.mp3`)}</span></p>
    <p className="c8"><span className="c1">Why are you laughing?</span></p>
    <p className="c0"><span className="c1"></span></p>
</div>
} else if (props.mood === 'Imperative Mood') {
return <div>
    <h2 className="c11" id="h.fpjilk8cdvu3"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.jn5sj8tbabfq"><span className="c2">Imperative Mood</span></h2>
    <p className="c7 c14"><span className="c15 c47 c34 c5"></span></p>
    <p className="c7"><span className="c5 c47">The Imperative Mood is used to express a command. It is only </span><span className="c4">used with 2nd person subjects (you, you two, you all) and 3rd </span><span className="c4">person or 1st person objects (her/him/it/them, me/us).</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Nig&#775;g&#775;iin&#771;.{props.aud(`summaries2-33.mp3`)}</span></p>
    <p className="c8"><span className="c1">You (1), eat.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Nig&#775;g&#775;iu&#331;.{props.aud(`summaries2-34.mp3`)}</span></p>
    <p className="c8"><span className="c1">You (1), eat it.</span></p>
    <p className="c8"><span className="c4">&nbsp;</span></p>
    <p className="c8"><span className="c24 c5">Nig&#775;isitchi</span><span className="c4">.{props.aud(`summaries2-35.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">You all (3+), eat.</span></p>
</div>
} else if (props.mood === 'Negative Imperative Mood') {
return <div>
    <p className="c7 c14"><span className="c4"></span></p>
    <h2 className="c41 c40 c39" id="h.bbykkkc7beqw"><span className="c2">Negative Imperative Mood</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c5">The Negative Imperative Mood is used to express a negative command. The mood marker +na- expresses &quot;do not&quot;. It is only used with 2nd person subjects (you, you two, you all) </span><span className="c5">and</span><span className="c5">&nbsp;3rd person or 1st person objects (her/him/it/them, me/us)</span><span className="c5 c17">.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Naumi, ulla&#331;na&#331;a.{props.aud(`summaries2-36.mp3`)}</span></p>
    <p className="c8"><span className="c1">No, (you) don&#39;t come to me.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Isiqtinnagu tupi&#289;mun.{props.aud(`summaries2-37.mp3`)}</span></p>
    <p className="c8"><span className="c1">Do not let him enter the tent.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Isi&#289;nak.{props.aud(`summaries2-38.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">You (1),</span><span className="c1">&nbsp;don&rsquo;t enter.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Isig&#775;natku.{props.aud(`summaries2-39.mp3`)}</span></p>
    <p className="c8"><span className="c1">You two, do not bring it in.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Uqallautinagi apiqsrikpata.{props.aud(`summaries2-40.mp3`)}</span></p>
    <p className="c8"><span className="c1">Do not tell them when they ask.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Pasi&ntilde;agik tautukkupkik.{props.aud(`summaries2-41.mp3`)}</span></p>
    <p className="c8"><span className="c1">Do not accuse them (2) when you (1) see them (2).</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Killukua&#289;ni&ntilde;agu uqa&#331;aiqpan.{props.aud(`summaries2-42.mp3`)}</span></p>
    <p className="c8"><span className="c1">Do not tell him that he is in the wrong when he stops talking.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c0"><span className="c15 c47 c5 c17"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Optative Mood') {
return <div>
    <h2 className="c30" id="h.xex94knlkubw"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.5rsgarhim0i9"><span className="c2">Optative Mood</span></h2>
    <p className="c40 c39 c44"><span className="c47 c5">Optative Mood is used to express a suggestion or request. It is only used with 1st and 3rd person subjects. The mood markers </span><span className="c5">+la or </span><span className="c5">lI</span><span className="c4">-. The question of &quot;should&quot; is expressed with rising final intonation.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Nig&#775;il&#803;i{props.aud(`summaries2-43.mp3`)}</span></p>
    <p className="c8"><span className="c1">Let her eat; should she eat?</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Nig&#775;il&#803;itku{props.aud(`summaries2-44.mp3`)}</span></p>
    <p className="c8"><span className="c1">Let them two eat it; should they two eat it? </span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Nig&#775;isa{props.aud(`summaries2-45.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Let&rsquo;s eat; should we eat?</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Tautuglagu{props.aud(`summaries2-46.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Let me see it; should I look at it?</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Kiisaimmaa Mood') {
return <div>
    <h2 className="c11" id="h.adeuruxw0051"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.78johyjof8rt"><span className="c2">Kiisaimmaa Mood</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c5">The Kiisaimma Mood markers </span><span className="c24 c5">+p/vu- </span><span className="c5">for the intransitive endings and </span><span className="c24 c5">+p/vaq- </span><span className="c5">for the transitive endings express the conclusion of a series of events.</span><span className="c5">&nbsp; </span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Kiisaimmaa ni&#289;ivaa.{props.aud(`summaries2-47.mp3`)}</span></p>
    <p className="c8"><span className="c1">Finally, she ate it.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Kiisaimmaa ni&#289;ivuq.{props.aud(`summaries2-48.mp3`)}</span></p>
    <p className="c8"><span className="c1">She finally ate.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Kiisaimmaa tautukpa&#289;a.{props.aud(`summaries2-49.mp3`)}</span></p>
    <p className="c8"><span className="c1">Finally, I saw it.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Kiisaimmaa tautukpu&#331;a.{props.aud(`summaries2-50.mp3`)}</span></p>
    <p className="c8"><span className="c1">I finally saw (something).</span></p>
</div>
} else if (props.mood === 'Subordinative Mood Realized') {
return <div>
    <h2 className="c11" id="h.vu0omzufnzfh"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.derlg3aktt8u"><span className="c2">Subordinative Mood Realized</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c26 c5">Subordinative Mood with a</span><span className="c5">&nbsp;</span><span className="c26 c5">realized aspect</span><span className="c5">&nbsp;</span><span className="c26 c5">(+lu-)</span><span className="c4">&nbsp;is a dependent verb that provides more information about the situation which the main verb describes. It tells how (in what manner, by what means, or in what role), or why an action is performed.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c26 c24 c5">Aqpa&#322;&#322;uni</span><span className="c13 c5">&nbsp;tikitchuq.{props.aud(`summaries2-51.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He arrived </span><span className="c12 c5">running</span><span className="c5 c17">.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c26 c24 c5">Puuvraq&#322;utik</span><span className="c13 c5">&nbsp;utiqpat?{props.aud(`summaries2-52.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Did they (two) return </span><span className="c12 c5">by swimming</span><span className="c1">? </span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c26 c24 c5">Naluvlugu</span><span className="c13 c5">&nbsp;atu&#331;itkiga.{props.aud(`summaries2-53.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I did not use it </span><span className="c5 c12">because I did not know of it</span><span className="c5 c17">.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c5">When the main verb expresses an action that is ongoing, completed or habitual, the realized aspect (+lu-) is used to describe </span><span className="c24 c5 c17">how</span><span className="c5">&nbsp;or </span><span className="c24 c5 c17">why</span><span className="c4">&nbsp;the action is done.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8 c37"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Ongoing (Indicative) main verb + realized Subordinative</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Atuqtuq </span><span className="c26 c24 c5">makitavluni</span><span className="c13 c5">.{props.aud(`summaries2-54.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She is singing</span><span className="c12 c5">&nbsp;standing up</span><span className="c1">.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Iriqtuq </span><span className="c26 c24 c5">iqsivl&#803;uni</span><span className="c13 c5">.{props.aud(`summaries2-55.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She hid (just now) </span><span className="c12 c5">because she is afraid</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8 c37"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Completed (Participial) main verb + realized Subordinative</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Atuqtuaq </span><span className="c26 c24 c5">makitavluni</span><span className="c13 c5">.{props.aud(`summaries2-02.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She sang </span><span className="c12 c5">standing up</span><span className="c5 c17">.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Iriqtuaq </span><span className="c26 c24 c5">iqsivl&#803;uni</span><span className="c13 c5">.{props.aud(`summaries2-56.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She hid </span><span className="c12 c5">because she is afraid</span><span className="c1">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c50 c5">Habitual main verb + realized Subordinative</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c24 c5">Atu&#289;uuruq </span><span className="c26 c24 c5">makitavluni</span><span className="c13 c5">.{props.aud(`summaries2-57.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She sings </span><span className="c12 c5">standing up</span><span className="c1">.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Iri&#289;uuruq </span><span className="c26 c24 c5">iqsivl&#803;uni</span><span className="c13 c5">.{props.aud(`summaries2-58.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She usually hides </span><span className="c12 c5">because she is a fearful person</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The realized aspect (+lu-) is also used when the action or state of being indicated is an established fact.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ti&#331;&#331;un atu&#289;nia&#331;itkaa </span><span className="c26 c24 c5">navi&#331;avluni</span><span className="c13 c5">.{props.aud(`summaries2-59.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He will not use the plane </span><span className="c12 c5">because it is broken</span><span className="c1">.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c26 c24 c5">I&ntilde;uqaq&#322;u&#331;a</span><span className="c13 c5">&nbsp;aimmi&ntilde;iaqtu&#331;a.{props.aud(`summaries2-60.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I will stay home </span><span className="c12 c5">since I have guests</span><span className="c1">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">When the main verb expresses a future action, the realized aspect (+lu-) of the Subordinative verb is used to explain </span><span className="c24 c5 c17">why</span><span className="c4">&nbsp;the future action will occur if it is definitely planned to take place.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c26 c24 c5">Sava&#331;niaq&#322;uta</span><span className="c13 c5">&nbsp;qai&ntilde;ia&#331;itchugut.{props.aud(`summaries2-61.mp3`)}</span></p>
    <p className="c7"><span className="c5 c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will not come</span><span className="c12 c5">&nbsp;because we will be working</span><span className="c1">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c26 c24 c5">I&ntilde;uqa&#289;niaq&#322;u&#331;a</span><span className="c13 c5">&nbsp;aimmi&ntilde;iaqtu&#331;a.{props.aud(`summaries2-62.mp3`)}</span></p>
    <p className="c7"><span className="c5 c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will remain at home </span><span className="c12 c5">because I will have guests</span><span className="c1">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Subordinative verbs in combination with the conjunction aasii(&ntilde;) &quot;and then&quot; may be used to describe sequential events.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c24 c5">Makittuaq aasii </span><span className="c23 c26 c24 c5">kigutiksaq&#322;uni.{props.aud(`summaries2-63.mp3`)}</span></p>
    <p className="c7"><span className="c26 c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c17">He stood up and then</span><span className="c15 c12 c5">&nbsp;brushed his teeth.</span></p>
    <p className="c7 c14"><span className="c15 c12 c5"></span></p>
    <p className="c8"><span className="c24 c5">Mali&#331;nia&#289;aak, aasii </span><span className="c26 c24 c5">salummaksa&#289;lutiglu</span><span className="c24 c5">, </span><span className="c26 c24 c5">tilaglutiglu</span><span className="c24 c5">, </span><span className="c26 c24 c5">iqaqsril&#803;utiglu</span><span className="c24 c5">&nbsp;</span><span className="c26 c24 c5">uvli&ntilde;iaqtuk</span><span className="c24 c5">.{props.aud(`summaries2-64.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">They (two) will go (followed) with her, and </span><span className="c12 c5">they (two) will spend the day cleaning up</span><span className="c5 c17">,</span><span className="c12 c5">&nbsp;mopping the floors</span><span className="c5 c17">,</span><span className="c12 c5">&nbsp;and doing the laundry</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c12 c5 c15"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c32 c5">Subordinative Mood also has an unrealized aspect (+vlu- and +&#322;u-)</span><span className="c5">. When the main verb expresses a future action, the unrealized aspect is used to explain </span><span className="c24 c5 c17">how</span><span className="c5">&nbsp;a future action will take place, or in rare cases, to explain </span><span className="c24 c5 c17">why</span><span className="c4">&nbsp;an action will occur if it is a presupposition, or a wish.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c18 c5">Pisua&#289;lu&#331;a</span><span className="c13 c5">&nbsp;qai&ntilde;iaqtu&#331;a.{props.aud(`summaries2-65.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I will come </span><span className="c32 c5 c17">walking</span><span className="c1">.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c18 c5">Quviasugluni</span><span className="c13 c5">&nbsp;iti&#289;niaqtuq.{props.aud(`summaries2-66.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He will wake up </span><span className="c5 c17 c32">happy</span><span className="c1">.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c18">Il&#803;ausuglunuk</span><span className="c13 c5">&nbsp;uqallautinia&#289;ikpuk.{props.aud(`summaries2-67.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c17">We (two) will tell him that </span><span className="c32 c5 c17">we (two) wish to participate</span><span className="c1">.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5 c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c18 c5">Pisuglugu</span><span className="c13 c5">&nbsp;piu&#331;.{props.aud(`summaries2-68.mp3`)}</span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c17">(You) Tell him that </span><span className="c32 c5 c17">you want to have it</span><span className="c5 c17">.</span></p>
</div>
} else if (props.mood === 'Subordinative Mood Unrealized') {
return <div>
    <h2 className="c11" id="h.z74nrgsv52an"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.ddvhtvu9nkuj"><span className="c2">Subordinative Mood Unrealized</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c32 c5">Subordinative Mood verb with an unrealized aspect (+vlu- and +&#322;u-) </span><span className="c5">is a dependent verb that provides more information about the situation which the main verb describes. It tells how (in what manner, by what means, or in what role), or why an action will be performed. When the main verb expresses a future action, the unrealized aspect (+vlu- and +&#322;u-) is used to explain </span><span className="c24 c5 c17">how</span><span className="c4">&nbsp;a future action will take place.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c18 c5">Qam&#331;uil&#803;uni</span><span className="c13 c5">&nbsp;itiqtinnia&#289;aa.{props.aud(`summaries2-69.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He will wake her up </span><span className="c32 c5 c17">with his snoring</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c18 c5">Pisua&#289;lu&#331;a</span><span className="c13 c5">&nbsp;qai&ntilde;iaqtu&#331;a.{props.aud(`summaries2-65.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I will come </span><span className="c32 c5 c17">walking</span><span className="c5 c17">.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c18 c5">Quviasugluni</span><span className="c13 c5">&nbsp;iti&#289;niaqtuq.{props.aud(`summaries2-66.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He will wake up </span><span className="c32 c5 c17">happy</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">In rare cases, the unrealized aspect (+vlu- and +&#322;u-) is also used to explain why an action will occur if it is a presupposition, or a wish.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c18 c5">Il&#803;ausuglunuk</span><span className="c13 c5">&nbsp;uqallautinia&#289;ikpuk.{props.aud(`summaries2-67.mp3`)}</span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c17">We (two) will tell him that </span><span className="c32 c5 c17">we (two) wish to participate</span><span className="c1">.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5 c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c18 c5">Pisuglugu</span><span className="c13 c5">&nbsp;piu&#331;.{props.aud(`summaries2-68.mp3`)}</span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c5 c17">(You) Tell him that </span><span className="c32 c5 c17">you want to have it</span><span className="c5 c17">.</span></p>
    <p className="c8 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c26 c5">Subordinative Mood also has a realized aspect (+lu-)</span><span className="c5">. When the main verb expresses an action that is ongoing, completed or habitual, the realized aspect is used to describe </span><span className="c24 c5 c17">how</span><span className="c5">&nbsp;or </span><span className="c24 c5 c17">why</span><span className="c5">&nbsp;the action is done.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Atuqtuaq </span><span className="c24 c5 c26">makitavluni</span><span className="c13 c5">.{props.aud(`summaries2-02.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She sang </span><span className="c12 c5">standing up</span><span className="c5 c17">.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Iriqtuaq </span><span className="c26 c24 c5">iqsivl&#803;uni</span><span className="c13 c5">.{props.aud(`summaries2-56.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">She hid </span><span className="c12 c5">because she is afraid</span><span className="c5 c17">.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c7"><span className="c4">The realized aspect (+lu-) is also used when the action or state of being indicated is an established fact.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ti&#331;&#331;un atu&#289;nia&#331;itkaa </span><span className="c26 c24 c5">navi&#331;avluni</span><span className="c13 c5">.{props.aud(`summaries2-59.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">He will not use the plane </span><span className="c12 c5">because it is broken</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">With a main verb in the future tense, the realized aspect (+lu-) is used to explain </span><span className="c24 c5 c17">why</span><span className="c4">&nbsp;the future action will occur if it is definitely planned to take place.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c26 c24 c5">Sava&#331;niaq&#322;uta</span><span className="c13 c5">&nbsp;qai&ntilde;ia&#331;itchugut.{props.aud(`summaries2-61.mp3`)}</span></p>
    <p className="c7"><span className="c5 c17">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will not come</span><span className="c12 c5">&nbsp;because we will be working</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c21 c40"><span className="c4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c2"></span></p>
</div>
} else if (props.mood === 'Negative Precessive Mood I') {
return <div>
    <h2 className="c11" id="h.ke0ocrxayifo"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.f2nfj29m8ydf"><span className="c2">Negative Precessive Mood I</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Negative Precessive Mood I (dependent verb) expresses the event that must happen before the event in the independent verb happens. This mood uses the Subordinative Mood endings.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautu&#331;aunnagu ukpi&#289;inia&#331;i&ntilde;&ntilde;iraatigut.{props.aud(`summaries3-01.mp3`)}</span></p>
    <p className="c8"><span className="c1">He said he would not believe us without witnessing or seeing it.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Katchu&#331;aunnani ani&ntilde;ia&#331;i&ntilde;&ntilde;iruaq.{props.aud(`summaries3-02.mp3`)}</span></p>
    <p className="c8"><span className="c1">She said she was not leaving until she was totally satisfied.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Naatchi&#331;aunnatik ai&ntilde;ia&#331;il&#803;gitchuk.{props.aud(`summaries3-03.mp3`)}</span></p>
    <p className="c8"><span className="c1">They (2) will not come home again until they (2) have finished.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Aitchuusiaqa&#331;aunnati&#331; aninia&#331;ilgitchut taapkua.{props.aud(`summaries3-04.mp3`)}</span></p>
    <p className="c8"><span className="c1">Those will not leave until they have been given gifts.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Iqa&#289;i&#331;aunnasik ni&#289;ipka&#289;nia&#331;itkivsik.{props.aud(`summaries3-05.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I will not feed until you have washed.</span></p>
</div>
} else if (props.mood === 'Negative Precessive Mood II (-kaluaqtinna-)') {
return <div>
    <h2 className="c11" id="h.fnyno26sjbno"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.w0f43kpapjn4"><span className="c2">Negative Precessive Mood II (-kaluaqtinna-)</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The Negative Precessive Mood II expresses the event that did not happen before the event in the independent verb. The +k/galuaqtinna- mood marker is paired with the subject-object markers of the Contemporative I Mood. The mood marker is interchangeable with &plusmn;&#331;aisillu-. The subject of a transitive Negative Precessive Mood II dependent verb is presented by the Terminalis case.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkaluaqtinnagu igisimaraa.{props.aud(`summaries3-06.mp3`)}</span></p>
    <p className="c8"><span className="c1">He threw it away before she had seen it.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkaluaqtinnagu aakamiñun igisimaraa.{props.aud(`summaries3-07.mp3`)}</span></p>
    <p className="c8"><span className="c1">He threw it away before his (own) mother had seen it.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkaluaqtinnagu uvaptignun utiqtisimaraa.{props.aud(`summaries3-08.mp3`)}</span></p>
    <p className="c8"><span className="c1">She returned it before we (2) had seen it.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Naatchigaluaqtinna&#331;a aigaluaqtaak.{props.aud(`summaries3-09.mp3`)}</span></p>
    <p className="c8"><span className="c1">They (2) came to get it before I finished.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Naatkaluaqtinnagu aggisisukkaluaqtaa.{props.aud(`summaries3-10.mp3`)}</span></p>
    <p className="c8"><span className="c1">He wanted to take it home before it was done.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Makitkaluaqtinna&#331;a tigluktu&#289;aluaqsimaruaq.{props.aud(`summaries3-11.mp3`)}</span></p>
    <p className="c8"><span className="c1">(I found out) that she knocked on the door before I got out of bed.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;isa&#289;igaluaqtinnani anil&#803;iqtuq.{props.aud(`summaries3-12.mp3`)}</span></p>
    <p className="c8"><span className="c1">She went out before she was recognized.</span></p>
    <p className="c0"><span className="c1"></span></p>
</div>
} else if (props.mood === 'Negative Precessive Mood II (-ŋaisillu-)') {
return <div>
    <h2 className="c11" id="h.56v7z11hmqcy"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.qg97dtqxxoqc"><span className="c2">Negative Precessive Mood II (-ŋaisillu-)</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The Negative Precessive Mood II expresses the event that did not happen before the event in the independent verb. The &plusmn;&#331;aisillu- mood marker is paired with the subject-object markers of the Contemporative I Mood. The mood marker is interchangeable with +k/galuaqtinna-. The subject of a transitive Negative Precessive Mood II dependent verb is presented by the Terminalis case.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Tautu&#331;aisillugu aġnanun igisimaraa.{props.aud(`summaries3-13.mp3`)}</span></p>
    <p className="c8"><span className="c1">He threw it away before the women had seen it.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Naatchi&#331;aisillu&#331;a aigaluaqtaak.{props.aud(`summaries3-14.mp3`)}</span></p>
    <p className="c8"><span className="c1">They (2) came to get it before I finished.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Naan&#331;aisillugu aggisisukkaluaqtaa.{props.aud(`summaries3-15.mp3`)}</span></p>
    <p className="c8"><span className="c1">He wanted to take it home before it was done.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Makin&#331;aisillu&#331;a tigluktu&#289;aluaqsimaruaq.{props.aud(`summaries3-16.mp3`)}</span></p>
    <p className="c8"><span className="c1">(I found out) that she knocked on the door before I got out of bed.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;isa&#289;i&#331;aisilluni anil&#803;iqtuq.{props.aud(`summaries3-17.mp3`)}</span></p>
    <p className="c8"><span className="c1">She went out before she was recognized.</span></p>
    <p className="c0"><span className="c1"></span></p>
</div>
} else if (props.mood === 'Negative Precessive Mood III') {
return <div>
    <h2 className="c11" id="h.9tkycq7gphpc"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.f0q8v3e8o67m"><span className="c2">Negative Precessive Mood III</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Negative Precessive Mood III (dependent verb) expresses the event that did not happen before the event expressed in the independent verb happened. The Negative Precessive Mood III marker +k/aglua&#289;na- uses the Subordinative Mood subject and object endings.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkalua&#289;nagu aniruaq.{props.aud(`summaries2-04.mp3`)}</span></p>
    <p className="c8"><span className="c1">She left without seeing him.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Iqaqsrigalua&#289;nak ani&ntilde;ak.{props.aud(`summaries3-18.mp3`)}</span></p>
    <p className="c8"><span className="c1">(You) do not go out before doing the laundry.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c4">The following examples compare the Affirmative Subordinative with the Negative Subordinative.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5">a.</span><span className="c13 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aqpa&#322;&#322;uni tikitchuaq.{props.aud(`summaries3-19.mp3`)}</span></p>
    <p className="c8"><span className="c1">He arrived running.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Aqpatkalua&#289;nani tikitchuaq.{props.aud(`summaries3-20.mp3`)}</span></p>
    <p className="c8"><span className="c1">He arrived without running.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c3"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c13 c5">I&#289;itchaqqaaq&#322;ugi qaugait siqurua&#331;a.{props.aud(`summaries3-21.mp3`)}</span></p>
    <p className="c8"><span className="c1">I fell asleep after defeathering the ducks.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">I&#289;itcha&#289;alua&#289;nagi qaugait siqurua&#331;a.{props.aud(`summaries4-01.mp3`)}</span></p>
    <p className="c8"><span className="c1">I fell asleep without defeathering the ducks.</span></p>
    <p className="c8 c14"><span className="c1"></span></p>
    <p className="c3"><span className="c5">c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c13 c5">Apiqsruqługik aniruaŋa.{props.aud(`summaries4-02.mp3`)}</span></p>
    <p className="c8"><span className="c1">I went out (after) asking them (two).</span></p>
    <p className="c8 c14"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Apiqsru&#289;alua&#289;nagik anirua&#331;a.{props.aud(`summaries4-03.mp3`)}</span></p>
    <p className="c8"><span className="c1">I went out without asking them (two).</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Consequential Mood I') {
return <div>    
    <h2 className="c41 c40 c39" id="h.o98it6my92vg"><span className="c2">Consequential Mood I</span></h2>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7"><span className="c26 c5">Consequential Mood I</span><span className="c4">&nbsp;is most commonly expressed in English as &ldquo;whenever&rdquo; or &ldquo;when&rdquo;. It is not used to express &quot;when&quot; in the future sense.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c24 c5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Iti</span><span className="c26 c24 c5">&#289;a</span><span className="c16 c5">mi</span><span className="c24 c5">&nbsp;uqallautira</span><span className="c19 c5">a.{props.aud(`summaries4-04.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">He</span><span className="c5 c17">&nbsp;told </span><span className="c5 c43 c17">her</span><span className="c5 c17">&nbsp;when </span><span className="c5 c27 c17">he</span><span className="c1">&nbsp;woke up.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Akima</span><span className="c26 c24 c5">mma</span><span className="c19 c5">n</span><span className="c24 c5">&nbsp;quyapaluktu</span><span className="c16 c5">&#331;a</span><span className="c13 c5">.{props.aud(`summaries4-05.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">I</span><span className="c5 c17">&nbsp;was so happy </span><span className="c12 c5">when</span><span className="c5 c17">&nbsp;</span><span className="c5 c43 c17">he</span><span className="c1">&nbsp;won.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c24 c5">Aquvsalaa&#289;</span><span className="c26 c24 c5">ma</span><span className="c19 c5">&#331;nik</span><span className="c24 c5">&nbsp;iglapaluktu</span><span className="c16 c5">gut</span><span className="c13 c5">.{props.aud(`summaries4-06.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">We</span><span className="c5 c17">&nbsp;sure laughed </span><span className="c12 c5">when</span><span className="c5 c17">&nbsp;</span><span className="c5 c43 c17">they (two)</span><span className="c1">&nbsp;fell on their butts.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c24 c5">Puggutchiqi</span><span className="c26 c24 c5">ga</span><span className="c16 c5">ma</span><span className="c24 c5">&nbsp;naalaktua&#289;uuru</span><span className="c16 c5">&#331;a</span><span className="c13 c5">&nbsp;sil&#803;akkuaqtuanik.{props.aud(`summaries4-07.mp3`)}</span></p>
    <p className="c8"><span className="c12 c5">Whenever</span><span className="c5 c17">&nbsp;</span><span className="c5 c27 c17">I</span><span className="c5 c17">&nbsp;do the dishes </span><span className="c5 c27 c17">I</span><span className="c5 c17">&nbsp;always listen to the radio.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">This verb mood is usually paired with an independent verb that is ongoing (Indicative), completed (Participial), or habitua</span><span className="c5">l.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c26 c5">Consequential Mood I </span><span className="c5">has a &ldquo;realized&rdquo; aspect expressed in English as &ldquo;when (in the past)&rdquo;, and &ldquo;whenever&rdquo;, while the </span><span className="c32 c5">Conditional Mood I</span><span className="c4">&nbsp;has an &ldquo;unrealized&rdquo; aspect expressed in English as &ldquo;when (in the future)&rdquo; and &ldquo;if&rdquo;.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Consequential:</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ai</span><span className="c26 c24 c5">ga</span><span className="c16 c5">mi </span><span className="c24 c5">ni&#289;irua</span><span className="c16 c5 c23">q.{props.aud(`summaries4-08.mp3`)}</span></p>
    <p className="c8"><span className="c12 c5">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c27 c17">he</span><span className="c5 c17">&nbsp;came home, </span><span className="c5 c27 c17">he</span><span className="c1">&nbsp;ate.</span></p>
    <p className="c0"><span className="c2"></span></p>
    <p className="c8"><span className="c2">Conditional:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ai</span><span className="c18 c5">gu</span><span className="c16 c5">mi </span><span className="c24 c5">ni&#289;i&ntilde;iaq</span><span className="c23 c16 c5">tuq.{props.aud(`summaries4-09.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c27 c17">he</span><span className="c5 c17">&nbsp;comes home, </span><span className="c5 c27 c17">he</span><span className="c1">&nbsp;will eat.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Consequential:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ikayu</span><span className="c26 c24 c5">&#289;a</span><span className="c24 c5 c29">m</span><span className="c16 c5">ni</span><span className="c24 c5">&nbsp;</span><span className="c16 c5">Si&#289;miaq</span><span className="c24 c5">&nbsp;quyasuur</span><span className="c23 c16 c5">uq.{props.aud(`summaries4-10.mp3`)}</span></p>
    <p className="c8"><span className="c12 c5">Whenever</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">I</span><span className="c5 c17">&nbsp;help </span><span className="c5 c27 c17">him</span><span className="c5 c17">, </span><span className="c5 c27 c17">Si&#289;miaq</span><span className="c1">&nbsp;is always thankful.</span></p>
    <p className="c0"><span className="c2"></span></p>
    <p className="c8"><span className="c2">Conditional:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ikayu</span><span className="c18 c5">&#289;u</span><span className="c24 c5 c29">m</span><span className="c16 c5">ni</span><span className="c24 c5">&nbsp;</span><span className="c16 c5">Si&#289;miaq</span><span className="c24 c5">&nbsp;quyaniaqtu</span><span className="c23 c16 c5">q.{props.aud(`summaries4-11.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">If</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">I</span><span className="c5 c17">&nbsp;help </span><span className="c5 c27 c17">him</span><span className="c5 c17">, </span><span className="c5 c27 c17">Si&#289;miaq</span><span className="c1">&nbsp;will be thankful.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Consequential Mood II') {
return <div>
    <h2 className="c11" id="h.1888rmr5ac9u"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.58d9i9c8wgo1"><span className="c2">Consequential Mood II</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Consequential Mood II generally means, &quot;every time one Vs (her/him/it), one...&quot;. It is usually attached to an independent verb that is habitual (+[s]uu- postbase, etc.). This dependent verb mood combines the -tuaq(-) postbase with the realized Consequential Mood marker, becoming -tua&#289;a-.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Annisitua&#289;amiu&#331; qa&#289;&#289;isisuugaa.{props.aud(`summaries4-12.mp3`)}</span></p>
    <p className="c8"><span className="c1">Every time she takes him outside, she brings him here.</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Anitua&#289;ami igla&#331;aqpaguuruq.{props.aud(`summaries4-13.mp3`)}</span></p>
    <p className="c8"><span className="c1">Every time he goes outside he always has a big smile.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Taututuaqami&#331;a/Taututua&#289;ami&#331;a nulu&#289;a&#289;uugaa&#331;a.{props.aud(`summaries4-14.mp3`)}</span></p>
    <p className="c8"><span className="c1">Every time she sees me, she waves at me.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ni&#289;i&#331;aisuaqami/Ni&#289;i&#331;aisua&#289;ami siqui&ntilde;&ntilde;a&#289;uuruq.{props.aud(`summaries4-15.mp3`)}</span></p>
    <p className="c8"><span className="c1">Every time she finishes eating, she immediately falls asleep.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Conditional Mood I') {
return <div>
    <h2 className="c11" id="h.drqi96q9aqr8"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.xc30f1iqmeym"><span className="c2">Conditional Mood I</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Conditional Mood I is most commonly expressed in English as &ldquo;when (in the future)&rdquo; and &ldquo;if&rdquo;. It is not used to express &ldquo;whenever&rdquo; or &ldquo;when&rdquo; an event has been completed.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Iti</span><span className="c18 c5">&#289;u</span><span className="c16 c5">mi</span><span className="c24 c5">&nbsp;uqallautinia&#289;a</span><span className="c16 c5">a</span><span className="c24 c5 c29">tin</span><span className="c4">.{props.aud(`summaries4-16.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c27 c17">he</span><span className="c5 c17">&nbsp;wakes up, </span><span className="c5 c27 c17">he</span><span className="c5 c17">&nbsp;will tell </span><span className="c15 c5 c17 c29">you.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Iti</span><span className="c18 c5">qpa</span><span className="c24 c5 c29">n</span><span className="c24 c5">&nbsp;uqallautisa&#289;</span><span className="c16 c5">i&ntilde;</span><span className="c23 c19 c5">.{props.aud(`summaries-73.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">he</span><span className="c5 c17">&nbsp;wakes up, </span><span className="c5 c27 c17">you</span><span className="c5 c17">&nbsp;tell </span><span className="c15 c5 c17 c29">him.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Akima</span><span className="c18 c5">kpa</span><span className="c24 c5 c29">n</span><span className="c24 c5">&nbsp;quyapalugniaqtu</span><span className="c16 c5">&#331;a.{props.aud(`summaries4-17.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">If</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">he</span><span className="c5 c17">&nbsp;wins, </span><span className="c5 c27 c17">I</span><span className="c1">&nbsp;will be happy.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Il&#803;isa&#289;i</span><span className="c18 c5">kpa</span><span className="c24 c5 c29">n</span><span className="c16 c5">ni</span><span className="c24 c5">&nbsp;qimaaniaq</span><span className="c16 c5">tuq.{props.aud(`summaries4-18.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">they (3+)</span><span className="c5 c17">&nbsp;recognize </span><span className="c5 c27 c17">him</span><span className="c5 c17">, </span><span className="c5 c27 c17">he</span><span className="c1">&nbsp;will run away.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">This dependent verb is usually paired with an independent verb in the future tense or imperative mood.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">The </span><span className="c32 c5">Conditional Mood I</span><span className="c5">&nbsp;has an &ldquo;unrealized&rdquo; aspect expressed in English as &ldquo;when (in the future)&rdquo; and &ldquo;if&rdquo; , while the </span><span className="c26 c5">Consequential Mood I </span><span className="c4">has a &ldquo;realized&rdquo; aspect expressed in English as &ldquo;when (in the past)&rdquo;, and &ldquo;whenever&rdquo;.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Conditional:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ai</span><span className="c18 c5">gu</span><span className="c16 c5">mi </span><span className="c24 c5">ni&#289;i&ntilde;iaq</span><span className="c23 c16 c5">tuq.{props.aud(`summaries4-09.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c27 c17">he</span><span className="c5 c17">&nbsp;comes home, </span><span className="c5 c27 c17">he</span><span className="c1">&nbsp;will eat.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c2">Consequential:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ai</span><span className="c26 c24 c5">ga</span><span className="c5 c16">mi </span><span className="c24 c5">ni&#289;irua</span><span className="c23 c16 c5">q.{props.aud(`summaries4-08.mp3`)}</span></p>
    <p className="c8"><span className="c12 c5">When</span><span className="c5 c17">&nbsp;</span><span className="c5 c27 c17">he</span><span className="c5 c17">&nbsp;came home, </span><span className="c5 c27 c17">he</span><span className="c1">&nbsp;ate.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Conditional:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ikayu</span><span className="c18 c5">&#289;u</span><span className="c24 c5 c29">m</span><span className="c16 c5">ni</span><span className="c5 c24">&nbsp;</span><span className="c16 c5">Si&#289;miaq</span><span className="c24 c5">&nbsp;quyaniaqtu</span><span className="c23 c16 c5">q.{props.aud(`summaries4-11.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">If</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">I</span><span className="c5 c17">&nbsp;help </span><span className="c5 c27 c17">him</span><span className="c5 c17">, </span><span className="c5 c27 c17">Si&#289;miaq</span><span className="c1">&nbsp;will be thankful.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c5">&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;  </span><span className="c2">Consequential:</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Ikayu</span><span className="c26 c24 c5">&#289;a</span><span className="c24 c5 c29">m</span><span className="c16 c5">ni</span><span className="c24 c5">&nbsp;</span><span className="c16 c5">Si&#289;miaq</span><span className="c24 c5">&nbsp;quyasuur</span><span className="c23 c16 c5">uq.{props.aud(`summaries4-10.mp3`)}</span></p>
    <p className="c8"><span className="c12 c5">Whenever</span><span className="c5 c17">&nbsp;</span><span className="c5 c17 c29">I</span><span className="c5 c17">&nbsp;help </span><span className="c5 c27 c17">him</span><span className="c5 c17">, </span><span className="c5 c27 c17">Si&#289;miaq</span><span className="c1">&nbsp;is always thankful.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7"><span className="c5">The addition of a +k/galuaq- postbase with this set of endings changes the verb mood to Concessive Mood II unrealized, meaning &quot;</span><span className="c5 c17">even if</span><span className="c5">&quot;. When adding a -tuaq(-) postbase with this set of endings, the verb mood becomes Conditional Mood II, meaning &quot;</span><span className="c5 c17">if ever or whenever</span><span className="c5">&quot;.</span></p>
    </div>
} else if (props.mood === 'Conditional Mood II') {
return <div>
    <h2 className="c41 c40 c39" id="h.mmp5vpgndq11"><span className="c2">Conditional Mood II</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Consequential Mood II generally means, &quot;if ever one Vs (her/him/it), one...&quot;. It is usually attached to an independent verb in the future tense or Imperative (command) Mood. This dependent verb mood combines the -tuaq(-) postbase with the unrealized Conditional Mood marker, becoming -tua&#289;u-.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Anitua&#289;uvit malikata&#289;nia&#289;aatin.{props.aud(`summaries4-19.mp3`)}</span></p>
    <p className="c8"><span className="c1">If you ever go out, he will chase you.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;itchu&#289;itua&#289;umi uumitchapalugniaqtuq.{props.aud(`summaries4-20.mp3`)}</span></p>
    <p className="c8"><span className="c1">if ever he finds out he will become very angry</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Taututua&#289;upku uqallautinia&#289;iga.{props.aud(`summaries4-21.mp3`)}</span></p>
    <p className="c8"><span className="c1">If ever I see her, I will tell her.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Unisua&#289;upku taututqig&ntilde;ia&#331;itki&ntilde;.{props.aud(`summaries4-22.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">If you ever leave it you will not see it ever again.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Concessive Mood I') {
return <div>
    <h2 className="c11" id="h.h03q49x6wp6v"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.1bede4sta80q"><span className="c2">Concessive Mood I</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Concessive Mood I generally means, &quot;although V-ing (her/him/it)...&quot; It is usually attached to an independent verb that occurred in the past or &quot;immediate&quot; past. This dependent verb combines the +k/galuaq- postbase with the Subordinative Mood realized marker, becoming +k/galuaq&#322;u-.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">I&#7735;isa&#289;igaluaq&#322;ugu pa&#289;la&#331;itkaa.{props.aud(`summaries4-23.mp3`)}</span></p>
    <p className="c8"><span className="c1">Although he recognized her, he did not greet, welcome her.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">I&#7735;isimagaluaq&#322;ugu atu&#331;itcha&#289;a.{props.aud(`summaries4-24.mp3`)}</span></p>
    <p className="c8"><span className="c1">Although I knew the song, I did not sing it.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Isi&#289;aluaq&#322;uni anitqiksiqtuaq.{props.aud(`summaries4-25.mp3`)}</span></p>
    <p className="c8"><span className="c1">Although he went inside, he left quickly.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Malikkaluaq&#322;ugu, qanuq pi&#331;i&ntilde;migaa.{props.aud(`summaries4-26.mp3`)}</span></p>
    <p className="c8"><span className="c1">Although she followed him, at the end, she did not say or do anything to him.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Ulla&#331;itchaa tautukkaluaq&#322;ugu.{props.aud(`summaries4-27.mp3`)}</span></p>
    <p className="c8"><span className="c1">He did not approach her, although he saw her.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkaluaq&#322;ugu qanu&#331;i&ntilde;miuq.{props.aud(`summaries4-28.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">Although she saw him, she did not do anything.</span></p>
</div>
} else if (props.mood === 'Concessive Mood II Unrealized') {
return <div>
    <h2 className="c11" id="h.e8hk6lqsdvue"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.qe6yifqd7qlz"><span className="c2">Concessive Mood II Unrealized</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c32 c5">Concessive Mood II</span><span className="c5">&nbsp;generally means, &quot;even if one Vs (her/him/it)&quot;. It is usually attached to an independent verb in the future tense or Imperative (command) Mood. This dependent verb mood combines the +k/galuaq- postbase with the unrealized Conditional Mood endings, becoming the mood markers </span><span className="c32 c5">+k/galua&#289;u- </span><span className="c5">or</span><span className="c32 c5">&nbsp;+k/galuaqpa-</span><span className="c4">.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Uqallauti</span><span className="c18 c5">galua&#289;u</span><span className="c19 c5">m</span><span className="c16 c5">ni</span><span className="c24 c5">&nbsp;qanu&#289;nia&#331;i&#7735;gitchu</span><span className="c16 c5">q</span><span className="c13 c5">.{props.aud(`summaries4-29.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">He</span><span className="c5 c17">&nbsp;will not respond again </span><span className="c32 c5 c17">even if </span><span className="c5 c43 c17">I</span><span className="c5 c17">&nbsp;tell </span><span className="c5 c27 c17">him</span><span className="c1">.</span></p>
    <p className="c8 c14"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">I&#7735;isa&#289;i</span><span className="c18 c5">galua&#289;u</span><span className="c19 c5">m</span><span className="c16 c5">&#331;a</span><span className="c24 c5">&nbsp;naluni&ntilde;ia&#289;i</span><span className="c16 c5">k</span><span className="c19 c5">pi&ntilde;</span><span className="c24 c5">.{props.aud(`summaries4-30.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">Even if </span><span className="c5 c43 c17">you</span><span className="c5 c17">&nbsp;say that </span><span className="c5 c43 c17">you</span><span className="c5 c17">&nbsp;recognize </span><span className="c5 c27 c17">me</span><span className="c5 c17">, </span><span className="c5 c27 c17">I</span><span className="c5 c17">&nbsp;will say that </span><span className="c5 c27 c17">I</span><span className="c5 c17">&nbsp;do not know </span><span className="c5 c43 c17">you</span><span className="c1">.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Isi</span><span className="c18 c5">&#289;aluaqpa</span><span className="c19 c5">n</span><span className="c24 c5">&nbsp;aninnia&#289;a</span><span className="c19 c5">a</span><span className="c16 c5">t</span><span className="c1">.{props.aud(`summaries4-31.mp3`)}</span></p>
    <p className="c8"><span className="c5 c27 c17">They</span><span className="c5 c17">&nbsp;will throw </span><span className="c5 c43 c17">him</span><span className="c5 c17">&nbsp;out </span><span className="c32 c5 c17">even if </span><span className="c5 c43 c17">he</span><span className="c1">&nbsp;should enter.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">I&#7735;it</span><span className="c18 c5">kalua&#289;u</span><span className="c19 c5">p</span><span className="c24 c5 c29">ku</span><span className="c24 c5">&nbsp;atuqtinnia&#331;itka</span><span className="c24 c5 c29">a</span><span className="c16 c5">t</span><span className="c24 c5">.{props.aud(`summaries4-32.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">Even if </span><span className="c5 c43 c17">you</span><span className="c5 c17">&nbsp;learn </span><span className="c5 c17 c29">it</span><span className="c5 c17">, </span><span className="c5 c27 c17">they</span><span className="c5 c17">&nbsp;will not let you sing </span><span className="c5 c17 c29">it</span><span className="c1">.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Tautuk</span><span className="c18 c5">kaluaqpa</span><span className="c24 c5 c29">gu</span><span className="c24 c5">&nbsp;qanuq pi&ntilde;&ntilde;asi</span><span className="c19 c5">u&#331;</span><span className="c24 c5">.{props.aud(`summaries4-33.mp3`)}</span></p>
    <p className="c8"><span className="c32 c5 c17">Even if </span><span className="c5 c43 c17">he</span><span className="c5 c17">&nbsp;sees </span><span className="c5 c17 c29">her</span><span className="c5 c17">, </span><span className="c5 c27 c17">(you all 3+)</span><span className="c5 c17">&nbsp;do not say or do anything to </span><span className="c5 c43 c17">him</span><span className="c5 c17">.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Concessive Mood II Realized') {
return <div>
    <h2 className="c11" id="h.r27mcw4ui4vs"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.xkgui7kzfz18"><span className="c2">Concessive Mood II Realized</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Concessive Mood II with a realized aspect generally means, &quot;even when one Vs (her/him/it)...&quot;. It is usually attached to an independent verb that is completed (occurred in the past or &quot;immediate&quot; past). This dependent verb combines the +k/galuaq- postbase with the Consequential Mood marker, becoming +k/galua&#289;a- or +k/galua&#289;ma-.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Uqallautigalua&#289;amni qanulaitchuq.{props.aud(`summaries4-34.mp3`)}</span></p>
    <p className="c8"><span className="c1">He does not respond even when I tell him.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">I&#7735;isa&#289;igalua&#289;magit su&#289;auttat qanulaitkiga.{props.aud(`summaries4-35.mp3`)}</span></p>
    <p className="c8"><span className="c1">Even when she recognizes some things, I do not say anything to her.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Isi&#289;alua&#289;man qanulaitkaat.{props.aud(`summaries4-36.mp3`)}</span></p>
    <p className="c8"><span className="c1">Even when he comes to visit, they do not do or say anything to him, or give him any hassle when he enters.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Tautukkalua&#289;magu suliqutigilaitkikput.{props.aud(`summaries4-37.mp3`)}</span></p>
    <p className="c8"><span className="c1">Even when he sees her, it is of no consequence to us.</span></p>
    <p className="c21 c38 c14 c39"><span className="c10"></span></p>
</div>
} else if (props.mood === 'Concessive Mood III') {
return <div>
    <h2 className="c41 c40 c39 c38" id="h.gnm7g6hjyqs7"><span className="c2">Concessive Mood III</span></h2>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Concessive Mood III with a realized aspect generally means, &quot;because, since one Vs (her/him/it)...&quot; It is usually attached to an independent verb that is completed (occurred in the past). This dependent verb mood combines the +k/galuaq- postbase with the realized Consequential Mood marker, becoming +k/galua&#289;a- or +k/galua&#289;ma-.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Aptana&#289;alua&#289;man unitcha&#289;a.{props.aud(`summaries4-38.mp3`)}</span></p>
    <p className="c8"><span className="c1">I left him because he creates so many tasks to be done.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Killukuaqta&#289;alua&#289;matku uuktua&#289;uktaqpuk.{props.aud(`summaries4-39.mp3`)}</span></p>
    <p className="c8"><span className="c1">Because they (two) were making mistakes on it, we (two) wanted to try it.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Malikkalua&#289;magu malirraqsira&#289;ali.{props.aud(`summaries4-40.mp3`)}</span></p>
    <p className="c8"><span className="c1">Since she was following him around, I decided to follow him too.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Qiagalua&#289;man qa&#289;&#289;isira&#289;a.{props.aud(`summaries4-41.mp3`)}</span></p>
    <p className="c8"><span className="c5 c17">I brought her with me because she was crying so much.</span></p>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c21 c14"><span className="c10"></span></p>
</div>
} else if (props.mood === 'Successive Mood Unrealized' || props.mood === 'Successive Mood Realized') {
return <div>
    <h2 className="c11" id="h.2cahkv8zd1w8"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.oaqotef6cmk"><span className="c2">Successive Mood</span></h2>
    <p className="c21 c14"><span className="c10"></span></p>
    <p className="c7"><span className="c4">Successive Mood generally means &ldquo;after V-ing for a while&rdquo;. &nbsp;It is used to describe an event that has already happened (realized) or has the potential to happen (unrealized).</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Consequential Mood endings are used to express a realized event and the Conditional Mood endings are used to express an unrealized event.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Successive Mood combines the mood marker +k/galuaqa- or +k/galua&#289;a- with the Consequential Mood endings; and the mood marker +k/galua&#289;u- with the Conditional Mood endings.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Anii&#289;utigaluaqamiu&#331; utqutigaa.{props.aud(`summaries4-42.mp3`)}</span></p>
    <p className="c8"><span className="c13 c5">Anii&#289;utigalua&#289;amiu&#331; utqutigaa.</span></p>
    <p className="c8"><span className="c1">After being outside with her for a while, he brought her inside.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Anii&#289;utigalua&#289;umiu&#331; utqutinia&#289;aa.{props.aud(`summaries4-43.mp3`)}</span></p>
    <p className="c8"><span className="c1">After being outside with her for a while, he will bring her inside. &nbsp;</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Aniiqsua&#289;aluaqami isiqtuq.{props.aud(`summaries4-44.mp3`)}</span></p>
    <p className="c8"><span className="c13 c5">Aniiqsua&#289;alua&#289;ami isiqtuq.</span></p>
    <p className="c8"><span className="c1">After being outside for a while, he entered.</span></p>
    <p className="c0"><span className="c24 c5 c17 c36"></span></p>
    <p className="c8"><span className="c13 c5">Aniiqsua&#289;alua&#289;umi isi&#289;niaqtuq.{props.aud(`summaries4-45.mp3`)}</span></p>
    <p className="c8"><span className="c1">After being outside for a while, he will enter.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Ipiqtusukkaluaqami quviatchallaktuq.{props.aud(`summaries4-46.mp3`)}</span></p>
    <p className="c8"><span className="c13 c5">Ipiqtusukkalua&#289;ami quviatchallakuq.</span></p>
    <p className="c8"><span className="c1">After being sad and depressed for a while, she gradually became happier.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ipiqtusukkalua&#289;umi quviatchallagniaqtuq.{props.aud(`summaries4-47.mp3`)}</span></p>
    <p className="c8"><span className="c1">After being sad and depressed for a while, she will gradually become happier.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Utaqqigaluaqamisin aniruaq.{props.aud(`summaries4-48.mp3`)}</span></p>
    <p className="c8"><span className="c13 c5">Utaqqigalua&#289;amisin aniruaq.</span></p>
    <p className="c8"><span className="c1">He waited for you for a while, then left.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Utaqqigalua&#289;umisin ani&ntilde;iaqtuq.{props.aud(`summaries4-49.mp3`)}</span></p>
    <p className="c8"><span className="c1">After waiting for you he will go out/leave.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Arakkaluaqamiu&#331;/Arakkalua&#289;amiu&#331; qapiqtuq.{props.aud(`summaries4-50.mp3`)}</span></p>
    <p className="c8"><span className="c1">After encouraging her for a while, he gave up.</span></p>
    <p className="c8"><span className="c13 c5">Arakkalua&#289;umiu&#331; qapi&#289;niaqtuq.{props.aud(`summaries4-51.mp3`)}</span></p>
    <p className="c8"><span className="c1">After encouraging her for a while, he will give up.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Itqasi&#289;aluaqapki/Itqasi&#289;alua&#289;apki airua&#331;a.{props.aud(`summaries4-52.mp3`)}</span></p>
    <p className="c8"><span className="c4">After staying with them for a while, I went home.</span></p>
    <p className="c8"><span className="c13 c5">Itqasi&#289;alua&#289;upki ai&ntilde;iaqtu&#331;a.{props.aud(`summaries4-53.mp3`)}</span></p>
    <p className="c8"><span className="c1">After staying with them for a while, I will go home.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <h2 className="c30" id="h.o4doloifswfo"><span className="c2"></span></h2>
</div>
} else if (props.mood === 'Contemporative Mood I Realized' || props.mood === 'Contemporative Mood I Unrealized') {
return <div>
    <h2 className="c11" id="h.30qnrt2mq29w"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.cy2ulzb2l8a7"><span className="c2">Contemporative Mood I</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c4">Contemporative Mood I generally means &quot;while one is V-ing&rdquo;. It is used to describe an event (realized or unrealized), which precedes an event that is happening or may happen within the same timeframe.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The Contemporative Mood I markers +pkaq&#322;u- and +ti&#322;&#322;u- are used to express an ongoing event; and the markers +pkaġlu- and +tillu- are used to express a potential future event. &nbsp;</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">I&ntilde;upiaq nouns in Terminalis are used to clarify the subject of a transitive Contemporative Mood I verb.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The endings (person and number) of the Subordinative Mood are used to complete the sentence.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c21 c40"><span className="c5">a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Examples with realized aspect:</span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Aimmiuraaqti&#322;&#322;ugu isiqtuaguk.{props.aud(`summaries2-03.mp3`)}</span></p>
    <p className="c8"><span className="c1">We entered while she was leisurely enjoying being home.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Aimmipkaq&#322;ugu isiqtuaguk.{props.aud(`summaries4-54.mp3`)}</span></p>
    <p className="c8"><span className="c1">We entered while she was at home.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Miquqti&#322;&#322;ugik kammak Aakami&ntilde;un quliaqtua&#289;utigaa aataurani.{props.aud(`summaries4-55.mp3`)}</span></p>
    <p className="c8"><span className="c1">While the boots were being sewn by his mother he told his older sister.</span></p>
    <p className="c21 c40"><span className="c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p className="c3"><span className="c5">b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="c2">Examples with unrealized aspect:</span></p>
    <p className="c9 c38"><span className="c4"></span></p>
    <p className="c8"><span className="c24 c5">Aimmiuraaqtillugu isi&#289;niaqtuguk.{props.aud(`summaries4-56.mp3`)}</span></p>
    <p className="c8"><span className="c1">We will enter while she is leisurely enjoying being home.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Aimmipka&#289;lugu isi&#289;niaqtuguk.{props.aud(`summaries4-57.mp3`)}</span></p>
    <p className="c8"><span className="c1">We will enter while she is at home.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Miquqtillugik kammak Aakami&ntilde;un quliaqtua&#289;utinia&#289;aa aataurani.{props.aud(`summaries4-58.mp3`)}</span></p>
    <p className="c8"><span className="c1">While the boots are being sewn by his mother, he will tell his elder sister.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Miquqtillugik kammak aakami&ntilde;un quliaqtua&#289;utinia&#289;aa.{props.aud(`summaries4-59.mp3`)}</span></p>
    <p className="c8"><span className="c1">While the boots are being sewn by his mother he will tell her.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c7"><span className="c5">NOTE: Some North Slope I&ntilde;upiaq speakers of related dialects or idiolects do not differentiate between Consequential Mood markers (voiceless k&#322; and q&#322; and &#322;&#322;) and Conditional Mood markers (voiced gl and &#289;l and ll).</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c9"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7 c14"><span className="c4"></span></p>
</div>
} else if (props.mood === 'Contemporative Mood II' || props.mood === 'Contemporative Mood II (alt)') {
return <div>
    <h2 className="c11" id="h.x6ppbf7zl79s"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.o6yd3cuuiocj"><span className="c2">Contemporative Mood II</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c4">Contemporative Mood II generally means &quot;while one is V-ing, one simultaneously V-ed or Vs&rdquo; in the same time frame.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">It is used to describe an event which is happening simultaneously as the event expressed in an independent verb.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The subject of the Comtemporative Mood II is the same as the subject of the independent verb.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Naalaktuallaan aakani miquqtuq.{props.aud(`summaries4-60.mp3`)}</span></p>
    <p className="c8"><span className="c1">While listening to her mother, she is sewing.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Miqulla&#289;mi naalaktua&#289;ai.{props.aud(`summaries4-61.mp3`)}</span></p>
    <p className="c8"><span className="c1">She is listening to them while (she is) sewing.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ilisa&#289;illaan nalunigaa.{props.aud(`summaries4-62.mp3`)}</span></p>
    <p className="c8"><span className="c1">While recognizing him, she said she did not know him.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Qialla&#289;mi uqaqtuaq ka&#331;iqsi&ntilde;aitchuq.{props.aud(`summaries4-63.mp3`)}</span></p>
    <p className="c8"><span className="c1">The one talking while she is crying is not understandable.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;isimallapki&ntilde; sagluturuatiin ukpi&#289;inia&#331;itkikpi&ntilde;.{props.aud(`summaries4-64.mp3`)}</span></p>
    <p className="c8"><span className="c1">I, knowing that you tell lies frequently, will not believe you.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Unitchu&#331;il&#803;l&#803;apku unitcha&#289;a.{props.aud(`summaries4-65.mp3`)}</span></p>
    <p className="c8"><span className="c1">While not wanting to leave her behind, I left her behind.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c0"><span className="c1"></span></p>
</div>
} else if (props.mood === 'Contemporative Mood III' || props.mood === 'Contemporative Mood III (alt)') {
return <div>
    <h2 className="c11" id="h.q41fq8gi91va"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.xp96icudo6yn"><span className="c2">Contemporative Mood III</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c4">The Contemporative Mood III marker -&#331;&#331;aq- generally means &ldquo;while one is in a state of being&rdquo;. The Contemporative Mood III is used to express the state of being that one is in when/while another does something.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Contemporative Mood III marker -&#331;&#331;aq- uses the endings also used by the Consequential Mood and Conditional Mood endings, but excluding some of the third person subject reflexive endings.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Uqauti&#331;&#331;aan a&#331;aaluptignun unitcha&#289;a. Uqautipkaq&#322;ugu a&#331;aaluptignun unitcha&#289;a.{props.aud(`summaries4-66.mp3`)}</span></p>
    <p className="c8"><span className="c1">I left him while our grand uncle was still talking to him.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c24 c5">Miqu&#331;&#331;a&#289;mi pialatqulaitkaatigut iglumi.{props.aud(`summaries4-67.mp3`)}</span></p>
    <p className="c8"><span className="c1">She does not like for us to run around in the house while she is sewing.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Si&ntilde;i&#331;&#331;a&#289;ma uqaqsimaruami.{props.aud(`summaries4-68.mp3`)}</span></p>
    <p className="c8"><span className="c1">I found out that I spoke while I was sleeping.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Anchorage-miin&#331;amnuk qamutikpuk salummaqsimaraa.{props.aud(`summaries4-69.mp3`)}</span></p>
    <p className="c8"><span className="c1">(We found out) he cleaned our car while we were in Anchorage.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Umiaqtu&#331;&#331;apta tikisimaruat.{props.aud(`summaries4-70.mp3`)}</span></p>
    <p className="c8"><span className="c4">It is now known that they arrived while we were boating.</span></p>
    <p className="c0"><span className="c4"></span></p>
    <p className="c8"><span className="c13 c5">Il&#803;isaurri&#331;&#331;a&#289;ma pil&#803;aaqtigisuura&#289;a qitun&#289;atka nuta&#289;au&#331;&#331;aisa.{props.aud(`summaries4-71.mp3`)}</span></p>
    <p className="c8"><span className="c1">While I was a teacher I had her as a caretaker for my young children.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Kasima&#331;&#331;aisa suli airua&#331;a.{props.aud(`summaries4-72.mp3`)}</span></p>
    <p className="c8"><span className="c1">I went home while they were still holding the meeting.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ui&ntilde;&#289;a&#331;&#331;a&#289;ma miqu&#289;ulaitchu&#331;a.{props.aud(`summaries4-73.mp3`)}</span></p>
    <p className="c8"><span className="c1">I do not like to sew while I am sleepy.</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ikayu&#331;&#331;apku nukaaluni qi&ntilde;i&#289;aqsimaraatiguk.{props.aud(`summaries4-74.mp3`)}</span></p>
    <p className="c8"><span className="c1">Apparently he took a picture of us while I was helping his younger sibling.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c0"><span className="c1"></span></p>
</div>
} else if (props.mood === 'Contemporative Mood IV' || props.mood === 'Contemporative Mood IV (alt)') {
return <div>
    <h2 className="c11" id="h.gnjf8azbhalv"><span className="c2"></span></h2>
    <h2 className="c41 c40 c39" id="h.6p30j02sogs8"><span className="c2">Contemporative Mood IV</span></h2>
    <p className="c9"><span className="c1"></span></p>
    <p className="c7"><span className="c4">Contemporative Mood IV generally means &ldquo;while intermittently V-ing (him/her/it). It is used to express the event that intermittently shares the same timeframe with the event expressed in the independent verb.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">Contemporative Mood IV marker -mmaq- combines with the endings also used by the Consequential Mood and Conditional Mood endings. The -mmaq- alternate endings are used more nowadays.</span></p>
    <p className="c7 c14"><span className="c4"></span></p>
    <p className="c7"><span className="c4">The subject of the Contemporative Mood IV is the same subject of the independent verb.</span></p>
    <p className="c7 c14"><span className="c1"></span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Iglaqtuulamma&#289;mi quliaqtua&#289;utiraatigut.{props.aud(`summaries-74.mp3`)}</span></p>
    <p className="c8"><span className="c1">She told us while intermittently having fits of laughter.</span></p>
    <p className="c9"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Salummaksaqtuq miquutitamma&#289;mi.{props.aud(`summaries4-75.mp3`)}</span></p>
    <p className="c8"><span className="c1">She is tidying up (the house) while intermittently sewing (on a sewing machine).</span></p>
    <p className="c0"><span className="c1"></span></p>
    <p className="c8"><span className="c13 c5">Ikayummamnuk naati&#289;aa savaani.{props.aud(`summaries4-76.mp3`)}</span></p>
    <p className="c8"><span className="c1">While intermittently helping us, she quickly finished her (own) work.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
    <p className="c8"><span className="c13 c5">Takummamnuk nakuummagaamnuk uvliruaq.{props.aud(`summaries4-77.mp3`)}</span></p>
    <p className="c8"><span className="c1">She spent the day intermittently checking on us to make sure we were fine.</span></p>
    <p className="c0"><span className="c13 c5"></span></p>
</div>
} else {
	return <div></div>
}
}


