import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup, Segment} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import {colorsList, customFontFam} from './constants/constants.js'
import {appendixInformation} from './constants/appendixConstants.js'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")
var scroll    = Scroll.animateScroll;

class Appendix extends Component {
	constructor(props) {
		super(props);
		// console.log(props)
		this.state = {
			// show: false,
			appendix: decodeURI(props.match.params.word) in appendixInformation ? props.match.params.word : '1',
			history: props.location.state === undefined ? [] : props.location.state.history,
			from: props.location.state === undefined ? '' : props.location.state.from,



			// entryData: {},
			// keySplit: [],
			// definition: [],
			// key: '',
			// verbalIllustrations: [],
			// etymology: [],
			// parent: '',
			// children: [],
			// childrenEntries: {},
			// relatedWords: {},
			// relatedWordsEntries: {},
			// parentEntries: {},
			// postbaseEntries: [],
			// halfTransitive: [],
			// halfTransitiveEtymology: [],
			// postbaseExamples: [],
			// audioMatrix: [],
			// exampleAudioMatrix: [],
			// extraInfo: [],
   //    entryModified: [],
   //    activeEditIndex:-1,
   //    baseOptions:[],
   //    activeKeyInEditIndex:0,
   //    nounMood:'Absolutive',
   //    verbMood:'Indicative',
   //    fstCall:'',
   //    irregular:[],
   //    extraInfo:[],
   //    objectPossessed:false,
   //    loaded:false,
   //    entryUsageData: props.usagedictionary_dict[decodeURI(props.match.params.word)] === undefined ? {} : this.props.usagedictionary_dict[decodeURI(props.match.params.word)],
		}
	    // this.getWord = this.getWord.bind(this);
	    // this.getWord(decodeURI(props.match.params.word));
	    // if (!(decodeURI(props.match.params.word) in appendixInformation)) {
	    	// console.log('go to appendix menu')
	    // }
	    scroll.scrollTo(0);
	}

	// componentDidMount(){
	// 	this.getWord(decodeURI(this.props.match.params.word));
	// }
	componentDidMount() {
	    if (!(decodeURI(this.props.match.params.word) in appendixInformation)) {
	    	this.props.history.push('/appendix')
	    }
	  }
		
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.key !== this.state.key) {
  //     this.getWord(this.state.key);
  //   }

  //   else if (prevProps.match.params.word !== this.props.match.params.word) {
  //   	this.getWord(decodeURI(this.props.match.params.word));
  //   }
  // }


 //  componentWillReceiveProps(nextProps) {
 //    // console.log(nextProps)
 //    this.setState({ 
	// 		from: nextProps.location.pathname,
	// 		// fullDefinition: nextProps.fullDefinition,
	// 	})
	// }

  // getWord(word) {
  // 	scroll.scrollTo(0);
  //   // axios
  //   //   .get(API_URL + "/inupiaqusage/" + encodeURIComponent(word))
  //   //   .then(response => {
  //   //     this.setState({
  //   //       entryUsageData: response.data,
  //   //     });
  //   //   })
  //   let entryUsageData = {}
  //   if (this.props.usagedictionary_dict[decodeURI(this.props.match.params.word)] !== undefined) {
  //   		entryUsageData = this.props.usagedictionary_dict[decodeURI(this.props.match.params.word)]
  //   }
    		

  //   axios
  //     .get(API_URL + "/inupiaqword/" + encodeURIComponent(word))
  //     .then(response => {
  //       // console.log(response.data);
  //       this.setState({
  //         word:word,
  //         entryData: response.data,
  //         keySplit: response.data.keySplit,
  //         definition: response.data.definition,
  //         verbalIllustrations: response.data.verbalIllustrations,
  //         etymology: response.data.etymology,
  //         parent: response.data.parent,
  //         children: response.data.children,
  //         childrenEntries: response.data.childrenEntries,
  //         relatedWords: response.data.relatedWords,
  //         relatedWordsEntries: response.data.relatedWordsEntries,
  //         extraInfo: response.data.extraInfo,
  //         parentEntries: response.data.parentEntries,
  //         postbaseEntries: response.data.postbaseEntries,
  //         postbaseExamples: response.data.postbaseExamples,
  //         irregular: response.data.irregular,
  //         extraInfo: response.data.extraInfo,
  //         fullDefinition: response.data.fullDefinition,
  //         audioMatrix: response.data.audioMatrix,
  //         exampleAudioMatrix: response.data.exampleAudioMatrix,
  //         halfTransitive: response.data.halfTransitive,
  //         halfTransitiveEtymology: response.data.halfTransitiveEtymology,
  //         entryUsageData: entryUsageData,
  //       },()=>{
  //       	this.setState({loaded:true})
  //       })
  //     });
  // }

	// parentEntry = () => {
	// 	  return <List.Item style={{padding:7}}>
	// 	    <Link to={{pathname: '/' + this.state.parentEntries[this.state.parent[0]]['url'], state: { key: this.state.parentEntries[this.state.parent[0]]['keySplit'], from: this.props.location.pathname }}}>
 //          <List.Content floated='right'>
 //            <Icon circular style={{marginTop:'4px', color:'#8f8f8f'}} name='chevron right' />
 //          </List.Content>
	// 	    </Link>
	// 	      <List.Content  style={{paddingRight:'16px'}}>
	// 	        <List.Header style={{fontSize:'18px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
	// 	          {this.state.parentEntries[this.state.parent[0]]['keySplit'].map((w,index) =>
	// 	              <div style={{display:'flex'}}>
	// 	    					<Link style={{color:'#306190'}} to={{pathname: '/' + this.state.parentEntries[this.state.parent[0]]['url'], state: { key: this.state.parentEntries[this.state.parent[0]]['keySplit'], from: this.props.location.pathname  }}}>

	// 						      <span style={{'paddingRight':'3px',fontWeight:'400',borderBottom:'1px solid #858585',paddingBottom:'2px',}}>
	// 	              <span style={{fontWeight:'400'}}>
	// 	    					{this.state.parent[0].includes('perhaps') ? <span style={{color:'grey',fontSize:'16px',fontStyle:'italic','paddingRight':'3px'}} >{'perhaps '}</span> : null}
	// 	              </span>
	// 		              {this.processStyledTextLink(w[0])}
	// 		              </span>
	// 	              </Link>


	// 	              {w[2] !== [""] ?
	// 	                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  

	// 			              {w[1][0] !== '' ?
	// 			                <span style={{marginRight:'5px',cursor:'pointer'}}>
	// 			                  <TagColors tags={w[1].join(', ')} width={window.innerWidth} />
	// 			                </span>
	// 			                :
	// 			                ''
	// 			              }  

	// 	                  {w[2].map((tag) => 
	// 	                    <span style={{marginRight:'5px',cursor:'pointer'}}>
	// 	                      <TagColors tags={tag} width={window.innerWidth} />
	// 	                    </span>
	// 	                    )}
	// 	                </span>
	// 	                :
	// 	                ''
	// 	              }
	// 	              </div>
	// 	            )}
	// 	        </List.Header>
	// 	        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>{this.processStyledText(this.state.parentEntries[this.state.parent[0]]['fullDefinition'])}</List.Description>
	// 	      </List.Content>
	// 	    </List.Item>
	// 	}

	// thisEntry = () => {
	// 	  return <List.Item style={{padding:7}}>
	// 	      <List.Content  style={{paddingRight:'16px'}}>
	// 	        <List.Header style={{fontSize:'18px',paddingBottom:'4px',paddingLeft:'15px',fontFamily:customFontFam,lineHeight:'25px'}}>
	// 	          {this.state.keySplit.map((w,index) =>
	// 	              <div style={{display:'flex'}}>

	// 						      <span style={{'paddingRight':'3px',fontWeight:'400',paddingBottom:'2px',}}>
	// 	              <span style={{fontWeight:'400'}}>
	// 	              </span>
	// 		              {this.processStyledTextLink(w[0])}
	// 		              </span>


	// 	              {w[3] !== [""] ?
	// 	                <span style={{'marginLeft':'15px',marginRight:'6px'}}>  

	// 			              {w[2][0] !== '' ?
	// 			                <span style={{marginRight:'5px',cursor:'pointer'}}>
	// 			                  <TagColors tags={w[2].join(', ')} width={window.innerWidth} />
	// 			                </span>
	// 			                :
	// 			                ''
	// 			              }  

	// 	                  {w[3].map((tag) => 
	// 	                    <span style={{marginRight:'5px',cursor:'pointer'}}>
	// 	                      <TagColors tags={tag} width={window.innerWidth} />
	// 	                    </span>
	// 	                    )}
	// 	                </span>
	// 	                :
	// 	                ''
	// 	              }
	// 	              </div>
	// 	            )}
	// 	        </List.Header>
	// 	        <List.Description style={{fontSize:'16px',color:'#000000cc',paddingLeft:'15px',fontWeight:'400',lineHeight:'23px',paddingTop:'4px'}}>
	// 	        	{this.processStyledText(this.state.definition.filter(x => x[2] === "(t) TRANSITIVE VERB").map(x => x[0]).join(", "))}
	// 	        </List.Description>
	// 	      </List.Content>
	// 	    </List.Item>
	// 	}

	processStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","").replace("«","").replace("»","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/{.*?}/g)
		let matches2 = sentence.match(/_.*?_/g)
		if (matches !== null || matches1 !== null || matches2 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>'+m.slice(1,-1)+'</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,m.slice(1,-1)))						
			}
			if (matches2 !== null) {
				matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}

    processStyledTextLink = (sentence) => {
      sentence = sentence.replaceAll(">","")


      sentence = sentence.replaceAll("⟨","").replaceAll("⟩","")
      let matches = sentence.match(/⎡.*?⎤/g)
      let matches1 = sentence.match(/{.*?}/g)
      let matches2 = sentence.match(/_.*?_/g)
      // console.log(matches2)
      if (matches !== null || matches1 !== null || matches2 !== null) {
        if (matches !== null) {
          matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))           
        }
        if (matches1 !== null) {
          matches1.map((m) => sentence = sentence.replaceAll(m,m.slice(1,-1)))            
        }
        if (matches2 !== null) {
          matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))            
        }
        return <span style={{whiteSpace:'nowrap'}} dangerouslySetInnerHTML={{__html: sentence}} />    
      } else {
        return <span style={{whiteSpace:'nowrap'}}>{sentence}</span>
      }


      // let matches = sentence.match(/\⎡.*?\⎤/g)
      // if (matches !== null) {
      //   matches.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))   
      //   return <span dangerouslySetInnerHTML={{__html: sentence}} />    
      // } else {
      //   return <span>{sentence}</span>
      // }
    }


	processUnderlyingStyledText = (sentence) => {			
		sentence = sentence.replace("⟨","").replace("⟩","")
		let matches = sentence.match(/⎡.*?⎤/g)
		let matches1 = sentence.match(/\^.*?\^/g)
		let matches2 = sentence.match(/_.*?_/g)
		if (matches !== null || matches1 !== null || matches2 !== null) {
			if (matches !== null) {
				matches.map((m) => sentence = sentence.replace(m,'<i>['+m.slice(1,-1)+']</i>'))						
			}
			if (matches1 !== null) {
				matches1.map((m) => sentence = sentence.replace(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
			}
			if (matches2 !== null) {
				matches2.map((m) => sentence = sentence.replace(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
			}
			return <span dangerouslySetInnerHTML={{__html: sentence}} />		
		} else {
			return <span>{sentence}</span>
		}
	}

	retrieveDialectSub = (dialects) => {
		let returnstringdialect = ''
		
		let dialectList = {
		'Anaqtuuvak':' ⎡(Nu)⎤',
		'Tikiġaq':' ⎡(Ti)⎤',
		'Nuvuk':' ⎡(PB)⎤',
		'¤Anaqtuuvak':' ⎡¤(Nu)⎤',
		'¤Tikiġaq':' ⎡¤(Ti)⎤',
		'Ulġuniq':' ⎡(Wa)⎤',
		'':'',
		}
		dialects.map((j)=>{
				returnstringdialect = returnstringdialect+dialectList[j]
		})

		return returnstringdialect
	}

	// processPostbaseExampleRow = (sentence) => {     
 //    let matches = sentence.match(/\`.*?(\`|\')/g)
 //    let matches2 = sentence.match(/\(.*?\)/g)

 //    // console.log(matches2)
 //    if (matches !== null) {
 //      matches.map((m) => sentence = '<b>'+sentence.replace(m,'<span style="font-weight:normal">'+m.slice(1,-1)+'</span>')+'</b>')
 //    }      
 //    if (matches2 !== null) {
 //      matches2.map((m) => sentence = sentence.replace(m,'<span style="font-weight:normal"><i>'+m+'</i></span>'))      
 //    }
 //    if (matches !== null || matches2 !== null) {
 //      return <span dangerouslySetInnerHTML={{__html: sentence}} />          
 //    } else {
 //      return <span>{sentence}</span>
 //    }
 //  }

 //  processPostbaseTableRowHTML = (sentence) => {
	// 	let matchesB = sentence.match(/\{.*?\}(?!\w)/g)
 //    let matchesI = sentence.match(/⎡.*?⎤/g)
	// 	let matchesSup = sentence.match(/\^.*?\^/g)
	// 	let matchesSub = sentence.match(/_.*?_/g)
	// 	if (matchesB !== null || matchesI !== null || matchesSup !== null || matchesSub !== null) {
	// 		if (matchesB !== null) {
	// 			matchesB.map((m) => sentence = sentence.replaceAll(m,'<b>'+m.slice(1,-1)+'</b>'))								
	// 		}
	// 		if (matchesI !== null) {
	// 			matchesI.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))						
	// 		}
	// 		if (matchesSub !== null) {
	// 			matchesSub.map((m) => sentence = sentence.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
	// 		}
	// 		if (matchesSup !== null) {
	// 			matchesSup.map((m) => sentence = sentence.replaceAll(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
	// 		}
	// 		return sentence
	// 	} else {
	// 		return sentence
	// 	}
 //  }

 //  processPostbaseTableRow = (sentence) => {     
	// 	let matchesBnL = sentence.match(/\{(.*?)\}⟨(.*?)⟩/g)
	// 	let splitSentence = []
	// 	let sentenceBank = []
	// 	let restOfSentence = sentence
	// 	if (matchesBnL !== null) {
	// 			let before = ''
	// 			let after = ''
	// 			matchesBnL.map((m) => {
	// 				before = restOfSentence.slice(0, restOfSentence.indexOf(m));
	// 				after = restOfSentence.slice(restOfSentence.indexOf(m)+m.length + 1);
	// 				sentenceBank.push(before)
	// 				restOfSentence = after
	// 			})
	// 			sentenceBank.push(after)
	// 			splitSentence = sentence.split(matchesBnL[0])
	// 			return <span>
	// 	 		<span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(sentenceBank[0])}} />

	// 		 		{matchesBnL.map((k,index)=><span>			 			
	// 				<Link style={{color:'#306190'}} to={{pathname: k.match(/⟨.*?⟩/g)[0].slice(1,-1)}} onClick={()=>{this.setState({key:k.match(/⟨.*?⟩/g)[0].slice(1,-1), from:this.props.location.pathname})}}><b>{k.match(/\{.*?\}/g)[0].slice(1,-1)}</b></Link>
	// 				{' '}
	// 		 		<span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(sentenceBank[index+1])}} />			
	// 		 		</span>	 			
	// 	 		)}
	// 			</span>
	// 	} else {
	// 	 	return <span dangerouslySetInnerHTML={{__html: this.processPostbaseTableRowHTML(this.processPostbaseTableRowHTML(sentence))}} />		
	// 	}
 //  }

 //  // processPostbaseTableRow = (sentence) => {     
	// 	// let matchesBnL = sentence.match(/\{(.*?)\}⟨(.*?)⟩/g)
	// 	// let matchesB = sentence.match(/\{.*?\}(?!\w)/g)
 //  //   let matchesI = sentence.match(/⎡.*?⎤/g)
	// 	// let matchesSup = sentence.match(/\^.*?\^/g)
	// 	// let matchesSub = sentence.match(/_.*?_/g)
	// 	// if (matchesBnL !== null || matchesB !== null || matchesI !== null || matchesSup !== null || matchesSub !== null) {
	// 	// 	// if (matchesBnL !== null) {
	// 	// 	// 	matchesBnL.map((m) => {
	// 	// 	// 		sentence = sentence.replaceAll(m,
	// 	// 	// 		"<Link style={{color:'#306190'}} to={{pathname: '/' +"+m.match(/⟨.*?⟩/g).slice(1,-1)+"}} onClick={()=>{this.setState({key:"+m.match(/⟨.*?⟩/g).slice(1,-1)+", from:"+this.props.location.pathname+"})}}><b>"+m.match(/{.*?}/g).slice(1,-1)+"</b></Link>"
	// 	// 	// 			)
	// 	// 	// 	})								
	// 	// 	// }
	// 	// 	if (matchesB !== null) {
	// 	// 		matchesB.map((m) => sentence = sentence.replaceAll(m,'<b>'+m.slice(1,-1)+'</b>'))								
	// 	// 	}
	// 	// 	if (matchesI !== null) {
	// 	// 		matchesI.map((m) => sentence = sentence.replaceAll(m,'<i>'+m.slice(1,-1)+'</i>'))						
	// 	// 	}
	// 	// 	if (matchesSub !== null) {
	// 	// 		matchesSub.map((m) => sentence = sentence.replaceAll(m,'<sub>'+m.slice(1,-1)+'</sub>'))						
	// 	// 	}
	// 	// 	if (matchesSup !== null) {
	// 	// 		matchesSup.map((m) => sentence = sentence.replaceAll(m,'<sup>'+m.slice(1,-1)+'</sup>'))						
	// 	// 	}
	// 	// 	return <span dangerouslySetInnerHTML={{__html: sentence}} />		
	// 	// } else {
	// 	// 	return <span>{sentence}</span>
	// 	// }
 //  // }

  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replace('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }



	render() {
		// console.log(this.state)
		// let postbaseTableOn = false
    // let postbaseExampleOn = false
		// console.log(this.props.location)
		// console.log(this.props)
		return (
			<div style={{fontFamily:customFontFam}}>
			<Transition visible={this.state.loaded} animation='fade' duration={300}>
          <Grid textAlign='center'>
          <Grid.Row  style={{height:40,paddingBottom:0}}>
          <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>

			<div style={{display:'flex'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					
	      </div>

				<div style={{flex:1,textAlign:'center',fontSize:'16px',paddingTop:'7px',color:'#737373'}}><span style={{paddingBottom:'2px',borderBottom:'1px solid #737373',}}>{'Appendix '+this.state.appendix}</span></div>

	       <div style={{flex:1,display:'flex',justifyContent:'flex-end',paddingTop:5}}>
					<Link to={{pathname: '/appendix'}}>
					<span style={{fontSize:'16px',fontWeight:'400',paddingBottom:'2px',color:'#306190'}}>
					{'See Appendices'}
					</span>
					</Link>
				</div>


			</div>

					<div style={{border:'1px solid #E3E3E3',marginTop:'10px'}}>

					<div style={{marginBottom:15}} className='hierarchymain'>
					<span className='span1'>{appendixInformation[this.state.appendix]['inupTitle']}</span>
					<span className='span2'>{appendixInformation[this.state.appendix]['engTitle']}</span>
					</div>


          <div style={{fontSize:'16px'}}>
 

					{'description' in appendixInformation[this.state.appendix] && appendixInformation[this.state.appendix]['description'].length != 0 ? 
						<div style={{margin:'25px 40px',color:'#000000DE'}} dangerouslySetInnerHTML={{__html: appendixInformation[this.state.appendix]['description']}} />	
						:
						null
					}


            {appendixInformation[this.state.appendix]['primaryImage'] ?
            	<div style={{textAlign:'center'}}> 
            	<div style={{padding:3}}><span><a href={"/Appendix_"+(this.state.appendix != '32' ? this.state.appendix : 'Bibliography')+'.pdf'} target="_blank" style={{cursor:'pointer'}}><span style={{color:'#306190'}}>Click to open the PDF in a new tab.</span></a></span></div>
            	<div style={{height:'334px'}}>
              <Image href={"/Appendix_"+[this.state.appendix]+'.pdf'} target="_blank" style={{'maxWidth':'250px',cursor:'pointer',marginTop:10,marginBottom:10,border:'1px solid grey',borderRadius:'0px'}} src={"/AppendixPrimary_"+(this.state.appendix != '32' ? this.state.appendix : 'Bibliography')+'.png'}/>
              </div>

	            {['12','13','14','15'].includes(this.state.appendix) ? 
	            	<div style={{color:'#777777',margin:'0px 18px'}}>{'Area references are from the perspective of Utqiaġvik-Barrow.'}</div>
		          	:
		          	null
	          	}


              {appendixInformation[this.state.appendix]['keys'].length > 0 ?
	              <div style={{margin:'30px 40px',marginBottom:'5px'}}>
		              <span style={{color:'#000000DE',paddingBottom:'1px',borderBottom:'1px solid #adadad'}}>
		              	{'Entries from the diagram'}
		              </span>
	              </div>
	              :
	              null
	            }
              {this.state.appendix === '3' ?
	            	<div style={{textAlign:'center'}}> 
	            	<div style={{padding:5,marginTop:3}}><span><a href={"/demonstratives"} target="_blank" style={{cursor:'pointer'}}><span style={{color:'#306190'}}>See the full demonstrative table here.</span></a></span></div>
	            	</div>
	              :
	              null
	            }

              </div>
              :
              null
            }

            {'keys' in appendixInformation[this.state.appendix] && appendixInformation[this.state.appendix]['keys'].length > 0 ? 
	            <Segment style={{fontFamily:customFontFam, maxHeight:420,overflow: 'auto',fontSize:'16px',margin:(window.innerWidth < 480 ? '20px 20px':'25px 40px'),paddingTop:'9px',paddingBottom:'9px',marginBottom:'0px',textAlign:'left'}}>
	              <div>
	                {appendixInformation[this.state.appendix]['keys'].map((p,pind)=>{
	                  if (p in this.props.dictionarydict) {
	                    return <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}>
	                    				<div style={{flex:2,color:'#4A80B5',cursor:'pointer',minWidth:(window.innerWidth < 480 ? 100:120)}}>
	                    					<Link to={{pathname: '/' + this.props.dictionarydict[p]["url"], state: { word: this.props.dictionarydict[p]["url"], from: this.props.location.pathname, history:['',[], this.state.Yposition, 0] }}}>
	                    						<span style={{color:'#306190',lineHeight:'19px',paddingBottom:'1px',borderBottom:'1px solid #adadad',}}>
	                    						{this.props.dictionarydict[p]["keySplit"].map((j,jindex)=><span><span>{this.processStyledText(j[0])}</span><span style={{fontStyle:'italic'}}>{j[1].length > 0 ? this.processStyledText(this.retrieveDialectSub(j[1])) : ''}</span><span>{this.props.dictionarydict[p]["keySplit"].length-1 == jindex ? '' : ', '}</span></span>)}
	                    						</span>
	                    					</Link>
	                    				</div>
	                    				<div style={{flex:5, color:'#666666',lineHeight:'19px',paddingLeft:'3px',marginLeft:(window.innerWidth < 480 && p.length > 13 && !p.includes(',') && !p.includes(' ') ? 60:0)}}>{this.processStyledText(this.props.dictionarydict[p]["fullDefinition"])}</div>
	                    				</div>                   
	                  } else {
	                    return (true ? null : <div style={{display:'flex',flexDirection:'row',lineHeight:'16px',margin:(pind == 0 ? '6px 3px':'12px 3px')}}><div style={{lineHeight:'21px',minWidth:(window.innerWidth < 480 ? 100:120)}}>{p}</div><div style={{flex:5}}>{''}</div></div>)             	                  		
	                  }
	                })}
	              </div>
	            </Segment> 
	            :
	            null
	          }

	          {appendixInformation[this.state.appendix]['keys'].length > 0 ?  
	            <div style={{display:'flex',justifyContent:'center',marginBottom:'10px'}}>
                <span style={{paddingTop:20,fontSize:'16px',color:'#000000DE',padding:'15px',fontWeight:'400',lineHeight:'23px'}}>
                <span style={{textDecoration:'underline'}}>Disclaimer:</span> Entries and definitions may not match the printed appendix.
                </span>
              </div>
              :
              null
            }
					
            {!appendixInformation[this.state.appendix]['primaryImage'] ?
            	<div style={{textAlign:'center'}}> 
            	<div style={{}}><span><a href={"/Appendix_"+[this.state.appendix]+'.pdf'} target="_blank" style={{cursor:'pointer'}}><span style={{color:'#306190'}}>Click to open the print appendix PDF in a new tab.</span></a></span></div>
              </div>
              :
              null
            }


            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',margin:'15px 40px'}}>
            <div style={{textAlign:'center',fontSize:'16px',color:'#000000DE',padding:'10px',fontWeight:'400',lineHeight:'23px'}}>
            <span style={{fontWeight:'bold'}}>{'Dictionary appendices compiled by Edna Ahgeak MacLean.'}</span><span>{(appendixInformation[this.state.appendix]['previewImage'] ? ' Appendix illustrations by Ronald Engstrom.' : '')+' Fish illustrations below by Dr. Ronald Senungetuk.'}</span>
            </div>
            <div style={{textAlign:'center',fontSize:'16px',color:'#000000DE',padding:'10px',fontWeight:'400',lineHeight:'23px'}}>
            Based on the 2014 publication by University of Alaska Press with the collaboration of the Alaska Native Language Center, University of Alaska Fairbanks, and the North Slope Borough Iñupiaq, History, Language, and Culture Commission (IHLC).
            </div>
            </div>

					</div>
          </div>

			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'16px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>

        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:90,paddingBottom:15,paddingTop:25}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>

				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
			</div>

		);
	}
}
export default Appendix;