import React, { Component } from 'react';
import { Container, Header, Button, Icon, Divider, Image, Grid, List, Dropdown, Transition, Label, Popup, Segment, Table, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../App.js';
import Collapsible from 'react-collapsible';
import { TagColors } from './HomeHelper.js';
import NewUsageBaseDisplay from './NewUsageBaseDisplay.js'
import {nounoptions1, nounoptions2, options1, options2, options3, colorsList, customFontFam} from './constants/constants.js'
import SimpleWordBuilder from './SimpleWordBuilder.js';
import * as Scroll from 'react-scroll';
import ReactGA from "react-ga4";
ReactGA.initialize("G-BCN5V0JQN1")


var scroll    = Scroll.animateScroll;


let tableCaseRootsFST = [
["⎡°uv⎤","⎡°mar⎤","⎡°sam¹⎤","here"],
["⎡°Ik⎤","⎡°av⎤","⎡°am⎤","over there"],
["","⎡°ag⎤","⎡°akim⎤","over across there"],
["⎡°pIk⎤","⎡°pag⎤","⎡°pam⎤","up there"],
["","","⎡°pakim⎤","on top of the roof"],
["⎡°kan⎤","⎡°un⎤","⎡°sam²⎤","down there"],
["","","⎡°sakim⎤","in the arctic entry; oceanword"],
["⎡°kIg⎤","⎡°qag⎤","⎡°qakim⎤","out there"],
["⎡°kIv⎤","⎡°qav⎤","⎡°qam⎤","in there"],
["","","⎡°Im⎤","previously mentioned"],
]
let tableCaseTatRootsFST = [
["⎡°tavr⎤,⎡°taam⎤","⎡°tamar⎤","⎡°tasam¹⎤","there"],
["⎡°taik⎤","⎡°taav⎤","⎡°taam⎤","over there"],
["","⎡°taag⎤","⎡°taakim⎤","over across there"],
["⎡°tatpIk⎤","⎡°tatpag⎤","⎡°tatpam⎤","up there"],
["","","⎡°tatpakim⎤","on top of the roof"],
["⎡°takan⎤","⎡°taun⎤","⎡°tasam²⎤","down there"],
["","","⎡°tasakim⎤","in the arctic entry; oceanword"],
["⎡°tatkIg⎤","⎡°tatqa(g)⎤","⎡°tatqakim⎤","out there"],
["⎡°tatkIv⎤","⎡°tatqav⎤","⎡°tatqam⎤","in there"],
["","","⎡°taim⎤","previously mentioned"],
]
let tableCaseRoots = [
["uv-","mar-","sam-","here"],
["ik-","av-","am-","over there"],
["","ag-","akim-","over across there"],
["pik-","pag-","pam-","up there"],
["","","pakim-","on top of the roof"],
["kan-","un-","sam-","down there"],
["","","sakim-","in the arctic entry; oceanword"],
["kig-","qag-","qakim-","out there"],
["kiv-","qav-","qam-","in there"],
["","","im-","previously mentioned"],
]
let tableCaseAdverbs = [
["uvva","marra","samma","here"],
["ikka","avva","amma","over there"],
["","agga","aŋma","over across there"],
["pikka","pagga","pamma","up there"],
["","","paŋma","on top of the roof"],
["kanna","unna","samma","down there"],
["","","saŋma","in the arctic entry; oceanword"],
["kigga","qagga","qaŋma","out there"],
["kivva","qavva","qamma","in there"],
["","","imma","previously mentioned"],
]
let tableCasePronouns = [
["una","manna","samna","this one here"],
["igña","amna","amna","that one over there"],
["","agna","akimña","that one over across there"],
["pigña","pagna","pamna","that one up there"],
["","","pakimna","that one on top of the roof"],
["kanna","unna","samna","that one down there"],
["","","sakimña","that one in the arctic entry; oceanword"],
["kigña","qagna","qakimña","that one out there"],
["kimña","qamna","qamna","that one in there"],
["","","imña","that one previously mentioned"],
]
let tableCaseTatRoots = [
["tavr-, taam-","tamar-","tasam-","there"],
["taik-","taav-","taam-","over there"],
["","taag-","taakim-","over across there"],
["tatpik-","tatpag-","tatpam-","up there"],
["","","tatpakim-","on top of the roof"],
["takan-","taun-","tasam-","down there"],
["","","tasakim-","in the arctic entry; oceanword"],
["tatkig-","tatqa(g)-","tatqakim-","out there"],
["tatkiv-","tatqav-","tatqam-","in there"],
["","","taim-","previously mentioned"],
]
let tableCaseTatAdverbs = [
["tavra","tamarra","tasamma","there"],
["taikka","taavva","taamma","over there"],
["","taagga","taaŋma","over across there"],
["tatpikka","tatpagga","tatpamma","up there"],
["","","tatpaŋma","on top of the roof"],
["takanna","taunna","tasagma","down there"],
["","","tasaŋma","in the arctic entry; oceanword"],
["tatkigga","tatqagga","tatqaŋma","out there"],
["tatkivva","tatqavva","tatqamma","in there"],
["","","taimma","previously mentioned"],
]
let tableCaseTatPronouns = [
["taamna","tamanna","tasamna","that one there"],
["taigña","taavamna","taamamna","that one over there"],
["","taagagna","taakimña","that one over across there"],
["tatpigña","tatpagna","tatpamna","that one up there"],
["","","tatpakimna","that one on top of the roof"],
["takanna","taunna","tasamna","that one down there"],
["","","tasakimña","that one in the arctic entry; oceanword"],
["tatkigña","tatqagna","tatqakimña","that one out there"],
["tatkimña","tatqamna","tatqamna","that one in there"],
["","","taimña","that one previously mentioned"],
]


const tableTitleOptions = [
  {
    key: 'Demonstrative roots',
    text: 'Demonstrative roots',
    value: ['roots','',''],
  },
  {
    key: 'Demonstrative adverb base',
    text: 'Demonstrative adverb base',
    value: ['adv','base',''],
  },
  {
    key: 'Demonstrative adverb localis',
    text: 'Demonstrative adverb localis',
    value: ['adv','loc',''],
  },
  {
    key: 'Demonstrative adverb vialis',
    text: 'Demonstrative adverb vialis',
    value: ['adv','via',''],
  },
  {
    key: 'Demonstrative adverb terminalis',
    text: 'Demonstrative adverb terminalis',
    value: ['adv','ter',''],
  },
  {
    key: 'Demonstrative adverb 2nd terminalis',
    text: 'Demonstrative adverb 2nd terminalis',
    value: ['adv','2ter',''],
  },
  {
    key: 'Demonstrative adverb ablative',
    text: 'Demonstrative adverb ablative',
    value: ['adv','abl',''],
  },
  {
    key: 'Demonstrative adverb 2nd ablative',
    text: 'Demonstrative adverb 2nd ablative',
    value: ['adv','2abl',''],
  },
  {
    key: 'Demonstrative abs. sg. pronouns',
    text: 'Demonstrative abs. sg. pronouns',
    value: ['pro','abs','sg'],
  },
  {
    key: 'Demonstrative abs. du. pronouns',
    text: 'Demonstrative abs. du. pronouns',
    value: ['pro','abs','du'],
  },
  {
    key: 'Demonstrative abs. pl. pronouns',
    text: 'Demonstrative abs. pl. pronouns',
    value: ['pro','abs','pl'],
  },
  {
    key: 'Demonstrative relative sg. pronouns',
    text: 'Demonstrative relative sg. pronouns',
    value: ['pro','rel','sg'],
  },
  {
    key: 'Demonstrative modalis sg. pronouns',
    text: 'Demonstrative modalis sg. pronouns',
    value: ['pro','mod','sg'],
  },
  {
    key: 'Demonstrative localis sg. pronouns',
    text: 'Demonstrative localis sg. pronouns',
    value: ['pro','loc','sg'],
  },
  {
    key: 'Demonstrative vialis sg. pronouns',
    text: 'Demonstrative vialis sg. pronouns',
    value: ['pro','via','sg'],
  },
  {
    key: 'Demonstrative terminalis sg. pronouns',
    text: 'Demonstrative terminalis sg. pronouns',
    value: ['pro','ter','sg'],
  },
  {
    key: 'Demonstrative ablative sg. pronouns',
    text: 'Demonstrative ablative sg. pronouns',
    value: ['pro','abl','sg'],
  },
  {
    key: 'Demonstrative similaris sg. pronouns',
    text: 'Demonstrative similaris sg. pronouns',
    value: ['pro','sim','sg'],
  },
]

const tableTypeOptions = [
  {
    key: 'Demonstrative roots',
    text: 'Demonstrative roots',
    value: 'roots',
  },
  {
    key: 'Demonstrative adverbs',
    text: 'Demonstrative adverbs',
    value: 'adv',
  },
  {
    key: 'Demonstrative pronouns',
    text: 'Demonstrative pronouns',
    value: 'pro',
  },
]


const tableProCaseOptions = [
  {
    key: 'absolutive',
    text: 'absolutive',
    value: 'abs',
  },
  {
    key: 'relative',
    text: 'relative',
    value: 'rel',
  },
  {
    key: 'modalis',
    text: 'modalis',
    value: 'mod',
  },
  {
    key: 'locative',
    text: 'locative',
    value: 'loc',
  },
  {
    key: 'terminalis',
    text: 'terminalis',
    value: 'ter',
  },
  {
    key: 'ablative',
    text: 'ablative',
    value: 'abl',
  },
  {
    key: 'vialis',
    text: 'vialis',
    value: 'via',
  },
  {
    key: 'similaris',
    text: 'similaris',
    value: 'sim',
  },
]

const tableAdvCaseOptions = [
  {
    key: 'base',
    text: 'stative',
    value: 'base',
  },
  {
    key: 'locative',
    text: 'locative',
    value: 'loc',
  },
  {
    key: 'terminalis',
    text: 'terminalis',
    value: 'ter',
  },
  {
    key: '2nd terminalis',
    text: '2nd terminalis',
    value: '2ter',
  },
  {
    key: 'ablative',
    text: 'ablative',
    value: 'abl',
  },
  {
    key: '2nd ablative',
    text: '2nd ablative',
    value: '2abl',
  },
  {
    key: 'vialis',
    text: 'vialis',
    value: 'via',
  },
]

const tableProNumberOptions = [
  {
    key: 'singular (1)',
    text: 'singular (1)',
    value: 'sg',
  },
  {
    key: 'dual (2)',
    text: 'dual (2)',
    value: 'du',
  },
  {
    key: 'plural (3+)',
    text: 'plural (3+)',
    value: 'pl',
  },
]

    let thatMatrix = {
      'that':{
      'sg':'that one',
      'du':'those two',
      'pl':'those (3+)'},
      'this':{
      'sg':'this one',
      'du':'these two',
      'pl':'these (3+)'},
    }
    let caseEnglish = {
    "base":'',
    "loc":'at',
    "sim":'like',
    "abl":'from',
    "ter":'to',
    "2ter":'toward',
    "2abl":'from that direction',
    "via":'through',
    "abs":"",
    "rel":"",
    "mod":"(a, some)",
    "sim":"like",
    }


class DemonstrativeNavigator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			demTableType:['roots',''],
      currentType:'roots',
      currentCase:'',
      currentNumber:'',
      history: props.location.state === undefined ? [] : props.location.state.history,
      from: props.location.state === undefined ? '' : props.location.state.from,
		}
	    scroll.scrollTo(0);
	}


  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    this.setState({ 
			from: nextProps.location.pathname,
			// fullDefinition: nextProps.fullDefinition,
		})
	}
  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentType !== this.state.currentType && this.state.currentType === 'adv') {
        this.setState({currentCase:'base'})
    }
    if (prevState.currentType !== this.state.currentType && this.state.currentType === 'pro') {
        this.setState({currentCase:'abs',currentNumber:'sg'})
    }

  }


  repeatAudio(audio, event, data) {
    if (!this.state.playingAudio) {

      let sound = new Audio(API_URL + "/inupiaqaudiolibrary/" +  audio.replaceAll('/','~'));
      this.setState({playingAudio: true});

      sound.play()

      sound.onended=()=>{
        this.setState({playingAudio: false});
      }
    }
  }
 
  handleTitleChange = (e, { value }) => {
  	this.setState({ demTableType:value })
  }

  handleTableTypeOptions = (e, { value }) => {
    this.setState({ currentType:value })
  }

  handleTableAdvCaseOptions = (e, { value }) => {
    this.setState({ currentCase:value })
  }

  handleTableProCaseOptions = (e, { value }) => {
    this.setState({ currentCase:value })
  }

  handleTableProNumberOptions = (e, { value }) => {
    this.setState({ currentNumber:value })
  }



  getCorrectForm = (word, e, value) => {
    // console.log(word)
    // console.log(this.state.demTableType)
    // console.log(word)
    // console.log(this.props.demonstrativedata[word])
    //this.props.demonstrativedata[this.state.currentRoot]['pro'][e]["examples"]
    //this.props.demonstrativedata[this.state.currentRoot]['adv'][p]['word']['text']
    //this.props.demonstrativedata[this.state.currentRoot]['pro'][l][[i]]['text']
    if (word.length !== 0) {
      if (this.state.currentType == 'roots') {
        return word.replaceAll('⎤','-').replaceAll('⎡°','').replaceAll(',',', ').toLowerCase()
      } else if (this.state.currentType == 'adv' && this.state.currentCase.length !== 0 && this.state.currentCase !== 'sim' && this.state.currentCase in this.props.demonstrativedata[word]['adv']) {
        return this.props.demonstrativedata[word]['adv'][this.state.currentCase]['word']['text']
      } else if (this.state.currentType == 'pro' && this.state.currentCase.length !== 0 && this.state.currentNumber.length !== 0 && this.state.currentCase in this.props.demonstrativedata[word]['pro']) {
        return this.props.demonstrativedata[word]['pro'][this.state.currentCase][[this.state.currentNumber]]['text']
      } else {
        return word      
      }      
    }

  }

  render() {
    // console.log(this.state)
    // let tableCase = {}
    // let tableCaseTat = {}

    // if (this.state.demTableType == 'adverbs') {
    // 	tableCase = tableCaseAdverbs
    // 	tableCaseTat = tableCaseTatAdverbs
    // 	tableTitle = 'Demonstrative adverbs'
    // } else if (this.state.demTableType == 'pronouns') {
    // 	tableCase = tableCasePronouns
    // 	tableCaseTat = tableCaseTatPronouns
    // 	tableTitle = 'Demonstrative abs. pronouns'
    // } else {
    // 	tableCase = tableCaseRoots
    // 	tableCaseTat = tableCaseTatRoots
    // 	tableTitle = 'Demonstrative roots'
    // }

    // const { activeIndex2 } = this.state;
		// console.log(this.props.location)
		// console.log(this.props)
    let clickText = 'a root'
    if (this.state.currentType == 'adv') {
      clickText = 'an adverb'
    } else if (this.state.currentType == 'pro') {
      clickText = 'a pronoun'
    }
	
		return (
			<div style={{fontFamily:customFontFam}}>
			<Transition visible={this.state.loaded} animation='fade' duration={300}>
          <Grid textAlign='center'>
          <Grid.Row  style={{height:40,paddingBottom:0}}>
          <Grid.Column style={{ maxWidth: 800, padding: 10 }} textAlign='left'>

			<div style={{display:'flex'}}>
	      <div style={{flex:1,justifyContent:'flex-start'}}>
					{this.state.from === '/' ?
						<Link to={{pathname: "/", state: { history:this.state.history }}} >
	          	<Icon circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
	          </Link>
						:
          	<Icon onClick={()=>{this.props.history.goBack()}} circular style={{margin:0,color:'#B1B1B1',cursor:'pointer'}} size='large' name='chevron left' />
					}
					<Link to={{pathname: "/"}}>
          <Icon circular style={{margin:0,marginLeft:5,color:'#B1B1B1',cursor:'pointer'}} size='large' name='search' />
					</Link>					
	      </div>


			</div>


					<div style={{border:'1px solid #E3E3E3',marginTop:'10px'}}>

					<div style={{marginBottom:25}} className='hierarchymain'>
					<span className='span1'>Tikkuaġautit</span>
					<span className='span2'>Demonstratives</span>
					</div>



					<Transition visible={this.state.loaded} animation='fade' duration={400}>
					<div>
            <span style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',alignItems:'center',flexDirection:(window.innerWidth < 480 ? 'column':'row'),flexWrap:'wrap',lineHeight:'20px',padding:'0px 10px',fontSize:'18px'}}>
              <Dropdown
                style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}}
                inline
                options={tableTypeOptions}
                defaultValue={tableTypeOptions[0].value}
                onChange={this.handleTableTypeOptions}
              />
              {this.state.currentType == 'adv' ?
                <Dropdown
                  style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}}
                  inline
                  options={tableAdvCaseOptions}
                  defaultValue={tableAdvCaseOptions[0].value}
                  onChange={this.handleTableAdvCaseOptions}
                />
                :
                null
              }
              {this.state.currentType == 'pro' ?
                <Dropdown
                  style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}}
                  inline
                  options={tableProCaseOptions}
                  defaultValue={tableProCaseOptions[0].value}
                  onChange={this.handleTableProCaseOptions}
                />
                :
                null
              }
              {this.state.currentType == 'pro' ?
                <Dropdown
                  style={{fontFamily:customFontFam, margin:'4px 2px',border:'solid 1px #22242626',fontSize:'18px',padding:'6px',borderRadius:'5px'}}
                  inline
                  options={tableProNumberOptions}
                  defaultValue={tableProNumberOptions[0].value}
                  onChange={this.handleTableProNumberOptions}
                />
                :
                null
              }

              
            </span>
              <div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',padding:'10px',fontSize:'16px',fontStyle:'italic',color:'#777777'}}>{'Click '+clickText+' below for more information'}</div>

						<div style={{display:'flex',justifyContent:'center',flexDirection:'row'}}>


              <Segment style={{margin:5,padding:0,border:'none',overflow: 'auto',fontSize:16}}>
			          <Table unstackable basic compact collapsing>
			            <Table.Header>
			              <Table.Row>
			                <Table.HeaderCell>Visible</Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)', textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}></Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>{window.innerWidth < 480 ? 'Not Vis.' : 'Not Visible'}</Table.HeaderCell>
			                <Table.HeaderCell>Gloss</Table.HeaderCell>
			              </Table.Row>
			              <Table.Row>
			                <Table.HeaderCell style={{textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}>{window.innerWidth < 480 ? 'Res.' : 'Restricted'}</Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)', textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}>{window.innerWidth < 480 ? 'Ext.' : 'Extended'}</Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)', minWidth:80}}></Table.HeaderCell>
			                <Table.HeaderCell></Table.HeaderCell>
			              </Table.Row>
			            </Table.Header>
			            <Table.Body>
			            {tableCaseRootsFST.map((l,lindex) =>
			              <Table.Row>
			                <Table.Cell style={{paddingLeft:10,fontSize:16,fontWeight:500,color:"#4a80b5"}}><Link to={{pathname: "/demonstratives/"+tableCaseRootsFST[lindex][0], state: { history:this.state.history, demTableType:[this.state.currentType,this.state.currentCase]}}} ><span style={{cursor:'pointer',}}>{this.getCorrectForm(l[0])}</span></Link></Table.Cell>
			                <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)', paddingLeft:10,fontSize:16,fontWeight:500,color:"#4a80b5"}}><Link to={{pathname: "/demonstratives/"+tableCaseRootsFST[lindex][1], state: { history:this.state.history, demTableType:[this.state.currentType,this.state.currentCase]}}} ><span style={{cursor:'pointer',}}>{this.getCorrectForm(l[1])}</span></Link></Table.Cell>
			                <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)', paddingLeft:10,fontSize:16,fontWeight:500,color:"#4a80b5"}}><Link to={{pathname: "/demonstratives/"+tableCaseRootsFST[lindex][2], state: { history:this.state.history, demTableType:[this.state.currentType,this.state.currentCase]}}} ><span style={{cursor:'pointer',}}>{this.getCorrectForm(l[2])}</span></Link></Table.Cell>
			                <Table.Cell style={{paddingLeft:10,fontSize:16,fontWeight:400}}><span>{this.state.currentType == 'pro'? (l[3] !== 'here' ? caseEnglish[this.state.currentCase]+' '+thatMatrix['that'][this.state.currentNumber] : caseEnglish[this.state.currentCase]+' '+thatMatrix['this'][this.state.currentNumber])+' '+l[3]:(this.state.currentType == 'adv'? caseEnglish[this.state.currentCase]+' '+l[3]:l[3])}</span></Table.Cell>
			              </Table.Row>
			            )}
			            </Table.Body>
			          </Table>
              </Segment>
						</div>

						<div style={{fontFamily:customFontFam, display:'flex',justifyContent:'center',marginTop:30,paddingBottom:'10px',fontSize:'16px',fontStyle:'italic',color:'#777777'}}>{"with tat- prefix"}</div>
						<div style={{display:'flex',marginBottom:30,justifyContent:'center',flexDirection:'row'}}>
              <Segment style={{margin:5,padding:0,border:'none',overflow: 'auto',fontSize:16}}>
			          <Table unstackable basic compact collapsing>
			            <Table.Header>
			              <Table.Row>
			                <Table.HeaderCell>Visible</Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)',textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}></Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>{window.innerWidth < 480 ? 'Not Vis.' : 'Not Visible'}</Table.HeaderCell>
			                <Table.HeaderCell>Gloss</Table.HeaderCell>
			              </Table.Row>
			              <Table.Row>
			                <Table.HeaderCell style={{textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}>{window.innerWidth < 480 ? 'Res.' : 'Restricted'}</Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)',textDecoration:'underline',paddingLeft:10,"color":"#000000CC",fontWeight:500}}>{window.innerWidth < 480 ? 'Ext.' : 'Extended'}</Table.HeaderCell>
			                <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)',minWidth:80}}></Table.HeaderCell>
			                <Table.HeaderCell></Table.HeaderCell>
			              </Table.Row>
			            </Table.Header>
			            <Table.Body>
			            {tableCaseTatRootsFST.map((l,lindex) =>
			              <Table.Row>
			                <Table.Cell style={{cursor:'pointer',paddingLeft:10,fontSize:16,fontWeight:500,color:"#4a80b5"}}><Link to={{pathname: "/demonstratives/"+tableCaseTatRootsFST[lindex][0], state: { history:this.state.history, demTableType:[this.state.currentType,this.state.currentCase]}}} ><span style={{cursor:'pointer',}}>{this.getCorrectForm(l[0])}</span></Link></Table.Cell>
			                <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',cursor:'pointer',paddingLeft:10,fontSize:16,fontWeight:500,color:"#4a80b5"}}><Link to={{pathname: "/demonstratives/"+tableCaseTatRootsFST[lindex][1], state: { history:this.state.history, demTableType:[this.state.currentType,this.state.currentCase]}}} ><span style={{cursor:'pointer',}}>{this.getCorrectForm(l[1])}</span></Link></Table.Cell>
			                <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',cursor:'pointer',paddingLeft:10,fontSize:16,fontWeight:500,color:"#4a80b5"}}><Link to={{pathname: "/demonstratives/"+tableCaseTatRootsFST[lindex][2], state: { history:this.state.history, demTableType:[this.state.currentType,this.state.currentCase]}}} ><span style={{cursor:'pointer',}}>{this.getCorrectForm(l[2])}</span></Link></Table.Cell>
                      <Table.Cell style={{paddingLeft:10,fontSize:16,fontWeight:400}}><span>{this.state.currentType == 'pro'? caseEnglish[this.state.currentCase]+' '+thatMatrix['that'][this.state.currentNumber]+' '+l[3]:(this.state.currentType == 'adv'? caseEnglish[this.state.currentCase]+' '+l[3]:l[3])}</span></Table.Cell>
			              </Table.Row>
			            )}
			            </Table.Body>
			          </Table>
              </Segment>
						</div>
					</div>
					</Transition>
					

					</div>


			    <Link to={{pathname: '/report'}}>
						<div style={{cursor:'pointer',paddingTop:'10px',paddingLeft:'8px'}}>
						<Icon style={{color:'#a9a9a9',}} name='exclamation circle' />
						<span style={{fontSize:'16px',color:'#9d9d9d',fontWeight:'400',lineHeight:'23px'}}>Report an Issue</span>
						</div>
          </Link>

        <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',height:90,paddingBottom:15,paddingTop:25}}>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
          <Image style={{height:'50px',opacity:0.9}} src='/inupiaqfish.png'/>
        </div>

				</Grid.Column>
				</Grid.Row>
				</Grid>
				</Transition>
			</div>
		);
	}
}
export default DemonstrativeNavigator;