// export const speakerColors = {
//   // 0:'red',
//   // 1:'orange',
//   // 2:'yellow',
//   // 3:'green',
//   4:'#2e2eb9',
//   5:'purple',
//   // 6:'purple',
//   // 7:'black',
//   // 8:'gold',
// }

export const moodColors = {
"black":"#000000",
"blacksoft":"#000000DE",
"1stmm":"#C78CBB",
"mainsub":"#852828",
"obj":"#3C7383",
"secondsub":"#565994",
"2ndmm":"#EC7575",

}
export const customFontFam = "Roboto,'Helvetica Neue',Arial,Helvetica,sans-serif"

export const newColorsList = { 

    'v.b':'#000000',
    'v.s':'#287485',
    'v.o':'#565994',
    'v.m':'#d188be',
    'v.1':'#000000',
    'v.2':'#000000',
    'v.3':'#000000',

    '1s':"#287485",
    '2s':"#287485",
    '3s':"#287485",

    '1o':"#383c8a",
    '2o':"#383c8a",
    '3o':"#383c8a",

    '1':"#f19d38",
    '2':"#8b2cf5",
    '3':'#a5429f',
    '4':'#852828',

    'pb.m':'#d188be',


    '1stPerson':"#f19d38",
    '2ndPerson':"#8b2cf5",

    'mvv.s':'#852828',
    'mvv.is':'#7b7b7b',
    'mvv.o':'#C84141',
    'mvv.b':'#000000',
    // 'mvv.m':'#838383',
    'mvv.m':'#7b7b7b',
    'mvv.1':'#d3741e',
    'mvv.2':'#3455b5',
    'mvv.3':'#b165d3',
    'mvqWord':'#148f38',

    'timeWord':'#023e8a',
    
    "pb.1":"#cfb541",
    "pb.2":"#e68833",
    "pb.3":"#4a90c1",
    "pb.4":"#89bb68",
    "pb.5":"#60187e",
    "pb.6":"#6cb28a",
    "pb.7":"#75b67e",
    "pb.8":"#e68833",
    "pb.9":"#93bd60",
    "pb.10":"#403f96",
    "pb.11":"#64ae97",
    "pb.12":"#e59535",
    "pb.13":"#e77930",
    "pb.14":"#4685c2",
    "pb.15":"#e4572a",
    "pb.16":"#bdbc48",
    "pb.17":"#dda93b",
    "pb.18":"#c6b944",
    "pb.19":"#4f99bb",
    "pb.20":"#9ebe58",
    "pb.21":"#781c81",
    "pb.22":"#3f4ea1",
    "pb.23":"#e59535",
    "pb.24":"#e6692d",
    "pb.25":"#7eb972",
    "pb.26":"#e1a038",
    "pb.27":"#3f5dac",
    "pb.28":"#521c80",
    "pb.29":"#537522",
    "pb.30":"#89bb68",
    "pb.31":"#e68833",
    "pb.32":"#406cb6",
    "pb.33":"#43b8be",
    "pb.34":"#75b67e",
    "pb.35":"#e6692d",
    "pb.36":"#a8be52",
    "pb.37":"#d6b03d",
    "pb.38":"#dda93b",


    // 'past':'#dd9221',
    // 'future':'#dd9221',
    'past':'#000000',
    'future':'#000000',

    "mvns00.e":'#000000DE',
    'mvns00.b':'#852828',
    'mvns00.pd':'#852828',
    'mvns00.ps':'#a5429f',
    'mvns10.b':'#a5429f',
    'mvns10.pd':'#a5429f',
    'mvns10.ps':'#6848e6',
    'mvns20.b':'#6848e6',
    'mvns20.pd':'#6848e6',
    'mvns20.ps':'#5c5c5c',

    // 'mvns00.ps':'#000000',
    // 'mvns10.b':'#000000',
    // 'mvns10.pd':'#000000',
    // 'mvns20.b':'#000000',
    // 'mvns20.pd':'#000000',

    // 'mvns10.b':'#852828',
    // 'mvns10.pd':'#852828',
    'mvns00.c':'#663782',    
    'mvns10.c':'#663782',    
    'mvns20.c':'#663782',    
    'mvns00.1':'#852828',
    'mvns00.2':'#852828',
    'mvns00.3':'#852828',


    // 'mvno00.b':'#C84141',
    // 'mvno00.pd':'#C84141', 
    // 'mvno00.ps':'#5c5c5c',
    // 'mvno10.ps':'#5c5c5c',
    // 'mvno20.ps':'#5c5c5c',

    'mvno00.c':'#663782',
    'mvno10.c':'#663782',
    'mvno20.c':'#663782',

    'cvno10.c':'#663782',
    'cvno20.c':'#663782',
    'mvneo10.c':'#663782',
    'mvneo20.c':'#663782',

    'cvns10.c':'#663782',
    'cvns20.c':'#663782',
    'mvnes10.c':'#663782',
    'mvnes20.c':'#663782',

        "mvno00.e":'#000000DE',
    'mvno00.b':'#C84141',
    'mvno00.pd':'#C84141',
    'mvno00.ps':'#cf5ca0',
    'mvno10.b':'#cf5ca0',
    'mvno10.pd':'#cf5ca0',
    'mvno10.ps':'#977ef9',
    'mvno20.b':'#977ef9',
    'mvno20.pd':'#977ef9',
    'mvno20.ps':'#5c5c5c',


    'mvneo00.b':'#9135BC',
    'mvneo00.c':'#f37837',
    'mvneo00.pd':'#9135BC', 
    'mvneo00.ps':'#cf5ca0', 
    'mvneo10.b':'#cf5ca0',
    'mvneo10.pd':'#cf5ca0',
    'mvneo10.ps':'#977ef9',
    'mvneo20.b':'#977ef9',
    'mvneo20.pd':'#977ef9',
    'mvneo20.ps':'#5c5c5c',

    'mvnes00.b':'#3360CB',
    'mvnes00.c':'#f37837',
    'mvnes00.pd':'#3360CB',
    'mvnes00.ps':'#a5429f',
    'mvnes10.b':'#a5429f',
    'mvnes10.pd':'#a5429f',
    'mvnes10.ps':'#6848e6',
    'mvnes20.b':'#6848e6',
    'mvnes20.pd':'#6848e6',
    'mvnes20.ps':'#5c5c5c',

    'mvno00.c':'#f37837',
    // 'mvno10.b':'#000000',
    // 'mvno20.b':'#000000',
    // 'mvno10.b':'#C84141',
    // 'mvno10.pd':'#C84141', 
    'mvno00.1':'#C84141',
    'mvno00.2':'#C84141',
    'mvno00.3':'#C84141',

    'cvv.b':'#000000',
    'cvv.s':'#287485',
    'cvv.o':'#565994',
    'cvv.m':'#d188be',
    'cvv.1':'#000000',
    'cvv.2':'#000000',
    'cvv.3':'#000000',

    'cvns00.c':'#f37837',

    "cvns00.e":'#000000DE',
    'cvns00.b':'#287485',
    'cvns00.pd':'#287485',
    'cvns00.ps':'#a5429f',
    'cvns10.b':'#a5429f',
    'cvns10.pd':'#a5429f',
    'cvns10.ps':'#6848e6',
    'cvns20.b':'#6848e6',
    'cvns20.pd':'#6848e6',
    'cvns20.ps':'#5c5c5c',

    // 'cvns00.b':'#287485',
    // 'cvns00.pd':'#287485',

    // 'cvns10.ps':'#9c9c9c',
    // 'cvns20.ps':'#9c9c9c',

    // 'cvns00.c':'#663782',    
    // 'cvns10.b':'#000000',
    // 'cvns20.b':'#000000',
    // 'cvns10.pd':'#287485',
    // 'cvns00.1':'#287485',
    // 'cvns00.2':'#287485',
    // 'cvns00.3':'#287485',

    // 'cvno00.b':'#565994',
    // 'cvno00.pd':'#565994', 

    // 'cvno10.ps':'#9c9c9c',
    // 'cvno20.ps':'#9c9c9c',

    // 'cvno00.c':'#663782',
    // 'cvno10.b':'#000000',
    // 'cvno20.b':'#000000',
    // 'cvno10.b':'#565994',
    // 'cvno10.pd':'#565994', 
    // 'cvno00.1':'#565994',
    // 'cvno00.2':'#565994',
    // 'cvno00.3':'#565994',


    'cvno00.c':'#663782',

    "cvno00.e":'#000000DE',
    'cvno00.b':'#565994',
    'cvno00.pd':'#565994',
    'cvno00.ps':'#cf5ca0',
    'cvno10.b':'#cf5ca0',
    'cvno10.pd':'#cf5ca0',
    'cvno10.ps':'#977ef9',
    'cvno20.b':'#977ef9',
    'cvno20.pd':'#977ef9',
    'cvno20.ps':'#5c5c5c',

    // 'svv.b':'#000000',
    // 'svv.o':'#565994',
    // 'svv.s':'#852828',
    // 'svv.e':'#565994',
    // 'svv.m':'#000000',
    // 'svv.1':'#000000',
    // 'svv.2':'#000000',
    // 'svv.3':'#000000',

    // 'svno00.b':'#565994',
    // 'svno00.pd':'#565994',

    // 'svno10.ps':'#9c9c9c',
    // 'svno20.ps':'#9c9c9c',

    // 'svno00.c':'#663782',
    // // 'svno10.b':'#000000',
    // // 'svno20.b':'#000000',
    // // 'svno10.b':'#565994',
    // // 'svno10.pd':'#565994', 
    // 'svno00.1':'#565994',
    // 'svno00.2':'#565994',
    // 'svno00.3':'#565994',

    // 'npn00.b':'#852828',
    // 'npn00.pd':'#852828',
    // 'npn00.ps':'#5c5c5c',

    'n.c':'#d188be',    
    'n.b':'#000000DE',
    'n.pd':'#000000DE',
    'n.ps':'#a5429f',
    'e.1':'#42a626',
    'e.2':'#cc2727',




    'npn00.c':'#663782',    
    'npn00.b':'#852828',
    'npn00.pd':'#852828',
    'npn00.ps':'#a5429f',
    'npn10.c':'#663782',    
    'npn10.b':'#a5429f',
    'npn10.pd':'#a5429f',
    'npn10.ps':'#6848e6',
    'npn20.c':'#663782',    
    'npn20.b':'#6848e6',
    'npn20.pd':'#6848e6',
    'npn20.ps':'#5c5c5c',


    'npnc00.c':'#663782',    
    'npnc00.b':'#852828',
    'npnc00.1':'#852828',
    'npnc00.pd':'#852828',
    'npnc00.ps':'#539e29',
    'npnc10.c':'#663782',    
    'npnc10.b':'#539e29',
    'npnc10.pd':'#539e29',
    'npnc10.ps':'#de9000',
    'npnc20.c':'#663782',    
    'npnc20.b':'#de9000',
    'npnc20.pd':'#de9000',
    'npnc20.ps':'#5c5c5c',


    'mvnObliques000.c':'#663782',
    'mvnObliques000.b':'#000000',
    'mvnObliques000.pd':'#000000',
    'mvnObliques000.ps':'#a5429f',

    'mvnObliques010.c':'#663782',
    'mvnObliques010.b':'#a5429f',
    'mvnObliques010.pd':'#a5429f',
    'mvnObliques010.ps':'#6848e6',

    'mvnObliques020.c':'#663782',
    'mvnObliques020.b':'#6848e6',
    'mvnObliques020.pd':'#6848e6',
    'mvnObliques020.ps':'#9c9c9c',

    'mvnObliques100.c':'#663782',
    'mvnObliques100.b':'#000000',
    'mvnObliques100.pd':'#000000',
    'mvnObliques100.ps':'#a5429f',

    'mvnObliques110.c':'#663782',
    'mvnObliques110.b':'#a5429f',
    'mvnObliques110.pd':'#a5429f',
    'mvnObliques110.ps':'#6848e6',

    'mvnObliques120.c':'#663782',
    'mvnObliques120.b':'#6848e6',
    'mvnObliques120.pd':'#6848e6',
    'mvnObliques120.ps':'#9c9c9c',

    'mvnObliques200.c':'#663782',
    'mvnObliques200.b':'#000000',
    'mvnObliques200.pd':'#000000',
    'mvnObliques200.ps':'#a5429f',

    'mvnObliques210.c':'#663782',
    'mvnObliques210.b':'#a5429f',
    'mvnObliques210.pd':'#a5429f',
    'mvnObliques210.ps':'#6848e6',

    'mvnObliques220.c':'#663782',
    'mvnObliques220.b':'#6848e6',
    'mvnObliques220.pd':'#6848e6',
    'mvnObliques220.ps':'#9c9c9c',


    // 'cvnObliques000.b':'#000000',
    // 'cvnObliques000.pd':'#000000',
    // 'cvnObliques010.ps':'#9c9c9c',
    // 'cvnObliques000.c':'#663782',
    // 'cvnObliques000.1':'#000000',
    // 'cvnObliques000.2':'#000000',
    // 'cvnObliques000.3':'#000000',

    // 'cvnObliques100.b':'#000000',
    // 'cvnObliques100.pd':'#000000',
    // 'cvnObliques110.ps':'#9c9c9c',
    // 'cvnObliques100.c':'#663782',
    // 'cvnObliques100.1':'#000000',
    // 'cvnObliques100.2':'#000000',
    // 'cvnObliques100.3':'#000000',

    // 'svnObliques000.b':'#000000',
    // 'svnObliques000.pd':'#000000',
    // 'svnObliques010.ps':'#9c9c9c',
    // 'svnObliques000.c':'#663782',
    // 'svnObliques000.1':'#000000',
    // 'svnObliques000.2':'#000000',
    // 'svnObliques000.3':'#000000',

    // 'svnObliques100.b':'#000000',
    // 'svnObliques100.pd':'#000000',
    // 'svnObliques110.ps':'#9c9c9c',
    // 'svnObliques100.c':'#663782',
    // 'svnObliques100.1':'#000000',
    // 'svnObliques100.2':'#000000',
    // 'svnObliques100.3':'#000000',


    // #287485
    // #565994
  }


export const colorsList = { 
    '1stPerson':"#f19d38",
    '2ndPerson':"#8b2cf5",

    'mvv.s':'#852828',
    'mvv.is':'#7b7b7b',
    'mvv.o':'#C84141',
    'mvv.b':'#000000DE',
    // 'mvv.m':'#838383',
    'mvv.m':'#7b7b7b',
    'mvv.1':'#d3741e',
    'mvv.2':'#3455b5',
    'mvv.3':'#b165d3',
    'mvqWord':'#148f38',
    
    "pb.1":"#cfb541",
    "pb.2":"#89bb68",
    "pb.3":"#4a90c1",
    "pb.4":"#89bb68",
    "pb.5":"#60187e",
    "pb.6":"#6cb28a",
    "pb.7":"#75b67e",
    "pb.8":"#e68833",
    "pb.9":"#93bd60",
    "pb.10":"#403f96",
    "pb.11":"#64ae97",
    "pb.12":"#e59535",
    "pb.13":"#e77930",
    "pb.14":"#4685c2",
    "pb.15":"#e4572a",
    "pb.16":"#bdbc48",
    "pb.17":"#dda93b",
    "pb.18":"#c6b944",
    "pb.19":"#4f99bb",
    "pb.20":"#9ebe58",
    "pb.21":"#781c81",
    "pb.22":"#3f4ea1",
    "pb.23":"#e59535",
    "pb.24":"#e6692d",
    "pb.25":"#7eb972",
    "pb.26":"#e1a038",
    "pb.27":"#3f5dac",
    "pb.28":"#521c80",
    "pb.29":"#537522",
    "pb.30":"#89bb68",
    "pb.31":"#e68833",
    "pb.32":"#406cb6",
    "pb.33":"#43b8be",
    "pb.34":"#75b67e",
    "pb.35":"#e6692d",
    "pb.36":"#a8be52",
    "pb.37":"#d6b03d",
    "pb.38":"#dda93b",


    // 'past':'#dd9221',
    // 'future':'#dd9221',
    'past':'#000000',
    'future':'#000000',

    "mvns00.e":'#000000DE',
    'mvns00.b':'#852828',
    'mvns00.pd':'#852828',
    'mvns00.ps':'#a5429f',
    'mvns10.b':'#a5429f',
    'mvns10.pd':'#a5429f',
    'mvns10.ps':'#6848e6',
    'mvns20.b':'#6848e6',
    'mvns20.pd':'#6848e6',
    'mvns20.ps':'#5c5c5c',

    // 'mvns00.ps':'#000000',
    // 'mvns10.b':'#000000',
    // 'mvns10.pd':'#000000',
    // 'mvns20.b':'#000000',
    // 'mvns20.pd':'#000000',

    // 'mvns10.b':'#852828',
    // 'mvns10.pd':'#852828',
    'mvns00.c':'#663782',    
    'mvns00.1':'#852828',
    'mvns00.2':'#852828',
    'mvns00.3':'#852828',

    // 'mvno00.b':'#C84141',
    // 'mvno00.pd':'#C84141', 
    // 'mvno00.ps':'#5c5c5c',
    // 'mvno10.ps':'#5c5c5c',
    // 'mvno20.ps':'#5c5c5c',

        "mvno00.e":'#000000DE',
    'mvno00.b':'#C84141',
    'mvno00.pd':'#C84141',
    'mvno00.ps':'#cf5ca0',
    'mvno10.b':'#cf5ca0',
    'mvno10.pd':'#cf5ca0',
    'mvno10.ps':'#977ef9',
    'mvno20.b':'#977ef9',
    'mvno20.pd':'#977ef9',
    'mvno20.ps':'#5c5c5c',


    'mvneo00.b':'#9135BC',
    'mvneo00.c':'#f37837',
    'mvneo00.pd':'#9135BC', 
    'mvneo00.ps':'#cf5ca0', 
    'mvneo10.b':'#cf5ca0',
    'mvneo10.pd':'#cf5ca0',
    'mvneo10.ps':'#977ef9',
    'mvneo20.b':'#977ef9',
    'mvneo20.pd':'#977ef9',
    'mvneo20.ps':'#5c5c5c',

    'mvnes00.b':'#3360CB',
    'mvnes00.c':'#f37837',
    'mvnes00.pd':'#3360CB',
    'mvnes00.ps':'#a5429f',
    'mvnes10.b':'#a5429f',
    'mvnes10.pd':'#a5429f',
    'mvnes10.ps':'#6848e6',
    'mvnes20.b':'#6848e6',
    'mvnes20.pd':'#6848e6',
    'mvnes20.ps':'#5c5c5c',

    'mvno00.c':'#f37837',
    // 'mvno10.b':'#000000',
    // 'mvno20.b':'#000000',
    // 'mvno10.b':'#C84141',
    // 'mvno10.pd':'#C84141', 
    'mvno00.1':'#C84141',
    'mvno00.2':'#C84141',
    'mvno00.3':'#C84141',

    'cvv.b':'#000000',
    'cvv.s':'#287485',
    'cvv.o':'#565994',
    'cvv.m':'#d188be',
    'cvv.1':'#000000',
    'cvv.2':'#000000',
    'cvv.3':'#000000',

    'cvns00.c':'#f37837',

    "cvns00.e":'#000000DE',
    'cvns00.b':'#287485',
    'cvns00.pd':'#287485',
    'cvns00.ps':'#a5429f',
    'cvns10.b':'#a5429f',
    'cvns10.pd':'#a5429f',
    'cvns10.ps':'#6848e6',
    'cvns20.b':'#6848e6',
    'cvns20.pd':'#6848e6',
    'cvns20.ps':'#5c5c5c',

    // 'cvns00.b':'#287485',
    // 'cvns00.pd':'#287485',

    // 'cvns10.ps':'#9c9c9c',
    // 'cvns20.ps':'#9c9c9c',

    // 'cvns00.c':'#663782',    
    // 'cvns10.b':'#000000',
    // 'cvns20.b':'#000000',
    // 'cvns10.pd':'#287485',
    // 'cvns00.1':'#287485',
    // 'cvns00.2':'#287485',
    // 'cvns00.3':'#287485',

    // 'cvno00.b':'#565994',
    // 'cvno00.pd':'#565994', 

    // 'cvno10.ps':'#9c9c9c',
    // 'cvno20.ps':'#9c9c9c',

    // 'cvno00.c':'#663782',
    // 'cvno10.b':'#000000',
    // 'cvno20.b':'#000000',
    // 'cvno10.b':'#565994',
    // 'cvno10.pd':'#565994', 
    // 'cvno00.1':'#565994',
    // 'cvno00.2':'#565994',
    // 'cvno00.3':'#565994',


    'cvno00.c':'#663782',

    "cvno00.e":'#000000DE',
    'cvno00.b':'#565994',
    'cvno00.pd':'#565994',
    'cvno00.ps':'#cf5ca0',
    'cvno10.b':'#cf5ca0',
    'cvno10.pd':'#cf5ca0',
    'cvno10.ps':'#977ef9',
    'cvno20.b':'#977ef9',
    'cvno20.pd':'#977ef9',
    'cvno20.ps':'#5c5c5c',

    // 'svv.b':'#000000',
    // 'svv.o':'#565994',
    // 'svv.s':'#852828',
    // 'svv.e':'#565994',
    // 'svv.m':'#000000',
    // 'svv.1':'#000000',
    // 'svv.2':'#000000',
    // 'svv.3':'#000000',

    // 'svno00.b':'#565994',
    // 'svno00.pd':'#565994',

    // 'svno10.ps':'#9c9c9c',
    // 'svno20.ps':'#9c9c9c',

    // 'svno00.c':'#663782',
    // // 'svno10.b':'#000000',
    // // 'svno20.b':'#000000',
    // // 'svno10.b':'#565994',
    // // 'svno10.pd':'#565994', 
    // 'svno00.1':'#565994',
    // 'svno00.2':'#565994',
    // 'svno00.3':'#565994',

    // 'npn00.b':'#852828',
    // 'npn00.pd':'#852828',
    // 'npn00.ps':'#5c5c5c',
    'npn00.c':'#663782',    
    'npn00.b':'#852828',
    'npn00.pd':'#852828',
    'npn00.ps':'#a5429f',
    'npn10.c':'#663782',    
    'npn10.b':'#a5429f',
    'npn10.pd':'#a5429f',
    'npn10.ps':'#6848e6',
    'npn20.c':'#663782',    
    'npn20.b':'#6848e6',
    'npn20.pd':'#6848e6',
    'npn20.ps':'#5c5c5c',


    'npnc00.c':'#663782',    
    'npnc00.b':'#852828',
    'npnc00.1':'#852828',
    'npnc00.pd':'#852828',
    'npnc00.ps':'#539e29',
    'npnc10.c':'#663782',    
    'npnc10.b':'#539e29',
    'npnc10.pd':'#539e29',
    'npnc10.ps':'#de9000',
    'npnc20.c':'#663782',    
    'npnc20.b':'#de9000',
    'npnc20.pd':'#de9000',
    'npnc20.ps':'#5c5c5c',


    'mvnObliques000.c':'#663782',
    'mvnObliques000.b':'#000000',
    'mvnObliques000.pd':'#000000',
    'mvnObliques000.ps':'#a5429f',

    'mvnObliques010.c':'#663782',
    'mvnObliques010.b':'#a5429f',
    'mvnObliques010.pd':'#a5429f',
    'mvnObliques010.ps':'#6848e6',

    'mvnObliques020.c':'#663782',
    'mvnObliques020.b':'#6848e6',
    'mvnObliques020.pd':'#6848e6',
    'mvnObliques020.ps':'#9c9c9c',

    'mvnObliques100.c':'#663782',
    'mvnObliques100.b':'#000000',
    'mvnObliques100.pd':'#000000',
    'mvnObliques100.ps':'#a5429f',

    'mvnObliques110.c':'#663782',
    'mvnObliques110.b':'#a5429f',
    'mvnObliques110.pd':'#a5429f',
    'mvnObliques110.ps':'#6848e6',

    'mvnObliques120.c':'#663782',
    'mvnObliques120.b':'#6848e6',
    'mvnObliques120.pd':'#6848e6',
    'mvnObliques120.ps':'#9c9c9c',

    'mvnObliques200.c':'#663782',
    'mvnObliques200.b':'#000000',
    'mvnObliques200.pd':'#000000',
    'mvnObliques200.ps':'#a5429f',

    'mvnObliques210.c':'#663782',
    'mvnObliques210.b':'#a5429f',
    'mvnObliques210.pd':'#a5429f',
    'mvnObliques210.ps':'#6848e6',

    'mvnObliques220.c':'#663782',
    'mvnObliques220.b':'#6848e6',
    'mvnObliques220.pd':'#6848e6',
    'mvnObliques220.ps':'#9c9c9c',


    // 'cvnObliques000.b':'#000000',
    // 'cvnObliques000.pd':'#000000',
    // 'cvnObliques010.ps':'#9c9c9c',
    // 'cvnObliques000.c':'#663782',
    // 'cvnObliques000.1':'#000000',
    // 'cvnObliques000.2':'#000000',
    // 'cvnObliques000.3':'#000000',

    // 'cvnObliques100.b':'#000000',
    // 'cvnObliques100.pd':'#000000',
    // 'cvnObliques110.ps':'#9c9c9c',
    // 'cvnObliques100.c':'#663782',
    // 'cvnObliques100.1':'#000000',
    // 'cvnObliques100.2':'#000000',
    // 'cvnObliques100.3':'#000000',

    // 'svnObliques000.b':'#000000',
    // 'svnObliques000.pd':'#000000',
    // 'svnObliques010.ps':'#9c9c9c',
    // 'svnObliques000.c':'#663782',
    // 'svnObliques000.1':'#000000',
    // 'svnObliques000.2':'#000000',
    // 'svnObliques000.3':'#000000',

    // 'svnObliques100.b':'#000000',
    // 'svnObliques100.pd':'#000000',
    // 'svnObliques110.ps':'#9c9c9c',
    // 'svnObliques100.c':'#663782',
    // 'svnObliques100.1':'#000000',
    // 'svnObliques100.2':'#000000',
    // 'svnObliques100.3':'#000000',


    // #287485
    // #565994
  }



export const inupiaqSpeakerIDs = {
// "Aglu (Whittier Williams Jr.)":0,
// "I'yiiqpak (Gladys Pungowiyi)":1,
// "Kapniaq (Lorena Williams)":2,
// "Maqik (Mary Schaeffer)":3,
"James Mumi\u0121aaluk Nageak":'#2e2eb9',
"Edna Paniattaaq MacLean":'purple',
"Katherine Uumiñaq Ahgeak":'#656565',
1:'#2e2eb9',
0:'purple',
2:'#656565',

// "Taġġaq (Lawrence Kaplan)":6,
// "Uyuġuluk (Marilyn Koezuna-Irelan)":7,
// "Yaayuk (Bernadette Alvanna-Stimpfle)":8,    
}

export const nounoptions1 = [
  {id: 0, value: '00(3)', text:'the'},
  {id: 1, value: '11(3)', text:'my'},
  {id: 2, value: '21(3)', text:'your'},
  {id: 3, value: '31-1(3)', text:'his'},
  {id: 4, value: '31-2(3)', text:'her'},
  {id: 5, value: '31-3(3)', text:'its'},
  {id: 6, value: '41(3)', text:'its own'},
  {id: 7, value: '12(3)', text:'our (two)'},
  {id: 8, value: '22(3)', text:'your (two)'},
  {id: 9, value: '32(3)', text:'their (two)'},
  {id: 10, value: '42(3)', text:'their own (two)'},
  {id: 11, value: '13(3)', text:'our (3+)'},
  {id: 12, value: '23(3)', text:'your (3+)'},
  {id: 13, value: '33(3)', text:'their (3+)'},
  {id: 14, value: '43(3)', text:'their own (3+)'},
];

export const nounoptionsmodalis = [
  {id: 0, value: '00(3)', text:'a, some'},
  {id: 1, value: '11(3)', text:'my'},
  {id: 2, value: '21(3)', text:'your'},
  {id: 3, value: '31-1(3)', text:'his'},
  {id: 4, value: '31-2(3)', text:'her'},
  {id: 5, value: '31-3(3)', text:'its'},
  // {id: 6, value: '41(3)', text:'its own'},
  {id: 7, value: '12(3)', text:'our (two)'},
  {id: 8, value: '22(3)', text:'your (two)'},
  {id: 9, value: '32(3)', text:'their (two)'},
  // {id: 10, value: '42(3)', text:'their own (two)'},
  {id: 11, value: '13(3)', text:'our (3+)'},
  {id: 12, value: '23(3)', text:'your (3+)'},
  {id: 13, value: '33(3)', text:'their (3+)'},
];

export const nounoptions2 = [
  {id: 0, value: '1', text:'one'},
  {id: 1, value: '2', text:'two'},
  {id: 2, value: '3', text:'three or more'},
];

export const nounoptions3 = [
  {id: 0, value: '1', text:'(supply/skin of) one'},
  {id: 1, value: '2', text:'(supply/skins of) two'},
  {id: 2, value: '3', text:'(supply/skins of) three or more'},
];


export const options1 = [
  {id: 0, value: 's11(1)', text:'I', otext:'me'},
  {id: 1, value: 's21(1)', text:'you', otext:'you'},
  {id: 2, value: 's31-1(1)', text:'he', otext:'him'},
  {id: 3, value: 's31-2(1)', text:'she', otext:'her'},
  {id: 4, value: 's31-3(1)', text:'it', otext:'it'},
  // {id: 5, value: 's41(1)', text:'its own', otext:'its own'},
  {id: 5, value: 's12(1)', text:'the two of us', otext:'the two of us'},
  {id: 6, value: 's22(1)', text:'the two of you', otext:'the two of you'},
  {id: 7, value: 's32(1)', text:'the two of them', otext:'the two of them'},
  // {id: 9, value: 's42(1)', text:'their own (two)', otext:'their own (two)'},
  {id: 8, value: 's13(1)', text:'we all (3+)', otext:'us all (3+)'},
  {id: 9, value: 's23(1)', text:'you all (3+)', otext:'you all (3+)'},
  {id: 10, value: 's33(1)', text:'they all (3+)', otext:'them all (3+)'},
  // {id: 13, value: 's43(1)', text:'their own (3+)', otext:'their own (3+)'}
];

export const options2 = [
  {id: 0, value: 'o11(2)', text:'me', ptext:'my'},
  {id: 1, value: 'o21(2)', text:'you', ptext:'your'},
  {id: 2, value: 'o31-1(2)', text:'him', ptext:'his'},
  {id: 3, value: 'o31-2(2)', text:'her', ptext:'her'},
  {id: 4, value: 'o31-3(2)', text:'it', ptext:'its'},
  // {id: 5, value: 'o41(2)', text:'its own', ptext:'its own'},
  {id: 5, value: 'o12(2)', text:'the two of us', ptext:'our (two)'},
  {id: 6, value: 'o22(2)', text:'the two of you', ptext:'your (two)'},
  {id: 7, value: 'o32(2)', text:'the two of them', ptext:'their (two)'},
  // {id: 9, value: 'o42(2)', text:'their own (two)', ptext:'their own (two)'},
  {id: 8, value: 'o13(2)', text:'us all (3+)', ptext:'our (3+)'},
  {id: 9, value: 'o23(2)', text:'you all (3+)', ptext:'your (3+)'},
  {id: 10, value: 'o33(2)', text:'them all (3+)', ptext:'their (3+)'},
  // {id: 13, value: 'o43(2)', text:'their own (3+)', ptext:'their own (3+)'},
];


export const options3 = [
  {id: 0, value: 'o11(2)', text:'my'},
  {id: 1, value: 'o21(2)', text:'your'},
  {id: 2, value: 'o31-1(2)', text:'his'},
  {id: 3, value: 'o31-2(2)', text:'her'},
  {id: 4, value: 'o31-3(2)', text:'its'},
  // {id: 5, value: 'o41(2)', text:'its own'},
  {id: 5, value: 'o12(2)', text:'our (two)'},
  {id: 6, value: 'o22(2)', text:'your (two)'},
  {id: 7, value: 'o32(2)', text:'their (two)'},
  // {id: 9, value: 'o42(2)', text:'their own (two)'},
  {id: 8, value: 'o13(2)', text:'our (3+)'},
  {id: 9, value: 'o23(2)', text:'your (3+)'},
  {id: 10, value: 'o33(2)', text:'their (3+)'},
  // {id: 13, value: 'o43(2)', text:'their own (3+)', ptext:'their own (3+)'},
];

export const retrieveSubjectObject =
  {
  's11(1)':'I',
  's21(1)':'you',
  's31-1(1)':'he',
  's31-2(1)':'she',
  's31-3(1)':'it',
  's41(1)':'its own',
  's12(1)':'the two of us',
  's22(1)':'the two of you',
  's32(1)':'the two of them',
  's42(1)':'their own (two)',
  's13(1)':'we all (3+)',
  's23(1)':'you all (3+)',
  's33(1)':'they all (3+)',
  's43(1)':'their own (3+)',
  'o11(2)':'me',
  'o21(2)':'you',
  'o31-1(2)':'him',
  'o31-2(2)':'her',
  'o31-3(2)':'it',
  'o41(2)':'its own',
  'o12(2)':'the two of us',
  'o22(2)':'the two of you',
  'o32(2)':'the two of them',
  'o42(2)':'their own (two)',
  'o13(2)':'us all (3+)',
  'o23(2)':'you all (3+)',
  'o33(2)':'them all (3+)',
  'o43(2)':'their own (3+)',
  'o11(3)':'my',
  'o21(3)':'your',
  'o31-1(3)':'his',
  'o31-2(3)':'her',
  'o31-3(3)':'its',
  'o41(3)':'its own',
  'o12(3)':'our (two)',
  'o22(3)':'your (two)',
  'o32(3)':'their (two)',
  'o42(3)':'their own (two)',
  'o13(3)':'our (3+)',
  'o23(3)':'your (3+)',
  'o33(3)':'their (3+)',
  'o43(3)':'their own (3+)',
  '00(3)':'the',
  '11(3)':'my',
  '21(3)':'your',
  '31-1(3)':'his',
  '31-2(3)':'her',
  '31-3(3)':'its',
  '12(3)':'our (two)',
  '22(3)':'your (two)',
  '32(3)':'their (two)',
  '13(3)':'our (3+)',
  '23(3)':'your (3+)',
  '33(3)':'their (3+)',
  '1':'one',
  '2':'two',
  '3':'three or more',
}

;

export const verbPostbases = {
  // '0':{
  //   // id: 0,
  //   description: 'is probably',
  //   englishModifier: (english) => { return 'probably '+ english; },
  //   expression: 'palliq',
  //   expression_conditional: '',
  //   conditional_rule: '',
  //   priority: 1,
  //   tense:'',
  //   allowable_next_ids: [1]
  // },
//   {
//     id: 1,
//     description: 'is evidently (without being observed)',
//     englishModifier: (english) => { return 'evidently '+ english; },
//     expression: '-2ini\\',
//     expression_conditional: '',
//     conditional_rule: '',
//     priority: 1,
//     tense:'',
//     allowable_next_ids: [0]
//   },
//   {
//     id: 2,
//     description: 'is no longer able to',
//     englishModifier: (english) => { return 'no longer able to '+ english; },
//     expression: '+(s)ciigali\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1,3]
//   },
//   {
//     id: 3,
//     description: 'is not able to',
//     englishModifier: (english) => { return 'not able to '+ english; },
//     expression: '+(s)ciigate\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1,2]
//   },

// notis = [1,2,6,7,10]
// future = [1,2]
// infinitive = [1,2,6,8,9,10,11]

// if not added second for notis, use does

  1:{
    // id: 4,
    description: 'will',
    englishModifier: (english) => { return 'will '+ english; },
    englishModifierInfinitive: (english) => { return 'will '+ english; },
    englishModifierGerund: (english) => { return 'will '+ english; },
    englishModifierPast: (english) => { return 'will '+ english; },
    expression: 'niaq',
    // tense:'future',
    allowable_next_ids: [10, 11, 5, 6, 8, 7, 12, 9],
  },
  2:{
    // id: 4,
    description: 'will not',
    englishModifier: (english) => { return 'will not '+ english; },
    englishModifierInfinitive: (english) => { return 'will not '+ english; },
    englishModifierGerund: (english) => { return 'will not '+ english; },
    englishModifierPast: (english) => { return "won't "+ english; },
    expression: 'niaq>ŋit',
    // tense:'future',
    allowable_next_ids: [6, 10, 11, 9, 12, 7, 8],
  },
  3:{
    // id: 4,
    description: 'probably',
    englishModifier: (english) => { return 'probably '+ english; },
    englishModifierInfinitive: (english) => { return 'probably '+ english; },
    englishModifierGerund: (english) => { return 'probably '+ english; },
    englishModifierPast: (english) => { return 'probably '+ english; },
    expression: 'palliq',
    // tense:'',
    allowable_next_ids: [1, 2, 6, 4],
  },
  4:{
    // id: 4,
    description: 'not',
    englishModifier: (english) => { return 'not '+ english; },
    englishModifierInfinitive: (english) => { return 'not '+ english; },
    englishModifierGerund: (english) => { return 'not '+ english; },
    englishModifierPast: (english) => { return 'not '+ english; },
    expression: 'ŋit',
    // tense:'',
    allowable_next_ids: [6, 5],
  },
  5:{
    // id: 4,
    description: 'deliberately (purposefully)',
    englishModifier: (english) => { return 'deliberately (purposefully) '+ english; },
    englishModifierInfinitive: (english) => { return 'deliberately (purposefully) '+ english; },
    englishModifierGerund: (english) => { return 'deliberately (purposefully) '+ english; },
    englishModifierPast: (english) => { return 'deliberately (purposefully) '+ english; },
    expression: 'saaġe',
    // tense:'',
    allowable_next_ids: [4],
  },

  12:{
    // id: 4,
    description: 'always (regularly)',
    englishModifier: (english) => { return 'always (regularly) '+ english; },
    englishModifierInfinitive: (english) => { return 'always (regularly) '+ english; },
    englishModifierGerund: (english) => { return 'always (regularly) '+ english; },
    englishModifierPast: (english) => { return 'always (regularly) '+ english; },
    expression: 'suu',
    // tense:'',
    allowable_next_ids: [6, 11]
  },

  6:{
    // id: 4,
    description: 'wants to',
    englishModifier: (english) => { return 'wanting to '+ english; },
    englishModifierInfinitive: (english) => { return 'want to '+ english; },
    englishModifierGerund: (english) => { return 'wanting to '+ english; },
    englishModifierPast: (english) => { return 'wanted to '+ english; },
    expression: 'suk',
    // tense:'',
    allowable_next_ids: [11, 9],
  },

  11:{
    // id: 4,
    description: 'beginning to',
    englishModifier: (english) => { return 'beginning to '+ english; },
    englishModifierInfinitive: (english) => { return 'begin to '+ english; },
    englishModifierGerund: (english) => { return 'beginning to '+ english; },
    englishModifierPast: (english) => { return 'began to '+ english; },
    expression: 'rraqsi',
    // tense:'',
    allowable_next_ids: [6]
  },

  10:{
    // id: 4,
    description: 'likes',
    englishModifier: (english) => { return 'likes '+ english; },
    englishModifierInfinitive: (english) => { return 'like '+ english; },
    englishModifierGerund: (english) => { return 'liking '+ english; },
    englishModifierPast: (english) => { return 'liked '+ english; },
    expression: 'llatu',
    // tense:'',
    allowable_next_ids: []
  },

  7:{
    // id: 4,
    description: 'dislikes',
    englishModifier: (english) => { return 'dislikes '+ english; },
    englishModifierInfinitive: (english) => { return 'dislike '+ english; },
    englishModifierGerund: (english) => { return 'disliking '+ english; },
    englishModifierPast: (english) => { return 'disliked '+ english; },
    expression: 'sulait',
    // tense:'',
    allowable_next_ids: []
  },

  8:{
    // id: 4,
    description: 'not able to',
    englishModifier: (english) => { return 'unable to '+ english; },
    englishModifierInfinitive: (english) => { return 'be unable to '+ english; },
    englishModifierGerund: (english) => { return 'being unable to '+ english; },
    englishModifierPast: (english) => { return 'unable to '+ english; },
    expression: 'lait',
    // tense:'',
    allowable_next_ids: []
  },

  9:{
    // id: 4,
    description: 'able to',
    englishModifier: (english) => { return 'able to '+ english; },
    englishModifierInfinitive: (english) => { return 'be able to '+ english; },
    englishModifierGerund: (english) => { return 'being able to '+ english; },
    englishModifierPast: (english) => { return 'able to '+ english; },
    expression: 'lla',
    // tense:'',
    allowable_next_ids: []
  },



//   {
//     id: 5,
//     description: 'past tense',
//     englishModifier: (english) => { return ''+ english; },
//     expression: '-2ru\\',
//     expression_conditional: '',
//     priority: 3,
//     conditional_rule: '',
//     tense:'past',
//     allowable_next_ids: [7]
//   },
//   {
//     id: 6,
//     description: 'not yet',
//     englishModifier: (english) => { return 'not yet '+ english; },
//     expression: '-ksaite\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7]
//   },
//   {
//     id: 7,
//     description: 'future tense',
//     englishModifier: (english) => { return ''+ english},
//     expression: '+ciqe\\',
//     expression_conditional: '@ciiqe\\',  // conditional te_ending
//     priority: 3,
//     conditional_rule: 'attaching_to_te',  // defined later and if satisfied display expression_conditional
//     tense:'future',
//     allowable_next_ids: [0,1,5,10]
//   },
//   {
//     id: 8,
//     description: 'will not',
//     englishModifier: (english) => { return 'not '+  english; },
//     expression: '@~+6aite\\',
//     expression_conditional: '',
//     priority: 3,
//     conditional_rule: '',
//     tense:'future',
//     allowable_next_ids: [0,1,7,10]
//   },
//   {
//     id: 9,
//     description: 'will soon',
//     englishModifier: (english) => { return 'soon '+ english; },
//     expression: '@~+niarar\\',
//     expression_conditional: '',
//     priority: 3,
//     conditional_rule: '',
//     tense:'future',
//     allowable_next_ids: [0,1,5,6,7,10]
//   },
//   {
//     id: 10,
//     description: 'about to',
//     englishModifier: (english) => { return 'about to '+ english; },
//     expression: '-qatar\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1,7]
//   },
//   {
//     id: 11,
//     description: 'repeatedly',
//     englishModifier: (english) => { return 'repeatedly '+ english; },
//     expression: '-lar\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 12,
//     description: 'doing it again',
//     englishModifier: (english) => { return '(again) '+ english; },
//     expression: '-nqigte\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 13,
//     description: 'almost',
//     englishModifier: (english) => { return 'almost '+ english; },
//     expression: '@~+yarpiar\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 14,
//     description: 'never',
//     englishModifier: (english) => { return 'never '+ english; },
//     expression: '@-~yuite\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 15,
//     description: 'no longer',
//     englishModifier: (english) => { return 'no longer '+ english; },
//     expression: '-nrir\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,27]
//   },
//   {
//     id: 16,
//     description: 'trying (unsuccessfully) to',
//     englishModifier: (english) => { return 'trying (unsuccessfully) to '+ english; },
//     englishModifierInfinitive: (english) => { return 'try (unsuccessfully) to '+ english; },
//     englishModifierGerund: (english) => { return 'trying (unsuccessfully) to '+ english; },
//     expression: '@~+yaaqe\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,17,19]
//   },
//   {
//     id: 17,
//     description: 'trying despite difficulties to',
//     englishModifier: (english) => { return 'trying despite difficulties to '+ english; },
//     englishModifierInfinitive: (english) => { return 'try despite difficulties to '+ english; },
//     englishModifierGerund: (english) => { return 'trying despite difficulties to '+ english; },
//     expression: '-qcaar(ar)\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,16,18]
//   },
//   {
//     id: 18,
//     description: 'trying to',
//     englishModifier: (english) => { return 'trying to '+ english; },
//     englishModifierInfinitive: (english) => { return 'try to '+ english; },
//     englishModifierGerund: (english) => { return 'trying to '+ english; },
//     expression: '-6naqe\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,16,17]
//   },
//   {
//     id: 19,
//     description: 'excessively (too much)',
//     englishModifier: (english) => { return 'excessively '+ english; },
//     expression: '-3iyaag\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 20,
//     description: 'to be more or better at',
//     englishModifier: (english) => { return '(being more or better at) '+ english; },
//     expression: '-nru\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 21,
//     description: 'loves to',
//     englishModifier: (english) => { return 'loves to '+ english; },
//     englishModifierInfinitive: (english) => { return 'love to '+ english; },
//     englishModifierGerund: (english) => { return 'loving to '+ english; },
//     expression: '@~+yunqe4\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7,8]
//   },
//   {
//     id: 22,
//     description: 'no longer wants to',
//     englishModifier: (english) => { return 'no longer wants to '+ english; },
//     englishModifierInfinitive: (english) => { return 'no longer want to '+ english; },
//     englishModifierGerund: (english) => { return 'no longer wanting to '+ english; },
//     expression: '@~+yuumiir(ar)te\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7,8]
//   },
//   {
//     id: 23,
//     description: 'not care to',
//     englishModifier: (english) => { return 'not care to '+ english; },
//     englishModifierInfinitive: (english) => { return 'not care to '+ english; },
//     englishModifierGerund: (english) => { return 'not caring to '+ english; },
//     expression: '@~+yuumiite\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7,8]
//   },
//   {
//     id: 24,
//     description: 'yearns to',
//     englishModifier: (english) => { return 'yearns to '+ english; },
//     englishModifierInfinitive: (english) => { return 'yearn to '+ english; },
//     englishModifierGerund: (english) => { return 'yearning to '+ english; },
//     expression: '@~+yuumir\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7,8]
//   },
//   {
//     id: 25,
//     description: 'wants to',
//     englishModifier: (english) => { return 'wants to '+ english; },
//     englishModifierInfinitive: (english) => { return 'want to '+ english; },
//     englishModifierGerund: (english) => { return 'wanting to '+ english; },
//     expression: '@~+yug\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7,8]
//   },
//   {
//     id: 26,
//     description: 'enjoys doing',
//     englishModifier: (english) => { return 'enjoying '+ english; },
//     englishModifierInfinitive: (english) => { return 'enjoy '+ english; },
//     englishModifierGerund: (english) => { return 'enjoying '+ english; },
//     expression: '@~+yugar\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,7,8]
//   },
//   {
//     id: 27,
//     description: 'not',
//     englishModifier: (english) => { return 'not '+ english; },
//     expression: '-nrite\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1]
//   },
//   // {
//   //   id: 28,
//   //   description: 'to ask one to',
//   //   englishModifier: (english) => { return 'to ask one to '+ english; },
//   //   expression: 'sqe-\\',
//   //   expression_conditional: '',
//   //   conditional_rule: '',
//   //   tense:'',
//   //   allowable_next_ids: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]
//   // },
//   {
//     id: 28,
//     description: 'in the process of',
//     englishModifier: (english) => { return 'in the process of '+ english; },
//     expression: '@~+yartur\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,2,3,4,5,6,7,8,9,10,19]
//   },
//   {
//     id: 29,
//     description: 'definitely no longer going to',
//     englishModifier: (english) => { return 'definitely no longer going to '+ english; },
//     expression: '@~+yugnairute\\',
//     expression_conditional: '',
//     priority: 1,
//     conditional_rule: '',
//     tense:'',
//     allowable_next_ids: [0,1,3,4,8,10], // only refers to the postbases, not the endings
//   }
};
export const nounPostbases = {


  13:{
    // id: 4,
    description: 'good',
    clickDescription: 'good',
    englishModifier: (english) => { return 'good '+ english; },
    englishModifierPlural: (english) => { return 'good '+ english; },

    expression: 'lluataq',
    // tense:'future',
    allowable_next_ids: [17,18,20,21,22,23,24,25],
  },
  14:{
    // id: 4,
    description: 'big',
    clickDescription: 'big',
    englishModifier: (english) => { return 'big '+ english; },
    englishModifierPlural: (english) => { return 'big '+ english; },
    expression: 'qpak',
    // tense:'future',
    allowable_next_ids: [17,18,20,21,22,23,24,25],
  },
  15:{
    // id: 4,
    description: 'bad',
    clickDescription: 'bad',
    englishModifier: (english) => { return 'bad '+ english; },
    englishModifierPlural: (english) => { return 'bad '+ english; },
    expression: 'pałłuk',
    // tense:'',
    allowable_next_ids: [17,18,20,21,22,23,24,25],
  },
  16:{
    // id: 4,
    description: 'small',
    clickDescription: 'small',
    englishModifier: (english) => { return 'small '+ english; },
    englishModifierPlural: (english) => { return 'small '+ english; },
    expression: 'ŋuluk',
    // tense:'',
    allowable_next_ids: [17,18,20,21,22,23,24,25],
  },
  17:{
    // id: 4,
    description: 'it is a N',
    clickDescription: 'to be a',
    englishModifier: (english) => { return 'a '+ english; },
    englishModifierPlural: (english) => { return 'a '+ english; },
    expression: 'u',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  18:{
    // id: 4,
    description: 'it is making N',
    clickDescription: 'to be making',
    englishModifier: (english) => { return 'making '+ english; },
    englishModifierPlural: (english) => { return 'making '+ english; },
    expression: 'liuq',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  19:{
    // id: 4,
    description: 'it is eating or using N',
    clickDescription: 'to eat or use a',
    englishModifier: (english) => { return 'eating or using '+ english; },
    englishModifierPlural: (english) => { return 'eating or using '+ english; },
    expression: 'tuq',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  20:{
    // id: 4,
    description: 'it has a N',
    clickDescription: 'to have a',
    englishModifier: (english) => { return 'has a '+ english; },
    englishModifierPlural: (english) => { return 'have a '+ english; },
    expression: 'qaq',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  21:{
    // id: 4,
    description: 'it has a small quantity of N',
    clickDescription: 'to have a small quantity of',
    englishModifier: (english) => { return 'has a small quantity of '+ english; },
    englishModifierPlural: (english) => { return 'have a small quantity of '+ english; },
    expression: 'kit',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  22:{
    // id: 4,
    description: 'it lacks N',
    clickDescription: 'to lack',
    englishModifier: (english) => { return 'lacks '+ english; },
    englishModifierPlural: (english) => { return 'lack '+ english; },
    expression: 'it',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  23:{
    // id: 4,
    description: 'it is located at the N',
    clickDescription: 'to be located at',
    englishModifier: (english) => { return 'located at the '+ english; },
    englishModifierPlural: (english) => { return 'located at the '+ english; },
    expression: '+N+Loc+Sg>miet',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  24:{
    // id: 4,
    description: 'it is coming from the N',
    clickDescription: 'to be coming from',
    englishModifier: (english) => { return 'coming from the '+ english; },
    englishModifierPlural: (english) => { return 'coming from the '+ english; },
    expression: '+N+Abl+Sg>miñŋaq',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
  25:{
    // id: 4,
    description: 'it is going to the N',
    clickDescription: 'to be going to',
    englishModifier: (english) => { return 'going to the '+ english; },
    englishModifierPlural: (english) => { return 'going to the '+ english; },
    expression: '+N+Trm+Sg>muk',
    // tense:'',
    allowable_next_ids: [13,14,15,16],
  },
//   {
//     id: 0,
//     description: 'large',
//     englishModifier: (english) => { return 'large '+ english; },
//     expression_end: '-rpak',
//     expression_postbase: '-rpag\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:4,
//     tense:'',
//     allowable_next_ids: [1]
//   },
//   {
//     id: 1,
//     description: 'small',
//     englishModifier: (english) => { return 'small '+ english; },
//     expression_end: '-cuar',
//     expression_postbase: '-cuar\\',
//     expression_conditional_end: '-kcuar',
//     expression_conditional_postbase: '-kcuar(ar)\\',
//     conditional_rule: '',
//     priority:4,
//     tense:'',
//     allowable_next_ids: [0]
//   },
//   {
//     id: 2,
//     description: 'baby/little',
//     englishModifier: (english) => { return 'baby/little '+ english; },
//     expression_end: '-yagaq',
//     expression_postbase: '-yagar\\',
//     expression_conditional_end: '-yagaq',
//     expression_conditional_postbase: '-yagar\\',
//     conditional_rule: '',
//     priority:4,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 3,
//     description: 'good',
//     englishModifier: (english) => { return 'good '+ english; },
//     expression_end: '-kegtaar',
//     expression_postbase: '-kegtaar\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:4,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 4,
//     description: 'shabby',
//     englishModifier: (english) => { return 'shabby '+ english; },
//     expression_end: '-2er',
//     expression_postbase: '-2er\\',
//     expression_conditional_end: '-2er',
//     expression_conditional_postbase: '-2er\\',
//     conditional_rule: '',
//     priority:4,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 5,
//     description: 'genuine',
//     englishModifier: (english) => { return 'genuine '+ english; },
//     expression_end: '-piaq',
//     expression_postbase: '-piar\\',
//     expression_conditional_end: '-pik',
//     expression_conditional_postbase: '-pig\\',
//     conditional_rule: '',
//     priority:4,
//     tense:'past',
//     allowable_next_ids: []
//   },
//   {
//     id: 6,
//     description: 'fake',
//     englishModifier: (english) => { return 'fake '+ english; },
//     expression_end: '-(6)uaq',
//     expression_postbase: '-(6)uar\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:4,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 7,
//     description: 'many Ns',
//     englishModifier: (english) => { return 'many of them'+ english},
//     expression_end: '-rugaq',
//     expression_postbase: '-rugar\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:2,
//     tense:'noun_end',
//     allowable_next_ids: []
//   },
//   {
//     id: 8,
//     description: 'just a little',
//     englishModifier: (english) => { return 'just a little'+ english},
//     expression_end: '-5aq',
//     expression_postbase: '-5ar\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:2,
//     tense:'noun_end',
//     allowable_next_ids: []
//   },
//   {
//     id: 9,
//     description: 'N and other',
//     englishModifier: (english) => { return 'and another - only applies to people or animals '+ english; },
//     expression_end: ':(e)nkuk',
//     expression_postbase: ':(e)nkug\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:2,
//     tense:'noun_end',
//     allowable_next_ids: []
//   },
//   {
//     id: 10,
//     description: 'N and others',
//     englishModifier: (english) => { return 'and others - only applies to people or animals '+ english; },
//     expression_end: ':(e)nkut',
//     expression_postbase: ':(e)nkur\\',
//     expression_conditional_end: '',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:2,
//     tense:'noun_end',
//     allowable_next_ids: []
//   },
//   {
//     id: 11,
//     description: 'to hunt',
//     englishModifier: (english) => { return 'hunting '+ english; },
//     expression_postbase: '+cur\\',
//     expression_conditional_postbase: '+ssur\\',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 12,
//     description: 'to make',
//     englishModifier: (english) => { return 'making '+ english; },
//     expression_postbase: '-li\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 13,
//     description: 'to catch lots of',
//     englishModifier: (english) => { return 'catching lots of '+ english; },
//     expression_postbase: '-liqe\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 14,
//     description: 'to have lots of',
//     englishModifier: (english) => { return 'lots of '+ english; },
//     expression_postbase: '-lir\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 15,
//     description: 'to be occupied with',
//     englishModifier: (english) => { return 'occupied with '+ english; },
//     expression_postbase: '-liur\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 16,
//     description: 'to acquire',
//     englishModifier: (english) => { return 'acquiring '+ english; },
//     expression_postbase: '-6e\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 17,
//     description: 'to have',
//     englishModifier: (english) => { return ''+ english; },
//     expression_postbase: '-ngqerr\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 18,
//     description: 'the place does not have',
//     englishModifier: (english) => { return '(location) does not have '+ english; },
//     expression_postbase: '+taite\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 19,
//     description: 'the place has',
//     englishModifier: (english) => { return '(location) has '+ english; },
//     expression_postbase: '+ta6qe5\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 20,
//     description: 'to eat',
//     englishModifier: (english) => { return 'eating '+ english; },
//     expression_postbase: '+tur\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   },
//   {
//     id: 21,
//     description: 'to become',
//     englishModifier: (english) => { return 'become '+ english; },
//     expression_postbase: '~:(6)urte\\',
//     expression_conditional_postbase: '',
//     conditional_rule: '',
//     priority:1,
//     tense:'',
//     allowable_next_ids: []
//   }
};
